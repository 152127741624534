import { MutableRefObject, useEffect } from 'react'
import { VideoJsPlayer } from 'video.js'

const useVideoPlayerProgress = (
  player: MutableRefObject<VideoJsPlayer | null>,
  videoId: string,
  playerReady: boolean,
  startFromBeginning?: boolean,
) => {
  const localStorageKey = `player:${videoId}`

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    const userPlaybackRate = localStorage.getItem('playbackRate')
    if (userPlaybackRate) {
      player?.current?.playbackRate(Number(userPlaybackRate))
    }

    player?.current?.on('play', () => {
      interval = setInterval(() => {
        if (player?.current?.currentTime()) {
          localStorage.setItem(localStorageKey, player?.current?.currentTime().toString())
        }
      }, 1000)
    })

    player?.current?.on('ratechange', (e, a) => {
      localStorage.setItem('playbackRate', e?.target?.player?.cache_?.lastPlaybackRate)
    })

    player?.current?.on('pause', () => {
      clearInterval(interval)
    })

    player?.current?.on('ended', () => {
      clearInterval(interval)
      localStorage.removeItem(localStorageKey)
    })

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [player, playerReady])

  useEffect(() => {
    if (startFromBeginning) return
    if (player && localStorage.getItem(localStorageKey)) {
      player?.current?.currentTime(Number(localStorage.getItem(localStorageKey) || ''))
    }
  }, [player, playerReady, startFromBeginning])
}

export default useVideoPlayerProgress
