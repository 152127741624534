import * as React from 'react'
import { Box } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import videojs, { VideoJsPlayer } from 'video.js'
import useVideoPlayerProgress from './hooks/useVideoPlayerProgress.hook'
import SkipVideoButtons from '../VideoItem/SkipVideoButtons'
import 'video.js/dist/video-js.css'
import './video.scss'
import { useParams } from 'react-router'

interface IVideoPlayerProps {
  videoJsOptions: videojs.PlayerOptions
  onPlayerReady?: (player: VideoJsPlayer) => void
  videoId: string
  visible?: boolean
  postId?: string
  startFromBeginning?: boolean
  mobileAutoplay?: boolean
}

const VideoPlayer = (props: IVideoPlayerProps) => {
  const { videoJsOptions } = props
  // used for useVideoPlayerProgress ref after creating video.js instance in playerRef
  // TODO: find better solution for passing refs between hook and parent component
  const [playerReady, setPlayerReady] = useState(false)
  const [videoHeight, setVideoHeight] = useState('')
  const videoNode = useRef(null)
  const playerRef = useRef<VideoJsPlayer | null>(null)
  const video = document.querySelector(props?.postId ? `.video-js-chat-video-${props.postId} .video-js` : '.video-js')
  const fullScreenBtn = video?.querySelector(`.vjs-fullscreen-control`)
  const { id: paramVideoId } = useParams() as { id: string }
  useVideoPlayerProgress(playerRef, props.videoId, playerReady, props?.startFromBeginning)

  const skipVideo = useCallback(
    (event: KeyboardEvent | null, interval: number) => {
      if (!playerRef?.current || !video) return
      event?.preventDefault()
      if (!event) {
        return playerRef.current?.currentTime(playerRef.current?.currentTime() + interval)
      }
      switch (event?.code) {
        case 'ArrowLeft':
          return playerRef.current?.currentTime(playerRef.current?.currentTime() - 5)
        case 'ArrowRight':
          return playerRef.current?.currentTime(playerRef.current?.currentTime() + 5)
        case 'Space':
          return playerRef.current?.paused() ? playerRef.current?.play() : playerRef.current?.pause()
        default:
          return
      }
    },
    [playerRef?.current, video],
  )

  useEffect(() => {
    if (!videoNode.current || playerRef.current || !videoJsOptions) return
    const player = (playerRef.current = videojs(videoNode.current, videoJsOptions, () => {
      props.onPlayerReady && props.onPlayerReady(player)
      setPlayerReady(true)
    }))
  }, [videoNode, videoJsOptions, props.visible])

  // handle skipping video with keyboard
  useEffect(() => {
    if (!props.visible || !video) return
    if (video) {
      video.addEventListener('keydown', skipVideo as EventListener)
    }
    return () => {
      if (video) {
        video.removeEventListener('keydown', skipVideo as EventListener)
      }
    }
  }, [skipVideo, props.visible, props?.postId, video])

  // change focus from fullscreen button to video element in full screen mode
  useEffect(() => {
    if (!video || !fullScreenBtn) return
    fullScreenBtn.addEventListener('click', () => {
      ;(fullScreenBtn as HTMLElement).blur()
      ;(video as HTMLElement).focus()
    })
  }, [video, fullScreenBtn, props.visible])

  useEffect(() => {
    const player = playerRef.current
    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef, paramVideoId])

  useEffect(() => {
    if (props.mobileAutoplay) {
      setVideoHeight('calc(100vh - 100px)')
    } else {
      setVideoHeight('70vh')
    }
  }, [props.mobileAutoplay])

  return (
    <Box className="c-player__screen" data-vjs-player="true">
      <SkipVideoButtons visible={props.visible} skipVideo={skipVideo} postId={props?.postId} />
      <video style={{ maxHeight: videoHeight }} ref={videoNode} className="video-js "></video>
    </Box>
  )
}

export default VideoPlayer
