import React from 'react'
import Group from '../blocks/Group'
import useChannelSidebar from '../../common/hooks/useChannelSidebar'
import { screensightChannel } from '../../../../../../../API'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Skeleton } from '@mui/material'

const Content = () => {
  const { t } = useTranslation('chat')
  const state = useChannelSidebar()

  if (state.loading) {
    return (
      <Box marginTop="50px">
        {Array.from(Array(20)).map((_, index) => (
          <Box width="100%" display="flex" padding="0 15px" marginBottom="14px" key={index}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton
              variant="rectangular"
              width={170}
              height={24}
              style={{ marginLeft: '10px', borderRadius: '6px' }}
            />
          </Box>
        ))}
      </Box>
    )
  }
  return (
    <InfiniteScroll
      style={{ overflowX: 'hidden' }}
      dataLength={state.dataLength || 0}
      next={state.loadNextChannels}
      hasMore={state.hasMore}
      scrollableTarget="chat__channels-scrollable-box"
      loader={
        <Box>
          <Box width="100%" display="flex" padding="0 15px" marginBottom="14px">
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton
              variant="rectangular"
              width={170}
              height={24}
              style={{ marginLeft: '10px', borderRadius: '6px' }}
            />
          </Box>
          <Box width="100%" display="flex" padding="0 15px" marginBottom="14px">
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton
              variant="rectangular"
              width={170}
              height={24}
              style={{ marginLeft: '10px', borderRadius: '6px' }}
            />
          </Box>
          <Box width="100%" display="flex" padding="0 15px" marginBottom="28px">
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton
              variant="rectangular"
              width={170}
              height={24}
              style={{ marginLeft: '10px', borderRadius: '6px' }}
            />
          </Box>
        </Box>
      }
    >
      {state.recentChannels && (
        <Group
          unreadChannels={state.unreadChannels}
          channels={state.recentChannels}
          label={t('channelsSidebar.recentMessages')}
          type="recent"
        />
      )}

      <Group
        unreadChannels={state.unreadChannels}
        channels={state.directChannels as screensightChannel[]}
        label={t('channelsSidebar.directMessages')}
      />
    </InfiniteScroll>
  )
}

export default Content
