import React from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useGroup from '../../../ChannelsSidebar/common/hooks/useGroup'
import GroupHeader from '../components/GroupHeader'
import Row from '../components/Row'
import { screensightChannel } from '../../../../../../../API'

interface IGroup {
  channels?: screensightChannel[] | null
  label: string
  unreadChannels: string[]
  closeChannels: () => void
}

const Group = (props: IGroup) => {
  const state = useGroup()
  const theme = useTheme()
  const { t } = useTranslation('chat')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box mb={isMobile ? 0 : '15px'} mt={isMobile ? '30px' : 0}>
      <GroupHeader onClick={state.toggleList} label={props.label} channelsExpanded={state.expanded} />
      {state.expanded ? (
        <>
          {(props?.channels || [])?.map((channel: screensightChannel, key: number) => {
            return (
              <Row
                isScreenSightUser={channel.channelType === 'direct_message'}
                closeChannels={props.closeChannels}
                label={channel?.name || ''}
                channelId={channel?.id || ''}
                key={channel?.id || key}
                active={state.channelId === channel?.id}
                shouldNotify={props.unreadChannels.includes(channel?.id)}
                imgKey={channel.chatPartnerMeta?.profilePhoto?.key}
              />
            )
          })}
          <Row
            createNewMessageRow
            channelId="new"
            label={t('channelsSidebar.newMessage')}
            closeChannels={props.closeChannels}
          />
        </>
      ) : null}
    </Box>
  )
}

export default Group
