import React from 'react'
import { UseRecorderProvider } from './useRecorderStateProvider'
import RecorderStateContext from './RecorderStateContext'

const RecorderProviderView = (props: RecorderProviderViewProps) => {
  const { state, dispatch, children } = props
  return <RecorderStateContext.Provider value={{ state, dispatch }}>{children}</RecorderStateContext.Provider>
}

export type RecorderProviderViewProps = UseRecorderProvider & any
export default RecorderProviderView
