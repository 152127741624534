import { GetVideoQuery, GetVideoQueryVariables } from '../../../../../../../../../API'
import { getVideo } from '../../../../../../../../../common/api/graphql/queries'
import useApolloLazyQuery from '../../../../../../../../../common/clients/useApolloLazyQuery'

const useGetVideo = () => {
  const [getVideoData, { loading }] = useApolloLazyQuery<GetVideoQuery, GetVideoQueryVariables>(getVideo)

  return { getVideoData, loadingVideoData: loading }
}

export default useGetVideo
