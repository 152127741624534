import React from 'react'

const AccessLockIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 0C4.2994 0 2.125 2.29388 2.125 5.14286V6C1.23689 6 0.5 6.77737 0.5 7.71429V16.2857C0.5 17.2226 1.23689 18 2.125 18H11.875C12.7631 18 13.5 17.2226 13.5 16.2857V7.71429C13.5 6.77737 12.7631 6 11.875 6V5.14286C11.875 2.29388 9.7006 0 7 0ZM7 1.71429C8.8494 1.71429 10.25 3.19184 10.25 5.14286V6H3.75V5.14286C3.75 3.19184 5.1506 1.71429 7 1.71429ZM2.125 7.71429H11.875V16.2857H2.125V7.71429ZM7 10.2857C6.10625 10.2857 5.375 11.0571 5.375 12C5.375 12.9429 6.10625 13.7143 7 13.7143C7.89375 13.7143 8.625 12.9429 8.625 12C8.625 11.0571 7.89375 10.2857 7 10.2857Z"
        fill={color || '#7A7A86'}
      />
    </svg>
  )
}

export default AccessLockIcon
