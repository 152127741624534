import React from 'react'
import { Box, TextField, Typography } from '@mui/material'
import NameImg from './assets/name.png'
import { IOnBoardingName } from '../interfaces'
import { useTranslation } from 'react-i18next'

const OnBoardingName = (props: IOnBoardingName) => {
  const { t } = useTranslation('library')
  const { t: g } = useTranslation('general')
  return (
    <>
      <Box
        width={props.isMobile ? '100%' : '50%'}
        paddingRight={props.isMobile ? '0' : '16px'}
        paddingTop={props.isMobile ? '0' : '32px'}
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Typography variant="body1" style={{ fontWeight: 500, fontSize: '20px', marginBottom: '10px' }}>
          {t('videoList.onBoardingModal.personalInfoTitle')}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ fontSize: '15px', marginBottom: '30px' }}>
          {t('videoList.onBoardingModal.personalInfoSubtitle')}
        </Typography>
        <TextField
          value={props.firstName}
          onChange={props.handleChangeFirstName}
          variant="outlined"
          label={g('common.firstName')}
          fullWidth
          style={{ marginBottom: '30px' }}
          inputProps={{ maxLength: 100 }}
        />
        <TextField
          value={props.lastName}
          onChange={props.handleChangeLastName}
          variant="outlined"
          label={g('common.lastName')}
          inputProps={{ maxLength: 100 }}
          fullWidth
        />
      </Box>
      <Box
        width="50%"
        height="100%"
        paddingLeft="16px"
        display={props.isMobile ? 'none' : 'flex'}
        alignItems="center"
        justifyContent="center"
      >
        <img src={NameImg} />
      </Box>
    </>
  )
}

export default OnBoardingName
