import React, { SVGProps } from 'react'

const IconScreenRecording = ({ width = 41, height = 40, color = '#7A7A86' }: SVGProps<any>) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.436.574h2.15v8.388h-2.15V.574z" fill="#fff" />
      <path
        d="M1.075 9.312c.593 0 1.075-.458 1.075-1.023s-.482-1.023-1.075-1.023C.48 7.266 0 7.724 0 8.289s.481 1.023 1.075 1.023z"
        fill={color}
      />
      <path
        d="M6.002 9.9h0L10.3 5.808a.801.801 0 00-.012-1.165.927.927 0 00-1.25-.011l-4.297 4.09s0 0 0 0a.8.8 0 00-.19.905.89.89 0 00.82.52l.631-.249zm0 0a.916.916 0 01-.63.248l.63-.249zM6.002 16.037s0 0 0 0a.927.927 0 01-1.261 0 .802.802 0 010-1.175L15.486 4.633a.927.927 0 011.25.011.801.801 0 01.012 1.165L6.002 16.037z"
        fill={color}
        stroke="#fff"
        strokeWidth={0.374}
      />
      <path
        d="M38.692 25.865h.187V4.195c0-1.242-1.055-2.233-2.337-2.233H4.3c-1.281 0-2.336.991-2.336 2.233 0 .453-.389.836-.888.836-.5 0-.888-.383-.888-.836C.187 2.047 2.02.29 4.3.29h19.346C25.814.29 27.71.258 29.37.23c2.22-.038 4.016-.07 5.478-.005 2.56.112 3.994.517 4.824 1.606.418.549.696 1.289.872 2.294.176 1.006.248 2.263.265 3.838.016 1.49-.018 3.257-.058 5.353l-.007.356a400.94 400.94 0 00-.09 7.915v7.161c0 2.148-1.832 3.905-4.112 3.905H25.608v1.21c0 .091.015 1.143.44 2.179.213.52.532 1.045 1.014 1.441.485.4 1.124.659 1.957.659h1.075c.499 0 .887.383.887.836 0 .453-.388.836-.887.836H10.748c-.5 0-.888-.383-.888-.836 0-.453.389-.836.888-.836h1.075c.838 0 1.48-.26 1.967-.658.483-.397.802-.922 1.014-1.442a6.37 6.37 0 00.43-2.179v-1.21H4.299c-2.28 0-4.112-1.757-4.112-3.905V12.38c0-.453.389-.836.888-.836.5 0 .888.383.888.836v13.486h3.411c.5 0 .888.383.888.836 0 .453-.389.836-.888.836H1.963v1.21c0 1.242 1.055 2.233 2.336 2.233h32.243c1.282 0 2.337-.991 2.337-2.233v-1.21H10.748c-.5 0-.888-.383-.888-.836 0-.453.389-.836.888-.836h27.944zM24.9 38.141h.349l-.194-.29a7.217 7.217 0 01-1.224-3.99v-1.209H17.01v1.21a7.217 7.217 0 01-1.223 3.988l-.194.291H24.9z"
        fill={color}
        stroke="#fff"
        strokeWidth={0.374}
      />
    </svg>
  )
}

export default IconScreenRecording
