import React from 'react'
import NotificationRow from '../../../../components/Settings/EmailNotifications/NotificationRow'
import { AuthContextValue } from '../../../../common/providers/AuthStatusProvider'
import { useTranslation } from 'react-i18next'
import UnreadMessageIcon from '../../../../common/icons/UnreadMessageIcon'
const CameraIcon = (
  <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6 4.56295L19 3.36125V9.63875L16.6 8.43705V4.56295ZM1 2.375C1 1.93681 1.3702 1.5 2 1.5H12.8C13.4298 1.5 13.8 1.93681 13.8 2.375V10.625C13.8 11.0632 13.4298 11.5 12.8 11.5H2C1.3702 11.5 1 11.0632 1 10.625V2.375Z"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
)

const CommentIcon = (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 13V12H4H1.33333C1.14895 12 1 11.851 1 11.6666V1.66665C1 1.48226 1.14895 1.33331 1.33333 1.33331H14.6667C14.851 1.33331 15 1.48226 15 1.66665V11.6666C15 11.851 14.851 12 14.6667 12H7.5H7.00043L6.70041 12.3994L5 14.6634V13Z"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
)

interface IEmailNotificationsViewProps {
  handleViewsNotification: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  handleCommentsNotification: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  handleUnseenMessagesNotification: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void

  auth: AuthContextValue
  viewsNotificationsLoading: boolean
  commentsNotificationsLoading: boolean
  unseenNotificationLoading: boolean
}

const EmailNotificationsView = (props: IEmailNotificationsViewProps) => {
  const { t } = useTranslation('settings')

  if (!props.auth.user?.settings) return <></>
  return (
    <>
      <NotificationRow
        icon={CameraIcon}
        title={t('emailNotifications.videoViewsTitle')}
        description={t('emailNotifications.videoViewsDescription')}
        submitAction={props.handleViewsNotification}
        checked={props.auth.user?.settings?.notifyEmailWatched}
        loading={props.viewsNotificationsLoading}
      />
      <NotificationRow
        icon={CommentIcon}
        title={t('emailNotifications.commentsTitle')}
        description={t('emailNotifications.commentsDescription')}
        submitAction={props.handleCommentsNotification}
        checked={props.auth.user?.settings?.notifyEmailComment}
        loading={props.commentsNotificationsLoading}
      />
      <NotificationRow
        icon={<UnreadMessageIcon />}
        title={t('emailNotifications.unseenMessagesTitle')}
        description={t('emailNotifications.unseenMessagesDescription')}
        submitAction={props.handleUnseenMessagesNotification}
        checked={props.auth.user?.settings?.notifyUnseenMessages}
        loading={props.unseenNotificationLoading}
      />
    </>
  )
}

export default EmailNotificationsView
