import React from 'react'
import { Typography } from '@mui/material'
import { screensightVideoEmailMessage } from '../../../../../../../API'
import useVideoPostActivity from '../../common/hooks/posts/useVideoPostActivity'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import i18next from 'i18next'
import './styles.scss'

interface IVideoPostActivity {
  emailVideoData?: screensightVideoEmailMessage | null
  partnerId: string
  videoId?: string
}

const getCurrentEmailVideoStatus = ({
  isEmailDelivered,
  isSpam,
  videoWatched,
  emailOpened,
}: {
  isEmailDelivered?: string | null
  isSpam: boolean
  videoWatched?: string | null
  emailOpened?: string | null
}) => {
  if (videoWatched) {
    return `${i18next.t('chat:chatBody.videoPostActivity.videoSeen')} ${videoWatched}`
  }
  if (!isEmailDelivered) {
    return `${i18next.t('chat:chatBody.videoPostActivity.outOfDelivery')}`
  }
  if (emailOpened) {
    return `${i18next.t('chat:chatBody.videoPostActivity.emailOpened')} ${emailOpened}`
  }
  if (isSpam) {
    return `${i18next.t('chat:chatBody.videoPostActivity.emailInSpam')}`
  }
  if (isEmailDelivered) {
    return `${i18next.t('chat:chatBody.videoPostActivity.emailDelivered')} ${isEmailDelivered}`
  }
}

const VideoPostActivity = (props: IVideoPostActivity) => {
  const state = useVideoPostActivity({
    emailVideoData: props.emailVideoData,
    partnerId: props.partnerId,
    videoId: props.videoId,
  })

  return (
    <Typography
      variant="body1"
      sx={{
        fontSize: '12px',
        color: !state.isSpam ? theme.palette.secondary.main : theme.palette.error.main,
        visibility: state.loading ? 'hidden' : 'visible',
        marginTop: '4px!important',
      }}
    >
      {getCurrentEmailVideoStatus({
        isEmailDelivered: state.isEmailDelivered,
        isSpam: state.isSpam,
        videoWatched: state.isVideoOpened,
        emailOpened: state.isEmailOpened,
      })}
    </Typography>
  )
}

export default VideoPostActivity
