export const onPostsSeen = /* GraphQL */ `
  subscription OnPostsSeen($channelId: ID!) {
    onPostsSeen(channelId: $channelId) {
      customerId
      channelId
      timestamp
      source
      postIds
    }
  }
`

export type OnPostsSeenSubscription = {
  onPostsSeen?: {
    __typename: 'PostsSeen'
    customerId?: string | null
    channelId?: string | null
    timestamp?: string | null
    source?: string | null
    postIds?: Array<string | null> | null
  } | null
}
