import * as React from 'react'
import ChatEditor from '../blocks/ChatEditor'
import { Box } from '@mui/material'
import 'react-quill/dist/quill.snow.css'
import VideoNewModal from '../../../../../../../containers/VideoList/VideoNew'
import ModalLibrary from '../../../../../ModalLibrary'
import useChatNewVideo from '../../common/hooks/useChatNewVideo'

const Footer = () => {
  const state = useChatNewVideo()

  return (
    <Box width="100%" padding="0 20px 20px 20px" alignSelf="flex-end" zIndex={12}>
      <VideoNewModal
        open={state.isNewVideoModalVisible}
        onClose={state.closeNewVideoModal}
        channelId={state.channelId}
        openLibraryModal={state.openLibraryModal}
        defaultTitle={state.defaultVideoTitle}
      />
      {state.isLibraryModalVisible && (
        <ModalLibrary
          open={true}
          closeLibraryModal={state.closeLibraryModal}
          onClose={() => {
            state.closeLibraryModal()
            state.closeNewVideoModal()
          }}
        />
      )}
      <Box position="absolute" bottom={0} left={0} width="100%" bgcolor="white" height="20px" />
      <Box border="1px solid #BCBCC3" bgcolor="white" borderRadius="4px" width="100%" position={'relative'}>
        <ChatEditor
          openNewVideoModal={state.openNewVideoModal}
          openLibraryModal={state.openLibraryModal}
          handleChangeDefaultTitle={state.handleChangeDefaultTitle}
        />
      </Box>
    </Box>
  )
}

export default Footer
