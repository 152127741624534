import React from 'react'
import { Box, BoxProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    paddingTop: 2,
  },
})

const IconStartoverSmall = (props: BoxProps) => {
  const { color } = props
  const { root } = useStyles()

  return (
    <Box className={root} {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99997 1.00006C7.92267 0.998965 7.84593 1.01325 7.7742 1.04207C7.70247 1.07089 7.63718 1.11369 7.58213 1.16796C7.52708 1.22224 7.48337 1.28692 7.45353 1.35823C7.4237 1.42955 7.40833 1.50608 7.40833 1.58339C7.40833 1.6607 7.4237 1.73723 7.45353 1.80855C7.48337 1.87986 7.52708 1.94454 7.58213 1.99881C7.63718 2.05309 7.70247 2.09588 7.7742 2.12471C7.84593 2.15353 7.92267 2.16781 7.99997 2.16672C11.2284 2.16672 13.8333 4.77157 13.8333 8.00003C13.8333 11.2285 11.2284 13.8333 7.99997 13.8333C4.77151 13.8333 2.16666 11.2285 2.16666 8.00003C2.16666 7.26638 2.64062 5.92185 3.42675 5.47302L4.49999 6.76045L5.66665 2.67713L1.58333 3.26047L2.67252 4.56726C1.63389 5.78924 1 6.88043 1 8.00003C1 11.859 4.14097 15 7.99997 15C11.859 15 14.9999 11.859 14.9999 8.00003C14.9999 4.14102 11.859 1.00006 7.99997 1.00006Z"
          fill="#7A7A86"
          stroke="#7A7A86"
        />
      </svg>
    </Box>
  )
}

export default IconStartoverSmall
