import React from 'react'
import { Box, Grid, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Button from '../../../components/_refactored/Button'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import './styles.scss'
interface IContactsHeader {
  showNewContactModal: () => void
  handleSearchPhraseChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  searchValue: string
}

const ContactsHeader = (props: IContactsHeader) => {
  const theme = useTheme()
  const { t: g } = useTranslation('general')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      className="contacts__header__wrapper"
      left={isMobile ? '0' : '60px'}
      width={isMobile ? '100vw' : 'calc(100vw - 60px)'}
      height={isMobile ? '132px' : '80px'}
    >
      <Grid container direction={isMobile ? 'column-reverse' : 'row'} justifyContent="space-between">
        <Grid item xs={12} md={6} style={{ marginTop: isMobile ? '16px' : '' }}>
          <TextField
            placeholder={g('common.searchInputPlaceholder')}
            variant="outlined"
            value={props.searchValue}
            onChange={props.handleSearchPhraseChange}
            style={{ width: '250px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: '#7A7A86' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="flex-end">
          <Button
            fullWidth={isMobile}
            variant="contained"
            color="primary"
            size={isMobile ? 'medium' : 'large'}
            onClick={props.showNewContactModal}
            style={{ fontSize: '16px', paddingLeft: '20px', paddingRight: '20px' }}
          >
            <Box marginRight="10px" display="flex" alignItems="center">
              <AddIcon />
            </Box>
            {g('common.addContactBtn')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContactsHeader
