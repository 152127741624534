import Layout from '../ui/Layout'
import Header from '../ui/partials/Header'
import UserForm from '../ui/blocks/UserForm'
import Content from '../ui/partials/Content'

export const Login = {
  Layout,
  Header,
  UserForm,
  Content,
}
