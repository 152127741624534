import React, { Dispatch, SetStateAction } from 'react'
import { Box, IconButton, TableCell, TableRow, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { theme } from '../../../../../common/providers/MuiThemeProvider'
import ContactAvatar from '../../../ContactAvatar'
import PencilIcon from '../../../../../common/icons/PencilIcon'
import BasketIcon from '../../../../../common/icons/BasketIcon'
import { useHistory } from 'react-router-dom'

interface IContactRow {
  name?: string | null
  email: string
  id: string
  phone?: string | null
  image?: string
  setContactToDelete: Dispatch<SetStateAction<string | null>>
  ['data-testid']?: string
}

const useStyles = makeStyles({
  root: {
    '&:hover': {
      background: theme.palette.background.paper,
    },
    '&:hover .actionButtons': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  actionButtons: {
    display: 'none',
  },
})

const ContactRow = (props: IContactRow) => {
  const { root, actionButtons } = useStyles()
  const history = useHistory()
  return (
    <TableRow
      className={root}
      key={props.email}
      style={{ display: 'table', width: '100%' }}
      data-testid={props?.['data-testid']}
    >
      <TableCell
        style={{
          borderColor: '#BCBCC3',
          width: '35%',
          maxWidth: '35%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        component="th"
        scope="row"
      >
        <Box display="flex" alignItems="center" maxWidth="100%">
          <ContactAvatar
            image={props?.image}
            name={props?.name || props.email}
            onClick={() => history.push(`/app/contacts/${props.id}`)}
          />
          <Typography
            variant="body1"
            style={{
              fontWeight: 500,
              fontSize: '16px',
              cursor: 'pointer',
            }}
            onClick={() => history.push(`/app/contacts/${props.id}`)}
          >
            {props?.name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell style={{ borderColor: '#BCBCC3' }} align="left">
        <Typography variant="body1" color="textSecondary" style={{ fontWeight: 400, fontSize: '15px' }}>
          {props.email}
        </Typography>
      </TableCell>
      <TableCell style={{ borderColor: '#BCBCC3' }} align="left">
        <Typography variant="body1" color="textSecondary" style={{ fontWeight: 400, fontSize: '15px' }}>
          {props?.phone}
        </Typography>
      </TableCell>
      <TableCell style={{ borderColor: '#BCBCC3' }} align="right" width="150px">
        <Box width="100%" className={`${actionButtons} actionButtons`}>
          <IconButton
            style={{ boxShadow: 'none' }}
            onClick={() => history.push(`/app/contacts/${props.id}/edit`)}
            data-testid={`edit-contact-btn-${props.email}`}
          >
            <PencilIcon />
          </IconButton>
          <IconButton
            style={{ boxShadow: 'none', marginLeft: '8px' }}
            onClick={() => props.setContactToDelete(props.id)}
            data-testid={`delete-contact-btn-${props.email}`}
          >
            <BasketIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default ContactRow
