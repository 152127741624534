import React from 'react'
import ScreenSightLock from '../../../../../../common/icons/ScreenSightLock'
import { TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import Button from '../../../../../../components/_refactored/Button'
import useSendResetCode from '../../common/hooks/useSendResetCode'
import { useTranslation } from 'react-i18next'

const VerifyEmail = () => {
  const state = useSendResetCode()
  const { t } = useTranslation('auth')
  const { t: g } = useTranslation('general')

  return (
    <form onSubmit={state.onSubmitEmail}>
      <ScreenSightLock style={{ width: '74px', display: 'block', margin: '0 auto 20px auto' }} />
      <Typography align="center" variant="body1" style={{ fontWeight: 500 }}>
        {t('forgotPassword.verifyEmail.heading')}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        style={{ margin: '20px 0 40px 0', fontSize: '14px' }}
      >
        {t('forgotPassword.verifyEmail.subheading')}
      </Typography>
      <Controller
        name={'email'}
        control={state.emailForm.control}
        rules={{
          required: g('common.requiredField'),
          validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
        }}
        render={({ value, onChange, onBlur }) => (
          <TextField
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            key="username"
            name="username"
            type="email"
            variant="standard"
            label={g('common.email')}
            error={!!state.emailForm.errors['email']}
            helperText={state.emailForm.errors['email']?.message}
            style={{ width: '100%' }}
            disabled={state.loading}
            data-testid="forgot-password-email-input"
          />
        )}
      />
      <Button
        variant="contained"
        color="primary"
        loading={state.loading}
        type="submit"
        style={{ width: '100%', marginTop: 20 }}
        data-testid="send-code-button"
      >
        {t('forgotPassword.verifyEmail.sendCodeBtn')}
      </Button>
    </form>
  )
}

export default VerifyEmail
