import React, { useContext } from 'react'
import { Box, Drawer, IconButton, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ChannelsDrawer from '../../../../../../pages/Chat/ui/components/ChannelsDrawer'
import Logo from '../../../../../icons/Logo'
import ChatIcon from '../../../../../icons/ChatIcon'
import { Link } from 'react-router-dom'
import Icon from '../../../../../icons'
import ContactsIcon from '../../../../../icons/ContactsIcon'
import { useTranslation } from 'react-i18next'
import useMobileMenu from '../../../common/hooks/useMobileMenu'
import { AuthContext } from '../../../../../providers/AuthStatusProvider'
import { useLocation } from 'react-router'
import useLogout from '../../../common/hooks/useLogout'

const MobileMenu = () => {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const { t: g } = useTranslation('general')
  const { menuVisible, openMenu, closeMenu, channelsVisible, openChannels, closeChannels } = useMobileMenu()
  const [logout] = useLogout()

  return (
    <>
      <Box
        height="80px"
        width="100%"
        padding="20px 17px"
        position="fixed"
        top="0"
        left="0"
        bgcolor="background.default"
        zIndex={999}
      >
        <IconButton onClick={openMenu} style={{ boxShadow: 'none' }}>
          <MenuIcon />
        </IconButton>
      </Box>
      <ChannelsDrawer channelsVisible={channelsVisible} closeChannels={closeChannels} openMenu={openMenu} />
      <Drawer anchor={'left'} open={menuVisible} onClose={closeMenu}>
        <Box width="80vw" height="100%" maxWidth="293px" bgcolor="background.default" style={{ overflowX: 'hidden' }}>
          <Box
            width="100%"
            height="205px"
            bgcolor="background.paper"
            padding="30px 20px 40px 20px"
            display="flex"
            flexDirection="column"
          >
            <Logo />
            <Box display="flex" marginTop="40px" alignItems="center">
              {auth?.user?.profilePhotoUrl && (
                <img
                  src={auth.user.profilePhotoUrl}
                  alt="User image"
                  style={{
                    height: '70px',
                    width: '70px',
                    marginRight: '6px',
                    objectFit: 'cover',
                    borderRadius: '100px',
                  }}
                />
              )}

              {auth.user?.firstName && auth.user?.lastName ? (
                <Typography
                  variant="h4"
                  color="textSecondary"
                  style={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                  {auth.user?.firstName} {auth.user?.lastName}
                </Typography>
              ) : (
                <Typography
                  variant="h4"
                  color="textSecondary"
                  style={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                  {auth.user?.email}
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            width="100%"
            height="calc(100vh - 205px)"
            bgcolor="background.default"
            padding="30px 20px 30px 20px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <ul style={{ paddingLeft: '0', margin: '0' }}>
              <Box display="flex" alignItems="center" onClick={openChannels} style={{ cursor: 'pointer' }}>
                <ChatIcon active={location.pathname.startsWith('/app/chat')} />
                <Typography variant="h4" color={location.pathname === '/app/chat' ? 'primary' : 'textSecondary'}>
                  {g('menu.channels')}
                </Typography>
              </Box>
              <Link to="/app" onClick={closeMenu}>
                <Box display="flex" alignItems="center" marginTop="15px">
                  <Icon.VideoList active={location.pathname === '/app'} />
                  <Typography variant="h4" color={location.pathname === '/app' ? 'primary' : 'textSecondary'}>
                    {g('menu.library')}
                  </Typography>
                </Box>
              </Link>
              <Link to="/app/contacts" onClick={closeMenu}>
                <Box display="flex" alignItems="center" marginTop="15px">
                  <ContactsIcon active={location.pathname.startsWith('/app/contacts')} />
                  <Typography variant="h4" color={location.pathname === '/app/contacts' ? 'primary' : 'textSecondary'}>
                    {g('menu.contacts')}
                  </Typography>
                </Box>
              </Link>
              {auth.user?.id !== process.env.REACT_APP_SUPPORT_ID && (
                <Link to="/app/support" onClick={closeMenu}>
                  <Box display="flex" alignItems="center" marginTop="15px">
                    <Icon.Support active={location.pathname.startsWith('/app/support')} />
                    <Typography
                      variant="h4"
                      color={location.pathname.startsWith('/app/support') ? 'primary' : 'textSecondary'}
                    >
                      {g('menu.support')}
                    </Typography>
                  </Box>
                </Link>
              )}
              <Link to="/app/settings" onClick={closeMenu}>
                <Box display="flex" alignItems="center" marginTop="15px">
                  <Icon.IconSettings active={location.pathname.startsWith('/app/settings')} />
                  <Typography
                    variant="h4"
                    color={location.pathname.startsWith('/app/settings') ? 'primary' : 'textSecondary'}
                  >
                    {g('menu.settings')}
                  </Typography>
                </Box>
              </Link>
            </ul>
            <Box display="flex" onClick={logout} alignItems="center">
              <Icon.LogoutIcon />
              <Typography variant="h4" color={'textSecondary'}>
                {g('menu.logOut')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default MobileMenu
