import * as React from 'react'
import { Box, IconButton } from '@mui/material'
import * as queryString from 'query-string'
import { Close } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import CopyLinkIconButton from '../../../../../../../../../../components/_refactored/CopyLinkIconButton'

type Props = {
  videoId: string
  accessId: string | null | undefined
}

const PlayerTopOverlay = (props: Props) => {
  const history = useHistory()

  return (
    <Box
      position="absolute"
      width="100%"
      height="100px"
      bottom="calc(100vh - 270px)"
      left="0"
      zIndex={100000}
      display="flex"
      justifyContent="flex-end"
      padding="15px 20px 10px 20px"
      borderRadius="10px"
      sx={{
        background: 'linear-gradient(180deg, #171717 -10.17%, rgba(23, 23, 23, 0) 81.03%)',
      }}
      className="player__top-overlay"
    >
      <CopyLinkIconButton videoId={props.videoId} accessID={props.accessId} isOnVideoList={false} />
      <IconButton
        style={{ background: 'white', marginLeft: '10px' }}
        onClick={() => {
          history.push({
            search: queryString.stringify({}),
          })
        }}
      >
        <Close style={{ color: '#7A7A86' }} />
      </IconButton>
    </Box>
  )
}
export default PlayerTopOverlay
