import { gql } from '@apollo/client'
import { AuthContext } from '../providers/AuthStatusProvider'
import { useContext } from 'react'
import CognitoApolloClient from '../clients/CognitoApolloClient'
import IamApolloClient from '../clients/IamApolloClient'

type UseCachedCustomerById =
  | {
      id: string
      email: string
      firstName?: string
      lastName?: string
      profilePhoto?: {
        key?: string
        bucket?: string
      }
    }
  | null
  | undefined
const useCachedCustomerById = (customerId?: string | null): UseCachedCustomerById => {
  const auth = useContext(AuthContext)
  const client = auth.isAuthenticated ? CognitoApolloClient : IamApolloClient

  if (!customerId) {
    return null
  }

  return client.readFragment({
    id: `screensightCustomer:${customerId}`, // The value of the to-do item's cache ID
    fragment: gql`
      fragment SingleCustomer on screensightCustomer {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
      }
    `,
  })
}

export default useCachedCustomerById
