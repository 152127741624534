import React from 'react'
import BillingTypeCard from '../../components/BillingTypeCard'
import { Box, Grid, Typography } from '@mui/material'
import { Control, useWatch } from 'react-hook-form'
import { IBillingForm } from '../../partials/form'
import { useTranslation } from 'react-i18next'

const BillingType = ({
  control,
  handleSetBillingType,
  prices,
}: {
  control: Control<IBillingForm>
  handleSetBillingType: (type: 'month' | 'year') => void
  prices: { year: string; month: string }
}) => {
  const watch = useWatch({ control })
  const { t } = useTranslation('settings')

  return (
    <Box>
      <Typography style={{ fontSize: '18px' }} color="textSecondary">
        {t('billing.purchase.billingTypeTitle')}
      </Typography>
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12} md={6}>
          <BillingTypeCard
            onClick={() => handleSetBillingType('year')}
            price={`$${prices.year}`}
            checked={watch.billingType === 'year'}
            isPopular
            title={t('billing.purchase.annually')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BillingTypeCard
            onClick={() => handleSetBillingType('month')}
            price={`$${prices.month}`}
            checked={watch.billingType === 'month'}
            title={t('billing.purchase.monthly')}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default BillingType
