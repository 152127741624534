import React from 'react'

interface IProps {
  color?: string
}

const FilesWidgetIcon = (props: IProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 1C4.343 1 3 2.343 3 4V20C3 21.657 4.343 23 6 23H18C19.657 23 21 21.657 21 20V10.3281C21 9.53213 20.6836 8.76953 20.1211 8.20703L13.793 1.87891C13.23 1.31641 12.4674 1 11.6719 1H6ZM6 3H11V8C11 9.657 12.343 11 14 11H19V20C19 20.5525 18.5525 21 18 21H6C5.4475 21 5 20.5525 5 20V4C5 3.4475 5.4475 3 6 3ZM13 3.91406L18.0859 9H14C13.4475 9 13 8.5525 13 8V3.91406ZM8 13C7.448 13 7 13.4475 7 14C7 14.5525 7.448 15 8 15H16C16.552 15 17 14.5525 17 14C17 13.4475 16.552 13 16 13H8ZM8 17C7.448 17 7 17.4475 7 18C7 18.5525 7.448 19 8 19H16C16.552 19 17 18.5525 17 18C17 17.4475 16.552 17 16 17H8Z"
        fill={props?.color || '#7A7A86'}
        stroke="#F8F9FB"
        strokeWidth="0.4"
      />
    </svg>
  )
}

export default FilesWidgetIcon
