import { BehaviorSubject, Subscription } from 'rxjs'
import { RecordingChunk } from './RecordingChunk'
import { filter, map, take } from 'rxjs/operators'
import { Map } from 'immutable'
import { RecordingEntity } from './RecordingEntity'
import { Schedulers } from './QueueHandler.types'
import { SchedulerItem } from './SchedulerItem'
import { RecordingNotifyComplete } from './RecordingNotifyComplete'
import { RecordingChunkType, RecordingData, RecordingIds } from './index'
import { RecordingDeleteEntity } from './RecordingDeleteEntity'
import IndexedDB from 'common/services/IndexedDB'
import { captureException } from '@sentry/react'

export class Recording {
  ids: RecordingIds

  title: string
  owner: string
  enableSplashScreen: boolean

  schedulers: Schedulers

  subs = new Subscription()
  chunks = Map<string, RecordingChunk>()

  requests = Map<string, SchedulerItem>()
  entity: RecordingEntity
  entityDelete?: RecordingDeleteEntity
  notifyComplete: RecordingNotifyComplete
  schedulerItemActions = new BehaviorSubject<'CREATE' | 'FINISH' | 'INIT'>('INIT')

  constructor(ids: RecordingIds, data: RecordingData, schedulers: Schedulers) {
    const { entity, notifyComplete } = data

    this.ids = { ...ids }
    this.schedulers = schedulers
    this.title = entity.title
    this.owner = entity.owner
    this.enableSplashScreen = entity.enableSplashScreen

    this.entity = new RecordingEntity(ids, entity, this.schedulers.request)
    this.notifyComplete = new RecordingNotifyComplete(
      ids,
      notifyComplete || {
        owner: this.owner,
        audioCount: 0,
        screenCount: 0,
        enableSplashScreen: this.enableSplashScreen,
      },
      this.schedulers.request,
    )

    this.schedulerItemActions.next('CREATE')

    this.subs.add(
      this.entity.completeObservable().subscribe((ev) => {
        if (ev.type === 'SUCCESS') this.scheduleAllChunks()

        this.notifyComplete.ids.entityId = this.entity.ids.entityId
        this.ids.entityId = this.entity.ids.entityId
        this.schedulerItemActions.next('FINISH')
      }),
    )

    this.subs.add(
      this.notifyComplete.completeObservable().subscribe((ev) => {
        this.schedulerItemActions.next('FINISH')
      }),
    )
  }

  async init() {
    await this.entity.init()
    await this.notifyComplete.init()

    this.entity.schedule()
  }

  scheduleAllChunks() {
    this.log('Schedule all chunks')
    this.chunks.forEach((item) => {
      item.ids.entityId = this.entity.ids.entityId
      item.schedule()
    })
  }

  async startOver() {
    this.log('StartOver detected, waiting for entity...')
    this.chunks.forEach((item) => item.abort())

    const entityStatus = await this.onAllChunksProcessingFinished([this.entity])

    if (entityStatus === 'OK') {
      this.entityDelete = new RecordingDeleteEntity(this.ids, this.schedulers.request)

      this.entityDelete.schedule()

      await this.onAllChunksProcessingFinished([this.entityDelete])
    }

    this.log('Clear IndexedDB')
    await IndexedDB.recordingEntity.where('localId').equals(this.ids.localId).delete()
    await IndexedDB.recordingChunk.where('localId').equals(this.ids.localId).delete()
    await IndexedDB.recordingNotifyComplete.where('localId').equals(this.ids.localId).delete()

    this.cleanUp()
  }

  async addChunk(type: RecordingChunkType, idx: number, data: Blob) {
    const chunk = new RecordingChunk(
      this.ids,
      {
        owner: this.owner,
        type,
        idx,
        chunk: data,
      },
      this.schedulers.upload,
    )
    this.chunks = this.chunks.set(chunk.key, chunk)

    await chunk.init()
    await this.notifyComplete.updateIndexes(type as 'AUDIO' | 'SCREEN', idx)

    if (this.entity.status === 'SUCCESS') {
      chunk.ids.entityId = this.entity.ids.entityId
      chunk.schedule()
    }

    this.schedulerItemActions.next('CREATE')

    this.subs.add(
      chunk.completeObservable().subscribe(() => {
        this.schedulerItemActions.next('FINISH')
      }),
    )
  }

  async finishRecording() {
    this.log('finishRecording detected, wait for entity creating complete...')
    const entityStatus = await this.onAllChunksProcessingFinished([this.entity])
    if (entityStatus !== 'OK') {
      this.log('Entity creating error, returning...')
      // Sentry.io capture error
      captureException(new Error('Recording not uploaded - entityId create failed'), {
        tags: {
          entityId: this.ids.entityId,
          localId: this.ids.localId,
        },
      })
      return
    }

    this.log('Entity created, wait for all chunks...')
    const chunksStatus = await this.onAllChunksProcessingFinished(this.chunks.valueSeq().toArray())
    if (chunksStatus !== 'OK') {
      this.log('Chunks sending error, returning...')
      // Sentry.io capture error
      captureException(new Error('Recording not uploaded - chunks upload failed'), {
        tags: {
          entityId: this.ids.entityId,
          localId: this.ids.localId,
        },
      })

      return
    }

    this.log('Chunks sended, wait for NotifyComplete...')
    this.notifyComplete.schedule()
    const notifyCompleteStatus = await this.onAllChunksProcessingFinished([this.notifyComplete])
    if (notifyCompleteStatus !== 'OK') {
      // Sentry.io capture error
      captureException(new Error('Recording not uploaded - notify complete upload failed'), {
        tags: {
          entityId: this.ids.entityId,
          localId: this.ids.localId,
        },
      })

      this.log('Notify complete error, returning...')
      return
    }

    await this.entity.deleteFromIndexedDb()
    this.cleanUp()
  }

  async onAllChunksProcessingFinished(schedulerItems: SchedulerItem[]) {
    return await this.schedulerItemActions
      .pipe(
        map(() => this.getCurrentRecordingStatus(schedulerItems)),
        filter((action) => action === 'OK' || action === 'ERROR'),
        take(1),
      )
      .toPromise()
  }

  getCurrentRecordingStatus(schedulerItems: SchedulerItem[]) {
    const all = schedulerItems.length
    const processing = schedulerItems.filter(
      (item) => item.status === 'INIT' || item.status === 'SCHEDULED' || item.status === 'UPLOADING',
    ).length

    const success = schedulerItems.filter((item) => item.status === 'SUCCESS').length

    this.log('Current recording tasks status, processing:', processing, ', success:', success, ', all:', all)
    if (success === all) return 'OK'
    else if (success !== all && processing === 0) return 'ERROR'
    else return 'UPLOADING'
  }

  cleanUp() {
    this.subs.unsubscribe()
    this.log('cleanUp')
  }

  log(...args: any[]) {
    console.log(new Date().toISOString(), `[VideoItem ${this.ids.localId}]: `, ...args)
  }
}
