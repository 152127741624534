import * as React from 'react'
import moment from 'moment/moment'
import CreateIcon from '@mui/icons-material/Create'
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material'
import ProfileRoundedImage from '../components/ProfileRoundedImage'
import useVideoDescription from '../common/hooks/useVideoDescription'
import EditTitleForm from '../components/EditTitleForm'
import { theme } from '../../../../common/providers/MuiThemeProvider'
import useGetVideoFragment from '../../../../common/api/hooks/useGetVideoFragment'
import useGetScreensightCustomer from '../../../../common/api/hooks/useGetScreensightCustomer'

interface IVideoDescriptionProps {
  videoId: string | undefined
  customerId: string | undefined
  isOwner: boolean
}
export const VideoDescription = (props: IVideoDescriptionProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const cacheVideo = useGetVideoFragment(props.videoId, '{title, createdAt}')
  const { form } = useVideoDescription(cacheVideo?.title, props.videoId)
  const cacheCustomer = useGetScreensightCustomer(props.customerId, '{profilePhoto, email}')

  return (
    <>
      {props.isOwner && (form.editState.edit || form.editState.update) ? (
        <EditTitleForm
          form={form}
          photoUrl={cacheCustomer?.profilePhoto?.key}
          email={cacheCustomer?.email}
          createdAt={cacheVideo?.createdAt}
        />
      ) : (
        <Box margin={'16px 5px'} display="flex" alignItems="center">
          <ProfileRoundedImage
            name={cacheCustomer?.email || ''}
            width="43px"
            height="43px"
            photoUrl={cacheCustomer?.profilePhoto?.key}
            isPrimaryColor
          />
          <Box width="90%" style={{ pointerEvents: 'all' }}>
            <Tooltip title={props.isOwner ? <CreateIcon /> : null} placement={'bottom-start'}>
              <Typography
                variant="h3"
                onClick={form.enableEditing}
                style={{
                  width: isMobile ? '100%' : '90%',
                  cursor: !props.isOwner ? 'default' : 'auto',
                  pointerEvents: 'all',
                }}
              >
                {cacheVideo?.title}
              </Typography>
            </Tooltip>
            {props.isOwner && (
              <Typography variant="body1" color="secondary">
                {moment(cacheVideo?.createdAt).format('MMMM DD, YYYY ')}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

export default VideoDescription
