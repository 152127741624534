import { useLazyQuery, useMutation } from "@apollo/client";
import {
  BillingInformation,
  BillingSession,
  CancelBillingSubscriptionMutation,
  GetBillingApiAccessKeyQuery,
} from "../../../../../API";
import gql from "graphql-tag";
import {
  getBillingApiAccessKey,
  getBillingInformation,
} from "../../../../../graphql/queries";
import { cancelBillingSubscription } from "../../../../../graphql/mutations";

function useBillingRedirect() {
  const [getBillingAccess, { loading }] = useLazyQuery<
    GetBillingApiAccessKeyQuery,
    BillingSession
  >(gql(getBillingApiAccessKey));

  const [cancelPricePlanToFree, { loading: loadingSubscriptionCancell }] =
    useMutation<CancelBillingSubscriptionMutation, BillingInformation>(
      gql(cancelBillingSubscription),
      {
        refetchQueries: [{ query: gql(getBillingInformation) }],
      }
    );

  const redirectToAttachCreditCard = async (selectedPricePlan?: string) => {
    const { data: getBillingApiAccessKey } = await getBillingAccess();

    if (!getBillingApiAccessKey?.getBillingApiAccessKey) return;

    const {
      AccessKeyId,
      SecretAccessKey,
      AwsRegion,
      ServiceName,
      SessionToken,
    } = getBillingApiAccessKey.getBillingApiAccessKey;

    if (
      AccessKeyId &&
      SecretAccessKey &&
      AwsRegion &&
      ServiceName &&
      SessionToken
    ) {
      window.location.replace(
        `${process.env.REACT_APP_BILLING_APP_URL}/session/${encodeURIComponent(
          AccessKeyId
        )}?SessionToken=${encodeURIComponent(
          SessionToken
        )}&SecretAccessKey=${encodeURIComponent(
          SecretAccessKey
        )}&ServiceName=${encodeURIComponent(
          ServiceName
        )}&AwsRegion=${encodeURIComponent(AwsRegion)}${
          selectedPricePlan
            ? `&pricePlanName=${encodeURIComponent(selectedPricePlan)}`
            : ""
        }`
      );
    }
  };

  const onDowngradeToFreeButtonClick = async () => {
    await cancelPricePlanToFree();
  };

  return {
    redirectToAttachCreditCard,
    loading: loading || loadingSubscriptionCancell,
    onDowngradeToFreeButtonClick,
  };
}

export default useBillingRedirect;
