import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import { useParams } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'
import {
  GetContactQuery,
  GetContactQueryVariables,
  UpdateContactMutation,
  UpdateContactMutationVariables,
} from '../../../../API'
import gql from 'graphql-tag'
import { getContact } from '../../../../graphql/queries'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import { useForm } from 'react-hook-form'
import { INewContactForm } from '../../../../containers/Contacts/NewContactModal/hooks/useNewContactModal'
import { updateContact } from '../../../../graphql/mutations'
import ToastContext from '../../../../common/providers/ToastProvider/ToastContext'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const useSingleContactEditHook = () => {
  const auth = useContext(AuthContext)
  const { t } = useTranslation('contacts')
  const toastContext = useContext(ToastContext)
  const { id: contactId } = useParams() as { id: string }
  const [saving, setSaving] = useState(false)
  const history = useHistory()

  const form = useForm<INewContactForm>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: undefined,
    },
  })

  const { loading: contactQueryLoading, data: contactQueryData } = useQuery<GetContactQuery, GetContactQueryVariables>(
    gql(getContact),
    {
      variables: {
        id: contactId,
      },
      skip: auth.user?.pending || !auth.isAuthenticated,
      client: CognitoApolloClient,
    },
  )

  const [updateContactMutation] = useMutation<UpdateContactMutation, UpdateContactMutationVariables>(
    gql(updateContact),
    {
      client: CognitoApolloClient,
    },
  )

  useEffect(() => {
    if (contactQueryData?.getContact) {
      form.reset({
        email: contactQueryData.getContact.email,
        firstName: contactQueryData.getContact.firstName || '',
        lastName: contactQueryData.getContact.lastName || '',
        phone: contactQueryData.getContact.mobilePhone || '',
      })
    }
  }, [contactQueryData?.getContact])

  const handleEditContact = form.handleSubmit(async (values) => {
    setSaving(true)
    try {
      await updateContactMutation({
        variables: {
          input: {
            id: contactId,
            email: values.email,
            firstName: (values.firstName || '').trim(),
            lastName: (values.lastName || '').trim(),
            mobilePhone: values.phone,
          },
        },
      })
      form.reset({ ...values })
      toastContext.open({ type: 'INFO', text: t('singleContactEdit.contactUpdateSuccess') })
      history.push('/app/contacts/')
    } catch (e: any) {
      toastContext.open({ type: 'ERROR', text: t('singleContactEdit.contactUpdateError') })
    } finally {
      setSaving(false)
    }
  })

  return {
    loading: contactQueryLoading,
    contact: contactQueryData,
    form,
    handleEditContact,
    saving,
  }
}

export default useSingleContactEditHook
