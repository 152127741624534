import * as React from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import CloudFrontImage from '../../../../../../../components/_refactored/CloudFrontImage'
import UserAvatar from '../../../ChannelsSidebar/ui/components/UserAvatar'
import { FilePost, screensightCustomer } from '../../../../../../../API'
import { getUsername } from '../../../../../../../common/utils/getUsername'
import ReactionsList from '../components/PostList/ReactionsList'
import ReadReceipt from '../components/PostList/ReadReceipt'
import { IParticipantData } from '../../../../../common/hooks/useChatParticipants'
import PostActions from '../components/PostList/PostActions'
import './styles.scss'
import VideoEmailArrowLeftIcon from '../../../../../../../common/icons/VideoEmailArrowLeftIcon'
import VideoEmailArrowRightIcon from '../../../../../../../common/icons/VideoEmailArrowRightIcon'
import MailOutlineIcon from '@mui/icons-material/MailOutline'

interface ISinglePostLayoutProps {
  postTimestamp: string
  children: React.ReactNode
  owner?: boolean
  mergedInside?: boolean
  firstMerged?: boolean
  lastMerged?: boolean
  ownerData?: screensightCustomer | null
  isLastPost: boolean
  postId: string
  videoId: string | undefined
  lastSeen?: boolean
  lastUnseen?: boolean
  seenAt?: string | null
  edited?: boolean
  participantData: IParticipantData
  content: string
  richContent: string
  postType?: string | null
  file: FilePost | null | undefined
  videoCommentTimestamp: number
  engagement: any
}

const SinglePostLayout = (props: ISinglePostLayoutProps) => {
  const username = getUsername(props.ownerData?.firstName, props.ownerData?.lastName, props.ownerData?.email)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const ownerName = getUsername(props.ownerData?.firstName, props.ownerData?.lastName, props.ownerData?.email)
  if (props.mergedInside || props.firstMerged) {
    return (
      <Box
        margin={props.owner ? '0px 46px 0px 0px' : '0px 0px 0px 46px'}
        marginBottom={props.isLastPost ? '12px' : '0'}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection={props.owner ? 'row-reverse' : 'row'}
          className="post__layout"
        >
          {props.children}
          <PostActions
            referencePostAuthorId={props.ownerData?.id}
            file={props.file}
            ownerName={ownerName}
            videoId={props.videoId}
            content={props.content}
            richContent={props.richContent}
            postId={props.postId}
            owner={props.owner}
            postType={props.postType}
            videoCommentTimestamp={props.videoCommentTimestamp}
            engagement={props.engagement}
          />
        </Box>
        <ReactionsList owner={props.owner} postId={props.postId} videoId={props.videoId} />
        <ReadReceipt
          lastSeen={props.lastSeen}
          lastUnseen={props.lastUnseen}
          seenAt={props.seenAt}
          partnerData={props.participantData.partner}
          owner={props.owner}
          edited={props.edited}
        />
      </Box>
    )
  }
  return (
    <Box
      display="flex"
      flexDirection={props.owner ? 'row-reverse' : 'row'}
      margin={props.lastMerged ? '15px 0 0 0' : '15px 0'}
    >
      {props?.ownerData?.profilePhoto?.key ? (
        <CloudFrontImage
          image={`public/${props?.ownerData?.profilePhoto?.key}` || ''}
          height={'40'}
          width={'40'}
          imgStyle={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            margin: props.owner ? '0 0 0 6px' : '0 6px 0 0',
            objectFit: 'cover',
          }}
        />
      ) : (
        <UserAvatar
          width="40px"
          height="40px"
          style={{ margin: props.owner ? '0 0 0 6px' : '0 6px 0 0' }}
          label={username}
        />
      )}
      <Box width="calc(100% - 46px)">
        <Box
          display="flex"
          alignItems="center"
          flexDirection={props.owner ? 'row-reverse' : 'row'}
          flexWrap="wrap"
          width="100%"
        >
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
              padding: props.owner ? '0 0 0 10px' : '0 10px 0 0',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '80%',
            }}
          >
            {username}
          </Typography>
          <Typography variant="caption" style={{ color: '#7A7A86', lineHeight: '17px' }}>
            {props.postTimestamp}
          </Typography>
          {props.postType === 'email_video' && (
            <Box
              display="flex"
              alignItems="center"
              margin={props.owner ? '0 6px 0 0' : '0 0 0 6px'}
              flexDirection={props.owner ? 'row' : 'row-reverse'}
            >
              <Box marginBottom="-10px">{props.owner ? <VideoEmailArrowLeftIcon /> : <VideoEmailArrowRightIcon />}</Box>
              <MailOutlineIcon style={{ color: '#7A7A86' }} />
            </Box>
          )}
        </Box>
        <Box>
          <Box
            className="post__layout"
            display="flex"
            alignItems="center"
            flexDirection={props.owner ? 'row-reverse' : 'row'}
            sx={{
              marginRight: (!props.owner && !isMobile && '10px') || 0,
              marginLeft: (props.owner && !isMobile && '10px') || 0,
            }}
          >
            <>
              {props.children}
              <PostActions
                referencePostAuthorId={props.ownerData?.id}
                file={props.file}
                ownerName={ownerName}
                videoId={props.videoId}
                content={props.content}
                richContent={props.richContent}
                postId={props.postId}
                owner={props.owner}
                postType={props.postType}
                videoCommentTimestamp={props.videoCommentTimestamp}
                engagement={props.engagement}
              />
            </>
          </Box>
          {props.postType !== 'video' && props.postType !== 'email_video' && props.postType !== 'shared' && (
            <ReactionsList owner={props.owner} postId={props.postId} videoId={props.videoId} />
          )}
        </Box>
        <ReadReceipt
          lastSeen={props.lastSeen}
          lastUnseen={props.lastUnseen}
          seenAt={props.seenAt}
          partnerData={props.participantData.partner}
          owner={props.owner}
          edited={props.edited}
        />
      </Box>
    </Box>
  )
}

export default SinglePostLayout
