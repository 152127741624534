import React from 'react'
import { Box, IconButton, LinearProgress, Typography } from '@mui/material'
import CloudFrontImage from '../../../../../../../components/_refactored/CloudFrontImage'
import moment from 'moment/moment'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import * as queryString from 'query-string'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'

interface IVideoBoxProps {
  image: string
  title: string
  duration: number
  videoId: string
  postId: string
  createdAt: string
  videoProgress: number
  isSeen: boolean
  videoLocalStorageId: string
}

const VideoPlaylistBox = (props: IVideoBoxProps) => {
  const location = useLocation()
  const history = useHistory()
  const queryStringVideoId = queryString.parse(location.search)?.play
  const queryStringPostId = queryString.parse(location.search)?.postId
  const isGift = props.image.includes('.gif')
  const active = queryStringVideoId === props.videoId && queryStringPostId === props.postId
  return (
    <Box
      data-testid="video-playlist-box"
      id={`chat__playlist-video-${props.postId}`}
      width="100%"
      maxWidth="100%"
      height="66px"
      padding={'8px 0'}
      display="flex"
      bgcolor={active ? 'rgba(36,131,247,0.15)' : 'transparent'}
      onClick={() => {
        if (queryStringVideoId === props.videoId && queryStringPostId === props.postId) return
        history.push({
          search: `?play=${props.videoId}&postId=${props.postId}`,
        })
      }}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: active ? 'rgba(36,131,247,0.15)' : 'rgba(36,131,247,0.05)',
        },
        '& .MuiLinearProgress-root': {
          backgroundColor: '#BCBCC3',
        },
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#7A7A86',
        },
      }}
      position="relative"
    >
      <Box>
        <Box
          width="90px"
          maxWidth="100%"
          height="50px"
          bgcolor="white"
          borderRadius="8px"
          overflow="hidden"
          position="relative"
        >
          <CloudFrontImage
            disableResize={isGift}
            image={props.image}
            width="90"
            height="50"
            imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
          />
          {!props.isSeen && !active && (
            <IconButton
              data-testid="video-playlist-box-play-button"
              style={{
                width: '12px',
                height: '12px',
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                boxShadow: 'none',
                backdropFilter: 'blur(2.61905px)',
                background: 'rgba(36, 131, 247, 0.5)',
                zIndex: 9,
              }}
            >
              <PlayArrowRoundedIcon style={{ color: 'white', fontSize: '12px' }} />
            </IconButton>
          )}
          <Box position="absolute" bgcolor="#7A7A86" borderRadius="4px" padding="0 5px" right="2px" bottom="2px">
            <Typography
              data-testid="video-playlist-box-duration"
              variant="body2"
              style={{ color: theme.palette.background.default, fontWeight: 500, fontSize: '10px' }}
            >
              {moment.utc(props.duration).format(props.duration > 3600000 ? 'HH:mm:ss' : 'mm:ss')}
            </Typography>
          </Box>
        </Box>
        {props.isSeen && (
          <LinearProgress
            data-testid="video-playlist-box-video-progress"
            color="primary"
            variant="determinate"
            value={(props.videoProgress / props.duration) * 100}
            sx={{ width: '100%', height: '3px', marginTop: '3px', borderRadius: '4px' }}
          />
        )}
      </Box>
      {active && (
        <Box
          height="15px"
          position="absolute"
          left="-14px"
          top="50%"
          zIndex={10}
          sx={{ transform: 'translateY(-50%)' }}
          data-testid="video-playlist-box-active-play-button"
        >
          <PlayArrowRoundedIcon
            style={{
              color: theme.palette.primary.main,
              fontSize: '15px',
            }}
          />
        </Box>
      )}
      {!props.isSeen && !active && (
        <Box
          data-testid="video-playlist-box-blue-bar"
          height="50px"
          width="2px"
          borderRadius="4px"
          position="absolute"
          left="-4px"
          top="50%"
          sx={{ transform: 'translateY(-50%)' }}
          bgcolor="primary.main"
          zIndex={10}
        />
      )}
      <Box width="calc(100% - 109px)" marginLeft="10px">
        <Typography
          variant="body1"
          className="video-box__title"
          sx={{ fontSize: '12px', fontWeight: 500, lineHeight: '17px' }}
        >
          {props.title}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className="video-box__created-at"
          sx={{ fontSize: '12px', fontWeight: 400, marginRight: '5px', lineHeight: '17px' }}
        >
          {moment(props.createdAt).format('LL')}
        </Typography>
      </Box>
    </Box>
  )
}

export default VideoPlaylistBox
