import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { Box, Typography } from '@mui/material'
import FormTextField from '../../../../../../../containers/Contacts/FormTextField'
import { isEmail } from 'react-multi-email'
import { useForm } from 'react-hook-form'
import Button from '../../../../../../../components/_refactored/Button'
import { useMutation } from '@apollo/client'
import {
  CreateChannelMutation,
  CreateChannelMutationVariables,
  CreateContactMutation,
  CreateContactMutationVariables,
} from '../../../../../../../API'
import gql from 'graphql-tag'
import { createChannel, createContact } from '../../../../../../../graphql/mutations'
import CognitoApolloClient from '../../../../../../../common/clients/CognitoApolloClient'
import { getUsername } from '../../../../../../../common/utils/getUsername'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'
import { useHistory } from 'react-router-dom'
import { refetchListChannels } from '../../../../../common/redux/store/chat-actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface ICreateNewChatContactModalForm {
  email: string
  firstName?: string
  lastName?: string
}

interface IProps {
  email: string
  partnerId: string
  setCreateContactModalVisible: Dispatch<SetStateAction<boolean>>
}

const CreateNewChatContactModal = (props: IProps) => {
  const auth = useContext(AuthContext)
  const { t } = useTranslation('chat')
  const { t: g } = useTranslation('general')
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const form = useForm<ICreateNewChatContactModalForm>({
    mode: 'onChange',
    defaultValues: {
      email: props.email,
      firstName: '',
      lastName: '',
    },
  })

  const [createContactMutation] = useMutation<CreateContactMutation, CreateContactMutationVariables>(
    gql(createContact),
    {
      client: CognitoApolloClient,
    },
  )

  const [createChannelMutation] = useMutation<CreateChannelMutation, CreateChannelMutationVariables>(
    gql(createChannel),
    {
      client: CognitoApolloClient,
    },
  )

  const handleCreateContact = form.handleSubmit(async (values) => {
    if (!auth.user?.id || !auth.isAuthenticated) return
    try {
      setLoading(true)
      const contactRes = await createContactMutation({
        variables: {
          input: {
            firstName: (values?.firstName || '').trim(),
            lastName: (values?.lastName || '').trim(),
            email: values.email,
          },
        },
      })

      const contact = contactRes.data?.createContact

      const res = await addChannel(props.partnerId, getUsername(contact?.firstName, contact?.lastName, contact?.email))

      props.setCreateContactModalVisible(false)
      history.push(`/app/chat/${res.data?.createChannel?.id || ''}`)
      dispatch(refetchListChannels(auth.user.id))
    } catch (e: any) {
      console.log('Error during creating contact', e)
    } finally {
      setLoading(false)
    }
  })

  const closeModalWithAddingChat = async () => {
    if (!auth.user?.id || !auth.isAuthenticated) return
    const res = await addChannel(props.partnerId, props.email)
    props.setCreateContactModalVisible(false)
    history.push(`/app/chat/${res?.data?.createChannel?.id || ''}`)
    dispatch(refetchListChannels(auth.user.id))
  }

  const addChannel = async (customerId: string, name: string) => {
    return await createChannelMutation({
      variables: {
        input: {
          participants: [
            {
              customerId: auth.user?.id || '',
              name: getUsername(auth.user?.firstName, auth.user?.lastName, auth.user?.email),
            },
            {
              customerId: customerId,
              name: name,
            },
          ],
        },
      },
    })
  }

  return (
    <Box
      width="380px"
      boxShadow="0px 1px 11px rgba(0, 0, 0, 0.14)"
      padding="20px"
      bgcolor="background.default"
      borderRadius="10px"
      style={{ position: 'absolute', left: '50px', top: '50px', zIndex: 1000 }}
    >
      <Typography variant="h6" align="center" style={{ fontSize: '18px', fontWeight: 500, marginBottom: '20px' }}>
        {t('newContactModal.title')}
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" style={{ fontSize: '15px' }}>
        {t('newContactModal.subtitle')}
      </Typography>
      <FormTextField
        fieldName="firstName"
        form={form}
        topLabel={g('common.firstName')}
        rules={{
          validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
        }}
      />
      <FormTextField
        fieldName="lastName"
        form={form}
        topLabel={g('common.lastName')}
        rules={{
          validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
        }}
      />
      <FormTextField
        fieldName="email"
        form={form}
        topLabel={g('common.email')}
        rules={{
          required: g('common.requiredField'),
          validate: (value: string) => (isEmail(value) ? true : g('common.incorrectEmail')),
        }}
      />
      <Box display="flex" justifyContent="center">
        <Box display="flex" justifyContent="space-between" alignItems="center" maxWidth="98%">
          <Button
            sx={{ width: 'fit-content', marginRight: '15px' }}
            variant="outlined"
            color="secondary"
            disabled={loading}
            onClick={closeModalWithAddingChat}
          >
            {g('common.cancelBtn')}
          </Button>
          <Button
            sx={{ width: 'fit-content' }}
            variant="contained"
            color="primary"
            onClick={handleCreateContact}
            loading={loading}
          >
            {g('common.addContactBtn')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default CreateNewChatContactModal
