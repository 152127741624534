import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import { useMutation } from '@apollo/client'
import { UpdateCustomerMutation, UpdateCustomerMutationVariables } from '../../../../API'
import gql from 'graphql-tag'
import { updateCustomer } from '../../../../graphql/mutations'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import ToastContext from '../../../../common/providers/ToastProvider/ToastContext'
import { useDropzone } from 'react-dropzone'
import { Storage } from '@aws-amplify/storage'
import { dataURItoBlob } from '../../../../common/utils/convertBase64ToBlob'
import { v4 } from 'uuid'
import Auth from '@aws-amplify/auth'
import awsmobile from '../../../../aws-exports'
import { useTranslation } from 'react-i18next'

const useBranding = () => {
  const auth = useContext(AuthContext)
  const { open } = useContext(ToastContext)
  const [image, setImage] = useState<string | ArrayBuffer | null>('')
  const [imageFile, setImageFile] = useState<null | File>(null)
  const [isCropperVisible, setIsCropperVisible] = useState(false)
  const [storageImg, setStorageImg] = useState<string | null>(null)
  const [removePhotoIconVisible, setRemovePhotoIconVisible] = useState(false)
  const [removeModalVisible, setRemoveModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [photoDeleting, setPhotoDeleting] = useState(false)
  const cropperRef = useRef<HTMLImageElement>(null)
  const { t } = useTranslation('settings')
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/jpeg, .svg',
  })
  const [splashScreenMutationLoading, setSplashScreenMutationLoading] = useState(false)

  const [updateUserMutation] = useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
    gql(updateCustomer),
    {
      client: CognitoApolloClient,
    },
  )

  useEffect(() => {
    if (!acceptedFiles?.length) return
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(acceptedFiles[0])
    setIsCropperVisible(true)
  }, [acceptedFiles])

  useEffect(() => {
    const getImage = async () => {
      if (!auth.user?.brandLogo?.key) {
        setImage(null)
        return
      }
      const _img = await Storage.get(`${auth.user.brandLogo.key}`)
      if (_img) {
        setImage(_img as string)
        setStorageImg(_img as string)
      }
    }
    getImage()
  }, [auth.user?.brandLogo?.key])

  const handleCloseCropperModal = async () => {
    if (!auth.user?.brandLogo?.key) {
      setImage(null)
      setImageFile(null)
    } else {
      const _img = await Storage.get(`${auth.user.brandLogo.key}`)
      if (_img) {
        setImage(_img as string)
      }
    }
    setIsCropperVisible(false)
  }

  const handleImageCroppingSubmit = async () => {
    const imageElement: any = cropperRef?.current
    const cropper: any = imageElement?.cropper
    if (typeof cropper !== 'undefined') {
      setIsCropperVisible(false)
      const blob = cropper.getCroppedCanvas().toDataURL('image/png', 0.9)
      setImage(blob)
      const file = dataURItoBlob(blob)
      if (file.size > 3145728) {
        open({ type: 'ERROR', text: t('branding.logoSizeError') })
        return
      }
      await uploadLogo(file as File)
    }
  }

  const uploadLogo = async (imageFile: File) => {
    try {
      setLoading(true)
      // @ts-ignore
      const S3Img: { key: string } = imageFile
        ? await Storage.put(`protected/${auth.user?.id}/branding/${v4()}.png`, imageFile, {
            contentType: 'image/png',
            useAccelerateEndpoint: true,
          })
        : null

      const user = await Auth.currentAuthenticatedUser()
      await updateUserMutation({
        variables: {
          input: {
            id: user.attributes.sub,
            brandLogo: imageFile && S3Img?.key ? { key: S3Img.key, bucket: awsmobile.aws_user_files_s3_bucket } : null,
          },
        },
      })
      await auth.updateUser({
        ...auth.user,
        brandLogo: imageFile && S3Img?.key ? { key: S3Img.key, bucket: awsmobile.aws_user_files_s3_bucket } : undefined,
      })
      open({ type: 'INFO', text: t('branding.logoUpdateSuccess') })
    } catch (e: any) {
      console.log('Error during updating branding logo', e)
    } finally {
      setLoading(false)
    }
  }

  const handleSplashScreenVisibility = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setSplashScreenMutationLoading(true)
      if (!auth.user?.id || !auth.user.settings) return
      const updatedUserRes = await updateUserMutation({
        variables: {
          input: {
            id: auth.user?.id,
            settings: {
              notifyEmailWatched: auth.user.settings.notifyEmailWatched,
              notifyEmailComment: auth.user.settings.notifyEmailComment,
              timezone: auth.user.settings.timezone,
              enableSplashScreen: event.target.checked,
            },
          },
        },
      })
      await auth.updateUser({
        ...auth.user,
        settings: {
          ...auth.user.settings,
          enableSplashScreen: updatedUserRes.data?.updateCustomer?.settings?.enableSplashScreen as boolean,
        },
      })
    } catch (e: any) {
      console.log('Error during updating splash screen settings', e)
    } finally {
      setSplashScreenMutationLoading(false)
    }
  }

  const removeImage = async () => {
    if (!auth.user?.id || !auth.user.brandLogo?.key) return
    try {
      setPhotoDeleting(true)
      await Promise.all([
        Storage.remove(auth.user.brandLogo?.key),
        updateUserMutation({
          variables: {
            input: { id: auth.user?.id, brandLogo: null },
          },
        }),
        auth.updateUser({ ...auth.user, pending: false, brandLogo: undefined }),
      ])
      open({ type: 'INFO', text: t('branding.logoDeleteSuccess') })
      setRemoveModalVisible(false)
    } catch (e: any) {
      console.error(e)
      open({ type: 'ERROR', text: t('branding.logoDeleteError') })
    } finally {
      setPhotoDeleting(false)
    }
  }

  const showRemoveIcon = () => {
    setRemovePhotoIconVisible(true)
  }

  const hideRemoveIcon = () => {
    setRemovePhotoIconVisible(false)
  }

  const showDeletePhotoModal = () => {
    setRemoveModalVisible(true)
  }

  const hideDeletePhotoModal = () => {
    setRemoveModalVisible(false)
  }

  return {
    handleSplashScreenVisibility,
    auth,
    splashScreenMutationLoading,
    dropzone: {
      getRootProps: image ? () => null : getRootProps,
      getInputProps,
    },
    image,
    cropperRef,
    handleCloseCropperModal,
    handleImageCroppingSubmit,
    isCropperVisible,
    showRemoveIcon,
    hideRemoveIcon,
    removePhotoIconVisible,
    photoUpdating: loading,
    disabled: storageImg === image,
    removeModalVisible,
    hideDeletePhotoModal,
    showDeletePhotoModal,
    photoDeleting,
    removeImage,
  }
}

export default useBranding
