import * as React from 'react'
import { Box } from '@mui/material'

const FastForward = () => {
  return (
    <Box display="flex">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.4497 9.65128L10.4497 13.3995L15.3995 8.44975L10.4497 3.50001L10.4497 7.65702C10.4771 8.75196 10.4727 8.57305 10.4497 9.65128Z"
          fill="white"
        />
        <path
          d="M12 8.0241C12 7.73466 11.765 7.49782 11.4765 7.52113C10.3754 7.61009 9.31205 7.97856 8.38879 8.59546C7.31987 9.30969 6.48675 10.3249 5.99478 11.5126C5.50281 12.7003 5.37409 14.0072 5.6249 15.2681C5.8757 16.529 6.49476 17.6872 7.40381 18.5962C8.31285 19.5053 9.47104 20.1243 10.7319 20.3751C11.9928 20.6259 13.2997 20.4972 14.4874 20.0052C15.6752 19.5133 16.6903 18.6801 17.4046 17.6112C18.0215 16.688 18.3899 15.6246 18.4789 14.5236C18.5022 14.235 18.2654 14 17.9759 14C17.6865 14 17.4544 14.2352 17.4267 14.5233C17.3405 15.4168 17.0347 16.2781 16.533 17.0289C15.934 17.9254 15.0825 18.6242 14.0863 19.0369C13.0901 19.4495 11.994 19.5575 10.9364 19.3471C9.87885 19.1367 8.90743 18.6175 8.14497 17.855C7.38252 17.0926 6.86328 16.1212 6.65292 15.0636C6.44256 14.0061 6.55053 12.9099 6.96316 11.9137C7.3758 10.9175 8.07458 10.066 8.97112 9.46698C9.72195 8.9653 10.5832 8.6595 11.4767 8.57335C11.7648 8.54557 12 8.31354 12 8.0241Z"
          fill="white"
          stroke="white"
          strokeWidth="0.5"
        />
      </svg>
    </Box>
  )
}
export default FastForward
