import React, { useContext } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { AuthContext } from '../../../../../providers/AuthStatusProvider'
import { useLocation } from 'react-router'
import GuestMenu from '../../blocks/GuestMenu'
import MobileMenu from '../../blocks/MobileMenu'
import DesktopMenu from '../../blocks/DesktopMenu'

const Menu = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const auth = useContext(AuthContext)
  const location = useLocation()

  if (auth.isInitialized && !auth.isAuthenticated) {
    if (location.pathname.includes('chat')) {
      return <GuestMenu />
    }
    return <div />
  }

  if (isMobile) return <MobileMenu />

  return <DesktopMenu />
}

export default Menu
