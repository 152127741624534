import * as React from 'react'
import { Box } from '@mui/material'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import videojs, { VideoJsPlayer } from 'video.js'
import useVideoPlayerProgress from '../../../common/hooks/useVideoPlayerProgress.hook'
import SkipVideoButtons from '../../../../../../../../../../../components/VideoItem/SkipVideoButtons'
import { AuthContext } from '../../../../../../../../../../../common/providers/AuthStatusProvider'
import useVideoAnalytics from '../../../common/hooks/useVideoAnalytics'
import 'video.js/dist/video-js.css'
import './video.scss'

interface IVideoPlayerProps {
  videoJsOptions: videojs.PlayerOptions
  onPlayerReady?: (player: VideoJsPlayer) => void
  videoId: string
  visible?: boolean
  postId?: string
  videoOwnerId?: string
  playerRef: React.MutableRefObject<VideoJsPlayer | null>
}

const VideoPlayer = (props: IVideoPlayerProps) => {
  const { videoJsOptions } = props
  const auth = useContext(AuthContext)
  const [playerReady, setPlayerReady] = useState(false)
  const videoNode = useRef(null)
  const video = document.querySelector(props?.postId ? `.video-js-chat-video-${props.postId} .video-js` : '.video-js')
  const fullScreenBtn = video?.querySelector(`.vjs-fullscreen-control`)

  // hook which handles video player progress & autoplay
  useVideoPlayerProgress(props?.playerRef, props.videoId, playerReady)

  // hook which handles video player analytics events
  const { onPlayerReady } = useVideoAnalytics(
    props.videoId,
    auth.applicationConfiguration,
    props?.playerRef,
    props?.videoOwnerId || '',
  )

  // handle keyboard events
  const skipVideo = useCallback(
    (event: KeyboardEvent | null, interval: number) => {
      if (!props?.playerRef?.current || !video) return
      event?.preventDefault()
      if (!event) {
        return props?.playerRef.current?.currentTime(props?.playerRef.current?.currentTime() + interval)
      }
      switch (event?.code) {
        case 'ArrowLeft':
          return props?.playerRef.current?.currentTime(props?.playerRef.current?.currentTime() - 5)
        case 'ArrowRight':
          return props?.playerRef.current?.currentTime(props?.playerRef.current?.currentTime() + 5)
        case 'Space':
          return props?.playerRef.current?.paused()
            ? props?.playerRef.current?.play()
            : props?.playerRef.current?.pause()
        default:
          return
      }
    },
    [props?.playerRef?.current, video],
  )

  // set up player on mount
  useEffect(() => {
    if (!videoNode.current || props?.playerRef.current || !videoJsOptions) return
    let player: VideoJsPlayer | null = (props.playerRef.current = videojs(videoNode.current, videoJsOptions, () => {
      if (!player) return
      props.onPlayerReady && props.onPlayerReady(player)
      onPlayerReady(player)
      setPlayerReady(true)
    }))
  }, [videoNode, videoJsOptions])

  // clear player on unmount
  useEffect(() => {
    if (!props?.playerRef?.current) return
    const player = props?.playerRef.current
    return () => {
      if (player) {
        player.dispose()
        props.playerRef.current = null
      }
    }
  }, [props?.playerRef])

  useEffect(() => {
    if (!video) return
    if (video) {
      video.addEventListener('keydown', skipVideo as EventListener)
    }
    return () => {
      if (video) {
        video.removeEventListener('keydown', skipVideo as EventListener)
      }
    }
  }, [skipVideo, props?.postId, video])

  useEffect(() => {
    if (!video || !fullScreenBtn) return
    fullScreenBtn.addEventListener('click', () => {
      ;(fullScreenBtn as HTMLElement).blur()
      ;(video as HTMLElement).focus()
    })
  }, [video, fullScreenBtn])

  return (
    <Box className="c-player__screen" data-vjs-player="true">
      <SkipVideoButtons visible={true} skipVideo={skipVideo} />
      <video ref={videoNode} className="video-js"></video>
    </Box>
  )
}

export default VideoPlayer
