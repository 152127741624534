import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import { useChatContext, useChatDispatch } from '../../contexts/ChatContext'
import { useMutation } from '@apollo/client'
import { CreatePostMutation, CreatePostMutationVariables, S3Object, VideoStatus } from '../../../../../../../../API'
import gql from 'graphql-tag'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import { createPost } from '../../../../../../../../graphql/mutations'
import { useParams } from 'react-router'
import useUpdateChannelsCache from './useUpdateChannelsCache'
import moment from 'moment/moment'
import { setChatPlayerPaused } from '../../../../../../common/redux/store/chat-actions'
import { useDispatch } from 'react-redux'

const useSendCommentPost = () => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const chatDispatcher = useChatDispatch()
  const chatContext = useChatContext()
  const dispatch = useDispatch()
  const [updateChannelLatestAt] = useUpdateChannelsCache()
  const customerIdToUse = auth.isAuthenticated
    ? auth.user?.id
    : sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId') || ''

  const [createPostMutation] = useMutation<CreatePostMutation, CreatePostMutationVariables>(gql(createPost), {
    client: !auth.user?.pending && auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    update(cache, { data }) {
      cache.modify({
        fields: {
          listPostsByChannel(existingItems = {}) {
            const newReplyToCommentPostRef = cache.writeFragment({
              data: data?.createPost,
              fragment: gql`
                fragment newReplyToCommentPost on ScreensightPost {
                  id
                  channelId
                  accessId
                  customerId
                  postType
                  video
                  comment {
                    id
                    customerId
                    customerMeta
                    videoId
                    channelId
                    parentId
                    content
                    richContent
                    videoTimestamp
                    resolved
                    replyCount
                    parentCreatedAt
                    createdAt
                    updatedAt
                    replies
                  }
                  emailMessage
                  text
                  file
                  engagement
                  referencePostId
                  referencePost {
                    id
                    channelId
                    accessId
                    customerId
                    postType
                    video {
                      id
                      customerId
                      customerMeta
                      channelId
                      title
                      searchTitle
                      status
                      fileObject
                      filePresignedUrl
                      duration
                      name
                      thumbnailObject
                      accessId
                      accessType
                      password
                      viewsCountTotal
                      viewsCountUnique
                      viewsList
                      commentsCount
                      width
                      height
                      comments
                      updatedAt
                      createdAt
                      uploadedVideo
                    }
                    comment
                    emailMessage
                    text
                    file
                    readHorizon
                    createdAt
                    updatedAt
                    engagement
                    referencePostId
                    referencePost
                    postTempId
                    reactions
                  }
                  readHorizon
                  reactions
                  createdAt
                  updatedAt
                }
              `,
            })
            return {
              ...existingItems,
              items: [newReplyToCommentPostRef, ...existingItems.items],
            }
          },
        },
      })
    },
  })

  const sendCommentPost = async (
    content: string,
    richContent: string,
    videoId: any,
    accessId: string | undefined,
    videoTitle: string,
    videoTimestamp: number,
    thumbnailObject: S3Object,
    videoPlayerEditor?: boolean | null,
  ) => {
    if (videoTimestamp) {
      dispatch(setChatPlayerPaused(false))
    }
    if (!videoPlayerEditor) {
      chatDispatcher({ type: 'setResponseBoxData', value: undefined })
    }

    const tempDate = moment(new Date()).add(1, 's').toISOString()

    const res = await createPostMutation({
      variables: {
        input: {
          comment: {
            channelId,
            content,
            richContent,
            videoId: videoId,
            videoTimestamp,
            customerId: customerIdToUse,
          },
        },
      },
      optimisticResponse: {
        createPost: {
          __typename: 'screensightPost',
          id: 'temp-id-id',
          channelId,
          accessId: null,
          customerId: auth.user?.id || sessionStorage.getItem('guestCustomerId') || '',
          postType: 'comment',
          video: null,
          comment: {
            __typename: 'screensightVideoComment',
            id: 'temp-comment-id',
            customerId: auth.user?.id || sessionStorage.getItem('guestCustomerId') || '',
            customerMeta: null,
            videoId: videoId,
            channelId,
            parentId: null,
            content: content,
            richContent: richContent,
            videoTimestamp: videoTimestamp,
            resolved: null,
            replyCount: null,
            parentCreatedAt: null,
            createdAt: tempDate,
            updatedAt: tempDate,
            replies: null,
          },
          emailMessage: null,
          text: null,
          file: null,
          engagement: null,
          referencePostId: null,
          referencePost: {
            __typename: 'screensightPost',
            id: 'temp-reference-id',
            channelId,
            accessId: null,
            customerId: auth.user?.id || sessionStorage.getItem('guestCustomerId') || '',
            postType: 'video',
            video: {
              __typename: 'screensightVideo',
              id: videoId,
              customerId: chatContext.responseBoxData?.customerId || '',
              customerMeta: null,
              channelId,
              title: videoTitle,
              searchTitle: null,
              status: VideoStatus.ok,
              duration: null,
              name: null,
              fileObject: null,
              filePresignedUrl: null,
              thumbnailObject: thumbnailObject,
              accessId: accessId,
              accessType: null,
              password: null,
              viewsCountTotal: null,
              viewsCountUnique: null,
              viewsList: null,
              commentsCount: null,
              width: null,
              height: null,
              comments: null,
              updatedAt: tempDate,
              createdAt: tempDate,
              uploadedVideo: null,
            },
            comment: null,
            emailMessage: null,
            text: null,
            file: null,
            readHorizon: null,
            engagement: null,
            referencePostId: null,
            referencePost: null,
            postTempId: null,
            reactions: null,
            createdAt: tempDate,
            updatedAt: tempDate,
          },
          postTempId: null,
          reactions: null,
          readHorizon: null,
          createdAt: tempDate,
          updatedAt: tempDate,
        },
      },
    })
    if (res.data?.createPost?.channelId && res.data.createPost?.createdAt) {
      updateChannelLatestAt(res.data?.createPost?.channelId, res.data.createPost?.createdAt)
    }
  }

  return [sendCommentPost]
}

export default useSendCommentPost
