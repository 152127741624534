import React, { memo, useContext } from 'react'
import PatentContext from 'common/providers/PatentProvider/PatentContext'
import { Typography } from '@mui/material'

interface IPatentNote {
  styles?: React.CSSProperties
}

const PatentNote = memo((props: IPatentNote) => {
  const { text, redirectTo } = useContext(PatentContext)

  const redirect = () => {
    if (redirectTo !== '') {
      window.location.href = redirectTo
    }
  }

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      style={{ textAlign: 'center', cursor: 'pointer', padding: '0 12px', fontSize: '12px', ...props?.styles }}
      onClick={redirect}
    >
      {text}
    </Typography>
  )
})

export default PatentNote
