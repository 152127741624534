import * as React from 'react'
import { Box, IconButton } from '@mui/material'
import FileThumbnail from '../PostList/FileThumbnail'
import Typography from '@mui/material/Typography'
import { Close } from '@mui/icons-material'
import { useChatContext, useChatDispatch } from '../../../common/contexts/ChatContext'

const FileUploadResponseBox = () => {
  const chatContext = useChatContext()
  const chatDispatcher = useChatDispatch()

  return (
    <Box
      height="60px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="0 10px 0 0"
      margin="0 10px"
    >
      <Box
        padding="10px"
        borderRadius="10px"
        height="50px"
        bgcolor="primary.main"
        display="flex"
        maxWidth="calc(100% - 40px)"
      >
        {chatContext?.responseBoxData?.file && (
          <FileThumbnail
            isImage={chatContext.responseBoxData.fileType?.includes('image')}
            isPdf={chatContext.responseBoxData.fileType?.includes('pdf')}
            isDoc={
              chatContext.responseBoxData.fileType?.includes('doc') ||
              chatContext.responseBoxData.fileType?.includes('word')
            }
            imageSource={URL.createObjectURL(chatContext.responseBoxData.file)}
          />
        )}
        <Box maxWidth="calc(100%  - 35px)" margin="auto 0">
          <Typography
            variant="body1"
            sx={{
              fontSize: '12px',
              color: '#FFF',
              marginLeft: '8px',
              maxWidth: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {chatContext.responseBoxData?.fileName}
          </Typography>
        </Box>
      </Box>
      <Box width="40px">
        <IconButton
          style={{ height: '32px', width: '32px', boxShadow: 'none' }}
          onClick={() => {
            chatDispatcher({
              type: 'setResponseBoxData',
              value: undefined,
            })
          }}
        >
          <Close />
        </IconButton>
      </Box>
    </Box>
  )
}

export default FileUploadResponseBox
