import * as React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SupportChatIcon from '../../../../../../../common/icons/SupportChatIcon'

const EmptySupportChat = () => {
  const { t } = useTranslation('support')
  const theme = useTheme()

  return (
    <Box
      maxWidth="325px"
      display="flex"
      flexDirection="column"
      alignSelf="center"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        borderRadius="50%"
        bgcolor="background.paper"
        width="67px"
        height="67px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <SupportChatIcon />
      </Box>
      <Typography
        variant="body2"
        style={{ color: theme.palette.text.secondary, textAlign: 'center', paddingTop: '20px' }}
      >
        {t('chatBox.initialBgMessage')}
      </Typography>
    </Box>
  )
}

export default EmptySupportChat
