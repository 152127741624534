import { useMutation } from '@apollo/client'
import { DeleteReactionMutationVariables } from '../../../../../../../../API'
import gql from 'graphql-tag'
import { deleteReaction as deleteMutation } from '../../../../../../../../graphql/mutations'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import { DeleteReactionMutation, screensightReaction } from '../api/types'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import useWriteReactionFragment from './useWriteReactionFragment'

/*
 * This hook is used to delete a reaction from a post
 * It will remove reaction from cache fragment
 * returns
 */
const useDeleteReaction = () => {
  const auth = useContext(AuthContext)
  const client = auth.isAuthenticated ? CognitoApolloClient : IamApolloClient
  const [updateReactionCacheFragment] = useWriteReactionFragment()

  const [deleteReactionMutation] = useMutation<DeleteReactionMutation, DeleteReactionMutationVariables>(
    gql(deleteMutation),
    {
      client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    },
  )

  /*
   * This function is used to delete a reaction from a post
   * It will remove reaction from cache fragment
   * @param postId - id of the post
   * @param unicode - unicode of the reaction
   * @param videoTimestamp - timestamp of the video. If not provided, should be set to 0 (optional)
   * @param onlyCache - if true, it will only update cache (optional)
   * @param customerId - id of the customer. If not provided, default customerId is equal to currentUserId (optional)
   */
  const deleteReaction = async ({
    postId,
    unicode,
    videoTimestamp = 0,
    onlyCache,
    customerId,
  }: {
    postId: string
    unicode: string
    videoTimestamp?: number
    onlyCache?: boolean
    customerId?: string
  }) => {
    /*
     * variable to use for customerId. If user is not authenticated, we will use guestCustomerId
     */
    const customerIdToUse = customerId
      ? customerId
      : auth.user?.id || sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId') || ''

    const cachedReactions: any = client.cache.readFragment({
      id: `screensightPost:${postId}`,
      fragment: gql`
        fragment PostReaction3 on screensightPost {
          reactions {
            items {
              unicode
              videoTimestamp
              customerId
              postId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      `,
    })
    if (!onlyCache) {
      await deleteReactionMutation({
        variables: {
          input: {
            postId: postId,
            customerId: customerIdToUse,
            unicode: unicode,
            videoTimestamp: videoTimestamp || 0,
          },
        },
      })
    }
    if (!cachedReactions) return
    updateReactionCacheFragment(postId, [
      ...cachedReactions.reactions.items?.filter(
        (item: screensightReaction) =>
          !(item.unicode === unicode && item.customerId === customerIdToUse && item.videoTimestamp === videoTimestamp),
      ),
    ])
  }
  return [deleteReaction]
}

export default useDeleteReaction
