import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../pages/Chat/common/redux/store/store'
import { Helmet } from 'react-helmet'

const PageTitle = (props: { name?: string }) => {
  const unreadChannelsCount = useSelector((state: RootState) => state.chat.unreadChannelsCount)

  if (props.name === 'ScreenSight' || props.name === '') {
    return (
      <Helmet defer={false}>
        <title>
          {unreadChannelsCount
            ? unreadChannelsCount > 9
              ? `(9+) ScreenSight`
              : `(${unreadChannelsCount}) ScreenSight`
            : 'ScreenSight'}
        </title>
      </Helmet>
    )
  }

  return (
    <Helmet defer={false}>
      <title>
        {unreadChannelsCount
          ? unreadChannelsCount > 9
            ? `(9+) ${props.name} | ScreenSight`
            : `(${unreadChannelsCount}) ${props.name} | ScreenSight`
          : props.name
          ? `${props.name} | ScreenSight`
          : 'ScreenSight'}
      </title>
    </Helmet>
  )
}

export default PageTitle
