import React from 'react'
import { Box, Typography } from '@mui/material'
import { Verify } from '../../../common/config'
import { useTranslation } from 'react-i18next'
import IconScreensightLogo from '../../../../../../../common/icons/ScreensightLogo'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import { useLocation } from 'react-router'

const UserAside = () => {
  const { t } = useTranslation('auth')
  const location = useLocation<{ email: string; password?: string }>()

  return (
    <Box width="50%" position="relative" display="flex" alignItems="center">
      <Box width="100%" display="flex" justifyContent="center">
        <Box width="400px" maxWidth="90%">
          <IconScreensightLogo width="175px" height="25px" />
          <Typography variant="h2" style={{ margin: '15px 0 40px 0', fontSize: '30px' }}>
            {t('verify.userAside.heading')}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '15px', marginBottom: '24px' }} color="textSecondary">
            {t('verify.userAside.checkEmailPart1')}{' '}
            <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>{location?.state?.email}</span>{' '}
            {t('verify.userAside.checkEmailPart2')}
          </Typography>
          <Verify.Shared.UserForm />
        </Box>
      </Box>
    </Box>
  )
}

export default UserAside
