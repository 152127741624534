import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  ListVideosByOwnerQuery,
  ListVideosByOwnerQueryVariables,
  ModelSortDirection,
  screensightVideo,
  SearchablescreensightVideoSortableFields,
  SearchableSortDirection,
  SearchScreensightVideosQuery,
  SearchScreensightVideosQueryVariables,
} from '../../../../../API'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { listVideosByOwner, searchScreensightVideos } from '../../../../../graphql/queries'
import CognitoApolloClient from '../../../../../common/clients/CognitoApolloClient'
import { AuthContext } from '../../../../../common/providers/AuthStatusProvider'
import { useForm } from 'react-hook-form'
import debounce from 'lodash/debounce'
import { getUsername } from '../../../../../common/utils/getUsername'
import { useChatDispatch } from '../../../ui/components/ChatContent/common/contexts/ChatContext'
import ToastContext from '../../../../../common/providers/ToastProvider/ToastContext'
import { useTranslation } from 'react-i18next'

const useModalLibraryList = (onClose: () => void) => {
  const auth = useContext(AuthContext)
  const chatContextDispatcher = useChatDispatch()
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [videos, setVideos] = useState<screensightVideo[]>([])
  const toastContext = useContext(ToastContext)
  const [videosLoaded, setVideosLoaded] = useState(false)
  const { t } = useTranslation('library')
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
  })
  const [selectedVideoId, setSelectedVideoId] = useState<string>()
  const handleSelectVideo = (videoId: string) => {
    setSelectedVideoId(videoId)
  }
  const {
    data: videoListData,
    refetch: refetchVideoListData,
    error: VideoListDataError,
    loading: videoListLoading,
  } = useQuery<ListVideosByOwnerQuery, ListVideosByOwnerQueryVariables>(gql(listVideosByOwner), {
    variables: {
      limit: 10,
      sortDirection: ModelSortDirection.DESC,
      customerId: auth.user?.id || '',
    },
    skip: auth.user?.pending || !auth.isAuthenticated || !!searchPhrase.length,
    client: CognitoApolloClient,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  const {
    loading: videoSearchListLoading,
    error: videoSearchListError,
    data: videoSearchListData,
    refetch: refetchVideoSearchListData,
  } = useQuery<SearchScreensightVideosQuery, SearchScreensightVideosQueryVariables>(gql(searchScreensightVideos), {
    variables: {
      limit: 10,
      sort: [{ field: SearchablescreensightVideoSortableFields.createdAt, direction: SearchableSortDirection.desc }],
      filter: {
        customerId: { eq: auth.user?.id },
        title: { matchPhrasePrefix: searchPhrase },
      },
    },
    skip: auth.user?.pending || !auth.isAuthenticated || !searchPhrase.length,
    client: CognitoApolloClient,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (VideoListDataError || videoSearchListError) {
      console.log('Error during loading videos in library modal', VideoListDataError || videoSearchListError)
      toastContext.open({ type: 'ERROR', text: t('videoList.listLoadingError') })
    }
  }, [VideoListDataError, videoSearchListError])

  const updateVideosByQuery = async () => {
    if (!videoListData?.listVideosByOwner?.items) return
    const fetchedVideos = [...videoListData?.listVideosByOwner?.items]
    // @ts-ignore
    setVideos((prevState) =>
      // @ts-ignore
      Object.values([...prevState, ...fetchedVideos].reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})),
    )

    setVideosLoaded(true)
  }

  const updateVideosBySearch = async () => {
    if (!videoSearchListData?.searchScreensightVideos?.items) return
    const fetchedVideos = [...videoSearchListData?.searchScreensightVideos?.items]
    // @ts-ignore
    setVideos((prevState) =>
      // @ts-ignore
      Object.values([...prevState, ...fetchedVideos].reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})),
    )
  }

  useEffect(() => {
    if (!searchPhrase) {
      updateVideosByQuery()
    } else {
      updateVideosBySearch()
    }
  }, [searchPhrase, videoListData?.listVideosByOwner?.items, videoSearchListData?.searchScreensightVideos?.items])

  const getNextVideos = async () => {
    if (searchPhrase) {
      if (!videoSearchListData?.searchScreensightVideos?.nextToken) return
      await refetchVideoSearchListData({ nextToken: videoSearchListData.searchScreensightVideos.nextToken, limit: 10 })
      return
    } else {
      if (!videoListData?.listVideosByOwner?.nextToken) return
      await refetchVideoListData({ nextToken: videoListData.listVideosByOwner.nextToken, limit: 10 })
    }
  }

  const onSubmit = handleSubmit(async (values) => {
    const selectedVideoData = videos?.find((item) => item?.id === selectedVideoId)

    chatContextDispatcher({
      type: 'setResponseBoxData',
      value: {
        type: 'video',
        videoId: selectedVideoId,
        title: selectedVideoData?.title,
        author: getUsername(
          selectedVideoData?.customerMeta?.firstName,
          selectedVideoData?.customerMeta?.lastName,
          selectedVideoData?.customerMeta?.email,
        ),
        photoKey: selectedVideoData?.thumbnailObject?.key,
      },
    })
    onClose()
  })

  const hasMore = useMemo(() => {
    if (searchPhrase) {
      return (videoSearchListData?.searchScreensightVideos?.items?.length || 0) === 10
    } else {
      return (videoListData?.listVideosByOwner?.items?.length || 0) === 10
    }
  }, [searchPhrase, videoListData?.listVideosByOwner?.items, videoSearchListData?.searchScreensightVideos?.items])

  const debounceCallback = useCallback(
    debounce((text: string) => {
      setVideos([])
      if (text) {
        setSearchPhrase(text)
      } else {
        setSearchPhrase('')
      }
    }, 500),
    [],
  )

  const handleSearchPhraseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
      debounceCallback(event.target.value)
    },
    [debounceCallback],
  )

  const clearSearchPhrase = () => {
    setSearchValue('')
    debounceCallback('')
  }

  return {
    videoListData,
    videoSearchListData,
    getNextVideos,
    hasMore: hasMore,
    register,
    onSubmit,
    handleSearchPhraseChange,
    searchValue,
    loading: videoListLoading || videoSearchListLoading,
    searchPhrase,
    videoList: videos,
    clearSearchPhrase,
    handleSelectVideo,
    selectedVideoId,
  }
}

export default useModalLibraryList
