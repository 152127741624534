import * as React from 'react'

const CollapseQuillIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M12.4974 6.44377L17.9412 1L19 2.05881L13.5562 7.5026H18.2357V9H11V1.76427H12.4974V6.44377Z"
        fill="#7A7A86"
      />
      <path
        d="M7.50259 13.5562L2.05882 19L1 17.9412L6.44378 12.4974H1.76429V11H9V18.2357H7.50259V13.5562Z"
        fill="#7A7A86"
      />
    </svg>
  )
}
export default CollapseQuillIcon
