import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../../../../ui/components/Card'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { Check } from '@mui/icons-material'
import { theme } from '../../../../../../common/providers/MuiThemeProvider'
import Button from '../../../../../../components/_refactored/Button'
import { useHistory } from 'react-router-dom'
import ToastContext from '../../../../../../common/providers/ToastProvider/ToastContext'
import { BillingProduct } from '../../../../../../API'
import useBillingRedirect from '../../hooks/useBillingRedirect'
import './planCard.scss'
import InfoIcon from '../../../../../../common/icons/InfoIcon'

interface IPlanCard {
  pricePlan?: BillingProduct | null
  planName: string
  price: string
  priceInfo: string
  description: string
  isRecommended?: boolean | string
  featuresTitle: string
  features: string[]
  isCurrentPlan?: boolean
  ctaName?: string
  ctaLink?: string
  loading?: boolean
  hasPaymentAttached?: boolean
  onSubmit?: any
  hideMonthSuffix?: boolean
}

const PlanCard = (props: IPlanCard) => {
  const history = useHistory()
  const toastContext = useContext(ToastContext)
  const { redirectToAttachCreditCard, loading } = useBillingRedirect()
  const { t } = useTranslation('settings')
  const proPlanPriceMonthlyValue = props.pricePlan?.prices?.find((price) => price?.interval === 'month')?.amount

  const onButtonClick = async () => {
    if (props.onSubmit) {
      await props.onSubmit()
      toastContext.open({ type: 'INFO', text: t('billing.common.planUpdateSuccess') })
      history.push('/app/settings/billing')
      return
    }

    if (props.ctaLink === '/app/settings/billing/contact-sales') {
      history.push(props.ctaLink)
    } else if (props.ctaLink && props.hasPaymentAttached) {
      history.push(props.ctaLink)
    } else if (props.pricePlan?.name && redirectToAttachCreditCard) {
      return redirectToAttachCreditCard(props.pricePlan.name)
    }
  }

  return (
    <Card highlighted={props.isRecommended} fullHeight className="plan-card" sx={{ padding: '24px 48px' }}>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography variant="h6" style={{ fontSize: '12px', marginBottom: '15px', fontWeight: 600 }}>
          {props.planName}
        </Typography>
        <Typography variant="h6" style={{ fontSize: '32px', fontWeight: 600 }}>
          {props.price}{' '}
          {!props.hideMonthSuffix && (
            <span style={{ fontSize: '15px', fontWeight: 400, color: theme.palette.text.secondary }}>
              {t('billing.common.monthUnit')}
            </span>
          )}
        </Typography>
        <Box display="flex" alignItems="center" marginTop="10px">
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: 600 }}>
            {props.priceInfo}
          </Typography>
          {props.ctaLink === '/app/settings/billing/plans/professional' && proPlanPriceMonthlyValue && (
            <Tooltip
              title={`$${proPlanPriceMonthlyValue}${t('billing.common.monthUnit')} ${t(
                'billing.common.whenBilledMonthly',
              )}`}
            >
              <div style={{ marginLeft: '5px' }}>
                <InfoIcon />
              </div>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Typography
        variant="h6"
        color="textSecondary"
        sx={{ fontSize: '14px', marginTop: '24px', marginBottom: '24px', height: '110px' }}
      >
        {props.description}
      </Typography>
      <Typography variant="h6" style={{ fontSize: '15px', fontWeight: 600, marginBottom: '10px' }}>
        {props.featuresTitle}
      </Typography>
      {props.features.map((feature, key) => {
        return (
          <Box display="flex" alignItems="center" marginBottom="2px" key={key}>
            <Check style={{ color: theme.palette.primary.main }} />
            <Typography variant="h6" color="textSecondary" style={{ fontSize: '14px', marginLeft: '6px' }}>
              {feature}
            </Typography>
          </Box>
        )
      })}
      <Box height="80px" />
      <Box
        display="flex"
        alignItems="center"
        position="absolute"
        bottom="20px"
        width="calc(100% - 48px)"
        left="50%"
        style={{ transform: 'translateX(-50%)' }}
      >
        {props.isCurrentPlan ? (
          <Typography
            variant="body1"
            align="center"
            color="textSecondary"
            style={{ fontWeight: 500, fontSize: '16px', width: '100%', padding: '10px 0' }}
          >
            {t('billing.common.currentPlan')}
          </Typography>
        ) : props.ctaLink?.startsWith('mailto') ? (
          <Button variant="contained" color="primary" fullWidth href={props.ctaLink}>
            {props.ctaName}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            loading={props?.loading || loading}
            fullWidth
            onClick={onButtonClick}
          >
            {props.ctaName}
          </Button>
        )}
      </Box>
    </Card>
  )
}

export default PlanCard
