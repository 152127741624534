import { useForm } from 'react-hook-form'
import { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { CreateContactMutation, CreateContactMutationVariables } from '../../../../../../API'
import gql from 'graphql-tag'
import { createContact } from '../../../../../../graphql/mutations'
import CognitoApolloClient from '../../../../../../common/clients/CognitoApolloClient'
import ToastContext from '../../../../../../common/providers/ToastProvider/ToastContext'
interface IForm {
  firstName?: string
  lastName?: string
  email: string
}
const useAddNewContact = (email: string) => {
  const [loading, setLoading] = useState(false)
  const { open } = useContext(ToastContext)
  const { t } = useTranslation('library')
  const form = useForm<IForm>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: email,
    },
  })

  useEffect(() => {
    form.reset({ email })
  }, [email])

  const [createContactMutation] = useMutation<CreateContactMutation, CreateContactMutationVariables>(
    gql(createContact),
    {
      client: CognitoApolloClient,
    },
  )
  const handleSubmit = async () => {
    try {
      setLoading(true)
      await createContactMutation({
        variables: {
          input: {
            firstName: form.watch('firstName'),
            lastName: form.watch('lastName'),
            email: form.watch('email'),
          },
        },
      })
    } catch (e: any) {
      console.log('Error occurred during creating contact.', e)
      open({ type: 'ERROR', text: t('videoItem.addNewContactModal.addContactError') })
    } finally {
      setLoading(false)
    }
  }

  return { handleSubmit, form, loading }
}

export default useAddNewContact
