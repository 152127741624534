import React from 'react'

const VideoEmailArrowRightIcon = () => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="12"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 11 12"
    >
      <path
        className="st0"
        d="M8.3,12c0.1,0,0.3-0.1,0.4-0.2l2.2-2.5c0.2-0.3,0.1-0.7-0.3-0.7H9V3.3C9,1.5,7.5,0,5.8,0H0.6C0.6,0,0.5,0,0.4,0  c-0.1,0-0.2,0.1-0.2,0.1S0.1,0.3,0,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.2,0.1,0.2s0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.2,0h5.1c1.1,0,1.9,0.9,1.9,2v5.3H6.1c-0.4,0-0.5,0.4-0.3,0.7l2.2,2.5C8.1,11.9,8.2,12,8.3,12z"
        fill="#7A7A86"
      />
    </svg>
  )
}
export default VideoEmailArrowRightIcon
