import React from 'react'
import { Controller } from 'react-hook-form'
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Button from '../../../../../../components/_refactored/Button'
import useLogin from '../../common/hooks/useLogin'
import { theme } from '../../../../../../common/providers/MuiThemeProvider'
import { useTranslation } from 'react-i18next'

const UserForm = () => {
  const state = useLogin()
  const { t } = useTranslation('auth')
  const { t: g } = useTranslation('general')

  return (
    <form onSubmit={state.onSubmit}>
      <Controller
        name={'email'}
        control={state.form.control}
        rules={{
          required: g('common.requiredField'),
          validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
        }}
        render={({ value, onChange, onBlur }) => (
          <TextField
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            key="username"
            name="username"
            type="email"
            label={t('login.username')}
            variant="standard"
            error={!!state.form.errors['email']}
            helperText={state.form.errors['email']?.message}
            style={{ marginBottom: !!state.form.errors['email'] ? '8px' : '30px', width: '100%' }}
            disabled={state.loading}
            data-testid="username-input"
          />
        )}
      />
      <Controller
        name={'password'}
        control={state.form.control}
        rules={{
          required: g('common.requiredField'),
          validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
        }}
        render={({ value, onChange, onBlur }) => (
          <TextField
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            key="password"
            type={state.isPasswordVisible ? 'text' : 'password'}
            name="password"
            variant="standard"
            label={g('common.password')}
            error={!!state.form.errors['password']}
            helperText={state.form.errors['password']?.message}
            style={{ marginBottom: !!state.form.errors['password'] ? '0' : '10px', width: '100%' }}
            disabled={state.loading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear"
                    style={{ boxShadow: 'none', marginRight: '-12px' }}
                    onClick={state.switchPasswordVisible}
                  >
                    {!state.isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            data-testid="password-input"
          />
        )}
      />
      <Typography
        style={{
          textAlign: 'end',
          cursor: 'pointer',
          fontSize: '13px',
          color: theme.palette.primary.main,
        }}
        onClick={() => state.history.push('/forgot-password')}
        data-testid="forgot-password-button"
      >
        {t('login.forgotPasswordBtn')}
      </Typography>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        loading={state.loading}
        style={{ width: '100%', marginTop: 20 }}
        data-testid="submit-button"
      >
        {g('common.signInBtn')}
      </Button>
    </form>
  )
}

export default UserForm
