import React from 'react'
import { Box, CircularProgress, FormControlLabel, IconButton, Switch, Typography } from '@mui/material'
import VideoPlaylistBox from '../components/VideoPlaylistBox'
import useSharedVideos from '../../common/hooks/useSharedVideos'
import '../../common/styles/VideoBox.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import { VideoStatus } from '../../../../../../../API'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { useTranslation } from 'react-i18next'
import { PartnerSidebarCurrentView } from '../../common/types/PartnerSidebarCurrentView'
import EmptyMediaList from '../components/EmptyMediaList'

interface ISharedVideosProps {
  partnerId?: string | null
  handleSharedVideosLoaded: () => void
  listMaxHeight?: string
  hidden?: boolean
  currentView: PartnerSidebarCurrentView
}

const SharedVideos = (props: ISharedVideosProps) => {
  const { t } = useTranslation('chat')
  const state = useSharedVideos({
    partnerId: props.partnerId,
    handleSharedVideosLoaded: props.handleSharedVideosLoaded,
  })

  if (props?.hidden || (state?.data?.length === 0 && props.currentView !== 'videos')) return <></>

  return (
    <Box borderBottom={props.currentView === 'media' ? '1px solid rgba(122, 122, 134, 0.3)' : 'none'}>
      {props.currentView === 'media' && (
        <Box maxWidth="100%" width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '12px' }}>
            {t('partnerSidebar.videos')}
          </Typography>
          <IconButton onClick={state.toggleVideosVisible} sx={{ boxShadow: 'none', background: 'transparent' }}>
            {state.videosVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
      )}
      {state.data?.length > 0 && (
        <Box sx={{ display: state.videosVisible ? 'block' : 'none' }}>
          <Box
            sx={{
              '& label': {
                marginLeft: '0',
              },
              '& p': {
                color: state.autoPlayChecked ? theme.palette.primary.main : theme.palette.text.secondary,
              },
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  // disabled={props.sending}
                  defaultChecked={state.autoPlayChecked}
                  color="primary"
                  onChange={state.toggleAutoPlay}
                />
              }
              label={
                <Typography variant="body2" color="textSecondary">
                  {t('partnerSidebar.autoplay')}
                </Typography>
              }
              labelPlacement="start"
            />
          </Box>

          <Box
            maxHeight={props?.listMaxHeight || '100%'}
            width="calc(100% + 20px)"
            marginLeft="-20px"
            paddingLeft="20px"
            sx={{ overflowY: 'scroll' }}
            className="video-box"
            id="videos-scrollable-box"
            {...{ ref: state.wrapperRef }}
          >
            <InfiniteScroll
              style={{ overflowX: 'hidden' }}
              dataLength={state.data?.length || 0}
              next={state.loadNextVideos}
              hasMore={state.hasMore}
              scrollableTarget="videos-scrollable-box"
              loader={
                <Box width="100%" display="flex" justifyContent="center">
                  <CircularProgress style={{ width: '24px', height: '24px' }} />
                </Box>
              }
            >
              {state.data?.map((item, key) => {
                // helper variable for access localStorage player by specific videoId
                const videoLocalStorageId = item?.video?.id || item?.referencePost?.video?.id || ''
                const videoProgress =
                  state?.videoPlaylistProgress?.[item?.id] * 1000 > (item.engagement?.items?.[0]?.videoProgress || 0)
                    ? state?.videoPlaylistProgress?.[item?.id] * 1000
                    : Number(localStorage.getItem(`player:${videoLocalStorageId}`) || 0) * 1000 >
                      (item?.engagement?.items?.[0]?.videoProgress || 0)
                    ? Number(localStorage.getItem(`player:${videoLocalStorageId}`) || 0) * 1000
                    : item.engagement?.items?.[0]?.videoProgress || 0

                if (item.postType === 'video' && item.video?.status !== VideoStatus.ok) {
                  return <React.Fragment key={item?.id || key}></React.Fragment>
                }
                if (item.postType === 'shared' && item.referencePost?.video?.status !== VideoStatus.ok) {
                  return <React.Fragment key={item?.id || key}></React.Fragment>
                }
                if (item.postType === 'email_message' && item.referencePost?.video?.status !== VideoStatus.ok) {
                  return <React.Fragment key={item?.id || key}></React.Fragment>
                }
                return (
                  <VideoPlaylistBox
                    videoId={
                      item?.video?.accessId ||
                      item?.video?.id ||
                      item?.referencePost?.video?.accessId ||
                      item?.referencePost?.video?.id ||
                      ''
                    }
                    videoLocalStorageId={item?.video?.id || item?.referencePost?.video?.id || ''}
                    isSeen={
                      !!(item.video
                        ? item.video.viewsList?.items?.find((view) => view.customerId === state.auth.user?.id)
                        : item?.referencePost?.video?.viewsList?.items?.find(
                            (view) => view.customerId === state.auth.user?.id,
                          )) || videoProgress > 0
                    }
                    key={item?.id || key}
                    image={item?.video?.thumbnailObject?.key || item?.referencePost?.video?.thumbnailObject?.key || ''}
                    title={item?.video?.title || item?.referencePost?.video?.title || ''}
                    duration={item?.video?.duration || item?.referencePost?.video?.duration || 0}
                    postId={item.id}
                    createdAt={item.createdAt || ''}
                    videoProgress={videoProgress}
                  />
                )
              })}
            </InfiniteScroll>
          </Box>
        </Box>
      )}
      {!state.videosListLoading && state.data?.length === 0 && (
        <EmptyMediaList text={t('partnerSidebar.noVideosShared')} />
      )}
    </Box>
  )
}

export default SharedVideos
