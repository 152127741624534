import * as React from 'react'
import FlagUS from '../../../common/icons/FlagUS'
import FlagDE from '../../../common/icons/FlagDE'
import FlagES from '../../../common/icons/FlagES'
import FlagFR from '../../../common/icons/FlagFR'
import FlagIT from '../../../common/icons/FlagIT'
import FlagPl from '../../../common/icons/FlagPL'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import moment from 'moment'
import i18next from 'i18next'

const useLanguage = () => {
  const { i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [value, setValue] = useState(i18n.language)
  const open = Boolean(anchorEl)
  const getLanguage = () => i18next.language || localStorage.getItem('i18nextLng')
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (key: string) => {
    setValue(key)
    i18n.changeLanguage(key)
    moment.locale(key)
    localStorage.setItem('i18nextLng', key)
  }

  const languages = [
    { key: 'en', flag: FlagUS },
    { key: 'fr', flag: FlagFR },
    { key: 'de', flag: FlagDE },
    { key: 'es', flag: FlagES },
    { key: 'it', flag: FlagIT },
    { key: 'pl', flag: FlagPl },
  ]

  return {
    languages,
    handleClick,
    handleClose,
    handleChange,
    getLanguage,
    anchorEl,
    open,
    value,
  }
}
export default useLanguage
