import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Position, Rnd } from 'react-rnd'
import RecorderStateContext from '../../../../providers/RecorderStateProvider/RecorderStateContext'
import { AuthContext } from '../../../../providers/AuthStatusProvider'
import MediaRecorderHandler from '../../../../providers/RecorderStateProvider/MediaRecorderHandler'

const formatTimestamp = (timestamp: number) => {
  const pad = (num: number, size: number) => {
    let numS = num.toString()
    while (numS.length < size) numS = '0' + numS
    return numS
  }

  const h = parseInt(`${timestamp / 3600000}`)
  const hRest = parseInt(`${timestamp % 3600000}`)
  const m = parseInt(`${hRest / 60000}`)
  const mRest = parseInt(`${hRest % 60000}`)
  const s = parseInt(`${mRest / 1000}`)

  return `${pad(h, 2)}:${pad(m, 2)}:${pad(s, 2)}`
}
const useRecordingInProgressModal = () => {
  const { state: recorderState } = useContext(RecorderStateContext)
  const auth = useContext(AuthContext)

  const [confirmCancelModalVisible, setConfirmCancelModalVisible] = useState(false)
  const [confirmStartOverModalVisible, setConfirmStartOverModalVisible] = useState(false)
  const time = useMemo(() => formatTimestamp(recorderState.duration), [recorderState.duration])
  const [collapsed, setCollapsed] = useState(false)
  const timeoutRef = useRef()
  const rndRef = useRef<Rnd>(null)
  const [recordingModalPosition, setRecordingModalPosition] = useState<Position>({
    x: window.innerWidth - 100,
    y: window.innerHeight - 60,
  })

  useEffect(() => {
    if (!auth?.applicationConfiguration?.getApplicationConfiguration?.featureFlags?.maxVideoDurationLimit) return
    if (
      recorderState.duration >
      auth.applicationConfiguration.getApplicationConfiguration.featureFlags.maxVideoDurationLimit * 1000
    ) {
      MediaRecorderHandler.stopMediaRecorderHandler()
    }
  }, [recorderState.duration, auth.applicationConfiguration?.getApplicationConfiguration?.featureFlags])

  useEffect(() => {
    const initialTimeout = setTimeout(() => {
      setCollapsed(true)
    }, 3000)

    return () => clearTimeout(initialTimeout)
  }, [])

  useEffect(() => {
    if (!recordingModalPosition) return

    const onResizeHandlerControls = () => {
      if (window.innerWidth - 100 !== recordingModalPosition.x) {
        setRecordingModalPosition({
          x: window.innerWidth - 100,
          y: rndRef?.current?.draggable.props.position.y || window.innerHeight - 60,
        })
      }
      if (window.innerHeight - 60 <= recordingModalPosition.y) {
        setRecordingModalPosition({
          x: window.innerWidth - 100,
          y: window.innerHeight - 60,
        })
      }
    }

    window.addEventListener('resize', onResizeHandlerControls)
    return () => window.removeEventListener('resize', onResizeHandlerControls)
  }, [recordingModalPosition])

  const onMouseOverHandler = () => {
    setCollapsed(false)
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    // @ts-ignore
    timeoutRef.current = setTimeout(() => {
      setCollapsed(true)
    }, 2000)
  }

  const showConfirmStartOverModal = () => {
    setConfirmStartOverModalVisible(true)
  }

  const showConfirmCancelModal = () => {
    setConfirmCancelModalVisible(true)
  }
  const closeConfirmStartOverModal = () => {
    setConfirmStartOverModalVisible(false)
  }

  const closeConfirmCancelModal = () => {
    setConfirmCancelModalVisible(false)
  }

  const handleUpdatePosition = (x: number, y: number) => {
    setRecordingModalPosition({ x: x, y: y })
  }

  return {
    showConfirmStartOverModal,
    showConfirmCancelModal,
    closeConfirmStartOverModal,
    closeConfirmCancelModal,
    handleUpdatePosition,
    onMouseOverHandler,
    confirmCancelModalVisible,
    confirmStartOverModalVisible,
    time,
    collapsed,
    rndRef,
    recordingModalPosition,
  }
}

export default useRecordingInProgressModal
