/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import { postFields } from './postFields'

export const getApplicationConfiguration = /* GraphQL */ `
  query GetApplicationConfiguration {
    getApplicationConfiguration {
      videoPlayerEventsFirehose
    }
  }
`
export const getCustomerProfile = /* GraphQL */ `
  query GetCustomerProfile($customerId: ID!) {
    getCustomerProfile(customerId: $customerId) {
      customerId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          timezone
        }
        createdAt
        updatedAt
      }
      totalVideos
    }
  }
`
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          timezone
        }
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
      }
      accessType
      accessId
      password
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
            }
            accessType
            accessId
            password
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            commentsCount
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      updatedAt
    }
  }
`
export const getVideoPresignedUrl = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      accessType
      accessId
      filePresignedUrl
      customerId
      createdAt
      duration
      title
      thumbnailObject {
        key
        bucket
      }
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
      }
    }
  }
`
export const getVideoThumbnailEdit = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      customerId
      accessType
      filePresignedUrl
      id
      thumbnailObject {
        key
        bucket
      }
    }
  }
`
export const verifyProtectedVideoPassword = /* GraphQL */ `
  query VerifyProtectedVideoPassword($accessId: ID!, $password: String!) {
    verifyProtectedVideoPassword(accessId: $accessId, password: $password) {
      accessToken
      video {
        id
        createdAt
        customerId
        channelId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            timezone
          }
          createdAt
          updatedAt
        }
        title
        searchTitle
        status
        fileObject {
          key
          bucket
        }
        filePresignedUrl
        duration
        name
        thumbnailObject {
          key
          bucket
        }
        accessType
        accessId
        password
        viewsCountTotal
        viewsCountUnique
        viewsList {
          items {
            videoId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            guestName
            viewCount
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
              }
              accessType
              accessId
              password
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              commentsCount
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        commentsCount
        updatedAt
      }
    }
  }
`
export const accessProtectedVideo = /* GraphQL */ `
  query AccessProtectedVideo($accessId: ID!, $accessToken: String!) {
    accessProtectedVideo(accessId: $accessId, accessToken: $accessToken) {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          timezone
        }
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
      }
      accessType
      accessId
      password
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
            }
            accessType
            accessId
            password
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            commentsCount
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      updatedAt
    }
  }
`
export const listVideoAudienceEngagement = /* GraphQL */ `
  query ListVideoAudienceEngagement($videoId: ID!) {
    listVideoAudienceEngagement(videoId: $videoId) {
      videoId
      customerId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          timezone
        }
        createdAt
        updatedAt
      }
      timestamp
      chartValues
      segments {
        timestamp
        chartValues
      }
    }
  }
`
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      email
      firstName
      lastName
      profilePhoto {
        key
        bucket
      }
      calendlyUrl
      lastSeen
      settings {
        notifyEmailWatched
        notifyEmailComment
        timezone
      }
      createdAt
      updatedAt
    }
  }
`
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      ownerCustomerId
      firstName
      lastName
      email
      mobilePhone
      contactCustomerMeta {
        items {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            timezone
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const listContactsByOwner = /* GraphQL */ `
  query ListContactsByOwner(
    $ownerCustomerId: ID!
    $lastNameFirstName: ModelscreensightContactByOwnerSortedByNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByOwner(
      ownerCustomerId: $ownerCustomerId
      lastNameFirstName: $lastNameFirstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerCustomerId
        firstName
        lastName
        email
        mobilePhone
        contactCustomerMeta {
          items {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const searchScreensightContacts = /* GraphQL */ `
  query SearchScreensightContacts(
    $filter: SearchablescreensightContactFilterInput
    $sort: [SearchablescreensightContactSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablescreensightContactAggregationInput]
  ) {
    searchScreensightContacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        ownerCustomerId
        firstName
        lastName
        email
        mobilePhone
        contactCustomerMeta {
          items {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!, $customerId: ID!) {
    getChannel(id: $id, customerId: $customerId) {
      id
      customerId
      channelType
      name
      categoryPosition
      readHorizon
      activity {
        new
        latestAt
      }
      createdAt
      updatedAt
    }
  }
`
export const listChannelsByCustomer = /* GraphQL */ `
  query ListChannelsByCustomer(
    $customerId: ID!
    $categoryPositionName: ModelscreensightChannelByCustomerCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannelsByCustomer(
      customerId: $customerId
      categoryPositionName: $categoryPositionName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        channelType
        name
        categoryPosition
        readHorizon
        activity {
          new
          latestAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listVideosByOwner = /* GraphQL */ `
  query ListVideosByOwner(
    $customerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosByOwner(
      customerId: $customerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        customerId
        channelId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            timezone
          }
          createdAt
          updatedAt
        }
        title
        searchTitle
        status
        fileObject {
          key
          bucket
        }
        filePresignedUrl
        duration
        name
        thumbnailObject {
          key
          bucket
        }
        accessType
        accessId
        password
        viewsCountTotal
        viewsCountUnique
        viewsList {
          items {
            videoId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            guestName
            viewCount
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
              }
              accessType
              accessId
              password
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              commentsCount
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        commentsCount
        updatedAt
      }
      nextToken
    }
  }
`
export const searchScreensightVideos = /* GraphQL */ `
  query SearchScreensightVideos(
    $filter: SearchablescreensightVideoFilterInput
    $sort: [SearchablescreensightVideoSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablescreensightVideoAggregationInput]
  ) {
    searchScreensightVideos(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        createdAt
        customerId
        channelId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            timezone
          }
          createdAt
          updatedAt
        }
        title
        searchTitle
        status
        fileObject {
          key
          bucket
        }
        filePresignedUrl
        duration
        name
        thumbnailObject {
          key
          bucket
        }
        accessType
        accessId
        password
        viewsCountTotal
        viewsCountUnique
        viewsList {
          items {
            videoId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            guestName
            viewCount
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
              }
              accessType
              accessId
              password
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              commentsCount
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        commentsCount
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const listCommentsByVideo = /* GraphQL */ `
  query ListCommentsByVideo(
    $videoId: ID!
    $parentCreatedAtCreatedAt: ModelscreensightVideoCommentByVideoCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightVideoCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByVideo(
      videoId: $videoId
      parentCreatedAtCreatedAt: $parentCreatedAtCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            timezone
          }
          createdAt
          updatedAt
        }
        videoId
        channelId
        parentId
        content
        videoTimestamp
        resolved
        replies {
          items {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        replyCount
        parentCreatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listCommentsByParent = /* GraphQL */ `
  query ListCommentsByParent(
    $parentId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightVideoCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByParent(
      parentId: $parentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            timezone
          }
          createdAt
          updatedAt
        }
        videoId
        channelId
        parentId
        content
        videoTimestamp
        resolved
        replies {
          items {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        replyCount
        parentCreatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const listPostsByChannel = /* GraphQL */ `
  query ListPostsByChannel(
    $channelId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostsByChannel(
      channelId: $channelId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${postFields}
      nextToken
    }
  }
`

export const searchScreensightPosts = /* GraphQL */ `
  query SearchScreensightPosts(
    $filter: SearchablescreensightPostFilterInput
    $sort: [SearchablescreensightPostSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablescreensightPostAggregationInput]
  ) {
    searchScreensightPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        channelId
        accessId
        customerId
        postType
        video {
          id
          createdAt
          customerId
          channelId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          title
          searchTitle
          status
          fileObject {
            key
            bucket
          }
          filePresignedUrl
          duration
          name
          thumbnailObject {
            key
            bucket
          }
          accessType
          accessId
          password
          viewsCountTotal
          viewsCountUnique
          viewsList {
            items {
              videoId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              guestName
              viewCount
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                }
                accessType
                accessId
                password
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                }
                commentsCount
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          comments {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          commentsCount
          updatedAt
        }
        comment {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        emailMessage {
          id
          videoId
          customerId
          channelId
          to
          note
          emailStatus {
            email
            status
            reject_reason
            _id
          }
          subject
          contacts {
            email
            contactId
          }
          createdAt
          updatedAt
        }
        referencePostId
        referencePost {
          channelId
          accessId
          customerId
          postType
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
            }
            accessType
            accessId
            password
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            commentsCount
            updatedAt
          }
          comment {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          emailMessage {
            id
            videoId
            customerId
            channelId
            to
            note
            emailStatus {
              email
              status
              reject_reason
              _id
            }
            subject
            contacts {
              email
              contactId
            }
            createdAt
            updatedAt
          }
          referencePostId
          referencePost {
            channelId
            accessId
            customerId
            postType
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
              }
              accessType
              accessId
              password
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              commentsCount
              updatedAt
            }
            comment {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            emailMessage {
              id
              videoId
              customerId
              channelId
              to
              note
              emailStatus {
                email
                status
                reject_reason
                _id
              }
              subject
              contacts {
                email
                contactId
              }
              createdAt
              updatedAt
            }
            referencePostId
            referencePost {
              channelId
              accessId
              customerId
              postType
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                }
                accessType
                accessId
                password
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                }
                commentsCount
                updatedAt
              }
              comment {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              emailMessage {
                id
                videoId
                customerId
                channelId
                to
                note
                emailStatus {
                  email
                  status
                  reject_reason
                  _id
                }
                subject
                contacts {
                  email
                  contactId
                }
                createdAt
                updatedAt
              }
              referencePostId
              referencePost {
                channelId
                accessId
                customerId
                postType
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  updatedAt
                }
                comment {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                emailMessage {
                  id
                  videoId
                  customerId
                  channelId
                  to
                  note
                  subject
                  createdAt
                  updatedAt
                }
                referencePostId
                referencePost {
                  channelId
                  accessId
                  customerId
                  postType
                  referencePostId
                  readHorizon
                  createdAt
                  updatedAt
                  id
                }
                readHorizon
                createdAt
                updatedAt
                id
              }
              readHorizon
              createdAt
              updatedAt
              id
            }
            readHorizon
            createdAt
            updatedAt
            id
          }
          readHorizon
          createdAt
          updatedAt
          id
        }
        readHorizon
        createdAt
        updatedAt
        id
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const listVideoAudience = /* GraphQL */ `
  query ListVideoAudience(
    $videoId: ID
    $customerId: ModelIDKeyConditionInput
    $filter: ModelScreensightVideoAudienceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVideoAudience(
      videoId: $videoId
      customerId: $customerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        videoId
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            timezone
          }
          createdAt
          updatedAt
        }
        guestName
        viewCount
        video {
          id
          createdAt
          customerId
          channelId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          title
          searchTitle
          status
          fileObject {
            key
            bucket
          }
          filePresignedUrl
          duration
          name
          thumbnailObject {
            key
            bucket
          }
          accessType
          accessId
          password
          viewsCountTotal
          viewsCountUnique
          viewsList {
            items {
              videoId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              guestName
              viewCount
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                }
                accessType
                accessId
                password
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                }
                commentsCount
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          comments {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          commentsCount
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listVideosWatched = /* GraphQL */ `
  query ListVideosWatched(
    $customerId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightVideoAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosWatched(
      customerId: $customerId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        videoId
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            timezone
          }
          createdAt
          updatedAt
        }
        guestName
        viewCount
        video {
          id
          createdAt
          customerId
          channelId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          title
          searchTitle
          status
          fileObject {
            key
            bucket
          }
          filePresignedUrl
          duration
          name
          thumbnailObject {
            key
            bucket
          }
          accessType
          accessId
          password
          viewsCountTotal
          viewsCountUnique
          viewsList {
            items {
              videoId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              guestName
              viewCount
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                }
                accessType
                accessId
                password
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                }
                commentsCount
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          comments {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          commentsCount
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
