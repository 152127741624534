import React from 'react'
import { Box } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

interface INewMessagesBox {
  onClick: () => void
}

const NewMessagesBox = (props: INewMessagesBox) => {
  return (
    <Box
      position="fixed"
      bottom="150px"
      bgcolor="primary.main"
      padding="15px"
      color="white"
      borderRadius="10px"
      zIndex={100}
      style={{ cursor: 'pointer' }}
      display="flex"
      alignItems="center"
      onClick={props.onClick}
    >
      New messages
      <ArrowDownwardIcon style={{ marginLeft: '10px' }} />
    </Box>
  )
}

export default NewMessagesBox
