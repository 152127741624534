import React from 'react'

import useErrorProvider from './useErrorProvider'
import ErrorProviderView from './ErrorProviderView'

export type ErrorProviderProps = any

export default (props: any) => {
  const state = useErrorProvider()
  return <ErrorProviderView {...state} {...props} />
}
