import * as React from 'react'
import { Box } from '@mui/material'

type Props = {
  color?: string
}
const AISummaryIcon = (props: Props) => {
  return (
	<svg
	xmlns="http://www.w3.org/2000/svg"
	width={13}
	height={12}
	fill="none"
	{...props}
  >
	<path
	  fill={props.color || '#2483F7'}
	  d="M8.917.167a1.167 1.167 0 0 0-.584 2.175v.741h-1.75V1.917l.001-.843a.583.583 0 0 0-.29-.505 2.926 2.926 0 0 0-3.523.452 2.492 2.492 0 0 0-.446.613l-.018-.009a2.887 2.887 0 0 0-.331 1.953C.91 4.176.166 5.278.166 6.583.167 7.645.685 8.56 1.43 9.2c.277 1.486 1.547 2.634 3.112 2.634.657 0 1.274-.2 1.783-.542a.583.583 0 0 0 .258-.484v-1.89h1.75v.74a1.167 1.167 0 1 0 1.167.001V8.333a.583.583 0 0 0-.583-.583H6.583V6.583h3.659a1.167 1.167 0 1 0-.001-1.166H6.583V4.25h2.334a.583.583 0 0 0 .583-.583V2.343A1.167 1.167 0 0 0 8.917.167ZM4.82 1.348c.202.02.4.096.597.169v2.052a.583.583 0 0 0 0 .19v1.075h-.004C5.414 5.484 4.9 6 4.251 6l.001 1.167c.426 0 .82-.126 1.165-.328v1.396a.583.583 0 0 0 0 .19v1.976c-.272.135-.55.266-.875.266A2.026 2.026 0 0 1 2.526 8.86a.583.583 0 0 0-.233-.402 2.304 2.304 0 0 1 .097-3.811c.243.382.567.72.985.961l.583-1.01a1.738 1.738 0 0 1-.753-.884.585.585 0 0 0-.013-.048 1.787 1.787 0 0 1-.1-.718c.005-.063.017-.126.028-.188a1.74 1.74 0 0 1 .028-.129c.012-.044.023-.089.038-.132a1.782 1.782 0 0 1 .41-.654 1.719 1.719 0 0 1 1.02-.497.949.949 0 0 1 .204 0Z"
	/>
  </svg>
  )
}

export default AISummaryIcon
