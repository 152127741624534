import React from 'react'
import { Button as MuiButton, ButtonProps, CircularProgress } from '@mui/material'

interface IProps extends ButtonProps {
  loading?: boolean
  children?: React.ReactNode
  loaderStyles?: React.CSSProperties
}

const Button = ({ loading, children, ...props }: IProps) => {
  return (
    <MuiButton {...props} disabled={props.disabled || loading}>
      {loading ? <CircularProgress color="primary" style={{ padding: '9px', ...props.loaderStyles }} /> : children}
    </MuiButton>
  )
}

export default Button
