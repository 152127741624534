import * as React from 'react'

const ArrowLeftOutline = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
      <path
        d="M6.98505 14L7.30112 14C7.57127 14 7.81686 13.8701 7.93147 13.6673C8.04881 13.4622 8.01333 13.2207 7.84142 13.0475L1.91719 7L7.84142 0.952473C8.01333 0.779297 8.04881 0.53776 7.93147 0.332681C7.81686 0.129882 7.57127 0 7.30112 0L6.98505 0C6.77493 0 6.57573 0.0797529 6.44475 0.214193L0.157588 6.63086C-0.0525294 6.84505 -0.0525294 7.15495 0.157588 7.36914L6.44475 13.7858C6.57573 13.9202 6.77493 14 6.98505 14Z"
        fill="#7A7A86"
      />
    </svg>
  )
}

export default ArrowLeftOutline
