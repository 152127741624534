import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const { t } = useTranslation('chat')

  return (
    <Typography
      variant="body1"
      color="textSecondary"
      style={{ fontWeight: 500, fontSize: '20px', marginBottom: '50px', padding: '0 15px' }}
    >
      {t('channelsSidebar.title')}
    </Typography>
  )
}

export default Header
