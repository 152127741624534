import React from 'react'
import { useTheme } from '@mui/material'

interface IPencilIcon {
  checked?: boolean
}

const PencilIcon = (props: IPencilIcon) => {
  const theme = useTheme()
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2189 0C12.8777 0 12.5365 0.130292 12.2761 0.390625L10.6667 2L14.0001 5.33333L15.6095 3.72396C16.1302 3.20329 16.1302 2.35921 15.6095 1.83854L14.1616 0.390625C13.9012 0.130292 13.56 0 13.2189 0ZM9.33341 3.33333L1.50652 11.1602C1.50652 11.1602 2.11837 11.1053 2.34637 11.3333C2.57437 11.5613 2.38669 13.0533 2.66669 13.3333C2.94669 13.6133 4.4293 13.4162 4.64196 13.6289C4.85463 13.8416 4.83988 14.4935 4.83988 14.4935L12.6668 6.66667L9.33341 3.33333ZM0.666672 13.3333L0.0377607 15.1146C0.013037 15.1849 0.000273226 15.2588 0 15.3333C0 15.5101 0.0702384 15.6797 0.195264 15.8047C0.320289 15.9298 0.489859 16 0.666672 16C0.741187 15.9997 0.815129 15.987 0.885424 15.9622C0.887598 15.9614 0.889768 15.9605 0.891934 15.9596L0.908861 15.9544C0.910166 15.9536 0.911469 15.9527 0.912768 15.9518L2.66669 15.3333L1.66668 14.3333L0.666672 13.3333Z"
        fill={props.checked ? theme.palette.primary.main : 'white'}
      />
    </svg>
  )
}

export default PencilIcon
