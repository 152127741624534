import React, {createContext, ReactNode, useContext, useState} from 'react'
import useTimeToFirstInputAnalytics from '../../../../../../common/utils/useTimeToFirstInputAnalytics'
import { Analytics } from 'aws-amplify'
import useInitialUILoadTime from '../../../../../../common/utils/useInitialUILoadTime'

export const AuthSignUpAnalyticsContext = createContext<any>(null)

interface AuthSignUpAnalyticsProviderProps {
	children: ReactNode
}
export const AuthSignUpAnalyticsProvider: React.FC<AuthSignUpAnalyticsProviderProps> = ({ children }) => {
	const loadTime = useInitialUILoadTime()
	const [analyticsEventError, setAnalyticsEventError] = useState('')
	const emailAnalytics = useTimeToFirstInputAnalytics()
	const passwordAnalytics = useTimeToFirstInputAnalytics()

	const sendAnalyticsSignupEvent = async (eventName: string, pageExitPoint: string) => {
		const timeToPageExit = parseFloat((performance.now() - loadTime).toFixed(2))
		const event = {
			name: eventName,
			metrics: {
				ui_load_time: loadTime,
				time_to_first_input_email: emailAnalytics.timeToFirstInput,
				time_to_first_input_password: passwordAnalytics.timeToFirstInput,
				time_to_page_exit: timeToPageExit
			},
			attributes: {
				email: emailAnalytics.inputValue,
				error: analyticsEventError,
				exit_point: pageExitPoint
			}
		}

		console.log('Sending analytics event: ', event)

		await Analytics.record({
			...event,
			immediate: true
		})
	}

	// Provide the context value
	return (
		<AuthSignUpAnalyticsContext.Provider value={{
			handleInputChangeForEmailAnalytics: emailAnalytics.handleInputChange,
			handleInputChangeForPasswordAnalytics: passwordAnalytics.handleInputChange,
			setAnalyticsEventError,
			sendAnalyticsSignupEvent
		}}>
			{children}
		</AuthSignUpAnalyticsContext.Provider>
	)
}

// Create a custom hook that uses the context
export const useAuthSignUpAnalytics = () => {
	const context = useContext(AuthSignUpAnalyticsContext)
	if (context === null) {
		throw new Error('useAuthSignUpAnalytics must be used within a AuthSignUpAnalyticsProvider')
	}
	return context
}
