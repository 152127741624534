import React, { SVGProps } from 'react'
import { BoxProps } from '@mui/material'

const IconCameraRecording = ({ width = 49, height = 40, color = '#7A7A86' }: BoxProps & SVGProps<SVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 49 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48.646 16.165a.748.748 0 00-.712-.01l-8.965 4.721v-1.645c0-1.744-1.482-3.162-3.305-3.162h-1.869c2.528-1.552 4.207-4.265 4.207-7.346a8.43 8.43 0 00-1.243-4.4.737.737 0 00-.984-.246.672.672 0 00-.258.941 7.098 7.098 0 011.046 3.705c0 4.05-3.444 7.346-7.678 7.346s-7.678-3.295-7.678-7.346 3.444-7.347 7.678-7.347c1.803 0 3.555.61 4.934 1.717a.741.741 0 001.013-.084.668.668 0 00-.088-.97A9.377 9.377 0 0028.885 0c-4.452 0-8.168 3.07-8.96 7.113a6.713 6.713 0 00-5.098-2.306c-3.642 0-6.605 2.835-6.605 6.32 0 1.999.977 3.783 2.497 4.942-1.82.002-3.3 1.42-3.3 3.162v.112H4.49l-3.463-1.57c-.456-.223-1.042.132-1.027.622v8.539c-.015.49.572.844 1.027.622l3.463-1.57h2.928v10.852C7.418 38.58 8.901 40 10.723 40H29.45c.397 0 .72-.308.72-.688 0-.38-.323-.688-.72-.688H10.723c-1.029 0-1.866-.802-1.866-1.786V19.23c0-.985.837-1.786 1.866-1.786h24.941c1.03 0 1.867.801 1.867 1.786v17.607c0 .984-.837 1.786-1.867 1.786h-3.337c-.397 0-.72.308-.72.688 0 .38.322.688.72.688h3.337c1.823 0 3.305-1.419 3.305-3.162v-5.462l8.965 4.722c.456.259 1.082-.095 1.066-.602V16.757a.683.683 0 00-.354-.592zM1.44 19.482l2.17.984v4.397l-2.17.983v-6.364zm3.609 5.128v-3.891h2.37v3.891h-2.37zM9.66 11.126c0-2.725 2.317-4.943 5.166-4.943 2.848 0 5.166 2.218 5.166 4.943 0 2.726-2.317 4.943-5.166 4.943S9.66 13.852 9.66 11.126zm9.273 4.943a6.354 6.354 0 002.13-2.87 8.98 8.98 0 002.911 2.87h-5.04zm28.627 18.262l-8.592-4.526v-7.357l8.592-4.526v16.41z"
        fill={color}
      />
      <path
        d="M25.219 8.723c0 1.935 1.644 3.508 3.666 3.508 2.022 0 3.667-1.573 3.667-3.508 0-1.934-1.645-3.508-3.667-3.508S25.22 6.79 25.22 8.723zm5.894 0c0 1.176-1 2.132-2.228 2.132-1.228 0-2.228-.956-2.228-2.132 0-1.175 1-2.132 2.228-2.132 1.229 0 2.228.957 2.228 2.132zM14.827 13.605c1.43 0 2.592-1.112 2.592-2.479s-1.163-2.479-2.592-2.479c-1.428 0-2.59 1.112-2.59 2.48 0 1.366 1.162 2.478 2.59 2.478zm0-3.582c.636 0 1.153.495 1.153 1.103s-.517 1.103-1.153 1.103c-.635 0-1.152-.495-1.152-1.103s.517-1.103 1.152-1.103zM31.607 29.827V22.32c0-.885-.753-1.605-1.678-1.605h-7.78c-.396 0-.718.308-.718.688 0 .38.322.688.719.688h7.779c.132 0 .24.103.24.23v7.506c0 .126-.108.23-.24.23H16.457a.235.235 0 01-.24-.23V22.32c0-.126.108-.23.24-.23h2.816c.397 0 .72-.307.72-.687 0-.38-.322-.688-.72-.688h-2.816c-.925 0-1.678.72-1.678 1.605v7.507c0 .885.753 1.605 1.678 1.605H29.93c.925 0 1.678-.72 1.678-1.605zM27.065 33.826c-.397 0-.72.308-.72.688 0 .38.323.688.72.688h1.794c.397 0 .719-.308.719-.688 0-.38-.322-.688-.72-.688h-1.793zM22.295 33.826c-.397 0-.719.308-.719.688 0 .38.322.688.72.688h1.793c.398 0 .72-.308.72-.688 0-.38-.322-.688-.72-.688h-1.794zM17.532 33.826c-.398 0-.72.308-.72.688 0 .38.322.688.72.688h1.794c.397 0 .719-.308.719-.688 0-.38-.322-.688-.72-.688h-1.793z"
        fill={color}
      />
    </svg>
  )
}

export default IconCameraRecording
