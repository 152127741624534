import * as React from 'react'
import { Box, IconButton, Tooltip, useMediaQuery } from '@mui/material'
import StatisticsButton from '../../../../components/_refactored/StatisticsButton'
import SeenBy from '../../../../components/VideoItem/SeenBy'
import TrashIcon from '../../../../common/icons/TrashIcon'
import { ListVideoAudienceQuery, VideoStatus } from '../../../../API'
import EditThumbnailIcon from '../../../../common/icons/EditThumbnailIcon'
import DownloadIcon from '../../../../common/icons/DownloadIcon'
import { theme } from '../../../../common/providers/MuiThemeProvider'
import useGetVideoFragment from '../../../../common/api/hooks/useGetVideoFragment'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useVideoActionsBar from '../common/hooks/useVideoActionsBar'
interface IVideoActionsBarProps {
  videoSource: string | null | undefined
  videoId: string | undefined
  videoViewersData: ListVideoAudienceQuery | undefined
  videoParams: { [key: string]: number | null | undefined }
  fileObject: any
}
const VideoActionsBar = (props: IVideoActionsBarProps) => {
  const state = useVideoActionsBar(props.videoId, props.fileObject?.key)
  const cacheVideo = useGetVideoFragment(props.videoId, '{status, viewsCountUnique, id, title}')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const { id: paramVideoId } = useParams() as { id: string }
  const { t: g } = useTranslation('general')
  const { t } = useTranslation('library')

  if (cacheVideo?.status !== VideoStatus.ok) {
    return <></>
  }

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <StatisticsButton videoId={cacheVideo?.id} isOnVideoList={false} />
        <SeenBy
          seenByArray={props.videoViewersData?.listVideoAudience?.items}
          uniqueViewsCount={cacheVideo?.viewsCountUnique}
        />
      </Box>
      <Box display="flex" marginLeft={isMobile ? '5px' : '0'}>
        <Tooltip title={g('common.deleteBtn')}>
          <IconButton aria-label="delete" name="delete-button" onClick={state.deleteOpenModal} size="small">
            <TrashIcon />
          </IconButton>
        </Tooltip>
        {cacheVideo?.status === VideoStatus.ok && (
          <Tooltip title={t('videoItem.buttons.editThumbnailBtn')}>
            <IconButton
              style={{ marginLeft: isMobile ? '5px' : '15px' }}
              aria-label="edit-thumbnail"
              name="edit-thumbnail-button"
              onClick={() =>
                history.push({
                  pathname: `/video/${paramVideoId}/edit-thumbnail`,
                  state: props.videoParams,
                })
              }
              size="small"
            >
              <EditThumbnailIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t('videoItem.buttons.downloadBtn')}>
          <IconButton
            onClick={() => {
              state.download()
            }}
            aria-label="delete"
            name="delete-button"
            size="small"
            style={{ marginLeft: isMobile ? '5px' : '15px' }}
          >
            <DownloadIcon />
          </IconButton>
          {/*</a>*/}
        </Tooltip>
      </Box>
    </Box>
  )
}

export default VideoActionsBar
