import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Box, Typography } from '@mui/material'

interface IEmptyList {
  text: string
}
const EmptyList = (props: IEmptyList) => {
  const [shouldRender, setShouldRender] = useState(false)
  const { id: channelParamsId } = useParams() as { id: string }

  useEffect(() => {
    const timeout = setTimeout(() => setShouldRender(true), 1500)
    return () => {
      setShouldRender(false)
      window.clearTimeout(timeout)
    }
  }, [channelParamsId])

  if (!shouldRender) {
    return <></>
  }

  return (
    <Box height="100%" padding="20px 10px" display="flex" justifyContent="center" alignItems="center">
      <Typography variant="body1" color="textSecondary" align="center" sx={{ fontSize: '14px', fontWeight: 400 }}>
        {props.text}
      </Typography>
    </Box>
  )
}

export default EmptyList
