import { useForm } from 'react-hook-form'
import { PasswordProps } from '../index'
import { Auth } from 'aws-amplify'
import { useContext, useState } from 'react'
import ToastContext from 'common/providers/ToastProvider/ToastContext'
import { useTranslation } from 'react-i18next'

interface PasswordForm {
  oldPassword: string
  newPassword: string
}

const usePasswordHook = (props: PasswordProps) => {
  const { open } = useContext(ToastContext)
  const { t } = useTranslation('settings')
  const [oldPasswordShowed, setOldPasswordShowed] = useState(false)
  const [newPasswordShowed, setNewPasswordShowed] = useState(false)
  const form = useForm<PasswordForm>({
    mode: 'onChange',
  })

  const translateErrorMessage = (message: any) => {
    switch (message) {
      case 'Attempt limit exceeded, please try after some time.':
        return t('password.attemptsExceeded')
      case 'Password did not conform with policy: Password must have uppercase characters':
        return t('password.upperCaseError')
      case 'Password did not conform with policy: Password must have lowercase characters':
        return t('password.lowerCaseError')
      case 'Password did not conform with policy: Password must have numeric characters':
        return t('password.numberError')
      default:
        return message
    }
  }

  const switchOldPassword = () => {
    setOldPasswordShowed(!oldPasswordShowed)
  }

  const switchNewPassword = () => {
    setNewPasswordShowed(!newPasswordShowed)
  }

  const submit = form.handleSubmit(async (values) => {
    const { oldPassword, newPassword } = values
    const user = await Auth.currentAuthenticatedUser()

    try {
      await Auth.changePassword(user, oldPassword, newPassword)
      open({ type: 'INFO', text: t('password.passwordUpdateSuccess') })
      form.reset()
    } catch (err: any) {
      open({ type: 'ERROR', text: translateErrorMessage(err.message) })
    }
  })

  return {
    form: {
      ...form,
      submit,
    },
    switchOldPassword,
    switchNewPassword,
    oldPasswordShowed,
    newPasswordShowed,
  }
}

export type UsePassword = ReturnType<typeof usePasswordHook>
export default usePasswordHook
