import * as React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import Checkmark from '../../../../../../../common/icons/Checkmark'

interface IDetailRowProps {
  data?: string | null | undefined | boolean
  label?: string
}
export const DetailRow = (props: IDetailRowProps) => {
  const theme = useTheme()

  return (
    <>
      {props.label ? (
        <Box display="flex" alignItems="center">
          <Checkmark color={props.data ? theme.palette.primary.main : theme.palette.secondary.light} />
          <Typography
            variant="body2"
            sx={{
              paddingLeft: '10px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            color={theme.palette.text.secondary}
          >
            {props?.label}
          </Typography>
        </Box>
      ) : (
        <Typography
          variant="body2"
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          color={theme.palette.text.secondary}
        >
          {props?.data}
        </Typography>
      )}
    </>
  )
}

export default DetailRow
