import * as React from 'react'
import { Box, FormControlLabel, Switch, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ITrackInThreadsBtnProps {
  isMobile: boolean
  sending: boolean
  trackInThreads: boolean
  toggleTrackInThreads: () => void
}
const TrackInThreadsBtn = (props: ITrackInThreadsBtnProps) => {
  const { t } = useTranslation('library')

  return (
    <Box display="flex" alignItems="center">
      <Box px={props.isMobile ? '0' : '30px'} marginRight="16px">
        <FormControlLabel
          control={
            <Switch
              disabled={props.sending}
              defaultChecked={props.trackInThreads}
              color="primary"
              onChange={props.toggleTrackInThreads}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              {t('videoItem.sendViaEmailModal.trackSwitch')}
            </Typography>
          }
          labelPlacement="start"
        />
      </Box>
    </Box>
  )
}

export default TrackInThreadsBtn
