import styled from 'styled-components'
import Box from '@mui/material/Box'
import React, { ReactNode } from 'react'
import { useTheme } from '@mui/material'

const StyledKeyBox = styled(Box)`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-name: ${(props: IKeyItem) => (props.active ? 'moveKey' : '')};
  animation-iteration-count: infinite;
  visibility: hidden;

  @keyframes moveKey {
    0% {
      visibility: visible;
      top: -7px;
    }
    33% {
      top: -1px;
    }
    66% {
      top: -7px;
    }
    100% {
      top: -1px;
    }
  }
`

interface IKeyItem {
  active?: boolean
  modalHelper?: boolean
  children: ReactNode
}

const KeyItem = (props: IKeyItem) => {
  const { active, modalHelper, children } = props
  const theme = useTheme()
  return (
    <Box
      width={modalHelper ? '50px' : '24px'}
      height={modalHelper ? '50px' : '24px'}
      position="relative"
      border={`2px solid ${
        active
          ? theme.palette.primary.main
          : props.modalHelper
          ? theme.palette.secondary.main
          : theme.palette.background.default
      }`}
      borderRadius="4px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <StyledKeyBox
        border={`2px solid ${theme.palette.primary.main}`}
        bgcolor={active ? theme.palette.primary.main : 'transparent'}
        borderRadius="4px"
        width={modalHelper ? '50px' : '24px'}
        height={modalHelper ? '50px' : '24px'}
        position="absolute"
        top="-10px"
        left="-2px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        active={active}
        zIndex={999}
      >
        {children}
      </StyledKeyBox>
      {children}
    </Box>
  )
}

export default KeyItem
