import gql from 'graphql-tag'

import CognitoApolloClient from 'common/clients/CognitoApolloClient'
import {
  CreatePostMutation,
  CreatePostMutationVariables,
  CreateVideoMutation,
  CreateVideoMutationVariables,
  VideoStatus,
} from 'API'
import { createPost, createVideo } from 'graphql/mutations'

import { SchedulerItem } from './SchedulerItem'
import { UploadScheduler } from './UploadScheduler'
import { RecordingEntityData, RecordingIds } from './index.types'
import IndexedDB, { IndexedRecordingEntity } from 'common/services/IndexedDB'
import { captureException } from '@sentry/react'

export class RecordingEntity extends SchedulerItem {
  ids: RecordingIds

  title: string
  owner: string
  channelId?: string
  enableSplashScreen: boolean

  constructor(ids: RecordingIds, data: RecordingEntityData, scheduler: UploadScheduler) {
    super(scheduler)
    this.ids = { ...ids }

    this.title = data.title
    this.owner = data.owner
    this.channelId = data?.channelId
    this.enableSplashScreen = data?.enableSplashScreen

    if (this.ids.entityId) {
      this.setSuccess()
    }
  }

  async init() {
    await this.saveToIndexedDb()
  }

  schedule() {
    if (this.status !== 'INIT') return
    super.schedule()
  }

  async saveToIndexedDb() {
    const entity = await IndexedDB.recordingEntity.where('localId').equals(this.ids.localId).first()

    const obj: IndexedRecordingEntity = {
      ...this.ids,
      title: this.title,
      owner: this.owner,
      enableSplashScreen: this.enableSplashScreen,
    }

    if (entity) {
      await IndexedDB.recordingEntity.update(entity, obj)
    } else {
      await IndexedDB.recordingEntity.add(obj)
    }
  }

  async deleteFromIndexedDb() {
    await IndexedDB.recordingEntity.where('localId').equals(this.ids.localId).delete()
  }

  async singleUpload() {
    if (this?.channelId) {
      const { data } = await CognitoApolloClient.mutate<CreatePostMutation, CreatePostMutationVariables>({
        mutation: gql(createPost),
        variables: {
          input: {
            video: {
              title: this.title,
              status: VideoStatus.uploading,
              ...(this?.channelId && { channelId: this.channelId }),
            },
          },
        },
      })
      const element = document.getElementsByClassName('ql-editor')
      if (element?.[0]) {
        element[0].innerHTML = ''
      }

      this.ids.entityId = data?.createPost?.video?.id
      await this.saveToIndexedDb()
    } else {
      const { data } = await CognitoApolloClient.mutate<CreateVideoMutation, CreateVideoMutationVariables>({
        mutation: gql(createVideo),
        variables: {
          input: {
            title: this.title,
            status: VideoStatus.uploading,
          },
        },
      })

      this.ids.entityId = data?.createVideo?.id
      await this.saveToIndexedDb()
    }
  }

  setError() {
    // Sentry.io capture error
    captureException(new Error('Create entityId request error'), {
      tags: {
        entityId: this.ids.entityId,
        localId: this.ids.localId,
      },
    })

    super.setError()
  }

  log(...args: any[]) {
    console.log(new Date().toISOString(), `[RecordingEntity]:`, ...args)
  }
}
