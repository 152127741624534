import { Box, styled } from '@mui/material'
import { theme } from '../../../../../../../../common/providers/MuiThemeProvider'

interface IWrapper {
  active?: boolean
  hasNewMessages?: boolean | null
}

export const RowWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'hasNewMessages',
})<IWrapper>`
  background: ${(props) => (props.active ? theme.palette.primary.main : 'transparent')};
  position: relative;

  &:hover {
    background: ${(props) => (props.active ? '' : 'rgba(36, 131, 247, 0.05)')};
  }
  & p {
    user-select: none;
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ${(props) => props.theme.breakpoints.down('lg')} {
      max-width: ${(props) => (props.hasNewMessages ? '170px' : '190px')};
    }
  }
`
