import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { theme } from '../../../../../../../../../../common/providers/MuiThemeProvider'
import * as React from 'react'

const VideoSummaryError = (props: { error: string }) => {
  const { t } = useTranslation('chat')

  return (
    <Box>
      <Typography
        variant={'body1'}
        sx={{
          fontSize: '12px',
          color: theme.palette.secondary.main,
          whiteSpace: 'pre-wrap',
          marginBottom: '10px',
        }}
      >
        {t('chatBody.videoPostSummaryAI.somethingWentWrong')}...
      </Typography>
      <Typography
        variant={'body1'}
        sx={{
          fontSize: '12px',
          color: theme.palette.secondary.main,
          whiteSpace: 'pre-wrap',
        }}
      >
        {props.error}
      </Typography>
    </Box>
  )
}

export default VideoSummaryError
