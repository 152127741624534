import * as React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../common/providers/MuiThemeProvider'
import MailIcon from '../../../common/icons/MailIcon'

interface ISendByEmailIconButtonProps {
  showSendByEmailModal: () => void
}

const SendByEmailIconButton = (props: ISendByEmailIconButtonProps) => {
  const { t } = useTranslation('library')

  return (
    <Tooltip title={t('common.sendViaEmailBtn')}>
      <IconButton
        style={{
          boxShadow: 'none',
        }}
        aria-label="send-via-email"
        name="send-via-email"
        onClick={props.showSendByEmailModal}
        size="small"
      >
        <MailIcon color={theme.palette.primary.main} />
      </IconButton>
    </Tooltip>
  )
}

export default SendByEmailIconButton
