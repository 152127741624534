import React, { Dispatch, memo, SetStateAction, useCallback, useContext } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import DeleteConfirmContext from 'common/providers/DeleteConfirmProvider/DeleteConfirmContext'
import UploadQueue from 'common/services/UploadQueue'
import { Box, Grid, IconButton, Theme, Tooltip, Typography } from '@mui/material'
import { AccessType, screensightVideo, VideoStatus } from '../../../API'
import { IndexedRecordingEntity } from '../../../common/services/IndexedDB'
import VideoErrorItem from '../components/VideoErrorItem/VideoErrorItem'
import { useTranslation } from 'react-i18next'
import VideoItemThumbnail from '../../../components/VideoItem/VideoItemThumbnail'
import CopyLinkIconButton from '../../../components/_refactored/CopyLinkIconButton'
import TrashIcon from '../../../common/icons/TrashIcon'
import SendByEmailModal from '../../../components/_refactored/SendByEmailModal'
import VideoPrivacyModal from '../../VideoItem/VideoPrivacyModal'
import PrivacyOptionsButton from '../../../components/_refactored/PirvacyOptionsButton'
import StatisticsButton from '../../../components/_refactored/StatisticsButton'
import SendByEmailIconButton from '../../../components/_refactored/SendByEmailIconButton'
import SeenBy from '../../../components/VideoItem/SeenBy'
import useVideoListItem from '../../../pages/VideoList/hooks/useVideoListItem'

interface IVideoListItem {
  item: screensightVideo | IndexedRecordingEntity
  deleteItem: (itemId?: string | null) => void
  setSelectedVideoItem: Dispatch<SetStateAction<screensightVideo | null>>
  theme: Theme
  isMobile: boolean
  isLargeScreen: boolean
}

const VideoListItem = memo((props: IVideoListItem) => {
  const { item, deleteItem, theme, isLargeScreen, isMobile } = props
  const { t: g } = useTranslation('general')
  const modal = useContext(DeleteConfirmContext)
  const state = useVideoListItem()

  const openDeleteModal = useCallback(() => {
    modal.open({
      callback: async () => {
        if ((item as screensightVideo).id) deleteItem((item as screensightVideo).id)
        if ((item as IndexedRecordingEntity).localId)
          await UploadQueue.deleteStoredRecording((item as IndexedRecordingEntity).localId)
      },
    })
  }, [modal, item, deleteItem])

  const { createdAt, status } = item as screensightVideo
  const date = moment(createdAt).format('MMMM DD, YYYY ')

  // @ts-ignore
  if (item?.error) {
    return <VideoErrorItem isMobile={isMobile} isLargeScreen={isLargeScreen} item={item} theme={theme} />
  }
  return (
    <>
      {state.sendEmailModalVisible && (
        <SendByEmailModal
          videoId={(item as screensightVideo).id}
          videoTitle={(item as screensightVideo).title}
          videoDateAdded={moment((item as screensightVideo).createdAt).format('MMMM DD, YYYY ')}
          thumbnailObject={(item as screensightVideo).thumbnailObject}
          hideSendByEmailModal={state.hideSendByEmailModal}
          videoPassword={
            (item as screensightVideo).accessType === AccessType.protected ? (item as screensightVideo).password : ''
          }
          videoStatus={(item as screensightVideo).status}
        />
      )}
      <Grid item xs={12} xl={4}>
        <Box display="flex" height={isMobile ? '166px' : '210px'} style={{ overflow: 'hidden' }}>
          {isMobile && (
            <Box
              width="4px"
              borderRadius="5px"
              height="100%"
              bgcolor={(item as screensightVideo).viewsCountUnique ? 'transparent' : 'primary.main'}
            />
          )}
          <Box
            marginLeft={isMobile ? '6px' : '13px'}
            bgcolor="background.paper"
            width="100%"
            padding={isMobile ? '14px' : '30px'}
            borderRadius="7px"
          >
            <Box display="flex">
              {!isMobile && (
                <Box
                  width="5px"
                  borderRadius="5px"
                  height="150px"
                  bgcolor={(item as screensightVideo).viewsCountUnique ? 'transparent' : 'primary.main'}
                  marginRight="13px"
                  marginLeft="-13px"
                />
              )}
              <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                <Link to={`/video/${(item as screensightVideo)?.accessId || (item as screensightVideo)?.id}`}>
                  <Box
                    height={isMobile ? '80px' : '150px'}
                    width={isMobile ? '80px' : '150px'}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="10px"
                    marginRight={isMobile ? '11px' : '20px'}
                    position="relative"
                  >
                    <VideoItemThumbnail
                      duration={(item as screensightVideo)?.duration}
                      photo={(item as screensightVideo)?.thumbnailObject?.key}
                      isUploading={status === VideoStatus.uploading}
                      isConverting={status === VideoStatus.converting}
                      isMobile={isMobile}
                    />
                  </Box>
                </Link>
              </Box>
              <Box width="100%" style={{ overflowX: 'hidden' }}>
                <div style={{ display: 'flex', width: '100%', maxWidth: '100%', justifyContent: 'space-between' }}>
                  <Typography
                    variant="h3"
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '100%',
                      maxWidth: isMobile ? 'calc(100vw - 200px)' : isLargeScreen ? '70%' : '40vw',
                      marginBottom: '3px',
                    }}
                  >
                    <Link
                      to={`/video/${(item as screensightVideo)?.accessId || (item as screensightVideo)?.id}`}
                      style={{ color: theme.palette.text.primary }}
                    >
                      {item?.title}
                    </Link>
                  </Typography>
                </div>
                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '15px' }}>
                  {date}
                </Typography>
                <SeenBy
                  uniqueViewsCount={(item as screensightVideo).viewsCountUnique}
                  seenByArray={(item as screensightVideo).viewsList?.items}
                />

                {!isMobile && (
                  <Box display="flex" justifyContent="space-between" marginTop="26.5px">
                    <Box display="flex">
                      {(item as screensightVideo)?.status === VideoStatus.ok && (
                        <StatisticsButton
                          videoId={(item as screensightVideo).accessId || (item as screensightVideo).id}
                          isOnVideoList={true}
                        />
                      )}
                    </Box>
                    <Box>
                      <Tooltip title={g('common.deleteBtn')}>
                        <IconButton aria-label="delete" style={{ boxShadow: 'none' }} onClick={openDeleteModal}>
                          <TrashIcon />
                        </IconButton>
                      </Tooltip>
                      <PrivacyOptionsButton
                        togglePrivacyModalVisibility={state.togglePrivacyModalVisibility}
                        accessType={(item as screensightVideo).accessType}
                        isOnVideoList={true}
                      />
                      {state.privacyModalVisible && (
                        <VideoPrivacyModal
                          togglePrivacyModalVisibility={state.togglePrivacyModalVisibility}
                          privacyType={(item as screensightVideo).accessType}
                          videoId={(item as screensightVideo).id}
                          videoPassword={(item as screensightVideo).password}
                          isOnVideoList={true}
                        />
                      )}
                      {(item as screensightVideo)?.status === VideoStatus.ok && (
                        <CopyLinkIconButton
                          videoId={(item as screensightVideo).id}
                          accessID={(item as screensightVideo).accessId}
                          isOnVideoList={true}
                        />
                      )}

                      <SendByEmailIconButton showSendByEmailModal={state.showSendByEmailModal} />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            {isMobile && (
              <Grid container justifyContent="space-between" style={{ marginTop: '20px' }}>
                <Box>
                  {(item as screensightVideo)?.status === VideoStatus.ok && (
                    <StatisticsButton videoId={(item as screensightVideo).id} isOnVideoList={true} />
                  )}
                </Box>

                <Box display="flex">
                  <Tooltip title={g('common.deleteBtn')}>
                    <IconButton aria-label="delete" style={{ boxShadow: 'none' }} onClick={openDeleteModal}>
                      <TrashIcon />
                    </IconButton>
                  </Tooltip>
                  <PrivacyOptionsButton
                    togglePrivacyModalVisibility={state.togglePrivacyModalVisibility}
                    accessType={(item as screensightVideo).accessType}
                    isOnVideoList={true}
                  />
                  {state.privacyModalVisible && (
                    <VideoPrivacyModal
                      togglePrivacyModalVisibility={state.togglePrivacyModalVisibility}
                      privacyType={(item as screensightVideo).accessType}
                      videoId={(item as screensightVideo).id}
                      videoPassword={(item as screensightVideo).password}
                      isOnVideoList={true}
                    />
                  )}
                  {(item as screensightVideo)?.status === VideoStatus.ok && (
                    <CopyLinkIconButton
                      videoId={(item as screensightVideo).id}
                      accessID={(item as screensightVideo).accessId}
                      isOnVideoList={true}
                    />
                  )}

                  <SendByEmailIconButton showSendByEmailModal={state.showSendByEmailModal} />
                </Box>
              </Grid>
            )}
          </Box>
        </Box>
        <Box marginBottom="20px" />
      </Grid>
    </>
  )
})

export default VideoListItem
