import React from 'react'
import useAudioPreview from './hooks/useAudioPreview'
import { Box, Fade } from '@mui/material'
import { Mic } from '@mui/icons-material'

type Props = {
  mediaStream: MediaStream
}
const AudioPreview = (props: Props) => {
  const { canvasLeftRef, canvasRightRef } = useAudioPreview()

  if (!props.mediaStream) return null

  return (
    <Fade in>
      <Box display="flex" justifyContent="center" alignItems="center" margin="20px 0 10px 0">
        <canvas
          ref={canvasLeftRef}
          style={{
            transform: 'scaleX(-1)',
            height: 30,
            width: 120,
          }}
        />

        <Mic fontSize="large" style={{ color: '#2483F7', margin: '0 10px' }} />

        <canvas
          ref={canvasRightRef}
          style={{
            height: 30,
            width: 120,
          }}
        />
      </Box>
    </Fade>
  )
}

export default AudioPreview
