import React, { PropsWithChildren } from 'react'

import AdvancedView from './view/Advanced.view'
import useAdvancedHook from './hooks/useAdvanced.hook'
import PageTitle from '../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

export type AdvancedProps = PropsWithChildren<any>

export default (props: AdvancedProps) => {
  const state = useAdvancedHook()
  const { t: g } = useTranslation('general')

  return (
    <>
      <PageTitle name={g('pageName.advancedSettings')} />

      <AdvancedView {...state} {...props} />
    </>
  )
}
