import * as React from 'react'
import { Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { theme } from '../providers/MuiThemeProvider'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
})

interface IProps {
  active?: boolean
}
const Support = (props: IProps) => {
  const { root } = useStyles()
  const theme = useTheme()

  return (
    <Box className={root} width="60px" display="flex" justifyContent="center" alignItems="center" height="36px">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM8 13C7.448 13 7 12.552 7 12C7 11.448 7.448 11 8 11C8.552 11 9 11.448 9 12C9 12.552 8.552 13 8 13ZM9.37867 8.33333C8.93733 8.74067 8.66667 9.008 8.66667 9.33333H7.33333C7.33333 8.40733 7.966 7.82333 8.474 7.35333C9.00667 6.86133 9.33333 6.53267 9.33333 6C9.33333 5.26467 8.73533 4.66667 8 4.66667C7.26467 4.66667 6.66667 5.26467 6.66667 6H5.33333C5.33333 4.52933 6.52933 3.33333 8 3.33333C9.47067 3.33333 10.6667 4.52933 10.6667 6C10.6667 7.14333 9.95267 7.80267 9.37867 8.33333Z"
          fill={props.active ? theme.palette.primary.main : theme.palette.secondary.light}
        />
      </svg>
    </Box>
  )
}

export default Support
