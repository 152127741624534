import * as React from 'react'
import EditPostActions from './EditPostActions'
import SubmitPostActions from './SubmitPostActions'
import { useChatContext } from '../../../common/contexts/ChatContext'
import usePostEditionDispatch from '../../../common/hooks/quill/usePostEditionDispatch'
import '../../../common/styles/quil.style.scss'

interface IEditorActionsBar {
  handleCancel: () => void
  handleUpdate: () => void
  handleSubmit: () => void
  openNewVideoModal?: () => void
  openLibraryModal?: () => void
  isVideoFromLinkLoading: boolean
  videoPlayerEditor?: boolean | null
}

const EditorActionsBar = (props: IEditorActionsBar) => {
  const chatContext = useChatContext()
  usePostEditionDispatch()

  return !chatContext.editedPostData ? (
    <SubmitPostActions
      isVideoFromLinkLoading={props.isVideoFromLinkLoading}
      handleSubmit={props.handleSubmit}
      openNewVideoModal={props.openNewVideoModal}
      openLibraryModal={props.openLibraryModal}
      videoPlayerEditor={props?.videoPlayerEditor}
    />
  ) : (
    <EditPostActions handleCancel={props.handleCancel} handleUpdate={props.handleUpdate} />
  )
}

export default EditorActionsBar
