import * as React from 'react'
import videojs from 'video.js'
import { Typography, useTheme } from '@mui/material'
import { useHistory } from 'react-router-dom'

interface ICommentPostTimestampProps {
  owner: boolean
  channelId: string | null | undefined
  videoId: string
  videoTimestamp: number | null | undefined
}
export const CommentPostTimestamp = (props: ICommentPostTimestampProps) => {
  const history = useHistory()
  const theme = useTheme()

  return (
    <Typography
      style={{
        fontSize: '15px',
        textOverflow: 'ellipsis',
        marginRight: '10px',
        textDecoration: 'underline',
        color: props.owner ? theme.palette.background.default : theme.palette.text.primary,
        cursor: 'pointer',
      }}
      onClick={() => {
        history.push({
          pathname: `/app/chat/${props?.channelId}`,
          search: `?play=${props?.videoId}&postId=${props?.videoId}&t=${props?.videoTimestamp}`,
        })
      }}
    >
      {videojs.formatTime(props?.videoTimestamp || 0, 600)}
    </Typography>
  )
}

export default CommentPostTimestamp
