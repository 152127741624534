import { useForm } from 'react-hook-form'
import { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../common/providers/AuthStatusProvider'
import ToastContext from '../../../../../../common/providers/ToastProvider/ToastContext'
import { useForgotPasswordDispatch } from '../context/ForgotPasswordContext'

const useSendResetCode = () => {
  const auth = useContext(AuthContext)
  const toastContext = useContext(ToastContext)
  const forgotPasswordDispatcher = useForgotPasswordDispatch()
  const [loading, setLoading] = useState(false)

  const emailForm = useForm<{ email: string }>({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  })

  const onSubmitEmail = emailForm.handleSubmit(async (values: { email: string }) => {
    try {
      setLoading(true)
      await auth.passwordRecovery(values.email)
      forgotPasswordDispatcher({ type: 'setEmail', value: values.email })
      forgotPasswordDispatcher({ type: 'setCurrentView', value: 'PASSWORD_VIEW' })
    } catch (err: any) {
      toastContext.open({ text: err.message, time: 5000 })
    } finally {
      setLoading(false)
    }
  })

  return {
    emailForm,
    onSubmitEmail,
    loading,
  }
}

export default useSendResetCode
