import React from 'react'
import { Box } from '@mui/material'
import IconScreensightLogo from '../../../../../../../common/icons/ScreensightLogo'

const Header = () => {
  return (
    <Box padding={'40px 40px 0px 40px'}>
      <Box width="336px" maxWidth="90%">
        <IconScreensightLogo width="175px" height="100%" />
      </Box>
    </Box>
  )
}

export default Header
