import * as React from 'react'
import Reaction from './Reaction'
import { Box } from '@mui/material'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import ReactionBtn from './ReactionBtn'
import useReactionsList from '../../../common/hooks/reactions/useReactionsList'
import { screensightReaction } from '../../../../../../../../API'

interface IReactionsListProps {
  postId: string
  videoId: string | undefined
  owner?: boolean // post owner
}

const ReactionsList = (props: IReactionsListProps) => {
  const auth = useContext(AuthContext)
  const state = useReactionsList(props.postId)

  return (
    <Box
      display="flex"
      marginTop="2px"
      flexWrap="wrap"
      flexDirection={props.owner ? 'row-reverse' : 'row'}
      alignItems="center"
    >
      {state?.reactionsList?.map((reaction: any, key: any) =>
        reaction?.videoTimestamp === 0 ? (
          <React.Fragment key={key + reaction.unicode}>
            <Reaction
              authors={reaction.customersId}
              amIEmojiOwner={reaction.customersId.includes(
                auth.user?.id || sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId'),
              )}
              unicode={reaction.unicode}
              postId={props.postId}
              videoId={props.videoId}
              videoTimestamp={reaction.videoTimestamp}
              reactionAmount={reaction.reactionAmount}
              disabled={state.areReactionsActionsDisabled}
              disableReactionsActions={state.disableReactionsActions}
              enableReactionsActions={state.enableReactionsActions}
            />
          </React.Fragment>
        ) : (
          <React.Fragment key={key + reaction.unicode} />
        ),
      )}
      {state?.reactionsList?.length > 0 &&
        state?.reactionsList?.find((reaction: screensightReaction) => reaction?.videoTimestamp === 0) && (
          <ReactionBtn videoId={props.videoId} postId={props.postId} location={'reactionsList'} />
        )}
    </Box>
  )
}

export default ReactionsList
