import React from 'react'
import { Box, List, ListItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import signUpProfile from '../../../../../../../common/assets/signUpAvatar.png'
import CheckCircle from '../../../../../../../common/icons/CheckCircle'
import SignUpBackgroundLogoTwo from '../../../../../../../common/icons/SignUpBackgroundLogoTwo'

const CompanyAside = () => {
  const { t } = useTranslation('auth')

  return (
    <Box
      width="50%"
      minHeight="100vh"
      display="flex"
      position="relative"
      alignItems="center"
      justifyContent="center"
      padding="70px"
      style={{
        objectPosition: 'center',
        objectFit: 'cover',
        background: 'linear-gradient(79deg, #38C3FF 0%, #236BF6 100%)',
      }}
    >
      <Box width="592px" display="flex" flexDirection="column" alignItems="center">
        <Box>
          <Typography variant="h1" color="white" style={{ fontSize: '40px', fontWeight: 'bold', lineHeight: '50px' }}>
            {t('signUp.companyAside.heading')}
          </Typography>
          <Typography variant="h6" style={{ margin: '15px 0 35px', lineHeight: '35px' }} color="white">
            {t('signUp.companyAside.subheading')}
          </Typography>
        </Box>
        <Box
          padding="30px"
          marginBottom="42px"
          borderRadius="20px"
          maxWidth="577px"
          maxHeight="299px"
          style={{ backgroundColor: 'rgba(256, 256, 256, 0.1)' }}
        >
          <Typography
            variant="h3"
            style={{ lineHeight: '32px', fontSize: '21px', whiteSpace: 'pre-wrap' }}
            color="white"
          >
            {t('signUp.companyAside.blockquote')}
          </Typography>
          <Box display="flex" alignItems="center" paddingTop="24px">
            <img
              alt="ScreenSight Sign Up"
              src={signUpProfile}
              width={40}
              height={40}
              style={{ border: '1px solid white', borderRadius: '50%' }}
            />
            <Typography variant="body2" style={{ paddingLeft: '10px', fontSize: '14px' }} color="white">
              {t('signUp.companyAside.quoteAuthor')}
            </Typography>
          </Box>
        </Box>
        <List>
          <ListItem sx={{ padding: '5px' }}>
            <CheckCircle />
            <Typography sx={{ paddingLeft: '10px', fontSize: '16px', color: 'white' }}>
              {t('signUp.companyAside.bulletPoints.bulletPointOne')}
            </Typography>
          </ListItem>
          <ListItem sx={{ padding: '5px' }}>
            <CheckCircle />
            <Typography sx={{ paddingLeft: '10px', fontSize: '16px', color: 'white' }}>
              {t('signUp.companyAside.bulletPoints.bulletPointTwo')}
            </Typography>
          </ListItem>
          <ListItem sx={{ padding: '5px' }}>
            <CheckCircle />
            <Typography sx={{ paddingLeft: '10px', fontSize: '16px', color: 'white' }}>
              {t('signUp.companyAside.bulletPoints.bulletPointThree')}
            </Typography>
          </ListItem>
          <ListItem sx={{ padding: '5px' }}>
            <CheckCircle />
            <Typography sx={{ paddingLeft: '10px', fontSize: '16px', color: 'white' }}>
              {t('signUp.companyAside.bulletPoints.bulletPointFour')}
            </Typography>
          </ListItem>
        </List>
        <Box position="absolute" top={0} left={30}>
          <SignUpBackgroundLogoTwo />
        </Box>
      </Box>
    </Box>
  )
}

export default CompanyAside
