import React from 'react'
import { Box, Modal as MuiModal, ModalProps, useMediaQuery, useTheme } from '@mui/material'

interface IModal extends ModalProps {
  resetPadding?: boolean
  boxStyle?: React.CSSProperties
  transparentBackdrop?: boolean
}

const Modal = ({ children, resetPadding, boxStyle, transparentBackdrop, ...props }: IModal) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <MuiModal
      {...props}
      BackdropProps={{ style: { backgroundColor: transparentBackdrop ? 'transparent' : 'rgba(20, 35, 61, 0.7)' } }}
    >
      <Box
        position="fixed"
        left="50%"
        top="50%"
        bgcolor="white"
        padding={resetPadding ? '10px' : isTablet ? '32px' : '40px 60px'}
        width="500px"
        maxWidth="90%"
        borderRadius="10px"
        style={{ transform: 'translate(-50%, -50%)', outline: 'none', ...boxStyle }}
      >
        {children}
      </Box>
    </MuiModal>
  )
}

export default Modal
