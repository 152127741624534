import React from 'react'
import { Modal as M, ModalProps, Box, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CameraPreview from './CameraPreview'
import Footer from './Footer'
import Description from './Description'
import RecordingCounter from './RecordingCounter'

const useStyles = makeStyles<Theme, { isSetup?: boolean }>(() => ({
  root: (props) => ({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    backgroundColor: props.isSetup ? 'white' : 'transparent',
    borderRadius: props.isSetup ? 10 : 0,
    maxWidth: '90%',
    width: 840,
  }),
  modal: {
    backgroundColor: 'rgba(20, 35, 61, 0.7);',
  },
}))

interface ICameraModal extends ModalProps {
  isSetup?: boolean
}

const CameraModal = (props: ICameraModal) => {
  const { root, modal } = useStyles({ isSetup: props?.isSetup })
  const { className = '', children, ...restProps } = props

  return (
    <M {...restProps} className={modal}>
      <Box className={`${root} ${className}`}>{children}</Box>
    </M>
  )
}

CameraModal.Preview = CameraPreview
CameraModal.Description = Description
CameraModal.Footer = Footer
CameraModal.RecordingCounter = RecordingCounter

export default CameraModal
