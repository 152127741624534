import React, { useContext } from 'react'
import { Box, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import Logo from '../../../../../icons/Logo'
import ChatIcon from '../../../../../icons/ChatIcon'
import Icon from '../../../../../icons'
import ContactsIcon from '../../../../../icons/ContactsIcon'
import { AuthContext } from '../../../../../providers/AuthStatusProvider'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import useUnreadChannelsNotifications from '../../../common/hooks/useUnreadChannelsNotifications'
import useLogout from '../../../common/hooks/useLogout'
import LanguageMenu from '../../../../../../pages/Settings/ui/components/LanguageMenu'

const DesktopMenu = () => {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const { t: g } = useTranslation('general')
  const { isSupportChannelUnread, unreadChannelsCount } = useUnreadChannelsNotifications()
  const [logout] = useLogout()

  return (
    <Box
      width="60px"
      height="100%"
      position="fixed"
      left="0"
      top="0"
      boxShadow="0px 0px 3px rgba(0, 0, 0, 0.1)"
      zIndex={101}
      bgcolor="background.default"
    >
      <Box position="relative" top="0" left="0" height="100%" width="60px" overflow="hidden">
        <Box position="absolute" top="26px" display="flex" justifyContent="center" width="100%">
          <Link to="/">
            <Logo />
          </Link>
        </Box>
        <Box sx={{ paddingTop: '80px' }}>
          <LanguageMenu />
        </Box>
        {auth.isAuthenticated && (
          <>
            <Box
              position="absolute"
              top="50%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              width="100%"
              style={{ transform: 'translateY(-50%)' }}
            >
              <Tooltip title={g('menu.threads')} placement="right">
                <Link
                  to={
                    localStorage.getItem('lastChannel')
                      ? `/app/chat/${localStorage.getItem('lastChannel')}`
                      : '/app/chat'
                  }
                >
                  <Box position="relative">
                    <ChatIcon active={location.pathname.includes('/app/chat')} />
                    {!!unreadChannelsCount && (
                      <Box
                        bgcolor="#FF1B69"
                        width="18px"
                        height="18px"
                        borderRadius="50px"
                        position="absolute"
                        right="12px"
                        top="0px"
                        display="flex"
                        alignItems="center"
                        color="white"
                        fontSize="10px"
                        fontWeight={500}
                        justifyContent="center"
                      >
                        {unreadChannelsCount > 9 ? '9+' : unreadChannelsCount}
                      </Box>
                    )}
                  </Box>
                </Link>
              </Tooltip>
              <Tooltip title={g('menu.library')} placement="right">
                <Link to="/app">
                  <Icon.VideoList active={location.pathname === '/app'} />
                </Link>
              </Tooltip>
              <Tooltip title={g('menu.contacts')} placement="right">
                <Link to="/app/contacts">
                  <ContactsIcon active={location.pathname.includes('/app/contacts')} />
                </Link>
              </Tooltip>
            </Box>
            <Box
              position="absolute"
              bottom="16px"
              width="100%"
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              {auth.user?.id !== process.env.REACT_APP_SUPPORT_ID && (
                <Tooltip title={g('menu.support')} placement="right">
                  <Link to="/app/support">
                    <Box position="relative">
                      <Icon.Support active={location.pathname.includes('/app/support')} />
                      {isSupportChannelUnread && (
                        <Box
                          bgcolor="#FF1B69"
                          width="10px"
                          height="10px"
                          borderRadius="40px"
                          position="absolute"
                          right="15px"
                          top="5px"
                        />
                      )}
                    </Box>
                  </Link>
                </Tooltip>
              )}
              <Tooltip title={g('menu.settings')} placement="right">
                <Link to="/app/settings">
                  <Icon.IconSettings active={location.pathname.includes('/app/settings')} />
                </Link>
              </Tooltip>
              <Tooltip title={g('menu.logOut')} placement="right">
                <div style={{ cursor: 'pointer' }} onClick={logout}>
                  <Icon.LogoutIcon />
                </div>
              </Tooltip>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default DesktopMenu
