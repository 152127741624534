import React from 'react'
import { Box, Typography } from '@mui/material'
import PluginArrow from '../../../../common/icons/PluginArrow'
import ChromePluginImg from '../../../../common/assets/chrome-plugin.png'
import Button from '../../../../components/_refactored/Button'
import { useTranslation } from 'react-i18next'

interface IProps {
  isMobile: boolean
}

const ExtensionModal = (props: IProps) => {
  const { t } = useTranslation('library')
  return (
    <Box position="relative">
      <Typography
        variant="body1"
        align="center"
        style={{ fontWeight: 500, fontSize: '18px', maxWidth: '500px', margin: '0 auto' }}
      >
        {t('videoList.extensionInstallTutorialModal.title')}
      </Typography>
      <Box display="flex" alignItems="center" flexDirection="column" margin="20px 0">
        <Box display={props.isMobile ? 'none' : 'block'} position="absolute" top="60px" right="95px">
          <PluginArrow />
        </Box>
        <img src={ChromePluginImg} style={{ width: '90%', marginBottom: '50px' }} alt="chrome-plugin" />
        <a
          href="https://chrome.google.com/webstore/detail/screensight/hhnbfppcjpaacnjecideabahjbdgppaf"
          style={{ width: 'fit-content' }}
        >
          <Button variant="contained" color="primary">
            {t('videoList.common.extensionWebStoreBtn')}
          </Button>
        </a>
      </Box>
    </Box>
  )
}

export default ExtensionModal
