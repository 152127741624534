import * as React from 'react'
import { Box } from '@mui/material'

interface IModalLibraryLayout {
  children: React.ReactNode
}

const ModalLibraryLayout = (props: IModalLibraryLayout) => {
  return <Box width="100%">{props.children}</Box>
}

export default ModalLibraryLayout
