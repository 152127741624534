import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import CognitoApolloClient from '../../../../../common/clients/CognitoApolloClient'
import { getApplicationConfiguration, getVideo, listVideoAudience } from '../../../../../graphql/queries'
import gql from 'graphql-tag'
import { useLocation, useParams } from 'react-router'
import IamApolloClient from '../../../../../common/clients/IamApolloClient'
import { useMediaQuery, useTheme } from '@mui/material'
import {
  AccessType,
  GetApplicationConfigurationQuery,
  GetVideoQuery,
  GetVideoQueryVariables,
  ListVideoAudienceQuery,
  ListVideoAudienceQueryVariables,
  OnUpdateVideoSubscription,
  OnUpdateVideoSubscriptionVariables,
  screensightVideo,
} from '../../../../../API'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { AuthContext } from '../../../../../common/providers/AuthStatusProvider'
import { VideoJsPlayer } from 'video.js'
import awsmobile from '../../../../../aws-exports'
import { escapeBtoaString } from 'common/utils/escapeBtoaString'
import { onUpdateVideo } from '../../../../../common/api/graphql/subscriptions'
import useGetVideoFragment from '../../../../../common/api/hooks/useGetVideoFragment'
import { Storage } from 'aws-amplify'
import useAmplifySubscription from '../../../../../common/clients/useAmplifySubscription'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../Chat/common/redux/store/store'

const useVideoItemHook = () => {
  const auth = useContext(AuthContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const location = useLocation()
  const { id: paramVideoId } = useParams() as { id: string }
  const playerRef = useRef<VideoJsPlayer | null>(null)
  const [isNameAdded, setIsNameAdded] = useState(!!localStorage.getItem('guestCustomerId')?.length || false)
  const [showAccessModal, setShowAccessModal] = useState(false)
  const [poster, setPoster] = useState<string | undefined>(undefined)
  const onPostEventSubscriptionData = useSelector((state: RootState) => state.chat.onPostEventSubscriptionData)

  const { loading: videoLoading, data: videoData } = useQuery<GetVideoQuery, GetVideoQueryVariables>(gql(getVideo), {
    variables: { id: paramVideoId },
    skip: !paramVideoId || !auth.isInitialized,
    client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  })

  const cacheVideo = useGetVideoFragment(videoData?.getVideo?.id)
  const videoThumbnail = videoData?.getVideo?.thumbnailObject?.key || cacheVideo?.thumbnailObject?.key
  const isGif =
    videoData?.getVideo?.thumbnailObject?.key.includes('.gif') || cacheVideo?.thumbnailObject?.key.includes('gif')

  const { loading: videoViewersLoading, data: videoViewersData } = useQuery<
    ListVideoAudienceQuery,
    ListVideoAudienceQueryVariables
  >(gql(listVideoAudience), {
    variables: { videoId: videoData?.getVideo?.id },
    skip: !videoData?.getVideo?.id || auth.user?.pending || !auth.isAuthenticated,
    client: CognitoApolloClient,
  })

  const { loading: appConfigLoading, data: appConfigData } = useQuery<
    GetApplicationConfigurationQuery,
    ListVideoAudienceQueryVariables
  >(gql(getApplicationConfiguration), {
    skip: !paramVideoId || auth.user?.pending,
    client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
  })

  useAmplifySubscription<OnUpdateVideoSubscription, OnUpdateVideoSubscriptionVariables>(
    onUpdateVideo,
    {
      variables: { id: videoData?.getVideo?.id },
      skip: auth.user?.pending || !auth.isAuthenticated,
    },
    'useVideoItem',
  )

  useEffect(() => {
    if (onPostEventSubscriptionData?.onPostEvent?.post?.video?.transcriptionSummary) {
      CognitoApolloClient.cache.writeFragment({
        id: `screensightVideo:${onPostEventSubscriptionData?.onPostEvent?.post?.id}`,
        fragment: gql`
          fragment video on screensightVideo {
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject
          }
        `,
        data: {
          transcriptionSummaryStatus: onPostEventSubscriptionData?.onPostEvent?.post?.video?.transcriptionSummaryStatus,
          transcriptionSummaryError: onPostEventSubscriptionData?.onPostEvent?.post?.video?.transcriptionSummaryError,
          transcriptionSummary: onPostEventSubscriptionData?.onPostEvent?.post?.video?.transcriptionSummary,
          transcriptionObject: onPostEventSubscriptionData?.onPostEvent?.post?.video?.transcriptionObject,
        },
      })
    }
  }, [onPostEventSubscriptionData?.onPostEvent?.post?.video?.transcriptionSummary])

  useEffect(() => {
    if (!videoData?.getVideo?.thumbnailObject?.key && !cacheVideo?.thumbnailObject?.key) return
    if (isGif) {
      const getImageFromS3 = async () => {
        const img = await Storage.get(videoThumbnail || '', {
          customPrefix: {
            public: '',
          },
        })
        setPoster(img)
      }
      getImageFromS3()
    } else {
      getImageFromCloudFront()
    }
  }, [videoData?.getVideo?.thumbnailObject?.key, cacheVideo?.thumbnailObject?.key])

  useEffect(() => {
    if (location.pathname.includes('/video') && !videoLoading && videoData?.getVideo === null) {
      history.replace('/page-not-found')
    }
  }, [videoData?.getVideo, videoLoading])

  const getImageFromCloudFront = () => {
    const imageRequest = JSON.stringify({
      bucket: awsmobile.aws_user_files_s3_bucket,
      key: videoData?.getVideo?.thumbnailObject?.key || cacheVideo?.thumbnailObject?.key,
    })
    const url = `https://${process.env.REACT_APP_CLOUDFRONT_DOMAIN_NAME}/${btoa(escapeBtoaString(imageRequest))}`
    setPoster(url)
  }

  const isUserVideoOwner = useMemo(() => {
    if (!auth.user?.id) return false
    return videoData?.getVideo?.customerId === auth.user?.id
  }, [videoData?.getVideo?.customerId, auth.user?.id])

  const hideAccessModal = (data: screensightVideo) => {
    setShowAccessModal(false)
  }

  useEffect(() => {
    if (
      videoData?.getVideo?.accessType === AccessType.protected &&
      !isUserVideoOwner &&
      !videoData?.getVideo?.fileObject
    ) {
      setShowAccessModal(true)
    }
  }, [isUserVideoOwner, videoData?.getVideo?.accessType, videoData?.getVideo?.fileObject])

  return {
    loading: videoLoading || auth.user?.pending || videoViewersLoading || appConfigLoading,
    playerRef,
    isMobile,
    authorized: !auth.user?.pending && auth.isAuthenticated,
    isUserVideoOwner,
    videoId: videoData?.getVideo?.id,
    customerId: videoData?.getVideo?.customerId,
    /*filePresignedUrl is necessary here, because the cache gets updated to null
    on setVideoViewedByMutation mutation even if there is no-cache policy */
    filePresignedUrl: videoData?.getVideo?.filePresignedUrl,
    width: videoData?.getVideo?.width,
    height: videoData?.getVideo?.height,
    isNameAdded,
    setIsNameAdded,
    videoViewersData,
    appConfigData,
    showAccessModal,
    poster,
    hideAccessModal,
    fileObject: videoData?.getVideo?.fileObject,
  }
}

export default useVideoItemHook
