import React, { memo, RefObject, useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import { Tooltip, Typography, useTheme } from '@mui/material'
import KeyItem from './KeyItem'
import RecorderStateContext from '../../../common/providers/RecorderStateProvider/RecorderStateContext'

interface IProps {
  modalHelper?: boolean
  prompterRef?: RefObject<HTMLTextAreaElement>
}
const helperTexts = {
  UP: 'Up press: Paragraph up',
  DOWN: 'Down press: Paragraph down',
  LEFT: 'Left press: Slow down speed',
  RIGHT: 'Right press: Speed up scroll',
  empty: '',
}

const KeyboardArrows = (props: IProps) => {
  const theme = useTheme()
  const [active, setActive] = useState<undefined | 'UP' | 'RIGHT' | 'DOWN' | 'LEFT'>('UP')
  const { state: recorderState, dispatch: recorderDispatcher } = useContext(RecorderStateContext)

  const handleKeyEvent = (e: KeyboardEvent) => {
    switch (e.code) {
      case 'ArrowUp': {
        setActive('UP')
        break
      }
      case 'ArrowRight': {
        setActive('RIGHT')
        break
      }
      case 'ArrowDown': {
        setActive('DOWN')
        break
      }
      case 'ArrowLeft': {
        setActive('LEFT')
        break
      }
      default:
        break
    }
  }
  const resetKeyboard = () => {
    setTimeout(() => {
      setActive(undefined)
    }, 2000)
  }

  useEffect(() => {
    if (props.modalHelper) return
    setActive(undefined)
    window.addEventListener('keydown', handleKeyEvent)
    return () => window.removeEventListener('keydown', handleKeyEvent)
  }, [props.modalHelper])

  useEffect(() => {
    if (props.modalHelper) return
    window.addEventListener('keyup', resetKeyboard)
    return () => window.removeEventListener('keyup', resetKeyboard)
  }, [props.modalHelper])

  useEffect(() => {
    if (!props.modalHelper) return
    const keyboardInterval = setInterval(() => {
      setActive((prevState) => {
        if (prevState === 'UP') {
          return 'DOWN'
        } else if (prevState === 'DOWN') {
          return 'LEFT'
        } else if (prevState === 'LEFT') {
          return 'RIGHT'
        } else {
          return 'UP'
        }
      })
    }, 2000)
    return () => {
      clearInterval(keyboardInterval)
    }
  }, [props.modalHelper])
  const handleUpClickEvent = () => {
    if (props.modalHelper || !props?.prompterRef?.current) return
    props.prompterRef.current.scrollTo(0, props.prompterRef.current?.scrollTop - 40)
  }

  const handleRightClickEvent = () => {
    if (props.modalHelper || !props?.prompterRef?.current) return
    const newVal = recorderState.prompter.textSpeed + 10
    if (recorderState.prompter.textSpeed <= 80) {
      recorderDispatcher({
        action: 'UPDATE_PROMPTER_STATE',
        value: { textSpeed: newVal },
      })
    }
  }

  const handleDownClickEvent = () => {
    if (props.modalHelper || !props?.prompterRef?.current) return
    props.prompterRef.current.scrollTo(0, props.prompterRef.current?.scrollTop + 40)
  }

  const handleLeftClickEvent = () => {
    if (props.modalHelper || !props?.prompterRef?.current) return
    const newVal = recorderState.prompter.textSpeed - 10
    if (recorderState.prompter.textSpeed >= 10 && recorderState.prompter.textSpeed < 20) {
      recorderDispatcher({
        action: 'UPDATE_PROMPTER_STATE',
        value: { textSpeed: 10 },
      })
    }
    if (recorderState.prompter.textSpeed >= 20) {
      recorderDispatcher({
        action: 'UPDATE_PROMPTER_STATE',
        value: { textSpeed: newVal },
      })
    }
  }

  const keyDimension = props.modalHelper ? '60px' : '30px'
  return (
    <>
      <Box
        width={props.modalHelper ? '190px' : '142px'}
        height="70px"
        bgcolor={props.modalHelper ? 'transparent' : 'rgba(20, 35, 61, 0.8)'}
        borderRadius="10px"
        margin={props.modalHelper ? '0 auto' : '10px auto 0 auto'}
        position="relative"
        padding={props.modalHelper ? '0' : '5px 20px'}
      >
        <Box display="flex" width="100%" justifyContent="center">
          <KeyItem active={active === 'UP'} modalHelper={props.modalHelper}>
            <Tooltip title={helperTexts.UP}>
              <ArrowDropUpRoundedIcon
                style={{
                  color:
                    active === 'UP'
                      ? theme.palette.background.default
                      : props.modalHelper
                      ? theme.palette.secondary.main
                      : theme.palette.background.default,
                  width: keyDimension,
                  height: keyDimension,
                  cursor: !props.modalHelper ? 'pointer' : 'default',
                }}
                onClick={handleUpClickEvent}
              />
            </Tooltip>
          </KeyItem>
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%" margin="10px auto 0 auto">
          <KeyItem active={active === 'LEFT'} modalHelper={props.modalHelper}>
            <Tooltip title={helperTexts.LEFT}>
              <ArrowDropUpRoundedIcon
                style={{
                  color:
                    active === 'LEFT'
                      ? theme.palette.background.default
                      : props.modalHelper
                      ? theme.palette.secondary.main
                      : theme.palette.background.default,
                  width: keyDimension,
                  height: keyDimension,
                  transform: 'rotate(-90deg)',
                  cursor: !props.modalHelper ? 'pointer' : 'default',
                }}
                onClick={handleLeftClickEvent}
              />
            </Tooltip>
          </KeyItem>
          <KeyItem active={active === 'DOWN'} modalHelper={props.modalHelper}>
            <Tooltip title={helperTexts.DOWN}>
              <ArrowDropDownRoundedIcon
                style={{
                  color:
                    active === 'DOWN'
                      ? theme.palette.background.default
                      : props.modalHelper
                      ? theme.palette.secondary.main
                      : theme.palette.background.default,
                  width: keyDimension,
                  height: keyDimension,
                  cursor: !props.modalHelper ? 'pointer' : 'default',
                }}
                onClick={handleDownClickEvent}
              />
            </Tooltip>
          </KeyItem>
          <KeyItem active={active === 'RIGHT'} modalHelper={props.modalHelper}>
            <Tooltip title={helperTexts.RIGHT}>
              <ArrowDropUpRoundedIcon
                style={{
                  color:
                    active === 'RIGHT'
                      ? theme.palette.background.default
                      : props.modalHelper
                      ? theme.palette.secondary.main
                      : theme.palette.background.default,
                  width: keyDimension,
                  height: keyDimension,
                  transform: 'rotate(90deg)',
                  cursor: !props.modalHelper ? 'pointer' : 'default',
                }}
                onClick={handleRightClickEvent}
              />
            </Tooltip>
          </KeyItem>
        </Box>
        {!props.modalHelper && active ? (
          <Box
            position="absolute"
            top="69px"
            left="-30px"
            zIndex={1000}
            bgcolor="rgba(20, 35, 61, 0.8)"
            width="200px"
            padding="5px"
            color="white"
            borderRadius="10px"
          >
            <Typography variant="body2" style={{ color: 'white', fontSize: '12px' }} align="center">
              {helperTexts[active]}
            </Typography>
          </Box>
        ) : null}
      </Box>
      {props.modalHelper ? (
        <Typography align="center" variant="body2" color="primary" style={{ marginTop: '60px' }}>
          {helperTexts[active || 'empty']}
        </Typography>
      ) : null}
    </>
  )
}

export default memo(KeyboardArrows)
