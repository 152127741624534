import * as React from 'react'

const ScreenSightLogoSmall = () => {
  return (
    <div>
      <svg width="53" height="30" viewBox="0 0 53 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10188_238716)">
          <path
            d="M45.3737 7.13307e-07L14.4873 0.0396422C13.7896 0.0330652 13.1167 0.301828 12.6113 0.78899C12.1059 1.27615 11.8076 1.94344 11.7797 2.64948C11.7718 3.00152 11.8335 3.3516 11.9612 3.67914C12.0888 4.00668 12.2798 4.30509 12.5229 4.55684C12.7661 4.80859 13.0564 5.00859 13.3769 5.14511C13.6975 5.28163 14.0417 5.35191 14.3893 5.35183L45.103 5.30888C45.7951 5.30888 46.4589 5.58732 46.9483 6.08295C47.4377 6.57859 47.7127 7.25082 47.7127 7.95175L47.7324 22.0218C47.7324 22.7227 47.4574 23.395 46.968 23.8906C46.4786 24.3862 45.8147 24.6647 45.1226 24.6647L31.2289 24.6845C30.5368 24.6845 29.8729 24.406 29.3835 23.9104C28.8941 23.4148 28.6191 22.7425 28.6191 22.0416V13.0096C28.6265 12.3028 28.3614 11.6209 27.8801 11.1089C27.3988 10.5969 26.7394 10.295 26.042 10.2676C25.6944 10.2596 25.3487 10.3222 25.0252 10.4514C24.7018 10.5807 24.4071 10.7741 24.1586 11.0203C23.91 11.2665 23.7124 11.5605 23.5776 11.8851C23.4428 12.2098 23.3734 12.5584 23.3734 12.9104V22.2926C23.3718 23.3066 23.568 24.311 23.9509 25.248C24.3337 26.185 24.8958 27.036 25.6045 27.7523C26.3134 28.4686 27.155 29.0358 28.0809 29.4216C29.007 29.8073 29.9992 30.0038 31.0005 30L45.4129 29.9801C47.4276 29.9766 49.3586 29.1632 50.7814 27.7186C52.2042 26.274 53.0025 24.3164 53.0008 22.2761L52.9812 7.68085C52.9786 5.64228 52.1769 3.68815 50.7523 2.2479C49.3275 0.807651 47.3965 -0.000876655 45.3835 7.13307e-07"
            fill="#D7D7DB"
          />
          <path
            d="M34.706 15.0079C34.7117 15.7628 34.9557 16.4963 35.4023 17.1007C35.849 17.7051 36.4749 18.1491 37.1886 18.3677C37.5064 18.4625 37.8358 18.5125 38.1671 18.5163C39.0864 18.5119 39.9666 18.1395 40.6156 17.4804C41.2647 16.8212 41.63 15.9289 41.6316 14.9979C41.6267 14.2424 41.3829 13.5084 40.9363 12.9032C40.4895 12.2981 39.8633 11.8536 39.1491 11.6349C38.8318 11.5373 38.5021 11.4872 38.1705 11.4863C37.2505 11.4898 36.3692 11.8623 35.7199 12.5223C35.0706 13.1825 34.706 14.0762 34.706 15.0079Z"
            fill="#D7D7DB"
          />
          <path
            d="M15.4955 21.4104H8.5992C7.90402 21.4104 7.23732 21.6901 6.74576 22.1879C6.25418 22.6858 5.97803 23.3609 5.97803 24.0649C5.97803 24.7688 6.25418 25.4441 6.74576 25.9418C7.23732 26.4397 7.90402 26.7193 8.5992 26.7193H15.4988C16.1939 26.722 16.8617 26.4448 17.3551 25.9489C17.8485 25.453 18.1271 24.7788 18.1297 24.0748C18.1323 23.3708 17.8586 22.6946 17.3689 22.195C16.8792 21.6953 16.2135 21.413 15.5183 21.4104"
            fill="#D7D7DB"
          />
          <path
            d="M15.4791 10.7991L2.6228 10.8156C1.92719 10.8156 1.26007 11.0955 0.768201 11.5936C0.27633 12.0917 0 12.7673 0 13.4717C0 14.1762 0.27633 14.8518 0.768201 15.3499C1.26007 15.848 1.92719 16.1279 2.6228 16.1279L15.4759 16.1113C16.1715 16.1113 16.8386 15.8315 17.3305 15.3334C17.8223 14.8352 18.0987 14.1596 18.0987 13.4552C18.0987 12.7507 17.8223 12.0752 17.3305 11.5771C16.8386 11.079 16.1715 10.7991 15.4759 10.7991"
            fill="#D7D7DB"
          />
        </g>
        <defs>
          <clipPath id="clip0_10188_238716">
            <rect width="53" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default ScreenSightLogoSmall
