import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import Modal from 'components/_refactored/Modal'
import useAddNewContact from './common/hooks/useAddNewContact'
import FormTextField from '../../Form/FormTextField'
import Button from '../../Button'

interface IAddNewContactModal {
  email: string
  handleSetVerifiedEmails: (email: string) => void
}
const AddNewContactModal = (props: IAddNewContactModal) => {
  const { t } = useTranslation('library')
  const { t: g } = useTranslation('general')
  const { handleSubmit, form, loading } = useAddNewContact(props.email)

  return (
    <Modal open={true}>
      <Box>
        <Typography variant="h6" align="center" style={{ fontWeight: 500, fontSize: '18px' }}>
          {t('videoItem.addNewContactModal.title')}
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          style={{ fontWeight: 400, fontSize: '15px', marginTop: '20px', marginBottom: '40px' }}
        >
          {t('videoItem.addNewContactModal.subtitle')}
        </Typography>
        <FormTextField
          form={form}
          fieldName={'firstName'}
          topLabel={g('common.firstName')}
          rules={{ validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true) }}
        />
        <FormTextField
          form={form}
          fieldName={'lastName'}
          topLabel={g('common.lastName')}
          rules={{ validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true) }}
        />
        <FormTextField
          form={form}
          fieldName={'email'}
          topLabel={g('common.email')}
          disabled
          rules={{ validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true) }}
        />
        <Box display="flex" alignItems="center" justifyContent="center" marginTop="10px">
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginRight: '10px' }}
            onClick={() => props.handleSetVerifiedEmails(props.email)}
          >
            {t('common.skipBtn')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              await handleSubmit()
              props.handleSetVerifiedEmails(props.email)
            }}
            loading={loading}
          >
            {g('common.addContactBtn')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default AddNewContactModal
