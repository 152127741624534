import React from 'react'
import { useTranslation } from 'react-i18next'
import useChannelSidebar from '../../../ChannelsSidebar/common/hooks/useChannelSidebar'
import Group from '../blocks/Group'
import { screensightChannel } from '../../../../../../../API'
import { Box } from '@mui/material'
import { Skeleton } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'

type ContentProps = {
  closeChannels: () => void
}

const Content = (props: ContentProps) => {
  const { t } = useTranslation('chat')
  const state = useChannelSidebar()

  return (
    <InfiniteScroll
      style={{ overflowX: 'hidden' }}
      dataLength={state.dataLength || 0}
      next={state.loadNextChannels}
      hasMore={state.hasMore}
      scrollableTarget="chat__channels-drawer-scrollable-box"
      loader={
        <Box>
          <Box width="100%" display="flex" padding="0 15px" marginBottom="14px">
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton
              variant="rectangular"
              width={170}
              height={24}
              style={{ marginLeft: '10px', borderRadius: '6px' }}
            />
          </Box>
          <Box width="100%" display="flex" padding="0 15px" marginBottom="14px">
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton
              variant="rectangular"
              width={170}
              height={24}
              style={{ marginLeft: '10px', borderRadius: '6px' }}
            />
          </Box>
          <Box width="100%" display="flex" padding="0 15px" marginBottom="28px">
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton
              variant="rectangular"
              width={170}
              height={24}
              style={{ marginLeft: '10px', borderRadius: '6px' }}
            />
          </Box>
        </Box>
      }
    >
      <Group
        closeChannels={props.closeChannels}
        unreadChannels={state.unreadChannels}
        channels={[...state.recentChannels, ...state.directChannels] as screensightChannel[]}
        label={t('channelsSidebar.directMessages')}
      />
    </InfiniteScroll>
  )
}

export default Content
