import * as React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../../../components/_refactored/Modal'
import OsxScreenRecSettings from '../../../../../assets/osxScreenRecSetting.png'
import Button from '../../../../../../components/_refactored/Button'
import { theme } from '../../../../../providers/MuiThemeProvider'
import MediaRecorderHandler from '../../../../../providers/RecorderStateProvider/MediaRecorderHandler'

export const ScreenRecordingPermissionsErrorModal = () => {
  const { t: g } = useTranslation('general')
  return (
    <Modal open={true} boxStyle={{ maxHeight: '90%', width: '590px', maxWidth: '90vw', overflowY: 'auto' }}>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography textAlign="center" variant="h6" fontWeight="bold">
          {g('recordControl.screenRecordingErrorModal.title')}
        </Typography>
        <Typography
          textAlign="center"
          variant="body2"
          color={theme.palette.secondary.main}
          style={{ padding: '15px 0' }}
        >
          {g('recordControl.screenRecordingErrorModal.subtitle')}
        </Typography>
        <Typography textAlign="center" variant="body2" color={theme.palette.secondary.main} fontWeight="bold">
          {g('recordControl.screenRecordingErrorModal.instruction')}
        </Typography>
        <Box alignItems="center" justifyContent="center" textAlign="center" paddingTop="30px">
          <img
            src={OsxScreenRecSettings}
            height={300}
            style={{ maxWidth: '100%', maxHeight: '100%', height: 'fit-content', width: '90%' }}
            alt={'SystemScreenRecordingSetting'}
          />
        </Box>
        <Typography variant="caption" color={theme.palette.secondary.main} style={{ padding: '15px 0' }}>
          {g('recordControl.screenRecordingErrorModal.restartPermissions')}
        </Typography>
        <Button variant="outlined" color="secondary" onClick={() => MediaRecorderHandler.cancelRecording()}>
          {g('common.gotItBtn')}
        </Button>
      </Box>
    </Modal>
  )
}

export default ScreenRecordingPermissionsErrorModal
