import React, { useMemo } from 'react'
import { Box, BoxProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const width = '100%'
const height = '42px'
const useStyles = makeStyles({
  root: {
    position: 'relative',
    width,
    height,
    lineHeight: height,

    fontSize: 16,
    fontWeight: 500,

    overflow: 'hidden',
    borderRadius: 30,
  },
  bar: {
    position: 'absolute',
    top: 0,
    height: '100%',
    overflow: 'hidden',
  },

  positive: {
    backgroundColor: '#2483F7',
    left: 0,
  },
  negative: {
    backgroundColor: '#F3F3F3',
    right: 0,
  },

  positiveText: {
    position: 'absolute',
    height: 42,
    textAlign: 'center',
    left: 0,
    color: '#FFF',
  },
  negativeText: {
    position: 'absolute',
    height: 42,
    textAlign: 'center',
    right: 0,
    color: '#707070',
  },
})

interface ProgressBarProps extends BoxProps {
  progress: number
}

const ProgressBar = (props: ProgressBarProps) => {
  const { progress } = props
  const { root, bar, positive, negative, positiveText, negativeText } = useStyles()

  const positiveProgress = useMemo(() => `${progress * 100}%`, [progress])
  const negativeProgress = useMemo(() => `${(1 - progress) * 100}%`, [progress])
  const fixedProgress = useMemo(() => `${(progress * 100).toFixed(0)}%`, [progress])

  return (
    <Box className={root}>
      <Box className={`${bar} ${positive}`} style={{ width: positiveProgress }}>
        <span className={positiveText} style={{ width: `calc(100% / ${progress})` }}>
          {fixedProgress}
        </span>
      </Box>

      <Box className={`${bar} ${negative}`} style={{ width: negativeProgress }}>
        <span className={negativeText} style={{ width: `calc(100% / ${1 - progress})` }}>
          {fixedProgress}
        </span>
      </Box>
    </Box>
  )
}

export default ProgressBar
