import React, { useEffect, useState } from 'react'
import { saveAs } from 'file-saver'
import { getCloudFrontUrl } from '../../../../../../../../common/utils/getCloudFrontUrl'
import { Box, IconButton } from '@mui/material'
import DownloadIcon from '../../../../../../../../common/icons/DownloadIcon'
import { Close } from '@mui/icons-material'

interface IImagePostPreviewProps {
  handleCloseImagePreview: () => void
  imagePreviewKey: string | null
  isGif?: boolean
}
const ImagePostPreview = (props: IImagePostPreviewProps) => {
  const [imgSrc, setImgSrc] = useState('')
  const downloadImage = async () => {
    saveAs(await getCloudFrontUrl(props.imagePreviewKey || '', true))
  }

  useEffect(() => {
    const getData = async () => {
      if (!props.imagePreviewKey) return
      const res = (await getCloudFrontUrl(props.imagePreviewKey || '', true)) || ''
      setImgSrc(res)
    }
    getData()
  }, [props.imagePreviewKey])

  return (
    <Box
      position="absolute"
      left="0"
      top="0"
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex={999999999}
    >
      <Box
        width="100vw"
        height="100vh"
        position="absolute"
        left="0"
        top="0"
        bgcolor="rgba(20, 35, 61, 0.8)"
        onClick={props.handleCloseImagePreview}
        zIndex={100}
      />
      <IconButton
        sx={{
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.7)',
          },
          position: 'absolute',
          top: '20px',
          right: '70px',
          zIndex: 100000,
          background: 'white',
          boxShadow: 'none',
        }}
        onClick={downloadImage}
      >
        <DownloadIcon />
      </IconButton>
      <IconButton
        sx={{
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.7)',
          },
          position: 'absolute',
          top: '20px',
          right: '20px',
          zIndex: 100000,
          background: 'white',
          boxShadow: 'none',
        }}
        onClick={props.handleCloseImagePreview}
      >
        <Close />
      </IconButton>
      <img
        alt="chat-gallery"
        src={imgSrc}
        style={{ maxWidth: '80vw', maxHeight: '80vh', zIndex: 100000, position: 'relative', borderRadius: '10px' }}
      />
    </Box>
  )
}

export default ImagePostPreview
