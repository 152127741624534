import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import PhotoImg from './assets/photo.png'
import AddIcon from '../../../../common/assets/Icon-Add-Photo.png'
import Modal from '../../../../components/_refactored/Modal'
import { Cropper } from 'react-cropper'
import Button from '../../../../components/_refactored/Button'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import TrashIcon from '../../../../common/icons/TrashIcon'
import { IOnBoardingPhoto } from '../interfaces'
import { useTranslation } from 'react-i18next'

const OnBoardingPhoto = (props: IOnBoardingPhoto) => {
  const auth = useContext(AuthContext)
  const { t } = useTranslation('library')
  const { t: g } = useTranslation('general')

  return (
    <>
      <Box
        width={props.isMobile ? '100%' : '50%'}
        paddingRight={props.isMobile ? '0' : '16px'}
        paddingTop={props.isMobile ? '0' : '32px'}
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Typography variant="body1" style={{ fontWeight: 500, fontSize: '20px', marginBottom: '10px' }}>
          {auth.user?.profilePhotoUrl
            ? t('videoList.onBoardingModal.addedPhotoTitle')
            : t('videoList.onBoardingModal.addPhotoTitle')}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ fontSize: '15px', marginBottom: '30px' }}>
          {auth.user?.profilePhotoUrl
            ? t('videoList.onBoardingModal.addedPhotoSubtitle')
            : t('videoList.onBoardingModal.addPhotoSubtitle')}
        </Typography>
        {auth.user?.profilePhotoUrl ? (
          <Box width="100%" display="flex" justifyContent="center">
            <Box
              position="relative"
              onMouseOver={props.showRemoveIcon}
              onMouseLeave={props.hideRemoveIcon}
              width="110px"
              height="110px"
            >
              {props.removePhotoIconVisible && (
                <Box
                  width="110px"
                  height="110px"
                  position="absolute"
                  top="0"
                  left="0"
                  style={{ background: 'rgba(20, 35, 61, 0.7)', cursor: 'pointer' }}
                  borderRadius="100px"
                  zIndex={100}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={props.clearUserPhoto}
                >
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="body2" style={{ color: 'white' }}>
                      {g('common.deleteBtn')}
                    </Typography>
                    <TrashIcon color="white" />
                  </Box>
                </Box>
              )}
              <img
                src={auth.user.profilePhotoUrl}
                alt="User image"
                style={{
                  height: '110px',
                  width: '110px',
                  marginRight: '6px',
                  objectFit: 'cover',
                  borderRadius: '100px',
                }}
              />
            </Box>
          </Box>
        ) : (
          <>
            <Box width="100%" display="flex" justifyContent="center">
              <Box
                borderRadius="100px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor="#EEF0F6"
                width="110px"
                height="110px"
                style={{ cursor: 'pointer' }}
                {...props.dropzone.getRootProps()}
              >
                <input {...props.dropzone.getInputProps()} />
                <img src={AddIcon} style={{ width: '40px', height: '40px', objectFit: 'cover' }} />
              </Box>
            </Box>
            {props.image && (
              <Modal open={true} onClose={props.handleCloseCropperModal} style={{ position: 'relative' }}>
                <>
                  <Box
                    width="100%"
                    height="400px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    overflow="hidden"
                    border="1px solid gray"
                  >
                    <Cropper
                      style={{ height: 400, width: '100%' }}
                      zoomTo={0}
                      initialAspectRatio={1}
                      aspectRatio={1}
                      preview=".img-preview"
                      src={props.image as string}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                      guides={true}
                      draggable
                      ref={props.cropperRef}
                    />
                  </Box>
                  <Box display="flex" justifyContent="flex-end" paddingTop="16px">
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginRight: '8px' }}
                      onClick={props.handleCloseCropperModal}
                    >
                      {g('common.cancelBtn')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={props.handleImageCroppingSubmit}>
                      {g('common.submitBtn')}
                    </Button>
                  </Box>
                </>
              </Modal>
            )}
          </>
        )}
      </Box>
      <Box
        width="50%"
        height="100%"
        paddingLeft="16px"
        display={props.isMobile ? 'none' : 'flex'}
        alignItems="center"
        justifyContent="center"
      >
        <img src={PhotoImg} />
      </Box>
    </>
  )
}

export default OnBoardingPhoto
