import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { useParams } from 'react-router'
import FullScreenLoader from '../../../../components/_refactored/Loaders/FullScreenLoader'
import Button from '../../../../components/_refactored/Button'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ContactAvatar from '../../../../containers/Contacts/ContactAvatar'
import { GetContactQuery } from '../../../../API'
import FormTextField from '../../../../containers/Contacts/FormTextField'
import { UseFormMethods } from 'react-hook-form/dist/types/form'
import { INewContactForm } from '../../../../containers/Contacts/NewContactModal/hooks/useNewContactModal'
import { getUsername } from '../../../../common/utils/getUsername'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../../../components/_refactored/PageTitle/PageTitle'

interface ISingleContactEditView {
  loading: boolean
  saving: boolean
  contact?: GetContactQuery
  form: UseFormMethods<INewContactForm>
  handleEditContact: () => void
}

const SingleContactEditView = (props: ISingleContactEditView) => {
  const history = useHistory()
  const theme = useTheme()
  const { t } = useTranslation('contacts')
  const { t: g } = useTranslation('general')
  const { id: contactId } = useParams() as { id: string }

  return (
    <>
      <PageTitle name={g('pageName.editContact')} />
      <Box marginTop="80px" maxWidth="100vw">
        {props.loading && <FullScreenLoader withoutSidebar />}
        <Button
          style={{ maxWidth: '250px' }}
          startIcon={<ArrowBackIosIcon style={{ color: theme.palette.text.secondary }} />}
          onClick={() => history.push(`/app/contacts/${contactId}`)}
        >
          <Typography variant="body1" color="textSecondary" style={{ fontWeight: 500, fontSize: '20px' }} noWrap>
            {getUsername(
              props.contact?.getContact?.firstName,
              props.contact?.getContact?.lastName,
              props.contact?.getContact?.email,
            )}
          </Typography>
        </Button>
        <Grid container spacing={6} style={{ padding: '30px' }}>
          <Grid item xs={12} md={3} style={{ borderRight: '1px solid #BCBCC3' }}>
            <ContactAvatar
              width={97}
              height={97}
              image={props.contact?.getContact?.contactCustomerMeta?.items?.[0]?.profilePhoto?.key}
              name={getUsername(
                props.contact?.getContact?.firstName,
                props.contact?.getContact?.lastName,
                props.contact?.getContact?.email,
              )}
              fontSize="32px"
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Box display="flex" justifyContent="flex-start" alignItems="center" marginBottom="40px">
              <Typography variant="h6" style={{ fontWeight: 500, fontSize: '20px' }}>
                {t('singleContactEdit.editInfoTitle')}
              </Typography>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  fieldName="firstName"
                  rules={{
                    validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
                  }}
                  form={props.form}
                  topLabel={g('common.firstName')}
                  marginBottom="0"
                  data-testid="contact-edit-first-name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  fieldName="lastName"
                  rules={{
                    validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
                  }}
                  form={props.form}
                  topLabel={g('common.lastName')}
                  marginBottom="0"
                  data-testid="contact-edit-last-name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField fieldName="email" form={props.form} topLabel={g('common.email')} marginBottom="0" />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  fieldName="phone"
                  rules={{
                    validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
                  }}
                  form={props.form}
                  topLabel={g('common.phone')}
                  marginBottom="0"
                  data-testid="contact-edit-phone"
                />
              </Grid>
            </Grid>
            <Box display="flex" alignItems="center" marginTop="40px">
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: '16px' }}
                disabled={!props.form.formState.isDirty}
                loading={props.saving}
                onClick={props.handleEditContact}
                data-testid="contact-edit-submit-btn"
              >
                {g('common.saveChangesBtn')}
              </Button>
              <Button variant="text" color="secondary" onClick={() => history.push(`/app/contacts/${contactId}`)}>
                {g('common.cancelBtn')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default SingleContactEditView
