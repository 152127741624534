export const createReaction = /* GraphQL */ `
  mutation CreateReaction($input: CreateScreensightReactionInput!, $condition: ModelScreensightReactionConditionInput) {
    createReaction(input: $input, condition: $condition) {
      postId
      customerId
      unicode
      videoTimestamp
      createdAt
      updatedAt
      incrementBy
      channelId
    }
  }
`
