import React from 'react'

interface IProps {
  color?: string
}

const ScriptIcon = (props: IProps) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.2 2.52251e-05C3.04852 2.52251e-05 2.1 0.948545 2.1 2.10002V9.80001H0.7C0.514354 9.80003 0.336317 9.87378 0.205046 10.0051C0.0737743 10.1363 1.85618e-05 10.3144 0 10.5V11.9C0 13.0515 0.948521 14 2.1 14H8.4H9.1C10.2515 14 11.2 13.0515 11.2 11.9V4.20002H13.3C13.4856 4.2 13.6637 4.12624 13.795 3.99497C13.9262 3.8637 14 3.68566 14 3.50002V2.10002C14 0.999946 13.1317 0.0945483 12.0504 0.0150643C12.0009 0.00463868 11.9505 -0.000403704 11.9 2.52251e-05H4.2ZM11.9 1.40002C12.2955 1.40002 12.6 1.7045 12.6 2.10002V2.80002H11.2V2.10002C11.2 1.7045 11.5045 1.40002 11.9 1.40002ZM1.4 11.2H2.68242C2.75802 11.2125 2.83515 11.2125 2.91074 11.2H6.99316L7 11.9164C7 11.9173 7 11.9182 7 11.9191C7.00326 12.1589 7.05686 12.3856 7.13535 12.6H2.1C1.70448 12.6 1.4 12.2955 1.4 11.9V11.2Z"
        fill={props.color || 'white'}
      />
    </svg>
  )
}

export default ScriptIcon
