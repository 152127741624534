import React from 'react'
import { Box, CircularProgress } from '@mui/material'

const VideoListLoader = () => {
  return (
    <Box
      width="100vw"
      height="calc(100vh - 80px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      left="0"
      bgcolor="background.default"
      top="80px"
      zIndex={10}
    >
      <CircularProgress />
    </Box>
  )
}

export default VideoListLoader
