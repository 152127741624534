import Layout from '../ui/Layout'
import Header from '../ui/partials/Header'
import VerifyEmail from '../ui/blocks/VerifyEmail'
import NewPassword from '../ui/blocks/NewPassword'
import Content from '../ui/partials/Content'

export const ForgotPassword = {
  Layout,
  Header,
  Content,
  VerifyEmail,
  NewPassword,
}
