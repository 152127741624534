import React, { PropsWithChildren } from 'react'
import usePasswordHook from './hooks/usePassword.hook'

import PasswordView from './view/Password.view'
import PageTitle from '../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

export type PasswordProps = PropsWithChildren<any>

export default (props: PasswordProps) => {
  const state = usePasswordHook(props)
  const { t: g } = useTranslation('general')

  return (
    <>
      <PageTitle name={g('pageName.passwordSettings')} />
      <PasswordView {...state} {...props} />
    </>
  )
}
