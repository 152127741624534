import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useCallback, useState } from 'react'
import CopyIcon from '../../../common/icons/CopyIcon'
import { IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Checkmark from '../../../common/icons/Checkmark'

type Props = {
  videoId: string | null | undefined
  accessID: string | null | undefined
  isOnVideoList: boolean
}
const CopyLinkIconButton = (props: Props) => {
  const [copied, setCopied] = useState(false)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('library')

  const onCopied = useCallback(() => {
    setCopied(true)
    setTimeout(() => setCopied(false), 4000)
    setTimeout(() => setOpen(false), 800)
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <CopyToClipboard
      onCopy={onCopied}
      text={`${process.env.REACT_APP_SSIGHT_SHARING_URL}/${props.accessID || props.videoId}`}
    >
      <Tooltip
        title={copied ? t('common.copiedLinkBtn') : t('common.copyLinkBtn')}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        <IconButton
          style={{
            display: props.isOnVideoList ? '' : 'flex',
            backgroundColor: props.isOnVideoList ? '' : 'white',
            boxShadow: props.isOnVideoList ? 'none' : 'auto',
            margin: props.isOnVideoList ? '0px' : '0 0 0 15px',
          }}
          disabled={copied}
        >
          {copied ? (
            <>
              <Checkmark />
            </>
          ) : (
            <CopyIcon />
          )}
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  )
}
export default CopyLinkIconButton
