import React from 'react'
import { Controller } from 'react-hook-form'
import { isEmail } from 'react-multi-email'
import { Box, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Button from '../../../../../../../components/_refactored/Button'
import useSignUp from '../../../common/hooks/useSignUp'
import { useTranslation } from 'react-i18next'
import '../../../signUp.scss'
import { theme } from 'common/providers/MuiThemeProvider'
import ValidatorBlock from '../../../../../ui/components/ValidatorBlock'
import { useValidatePassword } from '../../../../../../../common/utils/useValidatePassword'

const UserForm = () => {
  const state = useSignUp()
  const { t } = useTranslation('auth')
  const { t: g } = useTranslation('general')
  const { validationErrors, validatePassword } = useValidatePassword()

  return (
    <form onSubmit={state.onSubmit} className="signUp__form">
      {Object.keys(state.hubspotFormValues)?.map((key) => {
        return (
          <input
            key={key}
            hidden
            name={key}
            // @ts-ignore
            defaultValue={state.hubspotFormValues?.[key]}
          />
        )
      })}
      <Controller
        name={'email'}
        control={state.form.control}
        rules={{
          validate: (value: string) => (isEmail(value) ? true : g('common.incorrectEmail')),
          required: g('common.requiredField'),
        }}
        render={({ value, onChange, onBlur }) => (
          <TextField
            value={value}
            onChange={(e) => {
				onChange(e)
				state.handleInputChangeForEmailAnalytics(e)
			}}
            onBlur={onBlur}
            key="username"
            name="username"
            type="email"
            variant="standard"
            label={value.length > 0 ? '' : g('common.email')}
            error={!!state.form.errors['email']}
            helperText={state.form.errors['email']?.message}
            sx={{ marginBottom: !!state.form.errors['email'] ? '8px' : '20px', width: '100%' }}
            disabled={state.loading}
          />
        )}
      />
      <Controller
        name={'password'}
        control={state.form.control}
        rules={{
          required: g('common.requiredField'),
          validate: validatePassword,
        }}
        render={({ value, onChange, onBlur }) => (
          <TextField
            value={value}
            onFocus={() => state.handlePasswordHintsVisibility(true)}
            onChange={(e) => {
              onChange(e)
              state.handleInputChangeForPasswordAnalytics(e)
            }}
            onBlur={() => state.handlePasswordHintsVisibility(false)}
            key="password"
            type={state.isPasswordVisible ? 'text' : 'password'}
            name="password"
            variant="standard"
            label={g('common.password')}
            error={!!state.form.errors['password']}
            helperText={state.form.errors['password']?.message}
            style={{ width: '100%' }}
            disabled={state.loading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear"
                    style={{ boxShadow: 'none', background: 'transparent', marginRight: '-12px' }}
                    onClick={state.switchPasswordVisible}
                  >
                    {!state.isPasswordVisible ? (
                      <VisibilityOffIcon fontSize={'medium'} />
                    ) : (
                      <VisibilityIcon fontSize={'medium'} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Box
        style={{
          overflow: 'hidden',
          opacity: state.isPasswordHintVisible ? 1 : 0,
          height: state.isPasswordHintVisible ? '80px' : 0,
          transition: 'all 0.3s ease-out',
        }}
      >
        <ValidatorBlock
          text={t('common.passwordRuleOne')}
          error={validationErrors.uppercaseError}
          theme={theme}
          isDirty={state.form.formState.dirtyFields.password || false}
        />
        <ValidatorBlock
          text={t('common.passwordRuleTwo')}
          error={validationErrors.numberError}
          theme={theme}
          isDirty={state.form.formState.dirtyFields.password || false}
        />
        <ValidatorBlock
          text={t('common.passwordRuleThree')}
          error={validationErrors.lengthError}
          theme={theme}
          isDirty={state.form.formState.dirtyFields.password || false}
        />
      </Box>
      <FormControlLabel
        sx={{
          '& .MuiButtonBase-root': {
            padding: 0,
            marginRight: '5px',
          },
          color: theme.palette.secondary.main,
          margin: '15px 0 0 0',
        }}
        control={
          <Checkbox
            inputRef={state.form.register({ required: true })}
            color="primary"
            style={{ boxShadow: 'none' }}
            name="terms"
          />
        }
        label={
          <Typography sx={{ fontSize: '12px' }}>
            {t('signUp.userAside.acceptTermsPhrase')}{' '}
            <a href="https://www.screensight.com/terms" target="_blank" rel="noreferrer">
              {t('signUp.userAside.terms')}
            </a>{' '}
            {t('signUp.userAside.and')}{' '}
            <a href="https://www.screensight.com/privacy-policy" target="_blank" rel="noreferrer">
              {t('signUp.userAside.privacyPolicy')}
            </a>
            .
          </Typography>
        }
      />
      {!!state.form?.errors?.['terms'] && (
        <Typography sx={{ color: theme.palette.error.main, fontSize: '12px' }}>{g('common.requiredField')}</Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{ width: '100%', marginTop: 20, fontSize: '14px' }}
        loading={state.loading}
      >
        {t('signUp.userAside.createFreeAccountBtn')}
      </Button>
    </form>
  )
}

export default UserForm
