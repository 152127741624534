import moment from 'moment'
import { useMutation } from '@apollo/client'
import { CreatePostMutation, CreatePostMutationVariables } from '../../../../../../../../API'
import gql from 'graphql-tag'
import { createPost } from '../../../../../../../../common/api/graphql/mutations'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import { useParams } from 'react-router'
import useUpdateChannelsCache from './useUpdateChannelsCache'

const useSendTextPost = () => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const [updateChannelLatestAt] = useUpdateChannelsCache()
  const customerIdToUse = auth.isAuthenticated
    ? auth.user?.id
    : sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId') || ''

  const [createPostMutation] = useMutation<CreatePostMutation, CreatePostMutationVariables>(gql(createPost), {
    client: !auth.user?.pending && auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    update(cache, { data }) {
      cache.modify({
        fields: {
          listPostsByChannel(existingItems = {}) {
            const newTodoRef = cache.writeFragment({
              data: data?.createPost,
              fragment: gql`
                fragment newPost on ScreensightPost {
                  id
                  postType
                  channelId
                  customerId
                  comment
                  accessId
                  readHorizon
                  referencePost
                  referencePostId
                  createdAt
                  updatedAt
                  text {
                    content
                    customerId
                    richContent
                  }
                }
              `,
            })

            return {
              ...existingItems,
              items: [newTodoRef, ...existingItems.items],
            }
          },
        },
      })
    },
  })

  const sendTextPost = async (values: { content: string; richContent: string }) => {
    const tempDate = moment(new Date()).add(1, 's').toISOString()
    const res = await createPostMutation({
      variables: {
        input: {
          text: {
            customerId: customerIdToUse,
            channelId: channelId || '',
            content: values.content,
            richContent: values.richContent,
            ...(!auth.isAuthenticated &&
              sessionStorage.getItem('guestCustomerId') && { customerId: sessionStorage.getItem('guestCustomerId') }),
          },
        },
      },
      optimisticResponse: {
        createPost: {
          id: 'temp-id',
          __typename: 'screensightPost',
          postType: 'text',
          channelId,
          customerId: auth.user?.id,
          comment: null,
          accessId: null,
          video: null,
          emailMessage: null,
          engagement: null,
          readHorizon: null,
          referencePost: null,
          referencePostId: null,
          createdAt: tempDate,
          updatedAt: tempDate,
          reactions: null,
          file: null,
          text: {
            __typename: 'TextPost',
            content: values.content,
            richContent: values.richContent,
            customerId: customerIdToUse,
          },
        },
      },
    })
    if (res.data?.createPost?.channelId && res.data.createPost?.createdAt) {
      updateChannelLatestAt(res.data?.createPost?.channelId, res.data.createPost?.createdAt)
    }
  }

  return [sendTextPost]
}

export default useSendTextPost
