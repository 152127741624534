import React from 'react'
import { Box } from '@mui/material'
import { IAuthLayout } from '../../../common/types/Auth'

const Layout = (props: IAuthLayout) => {
  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      {props.children}
    </Box>
  )
}
export default Layout
