import React from 'react'
import { Box, BoxProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    width: 30,
    height: 30,
  },
})

const IconChrome = (props: BoxProps) => {
  const { root } = useStyles()

  return (
    <Box className={root} {...props} display="flex" alignItems="center" justifyContent="center">
      <svg width="21" height="21" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.74779 15C8.74779 11.5482 11.546 8.75 14.9978 8.75C15.9609 8.75 16.8623 8.9859 17.6771 9.375H28.8972C26.671 3.88 21.2913 0 14.9978 0C10.1826 0 5.90866 2.27791 3.16406 5.8049L8.80081 15.5264C8.78586 15.35 8.74779 15.1803 8.74779 15Z"
          fill="#F44336"
        />
        <path
          d="M15 21.2501C11.7285 21.2501 9.07455 18.7277 8.80302 15.5264L3.16628 5.80499C1.18896 8.34596 0 11.531 0 15.0001C0 22.5907 5.64362 28.8481 12.9605 29.8454L18.5243 20.1579C17.5205 20.8452 16.3084 21.2501 15 21.2501Z"
          fill="#00B060"
        />
        <path
          d="M17.6768 9.375C19.782 10.3805 21.2475 12.5114 21.2475 15C21.2475 17.1434 20.1663 19.0319 18.5218 20.1579L12.958 29.8454C13.6266 29.9365 14.3038 30 14.9975 30C23.2818 30 29.9975 23.2842 29.9975 15C29.9975 13.0092 29.6012 11.1132 28.897 9.375H17.6768Z"
          fill="#FFC107"
        />
        <path
          d="M15.001 20.0001C17.7624 20.0001 20.001 17.7615 20.001 15.0001C20.001 12.2386 17.7624 10.0001 15.001 10.0001C12.2396 10.0001 10.001 12.2386 10.001 15.0001C10.001 17.7615 12.2396 20.0001 15.001 20.0001Z"
          fill="#2196F3"
        />
        <path
          d="M20.5702 12.2013L28.8999 9.375H17.6797C18.9296 9.97192 19.9442 10.9716 20.5702 12.2013Z"
          fill="url(#paint_chrome_0_linear)"
        />
        <path
          d="M9.79215 11.5757L3.17969 5.77515L8.78977 15.4921C8.68176 14.1112 9.04023 12.7327 9.79215 11.5757Z"
          fill="url(#paint_chrome_1_linear)"
        />
        <path
          d="M14.639 21.2229L12.9219 29.8497L18.532 20.1328C17.39 20.9168 16.017 21.2955 14.639 21.2229Z"
          fill="url(#paint_chrome_2_linear)"
        />
        <path
          d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
          fill="url(#paint_chrome_3_linear)"
        />
        <defs>
          <linearGradient
            id="paint_chrome_0_linear"
            x1="20.4847"
            y1="6.56996"
            x2="26.0948"
            y2="12.18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.1" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint_chrome_1_linear"
            x1="2.35596"
            y1="6.59887"
            x2="10.0195"
            y2="14.2624"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.1" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint_chrome_2_linear"
            x1="13.2988"
            y1="22.5631"
            x2="16.7536"
            y2="26.0179"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.1" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint_chrome_3_linear"
            x1="1.40214"
            y1="8.65921"
            x2="28.5979"
            y2="21.3408"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.2" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  )
}

export default IconChrome
