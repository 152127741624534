import { Categories } from 'emoji-picker-react'
import { useTranslation } from 'react-i18next'

const useEmojiCategories = () => {
  const { t } = useTranslation('chat')

  return [
    {
      name: t('chatBody.reactionsCategories.frequentlyUsed'),
      category: Categories.SUGGESTED,
    },
    {
      name: t('chatBody.reactionsCategories.smileysEmotions'),
      category: Categories.SMILEYS_PEOPLE,
    },
    {
      name: t('chatBody.reactionsCategories.animalsNature'),
      category: Categories.ANIMALS_NATURE,
    },
    {
      name: t('chatBody.reactionsCategories.foodDrink'),
      category: Categories.FOOD_DRINK,
    },
    {
      name: t('chatBody.reactionsCategories.activities'),
      category: Categories.ACTIVITIES,
    },
    {
      name: t('chatBody.reactionsCategories.travelPlaces'),
      category: Categories.TRAVEL_PLACES,
    },
    {
      name: t('chatBody.reactionsCategories.objects'),
      category: Categories.OBJECTS,
    },
    {
      name: t('chatBody.reactionsCategories.symbols'),
      category: Categories.SYMBOLS,
    },
    {
      name: t('chatBody.reactionsCategories.flags'),
      category: Categories.FLAGS,
    },
  ]
}
export default useEmojiCategories
