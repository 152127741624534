import { useContext, useMemo } from 'react'
import { useLocation } from 'react-router'
import { LayoutProps } from '../index'
import { AuthContext } from '../../../common/providers/AuthStatusProvider'
import { useTranslation } from 'react-i18next'

const profilePath = '/app/settings/profile'
const billingPath = '/app/settings/billing'
const passwordPath = '/app/settings/password'
const emailPath = '/app/settings/email-notifications'
const integrationsPath = '/app/settings/integrations'
const brandingPath = '/app/settings/branding'
const advancedPath = '/app/settings/advanced'

const useSettingsLayoutHook = (props: LayoutProps) => {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const { t } = useTranslation('settings')

  const _pageName = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
  const internalizedPageName = t(
    // @ts-ignore
    `menu.${_pageName === 'email-notifications' ? 'emailNotifications' : _pageName}`,
  )
  // @ts-ignore
  const pageName = internalizedPageName.charAt(0).toUpperCase() + internalizedPageName.slice(1)

  const isProfile = location.pathname === profilePath

  const isBilling = location.pathname === billingPath

  const isPassword = location.pathname === passwordPath

  const isEmailNotifications = location.pathname === emailPath

  const isIntegrations = location.pathname === integrationsPath

  const isBranding = location.pathname === brandingPath

  const isAdvanced = location.pathname === advancedPath

  const fullName = useMemo(() => {
    return !!(auth.user?.firstName || auth.user?.lastName)
      ? `${auth.user?.firstName} ${auth.user?.lastName}`
      : auth.user?.email
  }, [auth.user])

  return {
    fullName,
    pageName: pageName === 'Email-notifications' ? t('menu.emailNotifications') : pageName,
    active: { isProfile, isBilling, isPassword, isEmailNotifications, isIntegrations, isAdvanced, isBranding },
  }
}

export type UseLayout = ReturnType<typeof useSettingsLayoutHook>
export default useSettingsLayoutHook
