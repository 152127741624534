import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import gql from 'graphql-tag'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import { useContext } from 'react'

const useUpdateChannelsCache = () => {
  const auth = useContext(AuthContext)
  const updateChannelLatestAt = (channelId: string, createdAt: string) => {
    if (!auth.isAuthenticated) return
    return CognitoApolloClient.cache.writeFragment({
      id: `screensightChannel:${channelId}`,
      fragment: gql`
        fragment channelFragment on screensightChannel {
          activity {
            new
            latestAt
            latestPost {
              customerId
            }
          }
        }
      `,
      data: {
        activity: {
          new: false,
          latestAt: createdAt,
          latestPost: {
            customerId: auth.user?.id,
          },
        },
      },
    })
  }

  return [updateChannelLatestAt]
}

export default useUpdateChannelsCache
