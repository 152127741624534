import React from 'react'
import { Box, Typography } from '@mui/material'
import '../../../ChannelsSidebar/common/style/GroupHeaderStyle.scss'
import ArrowBottom from '../../../../../../../common/icons/ArrowBottom'
import ArrowRight from '../../../../../../../common/icons/ArrowRight'

interface IGroupHeader {
  label: string
  channelsExpanded: boolean
  onClick: () => void
}

const GroupHeader = (props: IGroupHeader) => {
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      padding="5px 15px"
      style={{ cursor: 'pointer' }}
      onClick={props.onClick}
      className="group-header__wrapper"
    >
      <Box width="20px" display="flex" alignItems="center" justifyContent="center">
        {props.channelsExpanded ? <ArrowBottom /> : <ArrowRight />}
      </Box>
      <Typography variant="body1" color="textSecondary" style={{ marginLeft: '10px', fontSize: '15px' }}>
        {props.label}
      </Typography>
    </Box>
  )
}

export default GroupHeader
