import { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import CognitoApolloClient from '../../../../../../../common/clients/CognitoApolloClient'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'
import {
  UpdateCustomerRecentChannelsMutation,
  UpdateCustomerRecentChannelsMutationVariables,
} from '../api/recentChannelTypes'
import ToastContext from '../../../../../../../common/providers/ToastProvider/ToastContext'
import { useTranslation } from 'react-i18next'

export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateScreensightCustomerInput!, $condition: ModelScreensightCustomerConditionInput) {
    updateCustomer(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      profilePhoto {
        key
        bucket
        accessLevel
        identityId
      }
      brandLogo {
        key
        bucket
        accessLevel
        identityId
      }
      calendlyUrl
      lastSeen
      settings {
        notifyEmailWatched
        notifyEmailComment
        notifyUnseenMessages
        timezone
        pushNotifications {
          video
          comment
          reply
          text
        }
        lastChannel
        enableSplashScreen
        channelRecentActivityDays
      }
      stripeId
      createdAt
      updatedAt
    }
  }
`

const useRecentChannels = () => {
  const [daysCount, setDaysCount] = useState(1)
  const auth = useContext(AuthContext)
  const toastContext = useContext(ToastContext)
  const { t } = useTranslation('chat')
  const [recentChannelsFormValue, setRecentChannelsFormValue] = useState<'lastThree' | 'custom'>('lastThree')

  const [updateUserMutation] = useMutation<
    UpdateCustomerRecentChannelsMutation,
    UpdateCustomerRecentChannelsMutationVariables
  >(gql(updateCustomer), {
    client: CognitoApolloClient,
  })

  useEffect(() => {
    setRecentChannelsFormValue(auth.user?.settings?.channelRecentActivityDays !== null ? 'custom' : 'lastThree')
  }, [auth.user?.settings?.channelRecentActivityDays])

  useEffect(() => {
    setDaysCount(auth.user?.settings?.channelRecentActivityDays || 3)
  }, [auth.user?.settings?.channelRecentActivityDays])

  const handleIncrement = () => {
    if (daysCount < 7) setDaysCount((prevState) => prevState + 1)
  }

  const handleDecrement = () => {
    if (daysCount > 1) setDaysCount((prevState) => prevState - 1)
  }

  const updateRecentChannels = async () => {
    if (!auth.isAuthenticated && !auth.user?.id) return
    const settings = { ...auth.user?.settings }
    delete settings.termsAndConditions
    try {
      await updateUserMutation({
        variables: {
          input: {
            id: auth.user?.id || '',
            settings: {
              ...settings,
              channelRecentActivityDays: recentChannelsFormValue !== 'custom' ? null : daysCount,
            },
          },
        },
      })
      if (auth.user?.settings) {
        await auth.updateUser({
          ...auth.user,
          settings: {
            ...auth.user.settings,
            channelRecentActivityDays: recentChannelsFormValue !== 'custom' ? null : daysCount,
          },
        })
      }
      toastContext.open({ type: 'INFO', text: t('channelsSidebar.recentChannels.updateRecentChannelsSuccess') })
    } catch (e) {
      console.error('Error occurred during updating recent channels', e)
      toastContext.open({ type: 'ERROR', text: t('channelsSidebar.recentChannels.updateRecentChannelsError') })
    }
  }
  return {
    daysCount,
    handleIncrement,
    handleDecrement,
    updateRecentChannels,
    recentChannelsFormValue,
    setRecentChannelsFormValue,
  }
}
export default useRecentChannels
