import React from 'react'
import { UseDeleteConfirmProvider } from './useDeleteConfirmProvider'
import { DeleteConfirmProviderProps } from './index'
import DeleteConfirmContext from './DeleteConfirmContext'
import Modal from '../../../components/_refactored/Modal'
import { Grid, Typography, useTheme } from '@mui/material'
import Button from '../../../components/_refactored/Button'
import { useTranslation } from 'react-i18next'

const DeleteConfirmProviderView = (props: DeleteConfirmProviderViewProps) => {
  const { children, modalState, abort, ok, open, title, subtitle, loading } = props
  const theme = useTheme()
  const { t: g } = useTranslation('general')
  const { t } = useTranslation('library')
  return (
    // @ts-ignore
    <DeleteConfirmContext.Provider value={{ open }}>
      <Modal open={modalState} onClose={abort}>
        <>
          {title && (
            <Typography align="center" variant="h3" style={{ marginBottom: '17px' }}>
              {t('videoList.deleteVideoModal.title')}
            </Typography>
          )}
          {subtitle && (
            <Typography align="center" variant="body2" style={{ marginBottom: '17px' }} color="textSecondary">
              {t('videoList.deleteVideoModal.subtitle')}
            </Typography>
          )}
          <Grid item container xs={12} justifyContent={'center'} style={{ marginTop: '43px' }}>
            <Grid item xs={6} md={4}>
              <Button
                disabled={loading}
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={abort}
                style={{ marginRight: '8px' }}
              >
                {g('common.cancelBtn')}
              </Button>
            </Grid>
            <Grid item xs={6} md={4}>
              <Button
                loading={loading}
                fullWidth
                variant="outlined"
                onClick={ok}
                style={{ marginLeft: '8px', color: theme.palette.error.main, borderColor: theme.palette.error.main }}
              >
                {g('common.deleteBtn')}
              </Button>
            </Grid>
          </Grid>
        </>
      </Modal>

      {children}
    </DeleteConfirmContext.Provider>
  )
}

export type DeleteConfirmProviderViewProps = UseDeleteConfirmProvider & DeleteConfirmProviderProps
export default DeleteConfirmProviderView
