import { useState } from 'react'

/*
 * This hook is used to control the mobile menu
 */
const useMobileMenu = () => {
  const [menuVisible, setMenuVisible] = useState(false)
  const [channelsVisible, setChannelsVisible] = useState(false)
  const openMenu = () => {
    if (channelsVisible) closeChannels()
    setMenuVisible(true)
  }

  const closeMenu = () => {
    setMenuVisible(false)
  }
  const openChannels = () => {
    closeMenu()
    setChannelsVisible(true)
  }

  const closeChannels = () => {
    setChannelsVisible(false)
  }

  return {
    menuVisible,
    openMenu,
    closeMenu,
    channelsVisible,
    openChannels,
    closeChannels,
  }
}

export default useMobileMenu
