import React from 'react'
import useNewContactModal from './hooks/useNewContactModal'
import NewContactModalView from './view/NewContactModal.view'

interface INewContactModal {
  hideNewContactModal: () => void
}

const NewContactModal = (props: INewContactModal) => {
  const state = useNewContactModal(props.hideNewContactModal)
  return <NewContactModalView hideNewContactModal={props.hideNewContactModal} {...state} />
}

export default NewContactModal
