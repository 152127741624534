import React from 'react'

const ArrowBottom = () => {
  return (
    <svg width="14" height="7" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.05765 5.04815e-05C0.14734 5.48822e-05 -0.289522 1.11741 0.37938 1.73486L4.32172 5.3739C4.70478 5.72749 5.29522 5.72749 5.67828 5.3739L9.62062 1.73482C10.2895 1.11737 9.85264 7.96382e-06 8.94233 1.23645e-05L1.05765 5.04815e-05Z"
        fill="#BCBCC3"
      />
    </svg>
  )
}
export default ArrowBottom
