import { screensightContact } from '../../../../../API'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { ReactMultiEmail } from 'react-multi-email'

const useAvailableEmails = (
  setEmails: any,
  setAvailableContacts: Dispatch<SetStateAction<screensightContact[] | null>>,
  availableContacts: screensightContact[] | null,
  inputRef?: React.RefObject<ReactMultiEmail>,
) => {
  const elem: HTMLInputElement | null = document.querySelector('.react-multi-email input')
  const modal = document.getElementById('send-by-email-modal')

  const handleSelectContact = (contact: screensightContact, event: any) => {
    event.preventDefault()
    setEmails((prevState: any) => {
      const prev = prevState ? [...prevState] : []
      if (!prev.includes(contact.email)) {
        prev.push(contact.email)
      }
      return prev
    })
    setAvailableContacts([])
    inputRef?.current?.emailInputRef.current?.focus()
  }

  useEffect(() => {
    if (!modal || !availableContacts) return
    modal.addEventListener('keydown', preventScrolling)
    return () => {
      modal.removeEventListener('keydown', preventScrolling)
    }
  }, [elem])

  const preventScrolling = (event: KeyboardEvent) => {
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      event.preventDefault()
    }
  }

  const handleNavigationInsideTheList = (contact: screensightContact, event: React.KeyboardEvent<HTMLLIElement>) => {
    event.preventDefault()
    const id = event.currentTarget.id
    const index = parseInt(id.split('-')[3])
    const firstContact = document.getElementById('available-emails-list-0')
    const nextContact = document.getElementById(`available-emails-list-${index + 1}`)
    const prevContact = document.getElementById(`available-emails-list-${index - 1}`)

    switch (event.code) {
      case 'ArrowDown':
        nextContact ? nextContact?.focus() : firstContact?.focus()
        break
      case 'ArrowUp':
        prevContact ? prevContact.focus() : (elem as HTMLElement).focus()
        break
      case 'Enter':
        handleSelectContact(contact, event)
        break
      case 'Escape':
        ;(elem as HTMLElement).focus()
        setAvailableContacts([])
        break
    }
  }
  return { handleNavigationInsideTheList, inputRef, handleSelectContact }
}

export default useAvailableEmails
