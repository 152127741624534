import * as React from 'react'
import { Box } from '@mui/material'

const UserIcon = () => {
  return (
    <Box display="flex">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M12.5305 6.24296C12.5305 7.6757 11.3878 8.81929 10.0001 8.81929C8.61242 8.81929 7.46973 7.6757 7.46973 6.24296C7.46973 4.81022 8.61242 3.66663 10.0001 3.66663C11.3878 3.66663 12.5305 4.81022 12.5305 6.24296Z"
          stroke="#171717"
          strokeWidth="1.5"
        />
        <path
          d="M3.98596 17.0831C3.98596 17.0831 4.38672 10.9765 9.99999 10.9765C15.6133 10.9765 16.014 17.0831 16.014 17.0831"
          stroke="#171717"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </Box>
  )
}
export default UserIcon
