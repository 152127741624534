import React from 'react'

const ScreenSightLogoGray = () => {
  return (
    <>
      <svg width="137" height="20" viewBox="0 0 137 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.6">
          <path
            d="M32.4516 4.75539e-07L11.0389 0.0264282C10.5552 0.0220435 10.0888 0.201219 9.73839 0.525994C9.388 0.85077 9.18121 1.29563 9.16184 1.76632C9.15641 2.00102 9.1992 2.2344 9.28769 2.45277C9.37619 2.67113 9.50859 2.87007 9.67714 3.0379C9.84569 3.20573 10.047 3.33907 10.2692 3.43008C10.4914 3.52109 10.73 3.56795 10.9711 3.56789L32.2639 3.53926C32.7437 3.53926 33.2039 3.72489 33.5432 4.05531C33.8825 4.38574 34.0731 4.83389 34.0731 5.30118L34.0867 14.6812C34.0867 15.1485 33.8961 15.5966 33.5568 15.9271C33.2175 16.2575 32.7573 16.4431 32.2774 16.4431L22.6454 16.4563C22.1655 16.4563 21.7053 16.2707 21.366 15.9403C21.0267 15.6099 20.8361 15.1617 20.8361 14.6944V8.67305C20.8412 8.20184 20.6574 7.74727 20.3238 7.40593C19.9901 7.06459 19.533 6.86338 19.0495 6.84506C18.8085 6.83978 18.5688 6.88145 18.3446 6.96763C18.1203 7.0538 17.9161 7.18275 17.7437 7.34689C17.5714 7.51103 17.4345 7.70705 17.341 7.92344C17.2475 8.13983 17.1994 8.37223 17.1995 8.60698V14.8618C17.1983 15.5378 17.3343 16.2074 17.5998 16.832C17.8652 17.4567 18.2548 18.024 18.7462 18.5015C19.2376 18.979 19.821 19.3573 20.463 19.6144C21.105 19.8716 21.7929 20.0026 22.4871 20L32.4787 19.9868C33.8755 19.9844 35.2141 19.4422 36.2005 18.4791C37.1869 17.516 37.7403 16.211 37.7391 14.8508L37.7256 5.12058C37.7238 3.76153 37.168 2.45877 36.1803 1.4986C35.1926 0.538436 33.8539 -0.000584438 32.4583 4.75539e-07"
            fill="#BCBCC3"
          />
          <path
            d="M25.0564 10.0055C25.0603 10.5088 25.2295 10.9977 25.5392 11.4007C25.8488 11.8037 26.2827 12.0996 26.7775 12.2453C26.9979 12.3085 27.2262 12.3419 27.4559 12.3444C28.0932 12.3415 28.7034 12.0933 29.1534 11.6538C29.6033 11.2144 29.8565 10.6195 29.8577 9.99887C29.8543 9.49518 29.6853 9.00579 29.3756 8.60235C29.0659 8.19891 28.6318 7.90261 28.1367 7.75683C27.9167 7.6917 27.6881 7.65831 27.4582 7.65771C26.8204 7.66005 26.2095 7.90843 25.7593 8.34847C25.3091 8.7885 25.0564 9.38433 25.0564 10.0055Z"
            fill="#BCBCC3"
          />
          <path
            d="M11.7357 14.2737H6.95475C6.4728 14.2737 6.0106 14.4601 5.66981 14.792C5.32902 15.1239 5.13757 15.574 5.13757 16.0433C5.13757 16.5126 5.32902 16.9628 5.66981 17.2946C6.0106 17.6265 6.4728 17.8129 6.95475 17.8129H11.738C12.2199 17.8147 12.6829 17.6299 13.0249 17.2993C13.367 16.9687 13.5602 16.5193 13.562 16.0499C13.5638 15.5806 13.374 15.1298 13.0345 14.7967C12.695 14.4636 12.2335 14.2754 11.7516 14.2737"
            fill="#BCBCC3"
          />
          <path
            d="M46.4081 8.40211C46.4081 7.94622 46.8378 7.63568 47.419 7.63568C48.0749 7.63568 48.5046 7.87354 49.2283 8.32944C49.3957 8.45718 49.5268 8.49241 49.6399 8.34705L50.35 7.43526C50.4025 7.38569 50.4337 7.31863 50.4375 7.24755C50.4413 7.17647 50.4173 7.10662 50.3704 7.05204C49.9743 6.62282 49.4889 6.28064 48.9465 6.04824C48.4041 5.81584 47.8171 5.69855 47.2245 5.70417C45.5759 5.70417 44.0402 6.70627 44.0402 8.41974C44.0402 11.4282 48.4775 10.882 48.4775 12.3026C48.4775 12.8686 47.8985 13.0514 47.2992 13.0514C46.5687 13.0514 45.7636 12.7056 45.015 12.2299C44.8454 12.1021 44.7142 12.0669 44.6034 12.2123L43.8661 13.2144C43.8206 13.265 43.7955 13.3299 43.7955 13.3972C43.7955 13.4644 43.8206 13.5294 43.8661 13.58C44.3098 14.0538 44.8507 14.4317 45.4537 14.6892C46.0567 14.9468 46.7085 15.0784 47.367 15.0754C49.0519 15.0754 50.8679 14.0711 50.8679 12.34C50.8679 9.05843 46.4126 9.8601 46.4126 8.40211"
            fill="#BCBCC3"
          />
          <path
            d="M59.084 12.0493C59.0592 12.0195 59.0278 11.9953 58.9922 11.9788C58.9566 11.9622 58.9177 11.9536 58.8782 11.9536C58.8387 11.9536 58.7998 11.9622 58.7642 11.9788C58.7286 11.9953 58.6972 12.0195 58.6724 12.0493C58.1477 12.503 57.5846 12.851 56.7048 12.851C55.3207 12.851 54.346 11.7939 54.346 10.3887C54.346 8.9836 55.3207 7.92865 56.7048 7.92865C57.5484 7.92865 58.0912 8.27442 58.6159 8.73032C58.7651 8.85806 58.8963 8.87568 59.0275 8.74794L60.1583 7.67317C60.1861 7.6497 60.2088 7.62094 60.2249 7.58865C60.241 7.55635 60.2502 7.52121 60.2519 7.48535C60.2536 7.44949 60.2478 7.41367 60.2348 7.38008C60.2218 7.34649 60.2019 7.31582 60.1763 7.28996C59.7391 6.79281 59.1966 6.39367 58.5861 6.11996C57.9755 5.84624 57.3114 5.70442 56.6392 5.70422C53.9977 5.70422 51.9578 7.76347 51.9578 10.3887C51.9578 13.014 53.9932 15.0754 56.6392 15.0754C57.3202 15.0787 57.9938 14.9386 58.614 14.665C59.2342 14.3913 59.7864 13.9904 60.2329 13.4897C60.2584 13.4638 60.2783 13.4332 60.2913 13.3996C60.3043 13.366 60.3101 13.3302 60.3084 13.2943C60.3067 13.2585 60.2976 13.2233 60.2815 13.191C60.2654 13.1587 60.2427 13.13 60.2148 13.1065L59.084 12.0493Z"
            fill="#BCBCC3"
          />
          <path
            d="M67.6261 6.1248C67.2761 5.85121 66.84 5.70343 66.3912 5.70634C65.2853 5.70634 64.5367 6.3076 64.0505 7.16433L63.9759 6.17986C63.9768 6.14374 63.9702 6.10782 63.9564 6.07428C63.9427 6.04074 63.922 6.01027 63.8958 5.98473C63.8696 5.95918 63.8383 5.9391 63.8038 5.9257C63.7694 5.9123 63.7325 5.90585 63.6954 5.90676H61.8862C61.8487 5.90451 61.8112 5.91002 61.7762 5.92295C61.7411 5.93588 61.7092 5.95592 61.6827 5.98174C61.6562 6.00757 61.6356 6.03858 61.6224 6.07273C61.6091 6.10689 61.6034 6.14341 61.6057 6.17986V14.5666C61.6034 14.6031 61.6091 14.6396 61.6224 14.6737C61.6356 14.7079 61.6562 14.7389 61.6827 14.7647C61.7092 14.7905 61.7411 14.8106 61.7762 14.8235C61.8112 14.8364 61.8487 14.842 61.8862 14.8397H63.7949C63.8324 14.842 63.8699 14.8364 63.9049 14.8235C63.94 14.8106 63.9719 14.7905 63.9984 14.7647C64.0249 14.7389 64.0455 14.7079 64.0587 14.6737C64.072 14.6396 64.0777 14.6031 64.0754 14.5666V9.82263C64.0754 8.72143 64.8262 7.89112 65.9864 7.89112C66.2475 7.89221 66.5065 7.93538 66.7531 8.01886C66.7919 8.03756 66.8345 8.04761 66.8778 8.04827C66.9212 8.04894 66.9641 8.0402 67.0035 8.0227C67.0429 8.0052 67.0778 7.97936 67.1057 7.94708C67.1336 7.9148 67.1537 7.87687 67.1647 7.83606L67.7278 6.48819C67.7633 6.42705 67.7732 6.35495 67.7555 6.28686C67.7379 6.21878 67.6939 6.15995 67.6328 6.12259"
            fill="#BCBCC3"
          />
          <path
            d="M76.7538 10.1883C76.7538 7.76567 75.0508 5.70422 72.3912 5.70422C69.7316 5.70422 67.7686 7.76567 67.7686 10.3887C67.7686 13.0118 69.7339 15.0754 72.597 15.0754C73.2298 15.0783 73.8568 14.9589 74.4419 14.7242C75.0269 14.4894 75.5583 14.1439 76.0052 13.7077C76.1545 13.5624 76.1545 13.4346 76.0233 13.3069L75.2001 12.5118C75.0689 12.3863 74.9558 12.3863 74.7885 12.4964C74.2278 12.9071 73.5428 13.1248 72.8413 13.1153C71.3984 13.1153 70.4644 12.4414 70.2201 11.2565H76.1002C76.6611 11.2565 76.7538 10.8909 76.7538 10.1795V10.1883ZM70.1817 9.68617C70.3875 8.4308 71.2876 7.73704 72.4116 7.73704C73.5356 7.73704 74.395 8.4308 74.5623 9.68617H70.1817Z"
            fill="#BCBCC3"
          />
          <path
            d="M86.9105 10.1883C86.9105 7.76567 85.2052 5.70422 82.5479 5.70422C79.8905 5.70422 77.923 7.76567 77.923 10.3887C77.923 13.0118 79.8905 15.0754 82.7537 15.0754C83.3857 15.0779 84.0119 14.9582 84.5962 14.7235C85.1804 14.4887 85.711 14.1435 86.1574 13.7077C86.3089 13.5624 86.3089 13.4346 86.1777 13.3069L85.3568 12.5118C85.2256 12.3863 85.1125 12.3863 84.9429 12.4964C84.3822 12.9071 83.6972 13.1248 82.9957 13.1153C81.5551 13.1153 80.6188 12.4414 80.3768 11.2565H86.2569C86.8177 11.2565 86.9127 10.8909 86.9127 10.1795L86.9105 10.1883ZM80.3406 9.68617C80.5464 8.4308 81.4465 7.73704 82.5682 7.73704C83.69 7.73704 84.5517 8.4308 84.7213 9.68617H80.3406Z"
            fill="#BCBCC3"
          />
          <path
            d="M93.5528 5.70423C92.223 5.70423 91.2483 6.4134 90.649 7.36264V7.34502L90.5744 6.17775C90.5753 6.14164 90.5687 6.10572 90.5549 6.07218C90.5411 6.03863 90.5205 6.00817 90.4943 5.98262C90.468 5.95708 90.4368 5.93699 90.4023 5.92359C90.3679 5.91019 90.331 5.90374 90.2939 5.90465H88.4598C88.4223 5.9024 88.3848 5.90792 88.3498 5.92084C88.3147 5.93377 88.2829 5.95381 88.2563 5.97963C88.2298 6.00546 88.2092 6.03648 88.196 6.07063C88.1827 6.10479 88.177 6.1413 88.1793 6.17775V14.5645C88.177 14.6009 88.1827 14.6375 88.196 14.6716C88.2092 14.7058 88.2298 14.7368 88.2563 14.7626C88.2829 14.7884 88.3147 14.8085 88.3498 14.8214C88.3848 14.8343 88.4223 14.8398 88.4598 14.8376H90.3685C90.406 14.8398 90.4435 14.8343 90.4785 14.8214C90.5136 14.8085 90.5455 14.7884 90.572 14.7626C90.5985 14.7368 90.6191 14.7058 90.6324 14.6716C90.6456 14.6375 90.6513 14.6009 90.649 14.5645V9.67736C90.649 9.25891 91.2867 7.92866 92.6708 7.92866C93.5754 7.92866 94.3941 8.43741 94.3941 9.91082V14.5601C94.3922 14.5964 94.3981 14.6328 94.4115 14.6668C94.4249 14.7008 94.4455 14.7317 94.4719 14.7574C94.4984 14.7832 94.5301 14.8032 94.565 14.8163C94.5999 14.8293 94.6372 14.8351 94.6746 14.8332H96.5856C96.6229 14.8351 96.6603 14.8293 96.6952 14.8163C96.7301 14.8032 96.7618 14.7832 96.7882 14.7574C96.8147 14.7317 96.8353 14.7008 96.8487 14.6668C96.8621 14.6328 96.868 14.5964 96.866 14.5601V9.47254C96.866 7.08514 95.4232 5.69983 93.5528 5.69983"
            fill="#BCBCC3"
          />
          <path
            d="M100.799 8.40212C100.799 7.94622 101.229 7.63568 101.81 7.63568C102.464 7.63568 102.895 7.87355 103.606 8.32944C103.775 8.45718 103.906 8.49242 104.017 8.34706L104.73 7.43527C104.782 7.38567 104.813 7.31839 104.817 7.24716C104.82 7.17593 104.795 7.10614 104.748 7.05205C104.352 6.62277 103.867 6.28053 103.325 6.04811C102.783 5.81569 102.196 5.69845 101.604 5.70418C99.9554 5.70418 98.4198 6.70628 98.4198 8.41974C98.4198 11.4282 102.857 10.882 102.857 12.3026C102.857 12.8686 102.278 13.0514 101.679 13.0514C100.948 13.0514 100.143 12.7056 99.3946 12.2299C99.2249 12.1022 99.0938 12.0669 98.9829 12.2123L98.2524 13.2144C98.207 13.265 98.1819 13.3299 98.1819 13.3972C98.1819 13.4644 98.207 13.5294 98.2524 13.58C98.6968 14.0553 99.2391 14.4341 99.8439 14.6918C100.449 14.9495 101.102 15.0802 101.762 15.0754C103.447 15.0754 105.263 14.0711 105.263 12.34C105.263 9.05844 100.808 9.86011 100.808 8.40212"
            fill="#BCBCC3"
          />
          <path
            d="M108.791 5.90467H106.882C106.845 5.90277 106.807 5.90859 106.772 5.92175C106.737 5.93492 106.705 5.95513 106.678 5.98108C106.652 6.00704 106.631 6.03815 106.617 6.07241C106.604 6.10667 106.598 6.14332 106.6 6.17997V14.5667C106.598 14.6033 106.604 14.6398 106.617 14.674C106.631 14.7081 106.652 14.7391 106.678 14.7649C106.705 14.7907 106.737 14.8107 106.772 14.8236C106.807 14.8365 106.845 14.842 106.882 14.8398H108.791C108.829 14.8424 108.867 14.8371 108.902 14.8243C108.937 14.8116 108.969 14.7916 108.996 14.7657C109.023 14.7398 109.044 14.7087 109.057 14.6744C109.071 14.6401 109.076 14.6034 109.074 14.5667V6.17776C109.076 6.14103 109.07 6.10423 109.057 6.06981C109.044 6.03539 109.023 6.00413 108.996 5.9781C108.97 5.95207 108.937 5.93187 108.902 5.91883C108.867 5.90579 108.829 5.90021 108.791 5.90246"
            fill="#BCBCC3"
          />
          <path
            d="M119.756 5.90464H118.032C117.995 5.90374 117.958 5.91018 117.924 5.92358C117.889 5.93699 117.858 5.95707 117.832 5.98262C117.806 6.00816 117.785 6.03862 117.771 6.07217C117.757 6.10571 117.751 6.14163 117.752 6.17774L117.695 6.97942C117.353 6.58262 116.926 6.26306 116.444 6.04288C115.962 5.82269 115.437 5.70714 114.904 5.70422C112.34 5.70422 110.469 7.78109 110.469 10.2984C110.469 12.8158 112.34 14.8926 114.904 14.8926C115.45 14.8933 115.989 14.7749 116.482 14.5462C116.975 14.3176 117.409 13.9844 117.752 13.5712V14.5557C117.752 16.0335 116.142 16.4519 115.018 16.4519C114.03 16.4771 113.054 16.2444 112.191 15.778C112.16 15.7567 112.125 15.7425 112.088 15.7364C112.051 15.7304 112.013 15.7326 111.977 15.7431C111.941 15.7535 111.908 15.7718 111.88 15.7965C111.853 15.8213 111.831 15.8519 111.817 15.8859L111.2 16.9871C111.179 17.0156 111.165 17.0479 111.158 17.082C111.15 17.1161 111.15 17.1513 111.157 17.1855C111.164 17.2196 111.178 17.2521 111.198 17.2808C111.218 17.3096 111.244 17.334 111.275 17.3527C112.453 18.088 113.826 18.4708 115.226 18.4539C117.32 18.4539 120.036 17.595 120.036 14.7517V6.17774C120.038 6.14129 120.033 6.10478 120.019 6.07062C120.006 6.03647 119.985 6.00545 119.959 5.97962C119.932 5.9538 119.901 5.93376 119.866 5.92083C119.83 5.90791 119.793 5.90239 119.756 5.90464ZM115.205 12.7057C113.848 12.7057 112.846 11.6287 112.846 10.283C112.846 8.93735 113.839 7.8868 115.205 7.8868C116.571 7.8868 117.546 9.01663 117.546 10.2742C117.546 11.5318 116.553 12.6968 115.205 12.6968"
            fill="#BCBCC3"
          />
          <path
            d="M126.911 5.70407C125.581 5.70407 124.609 6.41325 124.01 7.36248V1.80142C124.012 1.76497 124.006 1.72846 123.993 1.6943C123.98 1.66015 123.959 1.62913 123.933 1.60331C123.906 1.57748 123.874 1.55744 123.839 1.54451C123.804 1.53159 123.767 1.52607 123.729 1.52832H121.818C121.781 1.52607 121.743 1.53159 121.708 1.54451C121.673 1.55744 121.641 1.57748 121.615 1.60331C121.588 1.62913 121.568 1.66015 121.554 1.6943C121.541 1.72846 121.535 1.76497 121.538 1.80142V14.5753C121.536 14.6117 121.542 14.6481 121.555 14.6821C121.568 14.7161 121.589 14.7469 121.615 14.7727C121.642 14.7984 121.674 14.8185 121.708 14.8315C121.743 14.8446 121.781 14.8503 121.818 14.8484H123.729C123.766 14.8503 123.804 14.8446 123.839 14.8315C123.874 14.8185 123.905 14.7984 123.932 14.7727C123.958 14.7469 123.979 14.7161 123.992 14.6821C124.006 14.6481 124.011 14.6117 124.01 14.5753V9.67721C124.01 9.25875 124.645 7.9285 126.031 7.9285C126.936 7.9285 127.755 8.43725 127.755 9.91066V14.5599C127.752 14.5964 127.758 14.6329 127.771 14.667C127.785 14.7012 127.805 14.7322 127.832 14.758C127.858 14.7839 127.89 14.8039 127.925 14.8168C127.96 14.8298 127.998 14.8353 128.035 14.833H129.944C129.981 14.8353 130.019 14.8298 130.054 14.8168C130.089 14.8039 130.121 14.7839 130.147 14.758C130.174 14.7322 130.194 14.7012 130.208 14.667C130.221 14.6329 130.227 14.5964 130.224 14.5599V9.47238C130.224 7.08498 128.784 5.69967 126.911 5.69967"
            fill="#BCBCC3"
          />
          <path
            d="M136.72 5.90448H135.408V3.4422C135.41 3.40575 135.405 3.36923 135.391 3.33507C135.378 3.30092 135.357 3.2699 135.331 3.24408C135.304 3.21825 135.273 3.19821 135.237 3.18529C135.202 3.17236 135.165 3.16684 135.127 3.16909H133.219C133.181 3.16651 133.143 3.17179 133.108 3.18457C133.073 3.19735 133.04 3.21734 133.014 3.2432C132.987 3.26906 132.966 3.3002 132.953 3.33452C132.939 3.36884 132.934 3.40555 132.936 3.4422V5.90448H131.627C131.589 5.90223 131.552 5.90774 131.517 5.92067C131.481 5.9336 131.45 5.95364 131.423 5.97946C131.397 6.00528 131.376 6.0363 131.363 6.07046C131.349 6.10461 131.344 6.14112 131.346 6.17758V7.60033C131.344 7.63678 131.349 7.67329 131.363 7.70745C131.376 7.7416 131.397 7.77262 131.423 7.79844C131.45 7.82427 131.481 7.84431 131.517 7.85723C131.552 7.87016 131.589 7.87568 131.627 7.87343H132.936V14.5643C132.934 14.6009 132.94 14.6374 132.954 14.6716C132.967 14.7057 132.988 14.7367 133.015 14.7625C133.041 14.7883 133.073 14.8083 133.108 14.8212C133.144 14.8341 133.181 14.8396 133.219 14.8374H135.127C135.165 14.8393 135.202 14.8336 135.237 14.8205C135.272 14.8075 135.304 14.7874 135.33 14.7617C135.357 14.7359 135.377 14.705 135.391 14.671C135.404 14.637 135.41 14.6007 135.408 14.5643V7.87343H136.72C136.757 7.87533 136.794 7.86957 136.829 7.85652C136.864 7.84346 136.896 7.82341 136.922 7.79767C136.949 7.77192 136.969 7.74104 136.983 7.70704C136.996 7.67305 137.002 7.6367 137 7.60033V6.17758C137.002 6.14112 136.997 6.10461 136.983 6.07046C136.97 6.0363 136.95 6.00528 136.923 5.97946C136.897 5.95364 136.865 5.9336 136.83 5.92067C136.795 5.90774 136.757 5.90223 136.72 5.90448Z"
            fill="#BCBCC3"
          />
          <path
            d="M107.837 1.0592C107.516 1.0592 107.202 1.15195 106.935 1.32571C106.668 1.49946 106.46 1.74643 106.337 2.03538C106.214 2.32433 106.182 2.64228 106.244 2.94903C106.307 3.25578 106.462 3.53754 106.689 3.75869C106.916 3.97984 107.205 4.13045 107.52 4.19147C107.835 4.25249 108.162 4.22117 108.458 4.10148C108.755 3.98179 109.009 3.77911 109.187 3.51907C109.366 3.25902 109.461 2.95328 109.461 2.64053C109.461 2.22113 109.29 1.81892 108.985 1.52236C108.681 1.22581 108.268 1.0592 107.837 1.0592Z"
            fill="#BCBCC3"
          />
          <path
            d="M11.7267 7.19946L2.81379 7.21047C2.33155 7.21047 1.86905 7.39703 1.52805 7.72911C1.18705 8.06118 0.995483 8.51158 0.995483 8.9812C0.995483 9.45083 1.18705 9.90123 1.52805 10.2333C1.86905 10.5654 2.33155 10.7519 2.81379 10.7519L11.7244 10.7409C12.2067 10.7409 12.6691 10.5544 13.0101 10.2223C13.3511 9.89021 13.5427 9.43982 13.5427 8.97019C13.5427 8.50057 13.3511 8.05017 13.0101 7.7181C12.6691 7.38602 12.2067 7.19946 11.7244 7.19946"
            fill="#BCBCC3"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="2516.21"
            y1="1740.63"
            x2="3212.33"
            y2="1740.63"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#BCBCC3" />
            <stop offset="1" stopColor="#469BF5" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}

export default ScreenSightLogoGray
