import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import CognitoApolloClient from '../../../../../../../../../common/clients/CognitoApolloClient'
import { updatePost } from './updatePost.mutation'
import { UpdatePostMutationVariables } from '../../../../../../../../../API'
import { UpdatePostMutation } from './onUpdatePost.types'
import IamApolloClient from '../../../../../../../../../common/clients/IamApolloClient'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../../common/providers/AuthStatusProvider'

const useUpdatePost = (postId: string, content: string | undefined, richContent: string | undefined) => {
  const auth = useContext(AuthContext)
  const [updatePostMutation] = useMutation<UpdatePostMutation, UpdatePostMutationVariables>(gql(updatePost), {
    variables: { input: { text: { id: postId, content: content, richContent: richContent } } },
    client: !auth.user?.pending && auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    update(cache, { data }) {
      cache.writeFragment({
        id: `screensightPost:${data?.updatePost?.id}`,
        fragment: gql`
          fragment updatedPost on screensightPost {
            text {
              content
              richContent
              customerId
            }
          }
        `,
        data: {
          text: { ...data?.updatePost?.text },
        },
      })
    },
    fetchPolicy: 'no-cache',
  })
  return updatePostMutation
}

export default useUpdatePost
