import React, { FC } from 'react'
import AuthLayout from '../../ui/layouts/Auth.layout'
import { useMediaQuery } from '@mui/material'
import { SignUp } from './common/config'
import PageTitle from '../../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'
import { AuthSignUpAnalyticsProvider } from './common/providers/useAuthSignUpAnalytics'

const SignUpPage: FC = () => {
  const isTablet = useMediaQuery(`(max-width:1024px)`)
  const { t: g } = useTranslation('general')

  if (isTablet) {
    return (
		<AuthSignUpAnalyticsProvider>
		  <AuthLayout>
			<PageTitle name={g('pageName.signUp')} />
			<SignUp.Tablet.Layout>
			  <SignUp.Tablet.Header />
			  <SignUp.Tablet.Content />
			</SignUp.Tablet.Layout>
		  </AuthLayout>
		</AuthSignUpAnalyticsProvider>
    )
  }

  return (
    <AuthSignUpAnalyticsProvider>
		<AuthLayout>
		  <PageTitle name={g('pageName.signUp')} />
		  <SignUp.Desktop.Layout>
			<SignUp.Desktop.CompanyAside />
			<SignUp.Desktop.UserAside />
		  </SignUp.Desktop.Layout>
		</AuthLayout>
	</AuthSignUpAnalyticsProvider>
  )
}

export default SignUpPage
