import React, { memo } from 'react'
import { Box, IconButton } from '@mui/material'

const PropmterIconButton = ({
  children,
  style,
  onClick,
  disabled,
}: {
  children: React.ReactNode
  style?: React.CSSProperties
  onClick?: any
  disabled?: boolean
}) => {
  return (
    <Box
      width="36px"
      height="36px"
      borderRadius="100px"
      bgcolor="rgba(20, 35, 61, 0.8)"
      marginRight="10px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ cursor: 'pointer', ...style }}
    >
      <IconButton onClick={onClick} disabled={disabled}>
        {children}
      </IconButton>
    </Box>
  )
}

export default memo(PropmterIconButton)
