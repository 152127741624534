import React, { ChangeEvent, RefObject } from 'react'
import PrompterTextView from './view/PrompterText.view'
import usePrompterText from './hooks/usePrompterText'

export interface IPrompterText {
  increaseTextSpeed: () => void
  decreaseTextSpeed: () => void
  editorState: 'DEFAULT' | 'FONT' | 'SPEED' | 'TEXT'
  handleTextChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  prompterRef: RefObject<HTMLTextAreaElement>
  isPrompterPlaying: boolean
}

const PrompterText = (props: IPrompterText) => {
  const state = usePrompterText(
    props.increaseTextSpeed,
    props.decreaseTextSpeed,
    props.editorState,
    props.prompterRef,
    props.isPrompterPlaying,
  )
  return <PrompterTextView {...state} {...props} />
}

export default PrompterText
