import { useQuery } from '@apollo/client'
import {
  DeleteVideoMutation,
  DeleteVideoMutationVariables,
  ListPostsByChannelQuery,
  ListPostsByChannelQueryVariables,
  ListVideosByOwnerQuery,
  ListVideosByOwnerQueryVariables,
  ModelSortDirection,
  screensightVideo,
  SearchablescreensightVideoSortableFields,
  SearchableSortDirection,
  SearchScreensightVideosQuery,
  SearchScreensightVideosQueryVariables,
} from '../../../API'
import gql from 'graphql-tag'
import { listVideosByOwner, searchScreensightVideos } from '../../../graphql/queries'
import CognitoApolloClient from '../../../common/clients/CognitoApolloClient'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AuthContext } from '../../../common/providers/AuthStatusProvider'
import UploadQueue from '../../../common/services/UploadQueue'
import { deleteVideo } from '../../../graphql/mutations'
import useVideoListSubscriptionsHook from './useVideoListSubscriptions.hook'
import debounce from 'lodash/debounce'
import RecorderStateContext from '../../../common/providers/RecorderStateProvider/RecorderStateContext'
import ToastContext from '../../../common/providers/ToastProvider/ToastContext'
import { IndexedRecordingEntity } from '../../../common/services/IndexedDB'
import DeleteConfirmContext from '../../../common/providers/DeleteConfirmProvider/DeleteConfirmContext'
import { useMediaQuery, useTheme } from '@mui/material'
import MediaRecorderHandler from '../../../common/providers/RecorderStateProvider/MediaRecorderHandler'
import useApolloQuery from '../../../common/clients/useApolloQuery'
import { listPostsByChannel } from '../../../common/api/graphql/queries'
import IamApolloClient from '../../../common/clients/IamApolloClient'

const useVideoListHook = () => {
  const toastContext = useContext(ToastContext)
  const auth = useContext(AuthContext)
  const modal = useContext(DeleteConfirmContext)
  const {
    state: { active: recorderActive },
    dispatch: recorderDispatch,
  } = useContext(RecorderStateContext)
  const [videos, setVideos] = useState<Array<screensightVideo | IndexedRecordingEntity>>([])
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [iseNewVideoModalVisible, setNewVideoModalVisibility] = useState(false)
  const [selectedVideoItem, setSelectedVideoItem] = useState<screensightVideo | null>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const [videosLoaded, setVideosLoaded] = useState(false)

  useVideoListSubscriptionsHook(auth.user?.id, videos, setVideos)

  const {
    loading: videoListLoading,
    error: videoListError,
    data: videoListData,
    refetch: refetchVideoListData,
  } = useQuery<ListVideosByOwnerQuery, ListVideosByOwnerQueryVariables>(gql(listVideosByOwner), {
    variables: {
      limit: 10,
      sortDirection: ModelSortDirection.DESC,
      customerId: auth.user?.id || '',
    },
    skip: !auth.isInitialized || !auth.isAuthenticated || !!searchPhrase.length,
    client: CognitoApolloClient,
  })

  useApolloQuery<ListPostsByChannelQuery, ListPostsByChannelQueryVariables>(listPostsByChannel, {
    variables: {
      channelId: localStorage.getItem('lastChannel') || '',
      sortDirection: ModelSortDirection.DESC,
      limit: 20,
    },
    skip: !auth.isInitialized || !localStorage.getItem('lastChannel') || localStorage.getItem('lastChannel') === 'new',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
  })

  const {
    loading: videoSearchListLoading,
    error: videoSearchListError,
    data: videoSearchListData,
    refetch: refetchVideoSearchListData,
  } = useQuery<SearchScreensightVideosQuery, SearchScreensightVideosQueryVariables>(gql(searchScreensightVideos), {
    variables: {
      limit: 10,
      sort: [{ field: SearchablescreensightVideoSortableFields.createdAt, direction: SearchableSortDirection.desc }],
      filter: {
        customerId: { eq: auth.user?.id },
        title: { matchPhrasePrefix: searchPhrase },
      },
    },
    skip: auth.user?.pending || !auth.isAuthenticated || !searchPhrase.length,
    client: CognitoApolloClient,
  })

  const updateVideosByQuery = async () => {
    if (!videoListData?.listVideosByOwner?.items) return
    const fetchedVideos = [...videoListData?.listVideosByOwner?.items]
    const videosToRestore = await UploadQueue.getRecordingsToRestore()
    videosToRestore.forEach((videoToRestore) => {
      const foundIndex = fetchedVideos.findIndex(
        (item) =>
          item?.id === videoToRestore.entityId && videoToRestore.localId !== MediaRecorderHandler.videoParams?.uuid,
      )
      if (foundIndex !== -1) {
        // @ts-ignore
        fetchedVideos[foundIndex] = { ...videoToRestore, ...fetchedVideos[foundIndex], error: true }
      }
    })

    setVideos((prevState) =>
      // @ts-ignore
      Object.values([...prevState, ...fetchedVideos].reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})),
    )
    setVideosLoaded(true)
  }

  const updateVideosBySearch = async () => {
    if (!videoSearchListData?.searchScreensightVideos?.items) return
    const fetchedVideos = [...videoSearchListData?.searchScreensightVideos?.items]
    // @ts-ignore
    setVideos((prevState) =>
      // @ts-ignore
      Object.values([...prevState, ...fetchedVideos].reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})),
    )
  }

  useEffect(() => {
    if (videoListData?.listVideosByOwner?.items) {
      updateVideosByQuery()
    } else {
      updateVideosBySearch()
    }
  }, [videoListData?.listVideosByOwner?.items, videoSearchListData?.searchScreensightVideos?.items])

  useEffect(() => {
    if (videoListError?.message) {
      toastContext.open({ type: 'ERROR', text: videoListError.message })
      return
    }
    if (videoSearchListError?.message) {
      toastContext.open({ type: 'ERROR', text: videoSearchListError.message })
      return
    }
  }, [videoListError?.message, videoSearchListError?.message])

  const closeNewVideoModal = () => setNewVideoModalVisibility(false)
  const openNewVideoModal = () => setNewVideoModalVisibility(true)

  const handleNewVideoBtnClick = async () => {
    if (localStorage.getItem('initialPermissionsModalDisplayed') !== 'true') {
      recorderDispatch({ action: 'SET_INITIAL_PERMISSIONS_VISIBLE', value: true })

      return
    }
    openNewVideoModal()
  }
  const openDeleteModal = (item: screensightVideo | IndexedRecordingEntity) => {
    modal.open({
      callback: async () => {
        if ((item as screensightVideo).id) await deleteVideoFromList((item as screensightVideo).id)
        if ((item as IndexedRecordingEntity).localId)
          await UploadQueue.deleteStoredRecording((item as IndexedRecordingEntity).localId)
        setSelectedVideoItem(null)
      },
    })
  }

  const getNextVideos = async () => {
    if (searchPhrase) {
      if (!videoSearchListData?.searchScreensightVideos?.nextToken) return
      await refetchVideoSearchListData({ nextToken: videoSearchListData.searchScreensightVideos.nextToken, limit: 10 })
      return
    } else {
      if (!videoListData?.listVideosByOwner?.nextToken) return
      await refetchVideoListData({ nextToken: videoListData.listVideosByOwner.nextToken, limit: 10 })
    }
  }

  const deleteVideoFromList = async (itemId?: string | null) => {
    if (!itemId) return
    await CognitoApolloClient.mutate<DeleteVideoMutation, DeleteVideoMutationVariables>({
      mutation: gql(deleteVideo),
      variables: { input: { id: itemId } },
    })
    if (videoListData?.listVideosByOwner?.nextToken) {
      await refetchVideoListData({ nextToken: videoListData.listVideosByOwner.nextToken, limit: 1 })
    }
    await refetchVideoListData
  }

  const debounceCallback = useCallback(
    debounce((text: string) => {
      setVideos([])
      if (text) {
        setSearchPhrase(text)
      } else {
        setSearchPhrase('')
      }
    }, 500),
    [],
  )

  const searchEvent = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
      debounceCallback(event.target.value)
    },
    [debounceCallback],
  )

  const hasMore = useMemo(() => {
    if (searchPhrase) {
      return (videoSearchListData?.searchScreensightVideos?.items?.length || 0) === 10
    } else {
      return (videoListData?.listVideosByOwner?.items?.length || 0) === 10
    }
  }, [searchPhrase, videoListData?.listVideosByOwner?.items, videoSearchListData?.searchScreensightVideos?.items])

  const clearSearchPhrase = () => {
    setSearchValue('')
    debounceCallback('')
  }

  return {
    getNextVideos,
    videos,
    hasMore: hasMore,
    deleteVideoFromList,
    loading:
      (videoListData?.listVideosByOwner?.items.length ? false : videoListLoading) ||
      (videoSearchListData?.searchScreensightVideos?.items.length ? false : videoSearchListLoading),
    searchEvent,
    iseNewVideoModalVisible,
    closeNewVideoModal,
    openNewVideoModal,
    recorderActive,
    selectedVideoItem,
    setSelectedVideoItem,
    noVideos:
      videosLoaded && !videos.length && !searchPhrase && !searchValue && !videoSearchListLoading && !videoListLoading,
    searching: !!searchPhrase.length,
    openDeleteModal,
    theme,
    isMobile,
    isLargeScreen,
    searchPhrase,
    clearSearchPhrase,
    searchValue,
    handleNewVideoBtnClick,
  }
}

export default useVideoListHook
