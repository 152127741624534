import React from 'react'
import { Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { theme } from '../providers/MuiThemeProvider'

interface IContactsIcon {
  active?: boolean
}

const useStyles = makeStyles({
  root: {
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
})

const ContactsIcon = (props: IContactsIcon) => {
  const { root } = useStyles()
  const theme = useTheme()

  return (
    <Box className={root} width="60px" display="flex" justifyContent="center" alignItems="center" height="36px">
      <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.66665 0.166687C6.00361 0.166687 5.36772 0.430079 4.89888 0.89892C4.43004 1.36776 4.16665 2.00365 4.16665 2.66669C4.16665 3.32973 4.43004 3.96561 4.89888 4.43445C5.36772 4.9033 6.00361 5.16669 6.66665 5.16669C7.32969 5.16669 7.96557 4.9033 8.43441 4.43445C8.90325 3.96561 9.16665 3.32973 9.16665 2.66669C9.16665 2.00365 8.90325 1.36776 8.43441 0.89892C7.96557 0.430079 7.32969 0.166687 6.66665 0.166687ZM13.3333 0.166687C12.6703 0.166687 12.0344 0.430079 11.5655 0.89892C11.0967 1.36776 10.8333 2.00365 10.8333 2.66669C10.8333 3.32973 11.0967 3.96561 11.5655 4.43445C12.0344 4.9033 12.6703 5.16669 13.3333 5.16669C13.9964 5.16669 14.6322 4.9033 15.1011 4.43445C15.5699 3.96561 15.8333 3.32973 15.8333 2.66669C15.8333 2.00365 15.5699 1.36776 15.1011 0.89892C14.6322 0.430079 13.9964 0.166687 13.3333 0.166687ZM6.66665 6.83335C4.16665 6.83335 0.833313 8.05335 0.833313 9.75002V11C0.833313 11.46 1.20665 11.8334 1.66665 11.8334H11.6666C12.1266 11.8334 12.5 11.46 12.5 11V9.75002C12.5 8.05335 9.16665 6.83335 6.66665 6.83335ZM13.3333 6.83335C13.0691 6.83335 12.7946 6.84984 12.5179 6.87567C13.5029 7.59651 14.1666 8.54919 14.1666 9.75002V11C14.1666 11.46 14.54 11.8334 15 11.8334H18.3333C18.7933 11.8334 19.1666 11.46 19.1666 11V9.75002C19.1666 8.05335 15.8333 6.83335 13.3333 6.83335Z"
          fill={props?.active ? theme.palette.primary.main : '#BCBCC3'}
        />
      </svg>
    </Box>
  )
}

export default ContactsIcon
