import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'

const useSharedMedia = () => {
  const auth = useContext(AuthContext)
  const [sharedVideosLoaded, setSharedVideosLoaded] = useState(false)
  const [sharedFilesLoaded, setSharedFilesLoaded] = useState(false)
  const panelDataLoaded = sharedFilesLoaded && sharedVideosLoaded

  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId

  useEffect(() => {
    setSharedFilesLoaded(false)
    setSharedVideosLoaded(false)
  }, [channelId])

  const handleSharedVideosLoaded = () => {
    setSharedVideosLoaded(true)
  }

  const handleSharedFilesLoaded = () => {
    setSharedFilesLoaded(true)
  }

  return {
    panelDataLoaded,
    handleSharedVideosLoaded,
    handleSharedFilesLoaded,
    isAuthenticated: auth.isAuthenticated,
  }
}

export default useSharedMedia
