import React from 'react'
import { Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { theme } from '../providers/MuiThemeProvider'

interface IProps {
  active?: boolean
}

const useStyles = makeStyles({
  root: {
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
})

const IconVideoList = (props: IProps) => {
  const { root } = useStyles()
  const theme = useTheme()

  return (
    <Box className={root} width="60px" display="flex" justifyContent="center" alignItems="center" height="36px">
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.58002 2.5C1.61708 2.5 0.833984 3.28903 0.833984 4.25926V13.0556C0.833984 14.0258 1.61708 14.8148 2.58002 14.8148H13.9292C14.8922 14.8148 15.6753 14.0258 15.6753 13.0556V4.25926C15.6753 3.28903 14.8922 2.5 13.9292 2.5H2.58002ZM6.07208 6.01852L10.8754 8.65741L6.07208 11.2963V6.01852ZM17.4213 6.01852V16.5741H4.32605V18.3333H17.4213C18.3755 18.3333 19.1673 17.5356 19.1673 16.5741V6.01852H17.4213Z"
          fill={props.active ? theme.palette.primary.main : theme.palette.secondary.light}
        />
      </svg>
    </Box>
  )
}

export default IconVideoList
