import { API } from 'aws-amplify'
import { captureException } from '@sentry/react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { S3Object } from '../../../../../../../../../../API'

const useAISummaryInitializer = (
  onError: (error: string) => void,
  handleExperimentalInfoVisibility: (isVisible: boolean) => void,
  fileObject: S3Object | null | undefined,
) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const { t } = useTranslation('chat')

  const onClick = async () => {
    onError('')
    setLoading(true)
    handleExperimentalInfoVisibility(true)
    try {
      const response = await API.post('ai_summary_api', '/summary', {
        body: {
          s3_bucket: fileObject?.bucket,
          s3_key: fileObject?.key,
        },
      })

      console.log('Request successfully sent for AI summary', response)

      if (response?.job?.status === 'FAILED') {
        setLoading(false)
        handleExperimentalInfoVisibility(false)
        onError(
          response.job?.error ||
            'Error with transcribing the video. Please try again later and make sure the video contains audio.',
        )
      }
    } catch (err) {
      captureException(err)
      console.log('Error on ai summary init:', err)

      setLoading(false)

      handleExperimentalInfoVisibility(false)
      onError(t('chatBody.videoPostSummaryAI.errorInitializingSummary'))
    }
  }

  return { onClick, loading }
}

export default useAISummaryInitializer
