export const searchScreensightPosts = /* GraphQL */ `
  query SearchScreensightPosts(
    $filter: SearchablescreensightPostFilterInput
    $sort: [SearchablescreensightPostSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablescreensightPostAggregationInput]
  ) {
    searchScreensightPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        channelId
        accessId
        customerId
        postType
        video {
          id
          createdAt
          customerId
          title
          status
          filePresignedUrl
          duration
          name
          thumbnailObject {
            key
            bucket
            accessLevel
            identityId
          }
          accessType
          accessId
          password
          width
          height
        }
        engagement {
          items {
            customerId
            seen
            seenAt
            videoProgress
          }
          nextToken
        }
        reactions {
          items {
            postId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
            }
            unicode
            videoTimestamp
            createdAt
            updatedAt
          }
          nextToken
        }
        referencePost {
          id
          reactions {
            items {
              postId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
              }
              unicode
              videoTimestamp
              createdAt
              updatedAt
            }
            nextToken
          }
          video {
            id
            createdAt
            customerId
            title
            status
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
          }
        }
        createdAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
