import * as React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import StatsIcon from '../../../common/icons/StatsIcon'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

interface IStatisticsButtonProps {
  videoId: string | undefined
  isOnVideoList: boolean
}

const StatisticsButton = (props: IStatisticsButtonProps) => {
  const { t } = useTranslation('library')
  const history = useHistory()

  return (
    <Tooltip title={t('common.statisticsBtn')}>
      <IconButton
        style={{
          margin: props.isOnVideoList ? '0 0' : ' 0 10px',
          boxShadow: props.isOnVideoList ? 'none' : 'auto',
        }}
        aria-label="send-via-email"
        name="send-via-email"
        onClick={() => history.push(`/video/${props.videoId}/stats`)}
        size="small"
      >
        <StatsIcon />
      </IconButton>
    </Tooltip>
  )
}
export default StatisticsButton
