import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, useTheme } from '@mui/material'
import CloudFrontImage from '../../../CloudFrontImage'
import { VideoStatus } from '../../../../../API'

interface ITemplateVideoDataProps {
  isMobile: boolean
  videoTitle: string | null | undefined
  videoDateAdded: string | null | undefined
  thumbnailObject: any
  videoPassword: string | null | undefined
  videoStatus?: VideoStatus
}
export const TemplateVideoData = (props: ITemplateVideoDataProps) => {
  const theme = useTheme()
  const { t } = useTranslation('library')
  const { t: g } = useTranslation('general')
  const isGif = !!props?.thumbnailObject?.key?.includes('.gif')

  return (
    <Box
      bgcolor="background.paper"
      borderRadius="10px"
      padding="25px 26px"
      display="flex"
      marginBottom="20px"
      width={props.isMobile ? '100%' : '450px'}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        variant="h3"
        style={{
          textAlign: 'center',
          marginBottom: '5px',
          marginTop: props.isMobile ? '8px' : '0',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: props.isMobile ? '200px' : '350px',
        }}
      >
        {props.videoTitle}
      </Typography>
      <Typography variant="body2" color="textSecondary" style={{ marginTop: '5px' }}>
        {props.videoDateAdded}
      </Typography>
      {props?.videoPassword && (
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '8px' }}>
          {t('videoItem.sendViaEmailModal.thumbnailVideoPassword')}{' '}
          <span style={{ color: theme.palette.text.primary }}>{props.videoPassword}</span>
        </Typography>
      )}
      {props?.thumbnailObject?.key && (
        <CloudFrontImage
          disableResize={isGif}
          image={props?.thumbnailObject?.key}
          autoScale
          imgStyle={{
            aspectRatio: isGif ? '16/9' : 'auto',
            maxWidth: '240px',
            borderRadius: '10px',
            objectFit: 'cover',
            display: 'block',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            marginTop: '8px',
          }}
        />
      )}
      {props?.videoStatus === VideoStatus.uploading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={props.isMobile ? '80px' : '120px'}
          width={props.isMobile ? '80px' : '120px'}
        >
          <Typography
            variant={'h3'}
            color="secondary"
            style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
          >
            {g('common.uploading')}
          </Typography>
        </Box>
      )}
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box
          bgcolor="primary.main"
          padding="10px 20px"
          borderRadius="100px"
          marginTop={props.isMobile ? '16px' : '25px'}
          width="fit-content"
        >
          <Typography
            variant="h6"
            align="center"
            style={{
              color: theme.palette.background.default,
              fontWeight: 500,
              fontSize: '16px',
              width: '100%',
            }}
          >
            {t('videoItem.sendViaEmailModal.viewOnScreenSightBtn')}{' '}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default TemplateVideoData
