import * as React from 'react'
import { Box, Typography } from '@mui/material'
import { CustomerSettings } from '../../../../../../../API'
import DetailRow from './DetailRow'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'

interface ListElementProps {
  data: string | null | undefined | boolean
  settings?: CustomerSettings | null | undefined
  label: string
  icon: any
  loading?: boolean
}
const UserDetail = (props: ListElementProps) => {
  const { t } = useTranslation('support')
  return (
    <>
      <Box display="flex" alignItems="center" sx={{ paddingBottom: '7px', paddingTop: '32px' }}>
        {props.icon}
        <Typography
          variant="body2"
          sx={{
            fontWeight: 500,
            paddingLeft: '8px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {props.label}
        </Typography>
      </Box>
      {props.loading ? (
        props?.settings ? (
          <Box>
            <Skeleton
              variant="rectangular"
              height={15}
              style={{ width: '80%', marginBottom: '8px', borderRadius: '10px' }}
            />
            <Skeleton
              variant="rectangular"
              height={15}
              style={{ width: '80%', marginBottom: '8px', borderRadius: '10px' }}
            />
            <Skeleton variant="rectangular" height={15} style={{ width: '80%', borderRadius: '10px' }} />
          </Box>
        ) : (
          <Skeleton variant="rectangular" height={21.5} style={{ width: '80%', borderRadius: '10px' }} />
        )
      ) : props?.settings ? (
        <>
          <DetailRow data={props.settings?.notifyEmailComment} label={t('accountSidebar.commentNotification')} />
          <DetailRow data={props.settings?.notifyEmailWatched} label={t('accountSidebar.viewsNotification')} />
          <DetailRow data={props.settings?.notifyUnseenMessages} label={t('accountSidebar.unreadNotification')} />
        </>
      ) : (
        <DetailRow data={props?.data} />
      )}
    </>
  )
}

export default UserDetail
