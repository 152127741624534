import { useMutation } from '@apollo/client'
import { DeleteVideoMutation, DeleteVideoMutationVariables } from '../../../../../API'
import gql from 'graphql-tag'
import { deleteVideo } from '../../../../../graphql/mutations'
import CognitoApolloClient from '../../../../../common/clients/CognitoApolloClient'
import { useHistory } from 'react-router'
import { useContext } from 'react'
import DeleteConfirmContext from '../../../../../common/providers/DeleteConfirmProvider/DeleteConfirmContext'
import { Storage } from 'aws-amplify'
import useGetVideoFragment from '../../../../../common/api/hooks/useGetVideoFragment'
import unidecode from 'unidecode'

const useVideoActionsBar = (videoId: string | undefined, fileKey: string) => {
  const history = useHistory()
  const deleteModal = useContext(DeleteConfirmContext)
  const cacheVideo = useGetVideoFragment(videoId, '{ title}')

  function sanitizeTitle(title: string): string {
    // Transliterate title, replace foreign characters with their ASCII equivalents
    const transliteratedTitle = unidecode(title)

    // Remove any non-alphanumeric character and replace it with "_"
    return transliteratedTitle.replace(/[^a-zA-Z0-9\.]/g, ' ')
  }

  const [deleteAction] = useMutation<DeleteVideoMutation, DeleteVideoMutationVariables>(gql(deleteVideo), {
    variables: { input: { id: videoId } },
    onCompleted: () => history.push('/app'),
    client: CognitoApolloClient,
  })

  const deleteOpenModal = () => {
    deleteModal.open({
      callback: () => {
        deleteAction()
      },
    })
  }

  function downloadBlob(blob: any, filename: string) {
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename || 'download'
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url)
        a.removeEventListener('click', clickHandler)
      }, 150)
    }
    a.addEventListener('click', clickHandler, false)
    a.click()
    return a
  }

  async function download() {
    const fileExtension = fileKey.toLowerCase().split('.').pop()
    const fileName = sanitizeTitle(cacheVideo?.title || '') + '.' + fileExtension
    const result = await Storage.get(fileKey, {
      download: true,
      customPrefix: {
        public: '',
      },
    })
    downloadBlob(result.Body, fileName)
  }

  return { deleteOpenModal, download }
}

export default useVideoActionsBar
