import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import Form from './ui/partials/form'
import Aside from './ui/partials/aside'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useParams } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'
import {
  BillingInformation,
  BillingProduct,
  BillingSubscriptionInput,
  CreateBillingSubscriptionMutation,
  GetBillingInformationQuery,
  ListPricePlansQuery,
  PaymentMethod,
  UpdateBillingSubscriptionMutation,
} from '../../../../API'
import gql from 'graphql-tag'
import { getBillingInformation, listPricePlans } from '../../../../graphql/queries'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import FullScreenLoader from '../../../../components/_refactored/Loaders/FullScreenLoader'
import { useHistory } from 'react-router-dom'
import { createBillingSubscription, updateBillingSubscription } from '../../../../graphql/mutations'
import ToastContext from '../../../../common/providers/ToastProvider/ToastContext'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../../../components/_refactored/PageTitle/PageTitle'

const PLAN_TYPES = ['FREE', 'STARTER', 'PROFESSIONAL', 'ENTERPRISE']

const BillingCheckout = () => {
  const auth = useContext(AuthContext)
  const toastContext = useContext(ToastContext)
  const { id: planType } = useParams() as { id: string }
  const history = useHistory()
  const { t } = useTranslation('settings')
  const [selectedPlan, setSelectedPlan] = useState<BillingProduct | undefined>(undefined)
  const [totalPrice, setTotalPrice] = useState(0)
  const [billingType, setBillingType] = useState<'year' | 'month'>('year')
  const [prices, setPrices] = useState({ year: '', month: '' })
  const { t: g } = useTranslation('general')

  const { data: billingInformationData, loading: billingInformationDataLoading } = useQuery<
    GetBillingInformationQuery,
    BillingInformation
  >(gql(getBillingInformation), {
    fetchPolicy: 'network-only',
  })

  const { data: plansData, loading: plansDataLoading } = useQuery<ListPricePlansQuery, BillingProduct>(
    gql(listPricePlans),
    {
      skip: auth.user?.pending || !auth.isAuthenticated,
      fetchPolicy: 'network-only',
      client: CognitoApolloClient,
    },
  )

  const [updateBillingPlan, { loading: updateBillingPlanLoading }] = useMutation<
    UpdateBillingSubscriptionMutation,
    BillingSubscriptionInput
  >(gql(updateBillingSubscription), {
    refetchQueries: [{ query: gql(getBillingInformation) }],
  })

  const [createBillingPlan, { loading: createBillingPlanLoading }] = useMutation<
    CreateBillingSubscriptionMutation,
    BillingSubscriptionInput
  >(gql(createBillingSubscription), {
    refetchQueries: [{ query: gql(getBillingInformation) }],
  })

  useEffect(() => {
    if (!planType || !PLAN_TYPES.includes(planType.toUpperCase())) {
      history.push('/app/settings/billing/plans')
    }
  }, [planType])

  useEffect(() => {
    if (!billingInformationData?.getBillingInformation?.subscription?.product?.name || !planType) return
    if (
      billingInformationData?.getBillingInformation?.subscription?.product?.name
        .toLowerCase()
        .includes(planType.toLowerCase())
    ) {
      history.push('/app/settings/billing/plans')
    }
  }, [planType, billingInformationData?.getBillingInformation?.subscription?.product?.name])

  useEffect(() => {
    if (!plansData?.listPricePlans?.length || !planType) return
    setSelectedPlan(
      plansData?.listPricePlans.find((plan) =>
        plan?.name?.toLowerCase().includes(planType.toLowerCase()),
      ) as BillingProduct,
    )
  }, [plansData?.listPricePlans, planType])

  useEffect(() => {
    if (!selectedPlan) return
    const type = selectedPlan?.prices?.find((plan) => plan?.interval === billingType)
    setTotalPrice(type?.amount || 0)
  }, [billingType, selectedPlan])

  useEffect(() => {
    if (!selectedPlan) return
    const yearPrice = selectedPlan?.prices?.find((priceObj) => priceObj?.interval === 'year')?.amount
    const monthPrice = selectedPlan?.prices?.find((priceObj) => priceObj?.interval === 'month')?.amount
    setPrices({
      year: String(+parseFloat(String((yearPrice || 0) / 12)).toFixed(2)) || '',
      month: String(+parseFloat(String(monthPrice || 0)).toFixed(2)) || '',
    })
  }, [selectedPlan])

  const updatePlan = async (e: any) => {
    e.preventDefault()
    const planId = selectedPlan?.prices?.find((price) => price?.interval === billingType)?.id
    if (!planId) return
    try {
      if (billingInformationData?.getBillingInformation?.subscription?.id) {
        await updateBillingPlan({
          variables: {
            // @ts-ignore
            input: { priceId: planId },
          },
        })
      } else {
        await createBillingPlan({
          variables: {
            // @ts-ignore
            input: { priceId: planId },
          },
        })
      }
      toastContext.open({ type: 'INFO', text: t('billing.common.planUpdateSuccess') })
      history.push('/app/settings/billing')
    } catch (e: any) {
      console.error('Error during creating or updating billing subscription', e)
      toastContext.open({ type: 'ERROR', text: t('billing.purchase.planUpdateError') })
    }
  }

  const handleChangeBillingType = (type: 'year' | 'month') => {
    setBillingType(type)
  }

  if (plansDataLoading || billingInformationDataLoading) return <FullScreenLoader withoutSidebar />

  return (
    <>
      <PageTitle name={g('pageName.billingCheckout')} />
      <Box position="relative" maxWidth="90%" margin="0 auto">
        <IconButton
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            boxShadow: 'none',
          }}
          onClick={() => history.push('/app/settings/billing/plans')}
        >
          <ArrowBackIosIcon style={{ marginLeft: '6px' }} />
        </IconButton>
        <Typography
          variant="h2"
          align="center"
          style={{ marginTop: '100px', fontSize: '20px', fontWeight: 500, marginBottom: '60px' }}
        >
          {t('billing.purchase.title')}
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12} md={8}>
            <Form
              paymentMethod={billingInformationData?.getBillingInformation?.paymentMethod as PaymentMethod}
              handleChangeBillingType={handleChangeBillingType}
              prices={prices}
              updatePlan={updatePlan}
              updating={updateBillingPlanLoading || createBillingPlanLoading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Aside planName={selectedPlan?.name || ''} totalPrice={totalPrice} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default BillingCheckout
