import { Subject, Observable, BehaviorSubject } from 'rxjs'
import { debounceTime, filter, map, throttleTime } from 'rxjs/operators'
import { v4 } from 'uuid'
import { Map } from 'immutable'

import { WorkerEvent, WorkersEvent, SchedulerItem, SchedulerEvent, ProgressItem } from './UploadScheduler.types'

const initialProgress: ProgressItem = { loaded: 0, total: 0 }
const errorsInRowOverflow = 5
export class UploadScheduler {
  scheduledSubject = new Subject<WorkersEvent>()
  schedulerSubject = new BehaviorSubject<SchedulerEvent>({ type: 'READY' })

  scheduledChunks = Map<string, SchedulerItem>()
  progress = Map<string, ProgressItem>()

  name: string

  errorsInRow = 0
  locked = false
  cleanupIsPossible = false

  constructor(name: string) {
    this.name = name

    // Actions observable
    this.scheduledSubject
      .pipe(filter((ev) => ev.type === 'SUCCESS' || ev.type === 'ERROR' || ev.type === 'ABORT'))
      .subscribe(this.chunkProcessingCompleteEvent.bind(this))

    this.scheduledSubject.subscribe(this.chunkProcessingProgressEvent.bind(this))

    // this.schedulerSubject.subscribe((ev) => this.log("SchedulerEvent", ev));
  }

  allowToCleanUp() {
    this.cleanupIsPossible = true

    if (this.checkIfCleanupIsPossible()) this.cleanup()
  }

  lockScheduler() {
    if (this.locked) return
    this.locked = true

    this.scheduledChunks
      .filter((item) => item.status === 'UPLOADING' || item.status === 'WAITING')
      .forEach((item) => item.abort())

    this.log('Lock!!')
    this.schedulerSubject.next({ type: 'ERROR' })
  }

  scheduleItem(worker: () => void, abort: () => void, observable: Observable<WorkerEvent>, totalSize: number) {
    if (this.locked) return abort()

    const scheduledItemId = v4()

    observable.pipe(map((ev) => ({ ...ev, id: scheduledItemId }))).subscribe(this.chunkEvent.bind(this))

    this.scheduledChunks = this.scheduledChunks.set(scheduledItemId, {
      worker,
      abort,
      status: 'WAITING',
    })

    this.progress = this.progress.set(scheduledItemId, {
      loaded: 0,
      total: totalSize,
    })

    this.log('Scheduled new item with ID:', scheduledItemId)
    if (this.checkIfProcessingIsPossible()) this.processNextScheduledChunk()
  }

  processNextScheduledChunk() {
    const [key, item] = this.scheduledChunks.findEntry((item) => item.status === 'WAITING') || []

    if (!item || !key) return
    this.log('Running item id', key)
    this.scheduledChunks = this.scheduledChunks.setIn([key, 'status'], 'UPLOADING')
    item.worker()
  }

  cleanup() {
    // Report queue status
    const allItemsSucceed = this.scheduledChunks
      .filter((item) => item.status !== 'ABORT')
      .every((item) => item.status === 'SUCCESS')

    if (allItemsSucceed) this.schedulerSubject.next({ type: 'SUCCESS' })
    else this.schedulerSubject.next({ type: 'ERROR' })

    this.progress = Map()
    this.scheduledChunks = Map()
    this.errorsInRow = 0
    this.locked = false
    this.cleanupIsPossible = false

    this.schedulerSubject.next({ type: 'READY' })
    this.log('Scheduler cleanuped')
  }

  checkIfProcessingIsPossible() {
    return !this.locked && this.scheduledChunks.filter((item) => item.status === 'UPLOADING').toArray().length < 2
  }

  checkIfCleanupIsPossible() {
    return (
      this.cleanupIsPossible &&
      this.scheduledChunks.filter((item) => item.status === 'WAITING' || item.status === 'UPLOADING').toArray()
        .length === 0
    )
  }

  chunkEvent(ev: WorkersEvent) {
    this.scheduledSubject.next(ev)
  }

  chunkProcessingCompleteEvent(ev: WorkersEvent) {
    if (this.scheduledChunks.has(ev.id)) this.scheduledChunks = this.scheduledChunks.setIn([ev.id, 'status'], ev.type)

    if (ev.type === 'ERROR') this.errorsInRow++
    if (this.errorsInRow >= errorsInRowOverflow) return this.lockScheduler()

    if (ev.type === 'SUCCESS') this.errorsInRow = 0

    if (this.checkIfProcessingIsPossible()) this.processNextScheduledChunk()
    if (this.checkIfCleanupIsPossible()) this.cleanup()
  }

  chunkProcessingProgressEvent(ev: WorkersEvent) {
    if (ev.type !== 'UPLOADING' || !this.progress.has(ev.id) || this.locked) return

    this.progress = this.progress.setIn([ev.id, 'loaded'], ev.loaded)
    const sum = this.progress
      .filter((item, key) => this.scheduledChunks.get(key)?.status !== 'ABORT')
      .reduce(
        (acc, val) => ({
          loaded: acc.loaded + val.loaded,
          total: acc.total + val.total,
        }),
        initialProgress,
      )

    this.schedulerSubject.next({ type: 'PROGRESS', ...sum })
  }

  observable() {
    return this.schedulerSubject.asObservable()
  }

  completedObservable() {
    return this.schedulerSubject.pipe(filter((ev) => ev.type === 'SUCCESS' || ev.type === 'ERROR'))
  }

  log(...args: any[]) {
    console.log(new Date().toISOString(), `[${this.name}Scheduler]:`, ...args)
  }
}

export type { WorkerEvent }
