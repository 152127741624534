import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'

interface IProps {
  cameraStream?: MediaStream | null
  initialModal?: boolean
}

const useStyles = makeStyles({
  root: {
    height: (props: IProps) => (props?.initialModal ? 300 : 470),
    width: (props: IProps) => (props?.initialModal ? 300 : '100%'),
    overflow: 'hidden',
    borderRadius: '10px',
    position: 'relative',
  },
  video: {
    background: '#000',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    borderRadius: '10px 10px 0 0',
    position: 'relative',
    zIndex: 1,
    objectFit: 'cover',
  },
})

const CameraPreview = (props: IProps) => {
  const { root, video } = useStyles(props)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!videoRef.current || !props.cameraStream) return

    videoRef.current.srcObject = props.cameraStream
    videoRef.current.play()
  }, [videoRef, props.cameraStream])

  return (
    <Box className={root}>
      <video className={video} ref={videoRef} />
    </Box>
  )
}

export default CameraPreview
