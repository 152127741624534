import * as React from 'react'

type Props = {}
const FlagFr = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_16376_376414)">
        <path
          d="M14 0.8375C12.7745 0.302 11.423 0 10 0C8.577 0 7.2255 0.302 6 0.8375V19.162C7.2255 19.698 8.577 20 10 20C11.423 20 12.7745 19.698 14 19.1625V0.8375Z"
          fill="#ECEFF1"
        />
        <path
          d="M20 9.99999C20 5.90049 17.5305 2.38149 14 0.837494V19.162C17.5305 17.6185 20 14.0995 20 9.99999Z"
          fill="#FF3D00"
        />
        <path
          d="M0 9.99999C0 14.0995 2.4695 17.6185 6 19.1625V0.837494C2.4695 2.38149 0 5.90049 0 9.99999Z"
          fill="#3F51B5"
        />
      </g>
      <defs>
        <clipPath id="clip0_16376_376414">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FlagFr
