import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TVideoInChannel } from '../../../../../../PartnerSidebar/common/api/listVideosInChannel'
import { screensightReaction } from '../../../../../common/hooks/api/types'
import { SearchScreensightPostsQuery, SearchScreensightPostsQueryVariables } from '../../../../../../../../../../API'
import useApolloLazyQuery from '../../../../../../../../../../common/clients/useApolloLazyQuery'
import {
  addPostToVideoPlaylistData,
  addVideoPlayerReactions,
} from '../../../../../../../../common/redux/store/chat-actions'
import { searchScreensightPosts } from '../api/searchScreensightPostsReactions'
import { AuthContext } from '../../../../../../../../../../common/providers/AuthStatusProvider'
import IamApolloClient from 'common/clients/IamApolloClient'
import CognitoApolloClient from '../../../../../../../../../../common/clients/CognitoApolloClient'
import useCachedCustomerById from '../../../../../../../../../../common/utils/useCachedCustomerById'
import { gql } from '@apollo/client'
import { useLocation, useParams } from 'react-router'
import { RootState } from '../../../../../../../../common/redux/store/store'
import * as queryString from 'query-string'

const useGetPlayerReactions = (): {
  data: screensightReaction[]
  currentVideoPostId: string | null | undefined
  channelId?: string
} => {
  const auth = useContext(AuthContext)
  const client = auth.isAuthenticated ? CognitoApolloClient : IamApolloClient
  const location = useLocation()
  const postIdQueryParam = queryString.parse(location.search)?.postId
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const videoInCache = useSelector((state: RootState) => state.chat.videoPlaylistData?.[postIdQueryParam as string])
  const [currentVideoPostId, setCurrentVideoPostId] = useState<string | null | undefined>('')
  const videoPlayerReactions = useSelector((state: RootState) => state.chat.videoPlayerReactions)?.[
    currentVideoPostId as string
  ]
  const chatCustomers = useSelector((state: RootState) => state.chat.chatCustomers)

  useEffect(() => {
    if (videoInCache) {
      if (videoInCache.postType === 'video') {
        setCurrentVideoPostId(videoInCache.id)
      } else if (videoInCache.postType === 'shared' || videoInCache.postType === 'email_video') {
        setCurrentVideoPostId(videoInCache.referencePost?.id)
      }
    }
  }, [videoInCache, postIdQueryParam])
  const channelData = client.readFragment({
    id: `screensightChannel:${channelId}`, // The value of the to-do item's cache ID
    fragment: gql`
      fragment SingleChannel on screensightChannel {
        id
        chatPartnerId
      }
    `,
  })

  const partnerData = useCachedCustomerById(channelData?.chatPartnerId)
  const dispatch = useDispatch()
  const [getReactionsFromPost] = useApolloLazyQuery<SearchScreensightPostsQuery, SearchScreensightPostsQueryVariables>(
    searchScreensightPosts,
  )

  useEffect(() => {
    const getData = async () => {
      if (videoInCache || !postIdQueryParam) return
      setCurrentVideoPostId(null)
      const res = await getReactionsFromPost({
        variables: {
          filter: {
            id: {
              eq: postIdQueryParam as string,
            },
          },
        },
      })
      const responseData = res?.data?.searchScreensightPosts?.items?.[0]
      if (!responseData) return
      // add post to redux videoPlaylistData if user is author of the post
      if (responseData?.customerId !== auth.user?.id) {
        dispatch(addPostToVideoPlaylistData(responseData as TVideoInChannel))
      }

      // add videoPostId to redux if user is author of the post
      const postId = responseData?.postType === 'video' ? responseData?.id : responseData?.referencePost?.id
      if (responseData.customerId === auth.user?.id) {
        setCurrentVideoPostId(postId)
      }

      // add reactions to redux videoPlayerReactions
      const reactions =
        responseData?.postType === 'video'
          ? responseData?.reactions?.items
          : responseData?.referencePost?.reactions?.items
      if (!postId) return
      dispatch(addVideoPlayerReactions(postId, (reactions as screensightReaction[]) || []))
    }
    getData()
  }, [postIdQueryParam, videoInCache, auth.user?.id])

  return {
    data:
      videoPlayerReactions
        ?.filter(
          (reaction: screensightReaction) =>
            reaction.customerId === chatCustomers?.author?.id || reaction.customerId === chatCustomers?.partner?.id,
        )
        ?.filter((reaction: screensightReaction) => reaction?.videoTimestamp !== 0)
        ?.sort(function (b: any, a: any) {
          if (!a?.videoTimestamp || !b?.videoTimestamp) return 0
          return b?.videoTimestamp < a?.videoTimestamp ? -1 : b?.videoTimestamp > a?.videoTimestamp ? 1 : 0
        }) || [],
    currentVideoPostId,
    channelId,
  }
}

export default useGetPlayerReactions
