import React from 'react'
import { TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { UseFormMethods } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'

interface INewContactTextField {
  form: UseFormMethods<any>
  fieldName: string
  rules?: any
  disabled?: boolean
  style?: React.CSSProperties
  InputProps?: React.CSSProperties
  topLabel?: string
  type?: string
  marginBottom?: string
  topLabelSx?: React.CSSProperties
}

const FormTextField = (props: INewContactTextField) => {
  const { t: g } = useTranslation('general')
  return (
    <>
      {props?.topLabel && (
        <Typography
          variant="body1"
          sx={{ fontWeight: 500, fontSize: '14px', marginBottom: '6px', ...props?.topLabelSx }}
        >
          {props.topLabel}
        </Typography>
      )}
      <Controller
        name={props.fieldName}
        control={props.form.control}
        defaultValue=""
        rules={
          props?.rules || {
            required: g('common.requiredField'),
            validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
          }
        }
        render={({ value, onChange, onBlur }) => (
          <TextField
            type={props?.type || 'text'}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!props.form.errors[props.fieldName]}
            helperText={props.form.errors[props.fieldName]?.message}
            disabled={props.disabled}
            variant="outlined"
            style={props.style || { width: '100%', marginBottom: props?.marginBottom || '30px' }}
            InputProps={{
              style: props.InputProps,
            }}
          />
        )}
      />
    </>
  )
}

export default FormTextField
