import React from 'react'
import { Box, Typography } from '@mui/material'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import { useTranslation } from 'react-i18next'

interface IBillingTypeCard {
  checked?: boolean
  isPopular?: boolean
  price: string
  title: string
  onClick?: () => void
}

const BillingTypeCard = (props: IBillingTypeCard) => {
  const { t } = useTranslation('settings')

  return (
    <Box
      borderRadius="10px"
      border={props.checked ? `2px solid ${theme.palette.primary.main}` : '1px solid rgba(188, 188, 195, 0.6)'}
      padding={props.checked ? '23px' : '24px'}
      width="100%"
      height="140px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      style={{ cursor: 'pointer' }}
      onClick={props?.onClick}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body1" style={{ fontWeight: 600, fontSize: '12px', color: theme.palette.primary.main }}>
          {props.title}
        </Typography>
        <Typography
          variant="body1"
          style={{
            visibility: props.isPopular ? 'visible' : 'hidden',
            fontWeight: 600,
            fontSize: '12px',
            padding: '10px',
            background: theme.palette.primary.main,
            borderRadius: '10px',
            color: 'white',
          }}
        >
          {t('billing.purchase.bestChoice')}
        </Typography>
      </Box>
      <Typography variant="h6" style={{ fontSize: '32px', fontWeight: 600 }}>
        {props.price}{' '}
        <span style={{ fontSize: '15px', fontWeight: 400, color: theme.palette.text.secondary }}>
          {t('billing.common.monthUnit')}
        </span>
      </Typography>
    </Box>
  )
}

export default BillingTypeCard
