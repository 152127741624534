import * as React from 'react'
import { Box } from '@mui/material'

const BackgroundPageNotFound = () => {
  return (
    <Box zIndex={0} className="layer1">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 522 320" fill="none">
        <path
          d="M520.025 142.585C516.639 183.884 456.187 231.271 385.755 268.025C284.676 320.772 279.179 268.122 212.829 311.606C161.578 345.195 129.461 257.967 93 247.422C28.6429 228.809 -20.5516 240.32 9.99996 122.422C25.8269 61.3458 97.8723 46.6322 171.468 14.02C245.063 -18.5922 311.649 13.2527 327.838 33.426C344.026 53.5994 384.19 74.575 453.386 61.3458C522.583 48.1166 523.411 101.285 520.025 142.585Z"
          fill="#F8F9FB"
        />
      </svg>
      {/*</Box>*/}
    </Box>
  )
}

export default BackgroundPageNotFound
