import { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { AccessType, GetVideoQuery, GetVideoQueryVariables } from '../../../../../../API'
import gql from 'graphql-tag'
import { getVideoThumbnailEdit } from '../../../../../../common/api/graphql/queries'
import CognitoApolloClient from '../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../common/clients/IamApolloClient'
import { AuthContext } from '../../../../../../common/providers/AuthStatusProvider'
import { useLocation, useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useEditThumbnailDispatch } from '../context/EditThumbnailContext'

const useEditThumbnail = () => {
  const auth = useContext(AuthContext)
  const { id: videoId } = useParams() as { id: string }
  const location = useLocation()
  const history = useHistory()
  const editThumbnailDispatcher = useEditThumbnailDispatch()

  const { loading: videoThumbnailLoading, data: videoThumbnail } = useQuery<GetVideoQuery, GetVideoQueryVariables>(
    gql(getVideoThumbnailEdit),
    {
      variables: { id: videoId },
      skip: !videoId || auth.user?.pending,
      client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    },
  )
  useEffect(() => {
    if (!videoThumbnail?.getVideo?.customerId && videoThumbnail?.getVideo?.accessType !== AccessType.protected) return
    if (videoThumbnail?.getVideo?.customerId !== auth.user?.id) {
      history.push(`/video/${videoId}`)
    }
  }, [videoThumbnail?.getVideo])

  useEffect(() => {
    if (!videoThumbnail?.getVideo?.thumbnailObject?.key || !videoThumbnail?.getVideo?.id) return
    editThumbnailDispatcher({ type: 'setThumbnailKey', value: videoThumbnail.getVideo.thumbnailObject.key })
    editThumbnailDispatcher({ type: 'setVideoId', value: videoThumbnail.getVideo.id })
  }, [videoThumbnail?.getVideo])

  // Save router video params to local storage to prevent loosing them on page refresh
  useEffect(() => {
    if (videoThumbnail?.getVideo?.id && location.state) {
      localStorage.setItem(
        'videoParams',
        JSON.stringify({ params: location.state, videoId: videoThumbnail?.getVideo?.id }),
      )
    }
  }, [videoThumbnail?.getVideo?.id])
  const handleExit = () => {
    history.push(`/video/${videoId}`)
  }

  return {
    photoUrl: videoThumbnail?.getVideo?.thumbnailObject?.key,
    loading: videoThumbnailLoading,
    filePresignedUrl: videoThumbnail?.getVideo?.filePresignedUrl,
    handleExit,
  }
}

export default useEditThumbnail
