import * as React from 'react'
import { Box } from '@mui/material'

const FastRewind = () => {
  return (
    <Box display="flex">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.5503 9.65128L13.5503 13.3995L8.60051 8.44975L13.5503 3.50001L13.5503 7.65702C13.5229 8.75196 13.5273 8.57305 13.5503 9.65128Z"
          fill="white"
        />
        <path
          d="M12 8.0241C12 7.73466 12.235 7.49782 12.5235 7.52113C13.6246 7.61009 14.6879 7.97856 15.6112 8.59546C16.6801 9.30969 17.5132 10.3249 18.0052 11.5126C18.4972 12.7003 18.6259 14.0072 18.3751 15.2681C18.1243 16.529 17.5052 17.6872 16.5962 18.5962C15.6872 19.5053 14.529 20.1243 13.2681 20.3751C12.0072 20.6259 10.7003 20.4972 9.51256 20.0052C8.32484 19.5133 7.30968 18.6801 6.59545 17.6112C5.97854 16.688 5.61008 15.6246 5.52111 14.5236C5.4978 14.235 5.73464 14 6.02408 14C6.31353 14 6.54556 14.2352 6.57334 14.5233C6.65949 15.4168 6.96528 16.2781 7.46697 17.0289C8.06602 17.9254 8.91748 18.6242 9.91367 19.0369C10.9099 19.4495 12.006 19.5575 13.0636 19.3471C14.1212 19.1367 15.0926 18.6175 15.855 17.855C16.6175 17.0926 17.1367 16.1212 17.3471 15.0636C17.5574 14.0061 17.4495 12.9099 17.0368 11.9137C16.6242 10.9175 15.9254 10.066 15.0289 9.46698C14.2781 8.9653 13.4168 8.6595 12.5233 8.57335C12.2352 8.54557 12 8.31354 12 8.0241Z"
          fill="white"
          stroke="white"
          strokeWidth="0.5"
        />
      </svg>
    </Box>
  )
}

export default FastRewind
