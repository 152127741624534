import { useState } from 'react'

const useContactsList = () => {
  const [contactToDelete, setContactToDelete] = useState<string | null>(null)

  return {
    setContactToDelete,
    contactToDelete,
  }
}

export default useContactsList
