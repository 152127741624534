import React from 'react'
import { UseErrorProvider } from './useErrorProvider'
import { ErrorProviderProps } from './index'
import DeleteConfirmContext from './ErrorContext'
import { Close } from '@mui/icons-material'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import Button from '../../../components/_refactored/Button'
import Modal from '../../../components/_refactored/Modal'

const ErrorProviderView = (props: ErrorViewProps) => {
  const { children, modalState, abort, ok, open, text } = props
  const theme = useTheme()

  return (
    // @ts-ignore
    <DeleteConfirmContext.Provider value={{ open }}>
      <Modal resetPadding open={modalState} onClose={abort}>
        <Box padding="8px" bgcolor={theme.palette.background.default}>
          <Box display="flex" justifyContent="flex-end" marginBottom="32px">
            <Close onClick={abort} style={{ cursor: 'pointer' }} />
          </Box>
          <Box padding="0 32px">
            {text.title && (
              <Typography color="error" variant="h3" align="center">
                {text.title}
              </Typography>
            )}
            {text.subtitle && (
              <Typography variant="body1" color="textSecondary" align="center" style={{ marginTop: '16px' }}>
                {text.subtitle}
              </Typography>
            )}
            <Grid container justifyContent="center" spacing={3} marginTop="24px" marginBottom="24px">
              {text.showAbort ? (
                <Grid item xs={6} md={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ color: theme.palette.error.main, borderColor: theme.palette.error.main }}
                    onClick={abort}
                  >
                    {text.showAbort}
                  </Button>
                </Grid>
              ) : null}
              <Grid item xs={6} md={4}>
                <Button fullWidth variant="outlined" color="secondary" onClick={ok}>
                  {text.okText}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>

      {children}
    </DeleteConfirmContext.Provider>
  )
}

export type ErrorViewProps = UseErrorProvider & ErrorProviderProps
export default ErrorProviderView
