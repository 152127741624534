import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export interface DeleteConfirmOpen {
  callback?: () => any
  title?: string
  subtitle?: string
}

const useDeleteConfirmProvider = () => {
  const { t } = useTranslation('library')
  const [modalState, setModalState] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [callback, setCallback] = useState<() => void>(() => () => {})
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [loading, setLoading] = useState(false)

  const deleteConfirmOpenDefault = {
    callback: () => null,
    title: t('videoList.deleteVideoModal.title'),
    subtitle: t('videoList.deleteVideoModal.subtitle'),
  }

  const open = useCallback((props: DeleteConfirmOpen) => {
    setCallback((() => props.callback) || (() => deleteConfirmOpenDefault.callback))
    setTitle(props.title || deleteConfirmOpenDefault.title)
    setSubtitle(props.subtitle || deleteConfirmOpenDefault.subtitle)

    setModalState(true)
  }, [])

  const abort = useCallback(() => {
    setModalState(false)
  }, [])

  const ok = async () => {
    try {
      setLoading(true)
      await callback()
    } catch (e: any) {
      console.log('Error during deleting data', e)
    } finally {
      setLoading(false)
      setModalState(false)
    }
  }

  return { modalState, open, abort, ok, title, subtitle, loading }
}

export type UseDeleteConfirmProvider = ReturnType<typeof useDeleteConfirmProvider>
export default useDeleteConfirmProvider
