import { Box, Typography } from '@mui/material'
import Icon from '../../../../common/icons'
import Edge from '../../../../common/icons/Edge.png'
import Button from '../../../../components/_refactored/Button'
import React from 'react'
import { VideoNewModalViewProps } from './VideoNewModal.view'
import { useTranslation } from 'react-i18next'

type BrowserSupportModalContentViewProps = {
  onClose: VideoNewModalViewProps['onClose']
  isMobile: boolean
}

const BrowserSupportModalContentView = ({ onClose, isMobile }: BrowserSupportModalContentViewProps) => {
  const { t } = useTranslation('library')
  return (
    <Box>
      <Typography variant="body1" align="center" style={{ fontWeight: 500, fontSize: '18px', marginBottom: '20px' }}>
        {t('videoList.browserSupportModal.title')}
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" style={{ fontSize: '15px' }}>
        {t('videoList.browserSupportModal.subtitle')}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop="20px"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Box display="flex" alignItems="center" marginBottom={isMobile ? '10px' : '0'}>
          <Icon.Chrome style={{ marginRight: '5px' }} />
          <Typography variant="body2" color="textSecondary">
            {t('videoList.browserSupportModal.chrome')}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginLeft={isMobile ? '0' : '10px'}>
          <img src={Edge} style={{ marginRight: '5px' }} alt={'Edge Browser'} />
          <Typography variant="body2" color="textSecondary">
            {t('videoList.browserSupportModal.edge')}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" marginTop="50px">
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {t('videoList.common.gotItBtn')}
        </Button>
      </Box>
    </Box>
  )
}

export default BrowserSupportModalContentView
