import { Box, Typography } from '@mui/material'
import Button from '../../../../components/_refactored/Button'
import React from 'react'
import { VideoNewModalViewProps } from './VideoNewModal.view'
import RecordingTypeRadioButtons from '../../../../components/RecordingTypeRadioButtons'
import ExtensionIntalledGif from '../../../../common/assets/extensionInstalled.gif'
import { useTranslation } from 'react-i18next'

type ExtensionInstalledAnimationTutorialViewProps = {
  onClose: VideoNewModalViewProps['onClose']
  setScreenValue: VideoNewModalViewProps['setScreenValue']
  setCameraValue: VideoNewModalViewProps['setCameraValue']
  setCameraAndScreenValue: VideoNewModalViewProps['setCameraAndScreenValue']
  selectedRecordingType: VideoNewModalViewProps['selectedRecordingType']
  setExtensionModalVisible: VideoNewModalViewProps['setExtensionModalVisible']
  form: VideoNewModalViewProps['form']
  isMobile: boolean
  fileInputRef?: any
  handleUploadVideoInputClick?: any
  handleUploadingProcess?: any
}

const ExtensionInstalledAnimationTutorialView = ({
  setScreenValue,
  setCameraValue,
  setCameraAndScreenValue,
  selectedRecordingType,
  setExtensionModalVisible,
  onClose,
  form,
  handleUploadVideoInputClick,
  handleUploadingProcess,
  fileInputRef,
}: ExtensionInstalledAnimationTutorialViewProps) => {
  const { t } = useTranslation('library')
  return (
    <>
      <Box display="flex">
        <Box>
          <Typography variant="h3" style={{ lineHeight: '26px', marginBottom: '10px' }}>
            {t('videoList.extensionInstalledTutorialModal.title')}
          </Typography>
          <Typography variant="body1" style={{ lineHeight: '20px', fontSize: '15px' }} color="textSecondary">
            {t('videoList.extensionInstalledTutorialModal.subtitle')}
          </Typography>
          <RecordingTypeRadioButtons
            setScreenValue={setScreenValue}
            setCameraValue={setCameraValue}
            setCameraAndScreenValue={setCameraAndScreenValue}
            selected={selectedRecordingType}
            form={form}
            handleUploadVideoInputClick={handleUploadVideoInputClick}
            handleUploadingProcess={handleUploadingProcess}
            fileInputRef={fileInputRef}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <img alt="ScreenSight extension" src={ExtensionIntalledGif} width="100%" height="auto" />
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="center" marginTop="24px">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onClose()
            setExtensionModalVisible(false)
          }}
        >
          {t('videoList.extensionInstalledTutorialModal.gotItBtn')}
        </Button>
      </Box>
    </>
  )
}

export default ExtensionInstalledAnimationTutorialView
