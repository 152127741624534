import * as React from 'react'
import { Box } from '@mui/material'

const SingleDot = () => {
  return (
    <Box>
      <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_12260_427151" fill="white">
          <ellipse cx="1.68306" cy="1.54978" rx="1.55" ry="1.54978" />
        </mask>
        <path
          d="M1.23306 1.54978C1.23306 1.30087 1.43479 1.09957 1.68306 1.09957V5.09957C3.6434 5.09957 5.23306 3.51054 5.23306 1.54978H1.23306ZM1.68306 1.09957C1.93132 1.09957 2.13306 1.30087 2.13306 1.54978H-1.86694C-1.86694 3.51054 -0.277292 5.09957 1.68306 5.09957V1.09957ZM2.13306 1.54978C2.13306 1.79869 1.93132 2 1.68306 2V-2C-0.277292 -2 -1.86694 -0.41097 -1.86694 1.54978H2.13306ZM1.68306 2C1.43479 2 1.23306 1.79869 1.23306 1.54978H5.23306C5.23306 -0.41097 3.6434 -2 1.68306 -2V2Z"
          fill="url(#paint0_linear_12260_427151)"
          mask="url(#path-1-inside-1_12260_427151)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_12260_427151"
            x1="3.11608"
            y1="3.02344"
            x2="0.249302"
            y2="2.98722"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2E71F2" />
            <stop offset="1" stopColor="#4AA3F5" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  )
}
export default SingleDot
