import { useMutation, useQuery } from '@apollo/client'
import {
  BillingInformation,
  BillingProduct,
  CancelBillingSubscriptionMutation,
  GetBillingInformationQuery,
  ListPricePlansQuery,
} from '../../../../../API'
import gql from 'graphql-tag'
import CognitoApolloClient from '../../../../../common/clients/CognitoApolloClient'
import { useContext, useEffect, useMemo } from 'react'
import { AuthContext } from '../../../../../common/providers/AuthStatusProvider'
import { getBillingInformation, listPricePlans } from '../../../../../graphql/queries'
import { cancelBillingSubscription } from '../../../../../graphql/mutations'

function useGetUserPayment() {
  const auth = useContext(AuthContext)

  const { data: billingInformationData, loading: billingInformationDataLoading } = useQuery<
    GetBillingInformationQuery,
    BillingInformation
  >(gql(getBillingInformation), {
    fetchPolicy: 'network-only',
  })

  const { data: plansData, loading: plansDataLoading } = useQuery<ListPricePlansQuery, BillingProduct>(
    gql(listPricePlans),
    {
      skip: auth.user?.pending || !auth.isAuthenticated,
      fetchPolicy: 'network-only',
      client: CognitoApolloClient,
      nextFetchPolicy: 'network-only',
    },
  )

  const [cancelPricePlanToFree, { loading: loadingSubscriptionCancel }] = useMutation<
    CancelBillingSubscriptionMutation,
    BillingInformation
  >(gql(cancelBillingSubscription), {
    refetchQueries: [{ query: gql(getBillingInformation) }],
  })

  const prices = useMemo(() => {
    if (!plansData?.listPricePlans?.length) return {}

    const starterPlanPrice = plansData?.listPricePlans
      ?.find((plan) => plan?.name?.toLowerCase()?.includes('starter'))
      ?.prices?.find((priceObj) => priceObj?.interval === 'year')?.amount

    const professionalPlanPrice = plansData?.listPricePlans
      ?.find((plan) => plan?.name?.toLowerCase()?.includes('professional'))
      ?.prices?.find((priceObj) => priceObj?.interval === 'year')?.amount

    return {
      starter: String(+parseFloat(String((starterPlanPrice || 0) / 12)).toFixed(2)) || '',
      professional: String(+parseFloat(String((professionalPlanPrice || 0) / 12)).toFixed(2)) || '',
    }
  }, [plansData])

  const currentPricePlanName = useMemo(() => {
    return billingInformationData?.getBillingInformation?.subscription?.product?.name
  }, [billingInformationData])

  const hasPaymentAttached = useMemo(() => {
    return !!billingInformationData?.getBillingInformation?.paymentMethod
  }, [billingInformationData])

  const starterPlan = useMemo(() => {
    return plansData?.listPricePlans?.find((plan) => plan?.name?.toLowerCase()?.includes('starter'))
  }, [plansData])

  const proPlan = useMemo(() => {
    return plansData?.listPricePlans?.find((plan) => plan?.name?.toLowerCase()?.includes('professional'))
  }, [plansData])

  return {
    currentPlan: currentPricePlanName,
    loading: billingInformationDataLoading || plansDataLoading,
    prices,
    loadingSubscriptionCancel,
    cancelPricePlanToFree,
    billingInformationData,
    hasPaymentAttached,
    starterPlan,
    proPlan,
  }
}

export default useGetUserPayment
