import * as React from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface INameInputProps {
  form: any
  loading: boolean
  topLabel?: string
  inputLabel?: string
}
const NameInput = (props: INameInputProps) => {
  const { t: g } = useTranslation('general')

  return (
    <>
      {props?.topLabel && (
        <Typography variant="body1" style={{ fontWeight: 500, fontSize: '14px', marginBottom: '6px' }}>
          {props?.topLabel}
        </Typography>
      )}
      <Controller
        name={'name'}
        control={props.form.control}
        rules={{
          required: g('common.requiredField'),
          validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
        }}
        render={({ value, onChange, onBlur }) => (
          <TextField
            id="name"
            label={props?.inputLabel}
            value={value}
            onChange={onChange}
            disabled={props.loading}
            onBlur={onBlur}
            error={!!props.form.errors['name']}
            helperText={props.form.errors['name']?.message}
            variant="outlined"
            fullWidth
            InputProps={{
              style: {
                fontSize: '16px',
              },
            }}
          />
        )}
      />
    </>
  )
}
export default NameInput
