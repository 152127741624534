import React from 'react'
import { Box, Grid, Theme, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { screensightVideo } from '../../../../API'
import Button from '../../../../components/_refactored/Button'
import UploadQueue from '../../../../common/services/UploadQueue'
import { IndexedRecordingEntity } from '../../../../common/services/IndexedDB'
import { useTranslation } from 'react-i18next'

interface IVideoErrorItem {
  isMobile: boolean
  isLargeScreen: boolean
  theme: Theme
  item: screensightVideo | IndexedRecordingEntity
}

const VideoErrorItem = (props: IVideoErrorItem) => {
  const { isMobile, isLargeScreen, theme, item } = props
  const { t } = useTranslation('library')
  return (
    <Grid item xs={12} xl={4}>
      <Box display="flex" height={isMobile ? '182px' : '210px'} style={{ overflow: 'hidden' }}>
        {isMobile && <Box width={isMobile ? '4px' : '5px'} borderRadius="5px" height="100%" bgcolor="error.main" />}
        <Box
          marginLeft={isMobile ? '6px' : '13px'}
          width="100%"
          padding={isMobile ? '14px' : '30px'}
          borderRadius="7px"
        >
          <Box display="flex">
            {!isMobile && (
              <Box
                width={isMobile ? '4px' : '5px'}
                borderRadius="5px"
                height="150px"
                bgcolor="error.main"
                marginRight="13px"
                marginLeft="-13px"
              />
            )}

            <div>
              <Box
                height={isMobile ? '80px' : '150px'}
                width={isMobile ? '80px' : '150px'}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="10px"
                bgcolor="error.light"
                marginRight={isMobile ? '11px' : '20px'}
              >
                <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.434573 0.00133219C0.41483 0.00476612 0.395088 0.00991701 0.377139 0.0150679C0.204836 0.0442564 0.0666346 0.164444 0.0181743 0.324122C-0.0302861 0.485518 0.0199691 0.657215 0.147402 0.770534L22.2022 21.8687C22.3853 22.0438 22.6796 22.0438 22.8627 21.8687C23.0458 21.6935 23.0458 21.4119 22.8627 21.2368L17.0187 15.6464V6.16869C17.0187 4.93762 16.0083 3.97096 14.7214 3.97096H4.81394L0.807897 0.13869C0.712771 0.0408225 0.57457 -0.00896963 0.434573 0.00133219ZM2.31555 3.97096C1.07533 3.97096 0.0181743 4.98226 0.0181743 6.16869V15.8387C0.0181743 17.0697 1.02866 18.0364 2.31555 18.0364H14.7214C15.3639 18.0364 15.9167 17.7702 16.3295 17.4183L2.31555 3.97096ZM22.9919 5.42695L17.9377 8.03675V13.9706L22.9919 16.5804V5.42695Z"
                    fill="#EA0044"
                  />
                </svg>
              </Box>
            </div>
            <Box width="100%" style={{ overflowX: 'hidden' }}>
              <div style={{ display: 'flex', width: '100%', maxWidth: '100%', justifyContent: 'space-between' }}>
                <Typography
                  variant="h3"
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    maxWidth: isMobile ? 'calc(100vw - 200px)' : isLargeScreen ? '70%' : '40vw',
                    marginBottom: '3px',
                  }}
                >
                  <Link
                    to={`/video/${(item as screensightVideo)?.accessId || (item as screensightVideo)?.id}`}
                    style={{ color: theme.palette.text.primary }}
                  >
                    {item?.title}
                  </Link>
                </Typography>
              </div>
              <Typography variant="caption" color="error">
                {t('videoList.listItem.videoUploadError')}
              </Typography>
              <br />
              <Button
                variant="outlined"
                color="secondary"
                size={isMobile ? 'small' : 'medium'}
                onClick={() => UploadQueue.restoreRecording((item as IndexedRecordingEntity).localId)}
                style={{ marginTop: '11px' }}
              >
                {t('videoList.listItem.resumeUploadBtn')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box marginBottom="22px" />
    </Grid>
  )
}

export default VideoErrorItem
