import React from 'react'
import { Box, Typography } from '@mui/material'
import EnvelopeIcon from '../../../../../../../common/icons/Envelope'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import { useTranslation } from 'react-i18next'
import SignUpBackgroundLogoTwo from '../../../../../../../common/icons/SignUpBackgroundLogoTwo'

const CompanyAside = () => {
  const { t } = useTranslation('auth')
  return (
    <Box
      width="50%"
      minHeight="100vh"
      display="flex"
      position="relative"
      alignItems="center"
      justifyContent="center"
      padding="70px"
      style={{
        objectPosition: 'center',
        objectFit: 'cover',
        background: 'linear-gradient(79deg, #38C3FF 0%, #236BF6 100%)',
      }}
    >
      <Box width="592px" display="flex" flexDirection="column" alignItems="center">
        <Box width="410px" display="flex" flexDirection="column" alignItems="center">
          <EnvelopeIcon />
          <Typography
            variant="h2"
            style={{
              marginTop: '30px',
              fontSize: '40px',
              fontWeight: 700,
              color: theme.palette.background.default,
            }}
          >
            {t('verify.companyAside.sloganIntro')}
          </Typography>
          <Typography
            variant="h2"
            style={{
              marginTop: '30px',
              fontSize: '16px',
              fontWeight: 400,
              color: theme.palette.background.default,
            }}
          >
            {t('verify.companyAside.sloganMain')}
          </Typography>
        </Box>
        <Box position="absolute" top={0} left={30}>
          <SignUpBackgroundLogoTwo />
        </Box>
      </Box>
    </Box>
  )
}

export default CompanyAside
