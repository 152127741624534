import React, { useMemo } from 'react'
import { Switch, HashRouter } from 'react-router-dom'
import { Route } from 'react-router'

import routes, { INestedRoute, IRoute } from './routes'

interface NestedRouterProps {
  routes: Record<string, IRoute | INestedRoute>
}

function isIRoute(route: IRoute | INestedRoute): route is IRoute {
  return (route as IRoute).component !== undefined
}

const NestedRouter = (props: NestedRouterProps) => {
  const routeArray = useMemo(
    () =>
      Object.entries(props.routes)
        .map(([key, value]) => value)
        .sort((a, b) => a.order - b.order),
    [props],
  )

  return useMemo(() => {
    return (
      <Switch>
        {routeArray.map((route) => {
          if (isIRoute(route)) {
            return (
              <Route
                // @ts-ignore
                key={Route} // this link is important to remove key warning, but the key must be the same for multiple elements
                exact
                path={route.path}
                children={<route.component />}
              />
            )
          }

          if (route.routes) {
            return (
              <Route
                // @ts-ignore
                key={Route} // this link is important to remove key warning, but the key must be the same for multiple elements
                exact={false}
                path={route.path}
              >
                <route.layout>
                  <NestedRouter
                    // @ts-ignore
                    key={Route} // this link is important to remove key warning, but the key must be the same for multiple elements
                    routes={route.routes}
                  />
                </route.layout>
              </Route>
            )
          }

          throw new Error('router badly configured: missing component or routes')
        })}
      </Switch>
    )
  }, [routeArray])
}

const Router = () => (
  <HashRouter>
    <NestedRouter routes={routes} />
  </HashRouter>
)

export default Router
