import { v4 } from 'uuid'
import { Storage } from '@aws-amplify/storage'
import awsmobile from '../../../../../../aws-exports'
import { useMutation } from '@apollo/client'
import { UpdateVideoMutation, UpdateVideoMutationVariables } from '../../../../../../API'
import gql from 'graphql-tag'
import { updateVideo } from '../../../../../../graphql/mutations'
import CognitoApolloClient from '../../../../../../common/clients/CognitoApolloClient'
import { useContext } from 'react'
import ToastContext from '../../../../../../common/providers/ToastProvider/ToastContext'
import { useTranslation } from 'react-i18next'

const useUploadThumbnail = (videoId: string, thumbnailKey: string, isGif?: boolean) => {
  const { open } = useContext(ToastContext)
  const { t } = useTranslation('library')
  const [updateVideoMutation] = useMutation<UpdateVideoMutation, UpdateVideoMutationVariables>(gql(updateVideo), {
    client: CognitoApolloClient,
  })

  const uploadThumbnail = async (file: Blob | File) => {
    if (!videoId || !file || !thumbnailKey) return
    const uuid = v4()

    const key = thumbnailKey.substring(16).split('/')[0]

    try {
      const s3Object: { key: string } = (await Storage.put(`incoming/${key}/${uuid}.${isGif ? 'gif' : 'png'}`, file, {
        contentType: `image/${isGif ? 'gif' : 'png'}`,
        useAccelerateEndpoint: true,
      })) as { key: string }

      await updateVideoMutation({
        variables: {
          input: {
            id: videoId,
            thumbnailObject: {
              key: `public/${s3Object.key}`,
              bucket: awsmobile.aws_user_files_s3_bucket,
            },
          },
        },
      })
      open({ type: 'INFO', text: t('videoItem.editThumbnail.successfullyUploaded') })
    } catch (e: any) {
      console.log('Error during uploading video thumbnail', e)
      open({ type: 'ERROR', text: t('videoItem.editThumbnail.uploadError') })
    }
  }
  return [uploadThumbnail] as const
}

export default useUploadThumbnail
