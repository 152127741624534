import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import { useChatDispatch } from '../../contexts/ChatContext'
import { useMutation } from '@apollo/client'
import { CreatePostMutation, CreatePostMutationVariables } from '../../../../../../../../API'
import gql from 'graphql-tag'
import { createPost } from '../../../../../../../../common/api/graphql/mutations'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import { useParams } from 'react-router'
import useUpdateChannelsCache from './useUpdateChannelsCache'
import moment from 'moment'

const useSendReplyToCommentPost = () => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const chatDispatcher = useChatDispatch()
  const [updateChannelLatestAt] = useUpdateChannelsCache()
  const customerIdToUse = auth.isAuthenticated
    ? auth.user?.id
    : sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId') || ''

  const [createPostMutation] = useMutation<CreatePostMutation, CreatePostMutationVariables>(gql(createPost), {
    client: !auth.user?.pending && auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    update(cache, { data }) {
      cache.modify({
        fields: {
          listPostsByChannel(existingItems = {}) {
            const newReplyToCommentPostRef = cache.writeFragment({
              data: data?.createPost,
              fragment: gql`
                fragment newReplyToCommentPost on ScreensightPost {
                  id
                  channelId
                  accessId
                  customerId
                  postType
                  video
                  comment {
                    id
                    customerId
                    videoId
                    channelId
                    parentId
                    content
                    richContent
                    videoTimestamp
                    createdAt
                    updatedAt
                  }
                  emailMessage
                  text
                  file
                  engagement
                  referencePostId
                  referencePost {
                    id
                    channelId
                    accessId
                    customerId
                    postType
                    video
                    comment {
                      customerId
                      videoId
                      channelId
                      parentId
                      content
                      richContent
                      videoTimestamp
                      createdAt
                      updatedAt
                    }
                    emailMessage
                    text
                    readHorizon
                    createdAt
                    updatedAt
                    file
                  }
                  readHorizon
                  reactions
                  createdAt
                  updatedAt
                }
              `,
            })
            return {
              ...existingItems,
              items: [newReplyToCommentPostRef, ...existingItems.items],
            }
          },
        },
      })
    },
  })

  const sendReplyToCommentPost = async (
    content: string,
    richContent: string,
    parentId: string,
    videoId: string,
    videoCommentTimestamp: number,
    title: string,
  ) => {
    chatDispatcher({ type: 'setResponseBoxData', value: undefined })
    const tempDate = moment(new Date()).add(1, 's').toISOString()

    const res = await createPostMutation({
      variables: {
        input: {
          comment: {
            customerId: customerIdToUse,
            parentId: parentId,
            channelId,
            content,
            richContent,
            videoId: videoId || '',
          },
        },
      },
      optimisticResponse: {
        createPost: {
          __typename: 'screensightPost',
          id: 'temp-id-id',
          channelId,
          accessId: null,
          customerId: customerIdToUse,
          postType: 'reply',
          video: null,
          comment: {
            __typename: 'screensightVideoComment',
            id: 'temp-comment-id',
            customerId: customerIdToUse,
            videoId: videoId,
            channelId,
            parentId: parentId,
            content: content,
            richContent: richContent,
            videoTimestamp: null,
            createdAt: tempDate,
            updatedAt: tempDate,
          },
          emailMessage: null,
          text: null,
          file: null,
          engagement: null,
          referencePostId: null,
          referencePost: {
            __typename: 'screensightPost',
            id: 'temp-reference-id',
            channelId,
            accessId: null,
            customerId: customerIdToUse,
            postType: 'comment',
            video: null,
            comment: {
              id: 'temp-referencePost-comment-id',
              __typename: 'screensightVideoComment',
              customerId: customerIdToUse,
              videoId: videoId,
              channelId,
              parentId: parentId,
              content: title,
              richContent: null,
              videoTimestamp: videoCommentTimestamp,
              createdAt: tempDate,
              updatedAt: tempDate,
            },
            emailMessage: null,
            text: null,
            file: null,
            readHorizon: null,
            createdAt: tempDate,
            updatedAt: tempDate,
          },
          readHorizon: null,
          reactions: null,
          createdAt: tempDate,
          updatedAt: tempDate,
        },
      },
    })
    if (res.data?.createPost?.channelId && res.data.createPost?.createdAt) {
      updateChannelLatestAt(res.data?.createPost?.channelId, res.data.createPost?.createdAt)
    }
  }

  return [sendReplyToCommentPost]
}

export default useSendReplyToCommentPost
