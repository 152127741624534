import { useQuery } from '@apollo/client'
import { GetVideoQuery, GetVideoQueryVariables } from '../../../API'
import gql from 'graphql-tag'
import { getVideo } from '../../../graphql/queries'
import CognitoApolloClient from '../../../common/clients/CognitoApolloClient'
import { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../../common/providers/AuthStatusProvider'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { VideoJsPlayer } from 'video.js'
import useGetVideoFragment from '../../../common/api/hooks/useGetVideoFragment'
import { Storage } from 'aws-amplify'
import awsmobile from '../../../aws-exports'
import { escapeBtoaString } from '../../../common/utils/escapeBtoaString'

const useVideoStatisticsHook = () => {
  const auth = useContext(AuthContext)
  const history = useHistory()
  const [visible, setVisible] = useState<boolean>(false)
  const [poster, setPoster] = useState<string | undefined>(undefined)

  const { id: videoId } = useParams() as { id: string }
  const playerRef = useRef<VideoJsPlayer | null>(null)
  const [sectionBarsVisible, setSectionBarsVisible] = useState(false)
  const [subsectionMS, setSubsectionMS] = useState([0, 0])

  const { loading: videoLoading, data: videoData } = useQuery<GetVideoQuery, GetVideoQueryVariables>(gql(getVideo), {
    variables: { id: videoId },
    skip: !videoId || !auth.isAuthenticated,
    client: CognitoApolloClient,
  })
  const cacheVideo = useGetVideoFragment(videoData?.getVideo?.id)
  const videoThumbnail = videoData?.getVideo?.thumbnailObject?.key || cacheVideo?.thumbnailObject?.key
  const isGif =
    videoData?.getVideo?.thumbnailObject?.key.includes('.gif') || cacheVideo?.thumbnailObject?.key.includes('gif')

  useEffect(() => {
    if (!videoData?.getVideo?.thumbnailObject?.key && !cacheVideo?.thumbnailObject?.key) return
    if (isGif) {
      const getImageFromS3 = async () => {
        const img = await Storage.get(videoThumbnail || '', {
          customPrefix: {
            public: '',
          },
        })
        setPoster(img)
      }
      getImageFromS3()
    } else {
      getImageFromCloudFront()
    }
  }, [videoData?.getVideo?.thumbnailObject?.key, cacheVideo?.thumbnailObject?.key])

  const getImageFromCloudFront = () => {
    const imageRequest = JSON.stringify({
      bucket: awsmobile.aws_user_files_s3_bucket,
      key: videoData?.getVideo?.thumbnailObject?.key || cacheVideo?.thumbnailObject?.key,
    })
    const url = `https://${process.env.REACT_APP_CLOUDFRONT_DOMAIN_NAME}/${btoa(escapeBtoaString(imageRequest))}`
    setPoster(url)
  }

  useEffect(() => {
    if (!videoData) return
    if (videoData.getVideo?.customerId !== auth.user?.id) {
      history.push(`/video/${videoId}`)
    }
  }, [videoData])

  useEffect(() => {
    if (!playerRef?.current) return
    const player = playerRef.current
    player.on('useractive', () => {
      if (subsectionMS[0] !== subsectionMS[1]) {
        // setSectionBarsVisible(true)
      }
    })
    player.on('userinactive', () => {
      if (!player.paused()) {
        setSectionBarsVisible(false)
      }
    })
  }, [playerRef.current, subsectionMS])

  const onLoadedMetadata = () => {
    setVisible(true)
  }
  const onPlayerReady = (player: VideoJsPlayer) => {
    playerRef.current = player
    player?.on('loadedmetadata', onLoadedMetadata)
  }

  const handleSectionPlay = (data: number[], domElWidth: number) => {
    if (!videoData?.getVideo?.duration || !playerRef.current) return
    setSubsectionMS([
      (data[0] / videoData.getVideo.duration) * domElWidth,
      // (data[0] / 114000) * domElWidth,
      (data[1] / videoData.getVideo.duration) * domElWidth,
      // (data[1] / 114000) * domElWidth,
    ])
    window.scrollTo(0, 0)

    playerRef.current.currentTime(data[0] / 1000)
    playerRef.current.userActive(true)
    playerRef.current.play()
    // setSectionBarsVisible(true)
  }

  return {
    videoData,
    playerRef,
    videoLoading,
    handleSectionPlay,
    subsectionMS,
    sectionBarsVisible,
    onPlayerReady,
    visible,
    poster,
  }
}

export default useVideoStatisticsHook
