import React, { FC } from 'react'
import useOnBoardingProfileInitSteps from './hooks/useOnBoardingProfileInitSteps'
import OnBoardingProfileInitStepsView from './view/OnBoardingProfileInitStepsView'

type OnBoardingProfileInitStepsProps = {
  onFinish: () => void
  visible: boolean
}

const OnBoardingProfileInitSteps: FC<OnBoardingProfileInitStepsProps> = ({ onFinish, visible }) => {
  const state = useOnBoardingProfileInitSteps(onFinish)
  if (visible) return <OnBoardingProfileInitStepsView {...state} />
  return null
}

export default OnBoardingProfileInitSteps
