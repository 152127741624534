import * as React from 'react'
import ReactDOM from 'react-dom'
import { IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import FastRewind from '../../common/icons/FastRewind'
import FastForward from '../../common/icons/FastForward'

type Props = {
  visible: boolean | undefined
  skipVideo: (event: KeyboardEvent | null, interval: number) => void
  postId?: string
}

const SkipVideoButtons = (props: Props) => {
  const [parent, setParent] = useState<Element | null>(null)

  const node = document.querySelector(
    props?.postId ? `.video-js-chat-video-${props.postId} .vjs-control-bar` : '.vjs-control-bar',
  )

  useEffect(() => {
    if (!props.visible || !node) return
    setParent(node)
  }, [props.visible, props?.postId, node])

  if (!parent) return <></>

  return ReactDOM.createPortal(
    <>
      <IconButton
        onClick={() => props.skipVideo(null, -15)}
        style={{
          position: 'absolute',
          right: '133px',
          bottom: 5,
          zIndex: 99,
          boxShadow: 'none',
          height: '35px',
          backgroundColor: 'transparent',
        }}
      >
        <FastRewind />
      </IconButton>
      <IconButton
        onClick={() => props.skipVideo(null, 15)}
        style={{
          position: 'absolute',
          right: '77px',
          bottom: 5,
          zIndex: 99,
          height: '35px',
          boxShadow: 'none',
          backgroundColor: 'transparent',
        }}
      >
        <FastForward />
      </IconButton>
    </>,
    parent,
  )
}

export default SkipVideoButtons
