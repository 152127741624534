import React, { Dispatch, SetStateAction, useContext, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { isEmail, ReactMultiEmail } from 'react-multi-email'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../../../components/_refactored/Button'
import CreateNewChatContactModal from '../components/CreateNewChatContactModal'
import AvailableEmailsModal from '../../../../../../../components/_refactored/SendByEmailModal/AvailableEmailsModal'
import { screensightContact } from '../../../../../../../API'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'
import useNewMessage from '../../common/hooks/useNewMessage'
import './styles.scss'

const NewMessage = () => {
  const auth = useContext(AuthContext)
  const { t } = useTranslation('chat')
  const state = useNewMessage()

  const renderAvailableEmailsModal = useMemo(
    () => state.searchPhrase?.length > 1 && state?.availableContacts?.length,
    [state?.searchPhrase, state?.availableContacts],
  )

  return (
    <Box className="new-message-wrapper">
      {renderAvailableEmailsModal ? (
        <AvailableEmailsModal
          availableContacts={state.availableContacts?.filter((contact) => contact.email !== auth.user?.email)}
          setAvailableContacts={state.setAvailableContacts as Dispatch<SetStateAction<screensightContact[] | null>>}
          setEmails={state.setEmails as Dispatch<SetStateAction<string[]>>}
          searchPhrase={state.searchPhrase?.trim()}
          loading={state.refetching}
          handleSelectContact={state.handleSelectContact}
          handleNavigationInsideTheList={state.handleNavigationInsideTheList}
          top="130px"
        />
      ) : null}
      <Box
        height="80px"
        width="100%"
        boxShadow="0px 0px 3px rgba(0, 0, 0, 0.1)"
        padding="0px 20px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" style={{ fontWeight: 600, fontSize: '15px', lineHeight: '17px' }}>
          {t('chatBody.newMessageTitle')}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          disabled={!state?.emails?.length || state.createContactModalVisible}
          onClick={() => state.checkCustomer(state?.emails?.[0] || '')}
          loading={state.loading}
        >
          {t('chatBody.startConversationBtn')}
        </Button>
      </Box>
      <Box
        height="60px"
        width="calc(100% - 40px)"
        borderBottom="1px solid rgba(188, 188, 195, 0.6)"
        padding="0px"
        display="flex"
        marginLeft="20px"
        alignItems="center"
        position="relative"
      >
        {state.createContactModalVisible && (
          <CreateNewChatContactModal
            email={state?.emails?.[0] || ''}
            setCreateContactModalVisible={state.setCreateContactModalVisible}
            partnerId={state.partnerId}
          />
        )}
        <Typography variant="body1" color="textSecondary" style={{ fontSize: '15px', paddingRight: '10px' }}>
          {t('chatBody.recipientInputPrefix')}
        </Typography>
        {/* @ts-ignore */}
        <ReactMultiEmail
          ref={state.inputRef}
          className="react-multi"
          emails={state.emails}
          onChange={(_emails: string[]) => {
            if (_emails?.length >= 1) {
              state.setEmails([_emails[0].toLowerCase()])
            } else {
              state.setEmails([])
            }
          }}
          style={{ border: 'none', fontSize: '15px' }}
          validateEmail={(email) => {
            // console.log('email', email.toLowerCase())
            return isEmail(email.toLowerCase()) // return boolean
          }}
          getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
            return (
              <div data-tag key={index}>
                {email.toLowerCase()}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            )
          }}
        />
      </Box>
    </Box>
  )
}

export default NewMessage
