import React from 'react'
import PartnerInfo from './PartnerInfo'
import { Box } from '@mui/material'
import SharedVideos from './SharedVideos'
import SharedFiles from './SharedFiles'
import { screensightCustomer } from '../../../../../../../API'
import useSharedMedia from '../../common/hooks/useSharedMedia'
import { PartnerSidebarCurrentView } from '../../common/types/PartnerSidebarCurrentView'
import PartnerInfoSkeleton from './PartnerInfoSkeleton'
import SharedVideosSkeleton from './SharedVideosSkeleton'
import SharedFilesSkeleton from './SharedFilesSkeleton'

interface ISharedMedia {
  partner: screensightCustomer | null | undefined
  currentView: PartnerSidebarCurrentView
}

const SharedMedia = (props: ISharedMedia) => {
  const state = useSharedMedia()

  return (
    <>
      {props.currentView === 'media' && (
        <>
          {state.panelDataLoaded ? (
            <PartnerInfo partner={props.partner} />
          ) : (
            <>
              <PartnerInfoSkeleton />
              <SharedVideosSkeleton items={5} />
              <SharedFilesSkeleton items={5} />
            </>
          )}
        </>
      )}
      {props.currentView === 'videos' && !state.panelDataLoaded && <SharedVideosSkeleton items={12} />}
      {props.currentView === 'files' && !state.panelDataLoaded && <SharedFilesSkeleton items={20} />}

      <Box display={state.panelDataLoaded ? 'block' : 'none'}>
        {props.partner?.id && (
          <Box margin={props.currentView !== 'media' ? '0' : '20px 0 13px 0'}>
            <SharedVideos
              partnerId={props.partner?.id}
              handleSharedVideosLoaded={state.handleSharedVideosLoaded}
              listMaxHeight={props.currentView === 'media' ? '332px' : 'calc(100vh - 70px)'}
              hidden={props.currentView === 'files'}
              currentView={props.currentView}
            />
          </Box>
        )}
        {props.partner?.id && (
          <Box margin={props.currentView !== 'media' ? '0' : '20px 0 13px 0'}>
            <SharedFiles
              partnerId={props.partner?.id}
              handleSharedFilesLoaded={state.handleSharedFilesLoaded}
              listMaxHeight={props.currentView === 'media' ? '295px' : 'calc(100vh - 70px)'}
              hidden={props.currentView === 'videos'}
              currentView={props.currentView}
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default SharedMedia
