import React, { useContext } from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { AuthContext } from '../../common/providers/AuthStatusProvider'
import ChannelsSidebar from './ui/components/ChannelsSidebar'
import ChatContent from './ui/components/ChatContent'
import PartnerSidebar from './ui/components/PartnerSidebar'
import useChatParticipants from './common/hooks/useChatParticipants'
import { useLocation } from 'react-router'
import UserInfoSidebar from './ui/components/UserInfoSidebar'
import PageTitle from '../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

const Chat = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(`(max-width:1024px)`)
  const auth = useContext(AuthContext)
  const state = useChatParticipants()
  const location = useLocation()
  const { t: g } = useTranslation('general')

  return (
    <Box display="flex" height="100vh" width="100vw">
      <PageTitle name={g('pageName.threads')} />
      {auth.isAuthenticated && !isMobile && <ChannelsSidebar />}
      <ChatContent {...state} />
      {location.pathname !== '/app/chat/new' && !isTablet && auth.user?.id !== process.env.REACT_APP_SUPPORT_ID && (
        <PartnerSidebar partner={state.participantData.partner} />
      )}
      {auth.user?.id === process.env.REACT_APP_SUPPORT_ID && !isTablet && location.pathname !== '/app/chat/new' && (
        <UserInfoSidebar partner={state.participantData.partner} />
      )}
    </Box>
  )
}

export default Chat
