import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { useMediaQuery, useTheme } from '@mui/material'

const useRow = (channelId: string, closeChannels?: any) => {
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { id: channelParamsId } = useParams() as { id: string }

  const handleRedirect = () => {
    if (isMobile) closeChannels()
    history.push(`/app/chat/${channelId}`)
  }

  return {
    handleRedirect,
    channelParamsId,
  }
}

export default useRow
