import * as React from 'react'
import { Box } from '@mui/material'

type Props = {
  color?: string
}

const MailIcon = (props: Props) => {
  return (
    <Box display="flex">
      <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.38462 0C0.62788 0 0 0.634856 0 1.4V12.6C0 13.3651 0.62788 14 1.38462 14H16.6154C17.3721 14 18 13.3651 18 12.6V1.4C18 0.634856 17.3721 0 16.6154 0H1.38462ZM3.25331 1.4H14.7467L9 5.69434L3.25331 1.4ZM2.07692 3.13223L9 8.30566L15.9231 3.13223V12.6H2.07692V3.13223Z"
          fill={props.color || '#7A7A86'}
        />
      </svg>
    </Box>
  )
}
export default MailIcon
