import React from 'react'
import { Box, Grow, IconButton, Typography } from '@mui/material'
import { Check, Close } from '@mui/icons-material'
import ConfirmCancelModal from './ConfirmCancelModal'
import ConfirmStartOverModal from './ConfirmStartOverModal'
import { Rnd } from 'react-rnd'
import MediaRecorderHandler from '../../../../../providers/RecorderStateProvider/MediaRecorderHandler'
import useRecordingInProgressModal from '../../../common/hooks/useRecordingInProgressModal'
import ScreenSightLogoSmall from '../../../../../icons/ScreenSightLogoSmall'
import RecordAnimation from '../../../../../icons/RecordAnimation'
import StartoverSmall from '../../../../../icons/StartoverSmall'

const RecordingInProgressModal = () => {
  const state = useRecordingInProgressModal()

  return (
    <>
      {state.confirmCancelModalVisible && <ConfirmCancelModal onCancel={state.closeConfirmCancelModal} />}
      {state.confirmStartOverModalVisible && <ConfirmStartOverModal onCancel={state.closeConfirmStartOverModal} />}
      <Box
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
        zIndex="99999!important"
        style={{ pointerEvents: 'none' }}
      >
        <Rnd
          bounds="parent"
          ref={state.rndRef}
          enableResizing={false}
          position={state.recordingModalPosition}
          onDragStop={(event, dragPosition) => {
            state.handleUpdatePosition(dragPosition.x, dragPosition.y)
          }}
          style={{ pointerEvents: 'auto' }}
        >
          <Box onMouseOver={state.onMouseOverHandler} display={'inline-flex'}>
            <Box
              bgcolor="rgba(20, 35, 61, 0.6)"
              style={{ backdropFilter: 'blur(20px);' }}
              height={56}
              width={80}
              display="flex"
              alignItems="center"
              zIndex={1}
              justifyContent="center"
              borderRadius="10px"
            >
              <ScreenSightLogoSmall />
            </Box>
            <Grow in={!state.collapsed}>
              <Box
                height={56}
                bgcolor="rgba(20, 35, 61, 0.6)"
                paddingLeft="8px"
                paddingRight="90px"
                display="flex"
                flexDirection="row"
                alignItems="center"
                position="absolute"
                right="0"
                borderRadius="10px"
                maxWidth={460}
              >
                <IconButton style={{ background: 'white', margin: '0 7px' }} onClick={state.showConfirmCancelModal}>
                  <Close style={{ color: '#7A7A86' }} />
                </IconButton>
                <RecordAnimation width={42} height={42} />
                <Typography style={{ color: 'white', fontSize: '16px', fontWeight: 500, width: '75px' }}>
                  {state.time}
                </Typography>
                <IconButton
                  style={{ background: 'white', marginLeft: '10px' }}
                  onClick={state.showConfirmStartOverModal}
                >
                  <StartoverSmall />
                </IconButton>
                <IconButton
                  style={{ background: '#2483F7', marginLeft: '10px' }}
                  onClick={() => MediaRecorderHandler.stopMediaRecorderHandler()}
                >
                  <Check style={{ color: 'white' }} />
                </IconButton>
              </Box>
            </Grow>
          </Box>
        </Rnd>
      </Box>
    </>
  )
}

export default RecordingInProgressModal
