import React from 'react'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import Button from '../../../../../../../components/_refactored/Button'
import useVerify from '../../../common/hooks/useVerify'
import { useTranslation } from 'react-i18next'
import CodeInput from '../components/CodeInput'
import CachedIcon from '@mui/icons-material/Cached'
import CreateIcon from '@mui/icons-material/Create'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'

const UserForm = () => {
  const state = useVerify()
  const isBelowLg = useMediaQuery(theme.breakpoints.down('lg'))
  const { t } = useTranslation('auth')

  return (
    <form onSubmit={state.onSubmit} style={{ width: '100%' }}>
      <CodeInput
        value={state.value}
        handleUpdateValue={state.handleUpdateValue}
        handleInputChangeForPinAnalytics={state.handleInputChangeForPinAnalytics}
      />
      <Box width="100%" position="relative" margin="20px 0" height="20px">
        <Box
          width="100%"
          bgcolor="text.secondary"
          height="1px"
          position="absolute"
          top="50%"
          left="0"
          zIndex={1}
          sx={{ opacity: '20%' }}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 2,
            background: '#FFF',
            padding: '0 10px',
            width: 'max-content',
          }}
        >
          {t('verify.userAside.notReceived')}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Button
            variant="outlined"
            fullWidth={isBelowLg}
            color="secondary"
            sx={{ borderRadius: '6px', fontWeight: 400, borderWidth: '1px !important', fontSize: '15px' }}
            startIcon={<CachedIcon />}
            onClick={state.resendCode}
            disabled={state.resending}
          >
            {t('verify.userAside.resendBtn')}
          </Button>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Button
            variant="outlined"
            fullWidth={isBelowLg}
            color="secondary"
            sx={{ borderRadius: '6px', fontWeight: 400, borderWidth: '1px !important', fontSize: '15px' }}
            startIcon={<CreateIcon />}
            onClick={() => state.history.push('/sign-up')}
          >
            {t('verify.userAside.tryDifferentEmail')}
          </Button>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        style={{ width: '100%', marginTop: 24 }}
        loading={state.loading}
      >
        {t('verify.userAside.createAccountBtn')}
      </Button>
    </form>
  )
}

export default UserForm
