import { Dispatch } from 'react'
import { combineLatest } from 'rxjs'

import { filter, startWith } from 'rxjs/operators'
import { Map } from 'immutable'

import { Recording } from './Recording'
import { UploadScheduler } from './UploadScheduler'

import { IRecording, QueueEvent, Schedulers } from './QueueHandler.types'
import { RecorderStateDispatch } from 'common/providers/RecorderStateProvider/useRecorderStateProvider'
import { SchedulerEvent } from './UploadScheduler.types'
import { RecordingChunkType } from './index'
import IndexedDB from 'common/services/IndexedDB'

export class QueueHandler {
  recordings = Map<string, Recording>()

  schedulers: Schedulers = {
    upload: new UploadScheduler('Chunk'),
    request: new UploadScheduler('Request'),
  }

  reporting = false
  recordingsToRestart: string[] = []

  constructor() {
    combineLatest([
      this.schedulers.upload.completedObservable().pipe(startWith({ type: 'SUCCESS' })),
      this.schedulers.request.completedObservable().pipe(startWith({ type: 'SUCCESS' })),
    ]).subscribe(([uploadEv, requestEv]) => {
      if (!this.reporting) return
      this.log('uploadEv', uploadEv, 'requestEv', requestEv)

      if (uploadEv.type === 'SUCCESS' && requestEv.type === 'SUCCESS') {
        this.dispatch({ action: 'END_UPLOAD' })

        this.recordingsToRestart = []

        this.log('To UI: Upload succeed.')
      } else {
        this.dispatch({ action: 'UPLOAD_ERROR' })

        this.recordingsToRestart = this.recordings.toArray().map(([index, item]) => index)

        this.log('To UI: Upload error!')
      }
    })

    combineLatest([
      this.schedulers.upload.observable().pipe(filter((ev: SchedulerEvent) => ev.type === 'READY')),
      this.schedulers.request.observable().pipe(filter((ev: SchedulerEvent) => ev.type === 'READY')),
    ]).subscribe(([uploadEv, requestEv]) => {
      if (this.reporting) {
        this.log('Disable reporting to UI.')

        this.reporting = false
      }
    })

    this.schedulers.upload.observable().subscribe((ev) => {
      if (ev.type !== 'PROGRESS' || !this.reporting) return
      this.dispatch({
        action: 'UPLOAD_PROGRESS',
        value: (ev.loaded / ev.total) * 100,
      })
    })
  }

  async restoreLastRecordings() {
    await Promise.all(this.recordingsToRestart.map((localId) => this.restoreRecording(localId)))
  }

  async enqueueRecording(record: IRecording) {
    const recording = new Recording(
      { localId: record.uuid },
      {
        entity: {
          title: record.title,
          owner: record.owner,
          enableSplashScreen: record.enableSplashScreen,
          ...(record?.channelId && { channelId: record.channelId }),
        },
      },
      this.schedulers,
    )
    this.recordings = this.recordings.set(record.uuid, recording)
    await recording.init()
  }

  async addChunkToRecording(uuid: string, type: RecordingChunkType, idx: number, data: Blob) {
    const recording = this.recordings.get(uuid)
    if (!recording) return

    await recording.addChunk(type, idx, data)
  }

  async endRecording(uuid: string) {
    const recording = this.recordings.get(uuid)
    if (!recording) return

    await recording.finishRecording()

    this.schedulers.upload.allowToCleanUp()
    this.schedulers.request.allowToCleanUp()

    this.recordings = this.recordings.remove(uuid)
  }

  async startOver(localId: string) {
    const recording = this.recordings.get(localId)

    if (!recording) return

    await recording.startOver()
    this.recordings = this.recordings.remove(localId)
  }

  async restoreRecording(localId: string) {
    const entity = await IndexedDB.recordingEntity.where('localId').equals(localId).first()
    const notifyComplete = await IndexedDB.recordingNotifyComplete.where('localId').equals(localId).first()

    if (!entity || !notifyComplete) return

    this.setReportingState(true)
    const recording = new Recording(
      entity,
      {
        entity,
        notifyComplete,
      },
      this.schedulers,
    )

    this.recordings = this.recordings.set(localId, recording)
    await recording.init()

    // Load all chunks
    const arr = await IndexedDB.recordingChunk.where('localId').equals(localId).toArray()

    await Promise.all(arr.map((chunk) => this.addChunkToRecording(localId, chunk.type, chunk.idx, chunk.chunk)))

    await this.endRecording(localId)
  }

  async getRecordingsToRestore() {
    return await IndexedDB.recordingEntity.toArray()
  }

  async deleteStoredRecording(localId: string) {
    await IndexedDB.recordingEntity.where('localId').equals(localId).delete()
    await IndexedDB.recordingChunk.where('localId').equals(localId).delete()
    await IndexedDB.recordingNotifyComplete.where('localId').equals(localId).delete()
  }

  async clearAllStoredRecordings() {
    await IndexedDB.recordingEntity.clear()
    await IndexedDB.recordingChunk.clear()
    await IndexedDB.recordingNotifyComplete.clear()
  }

  setReportingState(state: boolean) {
    this.log('Set reporting UI state to', state)

    this.reporting = state
  }

  dispatch: Dispatch<RecorderStateDispatch> = () => null

  setDispatch(dispatch: Dispatch<RecorderStateDispatch>) {
    this.dispatch = dispatch
  }

  log(...args: any[]) {
    console.log(new Date().toISOString(), `[QueueHandler]: `, ...args)
  }
}
