import React from 'react'
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import Button from '../../../../../../../components/_refactored/Button'
import Modal from '../../../../../../../components/_refactored/Modal'
import { useTranslation } from 'react-i18next'
import ProgressBar from '../../../../../../../components/_refactored/ProgressBar'
import { useEditThumbnailDispatch } from '../../../common/context/EditThumbnailContext'
import useVideoFrameThumbnailModal from '../../../common/hooks/useVideoFrameThumbnailModal'
import RefreshIcon from '../../../../../../../common/icons/RefreshIcon'

interface IProps {
  filePresignedUrl?: string | null
}

const VideoFrameThumbnailModal = (props: IProps) => {
  const { t } = useTranslation('library')
  const { t: g } = useTranslation('general')
  const editThumbnailDispatcher = useEditThumbnailDispatch()

  const theme = useTheme()
  const state = useVideoFrameThumbnailModal()

  return (
    <Modal open={true} resetPadding={true} boxStyle={{ width: '1100px', padding: '30px 35px' }}>
      <>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="30px"
          maxHeight="80vh"
        >
          <Typography variant="h6" style={{ fontWeight: 500, fontSize: '16px' }}>
            {t('videoItem.editThumbnailFramePhotoModal.selectFrame')}
          </Typography>
          <Box display="flex" alignItems="center">
            <Button
              style={{ color: theme.palette.secondary.main }}
              onClick={() => editThumbnailDispatcher({ type: 'setCurrentModalView', value: '' })}
            >
              {g('common.cancelBtn')}
            </Button>
            {state.arrayOfImages.length === 10 && (
              <Button variant="contained" color="primary" onClick={state.handleUpload} loading={state.uploading}>
                {g('common.saveBtn')}
              </Button>
            )}
          </Box>
        </Box>
        {props.filePresignedUrl && (
          <video
            ref={state.videoRef}
            crossOrigin="anonymous"
            src={props.filePresignedUrl}
            autoPlay
            muted
            controls
            onLoadedMetadata={state.handleLoadedMetadata}
            onCanPlay={state.handleCanPlay}
            style={{ zIndex: -2000, position: 'absolute', left: '10000px', top: '10000px' }}
          />
        )}

        <canvas id="canvas" style={{ display: 'none' }} />
        {state.arrayOfImages.length < 10 ? (
          <Box width="100%" display="flex" flexDirection="column" alignItems="center" marginBottom="20px">
            <Typography style={{ marginBottom: '16px', fontWeight: 500, fontSize: '15px' }}>
              {t('videoItem.editThumbnailFramePhotoModal.generatingFrames')}
            </Typography>
            <Box width="400px" maxWidth="100%" display="flex" justifyContent="center">
              <ProgressBar progress={state.arrayOfImages.length / 10} />
            </Box>
          </Box>
        ) : (
          <>
            <Box width="100%" height="100%" display="flex" justifyContent="center" marginBottom="15px">
              <img
                src={state.selectedImageObject?.image}
                alt="Video thumbnail"
                style={{
                  width: 'auto',
                  maxWidth: '100%',
                  height: 'auto',
                  maxHeight: 'calc(100vh - 400px)',
                  borderRadius: '10px',
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Box display="flex">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="800px"
                max-width="100%"
                margin="0 auto"
              >
                <Typography
                  variant="body1"
                  style={{ fontSize: '16px', flexGrow: 1 }}
                  color="textSecondary"
                  align="center"
                >
                  {t('videoItem.editThumbnailFramePhotoModal.tapFrame')}
                </Typography>
                <Tooltip title={t('videoItem.editThumbnail.refreshBtn')}>
                  <IconButton style={{ boxShadow: 'none' }} onClick={state.refreshThumbnails}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box width="100%" margin="40px auto 0 auto" maxWidth="100%" id="video-frame-thumbnail__axis-wrapper">
              <svg
                ref={state.d3Chart}
                style={{
                  height: '30px',
                  width: '100%',
                  marginRight: '10px',
                  marginLeft: '10px',
                  marginTop: '0px',
                  color: '#BCBCC3',
                  fontSize: '12px',
                  overflow: 'visible',
                }}
              />
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              {state.arrayOfImages.map((thumbnailImg, key) => {
                return (
                  <Box
                    width="10%"
                    key={key}
                    maxHeight="150px"
                    style={{ cursor: 'pointer' }}
                    onClick={() => state.setSelectedImageObject(state.arrayOfImages[key])}
                  >
                    <img
                      alt="Video frame selection"
                      src={thumbnailImg?.image}
                      style={{ width: '100%', height: '70px', objectFit: 'cover' }}
                    />
                  </Box>
                )
              })}
            </Box>
          </>
        )}
      </>
    </Modal>
  )
}

export default VideoFrameThumbnailModal
