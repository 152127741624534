import { useContext, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { dataURItoBlob } from '../../../../../../common/utils/convertBase64ToBlob'
import { useEditThumbnailContext } from '../context/EditThumbnailContext'
import { useTranslation } from 'react-i18next'
import ToastContext from '../../../../../../common/providers/ToastProvider/ToastContext'
import useUploadThumbnail from './useUploadThumbnail'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'

const useEditThumbnailCustomPhoto = () => {
  const { t } = useTranslation('library')
  const { open } = useContext(ToastContext)
  const editThumbnailContext = useEditThumbnailContext()
  const { id: videoAccessId } = useParams() as { id: string }
  const history = useHistory()
  const [image, setImage] = useState<string | ArrayBuffer | null>('')
  const [file, setFile] = useState<File | Blob | null>(null)
  const [uploading, setUploading] = useState(false)
  const cropperRef = useRef<HTMLImageElement>(null)

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/gif',
  })
  const isGif = acceptedFiles[0]?.type.includes('gif')
  const [uploadThumbnail] = useUploadThumbnail(editThumbnailContext.videoId, editThumbnailContext.thumbnailKey, isGif)

  useEffect(() => {
    if (!acceptedFiles?.length) return
    if (acceptedFiles[0].type === 'image/gif') {
      handleGifUpload()
    } else {
      handleImageUpload()
    }
  }, [acceptedFiles])

  const handleGifUpload = () => {
    const file = acceptedFiles[0]
    if (file.size > 10485760) {
      open({ type: 'ERROR', text: t('videoItem.editThumbnail.maxSizeErrorText') })
      return
    }
    setFile(file)
  }

  const handleImageUpload = () => {
    const reader = new FileReader()
    reader.onload = () => {
      if (dataURItoBlob(reader.result).size > 10485760) {
        open({ type: 'ERROR', text: t('videoItem.editThumbnail.maxSizeErrorText') })
        return
      }
      setImage(reader.result)
    }
    reader.readAsDataURL(acceptedFiles[0])
  }
  const handleImageCroppingSubmit = async () => {
    const imageElement: any = cropperRef?.current
    const cropper: Cropper = imageElement?.cropper
    if (typeof cropper !== 'undefined') {
      setImage(null)
      const blob = cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.5)
      const file = dataURItoBlob(blob)
      if (file.size > 10485760) {
        open({ type: 'ERROR', text: t('videoItem.editThumbnail.maxSizeErrorText') })
        return
      }
      setFile(file)
    }
  }

  const saveImage = async () => {
    if (!file) return
    try {
      setUploading(true)
      await uploadThumbnail(file)
      history.push(`/video/${videoAccessId}`)
    } catch (e: any) {
      console.error('Error during uploading video thumbnail')
    } finally {
      setUploading(false)
    }
  }

  return {
    image,
    setImage,
    dropzone: {
      getRootProps,
      getInputProps,
      acceptedFiles,
    },
    cropperRef,
    file,
    handleImageCroppingSubmit,
    setFile,
    saveImage,
    uploading,
    isGif,
  }
}

export default useEditThumbnailCustomPhoto
