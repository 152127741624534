import { useState } from 'react'
import moment from 'moment'

const useReadReceipt = (seenAt: string | null | undefined) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    if (!seenAt) return
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const timestamp =
    new Date(seenAt as string).getDay() === new Date().getDay()
      ? moment(seenAt).calendar()
      : moment(seenAt).format('MMM DD, YYYY, h:mm A')

  return { open, handleOpen, handleClose, timestamp }
}

export default useReadReceipt
