import { useState } from 'react'

const useVideoListItem = () => {
  const [sendEmailModalVisible, setSendEmailModalVisible] = useState(false)
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false)

  const showSendByEmailModal = () => {
    setSendEmailModalVisible(true)
  }

  const hideSendByEmailModal = () => {
    setSendEmailModalVisible(false)
  }

  const togglePrivacyModalVisibility = () => {
    setPrivacyModalVisible((prevState) => !prevState)
  }

  return {
    sendEmailModalVisible,
    showSendByEmailModal,
    hideSendByEmailModal,
    togglePrivacyModalVisibility,
    privacyModalVisible,
  }
}
export default useVideoListItem
