import React, { Dispatch, SetStateAction } from 'react'
import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Button from '../../../../components/_refactored/Button'
import { useHistory } from 'react-router-dom'
import PencilIcon from '../../../../common/icons/PencilIcon'
import BasketIcon from '../../../../common/icons/BasketIcon'
import { useParams } from 'react-router'
import ContactAvatar from '../../../../containers/Contacts/ContactAvatar'
import { GetContactQuery } from '../../../../API'
import FullScreenLoader from '../../../../components/_refactored/Loaders/FullScreenLoader'
import DeleteContactModal from '../../../../containers/Contacts/DeleteContactModal'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../../../components/_refactored/PageTitle/PageTitle'
import { getUsername } from '../../../../common/utils/getUsername'

interface ISingleContactView {
  loading: boolean
  contact?: GetContactQuery
  contactToDelete: string | null
  setContactToDelete: Dispatch<SetStateAction<string | null>>
}

const SingleContactView = (props: ISingleContactView) => {
  const history = useHistory()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const { t } = useTranslation('contacts')
  const { t: g } = useTranslation('general')
  const { id: contactId } = useParams() as { id: string }

  return (
    <Box marginTop="80px" maxWidth="100vw">
      <PageTitle
        name={getUsername(
          props.contact?.getContact?.firstName,
          props.contact?.getContact?.lastName,
          props.contact?.getContact?.email,
        )}
      />
      {props.loading && <FullScreenLoader withoutSidebar />}
      {props.contactToDelete ? (
        <DeleteContactModal type="SINGLE" id={props.contactToDelete} onCancel={() => props.setContactToDelete(null)} />
      ) : null}
      <Box paddingLeft="40px" marginBottom="50px">
        <Button
          style={{ maxWidth: '250px' }}
          startIcon={<ArrowBackIosIcon style={{ color: theme.palette.text.secondary }} />}
          onClick={() => history.push(`/app/contacts`)}
        >
          <Typography variant="body1" color="textSecondary" style={{ fontWeight: 500, fontSize: '20px' }} noWrap>
            {t('singleContact.backBtn')}
          </Typography>
        </Button>
      </Box>
      <Grid container columnSpacing={6} sx={{ padding: '0 30px' }}>
        <Grid
          item
          xs={12}
          md={4}
          style={{ borderRight: !isTablet ? '1px solid rgba(188, 188, 195, 0.3)' : 'none' }}
          paddingTop={0}
          marginBottom={'30px'}
        >
          <ContactAvatar
            width={190}
            height={190}
            image={props.contact?.getContact?.contactCustomerMeta?.items?.[0]?.profilePhoto?.key}
            name={
              props.contact?.getContact?.firstName && props.contact?.getContact?.lastName
                ? props.contact?.getContact?.firstName + ' ' + props.contact?.getContact?.lastName
                : props.contact?.getContact?.firstName
                ? props.contact?.getContact?.firstName
                : props.contact?.getContact?.lastName
                ? props.contact?.getContact.lastName
                : props?.contact?.getContact?.email || ''
            }
            fontSize="32px"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="40px">
            <Typography variant="h6" style={{ fontWeight: 500, fontSize: '20px' }}>
              {t('singleContact.personalInfoTitle')}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="80px">
              <IconButton style={{ boxShadow: 'none' }} onClick={() => history.push(`/app/contacts/${contactId}/edit`)}>
                <PencilIcon />
              </IconButton>
              <IconButton
                style={{ boxShadow: 'none', marginLeft: '8px' }}
                onClick={() => props.setContactToDelete(contactId)}
              >
                <BasketIcon />
              </IconButton>
            </Box>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                {g('common.firstName')}
              </Typography>
              <Typography variant="body1" style={{ fontSize: '16px' }}>
                {props.contact?.getContact?.firstName || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                {g('common.lastName')}
              </Typography>
              <Typography variant="body1" style={{ fontSize: '16px' }}>
                {props.contact?.getContact?.lastName || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                {g('common.email')}
              </Typography>
              <Typography variant="body1" style={{ fontSize: '16px' }}>
                {props.contact?.getContact?.email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                {g('common.phone')}
              </Typography>
              <Typography variant="body1" style={{ fontSize: '16px' }}>
                {props.contact?.getContact?.mobilePhone || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SingleContactView
