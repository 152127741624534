import * as React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import Linkify from 'react-linkify'
import { sanitazeHtml } from '../../../../../../../../common/utils/sanitazeHtml'

type Props = {
  owner: boolean
  content: string
}
export const SanitazedPostContent = (props: Props) => {
  const theme = useTheme()

  return (
    <Box display="flex" alignItems="center" marginTop="15px">
      <Typography
        variant="body1"
        style={{
          fontSize: '15px',
          lineHeight: '20px',
          color: props.owner ? theme.palette.background.default : theme.palette.text.primary,
        }}
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {sanitazeHtml(props.content)}
        </Linkify>
      </Typography>
    </Box>
  )
}

export default SanitazedPostContent
