import * as React from 'react'
import { Box, Typography } from '@mui/material'
import Button from '../Button'
import { useTranslation } from 'react-i18next'

interface ISignInHeaderProps {
  handleSignInPush: () => void
}
export const UnauthorizedModalHeader = (props: ISignInHeaderProps) => {
  const { t: g } = useTranslation('general')

  return (
    <Box
      bgcolor="background.paper"
      width="100%"
      padding="15px 0"
      display="flex"
      justifyContent="center"
      borderRadius="10px"
      alignItems="center"
      position="relative"
    >
      <Typography variant="h6" style={{ marginRight: '20px' }}>
        {g('common.unauthorizedModalHeader')}
      </Typography>
      <Button variant="contained" color="primary" onClick={props.handleSignInPush}>
        {g('common.signInBtn')}
      </Button>
    </Box>
  )
}

export default UnauthorizedModalHeader
