import { useContext } from 'react'
import { AuthContext } from '../providers/AuthStatusProvider'
import { useLazyQuery } from '@apollo/client'
import gql from 'graphql-tag'
import CognitoApolloClient from './CognitoApolloClient'
import IamApolloClient from './IamApolloClient'
import { QueryHookOptions } from '@apollo/client/react/types/types'

const useApolloLazyQuery = <T, V>(queryName: string, queryHookOptions?: QueryHookOptions<T, V>) => {
  const auth = useContext(AuthContext)
  return useLazyQuery<T, V>(gql(queryName), {
    client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    ...queryHookOptions,
  })
}

export default useApolloLazyQuery
