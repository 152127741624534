import React from 'react'
import { Box } from '@mui/material'
import '../../common/style/Layout.scss'

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      width="250px!important"
      height="100vh"
      maxHeight="100vh"
      bgcolor="#F8F9FB"
      boxShadow="0px 0px 3px rgba(0, 0, 0, 0.1)"
      padding="25px 0"
      zIndex={2}
      position="relative"
      sx={{
        overflow: 'hidden',
        '&:hover': {
          overflowY: 'auto',
        },
      }}
      id="chat__channels-scrollable-box"
    >
      {children}
    </Box>
  )
}

export default Layout
