import React, { useEffect, useState } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import Logo from '../../../../common/icons/Logo'
import Button from '../../../../components/_refactored/Button'
import CloudFrontImage from '../../../../components/_refactored/CloudFrontImage'
import { useTranslation } from 'react-i18next'

interface IUnauthorizedHeader {
  brandingLogoKey?: string
  autoplayView?: boolean
  isVideoHorizontal?: boolean
}

const UnauthorizedHeader = (props: IUnauthorizedHeader) => {
  const theme = useTheme()
  const { t } = useTranslation('library')
  const { t: g } = useTranslation('general')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [headerVisible, setHeaderVisible] = useState(false)
  const handleHeaderVisibility = () => {
    if (!headerVisible) {
      setHeaderVisible(true)
      setTimeout(() => {
        setHeaderVisible(false)
      }, 4000)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleHeaderVisibility)
    return () => {
      window.removeEventListener('scroll', handleHeaderVisibility)
    }
  }, [])

  return (
    <Box
      height={isMobile ? '70px' : '60px'}
      width="100%"
      padding={props.autoplayView ? '15px 0' : '20px 17px'}
      position="fixed"
      left="0"
      top={(headerVisible && props.autoplayView) || !props.autoplayView || props.isVideoHorizontal ? '0px' : '-70px'}
      bgcolor={props.brandingLogoKey ? 'white' : 'background.paper'}
      zIndex={999}
      display={'flex'}
      sx={{ boxShadow: ' 0px 1px 4px -1px rgba(0,0,0,0.2)', transition: 'top 0.3s' }}
      justifyContent="center"
    >
      {!isMobile &&
        (props.brandingLogoKey ? (
          <Box
            display="flex"
            maxWidth="80px"
            maxHeight="54px"
            position="absolute"
            left="50%"
            style={{ transform: 'translate(-50%, -50%)' }}
            top="50%"
            justifyContent="center"
            alignItems="center"
          >
            <CloudFrontImage
              image={`public/${props.brandingLogoKey}` || ''}
              autoScale
              imgStyle={{
                maxWidth: '150px',
                height: '54px',
                objectPosition: 'center',
                objectFit: 'contain',
                borderRadius: '4px',
              }}
            />
          </Box>
        ) : (
          <Box position="absolute" left="20px" top="18px">
            <Link to="/app">
              <Logo />
            </Link>
          </Box>
        ))}
      {!props.brandingLogoKey && (
        <Box display="flex" alignItems="center" position={'relative'} width={'100%'} justifyContent={'center'}>
          <Typography
            variant="h6"
            align="center"
            style={{
              paddingRight: '5px',
              width: isMobile ? '53%' : '',
              fontSize: isMobile ? '17px' : '18px',
            }}
          >
            {t('videoItem.unauthorizedHeader')}
          </Typography>
          <Link to="/sign-up">
            <Button
              variant="contained"
              color="primary"
              style={{
                flexGrow: 1,
                marginLeft: isMobile ? '0' : '12px',
                fontSize: isMobile ? '16px' : '18px',
                width: 'fit-content',
              }}
            >
              {g('common.signUpBtn')}
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  )
}

export default UnauthorizedHeader
