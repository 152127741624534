import styled from 'styled-components'

interface IIConSelectButton {
  resetMarginLeft?: boolean
}

export const IconSelectButton = styled.button<IIConSelectButton>`
  height: 46px;
  border-radius: 100px;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none !important;
  border: none;
  cursor: pointer;
  color: #fff;

  &:hover {
    filter: grayscale(0.5);
  }

  &:disabled {
    cursor: default;

    background-color: #eef0f6;
    color: #7a7a86;
  }
  background: transparent;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: space-between;
`

export const Label = styled.span<{ color?: string }>`
  font-size: 14px;
  font-weight: 500;
  color: ${({ color = '#7A7A86' }) => color || '#7A7A86'};
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 0 0;
`
