import React from 'react'

const PluginArrow = () => {
  return (
    <svg width="36" height="58" viewBox="0 0 76 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64.7784 97.0087C64.0957 97.1138 63.4545 96.8573 62.8942 96.4268L53.7453 88.4798C52.6917 87.5654 52.5773 85.9036 53.4907 84.8479C54.4041 83.7922 56.0634 83.6768 57.117 84.5911L61.8035 87.5657C60.217 71.5832 50.9591 51.4672 41.358 39.2574C30.4036 25.3266 12.7305 9.8801 1.85817 4.42319C0.49485 4.08401 0.040615 2.82263 0.196342 2.19534C0.33532 1.63553 1.01012 0.691146 2.37344 1.03032C19.4913 9.67093 33.5278 21.1206 45.1411 35.7612C55.4758 48.79 65.224 69.9434 66.9154 87.1125L70.5784 83.6277C71.4919 82.5721 73.1511 82.4566 74.2047 83.371C74.3113 83.5053 74.4849 83.5862 74.5914 83.7205C75.3372 84.6608 75.359 86.0677 74.5264 86.9495L66.5875 96.1166C66.1042 96.6109 65.461 96.9037 64.7784 97.0087Z"
        fill="#F73737"
      />
    </svg>
  )
}

export default PluginArrow
