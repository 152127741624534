import React, { FC } from 'react'
import { Login } from './common/config'
import PatentNote from '../../../../common/providers/ClientAuthProvider/PatentNote'
import PageTitle from '../../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

const LoginPage: FC = () => {
  const { t: g } = useTranslation('general')

  return (
    <Login.Layout>
      <PageTitle name={g('pageName.signIn')} />
      <Login.Header />
      <Login.Content />
      <PatentNote styles={{ padding: '20px 12px' }} />
    </Login.Layout>
  )
}

export default LoginPage
