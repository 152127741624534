import React from 'react'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import useSharedFiles from '../../common/hooks/useSharedFiles'
import FileBox from '../components/FileBox'
import '../../common/styles/FileBox.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import { PartnerSidebarCurrentView } from '../../common/types/PartnerSidebarCurrentView'
import EmptyMediaList from '../components/EmptyMediaList'

interface ISharedFilesProps {
  partnerId?: string | null
  handleSharedFilesLoaded: () => void
  listMaxHeight?: string
  hidden?: boolean
  currentView: PartnerSidebarCurrentView
}
const SharedFiles = (props: ISharedFilesProps) => {
  const { t } = useTranslation('chat')

  const state = useSharedFiles({ partnerId: props.partnerId, handleSharedFilesLoaded: props.handleSharedFilesLoaded })

  if (props?.hidden || (!state?.data?.length && props.currentView !== 'files')) return <></>

  return (
    <Box borderBottom={props.currentView === 'media' ? '1px solid rgba(122, 122, 134, 0.3)' : 'none'}>
      {props.currentView === 'media' && (
        <Box maxWidth="100%" width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '12px' }}>
            {t('partnerSidebar.files')}
          </Typography>
          <IconButton onClick={state.toggleFilesVisible} sx={{ boxShadow: 'none', background: 'transparent' }}>
            {state.filesVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
      )}
      {state.data?.length > 0 && (
        <Box
          maxHeight={props?.listMaxHeight || '100%'}
          sx={{ overflowY: 'scroll', display: state.filesVisible ? 'block' : 'none' }}
          className="file-box"
          id="files-scrollable-box"
        >
          <InfiniteScroll
            style={{ overflowX: 'hidden' }}
            dataLength={state.data?.length || 0}
            next={state.loadNextFiles}
            hasMore={state.hasMore}
            scrollableTarget="files-scrollable-box"
            loader={
              <Box width="100%" display="flex" justifyContent="center">
                <CircularProgress style={{ width: '24px', height: '24px' }} />
              </Box>
            }
          >
            {state.data?.map((item, key) => {
              return (
                <FileBox
                  key={item?.id || key}
                  title={item?.file?.fileName || ''}
                  // @ts-ignore
                  type={item?.file?.mimeType?.split('/')?.[1] || 'zip'}
                  image={`${item?.file?.fileObject?.accessLevel}/${item?.file?.fileObject?.identityId}/${item?.file?.fileObject?.key}`}
                  file={item?.file}
                />
              )
            })}
          </InfiniteScroll>
        </Box>
      )}
      {!state.filesListLoading && state.data?.length === 0 && (
        <EmptyMediaList text={t('partnerSidebar.noFilesShared')} />
      )}
    </Box>
  )
}

export default SharedFiles
