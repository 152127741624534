import React from 'react'
import { TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { UseFormMethods } from 'react-hook-form/dist/types/form'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

interface INewContactTextField {
  form: UseFormMethods<any>
  fieldName: string
  rules?: any
  disabled?: boolean
  style?: React.CSSProperties
  InputProps?: any
  topLabel?: string
  type?: string
  marginBottom?: string
  multiline?: number
  ['data-testid']?: string
}

const Wrapper = styled.div`
  & textarea {
    padding: 10.5px 14px;
  }
`

const FormTextField = (props: INewContactTextField) => {
  const { t: g } = useTranslation('general')
  return (
    <Wrapper data-testid={props?.['data-testid']}>
      {props?.topLabel && (
        <Typography variant="body1" style={{ fontWeight: 500, fontSize: '14px', marginBottom: '6px' }}>
          {props.topLabel}
        </Typography>
      )}
      <Controller
        name={props.fieldName}
        control={props.form.control}
        defaultValue=""
        rules={
          props?.rules || {
            required: g('common.requiredField'),
            validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
          }
        }
        render={({ value, onChange, onBlur }) => (
          <TextField
            type={props?.type || 'text'}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            multiline={!!props.multiline}
            error={!!props.form.errors[props.fieldName]}
            helperText={props.form.errors[props.fieldName]?.message}
            disabled={props.disabled}
            variant="outlined"
            rows={props?.multiline}
            style={props.style || { width: '100%', marginBottom: props?.marginBottom || '30px' }}
            InputProps={{
              style: props.InputProps,
            }}
          />
        )}
      />
    </Wrapper>
  )
}

export default FormTextField
