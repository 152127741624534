import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { UseFormMethods } from 'react-hook-form/dist/types/form'
import { Box } from '@mui/material'
import Button from '../../../Button'
import { IViewerModalForm } from '../../common/hooks/useViewerInfoModal.hook'
import NameInput from '../../../NameInput/NameInput'

type IViewerInfoFormProps = {
  form: UseFormMethods<IViewerModalForm>
  onSubmit: () => void
  loading: boolean
}
const ViewerInfoForm = (props: IViewerInfoFormProps) => {
  const { t: g } = useTranslation('general')
  const { t } = useTranslation('library')

  return (
    <form onSubmit={props.onSubmit}>
      <NameInput
        form={props.form}
        loading={props.loading}
        inputLabel={t('videoItem.viewerInfoModal.inputPlaceholder')}
      />
      <Box display="flex" justifyContent="center" marginTop="24px">
        <Button variant="contained" color="primary" type="submit" loading={props.loading}>
          {g('common.watchVideoBtn')}
        </Button>
      </Box>
    </form>
  )
}

export default ViewerInfoForm
