import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Tooltip, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ICloseBtnProps {
  sending: boolean
  hideSendByEmailModal: () => void
}
export const CloseBtn = (props: ICloseBtnProps) => {
  const theme = useTheme()
  const { t } = useTranslation('library')

  return (
    <Tooltip title={t('videoItem.sendViaEmailModal.closeIconBtn')}>
      <IconButton
        aria-label="close"
        aria-controls="long-menu"
        style={{
          width: '30px',
          height: '30px',
          boxShadow: 'none',
          color: theme.palette.secondary.main,
          marginLeft: '16px',
        }}
        onClick={props.sending ? undefined : props.hideSendByEmailModal}
      >
        <CloseIcon />
      </IconButton>
    </Tooltip>
  )
}

export default CloseBtn
