import useAmplifySubscription from '../../../../../clients/useAmplifySubscription'
import { OnPostReactionSubscriptionVariables } from '../../../../../../API'
import { useLocation, useParams } from 'react-router'
import { useContext } from 'react'
import { AuthContext } from '../../../../../providers/AuthStatusProvider'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'
import { onPostReaction, OnPostReactionSubscription } from './onPostReaction.subscription'

const useOnPostReactionSubscription = () => {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = location.pathname.includes('/app/chat')
    ? channelParamsId
    : location.pathname === '/app/support'
    ? auth.user?.supportChannelId
    : null

  const { data: onPostReactionSubscriptionData } = useAmplifySubscription<
    OnPostReactionSubscription,
    OnPostReactionSubscriptionVariables
  >(
    onPostReaction,
    {
      variables: {
        channelId: channelId || '',
      },
      skip: !auth.isAuthenticated || !channelId || channelId === 'new',
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    },
    'OnPostReactionSubscription',
  )

  return { data: onPostReactionSubscriptionData }
}

export default useOnPostReactionSubscription
