import { AccessType, VideoStatus } from '../../../API'

export const listCustomerRecentChannels = /* GraphQL */ `
  query ListCustomerRecentChannels(
    $customerId: ID!
    $latestPostAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerRecentChannels(
      customerId: $customerId
      latestPostAt: $latestPostAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        channelType
        name
        categoryPosition
        readHorizon
        activity {
          new
          latestAt
          latestPost {
            customerId
          }
        }
        chatPartnerId
        chatPartnerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
          }
          brandLogo {
            key
            bucket
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          createdAt
          updatedAt
        }
        latestPostAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export type ListCustomerRecentChannelsQuery = {
  listCustomerRecentChannels?: {
    __typename: 'ModelscreensightChannelConnection'
    items: Array<{
      __typename: 'screensightChannel'
      id: string
      customerId: string
      channelType?: string | null
      name?: string | null
      categoryPosition?: number | null
      readHorizon?: string | null
      activity?: {
        __typename: 'ChannelActivity'
        new?: boolean | null
        latestAt?: string | null
        latestPost?: {
          __typename: 'LatestPost'
          customerId?: string | null
        } | null
      } | null
      chatPartnerId?: string | null
      chatPartnerMeta?: {
        __typename: 'screensightCustomer'
        id: string
        email?: string | null
        firstName?: string | null
        lastName?: string | null
        profilePhoto?: {
          __typename: 'S3Object'
          key: string
          bucket: string
        } | null
        brandLogo?: {
          __typename: 'S3Object'
          key: string
          bucket: string
        } | null
        calendlyUrl?: string | null
        lastSeen?: string | null
        settings?: {
          __typename: 'CustomerSettings'
          notifyEmailWatched: boolean
          notifyEmailComment: boolean
          timezone?: string | null
          pushNotifications?: {
            __typename: 'PushNotificationSettings'
            video?: boolean | null
            comment?: boolean | null
            reply?: boolean | null
            text?: boolean | null
          } | null
          lastChannel?: string | null
          enableSplashScreen?: boolean | null
          termsAndConditions?: {
            __typename: 'TermsAndConditions'
            version: string
            accepted: boolean
            acceptedAt?: string | null
          } | null
          channelRecentActivityDays?: number | null
        } | null
        createdAt: string
        updatedAt: string
      } | null
      latestPostAt?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}
