import React, { useContext } from 'react'
import RecorderStateContext from '../../../../../providers/RecorderStateProvider/RecorderStateContext'
import ProgressBar from '../../../../../../components/_refactored/ProgressBar'
import { Box, Typography } from '@mui/material'
import Modal from '../../../../../../components/_refactored/Modal'
import { useTranslation } from 'react-i18next'

const RecordingUploadModal = () => {
  const { state: recorderState } = useContext(RecorderStateContext)
  const { t: g } = useTranslation('general')

  return (
    <Modal open={true}>
      <>
        <Typography
          style={{
            fontWeight: 600,
            fontSize: 17,
            color: '#171717',
            flex: 1,
            textAlign: 'center',
            marginBottom: 10,
          }}
        >
          {g('recordControl.uploadingModal.title')}
        </Typography>
        <Typography align="center" color="textSecondary" variant="body1" style={{ fontSize: '15px' }}>
          {g('recordControl.uploadingModal.subtitle')}
        </Typography>
        <Box marginTop="30px">
          <ProgressBar progress={recorderState.uploadProgress / 100} />
        </Box>
      </>
    </Modal>
  )
}

export default RecordingUploadModal
