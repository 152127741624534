import * as React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'

interface ITextareaInputProps {
  note: string
  handleNote: any
  videoId: string | undefined
  quillRef: any
  quillModules: any
  isError: any
}
const QuillEmailNote = (props: ITextareaInputProps) => {
  const { t } = useTranslation('library')

  return (
    <>
      <Box className="email-quill-wrapper">
        <ReactQuill
          key={props.videoId}
          ref={props.quillRef}
          onChange={props.handleNote}
          placeholder={t('videoItem.sendViaEmailModal.textareaPlaceholder')}
          modules={props.quillModules}
        />
        <Typography
          color={props.isError ? 'error' : 'textSecondary'}
          align="right"
          variant="body2"
        >{`${props.quillRef?.current?.editor?.getLength()} / 1000`}</Typography>
        {props.isError && (
          <Typography variant="body2" color="error" align="center" paddingTop="5px">
            {t('videoItem.sendViaEmailModal.maxTextareaInputLength')}
          </Typography>
        )}
      </Box>
    </>
  )
}

export default QuillEmailNote
