import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import { useChatDispatch } from '../../contexts/ChatContext'
import { useMutation } from '@apollo/client'
import { CreatePostMutation, CreatePostMutationVariables } from '../../../../../../../../API'
import gql from 'graphql-tag'
import { createPost } from '../../../../../../../../common/api/graphql/mutations'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import { useParams } from 'react-router'
import useUpdateChannelsCache from './useUpdateChannelsCache'
import moment from 'moment/moment'

const useSendReplyToTextPost = () => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const chatDispatcher = useChatDispatch()
  const [updateChannelLatestAt] = useUpdateChannelsCache()
  const customerIdToUse = auth.isAuthenticated
    ? auth.user?.id
    : sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId') || ''

  const [createPostMutation] = useMutation<CreatePostMutation, CreatePostMutationVariables>(gql(createPost), {
    client: !auth.user?.pending && auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    update(cache, { data }) {
      cache.modify({
        fields: {
          listPostsByChannel(existingItems = {}) {
            const newReplyToTextPostRef = cache.writeFragment({
              data: data?.createPost,
              fragment: gql`
                fragment newReplyToTextPost on ScreensightPost {
                  id
                  channelId
                  accessId
                  customerId
                  postType
                  video
                  comment {
                    id
                    customerId
                    videoId
                    channelId
                    parentId
                    content
                    richContent
                    videoTimestamp
                    createdAt
                    updatedAt
                  }
                  emailMessage
                  text
                  engagement
                  referencePostId
                  referencePost {
                    id
                    channelId
                    accessId
                    customerId
                    postType
                    comment
                    emailMessage
                    text {
                      content
                      richContent
                      customerId
                    }
                    readHorizon
                    createdAt
                    updatedAt
                    file
                  }
                  reactions
                  file
                  readHorizon
                  createdAt
                  updatedAt
                }
              `,
            })
            return {
              ...existingItems,
              items: [newReplyToTextPostRef, ...existingItems.items],
            }
          },
        },
      })
    },
  })
  const sendReplyToTextPost = async (
    content: string,
    richContent: string,
    parentId: string,
    title: string,
    postType: string,
    referencePostAuthorId: string,
    engagement: any,
  ) => {
    const tempDate = moment(new Date()).add(1, 's').toISOString()
    chatDispatcher({ type: 'setResponseBoxData', value: undefined })
    const res = await createPostMutation({
      variables: {
        input: {
          comment: {
            parentId,
            customerId: customerIdToUse,
            channelId,
            content,
            richContent,
            videoId: '',
          },
        },
      },
      optimisticResponse: {
        createPost: {
          __typename: 'screensightPost',
          id: 'temp-id-id',
          channelId,
          accessId: null,
          customerId: customerIdToUse,
          postType: 'reply',
          video: null,
          comment: {
            __typename: 'screensightVideoComment',
            id: 'temp-comment-id',
            customerId: customerIdToUse,
            videoId: '',
            channelId,
            parentId: parentId,
            content: content,
            richContent: richContent,
            videoTimestamp: null,
            createdAt: tempDate,
            updatedAt: tempDate,
          },
          emailMessage: null,
          text: null,
          engagement: engagement,
          referencePostId: null,
          referencePost: {
            __typename: 'screensightPost',
            id: 'temp-reference-id',
            channelId,
            accessId: null,
            customerId: referencePostAuthorId,
            postType: postType || 'text',
            video: null,
            comment: null,
            emailMessage: null,
            text: {
              __typename: 'TextPost',
              content: title,
              richContent: null,
              customerId: customerIdToUse,
            },
            file: null,
            readHorizon: null,
            createdAt: tempDate,
            updatedAt: tempDate,
          },
          reactions: null,
          file: null,
          readHorizon: null,
          createdAt: tempDate,
          updatedAt: tempDate,
        },
      },
    })
    if (res.data?.createPost?.channelId && res.data.createPost?.createdAt) {
      updateChannelLatestAt(res.data?.createPost?.channelId, res.data.createPost?.createdAt)
    }
  }

  return [sendReplyToTextPost]
}

export default useSendReplyToTextPost
