import * as React from 'react'
import { Box } from '@mui/material'

type Props = { color?: string }
export const Checkmark = (props: Props) => {
  return (
    <Box display="flex">
      <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.4094 0.500455L14.4092 0.50046C14.1365 0.508524 13.8769 0.621092 13.685 0.815595L5.48371 8.95525L2.31837 5.81369C2.2214 5.71456 2.10592 5.63552 1.9786 5.58091C1.84948 5.52552 1.71066 5.49631 1.57025 5.49489C1.42983 5.49348 1.29046 5.51987 1.16025 5.57263C1.03004 5.62539 0.911498 5.70351 0.811658 5.8026C0.711812 5.9017 0.632684 6.01978 0.579102 6.15004C0.525517 6.2803 0.498611 6.42 0.500055 6.56092C0.5015 6.70183 0.531264 6.84095 0.5875 6.97008C0.642947 7.09741 0.722977 7.21239 0.82283 7.30861L4.73595 11.1923L4.73602 11.1924C4.93499 11.3898 5.204 11.5 5.48371 11.5C5.76342 11.5 6.03244 11.3898 6.23141 11.1924L6.23148 11.1923L15.1806 2.31048C15.3323 2.16294 15.4365 1.97309 15.4788 1.76528C15.5215 1.55609 15.4994 1.33905 15.4157 1.14276C15.332 0.946508 15.1908 0.780561 15.0112 0.666195C14.8317 0.551857 14.6219 0.494235 14.4094 0.500455Z"
          fill={props.color || '#2483F7'}
          stroke={props.color || '#2483F7'}
        />
      </svg>
    </Box>
  )
}
export default Checkmark
