import React from 'react'
import { Box } from '@mui/material'
import { IAuthLayout } from '../../../../common/types/Auth'

const Layout = (props: IAuthLayout) => {
  return (
    <Box width="100%" minHeight="100vh" display="flex" position="relative">
      {props.children}
    </Box>
  )
}

export default Layout
