import React from 'react'
import { Typography, TypographyProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    fontWeight: 600,
    fontSize: 18,
    color: '#171717',
    textAlign: 'center',
  },
})

const RecordingCounter = (props: TypographyProps) => {
  const { root } = useStyles()
  const { className = '' } = props

  return <Typography {...props} className={`${root} ${className}`} />
}

export default RecordingCounter
