import * as React from 'react'
import Layout from './ui/layouts/Layout'
import Header from './ui/partials/Header'
import { Drawer } from '@mui/material'
import Content from './ui/partials/Content'

interface IChannelsDrawer {
  channelsVisible: boolean
  openMenu: () => void
  closeChannels: () => void
}

const ChannelsDrawer = (props: IChannelsDrawer) => {
  //Todo: make reusable component
  return (
    <Drawer anchor={'left'} open={props.channelsVisible} onClose={props.closeChannels}>
      <Layout>
        <Header openMenu={props.openMenu} />
        <Content closeChannels={props.closeChannels} />
      </Layout>
    </Drawer>
  )
}

export default ChannelsDrawer
