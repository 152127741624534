import { Box, Typography } from '@mui/material'
import React from 'react'
import Modal from '../../../../../../components/_refactored/Modal'
import Button from '../../../../../../components/_refactored/Button'
import MediaRecorderHandler from '../../../../../providers/RecorderStateProvider/MediaRecorderHandler'
import { useTranslation } from 'react-i18next'

interface IProps {
  onCancel: () => void
}

const ConfirmStartOverModal = (props: IProps) => {
  const { t: g } = useTranslation('general')
  return (
    <Modal open={true} style={{ zIndex: 100000 }} boxStyle={{ width: '600px' }}>
      <Box>
        <Typography align="center" variant="h6" style={{ fontSize: '20px', fontWeight: 500, marginBottom: '17px' }}>
          {g('recordControl.startOverConfirmModal.title')}
        </Typography>
        <Typography align="center" color="textSecondary" style={{ fontSize: '15px' }}>
          {g('recordControl.startOverConfirmModal.subtitle')}
        </Typography>
        <Box display="flex" alignItems="center" marginTop="20px" justifyContent="center">
          <Button variant="outlined" color="secondary" onClick={props.onCancel} style={{ minWidth: 'fit-content' }}>
            {g('recordControl.startOverConfirmModal.continueBtn')}
          </Button>
          <Button
            variant="outlined"
            style={{
              marginLeft: '10px',
              color: 'rgb(247, 55, 55)',
              borderColor: 'rgb(247, 55, 55)',
              minWidth: 'fit-content',
            }}
            onClick={() => MediaRecorderHandler.startOver()}
          >
            {g('recordControl.startOverConfirmModal.startOverBtn')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmStartOverModal
