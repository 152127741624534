import React from 'react'

interface IProps {
  color?: string
  width?: string
  height?: string
}

const StatsIcon = (props: IProps) => {
  return (
    <svg
      width={props?.width || '18'}
      height={props?.height || '16'}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.72 0C8.32256 0 8 0.325818 8 0.727273L7.92 13.0909H10V0.727273C10 0.325818 9.67744 0 9.28 0H8.72ZM0.72 1.45455C0.322355 1.45455 0 1.7769 0 2.17455V16H17.2727C17.6744 16 18 15.6744 18 15.2727C18 14.8711 17.6744 14.5455 17.2727 14.5455H1.44V2.17455C1.44 1.7769 1.11765 1.45455 0.72 1.45455ZM12.72 2.45455C12.3226 2.45455 12 2.78036 12 3.18182V13.0909H14V3.18182C14 2.78036 13.6774 2.45455 13.28 2.45455H12.72ZM4.6 5.36364C4.32256 5.36364 4 5.68946 4 6.09091V13.0909H6V6.09091C6 5.68946 5.67744 5.36364 5.28 5.36364H4.6Z"
        fill={props?.color || '#7A7A86'}
      />
    </svg>
  )
}

export default StatsIcon
