export const getImageResolution = (image: File) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
    img.onerror = () => {
      reject(new Error('Could not load image'))
    }
    img.src = URL.createObjectURL(image)
  })
}
