import { useEffect, useState, useCallback } from 'react'

const drawRoundedRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
) => {
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.lineTo(x + width - radius, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
  ctx.lineTo(x + width, y + height - radius)
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
  ctx.lineTo(x + radius, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
  ctx.lineTo(x, y + radius)
  ctx.quadraticCurveTo(x, y, x + radius, y)
  ctx.closePath()
  ctx.fill()
}

const setupCanvas = (canvasRef: HTMLCanvasElement) => {
  const dpr = window.devicePixelRatio || 1

  const rect = canvasRef.getBoundingClientRect()

  canvasRef.width = rect.width * dpr
  canvasRef.height = rect.height * dpr

  const ctx = canvasRef.getContext('2d')

  if (ctx) {
    ctx.scale(dpr, dpr)
  }

  return ctx
}

const useAudioPreview = () => {
  const [mediaStream, setMediaStream] = useState<MediaStream>()
  useEffect(() => {
    async function handleMediaPermissions() {
      try {
        const mediaStream = await window.navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true,
        })
        setMediaStream(mediaStream)
      } catch (e) {
        console.log('Media ERROR: ', e)
      }
    }

    handleMediaPermissions()

    return () => {
      mediaStream?.getTracks().forEach((track) => {
        track.stop()
      })
    }
  }, [])

  const [canvasLeftCtx, setCanvasLeftCtx] = useState<CanvasRenderingContext2D | null>()
  const [canvasRightCtx, setCanvasRightCtx] = useState<CanvasRenderingContext2D | null>()

  const canvasLeftRef = useCallback((node: any) => {
    if (node !== null) {
      const canvasCtx = setupCanvas(node)
      setCanvasLeftCtx(canvasCtx)
    }
  }, [])

  const canvasRightRef = useCallback((node: any) => {
    if (node !== null) {
      const canvasCtx = setupCanvas(node)
      setCanvasRightCtx(canvasCtx)
    }
  }, [])

  useEffect(() => {
    if (mediaStream) {
      const audioContext = new AudioContext()
      const analyser = audioContext.createAnalyser()

      let animationFrameId: number

      const microphone = audioContext.createMediaStreamSource(mediaStream)
      const audioProcessor = audioContext.createScriptProcessor(2048, 1, 1)

      analyser.smoothingTimeConstant = 0.8
      analyser.fftSize = 1024

      microphone.connect(analyser)
      analyser.connect(audioProcessor)
      audioProcessor.connect(audioContext.destination)

      audioProcessor.onaudioprocess = function () {
        const frequencyArray = new Uint8Array(analyser.frequencyBinCount)
        analyser.getByteFrequencyData(frequencyArray)

        const frequencesSum = frequencyArray.reduce((a, b) => a + b, 0)

        const averageVolume = frequencesSum / frequencyArray.length
        const adjustedAverageVolume = averageVolume / 1.5

        const draw = () => {
          if (canvasLeftCtx && canvasRightCtx) {
            const canvasWidth = 120
            const canvasHeight = 30

            const barWidth = 6
            const barHeight = 18
            const barAmount = 10
            const barRadius = 4
            const gapSize = 6

            canvasLeftCtx.fillStyle = 'transparent'
            canvasLeftCtx.fillRect(0, 0, canvasWidth, canvasHeight)

            canvasRightCtx.fillStyle = 'transparent'
            canvasRightCtx.fillRect(0, 0, canvasWidth, canvasHeight)

            let x = 0

            for (let i = 0; i < barAmount; i++) {
              canvasLeftCtx.fillStyle = adjustedAverageVolume > i ? '#2483F7' : '#EEF0F6'

              canvasRightCtx.fillStyle = adjustedAverageVolume > i ? '#2483F7' : '#EEF0F6'

              drawRoundedRect(canvasLeftCtx, x, canvasHeight - barHeight, barWidth, barHeight, barRadius)

              drawRoundedRect(canvasRightCtx, x, canvasHeight - barHeight, barWidth, barHeight, barRadius)
              x += barWidth + gapSize
            }
          }
          animationFrameId = requestAnimationFrame(draw)
        }
        draw()
      }
      return () => {
        mediaStream?.getTracks().forEach((track) => {
          track.stop()
        })
        window.cancelAnimationFrame(animationFrameId)
        audioProcessor.disconnect()
        analyser.disconnect()
        microphone.disconnect()
      }
    }
  }, [mediaStream, canvasLeftCtx, canvasRightCtx])

  return {
    mediaStream,
    canvasLeftRef,
    canvasRightRef,
  }
}

export default useAudioPreview
