import React from 'react'
import { Box, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import CalendlyLogo from '../../../../common/assets/calendly-logo.png'
import Button from '../../../../components/_refactored/Button'
import { Controller } from 'react-hook-form'
import { UseFormMethods } from 'react-hook-form/dist/types/form'
import { ICalendlyForm } from '../hooks/useIntegrations.hook'
import { isValidUrl } from '../../../../common/utils/isValidUrl'
import { useTranslation } from 'react-i18next'

interface IProps {
  form: UseFormMethods<ICalendlyForm>
  submit: () => void
  calendlyLoading: boolean
}

const IntegrationsView = (props: IProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation('settings')
  const { t: g } = useTranslation('general')
  return (
    <form onSubmit={props.submit}>
      <Box
        width="100%"
        borderRadius="10px"
        bgcolor="#F8F9FB"
        padding="22px 20px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Box display="flex">
          <img alt="Calendly logo" src={CalendlyLogo} style={{ marginRight: '20px', width: '43px', height: '43px' }} />
          <Box>
            <Typography variant="body1" style={{ fontWeight: 500, fontSize: '16px' }}>
              {t('integrations.calendlyIntegrationTitle')}
            </Typography>
            <Typography variant="body2" style={{ fontSize: '15px' }} color="textSecondary">
              {t('integrations.calendlyIntegrationDescription')}
            </Typography>
          </Box>
        </Box>
        <Controller
          name={'url'}
          control={props.form.control}
          rules={{
            validate: (value: string) =>
              value.length === 0 ? true : isValidUrl(value) ? true : t('integrations.urlValidationError'),
          }}
          render={({ value, onChange, onBlur }) => (
            <TextField
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={t('integrations.linkPlaceholder')}
              error={!!props.form.errors['url']}
              helperText={props.form.errors['url']?.message}
              variant="outlined"
              style={{ width: isMobile ? '100%' : '40%', marginTop: isMobile ? '16px' : '0px' }}
              InputProps={{
                style: {
                  fontSize: '16px',
                },
              }}
            />
          )}
        />
      </Box>
      <Button
        disabled={!props.form.formState.isDirty}
        variant="contained"
        loading={props.calendlyLoading}
        color="primary"
        style={{ marginTop: '24px' }}
        type="submit"
      >
        {g('common.saveChangesBtn')}
      </Button>
    </form>
  )
}

export default IntegrationsView
