import React from 'react'
import Modal from '../../../../../../../components/_refactored/Modal'
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import Button from '../../../../../../../components/_refactored/Button'
import AddThumbnailPhotoIcon from '../../../../../../../common/icons/AddThumbnailPhotoIcon'
import { useEditThumbnailDispatch } from '../../../common/context/EditThumbnailContext'
import useEditThumbnailCustomPhoto from '../../../common/hooks/useEditThumbnailCustomPhoto'
import { Cropper } from 'react-cropper'
import { useTranslation } from 'react-i18next'
import 'cropperjs/dist/cropper.css'
import { Close } from '@mui/icons-material'

interface IUploadThumbnailModalProps {
  videoParams: any
}
const UploadThumbnailModal = (props: IUploadThumbnailModalProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const editThumbnailDispatcher = useEditThumbnailDispatch()
  const state = useEditThumbnailCustomPhoto()
  const { t } = useTranslation('library')
  const { t: g } = useTranslation('general')
  const aspectRatio = props.videoParams?.width > props.videoParams?.height ? 16 / 9 : 9 / 16

  return (
    <Modal open={true} resetPadding={true} boxStyle={{ width: '1100px', padding: '30px 20px' }}>
      <>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" style={{ fontWeight: 500, fontSize: '16px' }}>
            {t('videoItem.editThumbnailCustomPhotoModal.uploadPhotoText')}
          </Typography>
          <Box display="flex" alignItems="center">
            <Button
              style={{ color: theme.palette.secondary.main }}
              onClick={() => editThumbnailDispatcher({ type: 'setCurrentModalView', value: '' })}
            >
              {g('common.cancelBtn')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={state.saveImage}
              disabled={!state.file}
              loading={state.uploading}
            >
              {g('common.saveBtn')}
            </Button>
          </Box>
        </Box>
        {state.image && !state.isGif && (
          <Modal
            open={true}
            onClose={() => {
              state.setImage(null)
              state.setFile(null)
            }}
            boxStyle={{ width: '1250px', maxWidth: '90%' }}
            style={{ position: 'relative' }}
          >
            <>
              <Box
                width="100%"
                maxHeight="80vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                border="1px solid gray"
              >
                <Cropper
                  style={{ width: '100%', height: '100%' }}
                  zoomTo={0}
                  initialAspectRatio={aspectRatio}
                  aspectRatio={aspectRatio}
                  preview=".img-preview"
                  src={state.image as string}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  guides={true}
                  draggable
                  ref={state.cropperRef}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" paddingTop="16px">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => state.setImage(null)}
                  style={{ marginRight: '8px' }}
                >
                  {g('common.cancelBtn')}
                </Button>
                <Button variant="contained" color="primary" onClick={state.handleImageCroppingSubmit}>
                  {g('common.submitBtn')}
                </Button>
              </Box>
            </>
          </Modal>
        )}
        {state.file ? (
          <Box
            width="700px"
            borderRadius="10px"
            maxWidth="90%"
            height="450px"
            margin={'50px auto'}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-end"
            position="relative"
          >
            <img
              alt={'Thumbnail'}
              src={URL.createObjectURL(state.file)}
              style={{
                aspectRatio: `${props.videoParams?.width} / ${props.videoParams?.height}` || '16 / 9',
                position: 'absolute',
                height: '100%',
                maxWidth: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: '10px',
              }}
            />
            <IconButton
              style={{ background: 'white', position: 'absolute', top: '10px', right: '10px' }}
              onClick={() => {
                state.setImage(null)
                state.setFile(null)
              }}
            >
              <Close style={{ color: '#7A7A86' }} />
            </IconButton>
          </Box>
        ) : (
          <Box
            width="700px"
            borderRadius="10px"
            maxWidth="90%"
            height="400px"
            margin={isMobile ? '50px auto' : '100px auto'}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{
              backgroundImage:
                "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e\")",
            }}
            {...state.dropzone.getRootProps()}
          >
            <AddThumbnailPhotoIcon />
            <Typography variant="h6" style={{ fontWeight: 500, fontSize: '16px', marginTop: '15px' }}>
              {t('videoItem.editThumbnailCustomPhotoModal.dragAndDrop')}
            </Typography>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ fontWeight: 400, fontSize: '15px', marginTop: '6px', marginBottom: '34px' }}
            >
              {t('videoItem.editThumbnailCustomPhotoModal.maxSizeDescription')}
            </Typography>
            <Button variant="contained" color="primary">
              <input {...state.dropzone.getInputProps()} />
              {t('videoItem.editThumbnailCustomPhotoModal.uploadFromComputer')}
            </Button>
          </Box>
        )}
      </>
    </Modal>
  )
}

export default UploadThumbnailModal
