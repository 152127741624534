import styled from 'styled-components'
import { Box } from '@mui/material'

export const VideoListInputWrapper = styled(Box)`
  width: 100%;
  padding-left: 35px;

  & .MuiOutlinedInput-root {
    border-radius: 17px;
    background: #f8f9fb;
  }

  & fieldset {
    border: none;
  }
`
