import React from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import useSendByEmailModal from './common/hooks/useSendByEmailModal'
import Modal from '../Modal'
import EmailForm from './ui/partials/EmailForm'
import EmailTemplatePreview from './ui/partials/EmailTemplatePreview'
import MessageSentModal from './MessageSentModal'
import 'react-multi-email/style.css'
import './styles.scss'
import { screensightVideo, VideoStatus } from '../../../API'

export interface ISendByEmailModal {
  videoTitle?: string | null
  videoDateAdded?: string | null
  videoId?: string
  thumbnailObject?: {
    __typename: 'S3Object'
    key: string
    bucket: string
  } | null
  hideSendByEmailModal: () => void
  videoPassword?: string | null
  videoStatus?: VideoStatus
}

const SendByEmailModal = (props: ISendByEmailModal) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const state = useSendByEmailModal(props.hideSendByEmailModal)

  if (state.isSentModalVisible) return <MessageSentModal handleClose={state.handleClose} />

  return (
    <Modal open={true} boxStyle={{ height: '800px', maxHeight: '90%', width: '1000px', maxWidth: '90%', padding: 0 }}>
      <Box
        id="send-by-email-modal"
        width="100%"
        height="100%"
        zIndex={900}
        display="flex"
        justifyContent="center"
        alignItems="center"
        maxHeight="100vh"
        style={{ overflowY: 'auto' }}
      >
        <Box
          width="100%"
          height="100%"
          maxWidth="1000px"
          bgcolor="background.default"
          borderRadius="10px"
          paddingBottom={isMobile ? '40px' : '0'}
        >
          <Box paddingBottom={isMobile ? '32px' : 0} position="relative">
            <EmailForm
              showMessageSentModal={state.showMessageSentModal}
              videoId={props.videoId}
              hideSendByEmailModal={props.hideSendByEmailModal}
              videoTitle={props.videoTitle}
              videoStatus={props?.videoStatus}
            />
            <EmailTemplatePreview
              isMobile={isMobile}
              videoTitle={props.videoTitle}
              videoDateAdded={props.videoDateAdded}
              thumbnailObject={props.thumbnailObject}
              videoPassword={props.videoPassword}
              videoStatus={props?.videoStatus}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default SendByEmailModal
