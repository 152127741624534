import * as React from 'react'
import { Close } from '@mui/icons-material'
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useChatContext, useChatDispatch } from '../../../common/contexts/ChatContext'
import ReplyIcon from '../../../../../../../../common/icons/ReplyIcon'
import CloudFrontImage from '../../../../../../../../components/_refactored/CloudFrontImage'
import FileThumbnail from '../PostList/FileThumbnail'
import ResponseBoxTimestamp from './ResponseBoxTimestamp'
import FileUploadResponseBox from './FileUploadResponseBox'
import { S3Object, screensightCustomer, VideoStatus } from '../../../../../../../../API'
import { isFileType } from '../../../../../../../../common/utils/isFileType'

type ContextFile = {
  fileName?: string | null
  fileObject?: S3Object | null
  status?: VideoStatus | null
  customerId?: string | null
  customerMeta?: screensightCustomer | null
  mimeType?: string | null
  metadata?: string | null
}

interface IResponseBox {
  videoPlayerEditor?: boolean | null
}
const ResponseBox = (props: IResponseBox) => {
  const chatContext = useChatContext()
  const chatDispatcher = useChatDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const file = chatContext.responseBoxData?.file as ContextFile
  const isGif = chatContext.responseBoxData?.photoKey?.includes('.gif')

  if (!chatContext.responseBoxData) return <></>

  if (chatContext.responseBoxData.type === 'file') {
    return <FileUploadResponseBox />
  }

  return (
    <Box height="60px" display="flex" alignItems="center" justifyContent="center" padding="0 10px 0 0" margin="0 auto">
      <Box display="flex" alignItems="center" padding="0 10px">
        {chatContext.responseBoxData?.type !== 'video' && (
          <Box marginRight="10px">
            <ReplyIcon />
          </Box>
        )}
        {(chatContext.responseBoxData?.type === 'video' || chatContext.responseBoxData?.type === 'comment') && (
          <CloudFrontImage
            disableResize={isGif}
            image={chatContext.responseBoxData?.photoKey || ''}
            width={chatContext.responseBoxData?.type === 'video' ? '80' : '40'}
            height={'40'}
            imgStyle={{
              width: chatContext.responseBoxData?.type === 'video' ? '80px' : '40px',
              height: '40px',
              borderRadius: '4px',
              objectFit: 'cover',
            }}
          />
        )}
        {chatContext.responseBoxData.file && chatContext.responseBoxData?.type === 'fileReply' && (
          <FileThumbnail
            imageSource={`${file?.fileObject?.accessLevel}/${file?.fileObject?.identityId}/${file?.fileObject?.key}`}
            isGif={isFileType(file?.fileObject?.key || '', ['gif'])}
            isImage={isFileType(file?.fileObject?.key || '', ['jpg', 'jpeg', 'png', 'gif'])}
            width={'40px'}
            height={'40px'}
            isResponseBox={true}
          />
        )}
      </Box>
      <Box width={isMobile ? '75%' : '90%'} position="relative">
        <Typography
          variant="body1"
          style={{
            maxWidth: '91%',
            fontSize: '16px',
            fontWeight: 500,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'block',
            position: 'absolute',
            top: '-22px',
          }}
        >
          {chatContext.responseBoxData?.title}
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontWeight: 400,
            fontSize: '14px',
            position: 'absolute',
            top: '2px',
            color: theme.palette.text.secondary,
          }}
        >
          {chatContext.responseBoxData?.author}
        </Typography>
      </Box>
      {chatContext.responseBoxData?.type === 'comment' && <ResponseBoxTimestamp />}
      {!props?.videoPlayerEditor && (
        <Box width="40px">
          <IconButton
            style={{ height: '32px', width: '32px', boxShadow: 'none' }}
            onClick={() => {
              chatDispatcher({
                type: 'setResponseBoxData',
                value: undefined,
              })
            }}
          >
            <Close />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default ResponseBox
