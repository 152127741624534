import * as React from 'react'

const SignUpBackgroundLogoTwo = () => {
  return (
    <svg width="236" height="291" viewBox="0 0 236 291" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.60038e-06 -68.5602L0.311242 176.197C0.259604 181.726 2.36974 187.058 6.19459 191.063C10.0194 195.069 15.2585 197.432 20.8018 197.654C23.5658 197.716 26.3143 197.227 28.886 196.215C31.4576 195.204 33.8005 193.69 35.7771 191.763C37.7536 189.837 39.3239 187.536 40.3957 184.996C41.4676 182.456 42.0194 179.728 42.0187 176.973L41.6815 -66.4146C41.6815 -71.8995 43.8677 -77.1596 47.759 -81.0381C51.6504 -84.9165 56.9283 -87.0954 62.4315 -87.0954L172.899 -87.2505C178.402 -87.2505 183.68 -85.0716 187.572 -81.1931C191.463 -77.3148 193.649 -72.0546 193.649 -66.5697L193.805 43.5298C193.805 49.0147 191.618 54.275 187.727 58.1534C183.836 62.0318 178.558 64.2107 173.055 64.2107H102.142C96.5923 64.1526 91.2389 66.2536 87.2189 70.0671C83.199 73.8805 80.8293 79.106 80.6136 84.633C80.5514 87.3878 81.0422 90.1271 82.0571 92.6902C83.072 95.2533 84.5906 97.5883 86.5236 99.5583C88.4567 101.528 90.7652 103.093 93.3136 104.162C95.862 105.23 98.5989 105.78 101.364 105.779L175.026 105.779C182.987 105.793 190.873 104.238 198.229 101.204C205.586 98.1697 212.267 93.7162 217.891 88.0994C223.514 82.4827 227.969 75.8133 230.997 68.475C234.026 61.1367 235.569 53.2742 235.538 45.3394L235.382 -68.8704C235.355 -84.8362 228.968 -100.138 217.627 -111.413C206.285 -122.688 190.915 -129.014 174.896 -129L60.3046 -128.845C44.2992 -128.824 28.9567 -122.471 17.6489 -111.182C6.34111 -99.892 -0.00688287 -84.5898 5.60038e-06 -68.6377"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        d="M117.834 15.9749C123.761 15.9298 129.519 13.9962 134.265 10.4567C139.011 6.91713 142.497 1.95726 144.212 -3.6978C144.957 -6.21727 145.349 -8.82687 145.38 -11.4531C145.345 -18.7372 142.422 -25.7121 137.246 -30.8556C132.071 -35.999 125.065 -38.8933 117.756 -38.9069C111.824 -38.8673 106.061 -36.9362 101.309 -33.3963C96.5582 -29.8564 93.0687 -24.8935 91.3518 -19.2343C90.5849 -16.7198 90.1916 -14.107 90.1846 -11.479C90.212 -4.18829 93.1372 2.79449 98.3195 7.94012C103.502 13.0857 110.519 15.9749 117.834 15.9749Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        d="M168.103 168.237V222.887C168.103 228.395 170.298 233.679 174.207 237.574C178.115 241.469 183.416 243.658 188.943 243.658C194.471 243.658 199.772 241.469 203.68 237.574C207.588 233.679 209.784 228.395 209.784 222.887V168.212C209.805 162.703 207.629 157.411 203.735 153.501C199.841 149.591 194.548 147.383 189.021 147.363C183.494 147.342 178.185 149.511 174.262 153.392C170.339 157.273 168.123 162.548 168.103 168.057"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        d="M84.7861 168.337L84.9158 270.216C84.9158 275.728 87.1129 281.015 91.0237 284.912C94.9345 288.81 100.239 291 105.77 291C111.3 291 116.605 288.81 120.515 284.912C124.426 281.015 126.623 275.728 126.623 270.216L126.494 168.363C126.494 162.85 124.296 157.564 120.386 153.666C116.475 149.768 111.171 147.579 105.64 147.579C100.109 147.579 94.8049 149.768 90.894 153.666C86.9832 157.564 84.7861 162.85 84.7861 168.363V168.363"
        fill="white"
        fill-opacity="0.1"
      />
    </svg>
  )
}
export default SignUpBackgroundLogoTwo
