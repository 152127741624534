import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const usePatentProvider = () => {
  const { t } = useTranslation('auth')

  const defaultState = {
    text: `${t('layout.defaultPatenPart1')} ${new Date().getFullYear()} ${t('layout.defaultPatenPart2')}`,
    redirectTo: 'https://www.parus.ai/legal/',
  }
  const [context, setContext] = useState(defaultState)

  useEffect(() => {
    ;(async () => {
      setContext(defaultState)

      try {
        const { patent = '' } = await (await fetch('https://legal.parusapis.com/patents')).json()

        setContext({ redirectTo: '', text: patent })
      } catch (err: any) {
        setContext(defaultState)
      }
    })()
  }, [])

  return {
    context,
  }
}

export type UsePatentProvider = ReturnType<typeof usePatentProvider>
export default usePatentProvider
