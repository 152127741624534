import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import UserAvatar from './UserAvatar'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import useRow from '../../common/hooks/useRow'
import { RowWrapper } from './styled/RowWrapper'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'
import PencilIcon from '../../../../../../../common/icons/PencilIcon'

interface IRow {
  active?: boolean
  shouldNotify?: boolean | null
  label: string
  imgKey?: string
  channelId: string
  hasDraft?: boolean
  isScreenSightUser?: boolean
}

const Row = (props: IRow) => {
  const auth = useContext(AuthContext)
  const state = useRow(props.channelId)
  const pencilIconVisible = props.hasDraft && !props.active
  const redDotVisible = props.shouldNotify && state.channelParamsId !== props.channelId

  if (props.channelId === auth.user?.supportChannelId) return <></>

  return (
    <RowWrapper
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="7px 15px"
      style={{ cursor: 'pointer' }}
      active={props.active}
      onClick={state.handleRedirect}
      hasNewMessages={props.shouldNotify}
    >
      <Box
        display="flex"
        width={pencilIconVisible && redDotVisible ? '180px' : pencilIconVisible || redDotVisible ? '200px' : '230px'}
      >
        <UserAvatar
          width="24px"
          height="24px"
          label={props.label}
          active={props.active}
          imgKey={props?.imgKey}
          isScreenSightUser={props.isScreenSightUser}
        />
        <Typography
          variant="body1"
          style={{
            fontWeight: props.shouldNotify && state.channelParamsId !== props.channelId ? 500 : 400,
            marginLeft: '10px',
            fontSize: '15px',
            width: 'calc(100% - 32px)',
            color:
              !!props.channelId && props.channelId === state.channelParamsId
                ? 'white'
                : props.shouldNotify
                ? 'black'
                : theme.palette.text.secondary,
          }}
        >
          {props.label}
        </Typography>
      </Box>
      <Box display="flex" position="absolute" left="200px" top="0" height="100%" alignItems="center">
        {pencilIconVisible && (
          <Box marginLeft={redDotVisible ? '5px' : '24px'}>
            <PencilIcon width="14px" height="14px" />
          </Box>
        )}
        {redDotVisible && (
          <Box width="10px" display="flex" alignItems="center" marginLeft={pencilIconVisible ? '5px' : '24px'}>
            <Box width="10px" height="10px" bgcolor="#FF1B69" borderRadius="40px" />
          </Box>
        )}
      </Box>
    </RowWrapper>
  )
}

export default Row
