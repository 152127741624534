import React from 'react'
import useEmailNotifications from './hooks/useEmailNotifications'
import EmailNotificationsView from './view/EmailNotifications.view'
import PageTitle from '../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

const EmailNotifications = () => {
  const state = useEmailNotifications()
  const { t: g } = useTranslation('general')

  return (
    <>
      <PageTitle name={g('pageName.emailNotificationsSettings')} />
      <EmailNotificationsView {...state} />
    </>
  )
}

export default EmailNotifications
