import { useEffect, useState } from 'react'

const useImageFilePost = (fileMetadata: any) => {
  const [imagePreviewKey, setImagePreviewKey] = useState<string | null>(null)
  const [cloudFrontDimensions, setCloudFrontDimensions] = useState<{ width?: string; height?: string } | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const handleCloseImagePreview = () => {
    setImagePreviewKey(null)
  }

  const handleOpenImagePreview = (key: string) => {
    setImagePreviewKey(key)
  }
  useEffect(() => {
    if (!fileMetadata?.metadata) {
      setLoading(false)
      return
    }
    const metadata = JSON.parse(fileMetadata?.metadata)
    if (!metadata) return
    if (metadata?.height <= 500) {
      setCloudFrontDimensions(null)
      setLoading(false)
      return
    }
    const divider = metadata?.width / metadata?.height
    setCloudFrontDimensions({ width: (500 * divider).toFixed(), height: '500' })
    setLoading(false)
  }, [fileMetadata])

  return { imagePreviewKey, cloudFrontDimensions, loading, handleCloseImagePreview, handleOpenImagePreview }
}

export default useImageFilePost
