import * as React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { isFileType } from '../../../../../../../../common/utils/isFileType'
import FileThumbnail from './FileThumbnail'
import { FilePost as IFilePost } from '../../../../../../../../API'

interface IFileMetaProps {
  file: IFilePost | null
  parentPostContent: string
  owner: boolean
  username?: string
}
const FileData = (props: IFileMetaProps) => {
  const theme = useTheme()
  const fileKey = props.file?.fileObject?.key

  return (
    <Box display="flex" alignItems="center">
      {props.file?.fileObject && (
        <FileThumbnail
          file={props.file}
          isReactive={true}
          imageSource={`${props.file?.fileObject?.accessLevel}/${props.file?.fileObject?.identityId}/${fileKey}`}
          isImage={isFileType(fileKey || '', ['jpg', 'jpeg', 'png', 'gif'])}
          isPdf={isFileType(fileKey || '', ['pdf'])}
          isDoc={isFileType(fileKey || '', ['doc', 'docx'])}
          isGif={isFileType(fileKey || '', ['gif'])}
          isExcel={isFileType(fileKey || '', ['xls', 'xlsx'])}
          width={'40px'}
          height={'40px'}
          owner={props.owner}
        />
      )}

      <Box position="relative" width="77%">
        <Box width="100%" display="block" position="absolute" left="8px" top="-21px">
          <Typography
            variant="body1"
            style={{
              lineHeight: '1',
              width: '95%',
              fontSize: '16px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              color: props.owner ? theme.palette.background.default : theme.palette.text.primary,
            }}
          >
            {props.parentPostContent}
          </Typography>
        </Box>
        <Typography
          width={'95%'}
          variant="body1"
          style={{
            lineHeight: '1.1',
            position: 'absolute',
            left: '8px',
            top: '4px',
            fontWeight: 400,
            fontSize: '14px',
            color: props.owner ? theme.palette.background.default : theme.palette.text.secondary,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {props.username}
        </Typography>
      </Box>
    </Box>
  )
}
export default FileData
