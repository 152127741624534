import { useEffect } from 'react'
import { API } from 'aws-amplify'
import gql from 'graphql-tag'
import { onCreateVideo } from '../../../graphql/subscriptions'
import { GraphQLErrors } from '@apollo/client/errors'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { onUpdateVideo, onDeleteVideo } from '../../../common/api/graphql/subscriptions'
import {
  OnCreateVideoSubscription,
  OnDeleteVideoSubscription,
  OnUpdateVideoSubscription,
  screensightVideo,
} from '../../../API'
import { IndexedRecordingEntity } from '../../../common/services/IndexedDB'

interface ICreateVideoSubsValue {
  value: { data: OnCreateVideoSubscription }
}
interface IDeleteVideoSubsValue {
  value: { data: OnDeleteVideoSubscription }
}
interface IUpdateVideoSubsValue {
  value: { data: OnUpdateVideoSubscription }
}

const useVideoListSubscriptionsHook = (
  userId?: string,
  videos?: (screensightVideo | IndexedRecordingEntity)[],
  setVideos?: any,
) => {
  useEffect(() => {
    if (userId) {
      const subOnCreate = API.graphql({
        query: gql(onCreateVideo),
        variables: {
          customerId: userId,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        // @ts-ignore
      }).subscribe({
        next: ({ value }: ICreateVideoSubsValue) => {
          setVideos((videosState: (screensightVideo | IndexedRecordingEntity)[]) => [
            value.data.onCreateVideo,
            ...videosState,
          ])
        },
        error: (error: GraphQLErrors) => {
          if (error) {
            console.error('Error on create video subscription', error)
            // window.location.reload()
          }
        },
      })

      const subOnUpdate = API.graphql({
        query: gql(onUpdateVideo),
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        // @ts-ignore
      }).subscribe({
        next: ({ value }: IUpdateVideoSubsValue) => {
		  console.log('UpdateVideo subscription data', value)

          if (value.data?.onUpdateVideo?.customerId === userId) {
            setVideos((videosState: any) => {
              const _videos = [...videosState]
              const videoIndex = _videos.findIndex((v) => v.id === value.data?.onUpdateVideo?.id)
              if (videoIndex > -1) {
                _videos[videoIndex] = value.data.onUpdateVideo
                return [..._videos]
              }
              return videosState
            })
          }
        },
        error: (error: GraphQLErrors) => {
          if (error) {
            console.error('Error on update video subscription', error)
          }
        },
      })

      const subOnDelete = API.graphql({
        query: gql(onDeleteVideo),
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        // @ts-ignore
      }).subscribe({
        next: ({ value }: IDeleteVideoSubsValue) => {
          if (value.data?.onDeleteVideo?.customerId === userId) {
            setVideos((videosState: any) => {
              const _videos = [...videosState]
              const videoIndex = _videos.findIndex((v) => v.id === value.data?.onDeleteVideo?.id)
              if (videoIndex > -1) {
                _videos.splice(videoIndex, 1)
                return [..._videos]
              }
              return videosState
            })
          }
        },
        error: (error: GraphQLErrors) => {
          if (error) {
            console.error('Error on create video subscription', error)
          }
        },
      })

      return () => {
        subOnCreate.unsubscribe()
        subOnUpdate.unsubscribe()
        subOnDelete.unsubscribe()
      }
    }
  }, [userId])
}

export default useVideoListSubscriptionsHook
