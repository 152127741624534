import React from 'react'

const LandscapeAdd = () => {
  return (
    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.8 0C0.816243 0 0 0.79357 0 1.75V12.25C0 13.2064 0.816243 14 1.8 14H16.2C17.1838 14 18 13.2064 18 12.25V1.75C18 0.79357 17.1838 0 16.2 0H1.8ZM1.8 1.75H16.2V12.25H1.8V1.75ZM7.2 3.5C6.96131 3.5 6.73239 3.59219 6.5636 3.75628C6.39482 3.92038 6.3 4.14294 6.3 4.375C6.3 4.60706 6.39482 4.82962 6.5636 4.99372C6.73239 5.15781 6.96131 5.25 7.2 5.25C7.43869 5.25 7.66761 5.15781 7.8364 4.99372C8.00518 4.82962 8.1 4.60706 8.1 4.375C8.1 4.14294 8.00518 3.92038 7.8364 3.75628C7.66761 3.59219 7.43869 3.5 7.2 3.5ZM11.25 6.125L8.1 9.625L5.85 7.4375L3.39961 10.5H14.625L11.25 6.125Z"
        fill="#2483F7"
      />
      <path d="M13 14V12.25H21V14H13Z" fill="#2483F7" />
      <path d="M10 14V12.25H13V14H10Z" fill="#F9FAFC" />
      <path d="M16.2 9H18V17H16.2V9Z" fill="#2483F7" />
      <path d="M16.2 6H18V9H16.2V6Z" fill="#F9FAFC" />
    </svg>
  )
}

export default LandscapeAdd
