import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '../../../../../icons'
import CameraModal from '../../../../../../containers/VideoRecorder/CameraModal'
import { Box, IconButton, Typography } from '@mui/material'
import RecorderStateContext from '../../../../../providers/RecorderStateProvider/RecorderStateContext'
import MediaRecorderHandler from '../../../../../providers/RecorderStateProvider/MediaRecorderHandler'
import CameraWithPrompterPreview from '../../../../../../containers/VideoRecorder/CameraWithPrompterPreview'
import { Check, Close } from '@mui/icons-material'
import ConfirmCancelModal from './ConfirmCancelModal'
import RecordingCountDown from './RecordingCountDown'
import ConfirmStartOverModal from './ConfirmStartOverModal'
import { AuthContext } from '../../../../../providers/AuthStatusProvider'

const formatTimestamp = (timestamp: number) => {
  const pad = (num: number, size: number) => {
    let numS = num.toString()
    while (numS.length < size) numS = '0' + numS
    return numS
  }

  const h = parseInt(`${timestamp / 3600000}`)
  const hRest = parseInt(`${timestamp % 3600000}`)
  const m = parseInt(`${hRest / 60000}`)
  const mRest = parseInt(`${hRest % 60000}`)
  const s = parseInt(`${mRest / 1000}`)

  return `${pad(h, 2)}:${pad(m, 2)}:${pad(s, 2)}`
}

interface IRecordingCameraInProgressModal {
  hasPrompter?: boolean
  countDown: boolean
}

const RecordingCameraInProgressModal = (props: IRecordingCameraInProgressModal) => {
  const { t: g } = useTranslation('general')
  const auth = useContext(AuthContext)
  const { state: recorderState } = useContext(RecorderStateContext)
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false)
  const [confirmStartOverModalVisible, setConfirmStartOverModalVisible] = useState(false)

  const time = useMemo(() => formatTimestamp(recorderState.duration), [recorderState.duration])

  useEffect(() => {
    if (!auth?.applicationConfiguration?.getApplicationConfiguration?.featureFlags?.maxVideoDurationLimit) return
    if (
      recorderState.duration >
      auth.applicationConfiguration.getApplicationConfiguration.featureFlags.maxVideoDurationLimit * 1000
    ) {
      MediaRecorderHandler.stopMediaRecorderHandler()
    }
  }, [recorderState.duration, auth.applicationConfiguration?.getApplicationConfiguration?.featureFlags])

  return (
    <CameraModal open={true}>
      <>
        {props.countDown && <RecordingCountDown />}
        {props.hasPrompter ? (
          <CameraWithPrompterPreview cameraStream={recorderState.cameraStream} paused={props.countDown} />
        ) : (
          <CameraModal.Preview cameraStream={recorderState.cameraStream} />
        )}
        {confirmDeleteModalVisible && <ConfirmCancelModal onCancel={() => setConfirmDeleteModalVisible(false)} />}
        {confirmStartOverModalVisible && (
          <ConfirmStartOverModal onCancel={() => setConfirmStartOverModalVisible(false)} />
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="20px">
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" marginRight="20px">
              <IconButton
                style={{ background: 'white', marginRight: '10px' }}
                onClick={() => {
                  setConfirmDeleteModalVisible(true)
                }}
              >
                <Close style={{ color: '#7A7A86' }} />
              </IconButton>
              <Typography style={{ color: 'white', fontSize: '18px', fontWeight: 500 }}>
                {g('common.cancelBtn')}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Icon.RecordAnimation width={42} height={42} />
              <Box padding="6px" borderRadius="10px" bgcolor="rgba(20, 35, 61, 0.6)">
                <Typography style={{ color: 'white', fontSize: '18px', fontWeight: 500 }}>{time}</Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" marginRight="20px">
              <Typography style={{ color: 'white', fontSize: '18px', fontWeight: 500 }}>
                {g('recordControl.recordInProgressModal.startOver')}
              </Typography>
              <IconButton
                style={{ background: 'white', marginLeft: '10px' }}
                onClick={() => setConfirmStartOverModalVisible(true)}
              >
                <Icon.StartoverSmall />
              </IconButton>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography style={{ color: 'white', fontSize: '18px', fontWeight: 500 }}>
                {g('recordControl.recordInProgressModal.done')}
              </Typography>
              <IconButton
                style={{ background: '#2483F7', marginLeft: '10px' }}
                onClick={() => MediaRecorderHandler.stopMediaRecorderHandler()}
              >
                <Check style={{ color: 'white' }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </>
    </CameraModal>
  )
}

export default RecordingCameraInProgressModal
