import React from 'react'
import { Box, CircularProgress, Grid, useMediaQuery } from '@mui/material'
import ViewerInfoModal from '../../components/_refactored/ViewerInfoModal'
import UnauthorizedHeader from './ui/components/UnauthorizedHeader'
import VideoAccessModal from '../../components/_refactored/VideoAccessModal'
import CalendlyBox from './ui/components/CalendlyBox'
import useGetVideoFragment from '../../common/api/hooks/useGetVideoFragment'
import useGetScreensightCustomer from '../../common/api/hooks/useGetScreensightCustomer'
import VideoSharingBar from './ui/partials/VideoSharingBar'
import VideoDescription from './ui/partials/VideoDescription'
import VideoActionsBar from './ui/partials/VideoActionsBar'
import useVideoItemHook from './ui/common/hooks/useVideoItem.hook'
import VideoPlayerLibContainer from './ui/partials/VideoPlayerLibContainer'
import { AccessType } from '../../API'
import PageTitle from '../../components/_refactored/PageTitle/PageTitle'
import { theme } from '../../common/providers/MuiThemeProvider'
import AISummaryContent from '../Chat/ui/components/ChatContent/ui/components/VideoPostAISummary/ui/components/AISummaryResult'
import useVideoPostAISummary from '../Chat/ui/components/ChatContent/ui/components/VideoPostAISummary/common/hooks/useVideoPostAISummary'

const VideoItem = () => {
  const state = useVideoItemHook()
  const cacheVideo = useGetVideoFragment(state.videoId)
  const cacheCustomer = useGetScreensightCustomer(state.customerId)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const autoplayView = !state.authorized && isMobile
  const isVideoHorizontal = (state?.width || 0) > (state?.height || 0)
  const AISummaryState = useVideoPostAISummary(cacheVideo?.transcriptionSummaryError)

  if (state.loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
        <CircularProgress />
      </Grid>
    )
  }

  if (state.showAccessModal) {
    return <VideoAccessModal videoId={state.videoId} onSuccess={state.hideAccessModal} />
  }

  if (!state.loading && !state.authorized && !state.isNameAdded && cacheVideo?.accessType !== AccessType.protected) {
    return <ViewerInfoModal setIsNameAdded={state.setIsNameAdded} />
  }

  return (
    <>
      {!state.authorized && state.isNameAdded && (
        <UnauthorizedHeader
          isVideoHorizontal={isVideoHorizontal}
          autoplayView={autoplayView}
          brandingLogoKey={cacheCustomer?.brandLogo?.key}
        />
      )}
      <PageTitle name={`${cacheVideo?.title || ''}`} />
      <Box
        height={'100vh'}
        marginTop={isVideoHorizontal ? '60px' : '0px'}
        bgcolor={!state.authorized && cacheCustomer?.brandLogo?.key ? '#F9FAFC' : 'background.default'}
      >
        {state.isUserVideoOwner && <VideoSharingBar videoId={state?.videoId} AISummaryState={AISummaryState} />}
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{
            ...(isLargeScreen && { marginLeft: 'auto', marginRight: 'auto' }),
            marginTop: autoplayView ? '0' : '70px',
            maxWidth: '1600px',
            padding: !cacheCustomer?.calendlyUrl || state.isUserVideoOwner || state.isMobile ? '0 5px' : '16px',
          }}
        >
          <Grid
            item
            xs={12}
            sm={!isVideoHorizontal && !state.authorized ? 8 : 10}
            md={!isVideoHorizontal && !state.authorized ? 6 : 9}
            lg={
              cacheCustomer?.calendlyUrl && state.authorized && !isVideoHorizontal && !state.isUserVideoOwner
                ? 5
                : !isVideoHorizontal && !state.isUserVideoOwner && cacheCustomer?.calendlyUrl
                ? 4
                : isVideoHorizontal && !state.authorized && cacheCustomer?.calendlyUrl
                ? 8
                : 9
            }
            xl={8}
            sx={{ paddingTop: '10px!important' }}
          >
            <VideoDescription customerId={state.customerId} videoId={state.videoId} isOwner={state.isUserVideoOwner} />
            <VideoPlayerLibContainer
              videoSource={state?.filePresignedUrl || cacheVideo?.filePresignedUrl}
              playerRef={state.playerRef}
              videoOwnerId={state?.customerId}
              appConfigData={state.appConfigData}
              videoId={state?.videoId}
              poster={state.poster}
              authorized={state.authorized}
            />
            {state.isUserVideoOwner && (
              <VideoActionsBar
                videoParams={{ width: state.width, height: state.height }}
                videoSource={state?.filePresignedUrl || cacheVideo?.filePresignedUrl}
                videoId={state?.videoId}
                videoViewersData={state.videoViewersData}
                fileObject={state.fileObject}
              />
            )}
          </Grid>
          {state.isUserVideoOwner &&
            (AISummaryState.error ||
              (AISummaryState.experimentalInfoVisible && !cacheVideo?.transcriptionSummary) ||
              cacheVideo?.transcriptionSummary) && (
              <Grid item xs={12} sm={10} md={9} lg={3}>
                <AISummaryContent
                  error={AISummaryState.error}
                  experimentalInfoVisible={AISummaryState.experimentalInfoVisible}
                  transcriptionSummary={cacheVideo?.transcriptionSummary}
                  handleExperimentalInfoVisibility={AISummaryState.handleExperimentalInfoVisibility}
                  contentSelected={AISummaryState.contentSelected}
                  bucket={cacheVideo?.transcriptionObject?.bucket}
                  bucketKey={cacheVideo?.transcriptionObject?.key}
                />
              </Grid>
            )}
          {!state.isUserVideoOwner && <CalendlyBox customerId={state.customerId} />}
        </Grid>
      </Box>
    </>
  )
}

export default VideoItem
