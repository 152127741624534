import gql from 'graphql-tag'
import CognitoApolloClient from '../../../../../common/clients/CognitoApolloClient'
import { listCustomerRecentChannels } from '../../../../../common/api/graphql/listCustomerRecentChannels'
import { chatActions, IChatCustomers, SidebarParams } from './chat-slice'
import { TVideoInChannel } from '../../../ui/components/PartnerSidebar/common/api/listVideosInChannel'
import { OnPostEventSubscription, screensightReaction } from '../../../../../API'
import { ConnectionState } from '@aws-amplify/pubsub'

const _fetchListChannel = async (dispatch: any, customerId: string) => {
  await CognitoApolloClient.query({
    query: gql(listCustomerRecentChannels),
    fetchPolicy: 'network-only',
    variables: {
      customerId: customerId,
    },
  })
}

export const refetchListChannels = (customerId: string) => {
  return async (dispatch: any) => {
    try {
      await _fetchListChannel(dispatch, customerId)
    } catch (error: any) {
      console.log('Error on refetchListChannels', error)
    }
  }
}

export const updateFileAttachmentProgress = (progress: any, postId: string) => {
  return async (dispatch: any) => {
    dispatch(chatActions.updateProgress({ progress: progress, postId: postId }))
  }
}

export const updateSidebarWidth = (sidebarParams: SidebarParams) => {
  return async (dispatch: any) => {
    dispatch(
      chatActions.updateWidth({
        width: sidebarParams.width,
        height: sidebarParams.height,
        x: sidebarParams.x,
        y: sidebarParams.y,
      }),
    )
  }
}

export const updateRightSidePanelParams = (sidebarParams: SidebarParams) => {
  return async (dispatch: any) => {
    dispatch(
      chatActions.updateRightSidePanelParams({
        width: sidebarParams.width,
        height: sidebarParams.height,
        x: sidebarParams.x,
        y: sidebarParams.y,
      }),
    )
  }
}

export const updateVideoPlaylistData = (videoPlaylistData: TVideoInChannel[]) => {
  return async (dispatch: any) => {
    dispatch(chatActions.updateVideoPlaylistData({ videoPlaylistData: videoPlaylistData }))
  }
}

export const addPostToVideoPlaylistData = (post: TVideoInChannel) => {
  return async (dispatch: any) => {
    dispatch(chatActions.addPostToVideoPlaylistData({ post: post }))
  }
}

export const updateChatPlayerAutoplay = (chatPlayerAutoplay: boolean) => {
  return async (dispatch: any) => {
    dispatch(chatActions.updateChatPlayerAutoplay({ chatPlayerAutoplay: chatPlayerAutoplay }))
  }
}

export const addVideoPlaylistProgress = (progress: number, postId: string) => {
  return async (dispatch: any) => {
    dispatch(chatActions.addVideoPlaylistProgress({ progress: progress, postId: postId }))
  }
}

export const clearVideoPlaylistData = () => {
  return async (dispatch: any) => {
    dispatch(chatActions.clearVideoPlaylistData(null))
  }
}

export const removeVideoPlaylistProgress = (postId: string) => {
  return async (dispatch: any) => {
    dispatch(chatActions.removeVideoPlaylistProgress({ postId: postId }))
  }
}

export const setVideoPlayerReactions = (reactions: screensightReaction) => {
  return async (dispatch: any) => {
    dispatch(chatActions.setVideoPlayerReactions({ reactions: reactions }))
  }
}

export const addVideoPlayerReactions = (postId: string, reactions: screensightReaction[]) => {
  return async (dispatch: any) => {
    dispatch(chatActions.addVideoPlayerReactions({ postId: postId, reactions: reactions }))
  }
}

export const addVideoPlayerReaction = (postId: string, reaction: screensightReaction) => {
  return async (dispatch: any) => {
    dispatch(chatActions.addVideoPlayerReaction({ postId: postId, reaction: reaction }))
  }
}

export const removeVideoPlayerReaction = (postId: string, reaction: screensightReaction) => {
  return async (dispatch: any) => {
    dispatch(chatActions.removeVideoPlayerReaction({ postId: postId, reaction: reaction }))
  }
}

export const clearVideoPlayerReactions = () => {
  return async (dispatch: any) => {
    dispatch(chatActions.clearVideoPlayerReactions(null))
  }
}

export const setChatCustomers = (chatCustomers: IChatCustomers) => {
  return async (dispatch: any) => {
    dispatch(chatActions.setChatCustomers({ chatCustomers: chatCustomers }))
  }
}

export const setChatPlayerPaused = (chatPlayerPaused: boolean) => {
  return async (dispatch: any) => {
    dispatch(chatActions.setChatPlayerPaused({ chatPlayerPaused: chatPlayerPaused }))
  }
}

export const setUnreadChannelsCount = (unreadChannelsCount: number) => {
  return async (dispatch: any) => {
    dispatch(chatActions.setUnreadChannelsCount({ unreadChannelsCount: unreadChannelsCount }))
  }
}

export const addSeenVideoPostId = (postId: string) => {
  return async (dispatch: any) => {
    dispatch(chatActions.addSeenVideoPostId({ postId: postId }))
  }
}

export const setonPostEventSubscriptionData = (onPostEventSubscriptionData?: OnPostEventSubscription | null) => {
  return async (dispatch: any) => {
    dispatch(chatActions.setonPostEventSubscriptionData({ onPostEventSubscriptionData: onPostEventSubscriptionData }))
  }
}

export const setVideosLimitReached = (videosLimitReached: boolean) => {
  return async (dispatch: any) => {
    dispatch(chatActions.setVideosLimitReached({ videosLimitReached: videosLimitReached }))
  }
}
