import * as React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useTranslation } from 'react-i18next'
import { useChatDispatch } from '../../../common/contexts/ChatContext'
import { useEffect } from 'react'

interface IFileDropzoneProps {
  isDragActive: boolean
  getInputProps: any
  rootRef: any
  acceptedFiles: any
}
const FileDropzone = (props: IFileDropzoneProps) => {
  const { t } = useTranslation('chat')
  const theme = useTheme()
  const chatDispatcher = useChatDispatch()

  useEffect(() => {
    if (!props.acceptedFiles?.length) return
    chatDispatcher({
      type: 'setResponseBoxData',
      value: {
        type: 'file',
        file: props.acceptedFiles[0],
        fileName: props.acceptedFiles[0].name,
        fileType: props.acceptedFiles[0].type,
      },
    })
  }, [props.acceptedFiles])

  return (
    <Box
      className="drag"
      width="100%"
      height="100%"
      position="absolute"
      zIndex={100000}
      bgcolor="rgba(255, 255, 255, 0.9)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      border={`3px solid ${theme.palette.primary.main}`}
      style={{ visibility: props.isDragActive ? 'visible' : 'hidden' }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <input {...props.getInputProps()} />
        <FileDownloadIcon style={{ color: theme.palette.primary.main, fontSize: '42px' }} />
        <Typography variant="body1" color="textSecondary" sx={{ fontSize: '18px', fontWeight: 700 }}>
          {t('chatBody.fileUpload.dropFile')}
        </Typography>
      </Box>
    </Box>
  )
}
export default FileDropzone
