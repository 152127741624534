import { useForm } from 'react-hook-form'
import { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { AccessType, UpdateVideoMutation, UpdateVideoMutationVariables } from '../../../../API'
import gql from 'graphql-tag'
import { updateVideo } from '../../../../graphql/mutations'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import ToastContext from '../../../../common/providers/ToastProvider/ToastContext'
import { v4 } from 'uuid'
import { useTranslation } from 'react-i18next'

export interface VideoPrivacyForm {
  type: string
  videoPassword: string
}

const useVideoPrivacyModalHook = (
  togglePrivacyModalVisibility: () => void,
  privacyType: AccessType | null | undefined,
  videoId: string | undefined,
  videoPassword?: string | null,
) => {
  const toastContext = useContext(ToastContext)
  const { t } = useTranslation('library')
  const [isPasswordShowed, setIsPasswordShowed] = useState(privacyType !== AccessType.protected)

  const togglePasswordVisible = () => {
    setIsPasswordShowed((prevState) => !prevState)
  }

  const form = useForm<VideoPrivacyForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      type: privacyType || AccessType.public,
      videoPassword: privacyType !== AccessType.protected ? v4().substring(0, 15) : videoPassword || '',
    },
  })

  useEffect(() => {
    if (!privacyType) return
    form.reset({
      type: privacyType,
      videoPassword: privacyType !== AccessType.protected ? v4().substring(0, 15) : videoPassword || '',
    })
  }, [privacyType])

  const [updateVideoMutation, { loading: updateVideoMutationLoading }] = useMutation<
    UpdateVideoMutation,
    UpdateVideoMutationVariables
  >(gql(updateVideo), {
    variables: { input: { id: videoId || '' } },
    client: CognitoApolloClient,
  })

  const handleUpdatePrivacy = form.handleSubmit(async (values: VideoPrivacyForm) => {
    if (!videoId) return
    try {
      if (form.watch('type') === 'public') {
        await updateVideoMutation({ variables: { input: { id: videoId, accessType: AccessType.public } } })
        toastContext.open({ type: 'INFO', text: t('videoItem.videoPrivacyModal.privacyUpdateSuccess') })
        form.reset({ videoPassword: '', type: AccessType.public })
        return
      }
      await updateVideoMutation({
        variables: { input: { id: videoId, accessType: AccessType.protected, password: values.videoPassword } },
      })
      form.reset({ type: AccessType.protected })
      toastContext.open({ type: 'INFO', text: t('videoItem.videoPrivacyModal.privacyUpdateSuccess') })
    } catch (e: any) {
      toastContext.open({ type: 'ERROR', text: t('videoItem.videoPrivacyModal.privacyUpdateError') })
    } finally {
      togglePrivacyModalVisibility()
    }
  })

  return {
    form,
    isPasswordShowed,
    togglePasswordVisible,
    handleUpdatePrivacy,
    loading: updateVideoMutationLoading,
  }
}

export default useVideoPrivacyModalHook
