import React from 'react'
import { Box, IconButton, Modal, Typography, useTheme } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import TrashIcon from '../../../../common/icons/TrashIcon'
import Button from '../../../../components/_refactored/Button'
import { screensightVideo } from '../../../../API'
import { IndexedRecordingEntity } from '../../../../common/services/IndexedDB'
import { useTranslation } from 'react-i18next'

interface IVideoListItemMobileSettingsModal {
  selectedVideoItem: any
  setSelectedVideoItem: any
  openDeleteModal: (item: screensightVideo | IndexedRecordingEntity) => void
}

const VideoListItemMobileSettingsModal = (props: IVideoListItemMobileSettingsModal) => {
  const theme = useTheme()
  const { t } = useTranslation('library')
  const { t: g } = useTranslation('general')
  const { selectedVideoItem, setSelectedVideoItem, openDeleteModal } = props

  return (
    <Modal
      open={true}
      onClose={() => setSelectedVideoItem(null)}
      BackdropProps={{ style: { backgroundColor: 'rgba(20, 35, 61, 0.7)' } }}
    >
      <Box position="absolute" width="90%" bottom="16px" left="5%">
        <Box
          width="100%"
          bgcolor="background.default"
          padding="22px 44px"
          display="flex"
          justifyContent="space-between"
          marginBottom="8px"
          borderRadius="14px"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <IconButton
              style={{
                background: theme.palette.primary.main,
                color: theme.palette.background.default,
                marginBottom: '17px',
              }}
            >
              <ShareIcon />
            </IconButton>
            <Typography variant="body2" color="secondary">
              {t('videoList.mobileSettingsModal.shareBtn')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <IconButton
              style={{
                background: theme.palette.primary.main,
                color: theme.palette.background.default,
                marginBottom: '17px',
              }}
            >
              <EqualizerIcon />
            </IconButton>
            <Typography variant="body2" color="secondary">
              {t('videoList.mobileSettingsModal.statisticsBtn')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <IconButton
              style={{
                background: theme.palette.primary.main,
                color: theme.palette.background.default,
                marginBottom: '17px',
              }}
              onClick={() => openDeleteModal(selectedVideoItem)}
            >
              <TrashIcon />
            </IconButton>
            <Typography variant="body2" color="secondary">
              {g('common.deleteBtn')}
            </Typography>
          </Box>
        </Box>
        <Button variant="contained" fullWidth onClick={() => setSelectedVideoItem(null)}>
          {g('common.cancelBtn')}
        </Button>
      </Box>
    </Modal>
  )
}

export default VideoListItemMobileSettingsModal
