import { SchedulerItem } from './SchedulerItem'
import { UploadScheduler, WorkerEvent } from './UploadScheduler'
import { config } from '../../config'
import { RecordingChunkData, RecordingChunkType, RecordingIds } from './index.types'
import IndexedDB, { IndexedRecordingChunk } from 'common/services/IndexedDB'
import { captureException } from '@sentry/react'

export class RecordingChunk extends SchedulerItem {
  ids: RecordingIds

  key: string
  owner: string
  type: RecordingChunkType
  idx: number
  chunk: Blob

  muxed = false
  fragmented = true
  source = 'web'

  request = new XMLHttpRequest()

  lastProgress = 0
  timeoutHandler?: ReturnType<typeof setTimeout>

  constructor(ids: RecordingIds, data: RecordingChunkData, scheduler: UploadScheduler) {
    super(scheduler, data.chunk.size)
    const { owner, type, idx, chunk } = data
    this.ids = { ...ids }

    this.key = `${ids.localId}_${type}_${idx}`
    this.owner = owner
    this.type = type
    this.idx = idx
    this.chunk = chunk
    this.log('Created')
  }

  async init() {
    await this.saveToIndexedDb()
  }

  async saveToIndexedDb() {
    const chunk = await IndexedDB.recordingChunk.where('key').equals(this.key).first()

    const obj: IndexedRecordingChunk = {
      ...this.ids,
      key: this.key,
      owner: this.owner,
      type: this.type,
      idx: this.idx,
      chunk: this.chunk,
    }

    if (chunk) {
      await IndexedDB.recordingChunk.update(chunk, obj)
    } else {
      await IndexedDB.recordingChunk.add(obj)
    }
  }

  async deleteFromIndexedDb() {
    await IndexedDB.recordingChunk.where('key').equals(this.key).delete()
  }

  async setSuccess() {
    await this.deleteFromIndexedDb()
    super.setSuccess()
  }

  resetTimeout(setNew = true) {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler)
    }

    if (setNew) {
      this.timeoutHandler = setTimeout(this.timeout.bind(this), 60000)
    }
  }

  timeout() {
    this.request.abort()
  }

  requestOnProgress(e: ProgressEvent) {
    this.setUploading(e.loaded)

    if (this.lastProgress !== e.loaded) {
      this.lastProgress = e.loaded
      this.resetTimeout()
    }
  }

  requestOnLoad(successCallback: () => void, failureCallback: () => void) {
    if (this.request.status >= 200 && this.request.status < 300) {
      this.resetTimeout(false)
      successCallback()
    } else {
      failureCallback()
    }
  }

  singleUpload() {
    return new Promise<void>((resolve, reject) => {
      const url = `${config.CHUNKS_UPLOADING_HOST}/uploads?sequence=${this.idx}&media_type=${this.type}&localId=${this.ids.localId}&user_id=${this.owner}&entity_id=${this.ids.entityId}&muxed=${this.muxed}&fragmented=${this.fragmented}&source=${this.source}`

      this.request = new XMLHttpRequest()
      this.request.open('POST', url)

      this.request.upload.onprogress = this.requestOnProgress.bind(this)

      const error = () => {
        this.resetTimeout(false)
        reject()
      }

      this.request.onload = (response) => this.requestOnLoad(resolve, error)

      this.request.onerror = (test) => {
        error()
      }

      this.request.onabort = () => {
        error()
      }

      this.request.ontimeout = () => {
        error()
      }

      this.request.setRequestHeader('Content-Type', 'video/webm')
      this.request.send(this.chunk)
    })
  }

  setError() {
    // Sentry.io capture error
    captureException(new Error('Chunk uploading error'), {
      tags: {
        entityId: this.ids.entityId,
        localId: this.ids.localId,
        sequence: this.idx,
        media_type: this.type,
      },
    })

    super.setError()
  }

  abort() {
    this.resetTimeout(false)
    this.subs.unsubscribe()
    this.request.abort()
    this.status = 'ABORT'
    this.event.next({ type: 'ABORT' })
    this.log('Aborting...')
  }

  log(...args: any[]) {
    console.log(new Date().toISOString(), `[VideoChunkItem ${this.key}]:`, ...args)
  }
}
