import { Auth } from 'aws-amplify'
import { Storage } from '@aws-amplify/storage'
import { S3FileAccessLevel } from '../../../../../../../../API'
import { saveAs } from 'file-saver'

const useDownloadAttachment = (fileObject: any) => {
  const downloadFile = async () => {
    const credentials = await Auth.currentCredentials()
    const fileUrl = await Storage.get(`${fileObject?.key}`, {
      level: fileObject?.accessLevel as S3FileAccessLevel,
      identityId: fileObject?.identityId as string,
      credentials: credentials,
    })
    saveAs(fileUrl)
  }
  return {
    downloadFile,
  }
}

export default useDownloadAttachment
