import * as React from 'react'
import { useContext } from 'react'
import moment from 'moment'
import { screensightPost, VideoStatus } from '../../../../../../../API'
import { isEmoji } from '../../../../../../../common/utils/isEmoji'
import { IParticipantData } from '../../../../../common/hooks/useChatParticipants'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'
import VideoPost from '../components/PostList/VideoPost'
import SinglePostLayout from '../layouts/SinglePostLayout'
import CommentPost from '../components/PostList/CommentPost'
import FilePost from '../components/PostList/FilePost'
import TextPost from '../components/PostList/TextPost'
import ReplyPost from '../components/PostList/ReplyPost'
import ReactionPost from '../components/PostList/ReactionPost'
import RemovedVideoCommentPost from '../components/PostList/RemovedVideoCommentPost'

import ReplyToRemovedPost from '../components/PostList/ReplyToRemovedPost'
import VideoItemRemoved from '../components/PostList/VideoItemRemoved'

interface ISinglePostProps {
  owner: boolean
  post: screensightPost | null
  lastSeen?: boolean
  lastUnseen?: boolean
  participantData: IParticipantData
  mergedInside: boolean
  firstMerged: boolean
  lastMerged: boolean
  isLastPost: boolean
}
const SinglePost = (props: ISinglePostProps) => {
  const auth = useContext(AuthContext)
  const { post, isLastPost, lastMerged, lastSeen, lastUnseen, participantData, mergedInside, firstMerged, owner } =
    props

  if (
    (auth.isAuthenticated &&
      props.post?.file?.status === VideoStatus.init &&
      props.post?.customerId !== auth.user?.id) ||
    (props.post?.customerId !== auth.user?.id &&
      props.post?.postType === 'video' &&
      props.post?.video?.status !== VideoStatus.ok) ||
    (!auth.isAuthenticated &&
      props.post?.file?.status === VideoStatus.init &&
      props.post?.customerId !== sessionStorage.getItem('guestCustomerId') &&
      props.post?.customerId !== localStorage.getItem('guestCustomerId'))
  ) {
    return <></>
  }
  return (
    <SinglePostLayout
      videoId={
        post?.postType && ['video', 'shared', 'email_video'].includes(post?.postType)
          ? post?.video?.id
          : post?.postType === 'reply' && post.referencePost?.postType === 'comment'
          ? post?.referencePost.comment?.videoId
          : post?.referencePost?.video?.id
      }
      postId={post?.id || ''}
      owner={owner}
      postTimestamp={moment(post?.createdAt).format('hh:mm A')}
      ownerData={owner ? participantData.author : participantData.partner}
      mergedInside={mergedInside}
      firstMerged={firstMerged}
      lastMerged={lastMerged}
      isLastPost={isLastPost}
      lastSeen={lastSeen}
      lastUnseen={lastUnseen}
      edited={
        (post?.postType === 'text' || post?.postType === 'comment' || post?.postType === 'reply') &&
        post?.createdAt !== post?.updatedAt
      }
      seenAt={post?.engagement?.items[0]?.seenAt}
      participantData={participantData}
      content={post?.postType === 'text' ? post?.text?.content || '' : post?.comment?.content || ''}
      richContent={post?.postType === 'text' ? post?.text?.richContent || '' : post?.comment?.richContent || ''}
      postType={post?.postType}
      file={post?.file}
      videoCommentTimestamp={post?.comment?.videoTimestamp || 0}
      engagement={post?.engagement}
    >
      {post?.postType === 'text' && !isEmoji(post?.text?.richContent || post?.text?.content || '') && (
        <TextPost
          postId={post.id}
          owner={owner}
          content={post?.text?.content || ''}
          richContent={post?.text?.richContent || ''}
          mergedInside={mergedInside}
          firstMerged={firstMerged}
          lastMerged={lastMerged}
        />
      )}

      {post?.postType === 'text' && isEmoji(post?.text?.richContent || post?.text?.content || '') && (
        <ReactionPost
          emoji={post?.text?.richContent?.replace('<p>', '')?.replace('</p>', '') || post?.text?.content || ''}
          owner={owner}
        />
      )}

      {post?.postType === 'file' && <FilePost file={post.file} postId={post.id} owner={owner} />}

      {post?.postType === 'comment' && post?.referencePost?.video && post?.comment && (
        <CommentPost
          referencePostId={post.referencePostId}
          videoData={post?.referencePost?.video}
          owner={owner}
          comment={post.comment}
        />
      )}

      {post?.postType === 'comment' && !post?.referencePost?.video && post?.comment && (
        <RemovedVideoCommentPost owner={owner} comment={post.comment} />
      )}

      {post?.postType === 'reply' && !post.referencePost && post.comment && (
        <ReplyToRemovedPost owner={owner} reply={post.comment} />
      )}

      {post?.postType === 'reply' && post.referencePost && post.comment && (
        <ReplyPost postId={post.id} owner={owner} referencePost={post?.referencePost} reply={post.comment} />
      )}

      {post?.postType === 'shared' && post?.referencePost?.video && (
        <VideoPost
          videoData={post.referencePost.video}
          partnerId={participantData.partner?.id || ''}
          owner={owner}
          postId={post.id}
          engagement={post.engagement}
          reactions={post.referencePost.reactions?.items}
        />
      )}
      {post?.postType === 'video' && post.video && (
        <VideoPost
          partnerId={participantData.partner?.id || ''}
          videoData={post.video}
          owner={owner}
          postId={post.id}
          engagement={post.engagement}
          reactions={post.reactions?.items}
        />
      )}

      {(post?.postType === 'email_video' || post?.postType === 'shared') && !post?.referencePost && (
        <VideoItemRemoved owner={owner} />
      )}

      {post?.postType === 'email_video' && post?.referencePost?.video && (
        <VideoPost
          isEmailPost={true}
          partnerId={participantData.partner?.id || ''}
          videoData={post?.referencePost?.video}
          owner={owner}
          postId={post.id}
          emailVideoData={post.emailMessage}
          engagement={post.engagement}
          reactions={post.referencePost.reactions?.items}
        />
      )}
    </SinglePostLayout>
  )
}

export default SinglePost
