import React from 'react'

const TextSpeedIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8902 1C10.1102 1 9.47826 1.65333 9.47826 2.45833C9.47826 3.26333 10.1102 3.91667 10.8902 3.91667C11.6719 3.91667 12.3043 3.26333 12.3043 2.45833C12.3043 1.65333 11.6719 1 10.8902 1ZM5.52174 3.91667C5.37184 3.9167 5.2281 3.97817 5.12211 4.08756L3.42646 5.83756C3.32046 5.94694 3.26089 6.0953 3.26087 6.25C3.26087 6.40471 3.32042 6.55308 3.42642 6.66248C3.53242 6.77187 3.67618 6.83333 3.82609 6.83333C3.97598 6.83331 4.11973 6.77184 4.22571 6.66243C4.22757 6.66055 4.22941 6.65865 4.23123 6.65674L5.64207 5.20068L6.95355 5.42627L5.74363 7.50667L5.74473 7.51123C5.60513 7.73756 5.52174 8.00408 5.52174 8.29167C5.52174 8.67958 5.67124 9.02952 5.91033 9.29085V9.29655L8.02327 11.5501L8.34783 14.4167C8.34783 14.5714 8.40737 14.7197 8.51337 14.8291C8.61937 14.9385 8.76314 15 8.91304 15C9.06295 15 9.20671 14.9385 9.31271 14.8291C9.41871 14.7197 9.47826 14.5714 9.47826 14.4167V10.9167C9.4782 10.7968 9.44235 10.6798 9.37559 10.5817L8.2275 8.88867L9.84035 6.12923L9.83704 6.12354C9.96535 5.90362 10.0435 5.64975 10.0435 5.375C10.0435 4.57 9.41156 3.91725 8.63043 3.91667H8.62933H5.52174ZM10.7743 6.17708L10.0435 7.41667L10.166 7.48047L10.8869 7.91911C10.9738 7.97204 11.073 7.99997 11.1739 8H13.4348C13.5847 8 13.7285 7.93854 13.8345 7.82915C13.9405 7.71975 14 7.57138 14 7.41667C14 7.26196 13.9405 7.11358 13.8345 7.00419C13.7285 6.89479 13.5847 6.83333 13.4348 6.83333H11.5437L10.7743 6.17708ZM4.88366 9.52213L4.03473 10.568L1.56522 10.9167C1.41531 10.9167 1.27155 10.9781 1.16555 11.0875C1.05955 11.1969 1 11.3453 1 11.5C1 11.6547 1.05955 11.8031 1.16555 11.9125C1.27155 12.0219 1.41531 12.0833 1.56522 12.0833H4.3913C4.46745 12.0832 4.54279 12.0672 4.61278 12.0362C4.68278 12.0053 4.74601 11.9601 4.79866 11.9033L4.8373 11.8612L6.02955 10.7412L4.88366 9.52213Z"
        fill="white"
      />
    </svg>
  )
}

export default TextSpeedIcon
