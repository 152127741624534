import React from 'react'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import Logo from '../../../../../icons/Logo'
import { useLocation } from 'react-router'

const GuestMenu = () => {
  const location = useLocation()

  if (location.pathname.includes('chat')) {
    return (
      <Box
        width="60px"
        height="100%"
        position="fixed"
        left="0"
        top="0"
        boxShadow="0px 0px 3px rgba(0, 0, 0, 0.1)"
        zIndex={101}
        bgcolor="background.default"
      >
        <Box position="relative" top="0" left="0" height="100%" width="60px" overflow="hidden">
          <Box position="absolute" top="26px" display="flex" justifyContent="center" width="100%">
            <Link to="/">
              <Logo />
            </Link>
          </Box>
        </Box>
      </Box>
    )
  }
  return <div />
}

export default GuestMenu
