import React, { useContext } from 'react'
import { Box, CircularProgress, Grid } from '@mui/material'
import CurrentPlan from '../blocks/CurrentPlan'
import { useMutation, useQuery } from '@apollo/client'
import {
  BillingInformation,
  DeleteBillingPaymentMethodMutation,
  GetBillingInformationQuery,
} from '../../../../../../API'
import gql from 'graphql-tag'
import { getBillingInformation } from '../../../../../../graphql/queries'
import PaymentInfo from '../blocks/PaymentInfo'
import { deleteBillingPaymentMethod } from '../../../../../../graphql/mutations'
import CognitoApolloClient from '../../../../../../common/clients/CognitoApolloClient'
import ToastContext from '../../../../../../common/providers/ToastProvider/ToastContext'
import ExplorePlans from '../blocks/ExplorePlans'
import { useTranslation } from 'react-i18next'

const Content = () => {
  const toastContext = useContext(ToastContext)
  const { t } = useTranslation('settings')
  const {
    data: billingInformationData,
    loading,
    refetch,
  } = useQuery<GetBillingInformationQuery, BillingInformation>(gql(getBillingInformation), {
    fetchPolicy: 'cache-and-network',
  })

  const [deletePayment, { loading: deletePaymentLoading }] = useMutation<DeleteBillingPaymentMethodMutation, any>(
    gql(deleteBillingPaymentMethod),
    {
      client: CognitoApolloClient,
    },
  )

  const deletePaymentMethod = async () => {
    try {
      await deletePayment()
      await refetch()
      toastContext.open({
        type: 'INFO',
        text: t('billing.confirmation.cardRemovedSuccessfully'),
      })
    } catch (e: any) {
      toastContext.open({
        type: 'ERROR',
        text: e?.message || t('billing.confirmation.paymentMethodRemovedError'),
      })
      console.error('Error during deleting payment method', e)
    }
  }

  if (loading) {
    return (
      <Box height={'100%'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={7} lg={8}>
          <CurrentPlan
            pricePlanName={billingInformationData?.getBillingInformation?.subscription?.product?.name || ''}
            currentPrice={billingInformationData?.getBillingInformation?.subscription?.price?.amount || 0}
            paymentMethod={billingInformationData?.getBillingInformation?.paymentMethod}
          />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <ExplorePlans />
        </Grid>
      </Grid>
      {billingInformationData?.getBillingInformation?.paymentMethod && (
        <PaymentInfo
          name={billingInformationData.getBillingInformation.paymentMethod?.nameOnCard || ''}
          card4digits={billingInformationData.getBillingInformation.paymentMethod?.lastFourDigits || ''}
          cardBrand={billingInformationData.getBillingInformation.paymentMethod?.brand || ''}
          deletePaymentMethod={deletePaymentMethod}
          deletePaymentLoading={deletePaymentLoading}
        />
      )}
    </>
  )
}

export default Content
