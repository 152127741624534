import { useContext } from 'react'
import { AuthContext } from '../../../../providers/AuthStatusProvider'
import { useHistory } from 'react-router-dom'

/*
 * This hook is used to log the user out
 */
const useLogout = () => {
  const auth = useContext(AuthContext)
  const history = useHistory()

  const logout = async () => {
    await auth.logout()
    history.push('/sign-up')
  }

  return [logout]
}

export default useLogout
