import * as React from 'react'
import { Box } from '@mui/material'
import './styles.scss'

interface IReactionPost {
  emoji: string
  owner: boolean
}

const ReactionPost = (props: IReactionPost) => {
  return (
    <Box>
      <Box className="single--emoji" display="flex" justifyContent={props.owner ? 'end' : 'start'}>
        {props.emoji}
      </Box>
    </Box>
  )
}

export default ReactionPost
