import React from 'react'
import { Box, Typography } from '@mui/material'
import Button from '../../../../../../components/_refactored/Button'
import Modal from '../../../../../../components/_refactored/Modal'
import CameraModal from '../../../../../../containers/VideoRecorder/CameraModal'
import useInitialPermissionsModal from './hooks/useInitialPermissionsModal'
import AudioPreview from './AudioPreview'
import IconPermissionsCamera from '../../../../../icons/PermissionsCamera'
import IconPermissionsAudio from '../../../../../icons/PermissionsAudio'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/Check'

type Props = {
  onCloseModal: () => void
}
const InitialPermissionsModal = (props: Props) => {
  const { mediaStream, closeModal } = useInitialPermissionsModal(props.onCloseModal)
  const { t: g } = useTranslation('general')

  return (
    <Modal open={true} boxStyle={{ width: '500px' }}>
      <Box margin="0 auto" display="flex" flexDirection="column" alignItems="center">
        <CameraModal.Preview cameraStream={mediaStream} initialModal />
        {mediaStream && <AudioPreview mediaStream={mediaStream} />}

        <Box marginBottom="15px" marginTop="20px">
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconPermissionsCamera />
            <Typography align="center" variant="body2" style={{ color: '#7A7A86', margin: '0 10px' }}>
              {g('recordControl.initialPermissionsModal.camera')}
            </Typography>
            <IconPermissionsAudio />
            <Typography align="center" variant="body2" style={{ color: '#7A7A86', margin: '0 10px' }}>
              {g('recordControl.initialPermissionsModal.microphone')}
            </Typography>
          </Box>
          <Typography variant="body2" align="center" style={{ color: '#7A7A86', marginTop: '15px' }}>
            {g('recordControl.initialPermissionsModal.description')}
          </Typography>
          <Box display="flex" flexDirection="row">
            <Box margin="20px auto 0 auto">
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  closeModal()
                }}
              >
                <CheckIcon sx={{ marginRight: '5px' }} /> Done
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default InitialPermissionsModal
