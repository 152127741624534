import React, { useContext, useState } from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import Icon from '../../../../common/icons'
import Button from '../../../../components/_refactored/Button'
import { useTranslation } from 'react-i18next'
import VideoLimitModal from './VideoLimitModal'
import { useSelector } from 'react-redux'
import { RootState } from '../../../Chat/common/redux/store/store'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import StarIconButton from '../../../../containers/VideoList/VideoNew/view/StarIconButton'
interface INewVideoButton {
  handleNewVideoBtnClick: () => void
}

const NewVideoButton = (props: INewVideoButton) => {
  const auth = useContext(AuthContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation('library')
  const videoLimitReached = useSelector((state: RootState) => state.chat.videosLimitReached)
  const [isVideoLimitModalOpen, setIsVideoLimitModalOpen] = useState(false)

  const hideVideoLimitModal = () => {
    setIsVideoLimitModalOpen(false)
  }

  const buttonStyles = {
    fontSize: '16px',
    paddingLeft: '20px',
    paddingRight: '20px',
    background: videoLimitReached || !auth.isInitialized ? '#2483F780!important' : theme.palette.primary.main,
    color: '#FFF',
    cursor: 'pointer',
  }

  return (
    <Box position="relative">
      {videoLimitReached && (
        <>
          {isVideoLimitModalOpen && <VideoLimitModal hideModal={hideVideoLimitModal} />}
          <StarIconButton onClick={() => setIsVideoLimitModalOpen(true)} />
        </>
      )}
      <Button
        fullWidth={isMobile}
        variant="contained"
        size={isMobile ? 'medium' : 'large'}
        onClick={() => {
          if (videoLimitReached) {
            setIsVideoLimitModalOpen(true)
          } else {
            props.handleNewVideoBtnClick()
          }
        }}
        sx={buttonStyles}
      >
        <Box marginRight="10px">
          <Icon.SmallCamera />
        </Box>
        {t('videoList.newVideoBtn')}
      </Button>
    </Box>
  )
}

export default NewVideoButton
