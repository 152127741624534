import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Typography } from '@mui/material'
import RemoveTwoToneIcon from '@mui/icons-material/RemoveTwoTone'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import Button from '../../../../../../../components/_refactored/Button'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'

type IIncrementProps = {
  handleDecrement: any
  handleIncrement: any
  daysCount: any
  isCustom: boolean
  handleSubmit: () => void
  toggleCustomizeRecent: () => void
}
const Increment = (props: IIncrementProps) => {
  const { t } = useTranslation('chat')

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      color={props.isCustom ? theme.palette.text.primary : theme.palette.text.secondary}
    >
      <Box display="flex">
        <IconButton
          onClick={props.handleDecrement}
          disabled={!props.isCustom}
          style={{
            width: '24px',
            height: '24px',
            boxShadow: 'none',
            backgroundColor: '#EEF0F6',
            color: props.daysCount > 1 ? theme.palette.text.primary : theme.palette.text.secondary,
          }}
        >
          <RemoveTwoToneIcon fontSize="small" color={props.isCustom ? 'inherit' : 'secondary'} />
        </IconButton>
        <Typography margin={props.daysCount > 1 ? '0 15px' : '0 20px'}>
          {props.daysCount}
          {props.daysCount > 1 ? t('channelsSidebar.recentChannels.days') : t('channelsSidebar.recentChannels.day')}
        </Typography>
        <IconButton
          onClick={props.handleIncrement}
          disabled={!props.isCustom}
          style={{
            width: '24px',
            height: '24px',
            boxShadow: 'none',
            backgroundColor: '#EEF0F6',
            color: props.daysCount >= 7 ? theme.palette.text.secondary : theme.palette.text.primary,
          }}
        >
          <AddTwoToneIcon fontSize="small" color={props.isCustom ? 'inherit' : 'secondary'} />
        </IconButton>
      </Box>
      <Button
        variant="contained"
        onClick={async () => {
          props.handleSubmit()
          props.toggleCustomizeRecent()
        }}
      >
        {t('channelsSidebar.recentChannels.applyBtn')}
      </Button>
    </Box>
  )
}
export default Increment
