import React, { useEffect } from 'react'
import { Box, LinearProgress, Modal } from '@mui/material'
import { AccessType } from '../../../../../../../../API'
import { Skeleton } from '@mui/material'
import '../../../common/styles/chatPlayer.style.scss'
import PlayerReactions from './ui/blocks/PlayerReactions'
import VideoPlayer from './ui/components/VideoPlayer'
import VideoAccessModal from '../../../../../../../../components/_refactored/VideoAccessModal'
import './common/styles/chatPlayer.style.scss'
import ChatEditor from '../../blocks/ChatEditor'
import useChatPlayer from './common/hooks/useChatPlayer'
import PlayerTopOverlay from './ui/components/PlayerTopOverlay'

const ChatPlayer = () => {
  const state = useChatPlayer()

  if (!state?.videoIdQueryParam || !state?.postIdQueryParam) return <></>

  if (state?.videoData?.getVideo?.accessType === AccessType.protected && !state?.privateVideoData?.filePresignedUrl) {
    return (
      <VideoAccessModal
        videoId={state?.videoData?.getVideo?.id}
        onSuccess={state?.handleVerifyAccess}
        onCancel={() => {
          state?.history.goBack()
        }}
      />
    )
  }

  return (
    <Modal disablePortal open={true} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box width="90vw" maxHeight="100vh" bgcolor="#F8F9FB" borderRadius="10px">
        <Box
          width="100%"
          padding="10px"
          height="100vh"
          zIndex={100}
          className="chat__player"
          boxShadow="0px 1px 7px rgba(0, 0, 0, 0.11)"
          {...{ ref: state?.wrapperRef }}
          sx={{
            '& .video-js': {
              maxHeight: '100%!important',
              height: '100%!important',
            },
            '& .video-js.vjs-fluid': {
              width: '100%!important',
              maxWidth: '100%!important',
              maxHeight: '100%!important',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%!important',
            },
            '& video': {
              maxHeight: '100%!important',
              height: '100%!important',
            },
          }}
        >
          {(state?.videoData?.getVideo?.filePresignedUrl || state?.privateVideoData?.filePresignedUrl) &&
          state?.videoData?.getVideo?.id &&
          !state?.chatPlayerLoader ? (
            <>
              <Box width="100%" height="calc(100vh - 170px)" position="relative" {...{ ref: state?.playerWrapperRef }}>
                {!state?.auth.isAuthenticated && (
                  <PlayerTopOverlay
                    videoId={state?.videoData?.getVideo?.id}
                    accessId={state?.videoData?.getVideo?.accessId}
                  />
                )}
                <VideoPlayer
                  playerRef={state?.playerRef}
                  videoId={state?.videoData?.getVideo?.id as string}
                  videoOwnerId={state?.videoData?.getVideo?.customerId}
                  visible
                  videoJsOptions={{
                    sources: [
                      {
                        src:
                          state?.videoData?.getVideo?.filePresignedUrl ||
                          state?.privateVideoData?.filePresignedUrl ||
                          '',
                        type: 'video/mp4',
                      },
                    ],
                    autoplay: true,
                    controls: true,
                    fluid: true,
                    preload: 'auto',
                    playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 3, 4],
                  }}
                />
              </Box>
              {state?.auth.isAuthenticated && (
                <PlayerReactions
                  videoId={state?.videoData?.getVideo?.id}
                  accessId={state?.videoData?.getVideo?.accessId}
                  playerRef={state?.playerRef}
                  wrapperRef={state?.wrapperRef}
                  postId={state?.postIdQueryParam as string}
                  videoDuration={state?.videoData?.getVideo?.duration || state?.privateVideoData?.duration}
                />
              )}
              <Box
                width="calc(90vw - 20px)"
                bgcolor="white"
                borderRadius="4px"
                position={'absolute'}
                bottom={10}
                zIndex={20}
                height={state.quillExpanded ? 'auto' : '110px'}
                maxHeight="300px"
              >
                <ChatEditor videoPlayerEditor toggleHeight={state.toggleHeight} quillExpanded={state.quillExpanded} />
              </Box>
            </>
          ) : (
            <Box width="100%" height="100%" position="relative">
              <Skeleton
                variant="rectangular"
                sx={{ maxWidth: '100%', width: '100%', height: '100%', maxHeight: '100%', borderRadius: '6px' }}
              />
              <Box
                position="absolute"
                width="100px"
                height="10px"
                left="50%"
                top="50%"
                sx={{ transform: 'translate(-50%, -50%)' }}
              >
                <LinearProgress />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default ChatPlayer
