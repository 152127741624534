import * as React from 'react'
import { useTheme } from '@mui/material'
import { Resizable } from 're-resizable'
import { useDispatch } from 'react-redux'
import useResize from './common/hooks/useResize'
import './styles.scss'
import { updateRightSidePanelParams } from '../../../../../../common/redux/store/chat-actions'

type ResizableContainerProps = {
  children: React.ReactNode
  maxWidth?: string
}
const ResizableRightSidePanelContainer = (props: ResizableContainerProps) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { sidebarParams } = useResize()

  return (
    <Resizable
      className="resizable__container"
      size={{ width: sidebarParams.width, height: sidebarParams.height }}
      onResize={(e, direction, ref, delta) => {
        if (ref.getBoundingClientRect().width < 120) {
          dispatch(
            updateRightSidePanelParams({
              width: 44,
              height: ref.style.height,
              x: window.innerWidth - 44,
              y: ref.getBoundingClientRect().y,
            }),
          )
          return
        }
        dispatch(
          updateRightSidePanelParams({
            width: Number(ref.style.width.replace('px', '')),
            height: ref.style.height,
            x: ref.getBoundingClientRect().x,
            y: ref.getBoundingClientRect().y,
          }),
        )
      }}
      maxWidth={'294px'}
      minWidth={44}
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        pointerEvents: 'auto',
      }}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
    >
      {props.children}
    </Resizable>
  )
}
export default ResizableRightSidePanelContainer
