import * as React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

type ITimeDividerProps = {
  isLastDate: boolean
  currentDate: string
  prevDate: string
  hidden?: boolean
}

const TimeDivider = (props: ITimeDividerProps) => {
  const { t } = useTranslation('chat')
  const momentCurrentDate = moment(props.currentDate, 'YYYY-MM-DD')
  const momentPrevDate = moment(props.prevDate, 'YYYY-MM-DD')

  const dividerDate = momentCurrentDate.startOf('day').isSame(moment(new Date()).startOf('day'))
    ? t('chatBody.chatTimeDivider.today')
    : momentCurrentDate.startOf('day').isSame(moment().subtract(1, 'day').startOf('day'))
    ? t('chatBody.chatTimeDivider.yesterday')
    : momentCurrentDate.format('LL')

  if (props.hidden) return <></>

  return (
    <>
      {(momentPrevDate && moment.duration(momentPrevDate.diff(momentCurrentDate)).asDays() < 0) || props.isLastDate ? (
        <Box padding="30px 0">
          <Box height="1px" width="100%" bgcolor="background.paper" position="relative">
            <Typography
              variant="body1"
              color="textSecondary"
              align="center"
              style={{
                position: 'absolute',
                left: '50%',
                top: '-8.5px',
                fontSize: '12px',
                padding: '0 20px',
                background: 'white',
                transform: 'translateX(-50%)',
                width: '200px',
              }}
            >
              {dividerDate}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </>
  )
}

export default TimeDivider
