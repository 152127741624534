import React, { memo, MutableRefObject } from 'react'
import { GetApplicationConfigurationQuery, VideoStatus } from '../../../../API'
import { VideoJsPlayer } from 'video.js'
import { Box, CircularProgress, useMediaQuery } from '@mui/material'
import VideoPlayer from '../../../../components/VideoPlayer'
import VideoConverting from '../../../../pages/VideoItem/ui/components/VideoConverting'
import useVideoPlayerContainer from '../../../../containers/VideoItem/VideoPlayerContainer/hooks/useVideoPlayerContainer'
import useGetVideoFragment from '../../../../common/api/hooks/useGetVideoFragment'
import { theme } from '../../../../common/providers/MuiThemeProvider'
import './styles.scss'

export interface IVideoPlayerLibContainer {
  videoSource: string | null | undefined
  videoOwnerId: string | undefined
  videoId: string | undefined
  playerRef: MutableRefObject<VideoJsPlayer | null>
  appConfigData: GetApplicationConfigurationQuery | undefined
  poster?: string | undefined
  authorized: boolean
}

const VideoPlayerLibContainer = (props: IVideoPlayerLibContainer) => {
  const state = useVideoPlayerContainer(props.videoId, props.appConfigData, props.playerRef, props.videoOwnerId)
  const cacheVideo = useGetVideoFragment(props.videoId, '{customerId, status, filePresignedUrl}')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const shouldAutoplay = !props.authorized && isMobile

  return (
    <Box className="library__video__player">
      {!state.visible && cacheVideo?.status === VideoStatus.ok && (
        <Box alignItems="center" display="flex" maxWidth="100%" height="350px" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {props.videoSource && props.poster && cacheVideo?.status === VideoStatus.ok ? (
        <Box className={state.visible ? 'video-js-ready' : 'video-js-hidden'} position="relative">
          <VideoPlayer
            mobileAutoplay={shouldAutoplay}
            onPlayerReady={state.onPlayerReady}
            videoId={props.videoId || ''}
            visible={state.visible}
            videoJsOptions={{
              sources: [{ src: props.videoSource || '', type: 'video/mp4' }],
              autoplay: shouldAutoplay,
              controls: true,
              fluid: true,
              playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 3, 4],
              ...(props?.poster && { poster: props.poster }),
            }}
          />
        </Box>
      ) : (
        <VideoConverting />
      )}
    </Box>
  )
}

export default VideoPlayerLibContainer
