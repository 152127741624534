import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { Typography } from '@mui/material'
import { theme } from '../../../../common/providers/MuiThemeProvider'
import { useTranslation } from 'react-i18next'

type Props = {
  handleChange?: any
  short?: string
  flag: any
}
const LanguageItem = (props: Props) => {
  const { t: g } = useTranslation('general')
  return (
    <MenuItem onClick={() => props.handleChange(props.short)}>
      {<props.flag />}
      <Typography component={'span'} sx={{ color: theme.palette.text.secondary, padding: '0 6px 0 6px' }}>
        {props.short?.toUpperCase()} -
      </Typography>
      <Typography component={'span'}>
        {/*//@ts-ignore*/}
        {g(`menu.languages.${props.short}`)}
      </Typography>
    </MenuItem>
  )
}

export default LanguageItem
