import { useQuery } from '@apollo/client'
import { GetContactQuery, GetContactQueryVariables } from '../../../../API'
import gql from 'graphql-tag'
import { getContact } from '../../../../graphql/queries'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import { useContext, useState } from 'react'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import { useParams } from 'react-router'

const useSingleContact = () => {
  const auth = useContext(AuthContext)
  const { id: contactId } = useParams() as { id: string }
  const [contactToDelete, setContactToDelete] = useState<string | null>(null)
  const { loading: contactQueryLoading, data: contactQueryData } = useQuery<GetContactQuery, GetContactQueryVariables>(
    gql(getContact),
    {
      variables: {
        id: contactId,
      },
      skip: auth.user?.pending || !auth.isAuthenticated,
      client: CognitoApolloClient,
    },
  )

  return {
    loading: contactQueryLoading,
    contact: contactQueryData,
    setContactToDelete,
    contactToDelete,
  }
}

export default useSingleContact
