import React from 'react'

interface IProps {
  color?: string
}

const VideosIcon = (props: IProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.9997" cy="4.66667" r="3.01667" stroke={props?.color || '#7A7A86'} strokeWidth="1.3" />
      <circle cx="4.05556" cy="5.27778" r="2.40556" stroke={props?.color || '#7A7A86'} strokeWidth="1.3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.81395 9.55554C3.068 9.55554 2.3514 9.82414 1.8234 10.3017C1.29642 10.7801 1 11.4294 1 12.1054V20.4502C1 21.1261 1.29642 21.7754 1.8234 22.2539C2.3514 22.7314 3.068 23 3.81395 23H14.5581C15.3041 23 16.0207 22.7314 16.5487 22.2539C17.0757 21.7754 17.3721 21.1261 17.3721 20.4502V20.1405L21.1913 21.7124C21.5873 21.8756 22.0481 21.8451 22.4144 21.6309C22.7797 21.4177 23 21.0507 23 20.6566V11.8989C23 11.5048 22.7797 11.1379 22.4144 10.9246C22.0481 10.7104 21.5873 10.6799 21.1913 10.8431L17.3721 12.415V12.1054C17.3721 11.4294 17.0757 10.7801 16.5487 10.3017C16.0207 9.82414 15.3041 9.55554 14.5581 9.55554H3.81395ZM3.81395 10.9463H14.5581C14.8979 10.9463 15.223 11.068 15.4635 11.285C15.7029 11.5029 15.8372 11.7975 15.8372 12.1054V13.4962V19.0594V20.4502C15.8372 20.758 15.7029 21.0526 15.4635 21.2705C15.223 21.4875 14.8979 21.6092 14.5581 21.6092H3.81395C3.47423 21.6092 3.14908 21.4875 2.90861 21.2705C2.66917 21.0526 2.53488 20.758 2.53488 20.4502V12.1054C2.53488 11.7975 2.66917 11.5029 2.90861 11.285C3.14908 11.068 3.47423 10.9463 3.81395 10.9463ZM21.4651 12.2575V20.2981L17.3721 18.6121V13.9435C17.3721 13.9435 20.404 12.6942 21.4651 12.2575Z"
        fill={props?.color || '#7A7A86'}
      />
    </svg>
  )
}

export default VideosIcon
