import React from 'react'

const PersonIcon = () => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0C7.68598 0 4.99998 2.686 4.99998 6V7C4.99998 10.314 7.68598 13 11 13C14.314 13 17 10.314 17 7V6C17 2.686 14.314 0 11 0ZM10.998 16C6.99203 16 1.85203 18.1668 0.37303 20.0898C-0.54097 21.2788 0.329108 23 1.82811 23H20.1699C21.6689 23 22.539 21.2788 21.625 20.0898C20.146 18.1678 15.004 16 10.998 16Z"
        fill="#BCBCC3"
      />
    </svg>
  )
}

export default PersonIcon
