import { useMutation } from '@apollo/client'
import { CreatePostMutation, CreatePostMutationVariables } from '../../../../../../../../API'
import gql from 'graphql-tag'
import { createPost } from '../../../../../../../../common/api/graphql/mutations'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import { useChatContext, useChatDispatch } from '../../contexts/ChatContext'
import { useParams } from 'react-router'
import useUpdateChannelsCache from './useUpdateChannelsCache'

const useSendSharedPost = () => {
  const auth = useContext(AuthContext)
  const chatContext = useChatContext()
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const chatDispatcher = useChatDispatch()
  const [updateChannelLatestAt] = useUpdateChannelsCache()
  const [createPostMutation] = useMutation<CreatePostMutation, CreatePostMutationVariables>(gql(createPost), {
    client: !auth.user?.pending && auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
  })

  const sendSharedPost = async () => {
    if (!chatContext.responseBoxData?.videoId) return
    const postId = chatContext.responseBoxData.videoId

    chatDispatcher({ type: 'setResponseBoxData', value: undefined })
    const res = await createPostMutation({
      variables: {
        input: {
          sharedPost: {
            channelId: channelId,
            postId: postId,
          },
        },
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            listPostsByChannel(existingItems = {}) {
              const newSharedPostRef = cache.writeFragment({
                data: data?.createPost,
                fragment: gql`
                  fragment newReplyToCommentPost on ScreensightPost {
                    id
                    channelId
                    accessId
                    customerId
                    postType
                    video
                    comment
                    emailMessage
                    text
                    file
                    engagement
                    referencePostId
                    referencePost {
                      id
                      channelId
                      accessId
                      customerId
                      postType
                      video {
                        id
                        customerId
                        customerMeta
                        channelId
                        title
                        searchTitle
                        status
                        fileObject
                        filePresignedUrl
                        duration
                        name
                        thumbnailObject
                        accessId
                        accessType
                        password
                        viewsCountTotal
                        viewsCountUnique
                        viewsList
                        commentsCount
                        width
                        height
                        comments
                        updatedAt
                        createdAt
                        uploadedVideo
                      }
                      comment
                      emailMessage
                      text
                      file
                      readHorizon
                      createdAt
                      updatedAt
                      engagement
                      referencePostId
                      referencePost
                      postTempId
                      reactions
                    }
                    readHorizon
                    reactions
                    createdAt
                    updatedAt
                  }
                `,
              })
              return {
                ...existingItems,
                items: [newSharedPostRef, ...existingItems.items],
              }
            },
          },
        })
      },
    })
    console.log('res', res)
    if (res.data?.createPost?.channelId && res.data.createPost?.createdAt) {
      updateChannelLatestAt(res.data?.createPost?.channelId, res.data.createPost?.createdAt)
    }
  }

  return [sendSharedPost]
}

export default useSendSharedPost
