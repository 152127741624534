import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import CloudFrontImage from '../../../../../../../components/_refactored/CloudFrontImage'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import { createPortal } from 'react-dom'
import ImagePostPreview from '../../../ChatContent/ui/components/PostList/ImagePostPreview'
import FileIcon from './FileIcon'
import { Auth } from 'aws-amplify'
import { Storage } from '@aws-amplify/storage'
import { FilePost, S3FileAccessLevel } from '../../../../../../../API'
import { saveAs } from 'file-saver'

interface IFileBoxProps {
  type: 'jpg' | 'jpeg' | 'png' | 'gif' | 'pdf' | 'zip'
  image?: string
  title: string
  file?: FilePost | null
}
const FileBox = (props: IFileBoxProps) => {
  const [showImagePreview, setShowImagePreview] = useState(false)

  const downloadFile = async () => {
    const credentials = await Auth.currentCredentials()
    const fileUrl = await Storage.get(`${props.file?.fileObject?.key}`, {
      level: props.file?.fileObject?.accessLevel as S3FileAccessLevel,
      identityId: props.file?.fileObject?.identityId as string,
      credentials: credentials,
    })
    saveAs(fileUrl)
  }

  const handleClickFileBox = async () => {
    if (props.type === 'jpg' || props.type === 'jpeg' || props.type === 'png' || props.type === 'gif') {
      setShowImagePreview(true)
    } else {
      await downloadFile()
    }
  }

  const handleCloseImagePreview = () => {
    setShowImagePreview(false)
  }

  if (!props.file || !props.file.fileObject?.key) return <></>

  return (
    <>
      <Box
        width="100%"
        maxWidth="100%"
        height="50px"
        margin="8px 0"
        display="flex"
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(36,131,247,0.05)',
          },
        }}
        onClick={handleClickFileBox}
      >
        <Box width="50px" maxWidth="100%" height="50px" bgcolor="white" borderRadius="8px" overflow="hidden">
          {props.type === 'jpg' || props.type === 'jpeg' || props.type === 'png' || props.type === 'gif' ? (
            <CloudFrontImage
              image={props?.image || ''}
              width="50"
              height="50"
              disableResize={props.type === 'gif'}
              imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
            />
          ) : (
            <Box
              bgcolor="primary.main"
              height="50px"
              width="50px"
              borderRadius="6px"
              marginRight="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={handleClickFileBox}
            >
              <FileIcon color={theme.palette.background.default} />
            </Box>
          )}
        </Box>
        <Box width="calc(100% - 85px)" marginLeft="10px" display="flex" alignItems="center">
          <Typography
            variant="body1"
            className="file-box__title"
            sx={{ fontSize: '12px', fontWeight: 500, lineHeight: '17px' }}
          >
            {props.title}
          </Typography>
        </Box>
      </Box>
      {showImagePreview &&
        createPortal(
          <ImagePostPreview
            handleCloseImagePreview={handleCloseImagePreview}
            imagePreviewKey={props.image || ''}
            isGif={props?.type === 'gif'}
          />,
          document.body,
        )}
    </>
  )
}

export default FileBox
