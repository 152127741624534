import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import notFoundGIF from '../../common/assets/notFoundToddy.gif'
import UnauthorizedHeader from '../VideoItem/ui/components/UnauthorizedHeader'
import { AuthContext } from '../../common/providers/AuthStatusProvider'
import BackgroundPageNotFound from '../../common/icons/BackgroundPageNotFound'
import FullScreenLoader from '../../components/_refactored/Loaders/FullScreenLoader'
import { theme } from '../../common/providers/MuiThemeProvider'
import Button from '../../components/_refactored/Button'
import { useHistory } from 'react-router-dom'
import IconScreensightLogo from '../../common/icons/ScreensightLogo'
import './styles.scss'

const VideoNotFoundPage = () => {
  const auth = useContext(AuthContext)
  const { t } = useTranslation('library')
  const history = useHistory()
  const handleRedirect = () => {
    if (!auth.isAuthenticated) {
      history.push('/sign-up')
    } else {
      history.push('/app')
    }
  }

  return (
    <>
      <Box
        component="button"
        sx={{
          marginTop: '25px',
          marginLeft: '14px',
          cursor: 'pointer',
          backgroundColor: 'transparent',
          border: 'none',
        }}
        onClick={handleRedirect}
      >
        <IconScreensightLogo width="165px" height="100%" />
      </Box>
      <Box position="relative" width="100%" height="100vh" display="flex" alignItems="center" justifyContent="center">
        {auth.user?.pending && <FullScreenLoader />}
        {!auth.isAuthenticated && <UnauthorizedHeader />}
        <Box
          className={'page-not-found-container'}
          display="grid"
          alignItems="center"
          justifyContent="center"
          width="560px"
          minWidth="200px"
        >
          <BackgroundPageNotFound />
          <Box className="layer2" zIndex={10} textAlign="center">
            <Box sx={{ paddingRight: '59px' }} textAlign="center">
              <img src={notFoundGIF} width={200} alt={'Resource not found'} />
            </Box>
            <Typography lineHeight={1.3} textAlign="center" variant="h3" style={{ padding: '15px 45px' }}>
              {t('videoNotFound.title')}
            </Typography>
            <Typography
              maxWidth="460px"
              textAlign="center"
              variant="body1"
              style={{ color: theme.palette.text.secondary, padding: '0 30px', margin: '0 auto' }}
            >
              {t('videoNotFound.subtitle')}
            </Typography>
            <Button style={{ marginTop: '20px' }} variant="contained" onClick={handleRedirect}>
              {t('videoNotFound.goToScreenSightBtn')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default VideoNotFoundPage
