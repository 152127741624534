import React, { useContext } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'
import BackIcon from '../../../../../../../common/icons/BackIcon'

interface IChannelsDrawerHeader {
  openMenu: () => void
}

const Header = (props: IChannelsDrawerHeader) => {
  const { t } = useTranslation('chat')
  const auth = useContext(AuthContext)

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" padding={'0 15px'}>
      <Box display="flex" alignItems="center">
        <IconButton onClick={props.openMenu} style={{ boxShadow: 'none', height: '20px', width: '14px' }}>
          <BackIcon />
        </IconButton>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ fontWeight: 500, fontSize: '24px', paddingLeft: '12px' }}
        >
          {t('channelsSidebar.title')}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {auth?.user?.profilePhotoUrl && (
          <img
            src={auth.user.profilePhotoUrl}
            alt="User image"
            style={{
              height: '40px',
              width: '40px',
              marginRight: '6px',
              objectFit: 'cover',
              borderRadius: '100px',
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default Header
