import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import { useLocation, useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import ToastContext from '../../../../common/providers/ToastProvider/ToastContext'
import { useQuery } from '@apollo/client'
import {
  ListChannelParticipantsQuery,
  ListChannelParticipantsQueryVariables,
  screensightCustomer,
} from '../../../../API'
import gql from 'graphql-tag'
import { listChannelParticipants } from '../../../../graphql/queries'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../common/clients/IamApolloClient'
import * as queryString from 'query-string'
import { Analytics } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { setChatCustomers } from '../redux/store/chat-actions'
import { useTranslation } from 'react-i18next'

export type IParticipantData = {
  author?: screensightCustomer | null
  partner?: screensightCustomer | null
}

const useChatParticipants = () => {
  const auth = useContext(AuthContext)
  const { id: channelId } = useParams() as { id: string }
  const location = useLocation()
  const history = useHistory()
  const toastContext = useContext(ToastContext)
  const dispatch = useDispatch()
  const { t } = useTranslation('chat')
  const [participantData, setParticipantData] = useState<IParticipantData>({
    author: undefined,
    partner: undefined,
  })

  const { data, error } = useQuery<ListChannelParticipantsQuery, ListChannelParticipantsQueryVariables>(
    gql(listChannelParticipants),
    {
      variables: { channelId: channelId || auth.user?.supportChannelId || '' },
      skip: auth.user?.pending || !(channelId || auth.user?.supportChannelId) || channelId === 'new',
      client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    },
  )

  useEffect(() => {
    if (error && queryString.parse(location.search)?.videoAccessId) {
      history.replace(`/video/${queryString.parse(location.search)?.videoAccessId}`)
      return
    } else if (error) {
      toastContext.open({ type: 'ERROR', text: t('chatBody.accessChannelError') })
      if (auth.isAuthenticated) {
        history.push('/app/chat/new')
      } else {
        history.push('/app')
      }
    }
  }, [error, auth])

  useEffect(() => {
    const setData = async () => {
      if (!data?.listChannelParticipants?.items) return
      if (!auth.user?.id) {
        if (data.listChannelParticipants.items[1]?.customerId.includes('anon#')) {
          sessionStorage.setItem('guestCustomerId', data.listChannelParticipants.items[1]?.customerId)
          setParticipantData({
            author: data.listChannelParticipants.items[1]?.customerMeta,
            partner: data.listChannelParticipants.items[0]?.customerMeta,
          })
          await Analytics.updateEndpoint({ userId: data.listChannelParticipants.items[1]?.customerId })
          Analytics.autoTrack('session', { enable: true })
        }
        if (data.listChannelParticipants.items[0]?.customerId.includes('anon#')) {
          sessionStorage.setItem('guestCustomerId', data.listChannelParticipants.items[0]?.customerId)
          setParticipantData({
            author: data.listChannelParticipants.items[0]?.customerMeta,
            partner: data.listChannelParticipants.items[1]?.customerMeta,
          })
          await Analytics.updateEndpoint({ userId: data.listChannelParticipants.items[0]?.customerId })
          Analytics.autoTrack('session', { enable: true })
        }
        return
      }
      if (data.listChannelParticipants.items[0]?.customerId !== auth.user?.id) {
        setParticipantData({
          author: data.listChannelParticipants.items[1]?.customerMeta,
          partner: data.listChannelParticipants.items[0]?.customerMeta,
        })
      } else {
        setParticipantData({
          author: data.listChannelParticipants.items[0]?.customerMeta,
          partner: data.listChannelParticipants.items[1]?.customerMeta,
        })
      }
    }
    setData()
  }, [data?.listChannelParticipants?.items, auth.user?.id])

  useEffect(() => {
    dispatch(setChatCustomers(participantData))
  }, [participantData])

  return {
    participantData,
  }
}

export type TUseChatParticipant = ReturnType<typeof useChatParticipants>

export default useChatParticipants
