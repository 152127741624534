import React from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { IAuthLayout } from '../../../common/types/Auth'
import { ForgotPasswordContext } from '../common/context/ForgotPasswordContext'

const Layout = (props: IAuthLayout) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ForgotPasswordContext>
      <Box
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        sx={{
          paddingLeft: (isMobile && '33px') || 0,
          paddingRight: (isMobile && '33px') || 0,
        }}
      >
        {props.children}
      </Box>
    </ForgotPasswordContext>
  )
}
export default Layout
