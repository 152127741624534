import React from 'react'

interface IProps {
  color?: string
  width?: string
  height?: string
}
const Logo = (props: IProps) => {
  return (
    <svg
      width={props.width || '45'}
      height={props.height || '25'}
      viewBox="0 0 45 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.6693 5.94423e-07L12.0277 0.0330352C11.4484 0.0275543 10.8899 0.251523 10.4703 0.657492C10.0507 1.06346 9.80305 1.61953 9.77985 2.2079C9.77335 2.50127 9.82459 2.793 9.93056 3.06595C10.0365 3.3389 10.1951 3.58758 10.3969 3.79737C10.5988 4.00716 10.8398 4.17383 11.1059 4.28759C11.372 4.40136 11.6578 4.45993 11.9464 4.45986L37.4445 4.42407C38.0191 4.42407 38.5702 4.6561 38.9765 5.06913C39.3828 5.48216 39.6111 6.04235 39.6111 6.62646L39.6274 18.3515C39.6274 18.9356 39.3991 19.4958 38.9928 19.9088C38.5865 20.3218 38.0354 20.5539 37.4608 20.5539L25.9264 20.5704C25.3518 20.5704 24.8007 20.3383 24.3944 19.9253C23.988 19.5123 23.7598 18.9521 23.7598 18.368V10.8413C23.7659 10.2523 23.5458 9.68407 23.1462 9.25739C22.7467 8.83072 22.1993 8.57921 21.6203 8.55631C21.3317 8.5497 21.0447 8.6018 20.7762 8.70952C20.5077 8.81724 20.263 8.97842 20.0566 9.18359C19.8503 9.38877 19.6863 9.63379 19.5744 9.90428C19.4625 10.1748 19.4049 10.4653 19.4049 10.7587V18.5772C19.4035 19.4222 19.5664 20.2592 19.8843 21.04C20.2021 21.8208 20.6687 22.53 21.2571 23.1269C21.8455 23.7238 22.5442 24.1965 23.313 24.518C24.0818 24.8394 24.9055 25.0032 25.7368 25L37.7018 24.9834C39.3744 24.9805 40.9775 24.3027 42.1587 23.0988C43.3399 21.895 44.0026 20.2637 44.0012 18.5634L43.9849 6.40071C43.9828 4.7019 43.3172 3.07346 42.1345 1.87325C40.9517 0.673043 39.3486 -0.000730546 37.6774 5.94423e-07"
        fill={props?.color || 'url(#paint0_linear)'}
      />
      <path
        d="M28.8125 12.5067C28.8172 13.1359 29.0198 13.747 29.3906 14.2507C29.7614 14.7545 30.281 15.1244 30.8735 15.3065C31.1374 15.3855 31.4108 15.4272 31.6859 15.4304C32.4491 15.4268 33.1798 15.1164 33.7186 14.5671C34.2575 14.0179 34.5607 13.2742 34.5621 12.4985C34.558 11.8688 34.3556 11.2571 33.9848 10.7528C33.6139 10.2485 33.094 9.87814 32.5011 9.69591C32.2377 9.61451 31.964 9.57277 31.6887 9.57202C30.9249 9.57493 30.1933 9.88541 29.6542 10.4355C29.1152 10.9855 28.8125 11.7303 28.8125 12.5067Z"
        fill={props?.color || '#367FF3'}
      />
      <path
        d="M12.8642 17.842H7.13896C6.56183 17.842 6.00834 18.0751 5.60025 18.4899C5.19215 18.9048 4.96289 19.4674 4.96289 20.0541C4.96289 20.6407 5.19215 21.2034 5.60025 21.6182C6.00834 22.0331 6.56183 22.2661 7.13896 22.2661H12.8669C13.444 22.2683 13.9984 22.0373 14.408 21.6241C14.8176 21.2108 15.0489 20.649 15.0511 20.0623C15.0532 19.4757 14.826 18.9122 14.4195 18.4958C14.0129 18.0794 13.4603 17.8442 12.8831 17.842"
        fill={props?.color || 'url(#paint1_linear)'}
      />
      <path
        d="M12.8506 8.99927L2.17742 9.01303C1.59993 9.01303 1.0461 9.24623 0.637752 9.66132C0.229406 10.0764 0 10.6394 0 11.2264C0 11.8135 0.229406 12.3765 0.637752 12.7916C1.0461 13.2067 1.59993 13.4399 2.17742 13.4399L12.8479 13.4261C13.4254 13.4261 13.9792 13.1929 14.3876 12.7778C14.7959 12.3627 15.0253 11.7997 15.0253 11.2127C15.0253 10.6256 14.7959 10.0627 14.3876 9.64756C13.9792 9.23246 13.4254 8.99927 12.8479 8.99927"
        fill={props?.color || 'url(#paint2_linear)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="9.77985"
          y1="12.4986"
          x2="44.0012"
          y2="12.4986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AA3F5" />
          <stop offset="1" stopColor="#2C6EF2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="4.96424"
          y1="20.0692"
          x2="15.0443"
          y2="20.0692"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53B2F6" />
          <stop offset="1" stopColor="#469BF5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="3011.97"
          y1="2175.78"
          x2="3845.58"
          y2="2175.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53B2F6" />
          <stop offset="1" stopColor="#469BF5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Logo
