import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import useSendTextPost from './useSendTextPost'
import { Sources } from 'quill'
import ReactQuill, { UnprivilegedEditor } from 'react-quill'
import { useChatContext, useChatDispatch } from '../../contexts/ChatContext'
import useSendCommentPost from './useSendCommentPost'
import useSendSharedPost from './useSendSharedPost'
import useSendReplyPost from './useSendReplyPost'
import { useParams } from 'react-router'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import useDraftProvider from '../../../../../../../../common/providers/DraftProvider/useDraftProvider'
import DOMPurify from 'dompurify'
import useUpdatePost from './api/useUpdatePost'
import useSendFilePost from './useSendFilePost'
import { isFileType } from '../../../../../../../../common/utils/isFileType'
import { getImageResolution } from '../../../../../../../../common/utils/getImageResolution'
import useSendReplyToFilePost from './useSendReplyToFile'
import useSendReplyToTextPost from './useSendReplyToTextPost'
import useSendReplyToCommentPost from './useSendReplyToComment'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../common/redux/store/store'
import { setChatPlayerPaused } from '../../../../../../common/redux/store/chat-actions'
import { useTranslation } from 'react-i18next'
import * as linkify from 'linkifyjs'
import { isScreenSightVideoLink } from './isScreenSightVideoLink'
import useGetVideo from './api/useGetVideo'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import { getUsername } from '../../../../../../../../common/utils/getUsername'
import ToastContext from '../../../../../../../../common/providers/ToastProvider/ToastContext'
import { useMediaQuery } from '@mui/material'

const useQuill = ({
  handleChangeDefaultTitle,
  videoPlayerEditor,
}: {
  handleChangeDefaultTitle?: (title?: string) => void
  videoPlayerEditor?: boolean | null
}) => {
  const chatContext = useChatContext()
  const auth = useContext(AuthContext)
  const toastContext = useContext(ToastContext)
  const chatDispatcher = useChatDispatch()
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const [value, setValue] = useState({ content: '', richTextContent: '' })
  const [disabled, setDisabled] = useState(false)
  const [channelDefaultValue, setChannelDefaultValue] = useState('')
  const [videoLinkFromQuill, setVideoLinkFromQuill] = useState<string | undefined>('')
  const quillRef = useRef<ReactQuill>(null)
  const [sendTextPost] = useSendTextPost()
  const [sendCommentPost] = useSendCommentPost()
  const [sendReplyPost] = useSendReplyPost()
  const [sendReplyToFilePost] = useSendReplyToFilePost()
  const [sendReplyToTextPost] = useSendReplyToTextPost()
  const [sendSharedPost] = useSendSharedPost()
  const [sendFilePost] = useSendFilePost()
  const [sendReplyToCommentPost] = useSendReplyToCommentPost()
  const { setDraft, getDraft, drafts, removeDraft } = useDraftProvider()
  const [balloonsVisible] = useState(false)
  const quill = document.getElementsByClassName('ql-editor')
  const videoPlaylistProgress = useSelector((state: RootState) => state.chat.videoPlaylistProgress)
  const isTablet = useMediaQuery(`(max-width:1024px)`)
  const dispatch = useDispatch()
  const { t } = useTranslation('chat')
  const { t: l } = useTranslation('library')
  const placeholderTranslation = t('chatBody.chatInputPlaceholder')
  const { getVideoData, loadingVideoData } = useGetVideo()

  const updatePostMutation = useUpdatePost(
    chatContext?.editedPostData?.postId as string,
    value.content,
    value.richTextContent,
  )

  // set translated placeholder on language change
  useEffect(() => {
    if (!quillRef?.current) return
    const editor = quillRef?.current?.getEditor()
    if (!editor) return
    editor.root.dataset.placeholder = placeholderTranslation
  }, [quillRef, placeholderTranslation])

  useEffect(() => {
    if (!quill || !videoPlayerEditor) return
    const listenerFunction = () => {
      dispatch(setChatPlayerPaused(true))
    }

    quill?.[0]?.addEventListener('click', listenerFunction)
    return () => quill?.[0]?.removeEventListener('click', listenerFunction)
  }, [quill, videoPlayerEditor])

  useEffect(() => {
    if (channelId && !!drafts?.[channelId]) {
      setChannelDefaultValue(getDraft(channelId))
    } else {
      setChannelDefaultValue('')
    }
  }, [channelId])

  useEffect(() => {
    chatDispatcher({
      type: 'setResponseBoxData',
      value: undefined,
    })
  }, [channelId])

  useEffect(() => {
    if (!chatContext.editedPostData || !quillRef.current) return
    quillRef?.current?.getEditor().focus()
  }, [chatContext.editedPostData])

  useEffect(() => {
    if (!quillRef.current) return
    focusAtTheTextEnd()
  }, [channelId, chatContext.responseBoxData, channelDefaultValue, quillRef.current])

  useEffect(() => {
    if (!chatContext.editedPostData) return
    const element = document.getElementsByClassName('ql-editor')
    element[0].innerHTML = chatContext?.editedPostData?.richContent || chatContext?.editedPostData?.content || ''
  }, [chatContext.editedPostData])

  useEffect(() => {
    if (!channelDefaultValue) return
    setValue({
      content: DOMPurify.sanitize(channelDefaultValue, {
        ALLOWED_TAGS: [''],
        KEEP_CONTENT: true,
        USE_PROFILES: { html: false },
      }),
      richTextContent: channelDefaultValue,
    })
  }, [channelDefaultValue])

  useEffect(() => {
    const getVideo = async () => {
      if (!videoLinkFromQuill) return

      const data = await getVideoData({
        variables: { id: isScreenSightVideoLink(videoLinkFromQuill) },
        client: CognitoApolloClient,
      })

      if (data.data?.getVideo?.customerId !== auth.user?.id || data.data?.getVideo === null) {
        setTimeout(() => {
          focusAtTheTextEnd()
        }, 200)
        return
      }

      chatDispatcher({
        type: 'setResponseBoxData',
        value: {
          type: 'video',
          videoId: data.data?.getVideo?.id,
          title: data.data?.getVideo?.title,
          author: getUsername(auth.user?.firstName, auth.user?.lastName, auth.user?.email),
          photoKey: data.data?.getVideo?.thumbnailObject?.key,
        },
      })

      setVideoLinkFromQuill(undefined)
      removeVideoLinkFromQuill()
    }

    focusAtTheTextEnd()
    getVideo()
  }, [videoLinkFromQuill, quillRef, auth.user])

  const removeVideoLinkFromQuill = () => {
    quillRef.current?.getEditor().setText(
      quillRef.current
        ?.getEditor()
        .getText()
        .replace(videoLinkFromQuill as string, ''),
    )
  }
  const focusAtTheTextEnd = () => {
    if (isTablet) return
    quillRef?.current?.getEditor().setSelection(quillRef?.current?.getEditor().getLength(), 0)
    quillRef?.current?.getEditor().focus()
  }

  const handleSubmit = useCallback(async () => {
    if (
      !value.content.trim() &&
      chatContext.responseBoxData?.type !== 'video' &&
      chatContext.responseBoxData?.type !== 'file'
    )
      return
    if ((value.content === '\n' && !chatContext.responseBoxData) || disabled) return

    // if (value.content?.toLowerCase() === 'balloons...\n' || value.content?.toLowerCase() === 'balloons...') {
    //   setBalloonsVisible(true)
    //   setTimeout(() => setBalloonsVisible(false), 5000)
    // }
    setDisabled(true)
    // setTimeout(() => {
    setValue({ content: '', richTextContent: '' })
    const element = document.getElementsByClassName('ql-editor')
    element[0].innerHTML = ''
    setChannelDefaultValue('')
    // })
    setTimeout(() => setDisabled(false), 1000)

    switch (chatContext.responseBoxData?.type) {
      case 'video': {
        if (value.content.trim() && value.richTextContent) {
          await sendTextPost({
            content: value.content,
            richContent: value.richTextContent,
          })
        }
        await sendSharedPost()
        break
      }
      case 'comment': {
        if (!chatContext.responseBoxData?.videoId) break

        await sendCommentPost(
          value.content,
          value.richTextContent,
          chatContext.responseBoxData.videoId,
          chatContext.responseBoxData.accessId,
          chatContext.responseBoxData.title,
          parseInt(
            String(videoPlaylistProgress?.[chatContext?.responseBoxData?.postId || '']) ||
              chatContext.responseBoxData?.videoTimestamp ||
              '',
            10,
          ) || 0,
          chatContext.responseBoxData.thumbnailObject,
          videoPlayerEditor,
        )
        if (videoPlayerEditor) {
          toastContext.open({ type: 'INFO', text: l('videoItem.sendViaEmailModal.messageSentSuccess') })
        }
        break
      }
      case 'videoReply': {
        if (!chatContext.responseBoxData?.videoId || !chatContext.responseBoxData.parentId) break
        await sendReplyPost(
          value.content,
          value.richTextContent,
          chatContext.responseBoxData.parentId,
          chatContext.responseBoxData.videoId,
        )
        break
      }
      case 'commentReply': {
        if (!chatContext.responseBoxData.parentId) break
        await sendReplyToCommentPost(
          value.content,
          value.richTextContent,
          chatContext.responseBoxData.parentId,
          chatContext.responseBoxData.videoId || '',
          chatContext.responseBoxData.videoCommentTimestamp || 0,
          chatContext.responseBoxData.title,
        )
        break
      }
      case 'textReply': {
        if (!chatContext.responseBoxData.parentId) break
        await sendReplyToTextPost(
          value.content,
          value.richTextContent,
          chatContext.responseBoxData.parentId,
          chatContext.responseBoxData.title,
          chatContext.responseBoxData.postType,
          chatContext.responseBoxData.referencePostAuthorId,
          chatContext.responseBoxData.engagement,
        )
        break
      }
      case 'fileReply': {
        if (!chatContext.responseBoxData.parentId) break
        await sendReplyToFilePost(
          value.content,
          value.richTextContent,
          chatContext.responseBoxData.parentId,
          chatContext.responseBoxData.file,
          chatContext.responseBoxData.referencePostAuthorId,
        )
        break
      }
      case 'file': {
        if (!chatContext?.responseBoxData?.file) break
        let imageRes: { width: number; height: number } | null = null
        if (isFileType(chatContext?.responseBoxData?.fileName || '', ['jpg', 'jpeg', 'png', 'gif'])) {
          imageRes = (await getImageResolution(chatContext?.responseBoxData?.file)) as {
            width: number
            height: number
          } | null
        }
        if (value.content.trim() && value.richTextContent) {
          await sendTextPost({
            content: value.content,
            richContent: value.richTextContent,
          })
        }
        await sendFilePost(
          chatContext.responseBoxData.file,
          chatContext.responseBoxData.fileName || '',
          auth.user?.id || sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId') || '',
          imageRes?.width && imageRes?.height
            ? `{"width": ${imageRes.width}, "height": ${imageRes.height}}`
            : undefined,
        )
        break
      }
      default: {
        await sendTextPost({
          content: value.content,
          richContent: value.richTextContent,
        })
        break
      }
    }
    const elem = document.getElementById('chat__scrollable-box')
    elem?.scrollTo(0, 0)
    if (channelId) removeDraft(channelId)
  }, [value, chatContext.responseBoxData, channelId, videoPlaylistProgress, videoPlayerEditor])

  const handleChange = (value: string, delta: any, source: Sources, editor: UnprivilegedEditor) => {
    const links = linkify.find(editor.getText())

    if (links.length === 1 && isScreenSightVideoLink(links[0]?.href)) {
      setVideoLinkFromQuill(links[0]?.href)
    }

    if (handleChangeDefaultTitle) {
      // @ts-ignore
      handleChangeDefaultTitle(quillRef?.current?.editor?.editor?.delta?.ops?.[0]?.insert)
    }

    setTimeout(() => {
      setValue({ content: editor.getText(), richTextContent: value })
      if (channelId && !chatContext?.editedPostData) setDraft(channelId, value)
    })
  }

  const clearQuill = () => {
    if (!quill) return
    quill[0].innerHTML = ''
  }

  const handleUpdate = async () => {
    if (!value.content?.trim()?.length) return
    await updatePostMutation()
    chatDispatcher({
      type: 'setEditedPost',
      value: null,
    })
    clearQuill()
  }

  const handleCancel = () => {
    chatDispatcher({
      type: 'setEditedPost',
      value: null,
    })
    clearQuill()
  }

  const onKeyDown = async (keyEvent: KeyboardEvent) => {
    if (
      keyEvent.shiftKey ||
      !keyEvent.code ||
      keyEvent.code !== 'Enter' ||
      keyEvent.key !== 'Enter' ||
      keyEvent.keyCode !== 13
    )
      return
    keyEvent.preventDefault()

    if (chatContext.editedPostData) {
      await handleUpdate()
      return
    }
    await handleSubmit()
  }

  const quillModules = useMemo(() => {
    return {
      toolbar: [],
      keyboard: {
        bindings: {
          enter: {
            key: 13,
            handler: () => {},
          },
        },
      },
    }
  }, [])

  return {
    quillRef,
    handleChange,
    handleSubmit,
    handleUpdate,
    handleCancel,
    channelDefaultValue,
    channelId,
    onKeyDown,
    quillModules,
    balloonsVisible,
    isVideoFromLinkLoading: loadingVideoData,
  }
}

export type TUseQuill = ReturnType<typeof useQuill>
export default useQuill
