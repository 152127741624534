import React, { FC } from 'react'
import { Box } from '@mui/material'
import Content from './ui/partials/Content'
import Footer from './ui/partials/Footer'
import { EditThumbnailContext } from './common/context/EditThumbnailContext'
import PageTitle from '../../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

const EditThumbnail: FC = () => {
  const { t: g } = useTranslation('general')

  return (
    <EditThumbnailContext>
      <PageTitle name={g('pageName.editThumbnail')} />
      <Box
        width="1100px"
        maxWidth="calc(100vw - 80px)"
        padding="30px 20px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="0 auto"
      >
        <Content />
        <Footer />
      </Box>
    </EditThumbnailContext>
  )
}

export default EditThumbnail
