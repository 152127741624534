import React from 'react'
import CloudFrontImage from '../CloudFrontImage'
import { Box } from '@mui/material'

interface IAvatarProps {
  width?: number
  height?: number
  imgKey?: string
  style?: React.CSSProperties
  imgStyle?: React.CSSProperties
  firstName?: string
  lastName?: string
  email: string
}
const Avatar = (props: IAvatarProps) => {
  return props.imgKey ? (
    <CloudFrontImage
      image={`public/${props.imgKey}`}
      width={props?.width?.toString() || '20'}
      height={props?.height?.toString() || '20'}
      imgStyle={{ borderRadius: '50px', ...props.imgStyle }}
    />
  ) : (
    <Box
      width={props.width || '20px'}
      height={props.height || '20px'}
      borderRadius="50px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={props.style}
      position="relative"
      bgcolor="primary.main"
      color="white"
      fontSize="12px"
    >
      {props?.firstName?.charAt(0)?.toUpperCase() || props.email?.charAt(0)?.toUpperCase()}
    </Box>
  )
}

export default Avatar
