import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { dataURItoBlob } from '../../../../common/utils/convertBase64ToBlob'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import ToastContext from '../../../../common/providers/ToastProvider/ToastContext'
import { isValidUrl } from '../../../../common/utils/isValidUrl'
import { useMutation } from '@apollo/client'
import { UpdateCustomerMutation, UpdateCustomerMutationVariables } from '../../../../API'
import gql from 'graphql-tag'
import { updateCustomer } from '../../../../graphql/mutations'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import Auth from '@aws-amplify/auth'
import { Storage } from '@aws-amplify/storage'
import { v4 } from 'uuid'
import awsmobile from '../../../../aws-exports'
import { useTranslation } from 'react-i18next'

const useOnBoardingInitSteps = (onFinish: () => void) => {
  const auth = useContext(AuthContext)
  const { open } = useContext(ToastContext)
  const { t: g } = useTranslation('general')
  const { t } = useTranslation('library')

  const [step, setStep] = useState(0)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true)

  const [removePhotoIconVisible, setRemovePhotoIconVisible] = useState(false)
  const [image, setImage] = useState<string | ArrayBuffer | null>('')
  const [imageFile, setImageFile] = useState<null | File>(null)
  const cropperRef = useRef<HTMLImageElement>(null)
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
  })

  const [calendlyUrl, setCalendlyUrl] = useState('')
  const [calendlyUrlError, setCalendlyUrlError] = useState(false)

  const [saving, setSaving] = useState(false)

  const [updateUserMutation] = useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
    gql(updateCustomer),
    {
      client: CognitoApolloClient,
    },
  )

  useEffect(() => {
    if (step === 0 && (!firstName?.length || !lastName?.length)) {
      setIsNextButtonDisabled(true)
    } else if (step === 1 && !imageFile) {
      setIsNextButtonDisabled(true)
    } else if (step === 2 && (!calendlyUrl.length || calendlyUrlError)) {
      setIsNextButtonDisabled(true)
    } else {
      setIsNextButtonDisabled(false)
    }
  }, [step, firstName, lastName, imageFile, calendlyUrlError, calendlyUrl])

  useEffect(() => {
    if (!acceptedFiles?.length) return
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(acceptedFiles[0])
  }, [acceptedFiles])

  const handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value)
  }

  const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value)
  }

  const handleImageCroppingSubmit = async () => {
    const imageElement: any = cropperRef?.current
    const cropper: Cropper = imageElement?.cropper
    if (typeof cropper !== 'undefined') {
      setImage(null)
      const blob = cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.7)
      const file = dataURItoBlob(blob)
      if (file.size > 10485760) {
        open({ type: 'ERROR', text: g('common.avatarSizeError') })
        return
      }
      setRemovePhotoIconVisible(false)
      await auth.updateUser({ ...auth.user, profilePhotoUrl: blob as string })
      setImageFile(file as File)
    }
  }

  const handleCloseCropperModal = () => {
    setImage(null)
  }

  const showRemoveIcon = () => {
    setRemovePhotoIconVisible(true)
  }

  const hideRemoveIcon = () => {
    setRemovePhotoIconVisible(false)
  }

  const clearUserPhoto = async () => {
    await auth.updateUser({ ...auth.user, profilePhotoUrl: '' })
    setImageFile(null)
  }

  const handleChangeCalendlyUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value
    setCalendlyUrlError(!isValidUrl(url))
    setCalendlyUrl(url)
  }

  const handleSkip = async () => {
    if (step === 0) {
      setFirstName('')
      setLastName('')
      setStep(1)
      return
    } else if (step === 1) {
      setImage(null)
      setImageFile(null)
      await auth.updateUser({ ...auth.user, profilePhotoUrl: '' })
      setStep(2)
      return
    } else if (step === 2) {
      setCalendlyUrl('')
      setCalendlyUrlError(false)
      setStep(3)
    }
  }

  const submitUserData = async () => {
    setSaving(true)
    try {
      // @ts-ignore
      const S3Img: { key: string } = imageFile
        ? await Storage.put(`protected/${auth.user?.id}/profile/${v4()}.jpg`, imageFile, {
            contentType: 'image/jpg',
            useAccelerateEndpoint: true,
          })
        : null

      const user = await Auth.currentAuthenticatedUser()
      await updateUserMutation({
        variables: {
          input: {
            id: user.attributes.sub,
            firstName: firstName || undefined,
            lastName: lastName || undefined,
            calendlyUrl: calendlyUrl || undefined,
            profilePhoto:
              imageFile && S3Img?.key ? { key: S3Img.key, bucket: awsmobile.aws_user_files_s3_bucket } : null,
          },
        },
      })
      if (firstName && lastName) {
        await Auth.updateUserAttributes(user, {
          given_name: firstName,
          family_name: lastName,
        })
      }

      const photoUrl = S3Img?.key ? await Storage.get(S3Img.key) : null

      await auth.updateUser({
        ...auth.user,
        firstName: firstName || undefined,
        lastName: lastName || undefined,
        calendlyUrl: calendlyUrl || undefined,
        profilePhotoUrl: (photoUrl as string) || undefined,
        profilePhoto:
          imageFile && S3Img?.key ? { key: S3Img.key, bucket: awsmobile.aws_user_files_s3_bucket } : undefined,
      })
      localStorage.setItem('onBoarded', 'true')
    } catch (e: any) {
      console.log('Error during onboarding user', e.message)
      if (auth?.user?.profilePhoto?.key) {
        await Storage.remove(auth.user.profilePhoto?.key)
      }
      setFirstName('')
      setLastName('')
      setImage(null)
      setImageFile(null)
      setCalendlyUrl('')

      const user = await Auth.currentAuthenticatedUser()
      await updateUserMutation({
        variables: {
          input: {
            id: user.attributes.sub,
            firstName: undefined,
            lastName: undefined,
            calendlyUrl: undefined,
            profilePhoto: null,
          },
        },
      })
      await Auth.updateUserAttributes(user, {
        given_name: '',
        family_name: '',
      })
      await auth.updateUser({
        ...auth.user,
        firstName: undefined,
        lastName: undefined,
        calendlyUrl: undefined,
        profilePhotoUrl: undefined,
        profilePhoto: undefined,
      })

      open({
        type: 'ERROR',
        text: e?.message?.includes('calendlyUrl')
          ? t('videoList.onBoardingModal.invalidCalendlyUrlError')
          : t('videoList.onBoardingModal.calendlyUrlError'),
      })
    } finally {
      setSaving(false)
    }
  }
  const submitUserDataAndDisplayVideo = async () => {
    await submitUserData()
    onFinish()
  }

  const submitUserDataAndRedirectToStore = async () => {
    await submitUserData()
    window.open('https://chrome.google.com/webstore/detail/screensight/hhnbfppcjpaacnjecideabahjbdgppaf', '_self')
  }

  return {
    step,
    setStep,
    firstName,
    lastName,
    handleChangeFirstName,
    handleChangeLastName,
    handleSkip,
    isNextButtonDisabled,
    dropzone: {
      getRootProps,
      getInputProps,
    },
    image,
    cropperRef,
    handleCloseCropperModal,
    handleImageCroppingSubmit,
    showRemoveIcon,
    hideRemoveIcon,
    removePhotoIconVisible,
    clearUserPhoto,
    handleChangeCalendlyUrl,
    calendlyUrl,
    calendlyUrlError,
    saving,
    submitUserData,
    submitUserDataAndDisplayVideo,
    submitUserDataAndRedirectToStore,
  }
}

export default useOnBoardingInitSteps
