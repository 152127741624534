import React from 'react'
import { Box, Typography } from '@mui/material'
import ExtensionImg from './assets/extension.png'
import Button from '../../../../components/_refactored/Button'
import { IOnBoardingExtension } from '../interfaces'
import { useTranslation } from 'react-i18next'

const OnBoardingExtension = (props: IOnBoardingExtension) => {
  const { t } = useTranslation('library')

  return (
    <>
      <Box
        width={props.isMobile ? '100%' : '50%'}
        paddingRight={props.isMobile ? '0' : '16px'}
        paddingTop={props.isMobile ? '0' : '32px'}
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Typography variant="body1" style={{ fontWeight: 500, fontSize: '20px', marginBottom: '10px' }}>
          {t('videoList.onBoardingModal.extensionInfoTitle')}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ fontSize: '15px', marginBottom: '30px' }}>
          {t('videoList.onBoardingModal.extensionInfoSubtitle')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ width: 'fit-content' }}
          onClick={props.submitUserDataAndRedirectToStore}
        >
          {t('videoList.common.extensionWebStoreBtn')}
        </Button>
      </Box>
      <Box
        width="50%"
        height="100%"
        paddingLeft="16px"
        display={props.isMobile ? 'none' : 'flex'}
        alignItems="center"
        justifyContent="center"
      >
        <img src={ExtensionImg} />
      </Box>
    </>
  )
}

export default OnBoardingExtension
