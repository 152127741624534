export const onPostReaction = /* GraphQL */ `
  subscription OnPostReaction($channelId: ID) {
    onPostReaction(channelId: $channelId) {
      __typename
      postId
      customerId
      unicode
      videoTimestamp
      channelId
      incrementBy
      createdAt
      updatedAt
    }
  }
`

export type OnPostReactionSubscription = {
  onPostReaction?: {
    __typename: 'screensightReaction'
    postId: string
    customerId: string
    unicode: string
    videoTimestamp: number
    channelId?: string | null
    incrementBy?: number | null
    createdAt?: string | null
    updatedAt?: string | null
  } | null
}
