import { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import Auth from '@aws-amplify/auth'

import ToastContext from 'common/providers/ToastProvider/ToastContext'
import { ProfileProps } from '../index'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import { useMutation } from '@apollo/client'
import { OnUpdateCustomerSubscription, UpdateCustomerMutation, UpdateCustomerMutationVariables } from '../../../../API'
import gql from 'graphql-tag'
import { updateCustomer } from '../../../../graphql/mutations'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import { Storage } from '@aws-amplify/storage'
import { v4 } from 'uuid'
import awsmobile from '../../../../aws-exports'
import { onUpdateCustomer } from '../../../../graphql/subscriptions'
import { useDropzone } from 'react-dropzone'
import { dataURItoBlob } from '../../../../common/utils/convertBase64ToBlob'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { useTranslation } from 'react-i18next'
import { GraphQLErrors } from '@apollo/client/errors'

interface ProfileForm {
  firstName: string
  lastName: string
}

interface ISubUpdateCustomerValue {
  value: { data: OnUpdateCustomerSubscription }
}

const useProfileHook = (props: ProfileProps) => {
  const { open } = useContext(ToastContext)
  const auth = useContext(AuthContext)
  const { t } = useTranslation('settings')
  const { t: g } = useTranslation('general')
  const [loading, setLoading] = useState(false)
  const [removePhotoIconVisible, setRemovePhotoIconVisible] = useState(false)
  const [removeModalVisible, setRemoveModalVisible] = useState(false)
  const [photoLoading, setPhotoLoading] = useState(false)
  const [photoDeleting, setPhotoDeleting] = useState(false)
  const [image, setImage] = useState<string | ArrayBuffer | null>('')
  const cropperRef = useRef<HTMLImageElement>(null)
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
  })
  const [updateCustomerSubData, setUpdateCustomerSubData] = useState<OnUpdateCustomerSubscription | null>(null)

  useEffect(() => {
    if (!acceptedFiles?.length) return
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(acceptedFiles[0])
  }, [acceptedFiles])

  const form = useForm<ProfileForm>({
    mode: 'onChange',
    defaultValues: {
      firstName: auth.user?.firstName || '',
      lastName: auth.user?.lastName || '',
    },
  })

  useEffect(() => {
    if (!auth.user) return
    form.reset({ firstName: auth.user?.firstName || '', lastName: auth.user?.lastName || '' })
  }, [auth.user])

  const [updateUserMutation, { loading: updateUserLoading }] = useMutation<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >(gql(updateCustomer), {
    client: CognitoApolloClient,
  })

  useEffect(() => {
    if (!auth.user?.id) return
    const subOnUpdateCustomer = API.graphql({
      query: gql(onUpdateCustomer),
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      // @ts-ignore
    }).subscribe({
      next: ({ value }: ISubUpdateCustomerValue) => {
        if (value.data?.onUpdateCustomer?.id === auth.user?.id) {
          setUpdateCustomerSubData(value.data)
        }
      },
      error: (error: GraphQLErrors) => {
        if (error) {
          console.error('Error on update customer profile', error)
          // window.location.reload()
        }
      },
    })
    return () => {
      subOnUpdateCustomer.unsubscribe()
    }
  }, [auth.user?.id])

  useEffect(() => {
    if (!updateCustomerSubData) return
    const updateUserProfilePhoto = async () => {
      await auth.updateUser({
        ...auth.user,
        profilePhoto: {
          key: updateCustomerSubData?.onUpdateCustomer?.profilePhoto?.key,
          bucket: updateCustomerSubData?.onUpdateCustomer?.profilePhoto?.bucket,
        },
      })
    }
    updateUserProfilePhoto()
  }, [updateCustomerSubData])

  useEffect(() => form.setValue('firstName', auth.user?.firstName || ''), [auth.user?.firstName])
  useEffect(() => form.setValue('lastName', auth.user?.lastName || ''), [auth.user?.lastName])

  const submit = form.handleSubmit(async (values) => {
    const { firstName, lastName } = values

    try {
      setLoading(true)
      const user = await Auth.currentAuthenticatedUser()
      await updateUserMutation({ variables: { input: { id: user.attributes.sub, firstName, lastName } } })
      const result = await Auth.updateUserAttributes(user, {
        given_name: firstName,
        family_name: lastName,
      })

      if (result === 'SUCCESS') {
        await auth.updateUser({
          ...auth.user,
          firstName,
          lastName,
        })

        form.reset(
          { firstName, lastName },
          {
            isDirty: false,
            touched: false,
          },
        )
      }

      open({ type: 'INFO', text: g('common.profileUpdateSuccess') })
    } catch (err: any) {
      open({ type: 'ERROR', text: err.message })
    } finally {
      setLoading(false)
    }
  })

  const handleImageCroppingSubmit = async () => {
    const imageElement: any = cropperRef?.current
    const cropper: Cropper = imageElement?.cropper

    if (typeof cropper !== 'undefined') {
      setImage(null)
      const blob = cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.7)
      const file = dataURItoBlob(blob)
      await uploadImage(file as File)
    }
  }

  const handleCloseCropperModal = () => {
    if (!photoLoading) {
      setImage(null)
    }
  }

  const uploadImage = async (file: File) => {
    if (!auth.user?.id) return
    if (file.size > 10485760) {
      open({ type: 'ERROR', text: g('common.avatarSizeError') })
      return
    }
    try {
      setPhotoLoading(true)
      // @ts-ignore
      const S3Img: { key: string } = await Storage.put(`protected/${auth.user?.id}/profile/${v4()}.jpg`, file, {
        contentType: 'image/jpg',
        useAccelerateEndpoint: true,
      })
      await updateUserMutation({
        variables: {
          input: {
            id: auth.user?.id,
            profilePhoto: { key: S3Img.key, bucket: awsmobile.aws_user_files_s3_bucket },
          },
        },
      })
      const photoUrl = await Storage.get(S3Img.key)
      await auth.updateUser({
        ...auth.user,
        profilePhotoUrl: photoUrl as string,
        profilePhoto: { key: S3Img.key, bucket: awsmobile.aws_user_files_s3_bucket },
      })
      open({ type: 'INFO', text: t('profile.avatarUploadSuccess') })
    } catch (e: any) {
      console.error(e)
      open({ type: 'ERROR', text: t('profile.avatarUploadError') })
    } finally {
      setPhotoLoading(false)
    }
  }

  const removeImage = async () => {
    if (!auth.user?.id || !auth.user.profilePhoto?.key) return
    try {
      setPhotoDeleting(true)
      await Promise.all([
        Storage.remove(auth.user.profilePhoto?.key),
        updateUserMutation({
          variables: {
            input: { id: auth.user?.id, profilePhoto: null },
          },
        }),
        auth.updateUser({ ...auth.user, pending: false, profilePhotoUrl: undefined, profilePhoto: undefined }),
      ])
      open({ type: 'INFO', text: g('common.profileUpdateSuccess') })
      setRemoveModalVisible(false)
    } catch (e: any) {
      console.error(e)
      open({ type: 'ERROR', text: t('profile.avatarDeleteError') })
    } finally {
      setPhotoDeleting(false)
    }
  }

  const showRemoveIcon = () => {
    setRemovePhotoIconVisible(true)
  }

  const hideRemoveIcon = () => {
    setRemovePhotoIconVisible(false)
  }

  const showDeletePhotoModal = () => {
    setRemoveModalVisible(true)
  }

  const hideDeletePhotoModal = () => {
    setRemoveModalVisible(false)
  }

  return {
    form: { submit, ...form },
    uploadImage,
    showRemoveIcon,
    hideRemoveIcon,
    removePhotoIconVisible,
    showDeletePhotoModal,
    hideDeletePhotoModal,
    removeModalVisible,
    removeImage,
    photoLoading,
    photoDeleting,
    updateUserLoading,
    handleCloseCropperModal,
    cropperRef,
    handleImageCroppingSubmit,
    image,
    dropzone: {
      getRootProps,
      getInputProps,
    },
    loading,
  }
}

export type UseProfile = ReturnType<typeof useProfileHook>
export default useProfileHook
