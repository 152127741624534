import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Check } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { IValidatorBlock } from '../../common/types/Auth'

const ValidatorBlock = (props: IValidatorBlock) => {
  return (
    <Box display="flex" alignItems="center" width="100%" marginTop="6px">
      {props.error ? (
        <CloseIcon style={{ color: props.theme.palette.error.main }} />
      ) : (
        <Check
          style={{
            color: props.isDirty ? props.theme.palette.primary.main : props.theme.palette.text.secondary,
          }}
        />
      )}
      <Typography
        variant="body2"
        color="textSecondary"
        style={{
          marginLeft: '7px',
          fontSize: '12px',
          color: props.error
            ? props.theme.palette.error.main
            : props.isDirty
            ? props.theme.palette.primary.main
            : props.theme.palette.text.secondary,
        }}
      >
        {props.text}
      </Typography>
    </Box>
  )
}

export default ValidatorBlock
