import UploadQueue from 'common/services/UploadQueue'
import { useReducer } from 'react'
import MediaRecorderHandler from './MediaRecorderHandler'

export type RecorderStateType = {
  countdown: boolean
  record: boolean
  upload: boolean
  startover: boolean
  startoverCountdown: number
  uploadError: boolean
  waitingFile: boolean
  active: boolean
  countdownProgress: number
  uploadProgress: number
  duration: number
  cameraStream: undefined | MediaStream
  channelId?: string | null
  initialPermissionsVisible: boolean
  screenPermissionDeniedBySystem: boolean
  cameraPermissionDeniedBySystem: boolean
  microphonePermissionDeniedBySystem: boolean
  prompter: {
    text: string
    currentState: undefined | 'SETUP' | 'RECORDING'
    textSpeed: number
    offset: number
    fontSize: number
    isHidden: boolean
    title: ''
    textEditing: boolean
  }
}

export const recorderInitialState: RecorderStateType = {
  countdown: false,
  record: false,
  upload: false,
  startover: false,
  startoverCountdown: 0,
  uploadError: false,
  waitingFile: false,
  active: false,
  countdownProgress: 0,
  uploadProgress: 0,
  duration: 0,
  cameraStream: undefined,
  initialPermissionsVisible: false,
  screenPermissionDeniedBySystem: false,
  cameraPermissionDeniedBySystem: false,
  microphonePermissionDeniedBySystem: false,
  channelId: null,
  prompter: {
    text: '',
    currentState: undefined,
    textSpeed: 60,
    offset: 0,
    fontSize: 31,
    isHidden: false,
    title: '',
    textEditing: true,
  },
}

export type RecorderStateAction =
  | 'COUNTDOWN_PROGRESS'
  | 'DURATION'
  | 'START_RECORD'
  | 'SET_CAMERA_STREAM'
  | 'STARTOVER_PROGRESS'
  | 'STOP_RECORD'
  | 'START_UPLOAD'
  | 'END_UPLOAD'
  | 'UPLOAD_PROGRESS'
  | 'UPLOAD_ERROR'
  | 'CANCEL'
  | 'CANCEL_WITH_PROMPTER'
  | 'UPDATE_PROMPTER_STATE'
  | 'SET_SCREEN_PERMISSION_ERROR'
  | 'SET_CAMERA_PERMISSION-ERROR'
  | 'SET_MICROPHONE_PERMISSION-ERROR'
  | 'SET_INITIAL_PERMISSIONS_VISIBLE'

export type RecorderState = typeof recorderInitialState
export interface RecorderStateDispatch {
  action: RecorderStateAction
  value?: any
}

const reducer = (state: RecorderState, action: RecorderStateDispatch): RecorderState => {
  switch (action.action) {
    case 'COUNTDOWN_PROGRESS':
      return {
        ...state,
        countdown: true,
        record: false,
        upload: false,
        uploadError: false,
        waitingFile: false,
        active: true,
        countdownProgress: action.value || 0,
        uploadProgress: 0,
      }
    case 'STARTOVER_PROGRESS':
      return {
        ...state,
        countdown: false,
        record: false,
        upload: false,
        uploadError: false,
        waitingFile: false,
        active: true,
        startover: true,
        startoverCountdown: action.value || 0,
        countdownProgress: 0,
        uploadProgress: 0,
      }
    case 'DURATION':
      return {
        ...state,
        duration: action.value as number,
      }
    case 'SET_CAMERA_STREAM':
      return {
        ...state,
        cameraStream: action.value || undefined,
      }
    case 'START_RECORD':
      return {
        ...state,
        countdown: false,
        record: true,
        upload: false,
        uploadError: false,
        waitingFile: false,
        active: true,
        countdownProgress: 0,
        uploadProgress: 0,
        duration: 0,
        startover: false,
        startoverCountdown: 0,
        channelId: action.value,
      }
    case 'STOP_RECORD':
      return {
        ...state,
        countdown: false,
        record: false,
        upload: false,
        uploadError: false,
        waitingFile: false,
        active: true,
        countdownProgress: 0,
        uploadProgress: 0,
        duration: 0,
        startover: false,
        startoverCountdown: 0,
        cameraStream: undefined,
        channelId: null,
      }
    case 'START_UPLOAD':
      return {
        ...state,
        countdown: false,
        record: false,
        upload: true,
        uploadError: false,
        waitingFile: false,
        active: true,
        countdownProgress: 0,
        startover: false,
        startoverCountdown: 0,
      }
    case 'UPLOAD_PROGRESS':
      return {
        ...state,
        countdown: false,
        record: false,
        upload: true,
        uploadError: false,
        waitingFile: false,
        active: true,
        countdownProgress: 0,
        uploadProgress: action.value || 0,
        startover: false,
        startoverCountdown: 0,
      }
    case 'UPLOAD_ERROR':
      return {
        ...state,
        countdown: false,
        record: false,
        upload: true,
        uploadError: true,
        waitingFile: false,
        active: true,
        countdownProgress: 0,
        startover: false,
        startoverCountdown: 0,
      }
    case 'END_UPLOAD':
    case 'CANCEL':
      return {
        ...state,
        countdown: false,
        record: false,
        upload: false,
        uploadError: false,
        waitingFile: false,
        active: false,
        countdownProgress: 0,
        uploadProgress: 0,
        duration: 0,
        startover: false,
        startoverCountdown: 0,
        cameraStream: undefined,
        screenPermissionDeniedBySystem: false,
        cameraPermissionDeniedBySystem: false,
        microphonePermissionDeniedBySystem: false,
        prompter: {
          ...state.prompter,
          text: '',
          currentState: undefined,
          offset: 0,
          title: '',
        },
      }
    case 'CANCEL_WITH_PROMPTER':
      return {
        ...state,
        countdown: false,
        record: false,
        upload: false,
        uploadError: false,
        waitingFile: false,
        active: false,
        countdownProgress: 0,
        uploadProgress: 0,
        duration: 0,
        startover: false,
        startoverCountdown: 0,
        cameraStream: undefined,
      }

    case 'UPDATE_PROMPTER_STATE':
      return {
        ...state,
        prompter: {
          ...state.prompter,
          ...action.value,
        },
      }
    case 'SET_SCREEN_PERMISSION_ERROR':
      return {
        ...state,
        screenPermissionDeniedBySystem: true,
      }
    case 'SET_CAMERA_PERMISSION-ERROR':
      return {
        ...state,
        cameraPermissionDeniedBySystem: true,
      }
    case 'SET_MICROPHONE_PERMISSION-ERROR':
      return {
        ...state,
        microphonePermissionDeniedBySystem: true,
      }
    case 'SET_INITIAL_PERMISSIONS_VISIBLE':
      return {
        ...state,
        initialPermissionsVisible: action.value,
      }
  }

  return { ...state }
}

const useRecorderProvider = () => {
  const [state, dispatch] = useReducer(reducer, recorderInitialState)
  MediaRecorderHandler.setDispatch(dispatch)
  UploadQueue.setDispatch(dispatch)

  return {
    state,
    dispatch,
  }
}

export type UseRecorderProvider = ReturnType<typeof useRecorderProvider>
export default useRecorderProvider
