import React from 'react'
import ContactsHeader from '../../../../containers/Contacts/ContactsHeader'
import ContactsList from '../../../../containers/Contacts/ContactsList'
import NewContactModal from '../../../../containers/Contacts/NewContactModal'
import { screensightContact } from '../../../../API'

interface IContactsView {
  newContactModalVisible: boolean
  showNewContactModal: () => void
  hideNewContactModal: () => void
  handleSearchPhraseChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  searchPhrase: string
  searchValue: string
  hasMore: boolean
  getNextContacts: () => void
  loading: boolean
  contacts: screensightContact[]
}

const ContactsView = (props: IContactsView) => {
  return (
    <>
      <ContactsHeader
        showNewContactModal={props.showNewContactModal}
        handleSearchPhraseChange={props.handleSearchPhraseChange}
        searchValue={props.searchValue}
      />
      <ContactsList
        showNewContactModal={props.showNewContactModal}
        searchPhrase={props.searchPhrase}
        searchValue={props.searchValue}
        hasMore={props.hasMore}
        getNextContacts={props.getNextContacts}
        loading={props.loading}
        contacts={props.contacts}
      />
      {props.newContactModalVisible && <NewContactModal hideNewContactModal={props.hideNewContactModal} />}
    </>
  )
}

export default ContactsView
