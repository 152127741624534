import {
  ListVideoAudienceQueryVariables,
  PostEngagementConnection,
  screensightVideo,
} from '../../../../../../../../API'
import { useHistory } from 'react-router-dom'
import * as queryString from 'query-string'
import { useLocation } from 'react-router'
import { useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import useApolloQuery from '../../../../../../../../common/clients/useApolloQuery'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../common/redux/store/store'
import { videoPostAudience, VideoPostAudienceQuery } from '../api/videoPostAudience.query'
import useVideoSeenStatus from './useVideoSeenStatus'

const useVideoPost = (
  video: screensightVideo,
  postId: string,
  owner?: boolean,
  engagement?: PostEngagementConnection | null,
) => {
  const auth = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation()
  const partnerId = useSelector((state: RootState) => state.chat.chatCustomers?.partner?.id)
  const seenVideoPostIds = useSelector((state: RootState) => state.chat.seenVideoPostIds)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(`(max-width:1150px)`)

  const [copied, setCopied] = useState(false)

  const wasVideoWatched = localStorage.getItem(`chatPlayerWatched:${video.id}`) === 'true'

  const videoProgress = owner
    ? engagement?.items?.find((item) => item?.customerId === partnerId)?.videoProgress || 0
    : wasVideoWatched
    ? video.duration
    : (engagement?.items?.find((item) => item?.customerId === auth.user?.id)?.videoProgress || 0) >
      Number(localStorage.getItem(`player:${video.id}`)) * 1000
    ? engagement?.items?.find((item) => item?.customerId === auth.user?.id)?.videoProgress
    : Number(localStorage.getItem(`player:${video.id}`)) * 1000

  const queryStringVideoId = queryString.parse(location.search)?.play

  useVideoSeenStatus(owner)

  const onCopied = useCallback(() => {
    setCopied(true)
    setTimeout(() => setCopied(false), 4000)
  }, [])

  const { data: videoPostAudienceData, loading: videoPostAudienceLoading } = useApolloQuery<
    VideoPostAudienceQuery,
    ListVideoAudienceQueryVariables
  >(videoPostAudience, {
    variables: { videoId: video?.id, customerId: { eq: owner ? partnerId : auth.user?.id } },
    skip: !video?.id || !auth.isAuthenticated,
    client: CognitoApolloClient,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  const handleOpenVideoModal = () => {
    if (queryStringVideoId === video?.accessId && queryStringVideoId === video?.id) return
    history.push({
      search: `?play=${video?.accessId || video.id}&postId=${postId}`,
    })
  }

  return {
    auth,
    handleOpenVideoModal,
    history,
    theme,
    isMobile,
    isTablet,
    copied,
    onCopied,
    audience: videoPostAudienceData?.listVideoAudience?.items,
    audienceLoading: videoPostAudienceLoading,
    seenVideoPostIds,
    videoProgress,
  }
}

export type TUseVideoPost = ReturnType<typeof useVideoPost>
export default useVideoPost
