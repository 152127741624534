import React, { FC } from 'react'
import AuthLayout from '../../ui/layouts/Auth.layout'
import { useMediaQuery } from '@mui/material'
import PatentNote from '../../../../common/providers/ClientAuthProvider/PatentNote'
import { Verify } from './common/config'
import PageTitle from '../../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

const VerifyPage: FC = () => {
  const isTablet = useMediaQuery(`(max-width:1024px)`)
  const { t: g } = useTranslation('general')

  if (isTablet) {
    return (
      <AuthLayout>
        <PageTitle name={g('pageName.verifyEmail')} />
        <Verify.Tablet.Layout>
          <Verify.Tablet.Content />
        </Verify.Tablet.Layout>
        <PatentNote styles={{ padding: '20px', marginTop: '50px' }} />
      </AuthLayout>
    )
  }

  return (
    <AuthLayout>
      <PageTitle name={g('pageName.verifyEmail')} />
      <Verify.Desktop.Layout>
        <Verify.Desktop.CompanyAside />
        <Verify.Desktop.UserAside />
      </Verify.Desktop.Layout>
      <PatentNote styles={{ position: 'absolute', bottom: '30px', left: '30px', zIndex: 10 }} />
    </AuthLayout>
  )
}

export default VerifyPage
