import * as React from 'react'
import { Box } from '@mui/material'
import { GetCustomerQuery, GetCustomerQueryVariables, screensightCustomer } from '../../../../../../../API'
import UserIcon from '../../../../../../../common/icons/UserIcon'
import UserDetail from '../components/UserDetail'
import MailIcon from '../../../../../../../common/icons/MailIcon'
import { useTranslation } from 'react-i18next'
import TimeZoneIcon from '../../../../../../../common/icons/TimeZoneIcon'
import CalendarIcon from '../../../../../../../common/icons/CalendarIcon'
import GearIcon from '../../../../../../../common/icons/GearIcon'
import StripeIdIcon from '../../../../../../../common/icons/StripeIdIcon'
import { getCustomer } from '../../../../../../../graphql/queries'
import useApolloQuery from '../../../../../../../common/clients/useApolloQuery'
import moment from 'moment/moment'
import { useContext, useEffect } from 'react'
import ToastContext from '../../../../../../../common/providers/ToastProvider/ToastContext'

interface IAccountDetailsProps {
  partner: screensightCustomer | null | undefined
  userName: string
}
const AccountDetails = (props: IAccountDetailsProps) => {
  const { t } = useTranslation('support')
  const toastContext = useContext(ToastContext)
  const { data, loading, error } = useApolloQuery<GetCustomerQuery, GetCustomerQueryVariables>(getCustomer, {
    variables: { id: props.partner?.id || '' },
    skip: !props.partner?.id,
  })

  useEffect(() => {
    if (!error) return
    toastContext.open({ type: 'ERROR', text: t('accountSidebar.detailsLoadingError') })
  }, [error])

  return (
    <Box>
      <UserDetail loading={loading} data={props.userName} label={t('accountSidebar.fullName')} icon={<UserIcon />} />
      <UserDetail
        loading={loading}
        data={data?.getCustomer?.email}
        label={t('accountSidebar.email')}
        icon={<MailIcon color={'black'} />}
      />
      <UserDetail
        loading={loading}
        data={data?.getCustomer?.settings?.timezone}
        label={t('accountSidebar.timeZone')}
        icon={<TimeZoneIcon />}
      />
      <UserDetail
        loading={loading}
        data={moment(data?.getCustomer?.createdAt).format('MMM DD, YYYY, h:mm A')}
        label={t('accountSidebar.signUpDate')}
        icon={<CalendarIcon />}
      />
      <UserDetail
        loading={loading}
        data={null}
        settings={data?.getCustomer?.settings}
        label={t('accountSidebar.settings')}
        icon={<GearIcon />}
      />
      <UserDetail
        loading={loading}
        data={data?.getCustomer?.stripeId || '-'}
        label={t('accountSidebar.stripeId')}
        icon={<StripeIdIcon />}
      />
    </Box>
  )
}
export default AccountDetails
