import React, { RefObject, useContext } from 'react'
import { Box, Fade, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { parseNativeEmoji } from '../../../PostList/Reaction'
import Button from '../../../../../../../../../../components/_refactored/Button'
import { createPortal } from 'react-dom'
import { Close } from '@mui/icons-material'
import { VideoJsPlayer } from 'video.js'
import { AuthContext } from '../../../../../../../../../../common/providers/AuthStatusProvider'
import useCachedCustomerById from '../../../../../../../../../../common/utils/useCachedCustomerById'
import { getUsername } from '../../../../../../../../../../common/utils/getUsername'
import AddIcon from '@mui/icons-material/Add'
import { ClickAwayListener } from '@mui/base'
import Picker from 'emoji-picker-react'
import { useTranslation } from 'react-i18next'
import usePlayerReactins from '../../common/hooks/usePlayerReactins'
import useEmojiCategories from '../../common/hooks/useEmojiCategories'
import PlayerTopOverlay from '../components/PlayerTopOverlay'

interface IPlayerReactions {
  wrapperRef: RefObject<HTMLElement>
  postId?: string
  videoDuration?: number | null
  playerRef: React.MutableRefObject<VideoJsPlayer | null>
  videoId: string
  accessId: string | null | undefined
}

const PartnerInfo = ({ id }: { id: string }) => {
  const cachedPartnerMeta = useCachedCustomerById(id)
  return (
    <>{getUsername(cachedPartnerMeta?.firstName, cachedPartnerMeta?.lastName, cachedPartnerMeta?.email) + ' reacted'}</>
  )
}

const REACTION_CODES = ['1f44d', '1f44e', '2764-fe0f', '1f525', '2714-fe0f', '1f44f']
const ANIMATION_DURATION = 1.3 // [seconds]
const PlayerReactions = (props: IPlayerReactions) => {
  const {
    onEmojiClick,
    moveAnimation,
    animationObj,
    getFinalReactionX,
    reactionsPickerVisible,
    handleReactionsPickerVisibility,
    handleRemoveReaction,
    elem,
    wrapperWidth,
    data,
    setCurrentReactionHover,
    currentReactionHover,
  } = usePlayerReactins(props?.wrapperRef?.current?.clientWidth, props.videoDuration, props.postId)

  const auth = useContext(AuthContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const categories = useEmojiCategories()
  const { t: g } = useTranslation('general')

  return (
    <>
      <Box
        bgcolor="#F8F9FB"
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="5px"
        height="40px"
        width="100%"
      >
        {REACTION_CODES.map((code, key) => (
          <Button
            className={`chat__player-reaction-${code}`}
            key={key}
            onClick={() => onEmojiClick(code)}
            sx={{
              background: 'transparent',
              width: '22px',
              minWidth: '22px',
              padding: 0,
              margin: '0 4px',
              height: '22px',
              fontSize: '22px',
              transition: '.5s',
              animation:
                animationObj?.code === code
                  ? `${moveAnimation(
                      getFinalReactionX(animationObj.reactionX, animationObj.videoTimestamp || 0),
                      83,
                    )} ${ANIMATION_DURATION}s`
                  : 'none',
              animationTimingFunction: 'ease',
            }}
          >
            {parseNativeEmoji(code)}
          </Button>
        ))}
        <Box className={'anchor-player'} position="relative">
          <IconButton
            onClick={() => handleReactionsPickerVisibility(true)}
            style={{
              boxShadow: 'none',
              backgroundColor: 'transparent',
              width: '20px',
              height: '20px',
              marginLeft: '2px',
            }}
          >
            <AddIcon color={'secondary'} />
          </IconButton>
          {reactionsPickerVisible && (
            <ClickAwayListener
              onClickAway={() => handleReactionsPickerVisibility(false)}
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
            >
              <Box
                position={'absolute'}
                sx={{
                  bottom: 0,
                  right: 0,
                  zIndex: 999999,
                  transform: isMobile ? `translate(20%, -15%)` : `translate(65%, -10%)`,
                }}
              >
                <Picker
                  previewConfig={{ showPreview: false }}
                  searchPlaceHolder={g('common.searchInputPlaceholder')}
                  onEmojiClick={onEmojiClick}
                  autoFocusSearch={false}
                  width={`${isMobile ? '300px' : '349px'}`}
                  height={`${isMobile ? '300px' : '380px'}`}
                  categories={categories}
                />
              </Box>
            </ClickAwayListener>
          )}
        </Box>
      </Box>
      {elem &&
        createPortal(
          <>
            <PlayerTopOverlay videoId={props.videoId} accessId={props.accessId} />
            <Box className="player_reactions-wrapper" width={`${wrapperWidth}px`} position="relative">
              {data?.map((reaction, key) => {
                return (
                  <Box
                    key={key}
                    onMouseEnter={() =>
                      setCurrentReactionHover({ code: reaction.unicode, videoTimestamp: reaction.videoTimestamp })
                    }
                    onMouseLeave={() => setCurrentReactionHover(null)}
                  >
                    <Box
                      visibility={
                        reaction.unicode === currentReactionHover?.code &&
                        reaction.videoTimestamp === currentReactionHover?.videoTimestamp
                          ? 'visible'
                          : 'hidden'
                      }
                      position="absolute"
                      bottom="63px"
                      left={`${
                        (((elem?.getBoundingClientRect()?.width || wrapperWidth) - 20) / (props.videoDuration || 1)) *
                          1000 *
                          reaction.videoTimestamp -
                        31
                      }px`}
                      sx={{ transform: 'translateX(-50%)' }}
                    >
                      <Fade
                        in={
                          reaction.unicode === currentReactionHover?.code &&
                          reaction.videoTimestamp === currentReactionHover?.videoTimestamp
                        }
                      >
                        <Box padding="8px" borderRadius="6px" bgcolor="#171717" marginBottom="20px">
                          {reaction.customerId === auth.user?.id ? (
                            <Box display="flex" alignItems="center">
                              <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>You reacted</Typography>
                              <IconButton
                                sx={{
                                  padding: '2px!important',
                                  boxShadow: 'none',
                                  height: 'fit-content!important',
                                  width: 'fit-content!important',
                                  fontSize: '12px',
                                  marginBottom: '-2px',
                                }}
                                onClick={async () => {
                                  await handleRemoveReaction(reaction)
                                }}
                              >
                                <Close sx={{ width: '18px', height: '18px' }} />
                              </IconButton>
                            </Box>
                          ) : (
                            <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
                              <PartnerInfo id={reaction.customerId} />
                            </Typography>
                          )}
                        </Box>
                      </Fade>
                    </Box>
                    <Button
                      key={`${key}-${reaction.createdAt}`}
                      sx={{
                        background: 'transparent',
                        width: '22px',
                        minWidth: '22px',
                        padding: 0,
                        margin: '0 4px',
                        height: '22px',
                        fontSize: `22px!important`,
                        position: 'absolute',
                        bottom: '53px',
                        visibility:
                          animationObj?.videoTimestamp === reaction.videoTimestamp &&
                          animationObj.code === reaction.unicode
                            ? 'hidden'
                            : 'visible',
                        left: `${
                          (((elem?.getBoundingClientRect()?.width || wrapperWidth) - 20) / (props.videoDuration || 1)) *
                            1000 *
                            reaction.videoTimestamp -
                          31
                        }px`,
                        zIndex: 100000 - key,
                        transform: 'translateX(-50%)',
                      }}
                      onClick={() => {
                        if (props.playerRef.current) {
                          props.playerRef.current?.player().currentTime(reaction.videoTimestamp)
                          props.playerRef.current?.player().play()
                        }
                      }}
                    >
                      {parseNativeEmoji(reaction?.unicode)}
                    </Button>
                  </Box>
                )
              })}
            </Box>
          </>,
          elem,
        )}
    </>
  )
}

export default PlayerReactions
