import { RecordTypeAction } from 'common/providers/RecorderStateProvider/MediaRecorderHandler/MediaRecorderHandler.types'
import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import moment from 'moment/moment'
import { VideoNewModalProps } from '../index'
import MediaRecorderHandler from '../../../../common/providers/RecorderStateProvider/MediaRecorderHandler'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import useCameraWithPrompterPreview from '../../../VideoRecorder/CameraWithPrompterPreview/hooks/useCameraWithPrompterPreview'
import { config } from '../../../../common/config'
import { v4 } from 'uuid'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import {
  CreateVideoMutation,
  CreateVideoMutationVariables,
  DeleteVideoMutation,
  DeleteVideoMutationVariables,
  VideoStatus,
} from '../../../../API'
import gql from 'graphql-tag'
import { createVideo, deleteVideo } from '../../../../graphql/mutations'
import ToastContext from '../../../../common/providers/ToastProvider/ToastContext'
import { useTranslation } from 'react-i18next'
import { Storage } from 'aws-amplify'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../pages/Chat/common/redux/store/store'

const recordTypeInitialState = {
  screen: true,
  screenAndCam: false,
  cam: false,
  type: 'SCREEN' as RecordTypeAction,
}

export type RecordTypeState = typeof recordTypeInitialState

const recordTypeReducer = (state: RecordTypeState, action: RecordTypeAction): RecordTypeState => {
  switch (action) {
    case 'SCREEN':
      return {
        ...state,
        screen: true,
        screenAndCam: false,
        cam: false,
        type: action,
      }
    case 'SCREENANDCAM':
      return {
        ...state,
        screen: false,
        screenAndCam: true,
        cam: false,
        type: action,
      }
    case 'CAM':
      return {
        ...state,
        screen: false,
        screenAndCam: false,
        cam: true,
        type: action,
      }
  }
  return { ...state }
}

export interface NewVideoForm {
  title: string
  type: RecordTypeAction
  teleprompter?: boolean
}

const useVideoNewModalHook = (props: VideoNewModalProps) => {
  const auth = useContext(AuthContext)
  const toastContext = useContext(ToastContext)
  const [recordTypeState, recordTypeDispatch] = useReducer(recordTypeReducer, recordTypeInitialState)
  const fileInputRef = useRef(null)
  const titleInputRef = useRef(null)
  const [prompterModalVisible, setPrompterModalVisible] = useState(false)
  const [extensionModalVisible, setExtensionModalVisible] = useState(false)
  const [isVideoUploading, setIsVideoUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const { id: channelId } = useParams() as { id: string }
  const [extensionInstalled, setExtensionInstalled] = useState(false)
  const defaultVideoTitle = `Recorded ${moment(Date.now()).format('MMM DD, YYYY, h:mm A')}`
  const { showPrompterWindow } = useCameraWithPrompterPreview()
  const { t: g } = useTranslation('general')
  const [uploadStorageObject, setUploadStorageObject] = useState<any>(null)
  const [uploadedVideoId, setUploadedVideoId] = useState<string | null | undefined>(null)
  const [isUploadPaused, setIsUploadPaused] = useState(false)
  const [selectedRecordingType, setSelectedRecordingType] = useState(
    (localStorage.getItem('recodingType') as RecordTypeAction) || 'SCREEN',
  )
  const videoLimitReached = useSelector((state: RootState) => state.chat.videosLimitReached)

  const { handleSubmit, register, errors, formState, watch, control, reset } = useForm<NewVideoForm>({
    mode: 'onChange',
    defaultValues: {
      title: '',
    },
  })

  useEffect(() => {
    if (videoLimitReached) {
      props.onClose()
    }
  }, [videoLimitReached])

  useEffect(() => {
    register('type')
  }, [register])

  useEffect(() => {
    reset({ ...watch(), title: props.defaultTitle })
  }, [props.defaultTitle])

  useEffect(() => {
    if (!titleInputRef.current) return
    // @ts-ignore
    titleInputRef.current?.focus()
  }, [selectedRecordingType])

  useEffect(() => {
    // @ts-ignore
    if (window?.chrome?.runtime && window?.chrome?.runtime?.sendMessage) {
      // chrome runtime exists so we can check if extension is installed
      // @ts-ignore
      chrome.runtime.sendMessage(
        process.env.REACT_APP_CHROME_EXTENSION_ID,
        { message: 'IS_EXTENSION_INSTALLED' },
        function (response: any) {
          if (response?.success) {
            setExtensionInstalled(true)
            return
          }
          // @ts-ignore
          if (chrome?.runtime?.lastError) {
            console.warn(
              'Connection with Chrome extension error:',
              // @ts-ignore
              chrome?.runtime?.lastError?.message,
            )
          }
          setExtensionInstalled(false)
        },
      )
    } else {
      setExtensionInstalled(false)
      // runtime is unavailable so it's wrong browser or extension is not installed
    }
  }, [props.open])

  useEffect(() => {
    setSelectedRecordingType((localStorage.getItem('recodingType') as RecordTypeAction) || 'SCREEN')
  }, [props.open])

  const handleBlockReloadPage = (ev: BeforeUnloadEvent) => {
    ev.preventDefault()
    return (ev.returnValue = g('recordControl.reloadPage.message'))
  }

  useEffect(() => {
    if (!isVideoUploading) return
    window.addEventListener('beforeunload', handleBlockReloadPage)
    return () => window.removeEventListener('beforeunload', handleBlockReloadPage)
  }, [isVideoUploading])

  const startRecording = async ({ title, teleprompter }: { title: string; teleprompter?: boolean }) => {
    if (teleprompter) {
      await MediaRecorderHandler.setupPrompter(title.trim() ? title : defaultVideoTitle, channelId)
      showPrompterWindow()
      props.onClose()
      return
    }
    await MediaRecorderHandler.initRecording({
      title: title.trim() ? title : defaultVideoTitle,
      type: selectedRecordingType,
      channelId: props?.channelId || undefined,
      enableSplashScreen: auth.user?.settings?.enableSplashScreen || false,
    })
    props.onClose()
  }

  const submit = handleSubmit(async ({ title, teleprompter }) => {
    await startRecording({ title, teleprompter })
  })

  const hidePrompterModal = () => {
    setPrompterModalVisible(false)
  }

  const setScreenValue = () => {
    setExtensionModalVisible(false)
    localStorage.setItem('recodingType', 'SCREEN')
    setSelectedRecordingType('SCREEN')
  }

  const setCameraValue = () => {
    setExtensionModalVisible(false)
    localStorage.setItem('recodingType', 'CAM')
    setSelectedRecordingType('CAM')
  }

  const setCameraAndScreenValue = () => {
    setExtensionModalVisible(true)
    setSelectedRecordingType('SCREENANDCAM')
  }

  const handleUploadVideoInputClick = (event: any) => {
    // @ts-ignore
    fileInputRef?.current?.click()
  }

  const getVideoResolution = (video: File) => {
    return new Promise<{ width: number; height: number }>((resolve, reject) => {
      const videoElement = document.createElement('video')
      videoElement.preload = 'metadata'
      videoElement.onloadedmetadata = function () {
        window.URL.revokeObjectURL(videoElement.src)
        resolve({ width: videoElement.videoWidth, height: videoElement.videoHeight })
      }
      videoElement.onerror = function (error) {
        reject(error)
      }
      videoElement.src = URL.createObjectURL(video)
    })
  }

  const progressCallback = (progress: any) => {
    setUploadProgress((progress.loaded / progress.total) * 100)
  }

  // method which cancel upload and delete video from db
  const handleCancelS3Upload = async () => {
    if (!uploadStorageObject || !uploadedVideoId) return
    const cancelRes = await Storage.cancel(uploadStorageObject)
    if (cancelRes) {
      await CognitoApolloClient.mutate<DeleteVideoMutation, DeleteVideoMutationVariables>({
        mutation: gql(deleteVideo),
        variables: {
          input: {
            id: uploadedVideoId,
          },
        },
      })
      setIsVideoUploading(false)
      setIsUploadPaused(false)
      setUploadProgress(0)
      props.onClose()
    }
  }

  // method which pause or resume upload
  const toggleUploadStatus = async () => {
    if (!uploadStorageObject) return
    if (isUploadPaused) {
      uploadStorageObject.resume()
      setIsUploadPaused(false)
    } else {
      uploadStorageObject.pause()
      setIsUploadPaused(true)
    }
  }

  const handleUploadingProcess = async (event: any) => {
    let videoId: string | undefined
    if (event.target.files[0]?.size > 10737418240) {
      console.error('File is too big')
      toastContext.open({ type: 'ERROR', text: g('recordControl.newVideoModal.maxSizeError') })
      props.onClose()
      return
    }

    const videoResolution = await getVideoResolution(event.target.files[0])

    try {
      setIsVideoUploading(true)
      const { data } = await CognitoApolloClient.mutate<CreateVideoMutation, CreateVideoMutationVariables>({
        mutation: gql(createVideo),
        variables: {
          input: {
            title: watch('title')?.trim() ? watch('title') : event.target.files[0]?.name.slice(0, -4),
            status: VideoStatus.uploading,
            width: videoResolution.width,
            height: videoResolution.height,
            // @ts-ignore
            uploadedVideo: {
              fileName: event.target.files[0]?.name,
            },
          },
        },
      })
      videoId = data?.createVideo?.id
      setUploadedVideoId(videoId)

      const uploadObject = Storage.put(`videos/${auth.user?.id}/${videoId}.tmp`, event.target.files[0], {
        resumable: true,
        customPrefix: {
          public: 'uploads/',
        },
        useAccelerateEndpoint: true,
        progressCallback: (progress: any) => progressCallback(progress),
        completeCallback: async () => {
          const params = {
            user_id: auth.user?.id,
          }
          const url = `${config.VIDEO_UPLOADING_HOST}/notify_complete`
          await fetch(
            // @ts-ignore
            `${url}/${data?.createVideo?.id || v4()}?` + new URLSearchParams(params),
            {
              method: 'POST',
              headers: {
                project: 'ScreenSight',
              },
            },
          )
          toastContext.open({ type: 'INFO', text: g('recordControl.newVideoModal.uploadedSuccessfully') })
          setIsVideoUploading(false)
          setUploadedVideoId(undefined)
          props.onClose()
          setIsUploadPaused(false)
          setUploadProgress(0)
        },
        errorCallback: async (err: any) => {
          console.error('Error during uploading video', err)
          if (err) {
            setIsUploadPaused(true)
            toastContext.open({ type: 'ERROR', text: g('recordControl.newVideoModal.connectionLost'), time: 10000 })
          }
        },
      })
      setUploadStorageObject(uploadObject)
    } catch (e) {
      console.error('Error during uploading video', e)
      toastContext.open({ type: 'ERROR', text: g('recordControl.newVideoModal.fileFormatError') })
      if (!videoId) return
      setIsVideoUploading(false)
      setUploadProgress(0)
      props.onClose()
      await CognitoApolloClient.mutate<DeleteVideoMutation, DeleteVideoMutationVariables>({
        mutation: gql(deleteVideo),
        variables: {
          input: {
            id: videoId,
          },
        },
      })
    }
  }

  const len = watch('title', '').length

  return {
    form: {
      submit,
      register,
      errors,
      formState,
      control,
      watch,
    },
    len,
    recordTypeState,
    recordTypeDispatch,
    setScreenValue,
    setCameraValue,
    setCameraAndScreenValue,
    selectedRecordingType,
    prompterModalVisible,
    hidePrompterModal,
    extensionModalVisible,
    setExtensionModalVisible,
    extensionInstalled,
    fileInputRef,
    handleUploadVideoInputClick,
    handleUploadingProcess,
    isVideoUploading,
    titleInputRef,
    uploadProgress,
    handleCancelS3Upload,
    toggleUploadStatus,
    isUploadPaused,
  }
}

export type UseVideoNewModal = ReturnType<typeof useVideoNewModalHook>
export default useVideoNewModalHook
