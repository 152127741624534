import React from 'react'
import { FilePost as IFilePost, VideoStatus } from '../../../../../../../../API'
import ImagePost from './ImagePost'
import { isFileType } from '../../../../../../../../common/utils/isFileType'
import { Box, LinearProgress, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AttachmentPost from './AttachmentPost'
import { RootState } from '../../../../../../common/redux/store/store'

interface IFilePostProps {
  file?: IFilePost | null
  postId: string
  owner: boolean
}

const FilePost = (props: IFilePostProps) => {
  const progress = useSelector((state: RootState) => state.chat.uploadFileAttachmentProgress?.[props.postId])
  const { t } = useTranslation('chat')

  if (props.file?.status === VideoStatus.init) {
    return (
      <Box
        height="50px"
        padding="10px"
        bgcolor="primary.main"
        borderRadius="10px"
        marginTop="6px"
        width="200px"
        maxWidth="calc(100% - 10px)"
        position="relative"
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: '12px',
            color: '#FFF',
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontWeight: 500,
          }}
        >
          {t('chatBody.filePost.uploading')}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          maxWidth="100%"
          height="18px"
          sx={{ '& .MuiLinearProgress-bar': { opacity: '0.5' } }}
        >
          <LinearProgress
            color="primary"
            variant="determinate"
            value={Number(progress || 0) * 100}
            sx={{ width: 'calc(100% - 35px)', height: '3px' }}
          />
          {progress && (
            <Typography variant="body1" sx={{ color: 'white', width: '30px', fontSize: '12px', marginLeft: '5px' }}>
              {Math.round(Number(progress || 0) * 100)}%
            </Typography>
          )}
        </Box>
      </Box>
    )
  }
  if (isFileType(props.file?.fileName || '', ['jpg', 'jpeg', 'png', 'gif'])) {
    return <ImagePost file={props.file} isGif={isFileType(props.file?.fileName || '', ['gif'])} />
  }

  if (isFileType(props.file?.fileName || '', ['doc', 'docx', 'xls', 'xlsx', 'pdf'])) {
    return <AttachmentPost file={props.file} owner={props.owner} />
  }

  return (
    <Box padding="10px" borderRadius="10px" marginTop="6px" bgcolor="secondary.main" color="white">
      <Typography variant="body1" sx={{ fontSize: '12px' }}>
        {t('chatBody.filePost.fileTypeError')}
      </Typography>
    </Box>
  )
}

export default FilePost
