import { useContext, useEffect } from 'react'
import { getApplicationConfiguration, getCustomerVideoLimitsData } from '../../../../../graphql/queries'
import { AuthContext } from '../../../../providers/AuthStatusProvider'
import {
  GetApplicationConfigurationQuery,
  GetCustomerVideoLimitsDataQuery,
  GetCustomerVideoLimitsDataQueryVariables,
} from '../../../../../API'
import { setVideosLimitReached } from '../../../../../pages/Chat/common/redux/store/chat-actions'
import { useDispatch } from 'react-redux'
import useApolloLazyQuery from '../../../../clients/useApolloLazyQuery'
import CognitoApolloClient from '../../../../clients/CognitoApolloClient'
import { useLocation } from 'react-router'
import Auth from '@aws-amplify/auth'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import IamApolloClient from '../../../../clients/IamApolloClient'

const useCustomerLimit = () => {
  const auth = useContext(AuthContext)
  const dispatch = useDispatch()
  const location = useLocation()

  const [getCustomerVideoLimits] = useApolloLazyQuery<
    GetCustomerVideoLimitsDataQuery,
    GetCustomerVideoLimitsDataQueryVariables
  >(getCustomerVideoLimitsData, {
    variables: {
      customerId: auth.user?.id as string,
    },
    client: CognitoApolloClient,
  })

  const [getAppConfigData] = useApolloLazyQuery<GetApplicationConfigurationQuery, never>(getApplicationConfiguration, {
    skip: auth.user?.pending,
    client: CognitoApolloClient,
  })

  useEffect(() => {
    const getData = async () => {
      if (!auth.isAuthenticated || !auth.user?.id) return
      const res = await getCustomerVideoLimits()
      if (res.data?.getCustomerVideoLimitsData === null) {
        dispatch(setVideosLimitReached(false))
        return
      }
      dispatch(setVideosLimitReached(!!res?.data?.getCustomerVideoLimitsData?.maxVideosLimitReached))
    }
    getData()
  }, [auth.user?.id, auth.isAuthenticated])

  useEffect(() => {
    const getData = async () => {
      if (!auth.isAuthenticated || !auth.user?.id || location.pathname !== '/app/settings/billing') return
      await Auth.currentAuthenticatedUser({ bypassCache: true })
      const customerVideoLimitsQueryResult = await getCustomerVideoLimits()
      const appConfigDataQueryResult = await getAppConfigData()

      if (appConfigDataQueryResult?.data) {
        auth.setApplicationConfiguration(appConfigDataQueryResult.data)
      }

      if (customerVideoLimitsQueryResult.data?.getCustomerVideoLimitsData === null) {
        dispatch(setVideosLimitReached(false))
        return
      }
      dispatch(
        setVideosLimitReached(
          !!customerVideoLimitsQueryResult?.data?.getCustomerVideoLimitsData?.maxVideosLimitReached,
        ),
      )
    }
    getData()
  }, [auth.user?.id, auth.isAuthenticated, location.pathname])
}

export default useCustomerLimit
