import * as React from 'react'
import Linkify from 'react-linkify'
import { useTranslation } from 'react-i18next'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import DeleteCrossIcon from '../../../../../../../../common/icons/DeleteCrossIcon'
import { sanitazeHtml } from '../../../../../../../../common/utils/sanitazeHtml'
import PostIcon from '../../../../../../../../common/icons/PostIcon'

interface IRemovedFilePostProps {
  owner: boolean
  reply: any
}
const ReplyToRemovedPost = (props: IRemovedFilePostProps) => {
  const { t } = useTranslation('chat')
  const theme = useTheme()
  const colorForUser = props.owner ? theme.palette.background.default : theme.palette.secondary.light
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(`(max-width:1024px)`)

  return (
    <Box
      justifyContent={props.owner ? 'flex-end' : 'flex-start'}
      maxWidth={isTablet ? 'calc(100% - 55px)' : 'calc(100% - 60px)'}
      minWidth={isTablet && !isMobile ? 'calc(100% - 50px)' : isMobile ? 'calc(100% - 50px)' : '250px'}
      alignItems="center"
      padding={'20px'}
      borderRadius={'24px'}
      style={{ borderTopRightRadius: props.owner ? '0' : '24px', borderTopLeftRadius: props.owner ? '24px' : '0' }}
      bgcolor={props.owner ? 'primary.main' : 'rgba(188, 188, 195, 0.15)'}
    >
      <Box alignItems="center" display="flex">
        <Box width="2px" height="40px" bgcolor={colorForUser} marginRight="8px" />
        <Box position="relative" display="flex">
          <PostIcon color={colorForUser} />
          <Box position="absolute" top="14px" left="10px">
            <DeleteCrossIcon fill={props.owner ? theme.palette.background.default : theme.palette.secondary.main} />
          </Box>
        </Box>
        <Typography style={{ color: colorForUser }} variant="body2" paddingLeft={props.owner ? '10px' : '20px'}>
          {t('chatBody.removedReferencePost')}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="15px">
        <Typography
          variant="body1"
          style={{
            fontSize: '15px',
            lineHeight: '20px',
            color: props.owner ? theme.palette.background.default : theme.palette.text.primary,
          }}
        >
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {sanitazeHtml(props.reply.richContent || props.reply.content || '')}
          </Linkify>
        </Typography>
      </Box>
    </Box>
  )
}

export default ReplyToRemovedPost
