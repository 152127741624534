import React, { useContext, useEffect } from 'react'
import { CssBaseline } from '@mui/material'
import Menu from '../Menu'
import RecordControl from '../../components/RecordControl'
import { AuthContext } from '../../../../../providers/AuthStatusProvider'
import FullScreenLoader from '../../../../../../components/_refactored/Loaders/FullScreenLoader'
import PageTitle from '../../../../../../components/_refactored/PageTitle/PageTitle'
import useAppStyles from '../../../common/hooks/useAppStyles'
import useAppRedirects from '../../../common/hooks/useAppRedirects'
import useUpdateUserConfig from '../../../common/hooks/useUpdateUserConfig'
import NetworkStatusInfo from '../../components/NetworkStatusInfo'
import useCustomerLimit from '../../../common/hooks/useCustomerLimit'
import useOnPostEventSubscription from '../../../common/hooks/subscriptions/useOnPostEventSubscription'
import useLoadMomentLocale from '../../../common/hooks/useLoadMomentLocale'

const AppContent = (props: { children: React.ReactNode }) => {
  const { children } = props
  const auth = useContext(AuthContext)
  useOnPostEventSubscription()
  useUpdateUserConfig()
  useAppRedirects()
  useCustomerLimit()
  const [styles] = useAppStyles()
  useLoadMomentLocale()

  return (
    <div className={styles.root}>
      <PageTitle name="ScreenSight" />
      <NetworkStatusInfo />
      <CssBaseline />
      {!auth.isInitialized && <FullScreenLoader />}
      <Menu />
      <RecordControl />
      <main className={styles.content}>{children}</main>
    </div>
  )
}

export default AppContent
