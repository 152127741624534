import * as React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import useDeleteReaction from '../../../common/hooks/reactions/useDeleteReaction'
import Button from '../../../../../../../../components/_refactored/Button'
import useCreateReaction from '../../../common/hooks/reactions/useCreateReaction'
import useReaction from '../../../common/hooks/reactions/useReaction'
import { useParams } from 'react-router'
import { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'

interface IreactionProps {
  postId: string
  unicode: string
  videoId: string | undefined
  videoTimestamp: number
  amIEmojiOwner?: boolean
  reactionAmount: number
  authors: string[]
  disabled: boolean
  disableReactionsActions: () => void
  enableReactionsActions: () => void
}

export function parseNativeEmoji(unified: string): string {
  return unified
    .split('-')
    .map((hex) => String.fromCodePoint(parseInt(hex, 16)))
    .join('')
}
const Reaction = (props: IreactionProps) => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const handleReactionTooltip = useReaction(props.authors, props.reactionAmount)
  const [addReaction] = useCreateReaction()
  const [deleteReaction] = useDeleteReaction()

  return (
    <Button
      style={{
        minWidth: '20px',
        minHeight: '20px',
        width: 'fit-content',
        height: 'fit-content',
        marginRight: '10px',
        cursor: 'pointer',
        backgroundColor: props?.amIEmojiOwner ? 'rgba(36, 131, 247, 0.15)' : 'rgba(188, 188, 195, 0.15)',
        padding: '0px 8px',
        borderRadius: '18px',
        marginTop: '4px',
      }}
      onClick={async () => {
        if (props.disabled) return
        props.disableReactionsActions()
        if (props.amIEmojiOwner) {
          await deleteReaction({ postId: props.postId, unicode: props.unicode })
        } else {
          await addReaction({ postId: props.postId, uniCode: props.unicode, channelId: channelId || '' })
        }
        props.enableReactionsActions()
      }}
    >
      {/*//@ts-ignore*/}
      <Tooltip title={handleReactionTooltip() || ''}>
        <Box display="flex" alignItems="center" sx={{ fontSize: '21px' }}>
          {parseNativeEmoji(props.unicode)}
          <Typography variant="body1" color="textPrimary" style={{ marginLeft: '5px' }}>
            {props.reactionAmount}
          </Typography>
        </Box>
      </Tooltip>
    </Button>
  )
}

export default Reaction
