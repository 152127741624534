import * as React from 'react'
import { Box } from '@mui/material'

const FromLibraryIcon = () => {
  return (
    <Box display="flex">
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.90476 0.5C0.854286 0.5 0 1.34717 0 2.38889V11.8333C0 12.8751 0.854286 13.7222 1.90476 13.7222H14.2857C15.3362 13.7222 16.1905 12.8751 16.1905 11.8333V2.38889C16.1905 1.34717 15.3362 0.5 14.2857 0.5H1.90476ZM5.71429 4.27778L10.9542 7.11111L5.71429 9.94444V4.27778ZM18.0952 4.27778V15.6111H3.80952V17.5H18.0952C19.1363 17.5 20 16.6434 20 15.6111V4.27778H18.0952Z"
          fill="#2483F7"
        />
      </svg>
    </Box>
  )
}

export default FromLibraryIcon
