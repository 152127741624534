import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { CreateGuestCustomerMutation, CreateGuestCustomerMutationVariables } from '../../../../../API'
import gql from 'graphql-tag'
import { createGuestCustomer } from '../../../../../graphql/mutations'
import IamApolloClient from '../../../../../common/clients/IamApolloClient'
import { Dispatch, SetStateAction } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { Analytics } from 'aws-amplify'

export interface IViewerModalForm {
  name: string
}

const useViewerInfoModalHook = (setIsNameAdded: Dispatch<SetStateAction<boolean>>) => {
  const { id: videoId } = useParams() as { id: string }
  const history = useHistory()

  const form = useForm<IViewerModalForm>({
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  })

  const [createGuestAccount, { loading: createGuestAccountLoading }] = useMutation<
    CreateGuestCustomerMutation,
    CreateGuestCustomerMutationVariables
  >(gql(createGuestCustomer), {
    client: IamApolloClient,
  })

  const onSubmit = form.handleSubmit(async (values: IViewerModalForm) => {
    try {
      const guestCustomerRes = await createGuestAccount({ variables: { input: { firstName: values.name } } })
      if (guestCustomerRes.data?.createGuestCustomer?.id) {
        localStorage.setItem('guestCustomerId', guestCustomerRes.data?.createGuestCustomer?.id)
        await Analytics.updateEndpoint({ userId: guestCustomerRes.data?.createGuestCustomer?.id })
        Analytics.autoTrack('session', { enable: true })
      }
      setIsNameAdded(true)
    } catch (err: any) {
      console.error(err)
    }
  })

  const handleSignInPush = () => {
    history.push({ pathname: '/login', state: { videoId } })
  }

  return {
    form,
    onSubmit,
    videoId,
    viewedByLoading: createGuestAccountLoading,
    handleSignInPush,
  }
}

export default useViewerInfoModalHook
