import { useContext, useEffect, useMemo, useState } from 'react'
import { API } from 'aws-amplify'
import gql from 'graphql-tag'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { GraphQLErrors } from '@apollo/client/errors'
import { AuthContext } from '../providers/AuthStatusProvider'
import { useNetworkState } from 'react-use'
import { useLocation } from 'react-router'
import { AUTH_PATHS } from '../layouts/AppLayout/common/hooks/useAppRedirects'

interface IAmplifySubscriptionOptions<VData = any> {
  authMode?: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS | GRAPHQL_AUTH_MODE.AWS_IAM | null
  skip?: boolean
  variables?: VData | null
}

/*
  This is a workaround for a bug in the aws-mobile-appsync-sdk-js.
 */
const useAmplifySubscription = <T, V>(
  queryName: string,
  subscriptionHookOptions?: IAmplifySubscriptionOptions<V>,
  key?: string,
) => {
  const auth = useContext(AuthContext)
  const networkState = useNetworkState()
  const location = useLocation()

  const [data, setData] = useState<{ data: T | undefined } | undefined>(undefined)
  const [error, setError] = useState<GraphQLErrors | undefined>(undefined)
  const [increment, setIncrement] = useState(0)
  const authMode =
    subscriptionHookOptions?.authMode || auth.isAuthenticated
      ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      : GRAPHQL_AUTH_MODE.AWS_IAM

  const variables = useMemo(() => subscriptionHookOptions?.variables, [location.pathname, auth.user])

  const isAuthPathname = useMemo(() => {
    return AUTH_PATHS.includes(location.pathname)
  }, [location.pathname])

  /*
  Reconnect subscription while tab is focused
 */
  useEffect(() => {
    const listenerFunc = () => {
      if (document.visibilityState === 'visible') {
        setIncrement((prevState) => prevState + 1)
      }
    }
    document.addEventListener('visibilitychange', listenerFunc)
    return () => {
      document.removeEventListener('visibilitychange', listenerFunc)
    }
  }, [])

  /*
    Reconnect subscription every min
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setIncrement((prevState) => prevState + 1)
    }, 60000)
    return () => {
      window.clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (auth.user?.pending || subscriptionHookOptions?.skip || !queryName || isAuthPathname) return
    const subscription = API.graphql({
      query: gql(queryName),
      variables: (variables as Object) || undefined,
      authMode,
      // @ts-ignore
    }).subscribe({
      next: ({ value }: { value: { data: T | undefined } | undefined }) => {
        console.log('Subscription data received', value)
        setData(value)
      },
      error: (error: GraphQLErrors) => {
        if (error) {
          setError(error)
        }
      },
    })
    if (!networkState.online) {
      return () => subscription.unsubscribe()
    }
    return () => subscription.unsubscribe()
  }, [
    auth.user?.pending,
    queryName,
    subscriptionHookOptions?.authMode,
    subscriptionHookOptions?.skip,
    variables,
    networkState.online,
    increment,
    authMode,
    isAuthPathname,
  ])

  return { data, error }
}

export default useAmplifySubscription
