import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AuthContext } from '../../../../../../common/providers/AuthStatusProvider'
import ToastContext from '../../../../../../common/providers/ToastProvider/ToastContext'
import { useHistory } from 'react-router-dom'
import { IForgotPasswordPasswordForm } from '../../../../common/types/Auth'
import { useForgotPasswordContext } from '../context/ForgotPasswordContext'

const useCreateNewPassword = () => {
  const auth = useContext(AuthContext)
  const toastContext = useContext(ToastContext)
  const forgotPasswordContext = useForgotPasswordContext()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  const passwordForm = useForm<IForgotPasswordPasswordForm>({
    mode: 'onChange',
    defaultValues: {
      code: '',
      password: '',
    },
  })

  const onSubmitPassword = passwordForm.handleSubmit(async (values: IForgotPasswordPasswordForm) => {
    try {
      setLoading(true)
      await auth.passwordReset(forgotPasswordContext.email, values.code, values.password)
      history.push({ pathname: '/login' })
    } catch (err: any) {
      toastContext.open({ text: err.message, time: 5000 })
    } finally {
      setLoading(false)
    }
  })

  const resendCode = async () => {
    try {
      setLoading(true)
      await auth.passwordRecovery(forgotPasswordContext.email)
    } catch (err: any) {
      toastContext.open({ text: err.message, time: 5000 })
    } finally {
      setLoading(false)
    }
  }

  const switchPasswordVisible = () => {
    setPasswordVisible(!isPasswordVisible)
  }

  return {
    loading,
    onSubmitPassword,
    passwordForm,
    resendCode,
    switchPasswordVisible,
    isPasswordVisible,
  }
}

export default useCreateNewPassword
