import { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import useCreateReaction from './useCreateReaction'
import useDeleteReaction from './useDeleteReaction'
import { useParams } from 'react-router'
import useGetCachedReactions from './useGetCachedReactions'

const useReactionBtn = (postId: string, location: string) => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const [allEmojisAnchorEl, setAllEmojisAnchorEl] = useState<null | Element>(null)
  const [defaultEmojisAnchorEl, setDefaultEmojisAnchorEl] = useState<null | Element>(null)
  const [addReaction] = useCreateReaction()
  const [deleteReaction] = useDeleteReaction()
  const cachedReactions = useGetCachedReactions(postId)
  const openAllEmojis = Boolean(allEmojisAnchorEl)
  const openDefaultEmojis = Boolean(defaultEmojisAnchorEl)
  const channelId = channelParamsId || auth.user?.supportChannelId

  const openDefaultReactions = () => {
    const buttonElement = document.querySelector(`.post--reactions-${postId}-${location}`)
    setDefaultEmojisAnchorEl(buttonElement)
  }
  const openAllReactions = () => {
    const buttonElement = document.querySelector(`.post--reactions-${postId}-${location}`)
    setAllEmojisAnchorEl(buttonElement)
    closeDefaultReactions()
  }

  const closeDefaultReactions = () => {
    setDefaultEmojisAnchorEl(null)
  }

  const closeAllReactions = () => {
    setAllEmojisAnchorEl(null)
  }

  const onEmojiClick = async (emojiData: any) => {
    const emoji = emojiData.unified || emojiData
    if (
      cachedReactions?.reactions?.items?.find(
        (item: any) => item.unicode === emoji && item.customerId === auth.user?.id && !item.videoTimestamp,
      )
    ) {
      setAllEmojisAnchorEl(null)
      setDefaultEmojisAnchorEl(null)
      await deleteReaction({ postId: postId, unicode: emoji })
      return
    }
    setAllEmojisAnchorEl(null)
    setDefaultEmojisAnchorEl(null)

    await addReaction({ postId: postId, uniCode: emoji, channelId: channelId || '' })
  }

  return {
    onEmojiClick,
    closeAllReactions,
    openAllReactions,
    openDefaultReactions,
    closeDefaultReactions,
    allEmojisAnchorEl,
    defaultEmojisAnchorEl,
    openAllEmojis,
    openDefaultEmojis,
  }
}

export default useReactionBtn
