import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import Button from '../../../../../../../../components/_refactored/Button'

interface IEditPostActionsProps {
  handleCancel: () => void
  handleUpdate: () => void
}

const EditPostActions = (props: IEditPostActionsProps) => {
  const { t: g } = useTranslation('general')

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      width="100%"
      alignItems="center"
      padding="10px"
      className="editor--action-bar"
    >
      <Button color="secondary" onClick={props.handleCancel}>
        {g('common.cancelBtn')}
      </Button>
      <Button variant="contained" onClick={props.handleUpdate}>
        {g('common.saveBtn')}
      </Button>
    </Box>
  )
}

export default EditPostActions
