import { AuthContext } from 'common/providers/AuthStatusProvider'
import { useContext } from 'react'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import gql from 'graphql-tag'
import { screensightReaction } from '../api/types'

/*
 * This hook is used to update reactions cache fragment
 * Helper hook for useDeleteReaction and useCreateReaction
 * It provides easier way to update cache fragment
 */
const useWriteReactionFragment = () => {
  const auth = useContext(AuthContext)
  const client = auth.isAuthenticated ? CognitoApolloClient : IamApolloClient

  /*
   * This function is used to update reactions cache fragment
   * @param postId - id of the post
   * @param data - data to write to cache fragment. It should be an array of screensightReaction related to specific post
   */
  const updateReactionCacheFragment = (postId: string, data: screensightReaction[]) =>
    client.cache.writeFragment({
      id: `screensightPost:${postId}`,
      fragment: gql`
        fragment newReaction on screensightPost {
          reactions {
            items {
              unicode
              videoTimestamp
              customerId
              postId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      `,
      data: {
        reactions: {
          __typename: 'ModelscreensightReactionConnection',
          items: data,
          nextToken: null,
        },
      },
    })
  return [updateReactionCacheFragment]
}

export default useWriteReactionFragment
