import React, { useState } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import ProfileRoundedImage from '../../pages/VideoItem/ui/components/ProfileRoundedImage'
import { getUsername } from '../../common/utils/getUsername'

interface ISeenedBy {
  seenByArray: any
  uniqueViewsCount: number | null | undefined
}

const SeenBy = (props: ISeenedBy) => {
  const { seenByArray, uniqueViewsCount } = props
  const [allViewersShowed, setAllViewersShowed] = useState(false)
  const theme = useTheme()
  const { t } = useTranslation('library')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const location = useLocation()

  const showAllViewers = () => {
    setAllViewersShowed(true)
  }

  const hideAllViewers = () => {
    setAllViewersShowed(false)
  }

  return (
    <Box display="flex" height="25px" width="100%" alignItems="center">
      {props?.uniqueViewsCount && props?.seenByArray?.length > 0 ? (
        <>
          {location.pathname.includes('chat') && isMobile ? null : (
            <Typography variant="body1" color="secondary">
              {t('common.seenByPrefix')}
            </Typography>
          )}
          <Box
            display="flex"
            marginLeft={location.pathname.includes('chat') && isMobile ? '0px' : '16px'}
            onMouseOver={showAllViewers}
            onMouseLeave={hideAllViewers}
            position="relative"
          >
            {seenByArray.slice(0, 3).map((viewerItem: any, key: number) => {
              return (
                <ProfileRoundedImage
                  // TODO: fix during maintenance
                  name={
                    viewerItem?.guestName ||
                    getUsername(
                      viewerItem?.customerMeta?.firstName,
                      viewerItem?.customerMeta?.lastName,
                      viewerItem?.customerMeta?.email,
                    )
                  }
                  width="25px"
                  height="25px"
                  photoUrl={viewerItem?.customerMeta?.profilePhoto?.key}
                  wrapperStyle={{
                    width: '25px',
                    height: '25px',
                    marginLeft: '-8.5px',
                    marginRight: '0',
                    zIndex: 1 + key,
                  }}
                  S3ImageStyle={{ width: '25px', height: '25px', border: '2px solid white' }}
                  hideTooltip={true}
                  key={viewerItem?.customerMeta?.id}
                  type="S3"
                  disabledLoading={true}
                />
              )
            })}
            {uniqueViewsCount && uniqueViewsCount > 3 && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor="background.default"
                border={`2px solid ${theme.palette.secondary.main}`}
                borderRadius="25px"
                marginLeft="-8.5px"
                height="25px"
                width="25px"
                zIndex={4}
              >
                <Typography variant="caption" color="textSecondary">
                  {(uniqueViewsCount || 0) > 12 ? '9+' : `+${(uniqueViewsCount || 0) - 3}`}
                </Typography>
              </Box>
            )}
            {location.pathname.includes('video') && allViewersShowed ? (
              <Box
                position="absolute"
                left="0px"
                top="28px"
                bgcolor="rgba(20, 35, 61, 0.7)"
                padding="5px 15px"
                color="white"
                zIndex={100}
                borderRadius="12px"
                style={{ transform: 'translateX(-50%, -50%)' }}
                width="max-content"
              >
                {seenByArray.map((viewerItem: any) => {
                  return (
                    <Typography variant="body2" style={{ color: 'white' }} key={viewerItem?.customerMeta?.id}>
                      {viewerItem?.guestName ||
                        getUsername(
                          viewerItem?.customerMeta?.firstName,
                          viewerItem?.customerMeta?.lastName,
                          viewerItem?.customerMeta?.email,
                        )}
                    </Typography>
                  )
                })}
              </Box>
            ) : null}
          </Box>
        </>
      ) : (
        <>
          {location.pathname.includes('chat') && isMobile ? null : (
            <Typography variant="body2" color="textSecondary">
              {t('common.noViews')}
            </Typography>
          )}
        </>
      )}
    </Box>
  )
}

export default SeenBy
