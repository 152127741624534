import React from 'react'
import { Box, Typography } from '@mui/material'
import { ClickAwayListener } from '@mui/base'
import StarsIcon from './StarsIcon'
import Button from '../../../../components/_refactored/Button'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
interface IVideoLimitModal {
  hideModal: () => void
  style?: React.CSSProperties
}

const VideoLimitModal = (props: IVideoLimitModal) => {
  const { t: l } = useTranslation('library')
  const history = useHistory()

  return (
    <ClickAwayListener onClickAway={props.hideModal}>
      <Box
        position="absolute"
        top="0"
        padding="30px 40px"
        zIndex={100}
        borderRadius="10px"
        bgcolor="#FFF"
        boxShadow="0px 1px 7px rgba(0, 0, 0, 0.11)"
        width="400px"
        maxWidth="65vw"
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ transform: 'translateX(calc(-100% - 10px))', ...props?.style }}
      >
        <StarsIcon />
        <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '18px', marginTop: '18px' }} align="center">
          {l('limitationModal.title')}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '15px', marginTop: '15px' }} align="center">
          {l('limitationModal.subtitlePart1')}
          <br />
          {l('limitationModal.subtitlePart2')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ margin: '20px 0' }}
          onClick={() => {
            history.push('/app/settings/billing/plans')
          }}
        >
          {l('limitationModal.upgradeAccountBtn')}
        </Button>
        <Typography
          align="center"
          sx={{ fontWeight: 500, fontSize: '12px', color: '#7A7A86', cursor: 'pointer' }}
          onClick={() => {
            props.hideModal()
            history.push('/app')
          }}
        >
          {l('limitationModal.deleteVideos')}
        </Typography>
      </Box>
    </ClickAwayListener>
  )
}

export default VideoLimitModal
