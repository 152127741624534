import React from 'react'
import useVideoPrivacyModalHook from './hooks/useVideoPrivacyModal.hook'
import VideoPrivacyModalView from './view/VideoPrivacyModal.view'
import { AccessType } from '../../../API'

interface IVideoPrivacyModal {
  togglePrivacyModalVisibility: () => void
  privacyType?: AccessType | null
  videoId: string | undefined
  videoPassword?: string | null
  isOnVideoList: boolean
}

const VideoPrivacyModal = (props: IVideoPrivacyModal) => {
  const state = useVideoPrivacyModalHook(
    props.togglePrivacyModalVisibility,
    props.privacyType,
    props.videoId,
    props.videoPassword,
  )
  return (
    <VideoPrivacyModalView
      togglePrivacyModalVisibility={props.togglePrivacyModalVisibility}
      {...state}
      isOnVideoList={props.isOnVideoList}
    />
  )
}

export default VideoPrivacyModal
