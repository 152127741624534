import React, { MutableRefObject } from 'react'
import { GetVideoQuery } from '../../../API'
import VideoPlayer from '../../../components/VideoPlayer'
import styled from 'styled-components'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import Button from '../../../components/_refactored/Button'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import VideoItemAnalyticsBarChart from '../../../containers/VideoItemAnalytics/VideoItemAnalyticsBarChart'
import { useHistory } from 'react-router-dom'
import FullScreenLoader from '../../../components/_refactored/Loaders/FullScreenLoader'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { VideoJsPlayer } from 'video.js'
import './styles.scss'

interface IStyledSectionBar {
  sectionBarsVisible: boolean
}
const StyledSectionBar = styled(Box)`
  position: absolute;
  height: 10px;
  width: 3px;
  border-radius: 2px;
  background: white;
  bottom: 48px;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: ${(props: IStyledSectionBar) => (props.sectionBarsVisible ? 'fadein' : 'fadeout')};

  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

interface IVideoStatisticsView {
  videoData: GetVideoQuery | undefined
  playerRef: MutableRefObject<VideoJsPlayer | null>
  videoLoading: boolean
  handleSectionPlay: (data: number[], domElWidth: number) => void
  subsectionMS: number[]
  sectionBarsVisible: boolean
  onPlayerReady: (player: VideoJsPlayer) => void
  poster?: any
  visible: boolean
}

const VideoStatisticsView = (props: IVideoStatisticsView) => {
  const history = useHistory()
  const theme = useTheme()
  const { t } = useTranslation('library')

  return (
    <Box
      paddingLeft="32px"
      paddingRight="32px"
      paddingTop="83px"
      maxWidth="1400px"
      marginLeft="auto"
      marginRight="auto"
    >
      {props.videoLoading && <FullScreenLoader withoutSidebar />}

      <Button
        style={{ maxWidth: '90vw', color: theme.palette.text.primary }}
        startIcon={<ArrowBackIosIcon />}
        onClick={() => history.goBack()}
      >
        <Typography variant="body1" style={{ fontWeight: 500, fontSize: '20px' }} noWrap>
          {props.videoData?.getVideo?.title}
        </Typography>
      </Button>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginLeft="45px" marginBottom={'10px'}>
        <Typography variant="body1" color="textSecondary" align="left">
          {t('videoStatistics.title')}
        </Typography>
        <Typography variant="body1" color="textSecondary" align="right">
          {moment(props.videoData?.getVideo?.createdAt).format('MMMM DD, dddd')}
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-end" height="70vh">
        {props.videoData?.getVideo?.filePresignedUrl && props.poster && (
          <Box className="statistics__video__player">
            {!props.visible && (
              <Box alignItems="center" display="flex" maxWidth="100%" height={'100%'} justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            <Box className={props.visible ? 'video-js-ready' : 'video-js-hidden'} position="relative">
              <StyledSectionBar
                left={`${8 + props.subsectionMS[0]}px`}
                zIndex={100}
                sectionBarsVisible={props.sectionBarsVisible}
              />
              <StyledSectionBar
                left={`${8 + props.subsectionMS[1]}px`}
                zIndex={100}
                sectionBarsVisible={props.sectionBarsVisible}
              />
              <VideoPlayer
                onPlayerReady={props.onPlayerReady}
                videoId={props.videoData.getVideo.id}
                visible={true}
                videoJsOptions={{
                  height: props.videoData?.getVideo.height,
                  width: props.videoData?.getVideo.width,
                  sources: [{ src: props.videoData?.getVideo?.filePresignedUrl, type: 'video/mp4' }],
                  fluid: true,
                  controls: true,
                  playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 3, 4],
                  ...(props.poster && { poster: props.poster }),
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      <VideoItemAnalyticsBarChart
        handleSectionPlay={props.handleSectionPlay}
        duration={props.videoData?.getVideo?.duration}
        viewsTotal={props.videoData?.getVideo?.viewsCountTotal}
        videoId={props.videoData?.getVideo?.id}
        videoAccessId={props.videoData?.getVideo?.accessId || props.videoData?.getVideo?.id}
      />
    </Box>
  )
}

export default VideoStatisticsView
