import React, { SVGProps } from 'react'
import { Box, BoxProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import './animation.css'

const useStyles = makeStyles({
  topClap: {
    animation: 'clapRotate 1s',
    transform: 'translateZ(0)',
  },
  lines: {
    opacity: 0,
    animation: 'opacityAnimation 0.5s',
    animationDelay: '0.7s',
    transform: 'translateZ(0)',
  },

  clapperHide: {
    position: 'absolute',
    animation: 'hideAnimation 2s forwards',
    transform: 'translateZ(0)',
  },
  redDotShow: {
    position: 'absolute',
    opacity: 0,
    animation: 'opacityAnimation 1s reverse forwards',
    animationDelay: '1s',
  },
  redDot1: {
    opacity: 0,
    animation: 'redDotInner 3s alternate infinite',
    animationDelay: '2s',
    transform: 'translateZ(0)',
  },
  redDot2: {
    opacity: 0,
    animation: 'redDotOuter 3s alternate infinite',
    animationDelay: '2s',
    transform: 'translateZ(0)',
  },
})

const IconRecordAnimation = ({ width = 126, height = 103, ...props }: BoxProps & SVGProps<SVGElement>) => {
  const { topClap, lines, redDot1, redDot2, clapperHide, redDotShow } = useStyles()

  return (
    <Box width={width} height={height} {...props}>
      <svg
        className={redDotShow}
        width={width}
        height={height}
        viewBox="0 0 126 103"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="63" cy="51.5" r="15.5" fill="#EA0044" />
        <circle className={redDot1} cx="63" cy="51.5" r="25.5" fill="#EA0044" fillOpacity="0.2" />
        <circle className={redDot2} cx="63" cy="51.5" r="35.5" fill="#EA0044" fillOpacity="0.1" />
      </svg>

      <svg
        className={clapperHide}
        width={width}
        height={height}
        viewBox="0 0 126 103"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={topClap}
          d="M98.2155 43.1809C100.425 43.1809 102.215 41.3901 102.215 39.181L102.216 28.3732C102.216 26.164 100.425 24.3731 98.2156 24.3731L8.95463 24.3732C6.79101 24.3732 5.03701 26.127 5.03686 28.2906L5.0368 29.0736C5.03668 30.8049 6.44003 32.2085 8.1713 32.2086L8.17143 29.7236L21.6289 43.1811L98.2155 43.1809ZM99.0806 27.5078L99.0807 37.8297L88.76 27.5076L99.0806 27.5078ZM84.327 27.5078L96.8635 40.0461L81.7041 40.046L69.1673 27.5079L84.327 27.5078ZM64.7342 27.5076L77.2712 40.046L62.112 40.0458L49.5746 27.5077L64.7342 27.5076ZM45.1416 27.5079L57.679 40.046L42.5198 40.046L29.9821 27.5078L45.1416 27.5079ZM25.5488 27.5079L38.0864 40.0461L22.9272 40.046L10.3891 27.5078L25.5488 27.5079Z"
          fill="#7A7A86"
        />
        <path d="M95.6191 93.7924H92.4844V96.9271H95.6191V93.7924Z" fill="#7A7A86" />
        <path d="M13.584 93.7917V84.9099H10.4492V96.9265H19.3311V93.7917H13.584Z" fill="#7A7A86" />
        <path d="M13.584 76.5509H10.4492V79.6857H13.584V76.5509Z" fill="#7A7A86" />
        <path
          d="M102.402 63.4891V48.6803C102.402 46.4712 100.611 44.6803 98.4023 44.6803H13.3995C12.6897 41.6885 9.9976 39.4557 6.79199 39.4557C3.04699 39.4557 0 42.5025 0 46.2477C0 49.4533 2.233 52.1454 5.22461 52.8551V98.151C5.22461 100.36 7.01547 102.151 9.22461 102.151H98.4023C100.611 102.151 102.402 100.36 102.402 98.151V68.7137C102.402 67.8481 101.701 67.1463 100.835 67.1463C99.9693 67.1463 99.2676 67.8481 99.2676 68.7137V95.0162C99.2676 97.2254 97.4767 99.0162 95.2676 99.0162H12.3594C10.1502 99.0162 8.35938 97.2254 8.35938 95.0162V67.4891C8.35938 65.28 10.1502 63.4891 12.3594 63.4891H102.402ZM99.2676 60.3543H88.9467L99.2676 50.032V60.3543ZM97.0513 47.8151L84.5137 60.3543H69.3533L81.8914 47.8151H97.0513ZM77.4584 47.8151L64.9204 60.3543H49.7604L62.2989 47.8151H77.4584ZM57.8661 47.8151L45.3277 60.3543H30.1679L42.7068 47.8151H57.8661ZM38.2736 47.8151L25.7348 60.3543H10.575L23.1143 47.8151H38.2736ZM13.3995 47.8151H18.6811L8.35938 58.1368V52.8551C10.8475 52.265 12.8091 50.3032 13.3995 47.8151ZM6.79199 49.9049C4.7755 49.9049 3.13477 48.2642 3.13477 46.2477C3.13477 44.2312 4.7755 42.5905 6.79199 42.5905C8.80848 42.5905 10.4492 44.2312 10.4492 46.2477C10.4492 48.2642 8.80848 49.9049 6.79199 49.9049Z"
          fill="#7A7A86"
        />
        <path
          d="M6.79785 47.8154C7.66349 47.8154 8.36523 47.1137 8.36523 46.248C8.36523 45.3824 7.66349 44.6807 6.79785 44.6807C5.93221 44.6807 5.23047 45.3824 5.23047 46.248C5.23047 47.1137 5.93221 47.8154 6.79785 47.8154Z"
          fill="#7A7A86"
        />
        <path d="M84.4355 52.5176H81.3008V55.6523H84.4355V52.5176Z" fill="#7A7A86" />
        <path d="M25.2871 52.5177H22.1523V55.6525H25.2871V52.5177Z" fill="#7A7A86" />
        <path
          className={lines}
          d="M110.744 37.1886L118.789 32"
          stroke="#7A7A86"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={lines}
          d="M110.999 50.3376L119 54"
          stroke="#7A7A86"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={lines}
          d="M110.172 43.517L123.785 43.6715"
          stroke="#7A7A86"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  )
}

export default IconRecordAnimation
