import * as React from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { isEmail, ReactMultiEmail } from 'react-multi-email'
import { useTranslation } from 'react-i18next'

interface IRecipientsInputProps {
  isVerifying: boolean
  inputRef: React.RefObject<ReactMultiEmail>
  emails: string[]
  verifyEmail: (emails: string[]) => void
}
const RecipientsInput = (props: IRecipientsInputProps) => {
  const { t } = useTranslation('library')
  return (
    <Box
      width="100%"
      padding="7px 15px 7px 0"
      borderBottom="1px solid rgba(122,122,134, .3)"
      display="flex"
      alignItems="center"
      overflow="hidden"
      style={{ overflowY: 'auto' }}
      position="relative"
    >
      {props.isVerifying && (
        <Box
          position="absolute"
          left="0"
          top="0"
          width="100%"
          height="100%"
          bgcolor="rgba(255,255,255,0.7)"
          zIndex={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress style={{ width: '18px', height: '18px' }} />
        </Box>
      )}
      <Typography variant="body1" color="textSecondary" style={{ marginRight: '0', fontSize: '15px' }}>
        {t('videoItem.sendViaEmailModal.recipientInputPrefix')}
      </Typography>
      <Box
        className="multi-email-wrapper"
        onClick={() => {
          if (document.querySelector('.react-multi-email input')) {
            ;(document.querySelector('.react-multi-email input') as HTMLElement).focus()
          }
        }}
      >
        <ReactMultiEmail
          ref={props.inputRef}
          emails={props.emails}
          onChange={props.verifyEmail}
          style={{ border: 'none', fontSize: '15px' }}
          validateEmail={(email) => {
            return isEmail(email) && !props.emails.includes(email) // return boolean
          }}
          getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
            return (
              <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            )
          }}
        />
      </Box>
    </Box>
  )
}
export default RecipientsInput
