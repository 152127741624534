import * as React from 'react'
import useDeletePost from '../quill/api/useDeletePost'
import { useChatDispatch } from '../../contexts/ChatContext'
import { useContext } from 'react'
import ToastContext from '../../../../../../../../common/providers/ToastProvider/ToastContext'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import gql from 'graphql-tag'
import { getUsername } from '../../../../../../../../common/utils/getUsername'
import { useTranslation } from 'react-i18next'
import { Storage } from '@aws-amplify/storage'

type cachedPost = {
  id: string
  video?: {
    id: string
    customerMeta?: {
      id: string
      email: string
      firstName?: string
      lastName?: string
    }
    thumbnailObject: {
      key?: string
    }
    title?: string
  }
  referencePost?: {
    id: string
    video?: {
      id: string
      customerMeta?: {
        id: string
        email: string
        firstName?: string
        lastName?: string
      }
      thumbnailObject: {
        key?: string
      }
      title?: string
    }
  }
}

const usePostActions = (postId: string) => {
  const toastContext = useContext(ToastContext)
  const auth = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const chatDispatcher = useChatDispatch()
  const deletePostMutation = useDeletePost(postId)

  const client = auth.isAuthenticated ? CognitoApolloClient : IamApolloClient
  const { t } = useTranslation('chat')
  const cachedPost: cachedPost | null = client.cache.readFragment({
    id: `screensightPost:${postId}`,
    fragment: gql`
      fragment PostReaction1 on screensightPost {
        id
        video {
          id
          customerMeta {
            id
            email
            firstName
            lastName
          }
          thumbnailObject {
            key
          }
          title
        }
        referencePost {
          id
          video {
            customerMeta {
              id
              email
              firstName
              lastName
            }
            id
            thumbnailObject {
              key
            }
            title
          }
        }
      }
    `,
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleReply = async (
    content: string | undefined,
    richTextContent: string | undefined,
    ownerName: string,
    videoCommentTimestamp: number,
    videoId: string | undefined,
    file: any,
    referencePostAuthorId: string | undefined,
    engagement: any,
    postType?: string,
  ) => {
    switch (postType) {
      case 'file':
        chatDispatcher({
          type: 'setResponseBoxData',
          value: {
            type: 'fileReply',
            author: ownerName,
            title: file.fileName,
            parentId: postId,
            file: file,
            referencePostAuthorId: referencePostAuthorId,
            engagement: engagement,
          },
        })
        setAnchorEl(null)
        break
      case 'reply':
      case 'text':
        chatDispatcher({
          type: 'setResponseBoxData',
          value: {
            type: 'textReply',
            author: ownerName,
            title: content,
            parentId: postId,
            postType: postType,
            referencePostAuthorId: referencePostAuthorId,
            engagement: engagement,
          },
        })
        setAnchorEl(null)
        break
      case 'comment':
        chatDispatcher({
          type: 'setResponseBoxData',
          value: {
            type: 'commentReply',
            parentId: postId,
            author: ownerName,
            title: content,
            videoId: videoId,
            videoCommentTimestamp: videoCommentTimestamp,
            engagement: engagement,
          },
        })
        setAnchorEl(null)
        break
      case 'video':
      case 'email_video':
      case 'shared':
        if (!cachedPost) {
          setAnchorEl(null)
          return
        }
        const video = cachedPost?.video || cachedPost?.referencePost?.video
        chatDispatcher({
          type: 'setResponseBoxData',
          value: {
            type: 'comment',
            videoId: video?.id,
            title: video?.title,
            customerId: video?.customerMeta?.id,
            author: getUsername(
              video?.customerMeta?.firstName,
              video?.customerMeta?.lastName,
              video?.customerMeta?.email,
            ),
            photoKey: video?.thumbnailObject?.key,
          },
        })
        setAnchorEl(null)
        break
      default:
        break
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleEdit = (content: string | undefined, richContent: string | undefined) => {
    setAnchorEl(null)
    chatDispatcher({ type: 'setResponseBoxData', value: undefined })
    chatDispatcher({
      type: 'setEditedPost',
      value: { content: content, richContent: richContent, postId: postId },
    })
  }

  const handleDelete = async (fileKey?: string | null) => {
    try {
      if (fileKey) {
        await Storage.remove(fileKey)
      }
      await deletePostMutation()
    } catch (e) {
      console.error('Error during removing post', e)
      toastContext.open({ type: 'ERROR', text: t('chatBody.deletePostError') })
    } finally {
      handleClose()
    }
  }

  return {
    handleDelete,
    handleClick,
    handleClose,
    handleEdit,
    handleReply,
    anchorEl,
    open,
  }
}

export default usePostActions
