import React, { useEffect, useState } from 'react'
import awsmobile from '../../../aws-exports'
import { Storage } from 'aws-amplify'
import { escapeBtoaString } from '../../../common/utils/escapeBtoaString'

interface IProps {
  image: string
  width?: string | number
  height?: string | number
  imgStyle?: React.CSSProperties
  autoScale?: boolean
  onClick?: any
  disableResize?: boolean
}

const CloudFrontImage = (props: IProps) => {
  const [avatar, setAvatar] = useState<string | undefined>(undefined)
  useEffect(() => {
    if (!props.image || props?.disableResize) return
    const imageRequest = JSON.stringify({
      bucket: awsmobile.aws_user_files_s3_bucket,
      key: props.image,
      ...(!props.autoScale && {
        edits: {
          resize: {
            width: props?.width || 43,
            height: props?.height || 43,
          },
        },
      }),
    })
    const url = `https://${process.env.REACT_APP_CLOUDFRONT_DOMAIN_NAME}/${btoa(escapeBtoaString(imageRequest))}`
    setAvatar(url)
  }, [props.image, props?.disableResize])

  useEffect(() => {
    const getImage = async () => {
      if (!props.image || !props?.disableResize) return
      const img = await Storage.get(props.image, {
        customPrefix: {
          public: '',
        },
      })
      setAvatar(img)
    }
    getImage()
  }, [props.image, props?.disableResize])
  return <img src={avatar} height={props.height} width={props.width} style={props?.imgStyle} onClick={props?.onClick} />
}

export default CloudFrontImage
