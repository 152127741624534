/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomerVideoLimitsData = /* GraphQL */ `
  query GetCustomerVideoLimitsData($customerId: ID!) {
    getCustomerVideoLimitsData(customerId: $customerId) {
      maxVideosLimitReached
      maxVideosLimitReachedTimesFor30days
    }
  }
`;
export const getApplicationConfiguration = /* GraphQL */ `
  query GetApplicationConfiguration {
    getApplicationConfiguration {
      videoPlayerEventsFirehose
      termsAndConditionsVersion
      appVersionEndOfLife
      appVersionMinimum
      maintenanceMode
      featureFlags {
        id
        maxVideosLimit
        maxVideoDurationLimit
      }
    }
  }
`;
export const listPricePlans = /* GraphQL */ `
  query ListPricePlans {
    listPricePlans {
      name
      description
      prices {
        id
        currency
        interval
        amount
      }
    }
  }
`;
export const getBillingApiAccessKey = /* GraphQL */ `
  query GetBillingApiAccessKey {
    getBillingApiAccessKey {
      AccessKeyId
      SecretAccessKey
      SessionToken
      Expiration
      AwsRegion
      ServiceName
    }
  }
`;
export const getBillingInformation = /* GraphQL */ `
  query GetBillingInformation {
    getBillingInformation {
      paymentMethod {
        brand
        nameOnCard
        expirationMonth
        expirationYear
        lastFourDigits
      }
      subscription {
        id
        price {
          id
          currency
          interval
          amount
        }
        product {
          name
          description
          prices {
            id
            currency
            interval
            amount
          }
        }
      }
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
        accessLevel
        identityId
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
        accessLevel
        identityId
      }
      accessType
      accessId
      password
      width
      height
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
        count
      }
      commentsCount
      uploadedVideo {
        fileName
        videoContainer
        videoEncoding
      }
      transcriptionSummaryStatus
      transcriptionSummaryError
      transcriptionSummary
      transcriptionObject {
        key
        bucket
        accessLevel
        identityId
      }
      updatedAt
    }
  }
`;
export const listVideosByOwner = /* GraphQL */ `
  query ListVideosByOwner(
    $customerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosByOwner(
      customerId: $customerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        customerId
        channelId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        title
        searchTitle
        status
        fileObject {
          key
          bucket
          accessLevel
          identityId
        }
        filePresignedUrl
        duration
        name
        thumbnailObject {
          key
          bucket
          accessLevel
          identityId
        }
        accessType
        accessId
        password
        width
        height
        viewsCountTotal
        viewsCountUnique
        viewsList {
          items {
            videoId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            guestName
            viewCount
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          nextToken
          count
        }
        commentsCount
        uploadedVideo {
          fileName
          videoContainer
          videoEncoding
        }
        transcriptionSummaryStatus
        transcriptionSummaryError
        transcriptionSummary
        transcriptionObject {
          key
          bucket
          accessLevel
          identityId
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const verifyProtectedVideoPassword = /* GraphQL */ `
  query VerifyProtectedVideoPassword($accessId: ID!, $password: String!) {
    verifyProtectedVideoPassword(accessId: $accessId, password: $password) {
      accessToken
      video {
        id
        createdAt
        customerId
        channelId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        title
        searchTitle
        status
        fileObject {
          key
          bucket
          accessLevel
          identityId
        }
        filePresignedUrl
        duration
        name
        thumbnailObject {
          key
          bucket
          accessLevel
          identityId
        }
        accessType
        accessId
        password
        width
        height
        viewsCountTotal
        viewsCountUnique
        viewsList {
          items {
            videoId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            guestName
            viewCount
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          nextToken
          count
        }
        commentsCount
        uploadedVideo {
          fileName
          videoContainer
          videoEncoding
        }
        transcriptionSummaryStatus
        transcriptionSummaryError
        transcriptionSummary
        transcriptionObject {
          key
          bucket
          accessLevel
          identityId
        }
        updatedAt
      }
    }
  }
`;
export const accessProtectedVideo = /* GraphQL */ `
  query AccessProtectedVideo($accessId: ID!, $accessToken: String!) {
    accessProtectedVideo(accessId: $accessId, accessToken: $accessToken) {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
        accessLevel
        identityId
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
        accessLevel
        identityId
      }
      accessType
      accessId
      password
      width
      height
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
        count
      }
      commentsCount
      uploadedVideo {
        fileName
        videoContainer
        videoEncoding
      }
      transcriptionSummaryStatus
      transcriptionSummaryError
      transcriptionSummary
      transcriptionObject {
        key
        bucket
        accessLevel
        identityId
      }
      updatedAt
    }
  }
`;
export const listVideoAudience = /* GraphQL */ `
  query ListVideoAudience(
    $videoId: ID
    $customerId: ModelIDKeyConditionInput
    $filter: ModelScreensightVideoAudienceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVideoAudience(
      videoId: $videoId
      customerId: $customerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        videoId
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        guestName
        viewCount
        video {
          id
          createdAt
          customerId
          channelId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          title
          searchTitle
          status
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          filePresignedUrl
          duration
          name
          thumbnailObject {
            key
            bucket
            accessLevel
            identityId
          }
          accessType
          accessId
          password
          width
          height
          viewsCountTotal
          viewsCountUnique
          viewsList {
            items {
              videoId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              guestName
              viewCount
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          comments {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          commentsCount
          uploadedVideo {
            fileName
            videoContainer
            videoEncoding
          }
          transcriptionSummaryStatus
          transcriptionSummaryError
          transcriptionSummary
          transcriptionObject {
            key
            bucket
            accessLevel
            identityId
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listVideoAudienceEngagement = /* GraphQL */ `
  query ListVideoAudienceEngagement($videoId: ID!) {
    listVideoAudienceEngagement(videoId: $videoId) {
      videoId
      customerId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      timestamp
      chartValues
      segments {
        timestamp
        chartValues
      }
    }
  }
`;
export const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!, $customerId: ID!) {
    getChannel(id: $id, customerId: $customerId) {
      id
      customerId
      channelType
      name
      categoryPosition
      readHorizon
      activity {
        new
        latestAt
        latestPost {
          id
          channelId
          accessId
          customerId
          postType
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          comment {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          emailMessage {
            id
            videoId
            customerId
            channelId
            to
            note
            emailStatus {
              email
              status
              reject_reason
              _id
              deliveredAt
              opens
            }
            subject
            contacts {
              email
              contactId
            }
            createdAt
            updatedAt
          }
          text {
            content
            richContent
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
          }
          referencePostId
          referencePost {
            id
            channelId
            accessId
            customerId
            postType
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            comment {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            emailMessage {
              id
              videoId
              customerId
              channelId
              to
              note
              emailStatus {
                email
                status
                reject_reason
                _id
                deliveredAt
                opens
              }
              subject
              contacts {
                email
                contactId
              }
              createdAt
              updatedAt
            }
            text {
              content
              richContent
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
            }
            referencePostId
            referencePost {
              id
              channelId
              accessId
              customerId
              postType
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              comment {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              emailMessage {
                id
                videoId
                customerId
                channelId
                to
                note
                emailStatus {
                  email
                  status
                  reject_reason
                  _id
                  deliveredAt
                  opens
                }
                subject
                contacts {
                  email
                  contactId
                }
                createdAt
                updatedAt
              }
              text {
                content
                richContent
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
              }
              referencePostId
              referencePost {
                id
                channelId
                accessId
                customerId
                postType
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                comment {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                emailMessage {
                  id
                  videoId
                  customerId
                  channelId
                  to
                  note
                  subject
                  createdAt
                  updatedAt
                }
                text {
                  content
                  richContent
                  customerId
                }
                referencePostId
                referencePost {
                  id
                  channelId
                  accessId
                  customerId
                  postType
                  referencePostId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      chatPartnerId
      chatPartnerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      latestPostAt
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerRecentChannels = /* GraphQL */ `
  query ListCustomerRecentChannels(
    $customerId: ID!
    $latestPostAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerRecentChannels(
      customerId: $customerId
      latestPostAt: $latestPostAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        channelType
        name
        categoryPosition
        readHorizon
        activity {
          new
          latestAt
          latestPost {
            id
            channelId
            accessId
            customerId
            postType
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            comment {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            emailMessage {
              id
              videoId
              customerId
              channelId
              to
              note
              emailStatus {
                email
                status
                reject_reason
                _id
                deliveredAt
                opens
              }
              subject
              contacts {
                email
                contactId
              }
              createdAt
              updatedAt
            }
            text {
              content
              richContent
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
            }
            referencePostId
            referencePost {
              id
              channelId
              accessId
              customerId
              postType
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              comment {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              emailMessage {
                id
                videoId
                customerId
                channelId
                to
                note
                emailStatus {
                  email
                  status
                  reject_reason
                  _id
                  deliveredAt
                  opens
                }
                subject
                contacts {
                  email
                  contactId
                }
                createdAt
                updatedAt
              }
              text {
                content
                richContent
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
              }
              referencePostId
              referencePost {
                id
                channelId
                accessId
                customerId
                postType
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                comment {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                emailMessage {
                  id
                  videoId
                  customerId
                  channelId
                  to
                  note
                  subject
                  createdAt
                  updatedAt
                }
                text {
                  content
                  richContent
                  customerId
                }
                referencePostId
                referencePost {
                  id
                  channelId
                  accessId
                  customerId
                  postType
                  referencePostId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
        chatPartnerId
        chatPartnerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        latestPostAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listChannelParticipants = /* GraphQL */ `
  query ListChannelParticipants($channelId: ID!) {
    listChannelParticipants(channelId: $channelId) {
      items {
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPostsByChannel = /* GraphQL */ `
  query ListPostsByChannel(
    $channelId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostsByChannel(
      channelId: $channelId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelId
        accessId
        customerId
        postType
        video {
          id
          createdAt
          customerId
          channelId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          title
          searchTitle
          status
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          filePresignedUrl
          duration
          name
          thumbnailObject {
            key
            bucket
            accessLevel
            identityId
          }
          accessType
          accessId
          password
          width
          height
          viewsCountTotal
          viewsCountUnique
          viewsList {
            items {
              videoId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              guestName
              viewCount
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          comments {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          commentsCount
          uploadedVideo {
            fileName
            videoContainer
            videoEncoding
          }
          transcriptionSummaryStatus
          transcriptionSummaryError
          transcriptionSummary
          transcriptionObject {
            key
            bucket
            accessLevel
            identityId
          }
          updatedAt
        }
        comment {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        emailMessage {
          id
          videoId
          customerId
          channelId
          to
          note
          emailStatus {
            email
            status
            reject_reason
            _id
            deliveredAt
            opens
          }
          subject
          contacts {
            email
            contactId
          }
          createdAt
          updatedAt
        }
        text {
          content
          richContent
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
        }
        file {
          fileName
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          status
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          mimeType
          metadata
        }
        engagement {
          items {
            customerId
            seen
            seenAt
            videoProgress
          }
          nextToken
        }
        referencePostId
        referencePost {
          id
          channelId
          accessId
          customerId
          postType
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          comment {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          emailMessage {
            id
            videoId
            customerId
            channelId
            to
            note
            emailStatus {
              email
              status
              reject_reason
              _id
              deliveredAt
              opens
            }
            subject
            contacts {
              email
              contactId
            }
            createdAt
            updatedAt
          }
          text {
            content
            richContent
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
          }
          file {
            fileName
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            status
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            mimeType
            metadata
          }
          engagement {
            items {
              customerId
              seen
              seenAt
              videoProgress
            }
            nextToken
          }
          referencePostId
          referencePost {
            id
            channelId
            accessId
            customerId
            postType
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            comment {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            emailMessage {
              id
              videoId
              customerId
              channelId
              to
              note
              emailStatus {
                email
                status
                reject_reason
                _id
                deliveredAt
                opens
              }
              subject
              contacts {
                email
                contactId
              }
              createdAt
              updatedAt
            }
            text {
              content
              richContent
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
            }
            file {
              fileName
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              status
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              mimeType
              metadata
            }
            engagement {
              items {
                customerId
                seen
                seenAt
                videoProgress
              }
              nextToken
            }
            referencePostId
            referencePost {
              id
              channelId
              accessId
              customerId
              postType
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              comment {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              emailMessage {
                id
                videoId
                customerId
                channelId
                to
                note
                emailStatus {
                  email
                  status
                  reject_reason
                  _id
                  deliveredAt
                  opens
                }
                subject
                contacts {
                  email
                  contactId
                }
                createdAt
                updatedAt
              }
              text {
                content
                richContent
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
              }
              file {
                fileName
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                status
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                mimeType
                metadata
              }
              engagement {
                items {
                  customerId
                  seen
                  seenAt
                  videoProgress
                }
                nextToken
              }
              referencePostId
              referencePost {
                id
                channelId
                accessId
                customerId
                postType
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                comment {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                emailMessage {
                  id
                  videoId
                  customerId
                  channelId
                  to
                  note
                  subject
                  createdAt
                  updatedAt
                }
                text {
                  content
                  richContent
                  customerId
                }
                file {
                  fileName
                  status
                  customerId
                  mimeType
                  metadata
                }
                engagement {
                  nextToken
                }
                referencePostId
                referencePost {
                  id
                  channelId
                  accessId
                  customerId
                  postType
                  referencePostId
                  readHorizon
                  postTempId
                  createdAt
                  updatedAt
                }
                readHorizon
                postTempId
                reactions {
                  nextToken
                }
                createdAt
                updatedAt
              }
              readHorizon
              postTempId
              reactions {
                items {
                  postId
                  customerId
                  unicode
                  videoTimestamp
                  channelId
                  incrementBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            readHorizon
            postTempId
            reactions {
              items {
                postId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                unicode
                videoTimestamp
                channelId
                incrementBy
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          readHorizon
          postTempId
          reactions {
            items {
              postId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              unicode
              videoTimestamp
              channelId
              incrementBy
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        readHorizon
        postTempId
        reactions {
          items {
            postId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            unicode
            videoTimestamp
            channelId
            incrementBy
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTypeOfPostInChannel = /* GraphQL */ `
  query ListTypeOfPostInChannel(
    $postType: PostTypeFilter!
    $channelId: ID!
    $customerId: ID
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listTypeOfPostInChannel(
      postType: $postType
      channelId: $channelId
      customerId: $customerId
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelId
        accessId
        customerId
        postType
        video {
          id
          createdAt
          customerId
          channelId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          title
          searchTitle
          status
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          filePresignedUrl
          duration
          name
          thumbnailObject {
            key
            bucket
            accessLevel
            identityId
          }
          accessType
          accessId
          password
          width
          height
          viewsCountTotal
          viewsCountUnique
          viewsList {
            items {
              videoId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              guestName
              viewCount
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          comments {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          commentsCount
          uploadedVideo {
            fileName
            videoContainer
            videoEncoding
          }
          transcriptionSummaryStatus
          transcriptionSummaryError
          transcriptionSummary
          transcriptionObject {
            key
            bucket
            accessLevel
            identityId
          }
          updatedAt
        }
        comment {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        emailMessage {
          id
          videoId
          customerId
          channelId
          to
          note
          emailStatus {
            email
            status
            reject_reason
            _id
            deliveredAt
            opens
          }
          subject
          contacts {
            email
            contactId
          }
          createdAt
          updatedAt
        }
        text {
          content
          richContent
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
        }
        file {
          fileName
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          status
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          mimeType
          metadata
        }
        engagement {
          items {
            customerId
            seen
            seenAt
            videoProgress
          }
          nextToken
        }
        referencePostId
        referencePost {
          id
          channelId
          accessId
          customerId
          postType
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          comment {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          emailMessage {
            id
            videoId
            customerId
            channelId
            to
            note
            emailStatus {
              email
              status
              reject_reason
              _id
              deliveredAt
              opens
            }
            subject
            contacts {
              email
              contactId
            }
            createdAt
            updatedAt
          }
          text {
            content
            richContent
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
          }
          file {
            fileName
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            status
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            mimeType
            metadata
          }
          engagement {
            items {
              customerId
              seen
              seenAt
              videoProgress
            }
            nextToken
          }
          referencePostId
          referencePost {
            id
            channelId
            accessId
            customerId
            postType
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            comment {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            emailMessage {
              id
              videoId
              customerId
              channelId
              to
              note
              emailStatus {
                email
                status
                reject_reason
                _id
                deliveredAt
                opens
              }
              subject
              contacts {
                email
                contactId
              }
              createdAt
              updatedAt
            }
            text {
              content
              richContent
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
            }
            file {
              fileName
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              status
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              mimeType
              metadata
            }
            engagement {
              items {
                customerId
                seen
                seenAt
                videoProgress
              }
              nextToken
            }
            referencePostId
            referencePost {
              id
              channelId
              accessId
              customerId
              postType
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              comment {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              emailMessage {
                id
                videoId
                customerId
                channelId
                to
                note
                emailStatus {
                  email
                  status
                  reject_reason
                  _id
                  deliveredAt
                  opens
                }
                subject
                contacts {
                  email
                  contactId
                }
                createdAt
                updatedAt
              }
              text {
                content
                richContent
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
              }
              file {
                fileName
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                status
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                mimeType
                metadata
              }
              engagement {
                items {
                  customerId
                  seen
                  seenAt
                  videoProgress
                }
                nextToken
              }
              referencePostId
              referencePost {
                id
                channelId
                accessId
                customerId
                postType
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                comment {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                emailMessage {
                  id
                  videoId
                  customerId
                  channelId
                  to
                  note
                  subject
                  createdAt
                  updatedAt
                }
                text {
                  content
                  richContent
                  customerId
                }
                file {
                  fileName
                  status
                  customerId
                  mimeType
                  metadata
                }
                engagement {
                  nextToken
                }
                referencePostId
                referencePost {
                  id
                  channelId
                  accessId
                  customerId
                  postType
                  referencePostId
                  readHorizon
                  postTempId
                  createdAt
                  updatedAt
                }
                readHorizon
                postTempId
                reactions {
                  nextToken
                }
                createdAt
                updatedAt
              }
              readHorizon
              postTempId
              reactions {
                items {
                  postId
                  customerId
                  unicode
                  videoTimestamp
                  channelId
                  incrementBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            readHorizon
            postTempId
            reactions {
              items {
                postId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                unicode
                videoTimestamp
                channelId
                incrementBy
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          readHorizon
          postTempId
          reactions {
            items {
              postId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              unicode
              videoTimestamp
              channelId
              incrementBy
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        readHorizon
        postTempId
        reactions {
          items {
            postId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            unicode
            videoTimestamp
            channelId
            incrementBy
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      email
      firstName
      lastName
      profilePhoto {
        key
        bucket
        accessLevel
        identityId
      }
      brandLogo {
        key
        bucket
        accessLevel
        identityId
      }
      calendlyUrl
      lastSeen
      settings {
        notifyEmailWatched
        notifyEmailComment
        notifyUnseenMessages
        timezone
        pushNotifications {
          video
          comment
          reply
          text
        }
        lastChannel
        enableSplashScreen
        termsAndConditions {
          version
          accepted
          acceptedAt
        }
        channelRecentActivityDays
      }
      stripeId
      createdAt
      updatedAt
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      ownerCustomerId
      firstName
      lastName
      email
      mobilePhone
      contactCustomerMeta {
        items {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listContactsByOwner = /* GraphQL */ `
  query ListContactsByOwner(
    $ownerCustomerId: ID!
    $lastNameFirstName: ModelscreensightContactByOwnerSortedByNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByOwner(
      ownerCustomerId: $ownerCustomerId
      lastNameFirstName: $lastNameFirstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerCustomerId
        firstName
        lastName
        email
        mobilePhone
        contactCustomerMeta {
          items {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchScreensightContacts = /* GraphQL */ `
  query SearchScreensightContacts(
    $filter: SearchablescreensightContactFilterInput
    $sort: [SearchablescreensightContactSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablescreensightContactAggregationInput]
  ) {
    searchScreensightContacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        ownerCustomerId
        firstName
        lastName
        email
        mobilePhone
        contactCustomerMeta {
          items {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const listChannelsByCustomer = /* GraphQL */ `
  query ListChannelsByCustomer(
    $customerId: ID!
    $categoryPositionName: ModelscreensightChannelByCustomerCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannelsByCustomer(
      customerId: $customerId
      categoryPositionName: $categoryPositionName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        channelType
        name
        categoryPosition
        readHorizon
        activity {
          new
          latestAt
          latestPost {
            id
            channelId
            accessId
            customerId
            postType
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            comment {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            emailMessage {
              id
              videoId
              customerId
              channelId
              to
              note
              emailStatus {
                email
                status
                reject_reason
                _id
                deliveredAt
                opens
              }
              subject
              contacts {
                email
                contactId
              }
              createdAt
              updatedAt
            }
            text {
              content
              richContent
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
            }
            referencePostId
            referencePost {
              id
              channelId
              accessId
              customerId
              postType
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              comment {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              emailMessage {
                id
                videoId
                customerId
                channelId
                to
                note
                emailStatus {
                  email
                  status
                  reject_reason
                  _id
                  deliveredAt
                  opens
                }
                subject
                contacts {
                  email
                  contactId
                }
                createdAt
                updatedAt
              }
              text {
                content
                richContent
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
              }
              referencePostId
              referencePost {
                id
                channelId
                accessId
                customerId
                postType
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                comment {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                emailMessage {
                  id
                  videoId
                  customerId
                  channelId
                  to
                  note
                  subject
                  createdAt
                  updatedAt
                }
                text {
                  content
                  richContent
                  customerId
                }
                referencePostId
                referencePost {
                  id
                  channelId
                  accessId
                  customerId
                  postType
                  referencePostId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
        chatPartnerId
        chatPartnerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        latestPostAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchScreensightVideos = /* GraphQL */ `
  query SearchScreensightVideos(
    $filter: SearchablescreensightVideoFilterInput
    $sort: [SearchablescreensightVideoSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablescreensightVideoAggregationInput]
  ) {
    searchScreensightVideos(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        createdAt
        customerId
        channelId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        title
        searchTitle
        status
        fileObject {
          key
          bucket
          accessLevel
          identityId
        }
        filePresignedUrl
        duration
        name
        thumbnailObject {
          key
          bucket
          accessLevel
          identityId
        }
        accessType
        accessId
        password
        width
        height
        viewsCountTotal
        viewsCountUnique
        viewsList {
          items {
            videoId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            guestName
            viewCount
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          nextToken
          count
        }
        commentsCount
        uploadedVideo {
          fileName
          videoContainer
          videoEncoding
        }
        transcriptionSummaryStatus
        transcriptionSummaryError
        transcriptionSummary
        transcriptionObject {
          key
          bucket
          accessLevel
          identityId
        }
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const listCommentsByVideo = /* GraphQL */ `
  query ListCommentsByVideo(
    $videoId: ID!
    $parentCreatedAtCreatedAt: ModelscreensightVideoCommentByVideoCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightVideoCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByVideo(
      videoId: $videoId
      parentCreatedAtCreatedAt: $parentCreatedAtCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        videoId
        channelId
        parentId
        content
        richContent
        videoTimestamp
        resolved
        replies {
          items {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          nextToken
          count
        }
        replyCount
        parentCreatedAt
        createdAt
        updatedAt
      }
      nextToken
      count
    }
  }
`;
export const listCommentsByParent = /* GraphQL */ `
  query ListCommentsByParent(
    $parentId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightVideoCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByParent(
      parentId: $parentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        videoId
        channelId
        parentId
        content
        richContent
        videoTimestamp
        resolved
        replies {
          items {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          nextToken
          count
        }
        replyCount
        parentCreatedAt
        createdAt
        updatedAt
      }
      nextToken
      count
    }
  }
`;
export const searchScreensightPosts = /* GraphQL */ `
  query SearchScreensightPosts(
    $filter: SearchablescreensightPostFilterInput
    $sort: [SearchablescreensightPostSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablescreensightPostAggregationInput]
  ) {
    searchScreensightPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        channelId
        accessId
        customerId
        postType
        video {
          id
          createdAt
          customerId
          channelId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          title
          searchTitle
          status
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          filePresignedUrl
          duration
          name
          thumbnailObject {
            key
            bucket
            accessLevel
            identityId
          }
          accessType
          accessId
          password
          width
          height
          viewsCountTotal
          viewsCountUnique
          viewsList {
            items {
              videoId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              guestName
              viewCount
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          comments {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          commentsCount
          uploadedVideo {
            fileName
            videoContainer
            videoEncoding
          }
          transcriptionSummaryStatus
          transcriptionSummaryError
          transcriptionSummary
          transcriptionObject {
            key
            bucket
            accessLevel
            identityId
          }
          updatedAt
        }
        comment {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        emailMessage {
          id
          videoId
          customerId
          channelId
          to
          note
          emailStatus {
            email
            status
            reject_reason
            _id
            deliveredAt
            opens
          }
          subject
          contacts {
            email
            contactId
          }
          createdAt
          updatedAt
        }
        text {
          content
          richContent
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
        }
        file {
          fileName
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          status
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          mimeType
          metadata
        }
        engagement {
          items {
            customerId
            seen
            seenAt
            videoProgress
          }
          nextToken
        }
        referencePostId
        referencePost {
          id
          channelId
          accessId
          customerId
          postType
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          comment {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          emailMessage {
            id
            videoId
            customerId
            channelId
            to
            note
            emailStatus {
              email
              status
              reject_reason
              _id
              deliveredAt
              opens
            }
            subject
            contacts {
              email
              contactId
            }
            createdAt
            updatedAt
          }
          text {
            content
            richContent
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
          }
          file {
            fileName
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            status
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            mimeType
            metadata
          }
          engagement {
            items {
              customerId
              seen
              seenAt
              videoProgress
            }
            nextToken
          }
          referencePostId
          referencePost {
            id
            channelId
            accessId
            customerId
            postType
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            comment {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            emailMessage {
              id
              videoId
              customerId
              channelId
              to
              note
              emailStatus {
                email
                status
                reject_reason
                _id
                deliveredAt
                opens
              }
              subject
              contacts {
                email
                contactId
              }
              createdAt
              updatedAt
            }
            text {
              content
              richContent
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
            }
            file {
              fileName
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              status
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              mimeType
              metadata
            }
            engagement {
              items {
                customerId
                seen
                seenAt
                videoProgress
              }
              nextToken
            }
            referencePostId
            referencePost {
              id
              channelId
              accessId
              customerId
              postType
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              comment {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              emailMessage {
                id
                videoId
                customerId
                channelId
                to
                note
                emailStatus {
                  email
                  status
                  reject_reason
                  _id
                  deliveredAt
                  opens
                }
                subject
                contacts {
                  email
                  contactId
                }
                createdAt
                updatedAt
              }
              text {
                content
                richContent
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
              }
              file {
                fileName
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                status
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                mimeType
                metadata
              }
              engagement {
                items {
                  customerId
                  seen
                  seenAt
                  videoProgress
                }
                nextToken
              }
              referencePostId
              referencePost {
                id
                channelId
                accessId
                customerId
                postType
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                comment {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                emailMessage {
                  id
                  videoId
                  customerId
                  channelId
                  to
                  note
                  subject
                  createdAt
                  updatedAt
                }
                text {
                  content
                  richContent
                  customerId
                }
                file {
                  fileName
                  status
                  customerId
                  mimeType
                  metadata
                }
                engagement {
                  nextToken
                }
                referencePostId
                referencePost {
                  id
                  channelId
                  accessId
                  customerId
                  postType
                  referencePostId
                  readHorizon
                  postTempId
                  createdAt
                  updatedAt
                }
                readHorizon
                postTempId
                reactions {
                  nextToken
                }
                createdAt
                updatedAt
              }
              readHorizon
              postTempId
              reactions {
                items {
                  postId
                  customerId
                  unicode
                  videoTimestamp
                  channelId
                  incrementBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            readHorizon
            postTempId
            reactions {
              items {
                postId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                unicode
                videoTimestamp
                channelId
                incrementBy
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          readHorizon
          postTempId
          reactions {
            items {
              postId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              unicode
              videoTimestamp
              channelId
              incrementBy
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        readHorizon
        postTempId
        reactions {
          items {
            postId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            unicode
            videoTimestamp
            channelId
            incrementBy
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const listVideosWatched = /* GraphQL */ `
  query ListVideosWatched(
    $customerId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelscreensightVideoAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosWatched(
      customerId: $customerId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        videoId
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        guestName
        viewCount
        video {
          id
          createdAt
          customerId
          channelId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          title
          searchTitle
          status
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          filePresignedUrl
          duration
          name
          thumbnailObject {
            key
            bucket
            accessLevel
            identityId
          }
          accessType
          accessId
          password
          width
          height
          viewsCountTotal
          viewsCountUnique
          viewsList {
            items {
              videoId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              guestName
              viewCount
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          comments {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          commentsCount
          uploadedVideo {
            fileName
            videoContainer
            videoEncoding
          }
          transcriptionSummaryStatus
          transcriptionSummaryError
          transcriptionSummary
          transcriptionObject {
            key
            bucket
            accessLevel
            identityId
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
