import * as React from 'react'
import { useContext } from 'react'
import { Box } from '@mui/material'
import { screensightVideo } from '../../../../../../../../API'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import VideoPostExistingSummaryControls from './ui/components/VideoPostExistingSummaryControls'
import AISummaryInitializer from './ui/components/AISummaryInitializer'
import useVideoPostAISummary from './common/hooks/useVideoPostAISummary'
import AISummaryContent from './ui/components/AISummaryResult'

type VideoPostAiSummaryProps = {
  beforeControls?: React.ReactNode
  videoData: screensightVideo
  owner?: boolean | undefined
}

const VideoPostAISummary = (props: VideoPostAiSummaryProps) => {
  const auth = useContext(AuthContext)
  const state = useVideoPostAISummary(props.videoData.transcriptionSummaryError)

  return (
    <Box>
      <Box display={'flex'} flexDirection={props.owner ? 'row-reverse' : 'row'} justifyContent={'space-between'}>
        <Box>{props.beforeControls}</Box>
        {auth.isAuthenticated && (
          <Box mt={'5px'}>
            {!props.videoData.transcriptionSummary &&
              props.videoData.fileObject?.bucket &&
              props.videoData.fileObject?.key && (
                <AISummaryInitializer
                  handleExperimentalInfoVisibility={state.handleExperimentalInfoVisibility}
                  videoData={props.videoData}
                  onError={state.handleErrorMessage}
                  isError={!!state.error}
                />
              )}

            {props.videoData.transcriptionSummary &&
              props.videoData.fileObject?.bucket &&
              props.videoData.fileObject?.key && (
                <VideoPostExistingSummaryControls
                  onSummaryClick={() => state.handleContentOptionSelection('SUMMARY')}
                  onTranscriptionClick={() => state.handleContentOptionSelection('TRANSCRIPTION')}
                  contentSelected={state.contentSelected}
                />
              )}
          </Box>
        )}
      </Box>
      {auth.isAuthenticated &&
        (state.error ||
          (state.experimentalInfoVisible && !props.videoData?.transcriptionSummary) ||
          props.videoData?.transcriptionSummary) && (
          <AISummaryContent
            error={state.error}
            experimentalInfoVisible={state.experimentalInfoVisible}
            transcriptionSummary={props.videoData.transcriptionSummary}
            handleExperimentalInfoVisibility={state.handleExperimentalInfoVisibility}
            contentSelected={state.contentSelected}
            bucket={props.videoData?.transcriptionObject?.bucket}
            bucketKey={props.videoData?.transcriptionObject?.key}
          />
        )}
    </Box>
  )
}

export default VideoPostAISummary
