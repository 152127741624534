import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { Storage } from '@aws-amplify/storage'
import { captureException } from '@sentry/react'

const useVideoTranscription = (bucket: string, bucketKey: string) => {
  const [transcription, setTranscription] = React.useState<string>()
  const [error, setError] = React.useState<string>('')
  const { t } = useTranslation('chat')

  useEffect(() => {
    const getTranscription = async () => {
      if (!bucketKey || !bucket) return
      try {
        const credentials = await Auth.currentCredentials()
        const fileData = await Storage.get(bucketKey, {
          bucket: bucket,
          download: true,
          customPrefix: {
            public: '',
          },
          credentials: credentials,
        })

        const jsonString = await (fileData?.Body as Blob).text()
        const jsonResult = JSON.parse(jsonString)

        setTranscription(jsonResult?.results?.transcripts[0]?.transcript)
      } catch (err) {
        captureException(err)
        console.log('Getting transcription error:', err)
        setError(t('chatBody.videoPostSummaryAI.errorGettingTranscription'))
      }
    }
    getTranscription()
  }, [])

  return { transcription, error }
}

export default useVideoTranscription
