import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material'
import RecorderStateContext from '../../../../common/providers/RecorderStateProvider/RecorderStateContext'

const useCameraWithPrompterPreview = (cameraStream?: MediaStream | null, paused?: boolean) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const theme = useTheme()
  const [editorState, setEditorState] = useState<'DEFAULT' | 'FONT' | 'SPEED' | 'TEXT'>('TEXT')
  const { state: recorderState, dispatch: recorderDispatcher } = useContext(RecorderStateContext)
  const prompterRef = useRef<HTMLTextAreaElement>(null)
  const [isPrompterPlaying, setIsPrompterPlaying] = useState(recorderState.prompter.currentState === 'RECORDING')

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    recorderDispatcher({ action: 'UPDATE_PROMPTER_STATE', value: { text: e.target.value } })
  }

  const setDefaultEditorState = () => {
    setEditorState('DEFAULT')
  }

  const setFontEditorState = () => {
    setEditorState('FONT')
  }

  const setSpeedEditorState = () => {
    setEditorState('SPEED')
  }

  const setTextEditorState = () => {
    setEditorState((prevState) => {
      if (prevState === 'TEXT') {
        if (prompterRef.current) {
          prompterRef.current.scrollTo(0, 0)
        }
        return 'DEFAULT'
      }
      return 'TEXT'
    })
  }

  useEffect(() => {
    if (editorState === 'TEXT') {
      recorderDispatcher({ action: 'UPDATE_PROMPTER_STATE', value: { textEditing: true } })
    } else {
      recorderDispatcher({ action: 'UPDATE_PROMPTER_STATE', value: { textEditing: false } })
    }
  }, [editorState])

  const handlePlayingPrompterChange = () => {
    setIsPrompterPlaying((prevState) => !prevState)
  }

  useEffect(() => {
    if (paused) {
      setIsPrompterPlaying(false)
    } else {
      setIsPrompterPlaying(true)
    }
  }, [paused])

  useEffect(() => {
    if (recorderState.prompter.currentState === 'RECORDING') {
      setEditorState('DEFAULT')
    }
  }, [recorderState.prompter.currentState])

  const hidePrompterWindow = () => {
    recorderDispatcher({ action: 'UPDATE_PROMPTER_STATE', value: { isHidden: true } })
  }

  const showPrompterWindow = () => {
    recorderDispatcher({ action: 'UPDATE_PROMPTER_STATE', value: { isHidden: false } })
  }

  useEffect(() => {
    if (!videoRef.current || !cameraStream) return

    videoRef.current.srcObject = cameraStream
    videoRef.current.play()
  }, [videoRef, cameraStream])

  const increaseTextSpeed = () => {
    const newSpeed = recorderState.prompter.textSpeed + 10
    if (recorderState.prompter.textSpeed <= 80) {
      recorderDispatcher({
        action: 'UPDATE_PROMPTER_STATE',
        value: { textSpeed: newSpeed },
      })
    }
  }

  const decreaseTextSpeed = () => {
    if (recorderState.prompter.textSpeed >= 20) {
      recorderDispatcher({
        action: 'UPDATE_PROMPTER_STATE',
        value: { textSpeed: recorderState.prompter.textSpeed - 10 },
      })
    }
  }

  const handleChangeFontSize = (e: any, newValue: number | number[]) => {
    recorderDispatcher({
      action: 'UPDATE_PROMPTER_STATE',
      value: { fontSize: newValue as number },
    })
  }

  const handleSliderTextSpeed = (e: any, newValue: number | number[]) => {
    recorderDispatcher({
      action: 'UPDATE_PROMPTER_STATE',
      value: { textSpeed: newValue as number },
    })
  }

  const scrollPrompterToTop = () => {
    if (!prompterRef.current) return
    prompterRef.current.scrollTo(0, 0)
  }

  return {
    theme,
    editorState,
    handleTextChange,
    setDefaultEditorState,
    setFontEditorState,
    setSpeedEditorState,
    setTextEditorState,
    increaseTextSpeed,
    decreaseTextSpeed,
    handleSliderTextSpeed,
    handleChangeFontSize,
    videoRef,
    hidePrompterWindow,
    showPrompterWindow,
    prompterRef,
    scrollPrompterToTop,
    handlePlayingPrompterChange,
    isPrompterPlaying,
  }
}

export default useCameraWithPrompterPreview
