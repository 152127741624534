import React from 'react'
import useSingleContactEditHook from './hooks/useSingleContactEdit.hook'
import SingleContactEditView from './view/SingleContactEdit.view'

const SingleContactEdit = () => {
  const state = useSingleContactEditHook()
  return <SingleContactEditView {...state} />
}

export default SingleContactEdit
