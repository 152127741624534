import React, { SetStateAction, useState } from 'react'
import DraftContext from './DraftContext'

interface IDraftContextProviderProps {
  children: React.ReactNode
}
export interface IDraftContext {
  drafts: {
    [key: string]: string
  }
  setDraft: (channelId: string, content: string) => void
  getDraft: (channelId: string) => SetStateAction<string>
  removeDraft: (channelId: string) => void
}

const DraftContextProvider = ({ children }: IDraftContextProviderProps) => {
  const [drafts, setDrafts] = useState(JSON.parse(localStorage.getItem('chatDraftMessages') || '{}'))

  const setNewDrafts = (newDrafts: IDraftContext['drafts']) => {
    setDrafts(newDrafts)
    localStorage.setItem(`chatDraftMessages`, JSON.stringify(newDrafts))
  }

  const setDraft: IDraftContext['setDraft'] = (channelId, content) => {
    const newContent = content?.replace(/(<p>((\s*)|(<br>))<\/p>)/, '')
    const newDrafts = { ...drafts }
    if (newContent) {
      newDrafts[channelId] = content
    } else {
      delete newDrafts[channelId]
    }
    setNewDrafts(newDrafts)
  }

  const getDraft: IDraftContext['getDraft'] = (channelId) => {
    return drafts[channelId]?.replace(/(<p>((\s*)|(<br>))<\/p>)/, '')
  }

  const removeDraft: IDraftContext['removeDraft'] = (channelId) => {
    const newDrafts = { ...drafts }
    delete newDrafts[channelId]
    setNewDrafts(newDrafts)
  }

  return <DraftContext.Provider value={{ setDraft, getDraft, drafts, removeDraft }}>{children}</DraftContext.Provider>
}

export default DraftContextProvider
