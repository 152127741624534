import React from 'react'
import { Box, Typography } from '@mui/material'
import styled from 'styled-components'
import FullScreenLoader from '../../../../components/_refactored/Loaders/FullScreenLoader'
import { ListVideoAudienceEngagementQuery } from '../../../../API'
import { useTranslation } from 'react-i18next'

interface IProps {
  d3Chart: any
  height: number
  fullNameTooltip: { text: string; left: number; top: number }
  videoStatisticDataLoading: boolean
  viewsTotal?: number | null
  videoStatisticData?: ListVideoAudienceEngagementQuery
}

const StyledChartWrapper = styled(Box)`
  .viewer-box {
    filter: drop-shadow(0px 5px 12px rgba(164, 184, 208, 0.2));
  }
`

const VideoItemAnalyticsBarChartView = (props: IProps) => {
  const { t } = useTranslation('library')

  if (!props.videoStatisticData?.listVideoAudienceEngagement?.length)
    return (
      <Box display="flex" justifyContent="space-between" marginTop="20px" marginBottom="20px">
        {props.videoStatisticDataLoading ? <FullScreenLoader withoutSidebar /> : null}
        <Typography variant="h3" color="textPrimary" />
        <Typography variant="body1" color="textSecondary">
          {props?.viewsTotal || 0} {t('videoStatistics.views')}
        </Typography>
      </Box>
    )
  return (
    <>
      {props.videoStatisticDataLoading ? <FullScreenLoader withoutSidebar /> : null}
      <Box display="flex" justifyContent="space-between" marginTop="20px">
        <Typography variant="h3" color="textPrimary">
          {t('videoStatistics.engagementChartTitle')}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {props?.viewsTotal || 0} {t('videoStatistics.views')}
        </Typography>
      </Box>
      <StyledChartWrapper
        marginTop="20px"
        marginBottom="50px"
        bgcolor="background.paper"
        borderRadius="10px"
        height={`${105 + props.height}px`}
        width="100%"
        border="2px solid white"
      >
        <Box
          height="71px"
          width="100%"
          bgcolor="background.default"
          style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
        >
          <Box width="100%" id="analytics__chart-wrapper">
            {props.fullNameTooltip.text && (
              <Box
                position="absolute"
                zIndex={101}
                left={`${props.fullNameTooltip.left}px`}
                top={`${props.fullNameTooltip.top}px`}
                bgcolor="rgba(20, 35, 61, 0.7)"
                padding="5px 15px"
                borderRadius="12px"
                color="white"
              >
                {props.fullNameTooltip.text}
              </Box>
            )}
            <svg
              ref={props.d3Chart}
              style={{
                height: props.height,
                width: '100%',
                marginRight: '10px',
                marginLeft: '10px',
                color: '#BCBCC3',
                fontSize: '12px',
                overflow: 'visible',
              }}
            />
          </Box>
        </Box>
      </StyledChartWrapper>
    </>
  )
}

export default VideoItemAnalyticsBarChartView
