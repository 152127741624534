import React from 'react'

const FontIcon = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.23389 12.2168H3.76611L2.7627 15H1.3125L5.38477 4.33594H6.61523L10.6948 15H9.25195L8.23389 12.2168ZM4.19092 11.0596H7.81641L6 6.07178L4.19092 11.0596Z"
        fill="white"
      />
      <path
        d="M15.4043 11.6895H9.89648L8.74414 15H6.17578L11.5469 0.78125H13.7637L19.1445 15H16.5664L15.4043 11.6895ZM10.5898 9.69727H14.7109L12.6504 3.79883L10.5898 9.69727Z"
        fill="white"
      />
    </svg>
  )
}

export default FontIcon
