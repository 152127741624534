import React from 'react'
import Header from './ui/partials/Header'
import Layout from './ui/Layouts/Layout'
import Content from './ui/partials/Content'

const ChannelsSidebar = () => {
  return (
    <Layout>
      <Header />
      <Content />
    </Layout>
  )
}

export default ChannelsSidebar
