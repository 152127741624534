import * as React from 'react'
import ReactQuill from 'react-quill'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, IconButton } from '@mui/material'
import { TUseQuill } from '../../../common/hooks/quill/useQuill'
import '../../../common/styles/quil.style.scss'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import { theme } from '../../../../../../../../common/providers/MuiThemeProvider'

const QuillEditor = (props: TUseQuill & { videoPlayerEditor?: boolean | null; quillExpanded?: boolean }) => {
  const { t } = useTranslation('chat')
  return (
    <Box className="customer-chat__quill-wrapper" display={props?.videoPlayerEditor ? 'flex' : 'block'}>
      <Box
        display={props.isVideoFromLinkLoading ? 'flex' : 'none'}
        justifyContent="center"
        position="absolute"
        paddingTop="10px"
        width="100%"
        height="100%"
        bgcolor="white"
        zIndex={9}
      >
        <CircularProgress size={25} />
      </Box>

      <ReactQuill
        readOnly={props.isVideoFromLinkLoading}
        style={{
          pointerEvents: props.isVideoFromLinkLoading ? 'none' : 'all',
          width: props?.videoPlayerEditor ? 'calc(100% - 40px)' : '100%',
          maxHeight: props?.videoPlayerEditor ? '260px' : '200px',
          height: props.videoPlayerEditor && !props.quillExpanded ? '50px' : 'auto',
        }}
        key={`${props.channelId}-${props.channelDefaultValue}`}
        ref={props.quillRef}
        placeholder={props.isVideoFromLinkLoading ? 'none' : t('chatBody.chatInputPlaceholder')}
        onChange={props.handleChange}
        defaultValue={props.channelDefaultValue || ''}
        modules={props.quillModules}
        onKeyDown={props.onKeyDown}
        formats={['bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link']}
      />
      {props?.videoPlayerEditor && (
        <IconButton sx={{ boxShadow: 'none' }} onClick={props.handleSubmit}>
          <SendRoundedIcon style={{ color: theme.palette.primary.main }} />
        </IconButton>
      )}
    </Box>
  )
}
export default QuillEditor
