import * as React from 'react'
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import ModalLibrarySearcher from '../components/ModalLibrarySearcher'
import Button from '../../../../../components/_refactored/Button'
import BackIcon from '../../../../../common/icons/BackIcon'
import { theme } from '../../../../../common/providers/MuiThemeProvider'

interface IHeaderProps {
  onClose: () => void
  searchPhrase: string
  // handleSearchPhraseChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  handleSearchPhraseChange: any
  onSubmit: any
  clearSearchPhrase: () => void
  selectedVideoId?: string
}

const Header = (props: IHeaderProps) => {
  const { t } = useTranslation('chat')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  //TODO: split this into two components separate for desktop & mobile
  if (isMobile) {
    return (
      <Box width="100%" paddingTop="23px">
        <Box display="flex" alignItems="center">
          <Box position="absolute" top={15} left={18}>
            <IconButton style={{ boxShadow: 'none' }} onClick={props.onClose}>
              <Box display="flex" alignItems="center">
                <BackIcon />
                <Typography variant="body1" style={{ paddingLeft: '8px' }}>
                  {t('selectFromLibraryModal.backBtn')}
                </Typography>
              </Box>
            </IconButton>
          </Box>
          <Typography align="center" variant="body1" style={{ fontWeight: 500, flexGrow: 1 }}>
            {t('selectFromLibraryModal.title')}
          </Typography>
          {props?.selectedVideoId && (
            <Box position="absolute" top={15} right={5}>
              <Button
                variant="text"
                color="primary"
                style={{ width: '47px' }}
                onClick={props.onSubmit}
                disabled={!props?.selectedVideoId}
              >
                {t('selectFromLibraryModal.selectBtn')}
              </Button>
            </Box>
          )}
        </Box>
        <Box padding="20px" width={isMobile ? '100%' : '250px'}>
          <ModalLibrarySearcher
            searchPhrase={props.searchPhrase}
            handleSearchPhraseChange={props.handleSearchPhraseChange}
            clearSearchPhrase={props.clearSearchPhrase}
          />
        </Box>
      </Box>
    )
  }

  return (
    <Box width="100%" paddingTop="23px">
      <Typography align="center" variant="h6" style={{ fontWeight: 500, width: '100%', textAlign: 'center' }}>
        {t('selectFromLibraryModal.title')}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 20px">
        <ModalLibrarySearcher
          searchPhrase={props.searchPhrase}
          handleSearchPhraseChange={props.handleSearchPhraseChange}
          clearSearchPhrase={props.clearSearchPhrase}
        />
        <Button variant="contained" color="primary" onClick={props.onSubmit} disabled={!props.selectedVideoId}>
          {t('selectFromLibraryModal.selectBtn')}
        </Button>
      </Box>
      <Box position="absolute" top={5} right={5}>
        <IconButton style={{ height: '14px', width: '14px', boxShadow: 'none' }} onClick={props.onClose}>
          <Close />
        </IconButton>
      </Box>
    </Box>
  )
}

export default Header
