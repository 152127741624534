import { BehaviorSubject, Observable, of, Subscription } from 'rxjs'
import { delay, filter, map, mergeMap } from 'rxjs/operators'
import { UploadScheduler, WorkerEvent } from './UploadScheduler'
import {
  Retry,
  SchedulerItemAbortEvent,
  SchedulerItemErrorEvent,
  SchedulerItemEvent,
  SchedulerItemStatus,
  SchedulerItemSuccessEvent,
} from './SchedulerItem.types'

export class SchedulerItem {
  status: SchedulerItemStatus = 'INIT'
  retries: Retry[] = [
    { retry: 0, time: 0 },
    { retry: 1, time: 2000 },
    { retry: 2, time: 4000 },
    { retry: 3, time: 8000 },
  ]

  event = new BehaviorSubject<SchedulerItemEvent>({ type: 'INIT' })
  retry = new BehaviorSubject<Retry>(this.retries[0])

  size: number

  scheduler: UploadScheduler

  subs = new Subscription()

  constructor(scheduler: UploadScheduler, size = 1) {
    this.scheduler = scheduler
    this.size = size
  }

  schedule() {
    this.status = 'SCHEDULED'
    this.event.next({ type: 'SCHEDULED' })
    this.scheduler.scheduleItem(
      () => this.uploadTrigger(),
      () => this.abort(),
      this.schedulerObservable(),
      this.size,
    )
  }

  uploadTrigger() {
    this.retry.pipe(mergeMap((ev) => of(ev).pipe(delay(ev.time)))).subscribe(
      async (ev) => {
        // Retry
        try {
          this.log('Attempt', ev.retry)
          this.setUploading(0)

          await this.singleUpload()
          this.retry.complete()
        } catch (err: any) {
          const nextRetry = this.retries[ev.retry + 1]

          if (nextRetry) this.retry.next(nextRetry)
          else this.retry.error(null)
        }
      },
      () => this.setError(),
      () => this.setSuccess(),
    )
  }

  async singleUpload() {
    return
  }
  abort() {
    return
  }
  cleanUp() {
    return
  }

  setSuccess() {
    this.log('Success.')
    this.setUploading(this.size)
    this.status = 'SUCCESS'
    this.event.next({ type: 'SUCCESS' })
  }

  setError() {
    this.log('Error!')
    this.status = 'ERROR'
    this.event.next({ type: 'ERROR' })
  }

  setAbort() {
    this.log('Aborted.')
    this.status = 'ABORT'
    this.event.next({ type: 'ABORT' })
  }

  setUploading(loaded: number) {
    this.event.next({ type: 'UPLOADING', loaded, total: this.size })
  }
  observable() {
    return this.event.asObservable()
  }

  schedulerObservable() {
    return this.event.pipe(
      filter((ev) => ev.type === 'UPLOADING' || ev.type === 'SUCCESS' || ev.type === 'ABORT' || ev.type === 'ERROR'),
      map((ev) => ev as WorkerEvent),
    )
  }

  completeObservable() {
    return this.event.pipe(
      filter((ev) => ev.type === 'SUCCESS' || ev.type === 'ERROR' || ev.type === 'ABORT'),
    ) as Observable<SchedulerItemSuccessEvent | SchedulerItemErrorEvent | SchedulerItemAbortEvent>
  }
  log(...args: any[]) {
    console.log(new Date().toISOString(), `[SchedulerItem]:`, ...args)
  }
}
