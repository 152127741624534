import React from 'react'
import VideoItemAnalyticsBarChartView from './view/VideoItemAnalyticsBarChart.view'
import useVideoItemAnalyticsBarChartHook from './hooks/useVideoItemAnalyticsBarChart.hook'

interface IProps {
  duration?: number | null
  handleSectionPlay: (data: number[], domElWidth: number) => void
  viewsTotal?: number | null
  videoId?: string
  videoAccessId?: string
}

const VideoItemAnalyticsBarChart = (props: IProps) => {
  const state = useVideoItemAnalyticsBarChartHook(
    props?.videoId,
    props?.videoAccessId,
    props.duration,
    props.handleSectionPlay,
  )
  return <VideoItemAnalyticsBarChartView {...state} viewsTotal={props?.viewsTotal} />
}

export default VideoItemAnalyticsBarChart
