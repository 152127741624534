import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import CloudFrontImage from '../../../components/_refactored/CloudFrontImage'

interface IContactAvatar {
  image?: string
  name?: string
  width?: number
  height?: number
  fontSize?: string
  onClick?: () => void
  removePublicPrefix?: boolean
  style?: React.CSSProperties
}

const ContactAvatar = (props: IContactAvatar) => {
  return (
    <Box
      marginRight="15px"
      height={props.height ? `${props.height}px` : '43px'}
      onClick={props?.onClick}
      style={{ cursor: props?.onClick ? 'pointer' : 'default', ...props.style }}
    >
      <Tooltip title={props.name || ''}>
        {props?.image ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="background.default"
            borderRadius="100px"
            height={'100%'}
            width={'100%'}
            zIndex={2}
          >
            <CloudFrontImage
              image={props.removePublicPrefix ? props.image : `public/${props.image}`}
              width={props.width || '43'}
              height={props.height || '43'}
              imgStyle={{
                width: props.width ? `${props.height}px` : '43px',
                height: props.height ? `${props.height}px` : '43px',
                borderRadius: '100px',
                objectFit: 'cover',
              }}
            />
          </Box>
        ) : (
          <Box
            height={props.height ? `${props.height}px` : '43px'}
            width={props.width ? `${props.height}px` : '43px'}
            borderRadius="100px"
            border="1px solid #7A7A86"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="background.default"
          >
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ fontWeight: 500, fontSize: props.fontSize || '15px' }}
            >
              {props?.name?.charAt(0).toUpperCase()}
            </Typography>
          </Box>
        )}
      </Tooltip>
    </Box>
  )
}

export default ContactAvatar
