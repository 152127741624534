import React, { ChangeEvent, RefObject, useContext } from 'react'
import { IconButton, Slider, Theme, Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import RecorderStateContext from '../../../../common/providers/RecorderStateProvider/RecorderStateContext'
import PropmterIconButton from 'components/VideoRecorder/PropmterIconButton'
import { Check, Close } from '@mui/icons-material'
import PencilIcon from '../../../../common/icons/Pencil'
import FontIcon from '../../../../common/icons/FontIcon'
import TextSpeedIcon from '../../../../common/icons/TextSpeedIcon'
import StandingPersonIcon from '../../../../common/icons/StandingPersonIcon'
import PrompterText from '../../PrompterText'
import KeyboardArrows from '../../CameraModal/KeyboardArrows'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import Button from '../../../../components/_refactored/Button'
import ReplayIcon from '@mui/icons-material/Replay'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import StopRoundedIcon from '@mui/icons-material/StopRounded'
import { useTranslation } from 'react-i18next'

interface IProps {
  theme: Theme
  editorState: 'DEFAULT' | 'FONT' | 'SPEED' | 'TEXT'
  handleTextChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  setDefaultEditorState: () => void
  setFontEditorState: () => void
  setSpeedEditorState: () => void
  setTextEditorState: () => void
  increaseTextSpeed: () => void
  decreaseTextSpeed: () => void
  hidePrompterWindow: () => void
  showPrompterWindow: () => void
  handleSliderTextSpeed: (e: any, newValue: number | number[]) => void
  handleChangeFontSize: (e: any, newValue: number | number[]) => void
  videoRef: React.Ref<HTMLVideoElement>
  scrollPrompterToTop: () => void
  prompterRef: RefObject<HTMLTextAreaElement>
  handlePlayingPrompterChange: () => void
  isPrompterPlaying: boolean
}

const useStyles = makeStyles({
  root: {
    height: 470,
    width: '100%',
    overflow: 'hidden',
    borderRadius: '10px 10px 0 0',
    position: 'relative',
  },
  video: {
    background: '#000',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    borderRadius: '10px 10px 0 0',
    position: 'relative',
    zIndex: 1,
    objectFit: 'cover',
  },
})

const CameraWithPrompterPreviewView = (props: IProps) => {
  const { root, video } = useStyles()
  const { t: g } = useTranslation('general')
  const { state: recorderState } = useContext(RecorderStateContext)

  return (
    <Box className={root}>
      {recorderState.prompter.isHidden ? (
        <PropmterIconButton
          style={{ marginRight: '0', position: 'absolute', top: '16px', right: '16px', zIndex: 100 }}
          onClick={props.showPrompterWindow}
        >
          <Tooltip title={g('recordControl.videoRecorderPreviewModal.showPrompterIconTitle')}>
            <TextFieldsIcon style={{ color: props.theme.palette.background.default }} />
          </Tooltip>
        </PropmterIconButton>
      ) : (
        <Box
          width="450px"
          height="90%"
          flexDirection="column"
          justifyContent={recorderState.prompter.currentState === 'SETUP' ? 'center' : 'space-between'}
          position="absolute"
          left="50%"
          top="50%"
          zIndex={100}
          style={{ transform: 'translate(-50%, -50%)' }}
          display="flex"
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            {props.editorState === 'DEFAULT' || props.editorState === 'TEXT' ? (
              <>
                <Box display="flex" alignItems="center" height="40px">
                  {recorderState.prompter.currentState !== 'SETUP' ? null : props.editorState === 'TEXT' ? null : (
                    <PropmterIconButton
                      onClick={props.setTextEditorState}
                      disabled={!recorderState.prompter.text.length}
                    >
                      <PencilIcon />
                    </PropmterIconButton>
                  )}
                  {props.editorState !== 'TEXT' ? (
                    <>
                      <PropmterIconButton onClick={props.setFontEditorState}>
                        <FontIcon />
                      </PropmterIconButton>
                      <PropmterIconButton onClick={props.setSpeedEditorState}>
                        <TextSpeedIcon />
                      </PropmterIconButton>
                      <PropmterIconButton onClick={props.handlePlayingPrompterChange}>
                        {props.isPrompterPlaying ? (
                          <StopRoundedIcon style={{ color: 'white' }} />
                        ) : (
                          <PlayArrowRoundedIcon style={{ color: 'white' }} />
                        )}
                      </PropmterIconButton>
                      <PropmterIconButton
                        onClick={props.scrollPrompterToTop}
                        style={{
                          marginRight: '0',
                        }}
                      >
                        <ReplayIcon style={{ color: 'white' }} />
                      </PropmterIconButton>
                    </>
                  ) : null}
                </Box>
                {props.editorState !== 'TEXT' ? (
                  <PropmterIconButton style={{ marginRight: '0' }} onClick={props.hidePrompterWindow}>
                    <Tooltip title={g('recordControl.videoRecorderPreviewModal.hidePrompterIconTitle')}>
                      <Close style={{ color: props.theme.palette.background.default }} />
                    </Tooltip>
                  </PropmterIconButton>
                ) : null}
              </>
            ) : null}
            {props.editorState === 'FONT' ? (
              <>
                <Box
                  height="40px"
                  bgcolor="rgba(20, 35, 61, 0.8)"
                  borderRadius="10px"
                  padding="20px"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body1"
                    style={{
                      color: props.theme.palette.background.default,
                      width: '130px',
                      fontSize: '15px',
                      fontWeight: 500,
                    }}
                  >
                    {g('recordControl.videoRecorderPreviewModal.fontSizeRangeTitle')}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" style={{ color: props.theme.palette.background.default }}>
                      {g('recordControl.videoRecorderPreviewModal.letterA')}
                    </Typography>
                    <Slider
                      style={{ width: '150px', margin: '0 10px' }}
                      value={recorderState.prompter.fontSize}
                      max={34}
                      min={12}
                      onChange={props.handleChangeFontSize}
                      aria-labelledby="text-speed-slider"
                    />
                    <Typography
                      variant="body2"
                      style={{ color: props.theme.palette.background.default, fontSize: '20px' }}
                    >
                      {g('recordControl.videoRecorderPreviewModal.letterA')}
                    </Typography>
                  </Box>
                </Box>
                <IconButton
                  onClick={props.setDefaultEditorState}
                  style={{
                    color: props.theme.palette.background.default,
                    background: props.theme.palette.primary.main,
                  }}
                >
                  <Check />
                </IconButton>
              </>
            ) : null}
            {props.editorState === 'SPEED' ? (
              <>
                <Box
                  height="40px"
                  bgcolor="rgba(20, 35, 61, 0.8)"
                  borderRadius="10px"
                  padding="20px"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body1"
                    style={{
                      color: props.theme.palette.background.default,
                      width: '130px',
                      fontSize: '15px',
                      fontWeight: 500,
                    }}
                  >
                    {g('recordControl.videoRecorderPreviewModal.scrollSpeedRangeTitle')}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <StandingPersonIcon />
                    <Slider
                      style={{ width: '150px', margin: '0 10px' }}
                      value={recorderState.prompter.textSpeed}
                      max={100}
                      min={1}
                      valueLabelDisplay="auto"
                      onChange={props.handleSliderTextSpeed}
                      aria-labelledby="text-speed-slider"
                    />
                    <TextSpeedIcon />
                  </Box>
                </Box>
                <IconButton
                  onClick={props.setDefaultEditorState}
                  style={{
                    color: props.theme.palette.background.default,
                    background: props.theme.palette.primary.main,
                  }}
                >
                  <Check />
                </IconButton>
              </>
            ) : null}
          </Box>
          <Box
            width="100%"
            height="65%"
            bgcolor="rgba(20, 35, 61, 0.8)"
            borderRadius="10px"
            padding="20px"
            marginTop="10px"
          >
            <Box
              width="100%"
              height="100%"
              style={{
                overflowX: 'hidden',
                overflowY:
                  props.editorState === 'TEXT' && recorderState.prompter.currentState !== 'RECORDING'
                    ? 'auto'
                    : 'hidden',
              }}
            >
              <PrompterText
                increaseTextSpeed={props.increaseTextSpeed}
                decreaseTextSpeed={props.decreaseTextSpeed}
                editorState={props.editorState}
                handleTextChange={props.handleTextChange}
                prompterRef={props.prompterRef}
                isPrompterPlaying={props.isPrompterPlaying}
              />
            </Box>
          </Box>
          {props.editorState === 'TEXT' ? (
            <Box width="142px" height="70px" borderRadius="10px" padding="5px 20px" margin="10px auto 0 auto">
              {recorderState.prompter.text.length ? (
                <Button variant="contained" color="primary" onClick={props.setTextEditorState}>
                  {g('recordControl.videoRecorderPreviewModal.nextBtn')}
                </Button>
              ) : null}
            </Box>
          ) : (
            <KeyboardArrows prompterRef={props.prompterRef} />
          )}
        </Box>
      )}

      <video className={video} ref={props.videoRef} />
    </Box>
  )
}

export default CameraWithPrompterPreviewView
