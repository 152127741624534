import { useTranslation } from 'react-i18next'
import Modal from '../../../components/_refactored/Modal'
import { Box, Typography } from '@mui/material'
import Button from '../../../components/_refactored/Button'
import React, { FC, PropsWithChildren } from 'react'

const Section: FC<PropsWithChildren> = ({ children }) => {
  return <Box marginBottom={'10px'}>{children}</Box>
}

const SectionTitle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box marginBottom={'20px'}>
      <Typography variant={'h6'} style={{ fontWeight: 700 }}>
        {children}
      </Typography>
    </Box>
  )
}

const SectionSubTitle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box marginBottom={'20px'} marginLeft={'15px'}>
      <Typography variant={'h6'} style={{ fontWeight: 700 }}>
        {children}
      </Typography>
    </Box>
  )
}

const SectionParagraph: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box marginBottom={'20px'} marginLeft={'15px'}>
      <Typography paragraph color="textSecondary">
        {children}
      </Typography>
    </Box>
  )
}

type TermsAndConditionsProps = {
  termsAndConditionsVersion: string
  loading: boolean
  onAccept: (accepted: boolean) => void
}

const TermsAndConditions: FC<PropsWithChildren<TermsAndConditionsProps>> = ({
  loading,
  termsAndConditionsVersion,
  onAccept,
}) => {
  const { t } = useTranslation('termsAndConditions')

  return (
    <Modal open={true} boxStyle={{ width: '100%' }}>
      <>
        <Box bgcolor="background.default" maxHeight={'600px'}>
          <Box>
            <Box display={'flex'} flexDirection={'column'} width="100%">
              <Box marginBottom={'30px'}>
                <Typography variant={'h3'} style={{ fontWeight: 700 }}>
                  {t('general.title')}
                </Typography>
                <Typography variant={'body1'} color={'secondary'} style={{ fontSize: 14 }}>
                  {t('general.lastUpdatedLabel')}: {termsAndConditionsVersion}
                </Typography>
              </Box>
              <Box overflow={'auto'} style={{ width: '100%', maxHeight: '400px', overflow: 'auto' }}>
                <Box marginBottom={'30px'}>
                  <Typography paragraph color={'secondary'}>
                    These Terms of Use for ScreenSight Services (these “Terms”) shall govern your access to and use of
                    the Services made available by Parus Holdings, Inc.(collectively “Parus”). By creating a ScreenSight
                    account or by using our Services, you agree to be bound by these Terms. If you use our Services on
                    behalf of an entity or organization, you are binding that organization to these Terms, and you
                    represent that you have the authority to do so.
                  </Typography>
                </Box>

                <Box marginBottom={'30px'}>
                  <Typography paragraph color={'secondary'}>
                    Capitalized terms in these Terms are defined in Section 14 below, except as otherwise noted.
                  </Typography>
                </Box>

                <Box marginBottom={'30px'}>
                  <Typography paragraph color={'secondary'}>
                    PLEASE CAREFULLY REVIEW THE DISPUTE RESOLUTION PROVISIONS IN SECTION 12 BELOW. THESE GOVERN THE
                    MANNER IN WHICH CLAIMS WILL BE ADDRESSED BETWEEN YOU AND PARUS. THESE PROVISIONS INCLUDE A MANDATORY
                    PRE-ARBITRATION INFORMAL DISPUTE RESOLUTION PROCESS, AN ARBITRATION AGREEMENT AND A CLASS ACTION
                    WAIVER THAT AFFECT YOUR RIGHTS. IN ARBITRATION, THERE IS TYPICALLY LESS DISCOVERY AND APPELLATE
                    REVIEW THAN IN COURT.
                  </Typography>
                </Box>

                <Section>
                  <SectionTitle>1. ScreenSight's Services</SectionTitle>
                  <SectionSubTitle>1.1 Registration.</SectionSubTitle>
                  <SectionParagraph>
                    ScreenSight provides the Services for Customer’s business purposes. Upon registration, a user must
                    either create a new account or join an existing account. If a user creates a new account on behalf
                    of themselves, the user is the Customer. If a user creates a new account on behalf of an
                    organization (such as the user’s employer), the organization is the Customer. Customers can invite
                    Authorized Users to their account and must designate at least one Authorized User to be an
                    Administrator, who will manage and control the account. If a user joins an existing account created
                    by another person or organization, that person or organization is the Customer and the user is an
                    Authorized User. All Customers, Administrators, and Authorized Users must comply with these Terms.
                  </SectionParagraph>
                  <SectionSubTitle>1.2 Access.</SectionSubTitle>
                  <SectionParagraph>
                    During the Subscription Term, Customer may access and use, and may allow Authorized Users to access
                    and use the Services for its business purposes in accordance with these Terms
                  </SectionParagraph>
                  <SectionSubTitle>1.3 Software.</SectionSubTitle>
                  <SectionParagraph>
                    ScreenSight may make Software available as part of the Services. Subject to these Terms, ScreenSight
                    grants to Customer a limited non-exclusive, non-transferable, non-sublicensable license to download
                    and use the Software to the extent necessary to use the Services. Software may update automatically.
                    To the extent a component of the Software contains any open source software, the open source license
                    for that software will govern with respect to that component.
                  </SectionParagraph>
                  <SectionSubTitle>1.4 Restrictions.</SectionSubTitle>
                  <SectionParagraph>
                    Customer will not and will ensure Authorized Users do not: (a) reproduce, modify, adapt, or create
                    derivative works of the Services; (b) rent, lease, distribute, sell, transfer, or sublicense the
                    Services to a third party except as permitted in these Terms; (c) interfere with, disrupt, or
                    circumvent any security measures or mechanisms designed to protect the Services; (d) reverse
                    engineer, disassemble, decompile, or seek to obtain or derive the source code, underlying ideas,
                    algorithms, file formats, or non-public APIs of Services; (e) remove or obscure any proprietary or
                    other notices contained in the Services; (f) harass, threaten, or cause distress, unwanted
                    attention, or discomfort to a person or entity; (g) impersonate or falsely state or misrepresent an
                    affiliation with anyone; (h) use the Services to transmit any viruses, worms, trojan horses, or
                    other harmful or malicious code or programs; (i) use the Services to build competitive products; or
                    (j) misuse the Services in any way, including in violation of these Terms or applicable law. Any use
                    of data mining, robots, web scraping, or similar data gathering and extraction tools or framing all
                    or any portion of the Services without ScreenSight’s permission is strictly prohibited. Customer
                    will prevent, terminate, and notify ScreenSight of any unauthorized or illegal use of or access to
                    its or any Authorized User account or the Services
                  </SectionParagraph>
                </Section>

                <Section>
                  <SectionTitle>2. Customer Responsibilities</SectionTitle>
                  <SectionSubTitle>2.1 Authorized Users.</SectionSubTitle>
                  <SectionParagraph>
                    Customer is responsible for Authorized Users’ use of the Services and their compliance with these
                    Terms. Customer will ensure that all Authorized Users are over the age of 13 or the applicable age
                    of digital consent, whichever is older. Customer represents that it has obtained all consents
                    necessary for Customer and its Authorized Users’ use of the Services, including Customer’s ability
                    to convert accounts previously registered by individuals using Customer’s business email domain into
                    Authorized User accounts. Customer is responsible for maintaining the security and confidentiality
                    of Administrator and Authorized User accounts, including account credentials such as usernames and
                    passwords.
                  </SectionParagraph>
                  <SectionSubTitle>2.2 Customer Data.</SectionSubTitle>
                  <SectionParagraph>
                    Customer is responsible for ensuring all Customer Data and any other content or information provided
                    to ScreenSight or uploaded, posted, recorded, or transmitted to the Services complies with these
                    Terms. If any Customer Data violates these terms, ScreenSight may ask Customer to remove or edit the
                    Customer Data so it is no longer in violation, or ScreenSight may, but is not obligated to, remove
                    the Customer Data or take action as needed to resolve the issue.
                  </SectionParagraph>
                  <SectionSubTitle>2.3 Third-Party Apps.</SectionSubTitle>
                  <SectionParagraph>
                    Third-Party Apps are subject to their own terms and privacy policies. Customer is responsible for
                    reviewing those terms and policies before using Third-Party Apps. ScreenSight is not responsible for
                    Third-Party Apps and does not warrant or support them. By enabling a Third-Party App that uses or
                    accesses Customer Data, Customer authorizes the transfer of that data to the Third-Party App and, if
                    applicable, will ensure that there is a legally valid data transfer mechanism for the transfer or
                    onward transfer, in accordance with all applicable privacy and data protection laws.
                  </SectionParagraph>
                  <SectionSubTitle>2.4 Prohibited Content.</SectionSubTitle>
                  <SectionParagraph>
                    Customer will not and will ensure Authorized Users do not upload, post, record, or transmit any
                    harmful, offensive, unlawful, or otherwise objectionable content to ScreenSight or the Services,
                    including content that: (a) is threatening, abusive, harassing, defamatory, vulgar, obscene,
                    discriminatory, or hateful; (b) infringes any patent, trademark, trade secret, copyright or other
                    intellectual property right or violates the rights of others in any way; (c) contains pornography or
                    sexually explicit material; (d) is harmful to minors; or (e) promotes or provides instructional
                    information about illegal activities or promotes physical harm or injury to any group or individual.
                  </SectionParagraph>
                  <SectionSubTitle>2.5 ScreenSight Rights.</SectionSubTitle>
                  <SectionParagraph>
                    ScreenSight reserves the right to investigate, suspend and/or terminate any Customer account, remove
                    or delete any content provided or posted in the Services (without refund) and/or take any other
                    lawful action, if ScreenSight discovers that Customer violated these Terms (including, without
                    limitation Customer’s obligations under this Section 2 and Section 1.4), misused the Services or
                    otherwise behaved in a way that ScreenSight regards as inappropriate or unlawful.
                  </SectionParagraph>
                </Section>

                <Section>
                  <SectionTitle>3. Data Protection</SectionTitle>
                  <SectionSubTitle>3.1 Processing of Customer Data.</SectionSubTitle>
                  <SectionParagraph>
                    ScreenSight will only process and use Customer Data: (a) to provide, protect, and update the
                    Services and create new services, (b) to enforce these Terms and exercise its rights hereunder, (c)
                    as required by law, and (d) as otherwise instructed by Customer. ScreenSight may collect and use
                    Customer Data on an aggregated and/or anonymized basis and for ScreenSight’s business purposes;
                    provided that ScreenSight will not use or share this data in a way that identifies any individual or
                    Customer as the source of the data.
                  </SectionParagraph>
                  <SectionSubTitle>3.2 Processing of Personal Information.</SectionSubTitle>
                  <SectionParagraph>
                    ScreenSight will process Personal Information in accordance with ScreenSight’s Privacy Policy found
                    at www.screensight.com/legal, as may be amended from time to time, which is incorporated into these
                    Terms.
                  </SectionParagraph>
                  <SectionSubTitle>3.3 Subcontractors.</SectionSubTitle>
                  <SectionParagraph>
                    ScreenSight may hire subcontractors to assist in providing the Services, provided that: (a)
                    subcontractors who receive Customer Data will be subject to confidentiality obligations no less
                    protective than those in these Terms; (b) ScreenSight will remain responsible for its obligations
                    under these Terms and for subcontractors’ use of Customer Data; and (c) ScreenSight’s Privacy Policy
                    www.screensight.com/legal will govern any subprocessing of Personal Information.
                  </SectionParagraph>
                </Section>
                <Section>
                  <SectionTitle>4. Payment</SectionTitle>
                  <SectionSubTitle>4.1 Fees.</SectionSubTitle>
                  <SectionParagraph>
                    Customer agrees to pay the Fees in accordance with the payment terms set forth on the Order.
                    Customer is responsible for paying any applicable Taxes.
                  </SectionParagraph>
                  <SectionSubTitle>4.2 Renewals.</SectionSubTitle>
                  <SectionParagraph>
                    Unless otherwise stated on an Order, each Subscription Term will automatically renew, unless
                    Customer cancels their subscription at least 60 days prior to annual renewal. Monthly price plans
                    may cancel at any time before next billing cycle. Customer authorizes ScreenSight to charge
                    Customer’s credit card on file or invoice Customer for each renewal until Customer cancels their
                    subscription.
                  </SectionParagraph>
                  <SectionSubTitle>4.3 Downgrades.</SectionSubTitle>
                  <SectionParagraph>
                    If Customer fails to pay Fees when they are due, ScreenSight may downgrade Customer to a free plan
                    upon reasonable notice. If Customer is downgraded, Customer will lose access to certain features or
                    functionality, but these Terms will continue to apply.
                  </SectionParagraph>
                  <SectionSubTitle>4.4 Price Changes.</SectionSubTitle>
                  <SectionParagraph>
                    If ScreenSight increases its pricing, ScreenSight will provide at least 30 days’ prior notice, and
                    the new pricing will go into effect on renewal. If Customer does not terminate its subscription
                    before renewal, Customer authorizes ScreenSight to collect payment for the increased pricing.
                  </SectionParagraph>
                </Section>
                <Section>
                  <SectionTitle>5. Confidentiality</SectionTitle>
                  <SectionSubTitle>5.1 Use.</SectionSubTitle>
                  <SectionParagraph>
                    If the parties disclose Confidential Information to each other, the recipient will only use the
                    disclosing party's Confidential Information to exercise its rights and fulfill its obligations under
                    these Terms. The recipient will use at least reasonable care to protect Confidential Information.
                  </SectionParagraph>
                  <SectionSubTitle>5.2 Nondisclosure.</SectionSubTitle>
                  <SectionParagraph>
                    The recipient will not disclose Confidential Information to anyone except to its affiliates,
                    employees, agents, or contractors who need to know it and who are bound by confidentiality
                    obligations at least as protective of Confidential Information as those described in this section.
                    The recipient will be responsible for those parties’ breach of this section. A breach of this
                    section may cause irreparable harm and entitle the disclosing party to injunctive relief.
                  </SectionParagraph>
                  <SectionSubTitle>5.3 Required Disclosure.</SectionSubTitle>
                  <SectionParagraph>
                    The recipient may disclose Confidential Information to the extent required by law, provided that the
                    recipient uses reasonable efforts to notify the disclosing party in advance and gives them an
                    opportunity to contest the disclosure (except as described in Section 8). Confidential Information
                    disclosed pursuant to this section will otherwise still be subject to the confidentiality
                    obligations described above.
                  </SectionParagraph>
                </Section>
                <Section>
                  <SectionTitle>6. Intellectual Property Rights</SectionTitle>
                  <SectionSubTitle>6.1 Ownership.</SectionSubTitle>
                  <SectionParagraph>
                    Except as expressly stated, these Terms do not grant any rights, implied or otherwise, to any
                    intellectual property. Customer owns and will continue to own all intellectual property rights in
                    Customer Data, and ScreenSight owns and will continue to own all intellectual property rights in the
                    Services.
                  </SectionParagraph>
                  <SectionSubTitle>6.2 License.</SectionSubTitle>
                  <SectionParagraph>
                    Customer grants ScreenSight a worldwide non-exclusive license to access, use, process, copy, modify,
                    distribute, perform, export, and display Customer Data solely to provide the Services or as
                    otherwise described in these Terms. Customer represents and warrants that it has secured all
                    necessary licenses to provide the foregoing license.
                  </SectionParagraph>
                  <SectionSubTitle>6.3 Feedback.</SectionSubTitle>
                  <SectionParagraph>
                    If Customer or Authorized Users provide Feedback to ScreenSight, ScreenSight may use it without
                    restriction or obligation.
                  </SectionParagraph>
                </Section>
                <Section>
                  <SectionTitle>7. Term & Termination</SectionTitle>
                  <SectionSubTitle>7.1 Term.</SectionSubTitle>
                  <SectionParagraph>
                    These Terms will continue in effect until terminated as described below.
                  </SectionParagraph>
                  <SectionSubTitle>7.2 Termination for Convenience.</SectionSubTitle>
                  <SectionParagraph>
                    Customer may terminate these Terms at any time by deleting Customer’s account, including all
                    associated Administrator and Authorized User accounts.
                  </SectionParagraph>
                  <SectionSubTitle>7.3 Termination for Breach.</SectionSubTitle>
                  <SectionParagraph>
                    Either party may terminate these Terms for material breach if the other party fails to cure the
                    breach within 30 days’ notice.
                  </SectionParagraph>
                  <SectionSubTitle>7.4 Effect of Termination.</SectionSubTitle>
                  <SectionParagraph>
                    Upon termination, ScreenSight may delete or anonymize all Customer Data within a commercially
                    reasonable period of time, and each party will return or delete any other Confidential Information
                    in its possession. ScreenSight will only provide a prorated refund of prepaid fees for the remainder
                    of the Subscription Term if Customer terminates these Terms for ScreenSight’s material breach in
                    accordance with Section 7.3.
                  </SectionParagraph>
                  <SectionSubTitle>7.5 Suspension.</SectionSubTitle>
                  <SectionParagraph>
                    ScreenSight may suspend Customer’s access to the Services: (a) to the extent required by law, (b) to
                    prevent injury, death, or a credible risk of harm to ScreenSight, the Services, or its users, or (c)
                    for repeated violations of our terms and policies, including our Copyright Policy. If practicable,
                    ScreenSight will use reasonable efforts to contact Customer and give Customer the opportunity to
                    resolve the issue prior to suspension. 7.6 Survival. Terms that are intended by their nature to
                    survive termination will survive, including confidentiality obligations, limitations of liability,
                    and disclaimers.
                  </SectionParagraph>
                </Section>
                <Section>
                  <SectionTitle>8. Legal Process</SectionTitle>
                  <SectionParagraph>
                    If ScreenSight receives a request from law enforcement or another third party to provide data or
                    information about Customer or its Authorized Users, ScreenSight will only respond to valid Legal
                    Process to the extent required by law. ScreenSight will notify Customer of the request unless
                    legally prohibited or notification would threaten the safety of an individual.
                  </SectionParagraph>
                </Section>
                <Section>
                  <SectionTitle>9. Warranty and Disclaimer</SectionTitle>
                  <SectionSubTitle>9.1 Limited Warranty.</SectionSubTitle>
                  <SectionParagraph>
                    The Services will perform materially in accordance with ScreenSight’s Documentation. If Customer
                    believes that this warranty has been breached, Customer must notify ScreenSight no later than 10
                    days following the date of the alleged breach. Customer’s sole and exclusive remedies for breach of
                    this warranty are those described in Sections 7.
                  </SectionParagraph>
                  <SectionSubTitle>9.2 Disclaimer.</SectionSubTitle>
                  <SectionParagraph>
                    EXCEPT AS STATED IN THIS SECTION, THE SERVICES, DOCUMENTATION AND SOFTWARE ARE PROVIDED ON AN “AS
                    IS” BASIS WITHOUT WARRANTY. SCREENSIGHT MAKES NO GUARANTEES AS TO UPTIME OR AVAILABILITY OF THE
                    SERVICE. TO THE EXTENT PERMITTED BY LAW, SCREENSIGHT EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES,
                    WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR
                    A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. SCREENSIGHT IS NOT RESPONSIBLE FOR THE CONTENT,
                    INFORMATION, OR MATERIALS POSTED BY THIRD PARTIES TO THE SERVICES OR ANY DAMAGE RESULTING FROM
                    LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC
                    COMMUNICATIONS, INCLUDING INTERNET CONGESTION, VIRUS ATTACKS, AND DENIAL OF SERVICE ATTACKS.
                  </SectionParagraph>
                  <SectionSubTitle>9.3 Beta Services.</SectionSubTitle>
                  <SectionParagraph>
                    Customer may choose to use Beta Services in its sole discretion. Notwithstanding anything to the
                    contrary, Beta Services: (a) may not be supported and may be changed or discontinued at any time;
                    (b) may not be as reliable or available as the Services; (c) have not been subjected to the same
                    security or compliance reviews as the Services; (d) are ScreenSight’s Confidential Information; and
                    (e) ARE PROVIDED “AS IS” WITHOUT ANY WARRANTY, INDEMNITY, OR SUPPORT AND SCREENSIGHT’S LIABILITY FOR
                    BETA SERVICES WILL NOT EXCEED FIFTY DOLLARS.
                  </SectionParagraph>
                </Section>
                <Section>
                  <SectionTitle>10. Indemnification</SectionTitle>
                  <SectionSubTitle>10.1 Indemnification by ScreenSight.</SectionSubTitle>
                  <SectionParagraph>
                    ScreenSight will defend Customer from and against any third-party claim, action, demand, or
                    proceeding alleging that Customer’s use of the Services as permitted under these Terms infringes or
                    misappropriates a third party’s intellectual property rights, and will indemnify and hold Customer
                    harmless for reasonable attorney’s fees incurred as well as damages and other costs finally awarded
                    against Customer or agreed in settlement by ScreenSight resulting from the claim; provided, however,
                    that ScreenSight will have no liability under this section if a claim arises from (a) Customer Data
                    or any third-party product or service; (b) any modification, combination, or development of the
                    Services that is not performed by or on behalf of ScreenSight; (c) Customer’s breach of these Terms;
                    or (d) Beta Services or other services offered for free or evaluation use.
                  </SectionParagraph>
                  <SectionSubTitle>10.2 Indemnification by Customer.</SectionSubTitle>
                  <SectionParagraph>
                    Customer will defend ScreenSight from and against any third-party claim, action, demand, or
                    proceeding resulting from Customer Data or a violation of these Terms by Customer or its Authorized
                    Users, and will indemnify and hold ScreenSight harmless for reasonable attorney’s fees and costs
                    incurred as well as damages and other costs finally awarded against ScreenSight or agreed in
                    settlement by Customer resulting from the claim.
                  </SectionParagraph>
                  <SectionSubTitle>10.3 Possible Infringement.</SectionSubTitle>
                  <SectionParagraph>
                    If the Services infringe or are alleged to infringe a third party's intellectual property rights,
                    ScreenSight may: (a) obtain the right for Customer, at ScreenSight’s expense, to continue using the
                    Services; (b) provide, at no additional charge, a non-infringing functionally equivalent
                    replacement; or (c) modify the Services so that they no longer infringe. If ScreenSight does not
                    believe the options above are commercially reasonable, ScreenSight may terminate Customer's use of
                    the affected Services and provide Customer a prorated refund of prepaid fees for the remainder of
                    the Subscription Term. ScreenSight will have no liability under this section if Customer continues
                    the allegedly infringing activity after being notified and provided with a non-infringing
                    functionally equivalent replacement.
                  </SectionParagraph>
                  <SectionSubTitle>10.4 Procedures.</SectionSubTitle>
                  <SectionParagraph>
                    The party seeking indemnification will promptly notify the other party of the claim and cooperate
                    with the other party in defending the claim. The indemnifying party will have full control and
                    authority over the defense, except that: (a) any settlement that does not include a complete release
                    of the indemnified party from all liability or that imposes any admission, obligation, or
                    restriction on the indemnified party requires prior written consent, not to be unreasonably withheld
                    or delayed and (b) the indemnified party may join in the defense with its own counsel at its own
                    expense. The indemnities above are Customer’s only remedy for infringement of third-party
                    intellectual property rights.
                  </SectionParagraph>
                </Section>
                <Section>
                  <SectionTitle>11. Limitation of Liability</SectionTitle>
                  <SectionSubTitle></SectionSubTitle>
                  <SectionParagraph>
                    ScreenSight does not control what people and others do or say, and ScreenSight is not responsible
                    for their (or Customer’s or any Authorized User’s) actions or conduct (whether online or offline) or
                    content (including unlawful or objectionable content). ScreenSight also is not responsible for
                    services and features offered by other people or companies, even if Customer or any Authorized User
                    accesses them through our Services. ScreenSight’s responsibility for anything that happens on the
                    Services (also called “liability”) is limited as much as the law will allow. If there is an issue
                    with ScreenSight’s Services, ScreenSight can’t know what all the possible impacts might be. Customer
                    and Authorized Users agree that ScreenSight won’t be responsible (“liable”) for any lost profits,
                    revenues, information, or data, or consequential , special, indirect, exemplary, punitive, or
                    incidental damages arising out of or related to these Terms, even if ScreenSight knows they are
                    possible. This includes when ScreenSight deletes Customer’s and any Authorized User’s content,
                    information or account (any such termination is addressed specifically in Section 7 herein).
                    ScreenSight’s aggregate liability arising out of or relating to these Terms will not exceed the
                    greater of $50 or the amount you have paid to us in the past twelve months.
                  </SectionParagraph>
                  <SectionSubTitle>11.2 Indirect Damages.</SectionSubTitle>
                  <SectionParagraph>
                    EXCEPT FOR LIABILITY ARISING FROM A PARTY’S GROSS NEGLIGENCE, WILLFUL MISCONDUCT, OR BREACH OF
                    SECTIONS 1.4 OR 2.4, NEITHER PARTY WILL HAVE ANY LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS,
                    WHETHER IN CONTRACT OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY, FOR ANY LOSS OF USE, LOST
                    PROFITS, INTERRUPTION, OR LOSS OF BUSINESS OR DATA OR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE,
                    RELIANCE OR CONSEQUENTIAL DAMAGES OF ANY KIND, HOWEVER CAUSED AND WHETHER OR NOT THE PARTY HAS BEEN
                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  </SectionParagraph>
                  <SectionSubTitle>11.3 Total Liability.</SectionSubTitle>
                  <SectionParagraph>
                    EXCEPT FOR LIABILITY ARISING FROM A PARTY’S GROSS NEGLIGENCE, WILLFUL MISCONDUCT, OR BREACH OF
                    SECTIONS 1.4 OR 2.4, EACH PARTY'S ENTIRE LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS, WHETHER
                    IN CONTRACT OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY, WILL NOT EXCEED IN AGGREGATE OF THE
                    GREATER OF FIFTY DOLLARS OR THE AMOUNT PAID BY CUSTOMER TO SCREENSIGHT IN THE 12 MONTHS PRECEDING
                    THE LAST EVENT GIVING RISE TO LIABILITY. THE FOREGOING DISCLAIMER WILL NOT LIMIT CUSTOMER’S PAYMENT
                    OBLIGATIONS ABOVE.
                  </SectionParagraph>
                  <SectionParagraph>
                    THIS SECTION WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
                  </SectionParagraph>
                </Section>
                <Section>
                  <SectionTitle>12. Disputes; Class-Action Waiver</SectionTitle>
                  <SectionSubTitle>12.1. Jurisdiction and Venue.</SectionSubTitle>
                  <SectionParagraph>
                    Subject to the dispute resolution provisions below, all claims and disputes relating to these Terms
                    or the Services may only be brought in the federal or state courts of Texas. Both Customer and
                    ScreenSight consent to venue and personal jurisdiction there. Notwithstanding anything to the
                    contrary, if Customer represents an entity or institution subject to state law mandating different
                    dispute resolution terms or governing law, ScreenSight agrees to such state law requirements.
                  </SectionParagraph>
                  <SectionSubTitle>12.2 Informal Resolution.</SectionSubTitle>
                  <SectionParagraph>
                    Before filing a claim, ScreenSight must first be contacted through the notice procedures below. If a
                    dispute is not resolved within 30 days of notice, a formal proceeding may be brought in accordance
                    with this section.
                  </SectionParagraph>
                  <SectionSubTitle>12.3 Mandatory Arbitration.</SectionSubTitle>
                  <SectionParagraph>
                    Any claims or disputes relating to these Terms or the Services must be resolved through final and
                    binding arbitration, except as set forth below. The American Arbitration Association (AAA) will
                    administer the arbitration under its Commercial Arbitration Rules. The arbitration will be in
                    English and held in Texas.
                  </SectionParagraph>
                  <SectionSubTitle>12.4 Exceptions.</SectionSubTitle>
                  <SectionParagraph>
                    A lawsuit may be filed in the federal or state courts of Texas solely for injunctive relief to stop
                    unauthorized use or abuse of the Services or infringement of intellectual property rights without
                    first engaging in the informal dispute resolution process described above.
                  </SectionParagraph>
                  <SectionSubTitle>12.5 NO CLASS ACTIONS.</SectionSubTitle>
                  <SectionParagraph>
                    Disputes with ScreenSight may only be resolved on an individual basis and neither Customer nor any
                    user will bring a claim in a class, consolidated, or representative action. The parties expressly
                    waive any class arbitrations, class actions, private attorney general actions, and consolidation
                    with other arbitrations.
                  </SectionParagraph>
                  <SectionSubTitle>12.6. Opt-Out.</SectionSubTitle>
                  <SectionParagraph>
                    To opt out of these arbitration provisions (including the waiver of class and representative
                    claims), ScreenSight must be notified by email at legal@screensight.com no later than 30 days after
                    account registration, unless a longer period is required by applicable law.
                  </SectionParagraph>
                </Section>
                <Section>
                  <SectionTitle>13. Miscellaneous</SectionTitle>
                  <SectionSubTitle>13.1 Entire Agreement.</SectionSubTitle>
                  <SectionParagraph>
                    These Terms supersede any prior agreements or understandings between the parties and constitutes the
                    entire agreement between the parties related to access and use of the Services. The terms of the
                    Order will control over these Terms to the extent there is a conflict. Any amendments must be in
                    writing and signed by the parties. Notwithstanding anything to the contrary, no other terms or
                    conditions in any Customer documentation or online vendor portals will apply to ScreenSight’s
                    provision of the Services to Customer, unless expressly agreed to in writing by an authorized
                    ScreenSight representative.
                  </SectionParagraph>
                  <SectionSubTitle>13.2 Modifications.</SectionSubTitle>
                  <SectionParagraph>
                    ScreenSight may update these Terms by posting the updates to the ScreenSight website. If an update
                    materially impacts Customer’s or its Authorized Users’ rights or obligations, ScreenSight will
                    provide at least 30 days’ notice before the updated Terms go into effect. Any other revisions will
                    become effective on the date the updated Terms are posted by ScreenSight. Continued use of or access
                    to the Services after the updated Terms go into effect will constitute acceptance of those Terms.
                  </SectionParagraph>
                  <SectionSubTitle>13.3 Publicity.</SectionSubTitle>
                  <SectionParagraph>
                    Upon prior written approval from Customer, ScreenSight may include Customer’s name and logo on its
                    website or in other marketing materials or channels solely to reference Customer as ScreenSight
                    customer, and subject to any trademark usage guidelines provided to ScreenSight. Customer may revoke
                    its approval at any time.
                  </SectionParagraph>
                  <SectionSubTitle>13.4 Waiver and Severance.</SectionSubTitle>
                  <SectionParagraph>
                    A waiver of any default is not a waiver of any subsequent default. Unenforceable provisions will be
                    modified to reflect the parties' intention, but only to the extent necessary to make them
                    enforceable, and the remaining provisions will remain in full effect.
                  </SectionParagraph>
                  <SectionSubTitle>13.5 Notices.</SectionSubTitle>
                  <SectionParagraph>
                    Notices must be sent via first class post, airmail, or overnight courier and are deemed given when
                    received. Notices to Customer may also be sent to the applicable Administrator account email address
                    and are deemed given when sent. Notices to ScreenSight must be sent to 111 Congress Ave., Suite
                    1060, Austin, TX 78701, Attn: Legal, with a copy to legal@screensight.com.
                  </SectionParagraph>
                  <SectionSubTitle>13.6 Assignment.</SectionSubTitle>
                  <SectionParagraph>
                    These Terms may not be assigned without the prior written consent of the other party, except
                    ScreenSight may assign these Terms to an affiliate or in connection with a merger, acquisition,
                    corporate reorganization, or sale of all or substantially all of its assets. Any other attempt to
                    transfer or assign is void.
                  </SectionParagraph>
                  <SectionSubTitle>13.7 Governing Law.</SectionSubTitle>
                  <SectionParagraph>
                    These terms will be governed by the laws of the state of Texas without regard to its conflict of
                    laws principles.
                  </SectionParagraph>
                  <SectionSubTitle>13.8 Force Majeure.</SectionSubTitle>
                  <SectionParagraph>
                    Except for payment obligations, no party will be liable for inadequate performance to the extent
                    caused by a condition that was beyond the party's reasonable control (such as, natural disaster,
                    terrorism, governmental action, or Internet disturbance).
                  </SectionParagraph>
                  <SectionSubTitle>13.9 Relationship of the Parties. </SectionSubTitle>
                  <SectionParagraph>
                    The parties are independent contractors. These Terms do not create a partnership, joint venture,
                    agency, fiduciary, or employment relationship between the parties.
                  </SectionParagraph>
                  <SectionSubTitle>13.10 Export Controls.</SectionSubTitle>
                  <SectionParagraph>
                    Customer agrees to comply with all relevant U.S. and foreign export and import laws in using the
                    Services. Customer represents and warrants that neither it nor its Authorized Users are listed on
                    any U.S. government list of prohibited or sanctioned parties or located in or a national of a
                    country that is subject to U.S. government embargo.
                  </SectionParagraph>
                  <SectionSubTitle>13.11 U.S. Government Restricted Rights.</SectionSubTitle>
                  <SectionParagraph>
                    The Services are “commercial items,” “commercial computer software” and “commercial computer
                    software documentation,” pursuant to DFAR section 227.7202 and FAR section 12.212, as applicable.
                    All the Services are and were developed solely at private expense. To the extent permitted in these
                    Terms, any use, modification, reproduction, release, performance, display, or disclosure of the
                    Services by the U.S. Government will be governed solely by these Terms.
                  </SectionParagraph>
                  <SectionSubTitle>13.12 Third-Party Beneficiaries.</SectionSubTitle>
                  <SectionParagraph>There are no third-party beneficiaries to these Terms.</SectionParagraph>
                </Section>
                <Section>
                  <SectionTitle>14. Definitions</SectionTitle>
                  <SectionParagraph>
                    “Administrator” means an Authorized User designated by Customer to manage and control Customer’s and
                    Authorized Users’ accounts.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Authorized User(s)” means Customer’s employees, agents, or other third parties authorized by
                    Customer to access or use the Services.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Beta Service” means early access trials or releases of new features or services identified as
                    “alpha,” “beta,” “preview,” “early access,” “evaluation,” or other words or phrases with similar
                    meanings.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Confidential Information” means information exchanged by the parties that would reasonably be
                    understood to be confidential given the nature of the information and manner of disclosure,
                    including business, product, technology, and marketing information, Orders, discounts, non-public
                    payment terms, audit reports, and Customer Data. Confidential Information does not include
                    information that (a) is or becomes publicly known through lawful means; (b) was known to the
                    recipient prior to disclosure without confidentiality obligations; (c) is received from a third
                    party without breach of confidentiality obligations; or (d) was independently developed by the
                    recipient without use of or access to any Confidential Information.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Customer” means the individual or organization agreeing to these Terms, as described in Section
                    1.1.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Customer Data” means all video, audio, or transcription data, images, comments, emoji reactions,
                    user support communications, or other content or information submitted or uploaded to the Services
                    by or collected, stored, or processed by ScreenSight on behalf of Customer or its Authorized Users.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Documentation” means any documentation or materials provided to Customer as part of the Services.
                  </SectionParagraph>
                  <SectionParagraph>“Feedback” means any feedback or suggestions about the Services.</SectionParagraph>
                  <SectionParagraph>
                    “Fees” means the fees for the Services displayed on the Order, plus any applicable Taxes.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Legal Process” means an information disclosure or access request made under law, governmental
                    regulation, court order, subpoena, warrant, governmental regulatory or agency request, or other
                    legal authority, legal procedure, or similar valid process.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Order” means the order form agreed to by the parties or the applicable ScreenSight check-out
                    webpage to purchase the Services.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Personal Information” is defined in the ScreenSight Privacy Policy www.screensight.com/legal.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Services” means ScreenSight’s video messaging products, services and website, including the
                    Software, Documentation, tools, and any other ScreenSight-related products and/or services ordered
                    by or provided to Customer.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Software” means ScreenSight’s client software provided as part of the Services, such as mobile or
                    desktop applications or browser extensions.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Subscription Term” means the applicable initial or renewal term for Customer’s provision of the
                    Services identified in an Order.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Taxes” means all government-imposed taxes, levies, or duties, including value-added, sales, use, or
                    withholding taxes, except for taxes based on ScreenSight’s net income.
                  </SectionParagraph>
                  <SectionParagraph>
                    “Third-Party Apps” means any third-party applications, integrations, websites, products, or services
                    that are linked in or that interact or interoperate with the Services, including any applications
                    built by third parties using ScreenSight’s SDKs or other developer tools.
                  </SectionParagraph>
                </Section>
              </Box>

              <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} paddingTop={'30px'}>
                <Box marginLeft={'15px'}>
                  <Button
                    loading={loading}
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    onClick={() => onAccept(true)}
                  >
                    {t('general.accept')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    </Modal>
  )
}

export default TermsAndConditions
