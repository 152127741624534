import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import {
  ListVideoAudienceQuery,
  ListVideoAudienceQueryVariables,
  ModelscreensightVideoAudienceConnection,
  screensightVideoEmailMessage,
} from '../../../../../../../../API'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { listVideoAudience } from '../../../../../../../../graphql/queries'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'

interface IProps {
  emailVideoData?: screensightVideoEmailMessage | null
  partnerId: string
  videoId?: string
}

const useVideoPostActivity = (props: IProps) => {
  const auth = useContext(AuthContext)

  const [isEmailDelivered, setIsEmailDelivered] = useState<undefined | string>(undefined)
  const [isEmailOpened, setIsEmailOpened] = useState<undefined | string>(undefined)
  const [isSpam, setIsSpam] = useState<boolean>(false)
  const [isVideoOpened, setIsVideoOpened] = useState<undefined | string>(undefined)

  const { data: videoViewersData, loading } = useQuery<ListVideoAudienceQuery, ListVideoAudienceQueryVariables>(
    gql(listVideoAudience),
    {
      variables: { videoId: props?.videoId, customerId: { eq: props.partnerId } },
      skip: !props?.videoId || auth.user?.pending || !auth.isAuthenticated || !props.partnerId,
      client: CognitoApolloClient,
    },
  )

  useEffect(() => {
    if (!props.emailVideoData?.emailStatus?.[0]?.deliveredAt) {
      setIsEmailDelivered(undefined)
      return
    }
    setIsEmailDelivered(moment(props.emailVideoData?.emailStatus?.[0]?.deliveredAt).format('MMM DD, YYYY, h:mm A'))
  }, [props.emailVideoData])

  useEffect(() => {
    if (props.emailVideoData?.emailStatus?.[0]?.status === 'spam') {
      setIsSpam(true)
      return
    } else {
      setIsSpam(false)
    }
    if (!props.emailVideoData?.emailStatus?.[0]?.opens?.[0]) {
      setIsEmailOpened(undefined)
      return
    }
    setIsEmailOpened(moment(props.emailVideoData?.emailStatus?.[0]?.opens?.[0] || '').format('MMM DD, YYYY, h:mm A'))
  }, [props.emailVideoData])

  useEffect(() => {
    if (!videoViewersData?.listVideoAudience?.items?.length) return
    setIsVideoOpened(
      moment(videoViewersData?.listVideoAudience?.items?.[0]?.createdAt || '').format('MMM DD, YYYY, h:mm A'),
    )
  }, [videoViewersData])

  return {
    isEmailDelivered,
    isEmailOpened,
    isVideoOpened,
    isSpam,
    loading,
  }
}

export default useVideoPostActivity
