import React from 'react'
import AuthIconMail from '../../../../../../common/icons/AuthIconMail'
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Button from '../../../../../../components/_refactored/Button'
import ValidatorBlock from '../../../../ui/components/ValidatorBlock'
import useCreateNewPassword from '../../common/hooks/useCreateNewPassword'
import { theme } from '../../../../../../common/providers/MuiThemeProvider'
import { useValidatePassword } from '../../../../../../common/utils/useValidatePassword'
import { useTranslation } from 'react-i18next'

const NewPassword = () => {
  const state = useCreateNewPassword()
  const { validationErrors, validatePassword } = useValidatePassword()
  const { t } = useTranslation('auth')
  const { t: g } = useTranslation('general')

  return (
    <form onSubmit={state.onSubmitPassword} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <AuthIconMail style={{ marginBottom: 20 }} />
      <Typography
        variant="body1"
        color="textPrimary"
        style={{ fontSize: '20px', fontWeight: 500, marginBottom: '20px' }}
      >
        {t('forgotPassword.newPassword.heading')}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        style={{ fontSize: '16px', marginBottom: '50px' }}
      >
        {t('forgotPassword.newPassword.subheading')}
      </Typography>
      <Controller
        name={'code'}
        control={state.passwordForm.control}
        rules={{
          required: g('common.requiredField'),
          validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
        }}
        render={({ value, onChange, onBlur }) => (
          <TextField
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            key="code"
            name="code"
            type="text"
            label={t('common.securityCode')}
            variant="standard"
            error={!!state.passwordForm.errors['code']}
            helperText={state.passwordForm.errors['code']?.message}
            style={{ width: '100%' }}
            disabled={state.loading}
            data-testid="security-code-input"
          />
        )}
      />

      <Typography
        style={{
          alignSelf: 'flex-end',
          cursor: 'pointer',
          margin: '12px 0',
          fontSize: '13px',
          color: theme.palette.primary.main,
        }}
        onClick={state.resendCode}
        data-testid="resend-code"
      >
        {t('forgotPassword.newPassword.resendBtn')}
      </Typography>
      <Controller
        name={'password'}
        control={state.passwordForm.control}
        rules={{
          validate: validatePassword,
        }}
        render={({ value, onChange, onBlur }) => (
          <TextField
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            key="password"
            type={state.isPasswordVisible ? 'text' : 'password'}
            name="password"
            label={t('forgotPassword.newPassword.newPassword')}
            variant="standard"
            error={!!state.passwordForm.errors['password']}
            helperText={state.passwordForm.errors['password']?.message}
            style={{ width: '100%' }}
            disabled={state.loading}
            data-testid="new-password-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear"
                    style={{ boxShadow: 'none', marginRight: '-12px' }}
                    onClick={state.switchPasswordVisible}
                    data-testid="VisibilityOffIcon"
                  >
                    {!state.isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <ValidatorBlock
        text={t('common.passwordRuleOne')}
        error={validationErrors.uppercaseError}
        theme={theme}
        isDirty={state.passwordForm.formState.dirtyFields.password || false}
      />
      <ValidatorBlock
        text={t('common.passwordRuleTwo')}
        error={validationErrors.numberError}
        theme={theme}
        isDirty={state.passwordForm.formState.dirtyFields.password || false}
      />
      <ValidatorBlock
        text={t('common.passwordRuleThree')}
        error={validationErrors.lengthError}
        theme={theme}
        isDirty={state.passwordForm.formState.dirtyFields.password || false}
      />
      <Button
        variant="contained"
        color="primary"
        loading={state.loading}
        type="submit"
        style={{ width: '100%', marginTop: 20 }}
        data-testid="verify-email-confirm"
      >
        {t('forgotPassword.newPassword.confirmBtn')}
      </Button>
    </form>
  )
}

export default NewPassword
