import React from 'react'
import { Box, TextField, Typography } from '@mui/material'
import CalendlyImg from './assets/calendly.png'
import { IOnBoardingCalendly } from '../interfaces'
import { useTranslation } from 'react-i18next'

const OnBoardingCalendly = (props: IOnBoardingCalendly) => {
  const { t } = useTranslation('library')
  return (
    <>
      <Box
        width={props.isMobile ? '100%' : '50%'}
        paddingRight={props.isMobile ? '0' : '16px'}
        paddingTop={props.isMobile ? '0' : '32px'}
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Typography variant="body1" style={{ fontWeight: 500, fontSize: '20px', marginBottom: '10px' }}>
          {t('videoList.onBoardingModal.calendlyIntegrationTitle')}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ fontSize: '15px', marginBottom: '30px' }}>
          {t('videoList.onBoardingModal.calendlyIntegrationSubtitle')}
        </Typography>
        <TextField
          value={props.calendlyUrl}
          onChange={props.handleChangeCalendlyUrl}
          variant="outlined"
          placeholder={t('videoList.onBoardingModal.calendlyLinkPlaceholder')}
          fullWidth
          inputProps={{ maxLength: 100 }}
          error={props.calendlyUrlError}
        />
        {props.calendlyUrlError ? (
          <Typography variant="body2" style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
            {t('videoList.onBoardingModal.calendlyLinkInvalid')}
          </Typography>
        ) : null}
      </Box>
      <Box
        width="50%"
        height="100%"
        paddingLeft="16px"
        display={props.isMobile ? 'none' : 'flex'}
        alignItems="center"
        justifyContent="center"
      >
        <img src={CalendlyImg} />
      </Box>
    </>
  )
}

export default OnBoardingCalendly
