import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import { useContext, useEffect } from 'react'
import { AuthContext } from 'common/providers/AuthStatusProvider'
import { useParams } from 'react-router'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import useDeleteReaction from './useDeleteReaction'
import useCreateReaction from './useCreateReaction'
import useOnPostReactionSubscription from '../../../../../../../../common/layouts/AppLayout/common/hooks/subscriptions/useOnPostReactionSubscription'

/*
 * This hook is used to subscribe to new reactions
 * New reaction will be added automatically
 * To remove reaction we have to manually manipulate cache fragment
 */
const useReactionsSubscription = () => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const client = auth.isAuthenticated ? CognitoApolloClient : IamApolloClient
  const [addReaction] = useCreateReaction()
  const [deleteReaction] = useDeleteReaction()

  const { data: subscriptionData } = useOnPostReactionSubscription()

  /*
   * This function is used to add new reaction from partner to cache fragment when subscription is triggered
   */
  useEffect(() => {
    const addNewReaction = async () => {
      const data = subscriptionData?.data?.onPostReaction
      if (!data?.postId || data?.incrementBy !== 1 || !channelId || data.customerId === auth.user?.id) return
      // if (!data?.postId || data?.incrementBy !== 1 || !channelId) return
      await addReaction({
        postId: data?.postId || '',
        uniCode: data?.unicode,
        videoTimestamp: data?.videoTimestamp,
        onlyCache: true,
        customerId: data?.customerId,
        channelId,
        createdAt: data?.createdAt || new Date().toISOString(),
        updatedAt: data?.updatedAt || new Date().toISOString(),
      })
    }
    addNewReaction()
  }, [subscriptionData, auth.user?.id, channelId, client])

  /*
   * This function is used to remove partner reaction from cache fragment when subscription is triggered
   */
  useEffect(() => {
    const removeReaction = async () => {
      const data = subscriptionData?.data?.onPostReaction
      if (data?.incrementBy !== -1 || !data?.postId) return
      await deleteReaction({
        postId: data?.postId,
        unicode: data?.unicode,
        videoTimestamp: data?.videoTimestamp,
        onlyCache: true,
        customerId: data?.customerId,
      })
    }
    removeReaction()
  }, [subscriptionData, auth.user?.id, channelId, client])
}

export default useReactionsSubscription
