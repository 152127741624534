export const updatePost = /* GraphQL */ `
  mutation UpdatePost($input: UpdateScreensightPostInput!, $condition: ModelScreensightPostConditionInput) {
    updatePost(input: $input, condition: $condition) {
      channelId
      text {
        content
        richContent
        customerId
      }
      createdAt
      updatedAt
      id
    }
  }
`
