import React from 'react'

const EditThumbnailIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 1C3.60603 1 3.21593 1.05604 2.85195 1.16493C2.48797 1.27381 2.15726 1.43341 1.87868 1.6346C1.31607 2.04093 1 2.59203 1 3.16667V11.8333C1 12.408 1.31607 12.9591 1.87868 13.3654C2.15726 13.5666 2.48797 13.7262 2.85195 13.8351C3.21593 13.944 3.60603 14 4 14H16C16.7957 14 17.5587 13.7717 18.1213 13.3654C18.6839 12.9591 19 12.408 19 11.8333V3.16667C19 2.88214 18.9224 2.60039 18.7716 2.33752C18.6209 2.07465 18.3999 1.8358 18.1213 1.6346C17.8427 1.43341 17.512 1.27381 17.1481 1.16493C16.7841 1.05604 16.394 1 16 1H4ZM4 1.86667H16C16.4774 1.86667 16.9352 2.00363 17.2728 2.24743C17.6104 2.49123 17.8 2.82189 17.8 3.16667V10.4597L14.6383 7.60833C14.5304 7.51087 14.3947 7.43138 14.2404 7.37528C14.0861 7.31919 13.9168 7.28783 13.7441 7.28333C13.5697 7.27657 13.3953 7.2984 13.2342 7.34721C13.073 7.39602 12.9293 7.47053 12.8137 7.56517L11.1695 8.86517L8.46367 6.0612C8.35211 5.9466 8.20374 5.85324 8.03128 5.78912C7.85882 5.725 7.6674 5.69203 7.47344 5.69303C7.28165 5.69353 7.09282 5.72722 6.92277 5.79127C6.75272 5.85533 6.60641 5.94789 6.49609 6.0612L2.2 10.4817V3.16667C2.2 2.82189 2.38964 2.49123 2.72721 2.24743C3.06477 2.00363 3.52261 1.86667 4 1.86667ZM7.48047 6.5597L10.6 9.80547C10.6509 9.85884 10.7178 9.90327 10.7957 9.9355C10.8736 9.96772 10.9605 9.98692 11.05 9.99167C11.1408 9.99601 11.2317 9.98539 11.316 9.96061C11.4002 9.93583 11.4756 9.89754 11.5363 9.84863L13.6902 8.15L17.8 11.8333C17.8 12.1781 17.6104 12.5088 17.2728 12.7526C16.9352 12.9964 16.4774 13.1333 16 13.1333H4C3.55321 13.1351 3.12149 13.0167 2.7888 12.8013C2.45612 12.5859 2.24624 12.2889 2.2 11.9679L7.48047 6.5597Z"
        fill="#7A7A86"
        stroke="#7A7A86"
        strokeWidth="0.4"
      />
      <circle cx="14.5" cy="5.5" r="1.5" fill="#7A7A86" />
      <path
        d="M8 17.75C8 17.3358 8.33579 17 8.75 17H18.25C18.6642 17 19 17.3358 19 17.75C19 18.1642 18.6642 18.5 18.25 18.5H8.75C8.33579 18.5 8 18.1642 8 17.75Z"
        fill="#7A7A86"
      />
      <path
        d="M1.5 16.418C1.5 16.0463 1.89116 15.8046 2.22361 15.9708L4.98754 17.3528C5.35606 17.537 5.35606 18.0629 4.98754 18.2472L2.22361 19.6292C1.89116 19.7954 1.5 19.5536 1.5 19.182V16.418Z"
        stroke="#7A7A86"
      />
    </svg>
  )
}

export default EditThumbnailIcon
