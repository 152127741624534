import * as React from 'react'
import { Box } from '@mui/material'

const TimeZoneIcon = () => {
  return (
    <Box display="flex">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_15600_346128)">
          <path
            d="M10 18.3334C5.39752 18.3334 1.66669 14.6025 1.66669 10C1.66669 5.39752 5.39752 1.66669 10 1.66669C14.6025 1.66669 18.3334 5.39752 18.3334 10C18.3334 14.6025 14.6025 18.3334 10 18.3334ZM10 16.6667C11.7681 16.6667 13.4638 15.9643 14.7141 14.7141C15.9643 13.4638 16.6667 11.7681 16.6667 10C16.6667 8.23191 15.9643 6.53622 14.7141 5.28598C13.4638 4.03573 11.7681 3.33335 10 3.33335C8.23191 3.33335 6.53622 4.03573 5.28598 5.28598C4.03573 6.53622 3.33335 8.23191 3.33335 10C3.33335 11.7681 4.03573 13.4638 5.28598 14.7141C6.53622 15.9643 8.23191 16.6667 10 16.6667ZM10.8334 10H14.1667V11.6667H9.16669V5.83335H10.8334V10Z"
            fill="#171717"
          />
        </g>
        <defs>
          <clipPath id="clip0_15600_346128">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}
export default TimeZoneIcon
