import * as React from 'react'
import { Box, TextField, Typography, useMediaQuery } from '@mui/material'
import { Controller } from 'react-hook-form'
import Button from '../../../../components/_refactored/Button'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../../common/providers/MuiThemeProvider'
import ProfileRoundedImage from './ProfileRoundedImage'

interface IVideoItemMetadataProps {
  form: any
  photoUrl?: string
  email?: string | null
  createdAt?: string | null | undefined
}
export const EditTitleForm = (props: IVideoItemMetadataProps) => {
  const { t: g } = useTranslation('general')
  const { t } = useTranslation('library')

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <form onSubmit={props.form.submit} style={{ marginBottom: '16px', paddingTop: '16px' }}>
      <Box display={isMobile ? 'block' : 'flex'}>
        <Box display="flex" flexGrow={1} paddingRight="10px">
          <ProfileRoundedImage
            name={props.email || ''}
            width="43px"
            height="43px"
            photoUrl={props.photoUrl}
            isPrimaryColor
          />
          <Controller
            name={'title'}
            control={props.form.control}
            defaultValue=""
            rules={{
              validate: (value: string) =>
                value?.length > 100
                  ? g('common.maxTextInputLength')
                  : value?.trim()?.length
                  ? true
                  : g('common.requiredField'),
            }}
            render={({ value, onChange, onBlur }) => (
              <TextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!props.form.errors['title']}
                helperText={props.form.errors['title']?.message}
                disabled={!props.form.editState.edit}
                variant="outlined"
                style={{ width: '90%' }}
                InputProps={{
                  style: {
                    fontSize: '16px',
                  },
                }}
              />
            )}
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            marginTop: !!props.form.errors['title'] && isMobile ? '5px' : isMobile ? '15px' : 0,
            marginLeft: isMobile ? '50px' : 0,
          }}
        >
          {!props.form.editState.edit && !props.form.editState.update && (
            <Button variant="outlined" color="secondary" fullWidth onClick={props.form.enableEditing}>
              {g('common.editBtn')}
            </Button>
          )}
          {props.form.editState.edit && (
            <>
              <Box style={{ paddingRight: '4px', width: '100%' }}>
                <Button
                  disabled={!!Object.values(props.form.errors)?.length}
                  style={{ minWidth: 'fit-content', width: '100%' }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {g('common.saveBtn')}
                </Button>
              </Box>
              <Box style={{ paddingLeft: '4px', width: '100%' }}>
                <Button
                  fullWidth
                  variant="text"
                  color="secondary"
                  style={{ minWidth: 'fit-content' }}
                  onClick={props.form.disableEditing}
                >
                  {g('common.cancelBtn')}
                </Button>
              </Box>
            </>
          )}
          {props.form.editState.update && (
            <Button variant="outlined" color="secondary" fullWidth disabled>
              {t('videoItem.updatingBtn')}
            </Button>
          )}
        </Box>
      </Box>
      <Typography variant="body1" color="secondary" style={{ marginLeft: '65px' }}>
        {moment(props?.createdAt).format('MMMM DD, YYYY ')}
      </Typography>
    </form>
  )
}

export default EditTitleForm
