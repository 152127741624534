import * as React from 'react'
import { Box, useTheme } from '@mui/material'
import { screensightCustomer } from '../../../../../API'
import AccountDetails from './ui/partials/AccountDetails'
import { getUsername } from '../../../../../common/utils/getUsername'
import ResizableContainer from '../ResizableContainer'
import { useDispatch, useSelector } from 'react-redux'
import ArrowLeftOutline from '../../../../../common/icons/ArrowLeftOutline'
import { updateSidebarWidth } from '../../../common/redux/store/chat-actions'
import ArrowRightOutline from '../../../../../common/icons/ArrowRightOutline'
import Button from 'components/_refactored/Button'
import PartnerInfo from '../PartnerSidebar/ui/blocks/PartnerInfo'

type Props = {
  partner: screensightCustomer | null | undefined
}
const UserInfoSidebar = (props: Props) => {
  const sidebarParams = useSelector((state: any) => state.chat.sidebarParams)
  const dispatch = useDispatch()
  const theme = useTheme()
  const userName = getUsername(props.partner?.firstName, props.partner?.lastName, props.partner?.email)

  const calculatedWidth = sidebarParams.width !== 300 ? 300 : 44

  const calculatedX =
    /** stick panel to left (distance from the right browser border):
     * for extended panel: previous position - maximum width of the panel (300px) - current width of the panel
     * OR for minimized panel: browser width - 100px buffer **/
    sidebarParams.width !== 300 ? sidebarParams.x - (300 - sidebarParams.width) : window.innerWidth - 100

  return (
    <ResizableContainer>
      <Box
        width="100%"
        height="100vh"
        padding="18px"
        bgcolor={theme.palette.background.paper}
        style={{
          boxShadow: '-20px -1px 11px -19px rgb(0 0 0 / 10%)',
        }}
      >
        <Button
          className="arrow-button"
          style={{ boxShadow: 'none', minWidth: '8px', maxWidth: '8px', padding: '0px' }}
          onClick={() =>
            dispatch(updateSidebarWidth({ width: calculatedWidth, height: '100vh', x: calculatedX, y: 0 }))
          }
        >
          {sidebarParams.width !== 300 ? <ArrowLeftOutline /> : <ArrowRightOutline />}
        </Button>
        <Box display={sidebarParams.width > 115 ? 'block' : 'none'}>
          <PartnerInfo partner={props.partner} />
          <AccountDetails partner={props.partner} userName={userName} />
        </Box>
      </Box>
    </ResizableContainer>
  )
}
export default UserInfoSidebar
