import React from 'react'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Modal from '../../../../../../components/_refactored/Modal'
import useCreateBillingMethodConfirmation from '../../hooks/useCreateBillingMethodConfirmation'
import SuccessPaymentSvg from '../../../../../../common/assets/SuccessPayment.svg'
import Button from '../../../../../../components/_refactored/Button'
import { useTranslation } from 'react-i18next'

const Content = () => {
  const { billingSubscriptionData, billingPaymentMethodData, loading, error, redirectToBillingPage } =
    useCreateBillingMethodConfirmation()
  const { t } = useTranslation('settings')
  const { t: g } = useTranslation('general')

  return (
    <Box position="relative">
      <IconButton
        style={{
          position: 'absolute',
          left: '0',
          top: '0',
          boxShadow: 'none',
        }}
      >
        <ArrowBackIosIcon style={{ marginLeft: '6px' }} />
      </IconButton>
      <Typography
        variant="h2"
        align="center"
        style={{ marginTop: '100px', fontSize: '20px', fontWeight: 500, marginBottom: '60px' }}
      >
        {t('billing.confirmation.paymentProcessing')}
      </Typography>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        {loading && (
          <>
            <CircularProgress />
            <Typography variant="h6" align="center" style={{ marginTop: '30px', fontSize: '14px' }}>
              {t('billing.confirmation.pleaseWait')}
            </Typography>
          </>
        )}
      </Box>
      <Modal open={(!loading && !!billingSubscriptionData && !!billingPaymentMethodData) || (!loading && !!error)}>
        <Box textAlign={'center'}>
          {!error && (
            <>
              <img src={SuccessPaymentSvg} alt="Successful payment" style={{ marginBottom: '-30px' }} />

              <Typography
                align="center"
                variant="h3"
                color="textPrimary"
                style={{ fontWeight: 700, fontSize: '20px', marginTop: '16px' }}
              >
                {t('billing.confirmation.modalSuccessTitle')}
              </Typography>
              <Typography
                align="center"
                variant="h3"
                color="textPrimary"
                style={{ fontWeight: 700, fontSize: '20px', marginTop: '16px' }}
              >
                {t('billing.confirmation.modalSuccessSubtitle')}
              </Typography>
            </>
          )}

          {error && (
            <>
              <Typography
                align="center"
                variant="h3"
                color="textPrimary"
                style={{ fontWeight: 700, fontSize: '20px', marginTop: '16px' }}
              >
                {t('billing.confirmation.modalErrorTitle')}
              </Typography>

              <Typography
                align="center"
                variant="h3"
                color="textSecondary"
                style={{ fontSize: '14px', marginTop: '16px' }}
              >
                {error}
              </Typography>
            </>
          )}
          <Box mt={'30px'}>
            <Button color="primary" fullWidth variant="contained" onClick={redirectToBillingPage}>
              {g('common.okBtn')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Content
