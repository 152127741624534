import { useWindowSize } from 'react-use'
import { useContext, useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { ListVideoAudienceEngagementQuery, ListVideoAudienceEngagementQueryVariables } from '../../../../API'
import gql from 'graphql-tag'
import { listVideoAudienceEngagement } from '../../../../graphql/queries'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import { useMediaQuery, useTheme } from '@mui/material'
import { calculateTicks } from '../../../../common/utils/calculateTicks'
import { useTranslation } from 'react-i18next'

const colors = ['#2483F7', '#625FFF', '#1FB692']

const useVideoItemAnalyticsBarChartHook = (
  videoId: string | undefined,
  videoAccessId: string | undefined,
  duration: number | null | undefined,
  handleSectionPlay: (data: number[], domElWidth: number) => void,
) => {
  const auth = useContext(AuthContext)
  const size = useWindowSize()
  const parentDiv = document.getElementById('analytics__chart-wrapper')
  const update = useRef(false)
  const d3Chart = useRef(null)
  const yDistance = useRef(60)
  const theme = useTheme()
  const isTablet = useMediaQuery(`(max-width:1024px)`)
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const { t } = useTranslation('library')
  const [fullNameTooltip, setFullNameTooltip] = useState<{ text: string; left: number; top: number }>({
    text: '',
    left: 0,
    top: 0,
  })
  const [height, setHeight] = useState(0)

  const [analyticsData, setAnalyticsData] = useState<any>(null)
  // let height = 0
  useEffect(() => {
    if (!analyticsData) return
    let _tempHeight = 0
    analyticsData.forEach((mock: any) => {
      _tempHeight += mock.chartValues.length + mock?.segments?.length - 1
    })
    setHeight(yDistance.current * _tempHeight)
  }, [analyticsData, yDistance.current])

  const getPolygonPoints = (
    d: any,
    parentWidth: number,
    videoLength: number,
    width: number,
    i: number,
    nodeY: number,
  ) => {
    const x1 = parentWidth * 0.15 + (d.chartValues[0] / videoLength) * width - 2
    const y1 = nodeY - 2
    const x2 = parentWidth * 0.15 + (d.chartValues[0] / videoLength) * width - 2
    const y2 = nodeY + 4
    const x3 = parentWidth * 0.15 + (d.chartValues[0] / videoLength) * width + 3
    const y3 = 1 + nodeY
    const val = `${x1} ,${y1} ${x2}, ${y2} ${x3}, ${y3}`

    if (val.includes('NaN')) {
      return '0,0,0,0,0,0'
    }
    return val
  }

  const getConvertedCustomerName = (d: any) => {
    {
      const customerName = `${
        d?.customerMeta?.firstName || d?.customerMeta?.lastName
          ? `${d?.customerMeta?.firstName || ''} ${d?.customerMeta?.lastName || ''}`
          : d?.customerMeta?.email
      }`.trim()
      let customerNameResult = ''
      const tabletLength = 7
      const normalLength = 13
      const largeScreenLength = 17
      if (isTablet) {
        customerNameResult = customerName.substring(0, tabletLength)
        customerNameResult = customerName.length > tabletLength ? customerNameResult.concat('...') : customerNameResult
      } else if (isLargeScreen) {
        customerNameResult = customerName.substring(0, largeScreenLength)
        customerNameResult =
          customerName.length > largeScreenLength ? customerNameResult.concat('...') : customerNameResult
      } else {
        customerNameResult = customerName.substring(0, normalLength)
        customerNameResult = customerName.length > normalLength ? customerNameResult.concat('...') : customerNameResult
      }
      return { customerNameResult, customerName }
    }
  }

  const [_headOffset, setHeadOffset] = useState<any>([])

  useEffect(() => {
    if (!analyticsData) return
    let _offsetInc = 0
    const _headOffset: any[] = []
    analyticsData.forEach((d: any) => {
      const offset = _offsetInc
      _offsetInc += d.segments.length + 1
      _headOffset.push(offset * yDistance.current)
    })
    setHeadOffset(_headOffset)
  }, [analyticsData])

  const { data: videoStatisticData, loading: videoStatisticDataLoading } = useQuery<
    ListVideoAudienceEngagementQuery,
    ListVideoAudienceEngagementQueryVariables
  >(gql(listVideoAudienceEngagement), {
    variables: { videoId: videoId || '' },
    skip: !videoId || !auth.isAuthenticated,
    client: CognitoApolloClient,
  })

  useEffect(() => {
    if (!videoStatisticData?.listVideoAudienceEngagement) return
    setAnalyticsData(videoStatisticData.listVideoAudienceEngagement)
  }, [videoStatisticData?.listVideoAudienceEngagement])

  const showFullNameTooltip = (evt: any, d: any) => {
    const t = getConvertedCustomerName(d).customerNameResult
    if (t.includes('...')) {
      setFullNameTooltip({ text: getConvertedCustomerName(d).customerName, top: evt.pageY, left: evt.pageX + 10 })
    }
  }

  const hideFullNameTooltip = () => {
    setFullNameTooltip({ text: '', top: 0, left: 0 })
  }

  useEffect(() => {
    if (!parentDiv?.offsetWidth || !duration || !analyticsData) return
    if (update.current) {
      d3.selectAll('g').remove()
    } else {
      update.current = true
    }
    drawChart(parentDiv?.offsetWidth, duration)
  }, [parentDiv?.offsetWidth, size, duration, analyticsData, isTablet, isLargeScreen, _headOffset])

  const drawChart = (parentWidth: number, duration: number) => {
    const svg = d3.select(d3Chart.current)
    const width = parentWidth * 0.85 - 20

    const videoLength = duration
    const uiTicks = calculateTicks(0, videoLength, 5)
    const detailedTicks = calculateTicks(0, videoLength, 15)

    // white background
    svg
      .append('g')
      .attr('transform', `translate(0 100)`)
      .append('rect')
      .attr('width', width)
      .attr('height', `${height}px`)
      .attr('fill', 'white')
      .attr('x', parentWidth * 0.15)
      .selectAll('.background-bar')

    svg
      .append('g')
      .attr('transform', `translate(0 70)`)
      .append('rect')
      .attr('width', width)
      .attr('height', '1px')
      .attr('fill', '#7A7A86')
      .attr('x', () => `${parentWidth * 0.15}px`)

    // ticks
    svg
      .append('g')
      .selectAll('.background-legend-bar')
      .data([...detailedTicks, videoLength])
      .join('rect')
      .attr('width', '1px')
      .attr('height', (d: number, i: number) =>
        uiTicks.includes(d) || i === 0 || i === detailedTicks.length ? '7px' : '3px',
      )
      .attr('fill', '#7A7A86')
      .attr('y', (d: number, i: number) =>
        uiTicks.includes(d) || i === 0 || i === detailedTicks.length ? '63px' : '67px',
      )
      .attr('x', (d: number, i: number) =>
        i === detailedTicks.length
          ? `${parentWidth * 0.15 + (d / videoLength) * width - 1}px`
          : `${parentWidth * 0.15 + (d / videoLength) * width}px`,
      )

    svg
      .append('g')
      .selectAll('.background-legend-bar')
      .data([...detailedTicks, videoLength])
      .join('text')
      .attr('fill', '#7A7A86')
      .text((d: number) => moment.utc(d).format('mm:ss'))
      .attr('y', '60px')
      .attr('visibility', (d: number, i: number) =>
        uiTicks.includes(d) || i === 0 || i === detailedTicks.length ? 'visible' : 'hidden',
      )
      .attr('x', (d: any, i: number) => `${parentWidth * 0.15 + (d / videoLength) * width - 17}px`)

    // white background lines
    svg
      .append('g')
      .attr('transform', `translate(0 100)`)
      .selectAll('.background-legend-bar')
      .data(detailedTicks)
      .join('rect')
      .attr('width', '1px')
      .attr('height', `${height}px`)
      .attr('fill', '#ededed')
      .attr('visibility', (d: number, i: number) =>
        (i === detailedTicks.length - 1 && d === uiTicks[uiTicks.length - 1]) || i === 0 ? 'hidden' : 'visible',
      )
      .attr('x', (d: number) => `${parentWidth * 0.15 + (d / videoLength) * width}px`)

    const groups = svg
      .append('g')
      .attr('class', '.plot-area')
      .attr('transform', `translate(0 100)`)
      .selectAll('.bar')
      .data(analyticsData)
      .join('g')

    // left side white boxes
    groups
      .append('rect')
      .attr('x', '0px')
      .attr('y', (d: any, i: number) => `${_headOffset?.[i] || 0}px`)
      .attr('width', '13.5%')
      .attr('height', '50px')
      .attr('fill', 'white')
      .attr('class', 'viewer-box')
      .attr('rx', '4')

    // left side text
    groups
      .filter((d: any, i: number) => i >= 0)
      .append('text')
      .text((d: any) => getConvertedCustomerName(d).customerNameResult)
      .attr('x', '10px')
      .attr('y', (d: any, i: number) => `${30 + _headOffset?.[i] || 0}px`)
      .attr('fill', '#7A7A86')
      .attr('font-size', '12px')
      .on('mousemove', showFullNameTooltip)
      .on('mouseout', hideFullNameTooltip)

    // // left side nested circle
    groups
      .selectAll('circle')
      .data((d: any, headIndex: number) => {
        return d.segments.map((segment: any) => {
          return {
            ...segment,
            headIndex,
          }
        })
      })
      .join('circle')
      .attr('r', 4)
      .attr('transform', (d: any, i: number) => {
        return `translate(30 ${25 + _headOffset[d.headIndex] + (i + 1) * yDistance.current || 0})`
      })
      .attr('fill', (d: any, i: number) => colors[d.headIndex % colors.length])

    // // left side nested connector
    groups
      .selectAll('.connector-node')
      .data((d: any, headIndex: number) => {
        return d.segments.map((segment: any) => {
          return {
            ...segment,
            headIndex,
          }
        })
      })
      .join('rect')
      .attr('width', '1px')
      .attr('height', (d: any, i: number) => (i === analyticsData[d.headIndex].segments.length - 1 ? '35px' : '60px'))
      .attr(
        'transform',
        (d: any, i: number) => `translate(10 ${-10 + _headOffset[d.headIndex] + (i + 1) * yDistance.current || 0})`,
      )
      .attr('fill', '#BCBCC3')

    // // left side text
    groups
      .selectAll('.text-node')
      .data((d: any, headIndex: number) => {
        return d.segments.map((segment: any) => {
          return {
            ...segment,
            headIndex,
          }
        })
      })
      .join('text')
      .text((d: any, i: number) => moment(new Date(d.timestamp)).format('HH:mm'))
      .attr('x', '45px')
      .attr('y', (d: any, i: number) => `${30 + _headOffset[d.headIndex] + (i + 1) * yDistance.current || 0}px`)
      .attr('fill', '#7A7A86')
      .attr('font-size', '12px')

    // RIGHT SIDE header BAR
    groups
      .append('rect')
      .attr('x', (d: any, i: number) => `${parentWidth * 0.15 + (d.chartValues[0] / videoLength) * width}px`)
      .attr('y', (d: any, i: number) => `${15 + _headOffset?.[i] || 0}px`)
      .attr('width', (d: any, i: number) => `${((d.chartValues[1] - d.chartValues[0]) / videoLength) * width}px`)
      .attr('height', '20px')
      .attr('fill', (d: any, i: number) => colors[i % colors.length])
      .attr('rx', '6')

    groups
      .append('rect')
      .attr('x', (d: any, i: number) => `${parentWidth * 0.15 + 5 + (d.chartValues[0] / videoLength) * width}px`)
      .attr('y', (d: any, i: number) => `${17 + _headOffset?.[i] || 0}px`)
      .attr('width', '250px')
      .attr('height', '16px')
      .attr('fill', 'rgba(0,0,0,.15)')
      .attr('rx', '6')

    // RIGHT SIDE header text
    groups
      .append('text')
      .text(
        (d: any) =>
          `${t('videoStatistics.viewed')} ${moment(d.timestamp).format('MMMM DD, YYYY')} at ${moment(
            d.timestamp,
          ).format('hh:mm A')}`,
      )
      .attr('x', (d: any, i: number) => `${parentWidth * 0.15 + 10 + (d.chartValues[0] / videoLength) * width}px`)
      .attr('y', (d: any, i: number) => `${29 + _headOffset?.[i] || 0}px`)
      .attr('fill', 'white')
      .attr('font-size', '12px')

    // RIGHT SIDE nested BAR
    groups
      .selectAll('.nested-node-bar')
      .data((d: any, headIndex: number) => {
        return d.segments.map((segment: any) => {
          return {
            ...segment,
            headIndex,
          }
        })
      })
      .join('rect')
      .attr('x', (d: any, i: number) => `${parentWidth * 0.15 + (d?.chartValues?.[0] / videoLength) * width}px`)
      .attr('y', (d: any, i: number) => `${25 + _headOffset[d.headIndex] + (i + 1) * yDistance.current || 0}px`)
      .attr('width', (d: any, i: number) => `${((d?.chartValues?.[1] - d?.chartValues?.[0]) / videoLength) * width}px`)
      .attr('height', '2px')
      .attr('fill', (d: any, i: number) => colors[d.headIndex % colors.length])
      .attr('rx', '1')

    // right side nested circle
    const nestedBarCircles = groups
      .selectAll('.play-circle')
      .data((d: any, headIndex: number) => {
        return d.segments.map((segment: any) => {
          return {
            ...segment,
            headIndex,
          }
        })
      })
      .join('circle')
      .attr('cx', (d: any, i: number) => `${parentWidth * 0.15 + (d?.chartValues?.[0] / videoLength) * width}px`)
      .attr('cy', (d: any, i: number) => `${26 + _headOffset[d.headIndex] + (i + 1) * yDistance.current || 0}px`)
      .attr('fill', (d: any, i: number) => colors[d.headIndex % colors.length])
      .attr('r', '7')
      .attr('cursor', 'pointer')

    // right side nested play
    const plays = groups
      .selectAll('.play-polygon')
      .data((d: any, headIndex: number) => {
        return d.segments.map((segment: any) => {
          return {
            ...segment,
            headIndex,
          }
        })
      })
      .enter()
      .append('polygon')
      .attr('x', (d: any, i: number) => `${parentWidth * 0.15 + (d?.chartValues?.[0] / videoLength) * width}px`)
      .attr('y', (d: any, i: number) => `${25 + _headOffset[d.headIndex] + (i + 1) * yDistance.current}px`)
      .attr('fill', 'white')
      .attr('cursor', 'pointer')
      .attr('points', (d: any, i: number) =>
        getPolygonPoints(
          d,
          parentWidth,
          videoLength,
          width,
          i,
          25 + _headOffset[d.headIndex] + (i + 1) * yDistance.current,
        ),
      )

    nestedBarCircles.on('click', (a, b) => {
      if (!parentDiv?.offsetWidth) return
      // @ts-ignore
      handleSectionPlay(b.chartValues, parentDiv.offsetWidth * 0.85)
    })

    plays.on('click', (a, b) => {
      if (!parentDiv?.offsetWidth) return
      // @ts-ignore
      handleSectionPlay(b.chartValues, parentDiv.offsetWidth * 0.85)
    })
  }
  return {
    d3Chart,
    height,
    fullNameTooltip,
    videoStatisticDataLoading,
    videoStatisticData,
  }
}

export default useVideoItemAnalyticsBarChartHook
