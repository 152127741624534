import React from 'react'
import Row from '../components/Row'
import { screensightChannel } from '../../../../../../../API'
import { Box } from '@mui/material'
import useGroup from '../../common/hooks/useGroup'
import GroupHeader from '../components/GroupHeader'
import useDraftProvider from '../../../../../../../common/providers/DraftProvider/useDraftProvider'

interface IGroup {
  channels?: screensightChannel[] | null
  label: string
  unreadChannels: string[]
  type?: 'recent' | null
}

const Group = (props: IGroup) => {
  const state = useGroup()
  const { drafts } = useDraftProvider()

  return (
    <Box mb="15px">
      <GroupHeader
        toggleList={state.toggleList}
        label={props.label}
        channelsExpanded={state.expanded}
        customizationVisible={state.customizationVisible}
        toggleCustomizeRecent={state.toggleCustomizeRecent}
        type={props.type}
      />
      {state.expanded ? (
        <>
          {(props?.channels || [])?.map((channel: screensightChannel, key: number) => {
            return (
              <Row
                isScreenSightUser={channel.channelType === 'direct_message'}
                label={
                  channel.chatPartnerMeta?.firstName && channel.chatPartnerMeta.lastName
                    ? `${channel.chatPartnerMeta.firstName} ${channel.chatPartnerMeta.lastName}`
                    : channel.name || ''
                }
                channelId={channel?.id || ''}
                hasDraft={!!drafts[channel.id]}
                key={channel?.id || key}
                active={state.channelId === channel?.id}
                shouldNotify={props.unreadChannels.includes(channel?.id)}
                imgKey={channel.chatPartnerMeta?.profilePhoto?.key}
              />
            )
          })}
        </>
      ) : null}
    </Box>
  )
}

export default Group
