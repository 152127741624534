import React from 'react'

interface IScreenSightLock {
  style?: React.CSSProperties
}

const ScreenSightLock = (props: IScreenSightLock) => {
  return (
    <svg width="74" height="53" viewBox="0 0 74 53" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
      <path
        d="M23.8613 35.1499H13.2397C12.169 35.1499 11.1422 35.5755 10.3851 36.3331C9.62797 37.0907 9.20264 38.1183 9.20264 39.1897C9.20264 40.2611 9.62797 41.2887 10.3851 42.0463C11.1422 42.8039 12.169 43.2295 13.2397 43.2295H23.8664C24.9371 43.2335 25.9655 42.8117 26.7255 42.0569C27.4854 41.3022 27.9146 40.2762 27.9186 39.2048C27.9226 38.1334 27.5011 37.1043 26.7468 36.3438C25.9925 35.5834 24.9672 35.1539 23.8965 35.1499"
        fill="url(#paint0_linear_8110_205914)"
      />
      <path
        d="M23.8408 19L4.03962 19.0251C2.96825 19.0251 1.94075 19.451 1.18318 20.2091C0.425601 20.9672 0 21.9954 0 23.0675C0 24.1396 0.425601 25.1677 1.18318 25.9258C1.94075 26.6839 2.96825 27.1098 4.03962 27.1098L23.8358 27.0846C24.9072 27.0846 25.9347 26.6588 26.6922 25.9007C27.4498 25.1426 27.8754 24.1144 27.8754 23.0423C27.8754 21.9702 27.4498 20.9421 26.6922 20.184C25.9347 19.4259 24.9072 19 23.8358 19"
        fill="url(#paint1_linear_8110_205914)"
      />
      <path
        d="M54 0C45.6905 0 39 6.7542 39 15.1429V17.6667C36.2673 17.6667 34 19.9556 34 22.7143V47.9524C34 50.7111 36.2673 53 39 53H69C71.7327 53 74 50.7111 74 47.9524V22.7143C74 19.9556 71.7327 17.6667 69 17.6667V15.1429C69 6.7542 62.3095 0 54 0ZM54 5.04762C59.6905 5.04762 64 9.39819 64 15.1429V17.6667H44V15.1429C44 9.39819 48.3095 5.04762 54 5.04762ZM39 22.7143H69V47.9524H39V22.7143Z"
        fill="url(#paint2_linear_8110_205914)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8110_205914"
          x1="9.20515"
          y1="39.2174"
          x2="27.906"
          y2="39.2174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53B2F6" />
          <stop offset="1" stopColor="#469BF5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8110_205914"
          x1="5587.91"
          y1="3976.17"
          x2="7134.44"
          y2="3976.17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53B2F6" />
          <stop offset="1" stopColor="#469BF5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8110_205914"
          x1="34"
          y1="27.0638"
          x2="75.7143"
          y2="27.0638"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AA3F5" />
          <stop offset="1" stopColor="#2C6EF2" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ScreenSightLock
