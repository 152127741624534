import * as React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../../../components/_refactored/Modal'
import Button from '../../../../../../components/_refactored/Button'
import { theme } from '../../../../../providers/MuiThemeProvider'
import MediaRecorderHandler from '../../../../../providers/RecorderStateProvider/MediaRecorderHandler'
import MicrophoneDisabled from '../../../../../icons/MicrophoneDisabled'

export const MicrophonePermissionsErrorModal = () => {
  const { t: g } = useTranslation('general')
  return (
    <Modal open={true} boxStyle={{ width: '500px', maxWidth: '90vw' }}>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography textAlign="center" variant="h3">
          {g('recordControl.microphoneAccessErrorModal.title')}
        </Typography>
        <Typography
          textAlign="center"
          variant="body1"
          style={{ padding: '20px 0' }}
          color={theme.palette.secondary.main}
        >
          {g('recordControl.microphoneAccessErrorModal.subtitle')}
        </Typography>
        <MicrophoneDisabled />
        <Box paddingTop={'20px'}>
          <Button variant="contained" color="primary" onClick={() => MediaRecorderHandler.cancelRecording()}>
            {g('common.gotItBtn')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default MicrophonePermissionsErrorModal
