import Dexie from 'dexie'
import { IndexedRecordingEntity, IndexedRecordingChunk, IndexedRecordingNotifyComplete } from './index.types'

class IndexedDBProvider {
  db: Dexie
  recordingEntity: Dexie.Table<IndexedRecordingEntity, number>
  recordingChunk: Dexie.Table<IndexedRecordingChunk, number>
  recordingNotifyComplete: Dexie.Table<IndexedRecordingNotifyComplete, number>

  constructor() {
    this.db = new Dexie('UploadQueueDatabase')
    this.db.version(1).stores({
      RecordingEntity: '++id,localId,entityId',
      RecordingChunk: '++id,localId,entityId,key',
      RecordingNotifyComplete: '++id,localId,entityId',
    })

    this.recordingEntity = this.db.table('RecordingEntity')
    this.recordingChunk = this.db.table('RecordingChunk')
    this.recordingNotifyComplete = this.db.table('RecordingNotifyComplete')
  }
}

export default new IndexedDBProvider()
export * from './index.types'
