import * as React from 'react'
import Header from './ui/partials/Header'
import Modal from '../../../components/_refactored/Modal'
import ModalLibraryLayout from './ui/layouts/ModalLibraryLayout'
import ModalLibraryList from './ui/partials/ModalLibraryList'
import useModalLibraryList from './common/hooks/useModalLibraryList'

interface IModalLibraryProps {
  open: boolean
  onClose: () => void
  closeLibraryModal: () => void
}

const ModalLibrary = (props: IModalLibraryProps) => {
  const {
    register,
    onSubmit,
    searchValue,
    handleSearchPhraseChange,
    clearSearchPhrase,
    hasMore,
    getNextVideos,
    videoList,
    loading,
    searchPhrase,
    selectedVideoId,
    handleSelectVideo,
  } = useModalLibraryList(props.onClose)

  return (
    <Modal
      open={props.open}
      onClose={props.closeLibraryModal}
      style={{ zIndex: 1000000000 }}
      boxStyle={{
        width: '800px',
        maxWidth: '90%',
        height: '670px',
        maxHeight: '90%',
        overflowY: 'hidden',
        padding: '0',
      }}
    >
      <ModalLibraryLayout>
        <Header
          searchPhrase={searchValue}
          handleSearchPhraseChange={handleSearchPhraseChange}
          onClose={props.closeLibraryModal}
          onSubmit={onSubmit}
          clearSearchPhrase={clearSearchPhrase}
          selectedVideoId={selectedVideoId}
        />

        <ModalLibraryList
          getNextVideos={getNextVideos}
          videoList={videoList}
          register={register}
          hasMore={hasMore}
          loading={loading}
          searchPhrase={searchPhrase}
          handleSelectVideo={handleSelectVideo}
          selectedVideoId={selectedVideoId}
        />
      </ModalLibraryLayout>
    </Modal>
  )
}

export default ModalLibrary
