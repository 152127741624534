/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import { postFields } from './postFields'

export const onUpdateVideo = /* GraphQL */ `
  subscription OnUpdateVideo($channelId: ID, $id: ID, $customerId: ID) {
    onUpdateVideo(channelId: $channelId, id: $id, customerId: $customerId) {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
        accessLevel
        identityId
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
        accessLevel
        identityId
      }
      accessType
      accessId
      password
      width
      height
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
        count
      }
      commentsCount
      uploadedVideo {
        fileName
        videoContainer
        videoEncoding
      }
      transcriptionSummaryStatus
      transcriptionSummaryError
      transcriptionSummary
      transcriptionObject {
        key
        bucket
        accessLevel
        identityId
      }
      updatedAt
    }
  }
`
export const onDeleteVideo = /* GraphQL */ `
  subscription OnDeleteVideo {
    onDeleteVideo {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          timezone
        }
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
      }
      accessType
      accessId
      password
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
            }
            accessType
            accessId
            password
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            commentsCount
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      updatedAt
    }
  }
`
export const onPostEvent = /* GraphQL */ `
  subscription OnPostEvent($customerId: ID!) {
    onPostEvent(customerId: $customerId) {
      customerId
      channelId
      action
      post ${postFields}
      videosLimitReached
      __typename
    }
  }
`
