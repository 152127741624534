import React from 'react'

import useDeleteConfirmProvider from './useDeleteConfirmProvider'
import DeleteConfirmProviderView from './DeleteConfirmProviderView'

export type DeleteConfirmProviderProps = any

export default (props: any) => {
  const state = useDeleteConfirmProvider()
  return <DeleteConfirmProviderView {...state} {...props} />
}
