import { AccessType, S3FileAccessLevel, VideoStatus } from '../../../../../../../../API'

export const listFilesInChannel = /* GraphQL */ `
  query ListTypeOfPostInChannel(
    $postType: PostTypeFilter!
    $channelId: ID!
    $customerId: ID
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listTypeOfPostInChannel(
      postType: $postType
      channelId: $channelId
      customerId: $customerId
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelId
        customerId
        file {
          fileName
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          status
          customerId
          mimeType
          metadata
        }
        createdAt
      }
      nextToken
    }
  }
`

export type TFileInChannel = {
  __typename: 'screensightPost'
  id: string
  channelId?: string | null
  customerId?: string | null
  file?: {
    __typename: 'FilePost'
    fileName?: string | null
    fileObject?: {
      __typename: 'S3Object'
      key: string
      bucket: string
      accessLevel?: S3FileAccessLevel | null
      identityId?: string | null
    } | null
    status?: VideoStatus | null
    customerId?: string | null
    mimeType?: string | null
    metadata?: string | null
  } | null
  createdAt?: string | null
}

export type ListFilesInChannelQuery = {
  listTypeOfPostInChannel?: {
    __typename: 'ModelscreensightPostConnection'
    items: Array<TFileInChannel | null>
    nextToken?: string | null
  } | null
}
