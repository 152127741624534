import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import usePostActions from '../../../common/hooks/posts/usePostActions'
import ReactionBtn from './ReactionBtn'
import EditMessagePencilIcon from '../../../../../../../../common/icons/EditMessagePencilIcon'
import TrashIcon from '../../../../../../../../common/icons/TrashIcon'
import ReplyIconOutlined from '../../../../../../../../common/icons/ReplyIconOutlined'
import { FilePost, VideoStatus } from '../../../../../../../../API'
import './styles.scss'

interface IPostActionsProps {
  postId: string
  owner: boolean | undefined
  content: string | undefined
  richContent: string | undefined
  videoId: string | undefined
  postType?: string | null
  ownerName: string
  file?: FilePost | null | undefined
  videoCommentTimestamp: number
  referencePostAuthorId: string | undefined
  engagement: any
}

const PostActions = (props: IPostActionsProps) => {
  const theme = useTheme()
  const { anchorEl, handleClose, handleEdit, handleClick, handleDelete, handleReply, open } = usePostActions(
    props.postId,
  )
  const { t } = useTranslation('chat')
  if (!props.owner) {
    return (
      <Box display="flex" paddingTop="4px" justifyContent="center" alignItems="center" className="post__actions">
        <ReactionBtn videoId={props.videoId} postId={props.postId} location={'postActions'} />
        <IconButton
          style={{ boxShadow: 'none', background: 'none', padding: '0', width: '20px' }}
          onClick={() =>
            handleReply(
              props?.content,
              props.richContent,
              props.ownerName,
              props.videoCommentTimestamp,
              props.videoId || '',
              props?.file || '',
              props.referencePostAuthorId,
              props.engagement,
              props?.postType || '',
            )
          }
        >
          <ReplyIconOutlined />
        </IconButton>
      </Box>
    )
  }

  return (
    <Box display="flex" alignItems="center" paddingTop="5px">
      {props.file?.status !== VideoStatus.init && (
        <>
          <IconButton
            id="menu-button"
            aria-label="more"
            aria-controls={open ? 'long-menu demo-positioned-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            style={{
              boxShadow: 'none',
              background: 'none',
              paddingTop: '10px',
              width: '20px',
            }}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            className="post__action__menu"
            id="menu-button"
            anchorEl={anchorEl}
            open={open}
            componentsProps={{ backdrop: { style: { background: 'transparent' } } }}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
            <MenuItem
              onClick={() =>
                handleReply(
                  props.content,
                  props.richContent,
                  props.ownerName,
                  props.videoCommentTimestamp,
                  props.videoId || '',
                  props?.file || '',
                  props.referencePostAuthorId,
                  props.engagement,
                  props?.postType || '',
                )
              }
            >
              <ReplyIconOutlined color={theme.palette.text.primary} width={'18'} height={'16'} />
              <Typography variant="body2" marginLeft="10px">
                {t('chatBody.postActionsMenu.reply')}
              </Typography>
            </MenuItem>
            {!['file', 'video', 'shared', 'email_video'].includes(props?.postType || '') && (
              <MenuItem onClick={() => handleEdit(props.content, props.richContent)}>
                <EditMessagePencilIcon />
                <Typography variant="body2" marginLeft="10px">
                  {t('chatBody.postActionsMenu.edit')}
                </Typography>
              </MenuItem>
            )}
            {!['video', 'shared', 'email_video'].includes(props?.postType || '') && (
              <MenuItem onClick={async () => handleDelete(props?.file?.fileObject?.key)} sx={{ marginLeft: '3px' }}>
                <TrashIcon color={theme.palette.error.main} />
                <Typography className="delete-option" variant="body2" marginLeft="10px">
                  {t('chatBody.postActionsMenu.delete')}
                </Typography>
              </MenuItem>
            )}
          </Menu>
        </>
      )}
      {props.file?.status !== VideoStatus.init && (
        <ReactionBtn videoId={props.videoId} postId={props.postId} location={'postActions'} />
      )}
    </Box>
  )
}

export default PostActions
