import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'
import { useParams } from 'react-router'
import { useLazyQuery } from '@apollo/client'
import {
  ListTypeOfPostInChannelQueryVariables,
  ModelSortDirection,
  OnPostEventSubscription,
  PostTypeFilter,
} from '../../../../../../../API'
import gql from 'graphql-tag'
import CognitoApolloClient from '../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../common/clients/IamApolloClient'
import { listFilesInChannel, ListFilesInChannelQuery, TFileInChannel } from '../api/listFilesInChannel'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../common/redux/store/store'

const useSharedFiles = ({
  partnerId,
  handleSharedFilesLoaded,
}: {
  partnerId?: string | null
  handleSharedFilesLoaded: () => void
}) => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const [data, setData] = useState<TFileInChannel[]>([])
  const [nextToken, setNextToken] = useState<string | null | undefined>(null)
  const [componentInitialized, setComponentInitialized] = useState<boolean>(false)
  const [filesVisible, setFilesVisible] = useState<boolean>(true)
  const onPostEventSubscriptionData = useSelector((state: RootState) => state.chat.onPostEventSubscriptionData)

  const [getListOfFiles, { loading }] = useLazyQuery<ListFilesInChannelQuery, ListTypeOfPostInChannelQueryVariables>(
    gql(listFilesInChannel),
    {
      client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    },
  )

  const updateFiles = (data: OnPostEventSubscription) => {
    const postEvent = data?.onPostEvent
    if (!postEvent) return

    const { action, channelId: postEventChannelId, post } = postEvent
    const isSameChannel = postEventChannelId === channelId
    const isFilePost = post?.postType === 'file'
    const isOwner = post?.customerId === auth.user?.id

    if (isSameChannel && isFilePost) {
      if (action === 'delete') {
        setData((prev) => prev.filter((prevPost) => prevPost.id !== post.id))
        return
      }

      if (action === 'update' && !isOwner) {
        setData((prev) => [post as TFileInChannel, ...prev])
        return
      }
    }
  }

  useEffect(() => {
    if (!onPostEventSubscriptionData) return
    updateFiles(onPostEventSubscriptionData)
  }, [onPostEventSubscriptionData])

  useEffect(() => {
    const getData = async () => {
      if (auth.user?.pending || !channelId || !partnerId) return
      setComponentInitialized(false)
      const res = await getListOfFiles({
        variables: {
          channelId: channelId || '',
          sortDirection: ModelSortDirection.DESC,
          limit: 20,
          postType: PostTypeFilter.file,
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
      })
      setData(res.data?.listTypeOfPostInChannel?.items as TFileInChannel[])
      setNextToken(res.data?.listTypeOfPostInChannel?.nextToken)
      setComponentInitialized(true)
    }
    getData()
  }, [auth.user?.pending, channelId, auth.isAuthenticated, partnerId])

  useEffect(() => {
    if (!componentInitialized) return
    handleSharedFilesLoaded()
  }, [componentInitialized])

  useEffect(() => {
    setNextToken(null)
    setData([])
  }, [channelId])

  const loadNextFiles = async () => {
    if (!nextToken || !channelId) return
    const res = await getListOfFiles({
      variables: {
        limit: 10,
        channelId: channelId || '',
        sortDirection: ModelSortDirection.DESC,
        nextToken: nextToken,
        postType: PostTypeFilter.file,
      },
    })
    setData((prevState) => [...prevState, ...(res.data?.listTypeOfPostInChannel?.items as TFileInChannel[])])
    setNextToken(res.data?.listTypeOfPostInChannel?.nextToken)
  }

  const toggleFilesVisible = () => {
    setFilesVisible((prevState) => !prevState)
  }

  return {
    filesListLoading: loading,
    data,
    hasMore: !!nextToken,
    loadNextFiles,
    filesVisible,
    toggleFilesVisible,
  }
}

export default useSharedFiles
