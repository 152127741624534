import { useTranslation } from 'react-i18next'
import Modal from '../../../components/_refactored/Modal'
import { Box, Typography } from '@mui/material'
import ScreensightLogo from '../../../common/icons/ScreensightLogo'
import Button from '../../../components/_refactored/Button'
import React from 'react'

type TermsAndConditionsUpdateProps = {
  onNext: () => void
}

const TermsAndConditionsUpdate = ({ onNext }: TermsAndConditionsUpdateProps) => {
  const { t } = useTranslation('termsAndConditions')

  return (
    <Modal open={true} boxStyle={{ width: '600px' }}>
      <>
        <Box bgcolor="background.default">
          <Box>
            <Box width="100%" minHeight="calc(100% - 40px)">
              <Box marginBottom={'30px'}>
                <ScreensightLogo height={'30px'} />
              </Box>
              <Box marginBottom={'20px'}>
                <Typography variant={'h3'} style={{ fontWeight: 700 }}>
                  {t('updating.termsAndConditionsTitle')}
                </Typography>
              </Box>
              <Box marginBottom={'30px'}>
                <Typography paragraph color="textSecondary">
                  {t('updating.termsAndConditionsDescription')}
                </Typography>
              </Box>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button variant="contained" color="primary" onClick={onNext}>
                  {t('general.next')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    </Modal>
  )
}

export default TermsAndConditionsUpdate
