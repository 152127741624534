import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { DeletePostMutation, DeletePostMutationVariables } from '../../../../../../../../../API'
import CognitoApolloClient from '../../../../../../../../../common/clients/CognitoApolloClient'
import { deletePost } from './deletePost.mutation'
import IamApolloClient from '../../../../../../../../../common/clients/IamApolloClient'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../../common/providers/AuthStatusProvider'

const useDeletePost = (postId: string) => {
  const auth = useContext(AuthContext)
  const [deletePostMutation] = useMutation<DeletePostMutation, DeletePostMutationVariables>(gql(deletePost), {
    variables: { input: { id: postId } },
    client: !auth.user?.pending && auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
  })

  return deletePostMutation
}

export default useDeletePost
