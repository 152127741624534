import * as React from 'react'
import QuillEditor from '../components/ChatEditor/QuillEditor'
import EditorActionsBar from '../components/ChatEditor/EditorActionsBar'
import ResponseBox from '../components/ChatEditor/ResponseBox'
import useQuill from '../../common/hooks/quill/useQuill'
import sv from '../../../../../bal.png'
import '../../common/styles/postList.scss'
import { Box, IconButton } from '@mui/material'
import CollapseQuillIcon from '../../../../../../../common/icons/CollapseQuillIcon'
import ExpandQuillIcon from '../../../../../../../common/icons/ExpandQuillIcon'

interface IChatEditorProps {
  openNewVideoModal?: () => void
  openLibraryModal?: () => void
  handleChangeDefaultTitle?: (title?: string) => void
  videoPlayerEditor?: boolean | null
  toggleHeight?: () => void
  quillExpanded?: boolean
}

const ChatEditor = (props: IChatEditorProps) => {
  const state = useQuill({
    handleChangeDefaultTitle: props.handleChangeDefaultTitle,
    videoPlayerEditor: props?.videoPlayerEditor,
  })

  return (
    <>
      {props.videoPlayerEditor && (
        <Box position={'absolute'} right={60} top={10} marginBottom={'10px'}>
          <IconButton style={{ boxShadow: 'none', backgroundColor: 'transparent' }} onClick={props.toggleHeight}>
            {props.quillExpanded ? <CollapseQuillIcon /> : <ExpandQuillIcon />}
          </IconButton>
        </Box>
      )}
      <ResponseBox videoPlayerEditor={props?.videoPlayerEditor} />
      <QuillEditor videoPlayerEditor={props?.videoPlayerEditor} {...state} quillExpanded={props.quillExpanded} />
      <EditorActionsBar
        isVideoFromLinkLoading={state.isVideoFromLinkLoading}
        handleUpdate={state.handleUpdate}
        handleCancel={state.handleCancel}
        handleSubmit={state.handleSubmit}
        openNewVideoModal={props.openNewVideoModal}
        openLibraryModal={props.openLibraryModal}
        videoPlayerEditor={props?.videoPlayerEditor}
      />
      {state.balloonsVisible && <img src={sv} className="balloons-anim" alt="balloons" />}
    </>
  )
}

export default ChatEditor
