import React, { ChangeEvent, useContext } from 'react'
import { IPrompterText } from '../index'
import styled from 'styled-components'
import RecorderStateContext from '../../../../common/providers/RecorderStateProvider/RecorderStateContext'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  height: 100%;
  & .MuiInputBase-root:before,
  & .MuiInputBase-root:after {
    display: none;
  }

  & textarea::placeholder {
    color: white;
    width: 100%;
    height: 100%;
    line-height: 220px;
    text-align: center;
  }

  & textarea:focus::placeholder {
    color: transparent;
  }
`
interface IPrompterTextView extends IPrompterText {
  handleTextChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

const PrompterTextView = (props: IPrompterTextView) => {
  const { t: g } = useTranslation('general')
  const { state: recorderState } = useContext(RecorderStateContext)
  return (
    <Wrapper>
      <textarea
        value={props.editorState === 'TEXT' ? recorderState.prompter.text : `\n\n\n${recorderState.prompter.text}`}
        onChange={props.handleTextChange}
        ref={props.prompterRef}
        placeholder={g('recordControl.videoRecorderPreviewModal.textareaPlaceholder')}
        disabled={props.editorState !== 'TEXT' || recorderState.prompter.currentState === 'RECORDING'}
        style={{
          width: '100%',
          background: 'transparent',
          color: 'white',
          height: '100%',
          border: 'none',
          resize: 'none',
          outline: 'none',
          fontSize: `${recorderState.prompter.fontSize}px`,
        }}
      />
    </Wrapper>
  )
}

export default PrompterTextView
