import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import { Analytics } from 'aws-amplify'
import { v4 } from 'uuid'
import {
  GetApplicationConfigurationQuery,
  SetVideoViewedByMutation,
  SetVideoViewedByMutationVariables,
} from '../../../../API'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import build from '../../../../common/services/BuildNumber'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { setVideoViewedBy } from '../../../../graphql/mutations'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../common/clients/IamApolloClient'
import { VideoJsPlayer } from 'video.js'
import { useParams } from 'react-router'

interface IAnalyticsPayload {
  viewId: string
  segmentId: string
  videoId: string | undefined
  customerId: string
  eventTimestamp: string // iso8601
  platform: 'web' | 'ios' | 'android'
  appVersion: string
  state: 'start' | 'playing' | 'speed change' | 'scrubbing' | 'end'
  startTimePointMS: number
  endTimePointMS: number | null
  speed: number | null
}

const useVideoPlayerContainer = (
  videoId: string | undefined,
  appConfigData: GetApplicationConfigurationQuery | undefined,
  playerRef: MutableRefObject<VideoJsPlayer | null>,
  videoOwnerId: string | undefined,
) => {
  const auth = useContext(AuthContext)
  const { id: paramVideoId } = useParams() as { id: string }

  const startTimePointMS = useRef<number | null>(0)
  const endTimePointMS = useRef<number | null>(null)
  const isStarted = useRef(false)
  const viewId = useRef('')
  const segmentId = useRef('')

  const [visible, setVisible] = useState<boolean>(false)
  const backdropRef = useRef<MutableRefObject<any>>(null)

  const onLoadedMetadata = () => {
    setVisible(true)
  }

  const onPlayerReady = (player: VideoJsPlayer | null) => {
    playerRef.current = player
    player?.on('loadedmetadata', onLoadedMetadata)
  }

  const [setVideoViewedByMutation] = useMutation<SetVideoViewedByMutation, SetVideoViewedByMutationVariables>(
    gql(setVideoViewedBy),
    {
      fetchPolicy: 'no-cache',
      client: !auth.user?.pending && auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    },
  )

  useEffect(() => {
    viewId.current = v4()
    segmentId.current = v4()
  }, [])

  const handleStartAnalytics = async (userId?: string | null) => {
    startTimePointMS.current = 0
    endTimePointMS.current = null
    const o: IAnalyticsPayload = {
      viewId: viewId.current,
      videoId,
      appVersion: build,
      state: 'start',
      platform: 'web',
      speed: 1,
      eventTimestamp: new Date().toISOString(),
      segmentId: segmentId.current,
      startTimePointMS: 0,
      endTimePointMS: null,
      customerId: userId || '',
    }
    await setVideoViewedByMutation({
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          id: videoId || '',
          name: auth.user?.email || undefined,
          guestCustomerId: auth.isAuthenticated ? undefined : userId,
        },
      },
    })
    if (appConfigData?.getApplicationConfiguration?.videoPlayerEventsFirehose) {
      console.log('START OBJECT', o)
      await Analytics.record(
        {
          partitionKey: '', // TODO check with the backend
          data: o,
          streamName: appConfigData?.getApplicationConfiguration?.videoPlayerEventsFirehose,
        },
        'AWSKinesisFirehose',
      )
    }
  }

  const handlePlayingAnalytics = async (userId?: string | null) => {
    startTimePointMS.current = endTimePointMS.current || 0
    endTimePointMS.current = playerRef.current?.currentTime() || 0
    if (Math.abs(startTimePointMS.current - endTimePointMS.current) > 5) {
      segmentId.current = v4()
      const o: IAnalyticsPayload = {
        viewId: viewId.current,
        videoId,
        appVersion: build,
        state: 'scrubbing',
        platform: 'web',
        speed: null,
        eventTimestamp: new Date().toISOString(),
        segmentId: segmentId.current,
        startTimePointMS: startTimePointMS.current * 1000,
        endTimePointMS: endTimePointMS.current * 1000,
        customerId: userId || '',
      }
      if (appConfigData?.getApplicationConfiguration?.videoPlayerEventsFirehose) {
        await Analytics.record(
          {
            partitionKey: '',
            data: o,
            streamName: appConfigData?.getApplicationConfiguration?.videoPlayerEventsFirehose,
          },
          'AWSKinesisFirehose',
        )
      }
      return
    }
    const o: IAnalyticsPayload = {
      viewId: viewId.current,
      videoId,
      appVersion: build,
      state: 'playing',
      platform: 'web',
      speed: 1,
      eventTimestamp: new Date().toISOString(),
      segmentId: segmentId.current,
      startTimePointMS: startTimePointMS.current * 1000,
      endTimePointMS: endTimePointMS.current * 1000,
      customerId: userId || '',
    }
    if (appConfigData?.getApplicationConfiguration?.videoPlayerEventsFirehose) {
      await Analytics.record(
        {
          partitionKey: '',
          data: o,
          streamName: appConfigData?.getApplicationConfiguration?.videoPlayerEventsFirehose,
        },
        'AWSKinesisFirehose',
      )
    }
  }

  const handleEndAnalytics = async (userId?: string | null) => {
    startTimePointMS.current = endTimePointMS.current
    endTimePointMS.current = playerRef.current?.duration() || 0
    const o: IAnalyticsPayload = {
      viewId: viewId.current,
      videoId,
      appVersion: build,
      state: 'end',
      platform: 'web',
      speed: 1,
      eventTimestamp: new Date().toISOString(),
      segmentId: segmentId.current,
      startTimePointMS: (startTimePointMS.current || 0) * 1000,
      endTimePointMS: endTimePointMS.current * 1000,
      customerId: userId || '',
    }
    if (appConfigData?.getApplicationConfiguration?.videoPlayerEventsFirehose) {
      console.log('---END EVENT---', o)
      await Analytics.record(
        {
          partitionKey: '',
          data: o,
          streamName: appConfigData?.getApplicationConfiguration?.videoPlayerEventsFirehose,
        },
        'AWSKinesisFirehose',
      )
    }
  }

  useEffect(() => {
    const player = playerRef.current
    if (!player || auth.user?.id === videoOwnerId || !videoOwnerId) return
    let playInterval: number
    // console.log('auto', player.autoplay())
    // if (player.autoplay()) {
    //   player.requestFullscreen()
    // }

    player?.on('play', () => {
      if (!isStarted.current) {
        handleStartAnalytics(
          auth.user?.id || sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId'),
        )
      }
      isStarted.current = true
      playInterval = window.setInterval(() => {
        handlePlayingAnalytics(
          auth.user?.id || sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId'),
        )
      }, 1000)
    })
    player?.on('pause', () => {
      startTimePointMS.current = player.currentTime()
      if (playInterval) {
        window.clearInterval(playInterval)
      }
    })
    player?.on('ended', () => {
      if (playInterval) {
        handleEndAnalytics(
          auth.user?.id || sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId'),
        )
        window.clearInterval(playInterval)
      }
    })

    return () => {
      window.clearInterval(playInterval)
    }
  }, [playerRef, auth.user?.id, visible, paramVideoId])

  return {
    onPlayerReady,
    visible,
    backdropRef,
  }
}

export type UseVideoPlayerContainer = ReturnType<typeof useVideoPlayerContainer>

export default useVideoPlayerContainer
