import React, { useContext } from 'react'
import RecorderStateContext from '../../../../../providers/RecorderStateProvider/RecorderStateContext'
import MediaRecorderHandler from '../../../../../providers/RecorderStateProvider/MediaRecorderHandler'
import StartOverCleanIcon from '../../../../../icons/StartOverCleanIcon'
import Button from '../../../../../../components/_refactored/Button'
import { Box, Typography } from '@mui/material'
import Modal from '../../../../../../components/_refactored/Modal'
import { useTranslation } from 'react-i18next'

const RecordingStartOverModal = () => {
  const { state: recorderState } = useContext(RecorderStateContext)
  const { t: g } = useTranslation('general')

  return (
    <Modal open={true} boxStyle={{ width: '650px' }}>
      <>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          paddingTop="50px"
          paddingLeft="20px"
          paddingRight="20px"
        >
          <StartOverCleanIcon />
        </Box>
        <Box padding="20px 40px 50px 40px">
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 18,
              color: '#171717',
              flex: 1,
              textAlign: 'center',
              marginBottom: 10,
            }}
          >
            {g('recordControl.startOverModal.title')}
          </Typography>
          <Typography variant="body1" color="textSecondary" align="center" style={{ fontSize: '15px' }}>
            {g('recordControl.startOverModal.subtitle')}
          </Typography>
          <Box width="100%" display="flex" justifyContent="center" marginTop="15px">
            <Box
              width="43px"
              borderRadius="50px"
              height="43px"
              bgcolor="primary.main"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="body1" style={{ color: 'white', fontWeight: 500, fontSize: '18px' }}>
                {recorderState.startoverCountdown}
              </Typography>
            </Box>
          </Box>
          <Box paddingTop="45px" display="flex" justifyContent="center">
            <Button
              color="secondary"
              variant="outlined"
              style={{ width: '150px' }}
              onClick={() => MediaRecorderHandler.cancelRecording()}
            >
              {g('common.cancelBtn')}
            </Button>
            {recorderState.prompter.text && (
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: '20px', width: '150px' }}
                onClick={async () => {
                  await MediaRecorderHandler.cancelRecordingWithPrompter()
                }}
              >
                {g('recordControl.startOverModal.modifyScriptBtn')}
              </Button>
            )}
          </Box>
        </Box>
      </>
    </Modal>
  )
}

export default RecordingStartOverModal
