import React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import Logo from '../../../../../../common/icons/Logo'
import Icon from '../../../../../../common/icons'
import Edge from '../../../../../../common/icons/Edge.png'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation('auth')
  const { t: g } = useTranslation('general')

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems="center"
    >
      <Box
        paddingLeft={isMobile ? '0' : '33px'}
        paddingTop={isMobile ? '16px' : '33px'}
        display="flex"
        alignItems="center"
      >
        <Logo />
        <Box display="flex" alignItems="center" marginLeft="21px">
          <Typography variant="body2" color="textSecondary">
            {t('common.websiteRecommendation')}
          </Typography>
          <Icon.Chrome style={{ marginLeft: 5 }} />
          <img alt="Edge icon" src={Edge} style={{ marginLeft: '8px' }} />
        </Box>
      </Box>
      <Box
        paddingRight={isMobile ? '0' : '33px'}
        paddingTop={isMobile ? '12px' : '23px'}
        display="flex"
        alignItems="center"
      >
        <Typography variant="body2" color="textSecondary">
          {t('login.noAccount')}
        </Typography>
        <Typography
          variant="body2"
          color="primary"
          style={{ cursor: 'pointer', marginLeft: '8px' }}
          onClick={() => history.push('/sign-up')}
          data-testid="sign-up-button"
        >
          {g('common.signUpBtn')}
        </Typography>
      </Box>
    </Box>
  )
}

export default Header
