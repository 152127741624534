import * as React from 'react'
import { Box } from '@mui/material'

const CalendarIcon = () => {
  return (
    <Box display="flex">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_15600_346129)">
          <path
            d="M14 3.6H17.2C17.4122 3.6 17.6157 3.68429 17.7657 3.83431C17.9157 3.98434 18 4.18783 18 4.4V17.2C18 17.4122 17.9157 17.6157 17.7657 17.7657C17.6157 17.9157 17.4122 18 17.2 18H2.8C2.58783 18 2.38434 17.9157 2.23431 17.7657C2.08429 17.6157 2 17.4122 2 17.2V4.4C2 4.18783 2.08429 3.98434 2.23431 3.83431C2.38434 3.68429 2.58783 3.6 2.8 3.6H6V2H7.6V3.6H12.4V2H14V3.6ZM12.4 5.2H7.6V6.8H6V5.2H3.6V8.4H16.4V5.2H14V6.8H12.4V5.2ZM16.4 10H3.6V16.4H16.4V10Z"
            fill="#171717"
          />
        </g>
        <defs>
          <clipPath id="clip0_15600_346129">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}

export default CalendarIcon
