import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AuthContext } from '../../../../../common/providers/AuthStatusProvider'
import { useTranslation } from 'react-i18next'
import ToastContext from '../../../../../common/providers/ToastProvider/ToastContext'
import {
  CreateEmailInThreadsMutation,
  CreateEmailInThreadsMutationVariables,
  CreateEmailMessageMutation,
  CreateEmailMessageMutationVariables,
  screensightContact,
  SearchablescreensightContactSortableFields,
  SearchableSortDirection,
  SearchScreensightContactsQuery,
  SearchScreensightContactsQueryVariables,
} from '../../../../../API'
import { ReactMultiEmail } from 'react-multi-email'
import { useMediaQuery, useTheme } from '@mui/material'
import ReactQuill, { UnprivilegedEditor } from 'react-quill'
import debounce from 'lodash/debounce'
import { useLazyQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { searchScreensightContacts } from '../../../../../graphql/queries'
import CognitoApolloClient from '../../../../../common/clients/CognitoApolloClient'
import { createEmailInThreads, createEmailMessage } from '../../../../../graphql/mutations'
import { Sources } from 'quill'

const useEmailForm = (
  videoId: string | undefined,
  showMessageSentModal: () => void,
  hideSendByEmailModal: () => void,
  videoTitle?: string | null,
) => {
  const auth = useContext(AuthContext)
  const { t } = useTranslation('library')
  const toastContext = useContext(ToastContext)
  const [trackInThreads, setTrackInThreads] = useState(true)
  const [emails, setEmails] = useState<string[]>([])
  const [subject, setSubject] = useState<string>(videoTitle || '')
  const [note, setNote] = useState<string>('')
  const elem: HTMLInputElement | null = document.querySelector('.react-multi-email input')
  const [refetching, setRefetching] = useState(false)
  const [contactsToAdd, setContactsToAdd] = useState<string[]>([])
  const [sending, setSending] = useState(false)
  const [verifiedEmails, setVerifiedEmails] = useState<string[]>([])
  const [isVerifying, setIsVerifying] = useState(false)
  const [availableContacts, setAvailableContacts] = useState<screensightContact[] | null>([])
  const inputRef = useRef<ReactMultiEmail | null>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isError, setIsError] = useState(false)
  const quillRef = useRef<ReactQuill>(null)

  const handleKeyEvents = useCallback(
    (event: any) => {
      if (!availableContacts?.[0]) return
      handleNavigationToTheList(event, availableContacts[0])
    },
    [availableContacts],
  )

  useEffect(() => {
    if (!inputRef.current?.emailInputRef.current) return
    inputRef.current?.emailInputRef.current.focus()
  }, [inputRef.current])

  useEffect(() => {
    if (!inputRef.current?.emailInputRef.current) return
    inputRef.current?.emailInputRef.current.addEventListener('keyup', handleKeyEvents)

    return () => {
      inputRef.current?.emailInputRef.current?.removeEventListener('keyup', handleKeyEvents)
    }
  }, [inputRef.current, availableContacts])

  useEffect(() => {
    if (!elem) return
    elem.addEventListener('keydown', debounce(handleInputChange, 400))
    return () => {
      elem.removeEventListener('keydown', handleInputChange)
    }
  }, [elem, emails])

  const [fetchContactsQuery, { data: contactsQueryData, loading: contactsQueryLoading }] = useLazyQuery<
    SearchScreensightContactsQuery,
    SearchScreensightContactsQueryVariables
  >(gql(searchScreensightContacts), {
    client: CognitoApolloClient,
    variables: {
      sort: [
        {
          direction: SearchableSortDirection.asc,
          field: SearchablescreensightContactSortableFields.email,
        },
        {
          direction: SearchableSortDirection.asc,
          field: SearchablescreensightContactSortableFields.lastName,
        },
        {
          direction: SearchableSortDirection.asc,
          field: SearchablescreensightContactSortableFields.firstName,
        },
      ],
    },
  })

  const [verifyEmailQuery] = useLazyQuery<SearchScreensightContactsQuery, SearchScreensightContactsQueryVariables>(
    gql(searchScreensightContacts),
    {
      client: CognitoApolloClient,
    },
  )

  const [sendEmailMutation, { loading: sendEmailMutationLoading }] = useMutation<
    CreateEmailMessageMutation,
    CreateEmailMessageMutationVariables
  >(gql(createEmailMessage), {
    client: CognitoApolloClient,
  })

  const [createEmailInThreadsMutation] = useMutation<
    CreateEmailInThreadsMutation,
    CreateEmailInThreadsMutationVariables
  >(gql(createEmailInThreads), {
    client: CognitoApolloClient,
  })

  useEffect(() => {
    if (!contactsQueryData?.searchScreensightContacts?.items?.length) {
      setAvailableContacts([])
    } else {
      setAvailableContacts(contactsQueryData?.searchScreensightContacts?.items as screensightContact[])
    }
  }, [contactsQueryData, refetching])

  const handleSubject = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value)
  }

  const handleNote = (value: string, delta: any, source: Sources, editor: UnprivilegedEditor) => {
    if (quillRef?.current && editor.getText().length > 1000) {
      setIsError(true)
    } else {
      setIsError(false)
    }
    setNote(value)
  }

  const sendEmail = async () => {
    if (!videoId || !emails?.length || isError) return
    setSending(true)
    try {
      if (emails.length) {
        if (trackInThreads) {
          await createEmailInThreadsMutation({
            variables: {
              input: {
                videoId,
                customerId: auth.user?.id,
                to: emails,
                note,
                subject: subject || videoTitle,
              },
            },
          })
        } else {
          await sendEmailMutation({
            variables: {
              input: { videoId, customerId: auth.user?.id, to: emails, note, subject: subject || videoTitle },
            },
          })
        }
        showMessageSentModal()
        return
      }
    } catch (e: any) {
      console.error('Error during sending email')
      toastContext.open({ text: t('videoItem.common.sendEmailError'), type: 'ERROR' })
    } finally {
      setSending(false)
    }
  }

  const handleNavigationToTheList = (
    event: React.KeyboardEvent<HTMLInputElement>,
    firstContactFromList?: screensightContact,
  ) => {
    event.preventDefault()
    const firstContact = document.getElementById('available-emails-list-0')
    if (event.code === 'ArrowDown') {
      firstContact?.focus()
    } else if (event.code === 'Tab' && firstContactFromList) {
      let newEmails = [...emails]
      if (!newEmails.includes(firstContactFromList.email)) {
        newEmails.push(firstContactFromList.email)
      }
      setEmails(newEmails)
      setAvailableContacts([])
    }
  }

  const handleInputChange = async (event: any) => {
    if (['ArrowDown', 'ArrowUp', 'Enter', 'Tab'].includes(event.key)) return

    const val = elem?.value
    if (!val) return
    setRefetching(true)
    await fetchContactsQuery({
      variables: {
        filter: val
          ? {
              or: [
                {
                  email: { matchPhrasePrefix: val },
                },
                {
                  firstName: { matchPhrasePrefix: val },
                },
                {
                  lastName: { matchPhrasePrefix: val },
                },
              ],
              ...(emails?.length && { and: emails.map((_email) => ({ email: { ne: _email } })) }),
            }
          : undefined,
      },
    })
    setRefetching(false)
  }

  const verifyEmail = async (_emails: string[]) => {
    if (!auth.user?.id) return
    setEmails(_emails)

    const currentEmails = _emails.filter((email) => !verifiedEmails.includes(email))
    if (!currentEmails) return

    let tempContacts: string[] = []

    setIsVerifying(true)
    for await (const email of currentEmails) {
      try {
        const data = await verifyEmailQuery({
          variables: { filter: { email: { eq: email } }, limit: 1 },
        })
        if (!data.data?.searchScreensightContacts?.items?.length) {
          tempContacts.push(email)
        } else {
          setVerifiedEmails((prevState) => [...prevState, email])
        }
      } catch (e) {
        console.log('Error during verifying contacts', e)
      }
    }
    setIsVerifying(false)
    setContactsToAdd(tempContacts)
  }
  const toggleTrackInThreads = () => {
    setTrackInThreads((value) => !value)
  }

  const handleSetVerifiedEmails = (email: string) => {
    setVerifiedEmails((prevState) => [...prevState, email])
    setContactsToAdd((prevState) => {
      const _prevState = [...prevState]
      const index = prevState.indexOf(email)
      if (index > -1) {
        _prevState.splice(index, 1)
        return _prevState
      }
      return prevState
    })
  }

  const quillModules = useMemo(() => {
    return {
      toolbar: [],
    }
  }, [])

  return {
    emails,
    setEmails,
    note,
    handleNote,
    sendEmail,
    handleSubject,
    inputRef,
    subject,
    availableContacts,
    setAvailableContacts,
    trackInThreads,
    toggleTrackInThreads,
    searchPhrase: inputRef?.current?.state?.inputValue || '',
    hideSendByEmailModal,
    loading: contactsQueryLoading || refetching,
    currentContactToAdd: verifiedEmails?.length
      ? contactsToAdd.find((email) => !verifiedEmails?.includes(email))
      : contactsToAdd?.[0],
    sending: sendEmailMutationLoading || sending,
    verifyEmail,
    isVerifying,
    isMobile,
    handleSetVerifiedEmails,
    quillModules,
    quillRef,
    isError,
  }
}

export type UseEmailForm = ReturnType<typeof useEmailForm>

export default useEmailForm
