import i18next from 'i18next'
import { useEffect } from 'react'
import moment from 'moment/moment'

const getLanguage = () => window.localStorage.i18nextLng || i18next.language

const useLoadMomentLocale = () => {
  useEffect(() => {
    const loadMoment = async () => {
      // @ts-ignore
      import(`moment/min/locales`).then(() => {
        moment.locale(getLanguage())
      })
    }
    loadMoment()
  }, [])
}

export default useLoadMomentLocale
