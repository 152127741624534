import React, { PropsWithChildren } from 'react'
import useProfileHook from './hooks/useProfile.hook'

import ProfileView from './view/Profile.view'
import PageTitle from '../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

export type ProfileProps = PropsWithChildren<any>

export default (props: ProfileProps) => {
  const state = useProfileHook(props)
  const { t: g } = useTranslation('general')

  return (
    <>
      <PageTitle name={g('pageName.profileSettings')} />
      <ProfileView {...state} {...props} />
    </>
  )
}
