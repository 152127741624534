const urlify = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text.replace(urlRegex, (url) => url)
}

const getVideoUrl = (text: string, env: string) => {
  const longUrl = `app.${env}screensight.com/#/video/`
  const shortUrl = `${env}ssight.co/`
  if (text.includes(shortUrl) || text.includes(longUrl)) {
    const urlsArr = urlify(text)
      .split(' ')
      ?.filter((t: string) => t.includes(shortUrl) || t.includes(longUrl))
    if (urlsArr?.length === 1) {
      return urlsArr[0].substring(urlsArr[0].lastIndexOf('/') + 1) || ''
    }
  }
  return ''
}

export const isScreenSightVideoLink: (text?: string | null) => string = (text?: string | null) => {
  if (!text) return ''
  switch (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
    case 'dev':
    case 'localhost': {
      return getVideoUrl(text, 'dev.')
    }
    case 'qa': {
      return getVideoUrl(text, 'qa.')
    }
    case 'prod': {
      return getVideoUrl(text, '')
    }

    default:
      return ''
  }
}
