import * as React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getUsername } from '../../../../../../../../common/utils/getUsername'
import { theme } from '../../../../../../../../common/providers/MuiThemeProvider'
import { screensightCustomer } from '../../../../../../../../API'
import useReadReceipt from '../../../common/hooks/useReadReceipt'

interface IReadReceiptProps {
  lastSeen: boolean | undefined
  lastUnseen: boolean | undefined
  seenAt?: string | null
  partnerData: screensightCustomer | undefined | null
  owner?: boolean
  edited?: boolean
}
const ReadReceipt = (props: IReadReceiptProps) => {
  const { t } = useTranslation('chat')
  const state = useReadReceipt(props.seenAt)

  return (
    <Box
      display={'flex'}
      width="100%"
      marginTop={props.edited || props.lastSeen || props.lastUnseen ? '6px' : '0px'}
      justifyContent={props.owner ? 'end' : 'start'}
      alignItems="center"
    >
      {props.edited && (
        <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
          {t('chatBody.postEdited')}
        </Typography>
      )}
      {props.lastSeen && !props.partnerData?.id?.includes('anon#') && (
        <Tooltip title={state.timestamp} open={state.open} onOpen={state.handleOpen} onClose={state.handleClose} arrow>
          <Typography
            marginLeft="6px"
            variant="body1"
            align="right"
            style={{
              fontSize: '12px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              color: theme.palette.secondary.main,
            }}
          >
            {t('chatBody.postReadByPrefix')}{' '}
            <strong>
              {getUsername(props.partnerData?.firstName, props.partnerData?.lastName, props.partnerData?.email)}
            </strong>
          </Typography>
        </Tooltip>
      )}
      {props.lastUnseen && !props.partnerData?.id?.includes('anon#') && (
        <Typography marginLeft="6px" style={{ fontSize: '12px', color: theme.palette.secondary.main }}>
          {t('chatBody.postUnread')}
        </Typography>
      )}
    </Box>
  )
}

export default ReadReceipt
