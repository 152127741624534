import React from 'react'
import useVideoNewModalHook from './hooks/useVideoNewModal.hook'
import VideoNewModalView from './view/VideoNewModal.view'

export interface VideoNewModalProps {
  open: boolean
  onClose: () => void
  channelId?: string
  openLibraryModal?: () => void
  defaultTitle?: string
}

export default (props: VideoNewModalProps) => {
  const state = useVideoNewModalHook(props)
  return <VideoNewModalView {...state} {...props} />
}
