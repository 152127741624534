import React from 'react'
import { Box, BoxProps } from '@mui/material'

const AuthIconMail = (props: BoxProps) => {
  return (
    <Box {...props} className={`${props.className}`}>
      <svg width="92" height="47" viewBox="0 0 92 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M36.9287 27.2732C35.7968 27.2732 33.5716 26.3215 33.5716 25.1474V11.2239C33.5716 5 38 0 44.4238 0H80.4935C87 0 91.3145 4 91.3145 11.2239V35.7761C91.3145 41.8227 87 47 80.4935 47H44.4238C38.943 47 33.5716 44.9857 33.5716 35.7761C33.5716 34.6021 35.7968 33.6504 36.9287 33.6504C38.0606 33.6504 40.3249 34.2386 40.3249 35.7761C40.2859 38.2714 42.5907 40.0276 44.4238 40.0276H80.4935C82.7536 40.0276 84.5924 38.1204 84.5924 35.7761V14.3953L66.7875 25.879C65.4524 26.74 63.9555 27.1706 62.4586 27.1706C60.9617 27.1706 59.4649 26.74 58.1298 25.879L40.3249 14.3953V25.1474C40.3249 26.3215 38.0606 27.2732 36.9287 27.2732ZM60.2942 19.5476C61.6292 20.4086 63.288 20.4088 64.623 19.5476L82.8822 7.77084C82.2092 7.26853 81.3841 6.97241 80.4935 6.97241H44.4238C43.5332 6.97241 42.7081 7.26863 42.0351 7.77094L60.2942 19.5476Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M23.8618 28.2351H13.2402C12.1695 28.2351 11.1427 28.6607 10.3856 29.4183C9.62846 30.176 9.20312 31.2035 9.20312 32.2749C9.20312 33.3463 9.62846 34.3739 10.3856 35.1315C11.1427 35.8891 12.1695 36.3147 13.2402 36.3147H23.8669C24.9376 36.3187 25.966 35.8969 26.7259 35.1422C27.4859 34.3874 27.9151 33.3614 27.9191 32.29C27.923 31.2186 27.5015 30.1895 26.7473 29.429C25.993 28.6686 24.9677 28.2391 23.897 28.2351"
          fill="url(#paint1_linear)"
        />
        <path
          d="M23.8408 12.0857L4.03962 12.1108C2.96825 12.1108 1.94075 12.5367 1.18318 13.2948C0.425601 14.0529 0 15.0811 0 16.1532C0 17.2252 0.425601 18.2534 1.18318 19.0115C1.94075 19.7696 2.96825 20.1955 4.03962 20.1955L23.8358 20.1703C24.9072 20.1703 25.9347 19.7444 26.6922 18.9864C27.4498 18.2283 27.8754 17.2001 27.8754 16.128C27.8754 15.0559 27.4498 14.0277 26.6922 13.2697C25.9347 12.5116 24.9072 12.0857 23.8358 12.0857"
          fill="url(#paint2_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="34.9145"
            y1="23.5"
            x2="90.0028"
            y2="23.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4AA3F5" />
            <stop offset="1" stopColor="#2C6EF2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="9.20564"
            y1="32.3026"
            x2="27.9065"
            y2="32.3026"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#53B2F6" />
            <stop offset="1" stopColor="#469BF5" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="5587.91"
            y1="3969.26"
            x2="7134.44"
            y2="3969.26"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#53B2F6" />
            <stop offset="1" stopColor="#469BF5" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  )
}

export default AuthIconMail
