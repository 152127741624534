import React from 'react'
import { Box } from '@mui/material'

const IconPermissionsCamera = () => {
  return (
    <Box display="flex">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
        <path
          d="M2 0.199997C1.116 0.199997 0.400002 0.915997 0.400002 1.8V13C0.400002 13.884 1.116 14.6 2 14.6H18C18.884 14.6 19.6 13.884 19.6 13V1.8C19.6 0.915997 18.884 0.199997 18 0.199997H2ZM2 1.8H18V11.4H2V1.8ZM13.9844 3.39218C13.7765 3.39838 13.5793 3.48524 13.4344 3.63437L9.2 7.86875L7.36563 6.03437C7.29191 5.95759 7.20362 5.89629 7.10591 5.85407C7.00821 5.81184 6.90306 5.78953 6.79663 5.78844C6.6902 5.78736 6.58462 5.80753 6.48607 5.84776C6.38753 5.88799 6.29801 5.94748 6.22274 6.02274C6.14748 6.098 6.08799 6.18753 6.04776 6.28607C6.00753 6.38461 5.98737 6.49019 5.98845 6.59663C5.98953 6.70306 6.01184 6.80821 6.05407 6.90591C6.0963 7.00361 6.1576 7.09191 6.23438 7.16562L8.63438 9.56562C8.78441 9.7156 8.98786 9.79984 9.2 9.79984C9.41214 9.79984 9.61559 9.7156 9.76563 9.56562L14.5656 4.76562C14.6812 4.65325 14.7602 4.50858 14.7922 4.35056C14.8241 4.19255 14.8076 4.02856 14.7448 3.88009C14.6819 3.73162 14.5757 3.6056 14.44 3.51854C14.3043 3.43147 14.1455 3.38743 13.9844 3.39218ZM10 12.2C10.4416 12.2 10.8 12.5584 10.8 13C10.8 13.4416 10.4416 13.8 10 13.8C9.5584 13.8 9.2 13.4416 9.2 13C9.2 12.5584 9.5584 12.2 10 12.2ZM6 16.2C5.89399 16.1985 5.78874 16.2181 5.69037 16.2576C5.59199 16.2971 5.50246 16.3558 5.42696 16.4303C5.35146 16.5047 5.29152 16.5934 5.2506 16.6912C5.20968 16.789 5.18861 16.894 5.18861 17C5.18861 17.106 5.20968 17.211 5.2506 17.3088C5.29152 17.4066 5.35146 17.4953 5.42696 17.5697C5.50246 17.6442 5.59199 17.7029 5.69037 17.7424C5.78874 17.7819 5.89399 17.8015 6 17.8H14C14.106 17.8015 14.2113 17.7819 14.3096 17.7424C14.408 17.7029 14.4975 17.6442 14.573 17.5697C14.6485 17.4953 14.7085 17.4066 14.7494 17.3088C14.7903 17.211 14.8114 17.106 14.8114 17C14.8114 16.894 14.7903 16.789 14.7494 16.6912C14.7085 16.5934 14.6485 16.5047 14.573 16.4303C14.4975 16.3558 14.408 16.2971 14.3096 16.2576C14.2113 16.2181 14.106 16.1985 14 16.2H6Z"
          fill="#2483F7"
        />
      </svg>
    </Box>
  )
}

export default IconPermissionsCamera
