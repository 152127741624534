import { Dispatch } from 'react'
import { Auth } from 'aws-amplify'
import moment from 'moment'
import { v4 } from 'uuid'
import NewQueueHandler from 'common/services/UploadQueue'
import { IMediaSource, IStartRecord, IVideoParams } from './MediaRecorderHandler.types'
import { RecorderStateDispatch } from '../useRecorderStateProvider'
import { ErrorOpen } from 'common/providers/ErrorProvider/useErrorProvider'

const maxVideoLength = 2 * 60 * 60 * 1000 // 2h = 2 * (60 * (60 * 1000ms)min)h
const audio = new Audio(`${process.env.PUBLIC_URL}/count-down.mp3`)

export class MediaRecorderHandler {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: Dispatch<RecorderStateDispatch> = () => {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showError = (error: ErrorOpen) => {}

  videoParams?: IVideoParams

  camera: IMediaSource = { chunkCounter: 0, stopped: false }
  screen: IMediaSource = { chunkCounter: 0, stopped: false }
  preRecordingCounter = 0
  cancelInitRecording = false
  preRecordingInterval?: number

  durationInterval?: number

  prompterText: string | undefined = ''

  setDispatch(dispatch: Dispatch<RecorderStateDispatch>) {
    this.dispatch = dispatch
  }

  setErrorOpen(error: (error: ErrorOpen) => void) {
    this.showError = error
  }

  durationIntervalHandler() {
    // Check if video has params and started
    if (this.videoParams && this.videoParams.startTimestamp) {
      // calculate duration by comparing timestamp from date.now and video startTimestamp
      const videoLength = Date.now() - this.videoParams.startTimestamp

      if (videoLength > maxVideoLength) {
        this.stopMediaRecorderHandler()
      }

      this.dispatch({ action: 'DURATION', value: videoLength })
    }
  }

  setDurationInterval(durationIntervalHandlerFn: () => void) {
    this.clearDurationInterval()

    // @ts-ignore
    this.durationInterval = setInterval(durationIntervalHandlerFn, 1000)
  }

  clearDurationInterval() {
    if (this.durationInterval) clearInterval(this.durationInterval)
  }

  preRecordingIntervalHandler() {
    if (--this.preRecordingCounter > 0) {
      const { startover = false } = this.videoParams || {}
      this.dispatch({
        action: startover ? 'STARTOVER_PROGRESS' : 'COUNTDOWN_PROGRESS',
        value: this.preRecordingCounter,
      })
    } else {
      this.clearPreRecordingInterval()
      this.dispatch({ action: 'START_RECORD', value: this.videoParams?.channelId })
      setTimeout(() => {
        this.startRecording()
      }, 250)
    }
  }

  async setPreRecordingInterval(preRecordingIntervalHandlerFn: () => void) {
    this.clearPreRecordingInterval()
    this.preRecordingCounter = 5

    try {
      setTimeout(async () => {
        if (!this.cancelInitRecording) await audio.play()
      }, 1000)
    } catch (e: any) {
      console.error('Error when playing the countdown audio: ', e)
    }

    // @ts-ignore
    this.preRecordingInterval = setInterval(preRecordingIntervalHandlerFn, 1000)
  }

  clearPreRecordingInterval() {
    audio.pause()
    audio.currentTime = 0
    if (this.preRecordingInterval) clearInterval(this.preRecordingInterval)
  }

  getSupportedType(onlyAudio = false) {
    const types = onlyAudio
      ? ['audio/webm;codecs=opus', 'audio/webm']
      : [
          'video/webm;codecs=vp9',
          'video/webm;codecs=vp8',
          'video/webm',
          'video/webm;codecs=daala',
          'video/webm;codecs=h264',
          'video/mpeg',
        ]

    for (const type of types) {
      if (MediaRecorder.isTypeSupported(type)) {
        return type
      }
    }
  }

  async getCameraStream(constraints: MediaStreamConstraints, onlyAudio: boolean, hasPrompter?: boolean) {
    if (onlyAudio) {
      try {
        this.camera.stream = await navigator.mediaDevices.getUserMedia(constraints)
        // audio & video setting
        this.camera.onlyAudio = onlyAudio
      } catch (e: any) {
        throw new Error('Microphone permission denied by system')
      }
    } else {
      try {
        this.screen.stream = await navigator.mediaDevices.getUserMedia(constraints)
        // audio & video setting
        this.screen.onlyAudio = false

        // set stream for UI preview
        this.dispatch({ action: 'SET_CAMERA_STREAM', value: this.screen.stream })
      } catch (e: any) {
        throw new Error('Camera permission denied by system')
      }
    }
  }

  async getScreenStream() {
    //set that the permission window is open
    try {
      this.screen.stream = (await (navigator.mediaDevices as any).getDisplayMedia()) as MediaStream

      this.screen.onlyAudio = false
    } catch (e: any) {
      if (e.message === 'Permission denied by system') {
        throw new Error('Screen recording permission denied by system')
      }
      this.cancelRecording()
      return
    }
  }

  async setupMediaStreams(type: string) {
    if (type === 'CAM') {
      await this.getCameraStream(
        {
          video: false,
          audio: true,
        },
        true,
      )
      await this.getCameraStream(
        {
          video: { width: { min: 1280 }, height: { min: 720 } },
          audio: false,
        },
        false,
      )
    } else {
      await this.getCameraStream(
        {
          video: false,
          audio: true,
        },
        true,
      )

      await this.getScreenStream()
    }
  }

  async setupPrompter(title: string, channelId?: string | null) {
    await this.setupMediaStreams('CAM')
    this.dispatch({ action: 'UPDATE_PROMPTER_STATE', value: { currentState: 'SETUP', title, channelId } })
  }

  sendScreenPermissionDeniedBySystemError() {
    this.dispatch({ action: 'SET_SCREEN_PERMISSION_ERROR' })
  }

  sendCameraPermissionDeniedBySystemError() {
    this.dispatch({ action: 'SET_CAMERA_PERMISSION-ERROR' })
  }
  sendMicrophonePermissionDeniedBySystemError() {
    this.dispatch({ action: 'SET_MICROPHONE_PERMISSION-ERROR' })
  }

  async initRecording(params: IStartRecord) {
    this.cancelInitRecording = false
    try {
      const user = await Auth.currentAuthenticatedUser()
      await this.stopMediaRecorderHandler(true)
      this.videoParams = {
        ...params,
        uuid: v4(),
        finished: false,
        date: moment().format('YYYY-MM-DDTHHmmss.SSS[Z]'),
        username: user.username,
        startover: params.startover || false,
      }

      this.preRecordingCounter = 5
      this.prompterText = this.videoParams.prompterText

      this.dispatch({
        action: this.videoParams.startover ? 'STARTOVER_PROGRESS' : 'COUNTDOWN_PROGRESS',
        value: this.preRecordingCounter,
      })

      // Start capturing...
      this.camera = { chunkCounter: 0, stopped: false }
      this.screen = { chunkCounter: 0, stopped: false }

      // setup media streams
      await this.setupMediaStreams(params.type)

      if (this.cancelInitRecording) {
        this.stopMediaRecorderHandler()
        return
      }
      // Start countdown
      await this.setPreRecordingInterval(this.preRecordingIntervalHandler.bind(this))
    } catch (e: any) {
      console.log('MediaStream error: ', e.message)
      switch (e.message) {
        case 'Camera permission denied by system':
          this.sendCameraPermissionDeniedBySystemError()
          break
        case 'Microphone permission denied by system':
          this.sendMicrophonePermissionDeniedBySystemError()
          break
        case 'Screen recording permission denied by system':
          this.sendScreenPermissionDeniedBySystemError()
          break
        default:
          break
      }
      return
    }
  }

  async onChunkAvailable(event: BlobEvent, type: 'AUDIO' | 'SCREEN', chunkCounter: number) {
    if (event.data && event.data.size > 0) {
      await this.addChunkToQueueHandler(event.data, type, chunkCounter)
    }
  }

  onRecorderStop(mediaSource: IMediaSource) {
    mediaSource.stopped = true
    this.stopMediaRecorderHandler(true)
    this.triggerQueueHandlerRecordingEnd()
  }

  startRecordingOnMediaSource(mediaSource: IMediaSource, type: 'AUDIO' | 'SCREEN') {
    if (!mediaSource.stream) return

    const options: MediaRecorderOptions = {
      mimeType: this.getSupportedType(mediaSource.onlyAudio),
    }

    // prepare recorder
    mediaSource.recorder = new MediaRecorder(mediaSource.stream, options)

    // on data chunk
    mediaSource.recorder.ondataavailable = async (event) =>
      this.onChunkAvailable(event, type, mediaSource.chunkCounter++)

    // on stop recorder
    mediaSource.recorder.onstop = (ev) => this.onRecorderStop(mediaSource)

    mediaSource.recorder.start(10000)
  }

  startRecording() {
    // clearing console on start
    console.clear()

    if (this.camera.stream) this.startRecordingOnMediaSource(this.camera, 'AUDIO')

    if (this.screen.stream) this.startRecordingOnMediaSource(this.screen, 'SCREEN')

    // assign start timestamp
    if (this.videoParams) this.videoParams.startTimestamp = Date.now()

    if (this.videoParams) {
      const { title, uuid, username: owner, channelId, enableSplashScreen } = this.videoParams
      this.videoParams.startover = false

      NewQueueHandler.enqueueRecording({
        title,
        uuid,
        owner,
        channelId,
        enableSplashScreen,
      })
    }

    // Start recording
    this.setDurationInterval(this.durationIntervalHandler.bind(this))
  }

  stopMediaRecorderHandler(preventResetPrompter?: boolean) {
    // stop camera recorder
    if (this.camera.recorder && this.camera.recorder.state === 'recording') this.camera.recorder.stop()

    // stop camera stream
    if (this.camera.stream) this.camera.stream.getTracks().forEach((track) => track.stop())

    // stop screen recorder
    if (this.screen.recorder && this.screen.recorder.state === 'recording') this.screen.recorder.stop()

    // stop screen stream
    if (this.screen.stream) this.screen.stream.getTracks().forEach((track) => track.stop())

    if (!preventResetPrompter) {
      this.dispatch({
        action: 'UPDATE_PROMPTER_STATE',
        value: { text: '', currentState: undefined, offset: 0 },
      })
    }
  }

  startOver() {
    if (!this.videoParams) return
    this.videoParams.startover = true
    this.stopMediaRecorderHandler(true)
  }

  triggerQueueHandlerRecordingEnd() {
    // Only can be fired when ALL recorders are stopped
    if (!this.videoParams) return
    if (this.videoParams.finished) return
    if (!this.camera.stopped || !this.screen.stopped) return
    this.clearDurationInterval()

    if (this.videoParams.startover) {
      const { uuid: localId, title, type, channelId, enableSplashScreen } = this.videoParams

      NewQueueHandler.startOver(localId)
      this.initRecording({ title, type, startover: true, channelId, enableSplashScreen })
      return
    }

    this.videoParams.finished = true
    this.videoParams.endTimestamp = Date.now()

    this.dispatch({ action: 'STOP_RECORD', value: { channelId: this.videoParams.channelId } })

    NewQueueHandler.endRecording(this.videoParams.uuid)

    this.dispatch({ action: 'START_UPLOAD' })

    NewQueueHandler.setReportingState(true)
  }

  abortRecording() {
    if (!this.videoParams) return
    if (this.videoParams.finished) return
    const { uuid: localId } = this.videoParams
    this.stopMediaRecorderHandler()
    NewQueueHandler.startOver(localId)
    this.videoParams.finished = true
    this.dispatch({ action: 'CANCEL' })
  }

  cancelRecording() {
    this.cancelInitRecording = true
    this.clearPreRecordingInterval()
    this.stopMediaRecorderHandler()
    this.dispatch({ action: 'CANCEL' })
  }

  async cancelRecordingWithPrompter() {
    this.cancelInitRecording = true
    this.clearPreRecordingInterval()

    this.stopMediaRecorderHandler(true)
    this.dispatch({ action: 'CANCEL_WITH_PROMPTER' })
    await this.setupMediaStreams('CAM')
    this.dispatch({ action: 'UPDATE_PROMPTER_STATE', value: { currentState: 'SETUP' } })
  }

  async addChunkToQueueHandler(blob: Blob, type: 'AUDIO' | 'SCREEN', idx: number) {
    if (!this.videoParams) return
    NewQueueHandler.addChunkToRecording(this.videoParams.uuid, type, idx, blob)
  }
}
