import React from 'react'
import { Box, ClickAwayListener } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Increment from './Increment'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import useRecentChannels from '../../common/hooks/useRecentChannels'

interface ICustomizeRecentChannelsProps {
  toggleCustomizeRecent: () => void
}

const RecentChannels = (props: ICustomizeRecentChannelsProps) => {
  const { t } = useTranslation('chat')
  const state = useRecentChannels()

  return (
    <Box
      position="absolute"
      width="290px"
      height="132px"
      bgcolor={theme.palette.background.default}
      zIndex={99}
      style={{
        transform: 'translate(12%, -23%)',
        boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.11)',
        borderRadius: '10px',
        padding: '19px',
      }}
    >
      <ClickAwayListener onClickAway={props.toggleCustomizeRecent}>
        <form style={{ cursor: 'default' }}>
          <Box display="flex" flexDirection="column">
            <label
              style={{
                cursor: 'pointer',
                width: 'fit-content',
                color: `${
                  state.recentChannelsFormValue !== 'custom' ? theme.palette.primary.main : theme.palette.text.secondary
                }`,
              }}
            >
              <input
                onChange={() => state.setRecentChannelsFormValue('lastThree')}
                name="recentChannels"
                checked={state.recentChannelsFormValue === 'lastThree'}
                type="radio"
                value="lastThree"
                style={{ marginRight: '14px', marginBottom: '20px' }}
              />
              {t('channelsSidebar.recentChannels.lastThreeDays')}
            </label>
            <label
              style={{
                cursor: 'pointer',
                width: 'fit-content',
                color: `${
                  state.recentChannelsFormValue !== 'custom' ? theme.palette.text.secondary : theme.palette.primary.main
                }`,
              }}
            >
              <input
                onChange={() => state.setRecentChannelsFormValue('custom')}
                name="recentChannels"
                type="radio"
                value="custom"
                style={{ marginRight: '14px' }}
                checked={state.recentChannelsFormValue === 'custom'}
              />
              {t('channelsSidebar.recentChannels.custom')}
            </label>
          </Box>
          <Increment
            handleDecrement={state.handleDecrement}
            handleIncrement={state.handleIncrement}
            daysCount={state.daysCount}
            isCustom={state.recentChannelsFormValue === 'custom'}
            handleSubmit={state.updateRecentChannels}
            toggleCustomizeRecent={props.toggleCustomizeRecent}
          />
        </form>
      </ClickAwayListener>
    </Box>
  )
}

export default RecentChannels
