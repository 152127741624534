import { SchedulerItem } from './SchedulerItem'
import { UploadScheduler } from './UploadScheduler'
import { config } from '../../config'
import { RecordingIds, RecordingNotifyCompleteData } from './index.types'
import IndexedDB, { IndexedRecordingNotifyComplete } from 'common/services/IndexedDB'
import { captureException } from '@sentry/react'

export class RecordingNotifyComplete extends SchedulerItem {
  ids: RecordingIds
  owner: string

  audioCount: number
  screenCount: number
  enableSplashScreen?: boolean

  constructor(ids: RecordingIds, data: RecordingNotifyCompleteData, scheduler: UploadScheduler) {
    super(scheduler)
    this.ids = { ...ids }

    this.owner = data.owner
    this.audioCount = data.audioCount
    this.screenCount = data.screenCount
    this.enableSplashScreen = data.enableSplashScreen

    this.log('Created')
  }

  async init() {
    await this.saveToIndexedDb()
  }

  async saveToIndexedDb() {
    const notifyComplete = await IndexedDB.recordingNotifyComplete.where('localId').equals(this.ids.localId).first()

    const obj: IndexedRecordingNotifyComplete = {
      ...this.ids,
      owner: this.owner,
      audioCount: this.audioCount,
      screenCount: this.screenCount,
      ...(notifyComplete && { enableSplashScreen: this.enableSplashScreen }),
    }

    if (notifyComplete) {
      await IndexedDB.recordingNotifyComplete.update(notifyComplete, obj)
    } else {
      await IndexedDB.recordingNotifyComplete.add(obj)
    }
  }

  async deleteFromIndexedDb() {
    this.log('Delete from db...')

    await IndexedDB.recordingNotifyComplete.where('localId').equals(this.ids.localId).delete()
  }

  async setSuccess() {
    await this.deleteFromIndexedDb()
    super.setSuccess()
  }

  async singleUpload() {
    const url = `${config.CHUNKS_UPLOADING_HOST}/notify_complete`
    const params = {
      localId: this.ids.localId,
      user_id: this.owner,
      entity_id: this.ids.entityId,
      final_video_seq: this.screenCount,
      final_audio_seq: this.audioCount,
      enable_splash_screen: this.enableSplashScreen,
    }

    // @ts-ignore
    await fetch(`${url}?` + new URLSearchParams(params))
  }

  async updateIndexes(type: 'AUDIO' | 'SCREEN', idx: number) {
    switch (type) {
      case 'AUDIO':
        if (idx > this.audioCount) this.audioCount = idx
        break
      case 'SCREEN':
        if (idx > this.screenCount) this.screenCount = idx
        break
    }

    this.log('Indexes updated! saving to db...')
    await this.saveToIndexedDb()
  }

  setError() {
    // Sentry.io capture error
    captureException(new Error('Notify complete request error'), {
      tags: {
        entityId: this.ids.entityId,
        localId: this.ids.localId,
        final_video_seq: this.screenCount,
        final_audio_seq: this.audioCount,
      },
    })

    super.setError()
  }

  log(...args: any[]) {
    console.log(new Date().toISOString(), `[RecordingNotifyComplete]:`, ...args)
  }
}
