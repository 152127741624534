import { useMutation, useQuery } from '@apollo/client'
import {
  GetApplicationConfigurationQuery,
  ListVideoAudienceQueryVariables,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
} from '../../../API'
import gql from 'graphql-tag'
import { getApplicationConfiguration } from '../../../graphql/queries'
import CognitoApolloClient from '../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../common/clients/IamApolloClient'
import { useContext, useEffect, useMemo, useState } from 'react'
import { AuthContext } from '../../../common/providers/AuthStatusProvider'
import { updateCustomer } from '../../../graphql/mutations'

export type TermsAndConditionsStep = 'UPDATED_CONDITIONS_MENTION' | 'TO_ACCEPT'

function useTermsAndConditions() {
  const auth = useContext(AuthContext)
  const [termsAndConditionsStep, setTermsAndConditionsStep] = useState<TermsAndConditionsStep | null>(null)

  const [updateUserMutation, { loading: updatingTermsAndConditions }] = useMutation<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >(gql(updateCustomer), {
    client: CognitoApolloClient,
  })

  const { data: appConfigData } = useQuery<GetApplicationConfigurationQuery, ListVideoAudienceQueryVariables>(
    gql(getApplicationConfiguration),
    {
      skip: auth.user?.pending,
      client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    },
  )

  useEffect(() => {
    const checkTerms = async () => {
      if (
        auth.user?.settings?.termsAndConditions?.version &&
        auth.user?.settings?.termsAndConditions?.version ===
          appConfigData?.getApplicationConfiguration?.termsAndConditionsVersion
      )
        return
      if (auth.user?.pending || !appConfigData?.getApplicationConfiguration?.termsAndConditionsVersion) return

      if (
        !auth?.user?.settings?.termsAndConditions?.version &&
        !auth.user?.settings?.termsAndConditions?.accepted &&
        localStorage.getItem('onBoarded') !== 'true' &&
        !auth.user?.calendlyUrl &&
        !auth.user?.firstName &&
        !auth.user?.lastName &&
        !auth.user?.profilePhoto
      ) {
        // Brand-new user - we have to update him, because he accepted t&c during signup process
        await updateUserMutation({
          variables: {
            input: {
              id: auth?.user?.id || '',
              settings: {
                notifyEmailWatched: auth.user?.settings?.notifyEmailWatched || true,
                notifyEmailComment: auth.user?.settings?.notifyEmailComment || true,
                termsAndConditions: {
                  version: appConfigData?.getApplicationConfiguration?.termsAndConditionsVersion,
                  accepted: true,
                },
              },
            },
          },
        })

        await auth.updateUser({
          ...auth.user,
          // @ts-ignore
          settings: {
            ...auth.user?.settings,
            termsAndConditions: {
              version: appConfigData?.getApplicationConfiguration?.termsAndConditionsVersion,
              accepted: true,
            },
          },
        })
        return setTermsAndConditionsStep(null)
      }

      // different version so update mention will be presented
      if (
        auth?.user?.settings?.termsAndConditions?.version &&
        auth.user.settings.termsAndConditions.version !==
          appConfigData.getApplicationConfiguration.termsAndConditionsVersion
      ) {
        return setTermsAndConditionsStep('UPDATED_CONDITIONS_MENTION')
      }
    }
    checkTerms()
  }, [auth.isAuthenticated, appConfigData])

  const currentTermsAndConditionsVersionForScreenSight = useMemo(() => {
    return appConfigData?.getApplicationConfiguration?.termsAndConditionsVersion
  }, [appConfigData])

  const setAcceptedValueForCurrentTermsAndConditions = async (accepted: boolean) => {
    if (!auth?.user?.id || !auth?.user?.settings || !currentTermsAndConditionsVersionForScreenSight) return

    await updateUserMutation({
      variables: {
        input: {
          id: auth?.user?.id,
          settings: {
            notifyEmailWatched: auth.user.settings?.notifyEmailWatched || true,
            notifyEmailComment: auth.user.settings?.notifyEmailComment || true,
            termsAndConditions: {
              version: currentTermsAndConditionsVersionForScreenSight,
              accepted,
            },
          },
        },
      },
    })

    await auth.updateUser({
      ...auth.user,
      settings: {
        ...auth.user.settings,
        termsAndConditions: {
          version: currentTermsAndConditionsVersionForScreenSight,
          accepted: true,
        },
      },
    })

    setTermsAndConditionsStep(null)
  }

  const goToActualTermsAndConditionsToAccept = () => {
    setTermsAndConditionsStep('TO_ACCEPT')
  }

  return {
    updatingTermsAndConditions,
    termsAndConditionsStep,
    goToActualTermsAndConditionsToAccept,
    currentTermsAndConditionsVersionForScreenSight,
    setAcceptedValueForCurrentTermsAndConditions,
  }
}

export default useTermsAndConditions
