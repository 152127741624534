import * as React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import moment from 'moment'
import { screensightVideo } from '../../../../../API'
import SeenBy from '../../../../../components/VideoItem/SeenBy'

interface IModalLibraryItemContent {
  videoItem: screensightVideo
  isMobile: boolean
}

const ModalLibraryItemContent = (props: IModalLibraryItemContent) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box paddingLeft="20px" flexGrow="1" maxWidth="100%" style={{ overflowX: 'hidden' }}>
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="h3"
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '75%',
          }}
        >
          {props.videoItem.title}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{
          marginTop: isMobile ? '0px' : '6px',
          marginBottom: isMobile ? '6px' : '15px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '90%',
        }}
      >
        {moment(props.videoItem.createdAt).format('MMMM DD, YYYY')}
      </Typography>
      <SeenBy uniqueViewsCount={props.videoItem.viewsCountUnique} seenByArray={props.videoItem.viewsList?.items} />
    </Box>
  )
}

export default ModalLibraryItemContent
