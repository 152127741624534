import { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'

const useOnBoarding = () => {
  const auth = useContext(AuthContext)

  const [onBoardingProfileInitStepsVisible, setOnBoardingProfileInitStepsVisible] = useState(false)
  const [welcomeVideoVisible, setWelcomeVideoVisible] = useState(false)

  useEffect(() => {
    if (auth.user?.settings?.termsAndConditions?.accepted) {
      setOnBoardingProfileInitStepsVisible(
        localStorage.getItem('onBoarded') !== 'true' &&
          !auth.user?.calendlyUrl &&
          !auth.user?.firstName &&
          !auth.user?.lastName &&
          !auth.user?.profilePhoto &&
          !auth.user?.pending,
      )
    }
  }, [auth])

  const closeStepsAndOpenWelcomeVideo = useCallback(() => {
    setWelcomeVideoVisible(true)
    setOnBoardingProfileInitStepsVisible(false)
  }, [])

  const closeWelcomeVideo = useCallback(() => {
    setWelcomeVideoVisible(false)
  }, [])

  return {
    onBoardingProfileInitStepsVisible,
    closeStepsAndOpenWelcomeVideo,
    closeWelcomeVideo,
    welcomeVideoVisible,
  }
}

export default useOnBoarding
