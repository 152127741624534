import React from 'react'

interface IProps {
  color?: string
}

const AllItemsIcon = (props: IProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r="3.35" stroke={props?.color || '#7A7A86'} strokeWidth="1.3" />
      <path
        d="M12.75 5.85C12.75 5.38056 13.1306 5 13.6 5H21.15C21.6194 5 22 5.38056 22 5.85C22 6.31944 21.6194 6.7 21.15 6.7H13.6C13.1306 6.7 12.75 6.31944 12.75 5.85Z"
        fill={props?.color || '#7A7A86'}
      />
      <path
        d="M2 12.85C2 12.3806 2.38056 12 2.85 12H13.15C13.6194 12 14 12.3806 14 12.85C14 13.3194 13.6194 13.7 13.15 13.7H2.85C2.38056 13.7 2 13.3194 2 12.85Z"
        fill={props?.color || '#7A7A86'}
      />
      <path
        d="M2 16.85C2 16.3806 2.38056 16 2.85 16H21.15C21.6194 16 22 16.3806 22 16.85C22 17.3194 21.6194 17.7 21.15 17.7H2.85C2.38056 17.7 2 17.3194 2 16.85Z"
        fill={props?.color || '#7A7A86'}
      />
      <path
        d="M2 20.85C2 20.3806 2.38056 20 2.85 20H17.15C17.6194 20 18 20.3806 18 20.85C18 21.3194 17.6194 21.7 17.15 21.7H2.85C2.38056 21.7 2 21.3194 2 20.85Z"
        fill={props?.color || '#7A7A86'}
      />
    </svg>
  )
}

export default AllItemsIcon
