import * as React from 'react'
import Button from '../../../../../../../components/_refactored/Button'
import { useTranslation } from 'react-i18next'

type Props = {
  calendlyUrl?: any
}
const ScheduleMeetingBtn = (props: Props) => {
  const { t } = useTranslation('chat')

  return (
    <a href={props.calendlyUrl} target="_blank" rel="noreferrer" data-testid="schedule-meeting-btn">
      <Button
        color="primary"
        variant="contained"
        sx={{ height: '40px', width: 'fit-content', fontSize: '14px', padding: '10px 15px', textWrap: 'nowrap' }}
      >
        {t('partnerSidebar.scheduleMeetingBtn')}
      </Button>
    </a>
  )
}

export default ScheduleMeetingBtn
