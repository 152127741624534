import React from 'react'
import { BoxProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import test from '../assets/Mascot 1.png'

const useStyles = makeStyles({
  root: {
    width: 272,
    height: 210,
  },
  container: {
    paddingTop: 10,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
})

const IconStartover = (props: BoxProps) => {
  const { color } = props
  const { root, container } = useStyles()

  return (
    <div className={container}>
      <video className={root} autoPlay loop muted playsInline>
        <source type="video/webm" src={`${process.env.PUBLIC_URL}/startOverVideo.webm`} />
        <img className={root} src={test} alt={'Resource not found'} />
      </video>
    </div>
  )
}

export default IconStartover
