import React from 'react'
import { Typography, TypographyProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    color: '#7A7A86',
    flex: 1,
    textAlign: 'center',
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
})

const Description = (props: TypographyProps) => {
  const { root } = useStyles()
  const { className = '' } = props

  return <Typography {...props} className={`${root} ${className}`} />
}

export default Description
