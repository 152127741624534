import React from 'react'
import { AdvancedProps } from '../index'
import { UseAdvanced } from '../hooks/useAdvanced.hook'
import { Box, Button, Divider, Grid, IconButton, List, Typography, useMediaQuery, useTheme } from '@mui/material'

import SendIcon from '@mui/icons-material/Send'
import { useTranslation } from 'react-i18next'
import TrashIcon from '../../../../common/icons/TrashIcon'

const AdvancedView = (props: AdvancedViewProps) => {
  const { entities, onClearLocalStorage } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation('settings')

  return (
    <Box>
      <Typography variant="h4" color="secondary">
        {t('advanced.uploadQueue')}
      </Typography>
      <Button style={{ marginTop: 10 }} variant="outlined" color="secondary" onClick={onClearLocalStorage}>
        {t('advanced.clearStorageBtn')}
      </Button>
      <List component="nav" aria-label="main mailbox folders">
        {entities.list.map((item: any, idx: number) => (
          <React.Fragment key={idx}>
            <Grid container spacing={3} alignItems="center" style={{ paddingBottom: '8px', marginTop: '4px' }}>
              <Grid item xs={12} md={9}>
                <Typography variant="body1">{item.title}</Typography>
              </Grid>
              <Grid item xs={12} md={3} container justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
                <IconButton
                  aria-label="send"
                  style={{ marginRight: '8px' }}
                  onClick={() => entities.upload(item.localId)}
                >
                  <SendIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => entities.delete(item.localId)}>
                  <TrashIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      {entities.list.length === 0 && (
        <Typography variant="h6" color="secondary" align="center">
          {t('advanced.noVideos')}
        </Typography>
      )}
    </Box>
  )
}

export type AdvancedViewProps = AdvancedProps & UseAdvanced

export default AdvancedView
