import makeStyles from '@mui/styles/makeStyles'
import { Theme, useMediaQuery, useTheme } from '@mui/material'
import { createStyles } from '@mui/styles'
import { useContext } from 'react'
import { AuthContext } from '../../../../providers/AuthStatusProvider'
import { useLocation } from 'react-router'

/*
 * This hook is used to set the styles for the AppLayout component
 */
const useAppStyles = () => {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))

  const styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: location.pathname.includes('/stats') ? '#F8F9FB' : theme.palette.background.default,
      },
      content: {
        flexGrow: 1,
        padding: isMobile
          ? 0
          : location.pathname.includes('/chat') || location.pathname.includes('/app/support')
          ? auth.isAuthenticated
            ? '0 0 0 60px'
            : '0'
          : isLargeScreen
          ? auth.isAuthenticated
            ? '30px 30px 30px 90px'
            : '0'
          : auth.isAuthenticated
          ? '0 0 0 60px'
          : '0',
        backgroundColor: location.pathname.includes('/stats') ? '#F8F9FB' : theme.palette.background.default,
        maxWidth:
          location.pathname.includes('/chat') || location.pathname.includes('/app/support')
            ? '100vw'
            : auth.isAuthenticated
            ? isLargeScreen && (location.pathname === '/app' || location.pathname.includes('/video'))
              ? '100vw'
              : 1200
            : '100vw',
        minHeight: '100vh',
      },
    }),
  )()

  return [styles]
}

export default useAppStyles
