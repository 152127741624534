import React, { Dispatch, SetStateAction } from 'react'
import { Box, CircularProgress, ClickAwayListener } from '@mui/material'
import ContactAvatar from '../../../../containers/Contacts/ContactAvatar'
import { screensightContact } from '../../../../API'
import AvailableContactRow from './components/AvailableContactRow'
import useAvailableEmails from './hooks/useAvailableEmails'
import './styles.scss'

interface IAvailableEmailsModal {
  handleSelectContact?: (contact: screensightContact, event: any) => void
  handleNavigationInsideTheList?: (contact: screensightContact, event: any) => void
  setEmails: Dispatch<SetStateAction<string[]>>
  availableContacts: screensightContact[] | null
  setAvailableContacts: Dispatch<SetStateAction<screensightContact[] | null>>
  searchPhrase: string
  loading: boolean
  top?: string

  inputRef?: any
}

const AvailableEmailsModal = (props: IAvailableEmailsModal) => {
  const state = useAvailableEmails(props.setEmails, props.setAvailableContacts, props.availableContacts, props.inputRef)
  return (
    <ClickAwayListener onClickAway={() => props.setAvailableContacts([])}>
      <Box
        id="available-emails-list"
        className="available__emails__list"
        maxWidth="90%"
        position="absolute"
        zIndex={2}
        bgcolor="background.default"
        width="400px"
        height="auto"
        top={props?.top || ''}
        left="50px"
        borderRadius="10px"
        boxShadow="0px 1px 7px rgba(0, 0, 0, 0.11)"
      >
        {props.loading ? (
          <Box width="100%" display="flex" justifyContent="center" padding="15px 0">
            <CircularProgress style={{ width: '16px', height: '16px' }} />
          </Box>
        ) : (
          props?.availableContacts?.map((contact, key) => {
            return (
              <Box
                key={contact.id}
                display="flex"
                tabIndex={0}
                alignItems="center"
                padding="10px"
                id={`available-emails-list-${key}`}
                className="contact-row"
                onClick={(event: any) => state?.handleSelectContact(contact, event)}
                onKeyUp={(event: any) => state?.handleNavigationInsideTheList(contact, event)}
              >
                <ContactAvatar
                  name={contact.firstName || contact.email || ''}
                  image={contact?.contactCustomerMeta?.items?.[0]?.profilePhoto?.key}
                  width={32}
                  height={32}
                />
                <AvailableContactRow
                  key={contact.id}
                  text={
                    contact.email.toLowerCase().includes(props.searchPhrase.toLowerCase())
                      ? contact.email
                      : `${contact.firstName} ${contact.lastName}`
                  }
                  searchPhrase={props.searchPhrase}
                />
              </Box>
            )
          })
        )}
      </Box>
    </ClickAwayListener>
  )
}

export default AvailableEmailsModal
