import * as React from 'react'
import { useContext } from 'react'
import { Box } from '@mui/material'
import CloudFrontImage from '../../../CloudFrontImage'
import ScreenSightLogoGray from '../../../../../common/icons/ScreenSightLogoGray'
import { AuthContext } from '../../../../../common/providers/AuthStatusProvider'

export const TemplateBrandLogo = () => {
  const auth = useContext(AuthContext)

  return (
    <>
      {auth.user?.brandLogo?.key ? (
        <Box display="flex" maxWidth="70px" maxHeight="30px">
          <CloudFrontImage
            image={`public/${auth.user.brandLogo?.key}` || ''}
            autoScale
            imgStyle={{
              maxWidth: '70px',
              objectPosition: 'center',
              objectFit: 'contain',
              borderRadius: '4px',
            }}
          />
        </Box>
      ) : (
        <ScreenSightLogoGray />
      )}
    </>
  )
}

export default TemplateBrandLogo
