import React from 'react'
import IconScreensightLogo from './ScreensightLogo'
import IconVideoList from './VideoList'
import IconSmallCamera from './SmallCamera'
import IconChrome from './Chrome'
import IconRecordAnimation from './RecordAnimation'
import IconStartover from './Startover'
import IconStartoverSmall from './StartoverSmall'
import IconSettings from './Settings'
import LogoutIcon from './Logout'
import Support from './Support'

const Icon = () => <></>

Icon.ScreensightLogo = IconScreensightLogo
Icon.VideoList = IconVideoList
Icon.SmallCamera = IconSmallCamera
Icon.Chrome = IconChrome
Icon.RecordAnimation = IconRecordAnimation
Icon.Startover = IconStartover
Icon.StartoverSmall = IconStartoverSmall
Icon.IconSettings = IconSettings
Icon.LogoutIcon = LogoutIcon
Icon.Support = Support

export default Icon
