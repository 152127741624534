/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "ea1272cc98f34dad9619e5dce68e1848",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://kt2ixhhvlfbuviafyh6wkvd2om.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:4bd0a7b1-06e4-41c5-a63e-83559f0ee7dd",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_2WBrx68Hn",
    "aws_user_pools_web_client_id": "7ar81ulkegvvrlddti07pj228h",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "screensightApplicationConfiguration-dev",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "screensight-video-22aa7650171703-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
