/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type SetMaxVideosLimitReachedEventInput = {
  customerId: string,
};

export type SetMaxVideosLimitReachedEvent = {
  __typename: "SetMaxVideosLimitReachedEvent",
  maxVideosLimitReachedTimesFor30days?: number | null,
};

export type CreateScreensightCustomerInput = {
  firstName: string,
  lastName?: string | null,
  email?: string | null,
};

export type screensightCustomer = {
  __typename: "screensightCustomer",
  id: string,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  profilePhoto?: S3Object | null,
  brandLogo?: S3Object | null,
  calendlyUrl?: string | null,
  lastSeen?: string | null,
  settings?: CustomerSettings | null,
  stripeId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type S3Object = {
  __typename: "S3Object",
  key: string,
  bucket: string,
  accessLevel?: S3FileAccessLevel | null,
  identityId?: string | null,
};

export enum S3FileAccessLevel {
  public = "public",
  protected = "protected",
  private = "private",
}


export type CustomerSettings = {
  __typename: "CustomerSettings",
  notifyEmailWatched: boolean,
  notifyEmailComment: boolean,
  notifyUnseenMessages?: boolean | null,
  timezone?: string | null,
  pushNotifications?: PushNotificationSettings | null,
  lastChannel?: string | null,
  enableSplashScreen?: boolean | null,
  termsAndConditions?: TermsAndConditions | null,
  channelRecentActivityDays?: number | null,
};

export type PushNotificationSettings = {
  __typename: "PushNotificationSettings",
  video?: boolean | null,
  comment?: boolean | null,
  reply?: boolean | null,
  text?: boolean | null,
};

export type TermsAndConditions = {
  __typename: "TermsAndConditions",
  version: string,
  accepted: boolean,
  acceptedAt?: string | null,
};

export type CreatePaymentMethodInput = {
  vaultId: string,
  stripeToken: string,
  radarSession?: string | null,
};

export type PaymentMethod = {
  __typename: "PaymentMethod",
  brand?: string | null,
  nameOnCard?: string | null,
  expirationMonth?: number | null,
  expirationYear?: number | null,
  lastFourDigits?: string | null,
};

export type UpdatePaymentMethodInput = {
  expirationMonth?: number | null,
  expirationYear?: number | null,
  nameOnCard?: string | null,
  postalCode?: string | null,
  country?: string | null,
};

export type BillingSubscriptionInput = {
  priceId: string,
};

export type BillingSubscription = {
  __typename: "BillingSubscription",
  id?: string | null,
  price?: BillingPrice | null,
  product?: BillingProduct | null,
};

export type BillingPrice = {
  __typename: "BillingPrice",
  id?: string | null,
  currency?: string | null,
  interval?: string | null,
  amount?: number | null,
};

export type BillingProduct = {
  __typename: "BillingProduct",
  name?: string | null,
  description?: string | null,
  prices?:  Array<BillingPrice | null > | null,
};

export type CreateScreensightChannelInput = {
  participants?: Array< ScreensightCustomerInput | null > | null,
};

export type ScreensightCustomerInput = {
  customerId: string,
  name: string,
};

export type ModelScreensightChannelConditionInput = {
  customerId?: ModelIDInput | null,
  channelType?: ModelStringInput | null,
  name?: ModelStringInput | null,
  categoryPosition?: ModelIntInput | null,
  readHorizon?: ModelStringInput | null,
  chatPartnerId?: ModelStringInput | null,
  latestPostAt?: ModelStringInput | null,
  and?: Array< ModelScreensightChannelConditionInput | null > | null,
  or?: Array< ModelScreensightChannelConditionInput | null > | null,
  not?: ModelScreensightChannelConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type screensightChannel = {
  __typename: "screensightChannel",
  id: string,
  customerId: string,
  channelType?: string | null,
  name?: string | null,
  categoryPosition?: number | null,
  readHorizon?: string | null,
  activity?: ChannelActivity | null,
  chatPartnerId?: string | null,
  chatPartnerMeta?: screensightCustomer | null,
  latestPostAt?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ChannelActivity = {
  __typename: "ChannelActivity",
  new?: boolean | null,
  latestAt?: string | null,
  latestPost?: LatestPost | null,
};

export type LatestPost = {
  __typename: "LatestPost",
  id?: string | null,
  channelId?: string | null,
  accessId?: string | null,
  customerId?: string | null,
  postType?: string | null,
  video?: screensightVideo | null,
  comment?: screensightVideoComment | null,
  emailMessage?: screensightVideoEmailMessage | null,
  text?: TextPost | null,
  referencePostId?: string | null,
  referencePost?: LatestPost | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type screensightVideo = {
  __typename: "screensightVideo",
  id: string,
  createdAt: string,
  customerId: string,
  channelId?: string | null,
  customerMeta?: screensightCustomer | null,
  title?: string | null,
  searchTitle?: string | null,
  status: VideoStatus,
  fileObject?: S3Object | null,
  filePresignedUrl?: string | null,
  duration?: number | null,
  name?: string | null,
  thumbnailObject?: S3Object | null,
  accessType?: AccessType | null,
  accessId?: string | null,
  password?: string | null,
  width?: number | null,
  height?: number | null,
  viewsCountTotal?: number | null,
  viewsCountUnique?: number | null,
  viewsList?: ModelscreensightVideoAudienceConnection | null,
  comments?: ModelscreensightVideoCommentConnection | null,
  commentsCount?: number | null,
  uploadedVideo?: UploadedVideo | null,
  transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
  transcriptionSummaryError?: string | null,
  transcriptionSummary?: string | null,
  transcriptionObject?: S3Object | null,
  updatedAt: string,
};

export enum VideoStatus {
  init = "init",
  uploading = "uploading",
  converting = "converting",
  error = "error",
  ok = "ok",
  webm = "webm",
}


export enum AccessType {
  public = "public",
  protected = "protected",
}


export type ModelscreensightVideoAudienceConnection = {
  __typename: "ModelscreensightVideoAudienceConnection",
  items:  Array<screensightVideoAudience | null >,
  nextToken?: string | null,
};

export type screensightVideoAudience = {
  __typename: "screensightVideoAudience",
  videoId: string,
  customerId: string,
  customerMeta?: screensightCustomer | null,
  guestName?: string | null,
  viewCount?: number | null,
  video?: screensightVideo | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelscreensightVideoCommentConnection = {
  __typename: "ModelscreensightVideoCommentConnection",
  items:  Array<screensightVideoComment | null >,
  nextToken?: string | null,
  count?: number | null,
};

export type screensightVideoComment = {
  __typename: "screensightVideoComment",
  id: string,
  customerId?: string | null,
  customerMeta?: screensightCustomer | null,
  videoId: string,
  channelId?: string | null,
  parentId?: string | null,
  content?: string | null,
  richContent?: string | null,
  videoTimestamp?: number | null,
  resolved?: boolean | null,
  replies?: ModelscreensightVideoCommentConnection | null,
  replyCount?: number | null,
  parentCreatedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UploadedVideo = {
  __typename: "UploadedVideo",
  fileName?: string | null,
  videoContainer?: string | null,
  videoEncoding?: string | null,
};

export enum TranscriptionSummaryStatus {
  error = "error",
  completed = "completed",
}


export type screensightVideoEmailMessage = {
  __typename: "screensightVideoEmailMessage",
  id: string,
  videoId: string,
  customerId?: string | null,
  channelId?: string | null,
  to?: Array< string | null > | null,
  note?: string | null,
  emailStatus?:  Array<EmailStatus | null > | null,
  subject?: string | null,
  contacts?:  Array<EmailContact | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type EmailStatus = {
  __typename: "EmailStatus",
  email?: string | null,
  status?: string | null,
  reject_reason?: string | null,
  _id?: string | null,
  deliveredAt?: string | null,
  opens?: Array< string | null > | null,
};

export type EmailContact = {
  __typename: "EmailContact",
  email?: string | null,
  contactId?: string | null,
};

export type TextPost = {
  __typename: "TextPost",
  content?: string | null,
  richContent?: string | null,
  customerId?: string | null,
  customerMeta?: screensightCustomer | null,
};

export type UpdateScreensightChannelInput = {
  id: string,
  customerId: string,
  name?: string | null,
  readHorizon: string,
};

export type CreateScreensightPostInput = {
  video?: CreateScreensightVideoInput | null,
  comment?: CreateScreensightVideoCommentInput | null,
  emailMessage?: CreateScreensightVideoEmailMessageInput | null,
  text?: CreateTextPostInput | null,
  sharedPost?: CreatePostShareInput | null,
  file?: FilePostInput | null,
  referencePostId?: string | null,
  postTempId?: string | null,
};

export type CreateScreensightVideoInput = {
  channelId?: string | null,
  title?: string | null,
  searchTitle?: string | null,
  status: VideoStatus,
  fileObject?: S3ObjectInput | null,
  duration?: number | null,
  name?: string | null,
  thumbnailObject?: S3ObjectInput | null,
  accessType?: AccessType | null,
  password?: string | null,
  width?: number | null,
  height?: number | null,
  uploadedVideo?: UploadedVideoInput | null,
};

export type S3ObjectInput = {
  key: string,
  bucket: string,
  accessLevel?: S3FileAccessLevel | null,
  indentityId?: string | null,
};

export type UploadedVideoInput = {
  fileName?: string | null,
  videoContainer?: string | null,
  videoEncoding?: string | null,
};

export type CreateScreensightVideoCommentInput = {
  customerId?: string | null,
  videoId: string,
  channelId?: string | null,
  parentId?: string | null,
  content?: string | null,
  richContent?: string | null,
  videoTimestamp?: number | null,
  resolved?: boolean | null,
  parentCreatedAt?: string | null,
};

export type CreateScreensightVideoEmailMessageInput = {
  videoId: string,
  customerId?: string | null,
  channelId?: string | null,
  to?: Array< string | null > | null,
  note?: string | null,
  subject?: string | null,
};

export type CreateTextPostInput = {
  channelId: string,
  content: string,
  customerId?: string | null,
  richContent?: string | null,
};

export type CreatePostShareInput = {
  postId: string,
  channelId?: string | null,
};

export type FilePostInput = {
  channelId: string,
  customerId?: string | null,
  metadata?: string | null,
};

export type ModelScreensightPostConditionInput = {
  channelId?: ModelIDInput | null,
  accessId?: ModelStringInput | null,
  customerId?: ModelIDInput | null,
  postType?: ModelStringInput | null,
  referencePostId?: ModelIDInput | null,
  readHorizon?: ModelStringInput | null,
  postTempId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelScreensightPostConditionInput | null > | null,
  or?: Array< ModelScreensightPostConditionInput | null > | null,
  not?: ModelScreensightPostConditionInput | null,
};

export type screensightPost = {
  __typename: "screensightPost",
  id: string,
  channelId?: string | null,
  accessId?: string | null,
  customerId?: string | null,
  postType?: string | null,
  video?: screensightVideo | null,
  comment?: screensightVideoComment | null,
  emailMessage?: screensightVideoEmailMessage | null,
  text?: TextPost | null,
  file?: FilePost | null,
  engagement?: PostEngagementConnection | null,
  referencePostId?: string | null,
  referencePost?: screensightPost | null,
  readHorizon?: string | null,
  postTempId?: string | null,
  reactions?: ModelscreensightReactionConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type FilePost = {
  __typename: "FilePost",
  fileName?: string | null,
  fileObject?: S3Object | null,
  status?: VideoStatus | null,
  customerId?: string | null,
  customerMeta?: screensightCustomer | null,
  mimeType?: string | null,
  metadata?: string | null,
};

export type PostEngagementConnection = {
  __typename: "PostEngagementConnection",
  items:  Array<PostEngagement | null >,
  nextToken?: string | null,
};

export type PostEngagement = {
  __typename: "PostEngagement",
  customerId?: string | null,
  seen?: boolean | null,
  seenAt?: string | null,
  videoProgress?: number | null,
};

export type ModelscreensightReactionConnection = {
  __typename: "ModelscreensightReactionConnection",
  items:  Array<screensightReaction | null >,
  nextToken?: string | null,
};

export type screensightReaction = {
  __typename: "screensightReaction",
  postId: string,
  customerId: string,
  customerMeta?: screensightCustomer | null,
  unicode: string,
  videoTimestamp: number,
  channelId?: string | null,
  incrementBy?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateScreensightPostInput = {
  text?: UpdateTextPostInput | null,
};

export type UpdateTextPostInput = {
  id: string,
  content?: string | null,
  richContent?: string | null,
};

export type DeleteScreensightPostInput = {
  id: string,
};

export type ModelScreensightVideoConditionInput = {
  createdAt?: ModelStringInput | null,
  customerId?: ModelIDInput | null,
  channelId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  searchTitle?: ModelStringInput | null,
  status?: ModelVideoStatusInput | null,
  filePresignedUrl?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  name?: ModelStringInput | null,
  accessType?: ModelAccessTypeInput | null,
  accessId?: ModelStringInput | null,
  password?: ModelStringInput | null,
  width?: ModelIntInput | null,
  height?: ModelIntInput | null,
  viewsCountTotal?: ModelIntInput | null,
  viewsCountUnique?: ModelIntInput | null,
  commentsCount?: ModelIntInput | null,
  and?: Array< ModelScreensightVideoConditionInput | null > | null,
  or?: Array< ModelScreensightVideoConditionInput | null > | null,
  not?: ModelScreensightVideoConditionInput | null,
};

export type ModelVideoStatusInput = {
  eq?: VideoStatus | null,
  ne?: VideoStatus | null,
};

export type ModelAccessTypeInput = {
  eq?: AccessType | null,
  ne?: AccessType | null,
};

export type UpdateScreensightVideoInput = {
  id: string,
  title?: string | null,
  searchTitle?: string | null,
  status?: VideoStatus | null,
  fileObject?: S3ObjectInput | null,
  duration?: number | null,
  name?: string | null,
  thumbnailObject?: S3ObjectInput | null,
  accessType?: AccessType | null,
  password?: string | null,
  width?: number | null,
  height?: number | null,
  uploadedVideo?: UploadedVideoInput | null,
  transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
  transcriptionSummaryError?: string | null,
  transcriptionSummary?: string | null,
  transcriptionObject?: S3ObjectInput | null,
};

export type DeleteScreensightVideoInput = {
  id?: string | null,
};

export type SetVideoViewedInput = {
  id: string,
  name?: string | null,
  guestCustomerId?: string | null,
};

export type ModelScreensightVideoCommentConditionInput = {
  customerId?: ModelIDInput | null,
  videoId?: ModelIDInput | null,
  channelId?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  richContent?: ModelStringInput | null,
  videoTimestamp?: ModelIntInput | null,
  resolved?: ModelBooleanInput | null,
  replyCount?: ModelIntInput | null,
  parentCreatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelScreensightVideoCommentConditionInput | null > | null,
  or?: Array< ModelScreensightVideoCommentConditionInput | null > | null,
  not?: ModelScreensightVideoCommentConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateScreensightVideoCommentInput = {
  id: string,
  customerId?: string | null,
  videoId?: string | null,
  channelId?: string | null,
  parentId?: string | null,
  content?: string | null,
  richContent?: string | null,
  videoTimestamp?: number | null,
  resolved?: boolean | null,
  replyCount?: number | null,
  parentCreatedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteScreensightVideoCommentInput = {
  id: string,
};

export type ModelScreensightVideoEmailMessageConditionInput = {
  videoId?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  channelId?: ModelIDInput | null,
  to?: ModelStringInput | null,
  note?: ModelStringInput | null,
  subject?: ModelStringInput | null,
  and?: Array< ModelScreensightVideoEmailMessageConditionInput | null > | null,
  or?: Array< ModelScreensightVideoEmailMessageConditionInput | null > | null,
  not?: ModelScreensightVideoEmailMessageConditionInput | null,
};

export type ExecutionStatus = {
  __typename: "ExecutionStatus",
  executionArn: string,
  startDate: number,
};

export type CreateScreensightReactionInput = {
  postId: string,
  customerId: string,
  unicode: string,
  videoTimestamp: number,
  channelId?: string | null,
  incrementBy?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelScreensightReactionConditionInput = {
  postId?: ModelIDInput | null,
  customerId?: ModelStringInput | null,
  unicode?: ModelStringInput | null,
  videoTimestamp?: ModelIntInput | null,
  channelId?: ModelIDInput | null,
  incrementBy?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelScreensightReactionConditionInput | null > | null,
  or?: Array< ModelScreensightReactionConditionInput | null > | null,
  not?: ModelScreensightReactionConditionInput | null,
};

export type DeleteScreensightReactionInput = {
  postId: string,
  videoTimestamp: number,
  unicode: string,
  customerId: string,
};

export type SendPostEventInput = {
  customerId: string,
  channelId?: string | null,
  action: string,
  post?: string | null,
  videosLimitReached?: boolean | null,
};

export type PostEvent = {
  __typename: "PostEvent",
  customerId: string,
  channelId?: string | null,
  action: string,
  post?: screensightPost | null,
  videosLimitReached?: boolean | null,
};

export type SetPostsSeenInput = {
  customerId?: string | null,
  channelId: string,
  postIds: Array< string | null >,
  readHorizon: string,
};

export type PostsSeen = {
  __typename: "PostsSeen",
  customerId?: string | null,
  channelId?: string | null,
  timestamp?: string | null,
  source?: string | null,
  postIds?: Array< string | null > | null,
  customerMeta?: screensightCustomer | null,
};

export type PublishCustomerChangesInput = {
  customerId: string,
  customerMeta: string,
};

export type ModelScreensightCustomerConditionInput = {
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  calendlyUrl?: ModelStringInput | null,
  lastSeen?: ModelStringInput | null,
  stripeId?: ModelStringInput | null,
  and?: Array< ModelScreensightCustomerConditionInput | null > | null,
  or?: Array< ModelScreensightCustomerConditionInput | null > | null,
  not?: ModelScreensightCustomerConditionInput | null,
};

export type UpdateScreensightCustomerInput = {
  id: string,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  profilePhoto?: S3ObjectInput | null,
  brandLogo?: S3ObjectInput | null,
  calendlyUrl?: string | null,
  lastSeen?: string | null,
  settings?: CustomerSettingsInput | null,
  stripeId?: string | null,
};

export type CustomerSettingsInput = {
  notifyEmailWatched: boolean,
  notifyEmailComment: boolean,
  notifyUnseenMessages?: boolean | null,
  timezone?: string | null,
  pushNotifications?: PushNotificationSettingsInput | null,
  lastChannel?: string | null,
  enableSplashScreen?: boolean | null,
  termsAndConditions?: TermsAndConditionsInput | null,
  channelRecentActivityDays?: number | null,
};

export type PushNotificationSettingsInput = {
  video?: boolean | null,
  comment?: boolean | null,
  reply?: boolean | null,
  text?: boolean | null,
};

export type TermsAndConditionsInput = {
  version: string,
  accepted: boolean,
};

export type CreateScreensightContactInput = {
  id?: string | null,
  ownerCustomerId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email: string,
  mobilePhone?: string | null,
};

export type ModelScreensightContactConditionInput = {
  ownerCustomerId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  mobilePhone?: ModelStringInput | null,
  and?: Array< ModelScreensightContactConditionInput | null > | null,
  or?: Array< ModelScreensightContactConditionInput | null > | null,
  not?: ModelScreensightContactConditionInput | null,
};

export type screensightContact = {
  __typename: "screensightContact",
  id: string,
  ownerCustomerId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email: string,
  mobilePhone?: string | null,
  contactCustomerMeta?: ModelscreensightCustomerConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelscreensightCustomerConnection = {
  __typename: "ModelscreensightCustomerConnection",
  items:  Array<screensightCustomer | null >,
  nextToken?: string | null,
};

export type UpdateScreensightContactInput = {
  id: string,
  ownerCustomerId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  mobilePhone?: string | null,
};

export type DeleteScreensightContactInput = {
  id: string,
};

export type CustomerVideoLimitsData = {
  __typename: "CustomerVideoLimitsData",
  maxVideosLimitReached?: boolean | null,
  maxVideosLimitReachedTimesFor30days?: number | null,
};

export type ApplicationConfiguration = {
  __typename: "ApplicationConfiguration",
  videoPlayerEventsFirehose?: string | null,
  termsAndConditionsVersion?: string | null,
  appVersionEndOfLife?: number | null,
  appVersionMinimum?: number | null,
  maintenanceMode?: boolean | null,
  featureFlags?: FeatureFlags | null,
};

export type FeatureFlags = {
  __typename: "FeatureFlags",
  id: string,
  maxVideosLimit?: number | null,
  maxVideoDurationLimit?: number | null,
};

export type BillingSession = {
  __typename: "BillingSession",
  AccessKeyId?: string | null,
  SecretAccessKey?: string | null,
  SessionToken?: string | null,
  Expiration?: string | null,
  AwsRegion?: string | null,
  ServiceName?: string | null,
};

export type BillingInformation = {
  __typename: "BillingInformation",
  paymentMethod?: PaymentMethod | null,
  subscription?: BillingSubscription | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelscreensightVideoFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  customerId?: ModelIDInput | null,
  channelId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  searchTitle?: ModelStringInput | null,
  status?: ModelVideoStatusInput | null,
  filePresignedUrl?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  name?: ModelStringInput | null,
  accessType?: ModelAccessTypeInput | null,
  accessId?: ModelStringInput | null,
  password?: ModelStringInput | null,
  width?: ModelIntInput | null,
  height?: ModelIntInput | null,
  viewsCountTotal?: ModelIntInput | null,
  viewsCountUnique?: ModelIntInput | null,
  commentsCount?: ModelIntInput | null,
  and?: Array< ModelscreensightVideoFilterInput | null > | null,
  or?: Array< ModelscreensightVideoFilterInput | null > | null,
  not?: ModelscreensightVideoFilterInput | null,
};

export type ModelscreensightVideoConnection = {
  __typename: "ModelscreensightVideoConnection",
  items:  Array<screensightVideo | null >,
  nextToken?: string | null,
};

export type ProtectedVideo = {
  __typename: "ProtectedVideo",
  accessToken?: string | null,
  video?: screensightVideo | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelScreensightVideoAudienceFilterInput = {
  videoId?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  guestName?: ModelStringInput | null,
  viewCount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelscreensightVideoAudienceFilterInput | null > | null,
  or?: Array< ModelscreensightVideoAudienceFilterInput | null > | null,
  not?: ModelscreensightVideoAudienceFilterInput | null,
};

export type ModelscreensightVideoAudienceFilterInput = {
  videoId?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  guestName?: ModelStringInput | null,
  viewCount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelscreensightVideoAudienceFilterInput | null > | null,
  or?: Array< ModelscreensightVideoAudienceFilterInput | null > | null,
  not?: ModelscreensightVideoAudienceFilterInput | null,
};

export type ModelScreensightVideoAudienceConnection = {
  __typename: "ModelScreensightVideoAudienceConnection",
  items:  Array<screensightVideoAudience | null >,
  nextToken?: string | null,
};

export type VideoAudienceEngagement = {
  __typename: "VideoAudienceEngagement",
  videoId?: string | null,
  customerId?: string | null,
  customerMeta?: screensightCustomer | null,
  timestamp?: string | null,
  chartValues?: Array< number | null > | null,
  segments?:  Array<VideoAudienceEngagementSegment | null > | null,
};

export type VideoAudienceEngagementSegment = {
  __typename: "VideoAudienceEngagementSegment",
  timestamp?: string | null,
  chartValues?: Array< number | null > | null,
};

export type ModelscreensightChannelFilterInput = {
  id?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  channelType?: ModelStringInput | null,
  name?: ModelStringInput | null,
  categoryPosition?: ModelIntInput | null,
  readHorizon?: ModelStringInput | null,
  chatPartnerId?: ModelStringInput | null,
  latestPostAt?: ModelStringInput | null,
  and?: Array< ModelscreensightChannelFilterInput | null > | null,
  or?: Array< ModelscreensightChannelFilterInput | null > | null,
  not?: ModelscreensightChannelFilterInput | null,
};

export type ModelscreensightChannelConnection = {
  __typename: "ModelscreensightChannelConnection",
  items:  Array<screensightChannel | null >,
  nextToken?: string | null,
};

export type ChannelParticipantConnection = {
  __typename: "ChannelParticipantConnection",
  items:  Array<ChannelParticipant | null >,
  nextToken?: string | null,
};

export type ChannelParticipant = {
  __typename: "ChannelParticipant",
  customerId: string,
  customerMeta?: screensightCustomer | null,
};

export type ModelscreensightPostFilterInput = {
  id?: ModelIDInput | null,
  channelId?: ModelIDInput | null,
  accessId?: ModelStringInput | null,
  customerId?: ModelIDInput | null,
  postType?: ModelStringInput | null,
  referencePostId?: ModelIDInput | null,
  readHorizon?: ModelStringInput | null,
  postTempId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelscreensightPostFilterInput | null > | null,
  or?: Array< ModelscreensightPostFilterInput | null > | null,
  not?: ModelscreensightPostFilterInput | null,
};

export type ModelscreensightPostConnection = {
  __typename: "ModelscreensightPostConnection",
  items:  Array<screensightPost | null >,
  nextToken?: string | null,
};

export enum PostTypeFilter {
  file = "file",
  video = "video",
}


export type ModelscreensightContactByOwnerSortedByNameCompositeKeyConditionInput = {
  eq?: ModelscreensightContactByOwnerSortedByNameCompositeKeyInput | null,
  le?: ModelscreensightContactByOwnerSortedByNameCompositeKeyInput | null,
  lt?: ModelscreensightContactByOwnerSortedByNameCompositeKeyInput | null,
  ge?: ModelscreensightContactByOwnerSortedByNameCompositeKeyInput | null,
  gt?: ModelscreensightContactByOwnerSortedByNameCompositeKeyInput | null,
  between?: Array< ModelscreensightContactByOwnerSortedByNameCompositeKeyInput | null > | null,
  beginsWith?: ModelscreensightContactByOwnerSortedByNameCompositeKeyInput | null,
};

export type ModelscreensightContactByOwnerSortedByNameCompositeKeyInput = {
  lastName?: string | null,
  firstName?: string | null,
};

export type ModelscreensightContactFilterInput = {
  id?: ModelIDInput | null,
  ownerCustomerId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  mobilePhone?: ModelStringInput | null,
  and?: Array< ModelscreensightContactFilterInput | null > | null,
  or?: Array< ModelscreensightContactFilterInput | null > | null,
  not?: ModelscreensightContactFilterInput | null,
};

export type ModelscreensightContactConnection = {
  __typename: "ModelscreensightContactConnection",
  items:  Array<screensightContact | null >,
  nextToken?: string | null,
};

export type SearchablescreensightContactFilterInput = {
  id?: SearchableIDFilterInput | null,
  ownerCustomerId?: SearchableIDFilterInput | null,
  firstName?: SearchableStringFilterInput | null,
  lastName?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  mobilePhone?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchablescreensightContactFilterInput | null > | null,
  or?: Array< SearchablescreensightContactFilterInput | null > | null,
  not?: SearchablescreensightContactFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchablescreensightContactSortInput = {
  field?: SearchablescreensightContactSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablescreensightContactSortableFields {
  id = "id",
  ownerCustomerId = "ownerCustomerId",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  mobilePhone = "mobilePhone",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchablescreensightContactAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablescreensightContactAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchablescreensightContactAggregateField {
  id = "id",
  ownerCustomerId = "ownerCustomerId",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  mobilePhone = "mobilePhone",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablescreensightContactConnection = {
  __typename: "SearchablescreensightContactConnection",
  items:  Array<screensightContact | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelscreensightChannelByCustomerCompositeKeyConditionInput = {
  eq?: ModelscreensightChannelByCustomerCompositeKeyInput | null,
  le?: ModelscreensightChannelByCustomerCompositeKeyInput | null,
  lt?: ModelscreensightChannelByCustomerCompositeKeyInput | null,
  ge?: ModelscreensightChannelByCustomerCompositeKeyInput | null,
  gt?: ModelscreensightChannelByCustomerCompositeKeyInput | null,
  between?: Array< ModelscreensightChannelByCustomerCompositeKeyInput | null > | null,
  beginsWith?: ModelscreensightChannelByCustomerCompositeKeyInput | null,
};

export type ModelscreensightChannelByCustomerCompositeKeyInput = {
  categoryPosition?: number | null,
  name?: string | null,
};

export type SearchablescreensightVideoFilterInput = {
  id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  customerId?: SearchableIDFilterInput | null,
  channelId?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  searchTitle?: SearchableStringFilterInput | null,
  filePresignedUrl?: SearchableStringFilterInput | null,
  duration?: SearchableIntFilterInput | null,
  name?: SearchableStringFilterInput | null,
  accessId?: SearchableStringFilterInput | null,
  password?: SearchableStringFilterInput | null,
  width?: SearchableIntFilterInput | null,
  height?: SearchableIntFilterInput | null,
  viewsCountTotal?: SearchableIntFilterInput | null,
  viewsCountUnique?: SearchableIntFilterInput | null,
  commentsCount?: SearchableIntFilterInput | null,
  transcriptionSummaryError?: SearchableStringFilterInput | null,
  transcriptionSummary?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  accessType?: SearchableStringFilterInput | null,
  transcriptionSummaryStatus?: SearchableStringFilterInput | null,
  and?: Array< SearchablescreensightVideoFilterInput | null > | null,
  or?: Array< SearchablescreensightVideoFilterInput | null > | null,
  not?: SearchablescreensightVideoFilterInput | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchablescreensightVideoSortInput = {
  field?: SearchablescreensightVideoSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablescreensightVideoSortableFields {
  id = "id",
  createdAt = "createdAt",
  customerId = "customerId",
  channelId = "channelId",
  title = "title",
  searchTitle = "searchTitle",
  filePresignedUrl = "filePresignedUrl",
  duration = "duration",
  name = "name",
  accessId = "accessId",
  password = "password",
  width = "width",
  height = "height",
  viewsCountTotal = "viewsCountTotal",
  viewsCountUnique = "viewsCountUnique",
  commentsCount = "commentsCount",
  transcriptionSummaryError = "transcriptionSummaryError",
  transcriptionSummary = "transcriptionSummary",
  updatedAt = "updatedAt",
}


export type SearchablescreensightVideoAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablescreensightVideoAggregateField,
};

export enum SearchablescreensightVideoAggregateField {
  id = "id",
  createdAt = "createdAt",
  customerId = "customerId",
  channelId = "channelId",
  title = "title",
  searchTitle = "searchTitle",
  status = "status",
  filePresignedUrl = "filePresignedUrl",
  duration = "duration",
  name = "name",
  accessType = "accessType",
  accessId = "accessId",
  password = "password",
  width = "width",
  height = "height",
  viewsCountTotal = "viewsCountTotal",
  viewsCountUnique = "viewsCountUnique",
  commentsCount = "commentsCount",
  transcriptionSummaryStatus = "transcriptionSummaryStatus",
  transcriptionSummaryError = "transcriptionSummaryError",
  transcriptionSummary = "transcriptionSummary",
  updatedAt = "updatedAt",
}


export type SearchablescreensightVideoConnection = {
  __typename: "SearchablescreensightVideoConnection",
  items:  Array<screensightVideo | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelscreensightVideoCommentByVideoCompositeKeyConditionInput = {
  eq?: ModelscreensightVideoCommentByVideoCompositeKeyInput | null,
  le?: ModelscreensightVideoCommentByVideoCompositeKeyInput | null,
  lt?: ModelscreensightVideoCommentByVideoCompositeKeyInput | null,
  ge?: ModelscreensightVideoCommentByVideoCompositeKeyInput | null,
  gt?: ModelscreensightVideoCommentByVideoCompositeKeyInput | null,
  between?: Array< ModelscreensightVideoCommentByVideoCompositeKeyInput | null > | null,
  beginsWith?: ModelscreensightVideoCommentByVideoCompositeKeyInput | null,
};

export type ModelscreensightVideoCommentByVideoCompositeKeyInput = {
  parentCreatedAt?: string | null,
  createdAt?: string | null,
};

export type ModelscreensightVideoCommentFilterInput = {
  id?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  videoId?: ModelIDInput | null,
  channelId?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  richContent?: ModelStringInput | null,
  videoTimestamp?: ModelIntInput | null,
  resolved?: ModelBooleanInput | null,
  replyCount?: ModelIntInput | null,
  parentCreatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelscreensightVideoCommentFilterInput | null > | null,
  or?: Array< ModelscreensightVideoCommentFilterInput | null > | null,
  not?: ModelscreensightVideoCommentFilterInput | null,
};

export type SearchablescreensightPostFilterInput = {
  id?: SearchableIDFilterInput | null,
  channelId?: SearchableIDFilterInput | null,
  accessId?: SearchableStringFilterInput | null,
  customerId?: SearchableIDFilterInput | null,
  postType?: SearchableStringFilterInput | null,
  referencePostId?: SearchableIDFilterInput | null,
  readHorizon?: SearchableStringFilterInput | null,
  postTempId?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchablescreensightPostFilterInput | null > | null,
  or?: Array< SearchablescreensightPostFilterInput | null > | null,
  not?: SearchablescreensightPostFilterInput | null,
};

export type SearchablescreensightPostSortInput = {
  field?: SearchablescreensightPostSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablescreensightPostSortableFields {
  id = "id",
  channelId = "channelId",
  accessId = "accessId",
  customerId = "customerId",
  postType = "postType",
  referencePostId = "referencePostId",
  readHorizon = "readHorizon",
  postTempId = "postTempId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablescreensightPostAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablescreensightPostAggregateField,
};

export enum SearchablescreensightPostAggregateField {
  id = "id",
  channelId = "channelId",
  accessId = "accessId",
  customerId = "customerId",
  postType = "postType",
  referencePostId = "referencePostId",
  readHorizon = "readHorizon",
  postTempId = "postTempId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablescreensightPostConnection = {
  __typename: "SearchablescreensightPostConnection",
  items:  Array<screensightPost | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelSubscriptionScreensightCustomerFilterInput = {
  email?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  calendlyUrl?: ModelSubscriptionStringInput | null,
  lastSeen?: ModelSubscriptionStringInput | null,
  stripeId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionScreensightCustomerFilterInput | null > | null,
  or?: Array< ModelSubscriptionScreensightCustomerFilterInput | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type SetMaxVideosLimitReachedEventMutationVariables = {
  input?: SetMaxVideosLimitReachedEventInput | null,
};

export type SetMaxVideosLimitReachedEventMutation = {
  setMaxVideosLimitReachedEvent?:  {
    __typename: "SetMaxVideosLimitReachedEvent",
    maxVideosLimitReachedTimesFor30days?: number | null,
  } | null,
};

export type GetOrCreateCustomerMutationVariables = {
  input?: CreateScreensightCustomerInput | null,
};

export type GetOrCreateCustomerMutation = {
  getOrCreateCustomer?:  {
    __typename: "screensightCustomer",
    id: string,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    profilePhoto?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    brandLogo?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    calendlyUrl?: string | null,
    lastSeen?: string | null,
    settings?:  {
      __typename: "CustomerSettings",
      notifyEmailWatched: boolean,
      notifyEmailComment: boolean,
      notifyUnseenMessages?: boolean | null,
      timezone?: string | null,
      pushNotifications?:  {
        __typename: "PushNotificationSettings",
        video?: boolean | null,
        comment?: boolean | null,
        reply?: boolean | null,
        text?: boolean | null,
      } | null,
      lastChannel?: string | null,
      enableSplashScreen?: boolean | null,
      termsAndConditions?:  {
        __typename: "TermsAndConditions",
        version: string,
        accepted: boolean,
        acceptedAt?: string | null,
      } | null,
      channelRecentActivityDays?: number | null,
    } | null,
    stripeId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBillingPaymentMethodMutationVariables = {
  input: CreatePaymentMethodInput,
};

export type CreateBillingPaymentMethodMutation = {
  createBillingPaymentMethod?:  {
    __typename: "PaymentMethod",
    brand?: string | null,
    nameOnCard?: string | null,
    expirationMonth?: number | null,
    expirationYear?: number | null,
    lastFourDigits?: string | null,
  } | null,
};

export type UpdateBillingPaymentMethodMutationVariables = {
  input: UpdatePaymentMethodInput,
};

export type UpdateBillingPaymentMethodMutation = {
  updateBillingPaymentMethod?:  {
    __typename: "PaymentMethod",
    brand?: string | null,
    nameOnCard?: string | null,
    expirationMonth?: number | null,
    expirationYear?: number | null,
    lastFourDigits?: string | null,
  } | null,
};

export type DeleteBillingPaymentMethodMutation = {
  deleteBillingPaymentMethod?:  {
    __typename: "PaymentMethod",
    brand?: string | null,
    nameOnCard?: string | null,
    expirationMonth?: number | null,
    expirationYear?: number | null,
    lastFourDigits?: string | null,
  } | null,
};

export type CreateBillingSubscriptionMutationVariables = {
  input: BillingSubscriptionInput,
};

export type CreateBillingSubscriptionMutation = {
  createBillingSubscription?:  {
    __typename: "BillingSubscription",
    id?: string | null,
    price?:  {
      __typename: "BillingPrice",
      id?: string | null,
      currency?: string | null,
      interval?: string | null,
      amount?: number | null,
    } | null,
    product?:  {
      __typename: "BillingProduct",
      name?: string | null,
      description?: string | null,
      prices?:  Array< {
        __typename: "BillingPrice",
        id?: string | null,
        currency?: string | null,
        interval?: string | null,
        amount?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type UpdateBillingSubscriptionMutationVariables = {
  input: BillingSubscriptionInput,
};

export type UpdateBillingSubscriptionMutation = {
  updateBillingSubscription?:  {
    __typename: "BillingSubscription",
    id?: string | null,
    price?:  {
      __typename: "BillingPrice",
      id?: string | null,
      currency?: string | null,
      interval?: string | null,
      amount?: number | null,
    } | null,
    product?:  {
      __typename: "BillingProduct",
      name?: string | null,
      description?: string | null,
      prices?:  Array< {
        __typename: "BillingPrice",
        id?: string | null,
        currency?: string | null,
        interval?: string | null,
        amount?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type CancelBillingSubscriptionMutation = {
  cancelBillingSubscription?:  {
    __typename: "BillingSubscription",
    id?: string | null,
    price?:  {
      __typename: "BillingPrice",
      id?: string | null,
      currency?: string | null,
      interval?: string | null,
      amount?: number | null,
    } | null,
    product?:  {
      __typename: "BillingProduct",
      name?: string | null,
      description?: string | null,
      prices?:  Array< {
        __typename: "BillingPrice",
        id?: string | null,
        currency?: string | null,
        interval?: string | null,
        amount?: number | null,
      } | null > | null,
    } | null,
  } | null,
};

export type CreateChannelMutationVariables = {
  input: CreateScreensightChannelInput,
  condition?: ModelScreensightChannelConditionInput | null,
};

export type CreateChannelMutation = {
  createChannel?:  {
    __typename: "screensightChannel",
    id: string,
    customerId: string,
    channelType?: string | null,
    name?: string | null,
    categoryPosition?: number | null,
    readHorizon?: string | null,
    activity?:  {
      __typename: "ChannelActivity",
      new?: boolean | null,
      latestAt?: string | null,
      latestPost?:  {
        __typename: "LatestPost",
        id?: string | null,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "LatestPost",
          id?: string | null,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "LatestPost",
            id?: string | null,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "LatestPost",
              id?: string | null,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "LatestPost",
                id?: string | null,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
    chatPartnerId?: string | null,
    chatPartnerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    latestPostAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateChannelMutationVariables = {
  input: UpdateScreensightChannelInput,
  condition?: ModelScreensightChannelConditionInput | null,
};

export type UpdateChannelMutation = {
  updateChannel?:  {
    __typename: "screensightChannel",
    id: string,
    customerId: string,
    channelType?: string | null,
    name?: string | null,
    categoryPosition?: number | null,
    readHorizon?: string | null,
    activity?:  {
      __typename: "ChannelActivity",
      new?: boolean | null,
      latestAt?: string | null,
      latestPost?:  {
        __typename: "LatestPost",
        id?: string | null,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "LatestPost",
          id?: string | null,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "LatestPost",
            id?: string | null,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "LatestPost",
              id?: string | null,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "LatestPost",
                id?: string | null,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
    chatPartnerId?: string | null,
    chatPartnerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    latestPostAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePostMutationVariables = {
  input: CreateScreensightPostInput,
  condition?: ModelScreensightPostConditionInput | null,
};

export type CreatePostMutation = {
  createPost?:  {
    __typename: "screensightPost",
    id: string,
    channelId?: string | null,
    accessId?: string | null,
    customerId?: string | null,
    postType?: string | null,
    video?:  {
      __typename: "screensightVideo",
      id: string,
      createdAt: string,
      customerId: string,
      channelId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title?: string | null,
      searchTitle?: string | null,
      status: VideoStatus,
      fileObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      filePresignedUrl?: string | null,
      duration?: number | null,
      name?: string | null,
      thumbnailObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      accessType?: AccessType | null,
      accessId?: string | null,
      password?: string | null,
      width?: number | null,
      height?: number | null,
      viewsCountTotal?: number | null,
      viewsCountUnique?: number | null,
      viewsList?:  {
        __typename: "ModelscreensightVideoAudienceConnection",
        items:  Array< {
          __typename: "screensightVideoAudience",
          videoId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          guestName?: string | null,
          viewCount?: number | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      commentsCount?: number | null,
      uploadedVideo?:  {
        __typename: "UploadedVideo",
        fileName?: string | null,
        videoContainer?: string | null,
        videoEncoding?: string | null,
      } | null,
      transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
      transcriptionSummaryError?: string | null,
      transcriptionSummary?: string | null,
      transcriptionObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    comment?:  {
      __typename: "screensightVideoComment",
      id: string,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      videoId: string,
      channelId?: string | null,
      parentId?: string | null,
      content?: string | null,
      richContent?: string | null,
      videoTimestamp?: number | null,
      resolved?: boolean | null,
      replies?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      replyCount?: number | null,
      parentCreatedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    emailMessage?:  {
      __typename: "screensightVideoEmailMessage",
      id: string,
      videoId: string,
      customerId?: string | null,
      channelId?: string | null,
      to?: Array< string | null > | null,
      note?: string | null,
      emailStatus?:  Array< {
        __typename: "EmailStatus",
        email?: string | null,
        status?: string | null,
        reject_reason?: string | null,
        _id?: string | null,
        deliveredAt?: string | null,
        opens?: Array< string | null > | null,
      } | null > | null,
      subject?: string | null,
      contacts?:  Array< {
        __typename: "EmailContact",
        email?: string | null,
        contactId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    text?:  {
      __typename: "TextPost",
      content?: string | null,
      richContent?: string | null,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    file?:  {
      __typename: "FilePost",
      fileName?: string | null,
      fileObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      status?: VideoStatus | null,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      mimeType?: string | null,
      metadata?: string | null,
    } | null,
    engagement?:  {
      __typename: "PostEngagementConnection",
      items:  Array< {
        __typename: "PostEngagement",
        customerId?: string | null,
        seen?: boolean | null,
        seenAt?: string | null,
        videoProgress?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    referencePostId?: string | null,
    referencePost?:  {
      __typename: "screensightPost",
      id: string,
      channelId?: string | null,
      accessId?: string | null,
      customerId?: string | null,
      postType?: string | null,
      video?:  {
        __typename: "screensightVideo",
        id: string,
        createdAt: string,
        customerId: string,
        channelId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        title?: string | null,
        searchTitle?: string | null,
        status: VideoStatus,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        filePresignedUrl?: string | null,
        duration?: number | null,
        name?: string | null,
        thumbnailObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        accessType?: AccessType | null,
        accessId?: string | null,
        password?: string | null,
        width?: number | null,
        height?: number | null,
        viewsCountTotal?: number | null,
        viewsCountUnique?: number | null,
        viewsList?:  {
          __typename: "ModelscreensightVideoAudienceConnection",
          items:  Array< {
            __typename: "screensightVideoAudience",
            videoId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            guestName?: string | null,
            viewCount?: number | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        commentsCount?: number | null,
        uploadedVideo?:  {
          __typename: "UploadedVideo",
          fileName?: string | null,
          videoContainer?: string | null,
          videoEncoding?: string | null,
        } | null,
        transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
        transcriptionSummaryError?: string | null,
        transcriptionSummary?: string | null,
        transcriptionObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      comment?:  {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      emailMessage?:  {
        __typename: "screensightVideoEmailMessage",
        id: string,
        videoId: string,
        customerId?: string | null,
        channelId?: string | null,
        to?: Array< string | null > | null,
        note?: string | null,
        emailStatus?:  Array< {
          __typename: "EmailStatus",
          email?: string | null,
          status?: string | null,
          reject_reason?: string | null,
          _id?: string | null,
          deliveredAt?: string | null,
          opens?: Array< string | null > | null,
        } | null > | null,
        subject?: string | null,
        contacts?:  Array< {
          __typename: "EmailContact",
          email?: string | null,
          contactId?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      text?:  {
        __typename: "TextPost",
        content?: string | null,
        richContent?: string | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      file?:  {
        __typename: "FilePost",
        fileName?: string | null,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        status?: VideoStatus | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        mimeType?: string | null,
        metadata?: string | null,
      } | null,
      engagement?:  {
        __typename: "PostEngagementConnection",
        items:  Array< {
          __typename: "PostEngagement",
          customerId?: string | null,
          seen?: boolean | null,
          seenAt?: string | null,
          videoProgress?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      referencePostId?: string | null,
      referencePost?:  {
        __typename: "screensightPost",
        id: string,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        file?:  {
          __typename: "FilePost",
          fileName?: string | null,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          status?: VideoStatus | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          mimeType?: string | null,
          metadata?: string | null,
        } | null,
        engagement?:  {
          __typename: "PostEngagementConnection",
          items:  Array< {
            __typename: "PostEngagement",
            customerId?: string | null,
            seen?: boolean | null,
            seenAt?: string | null,
            videoProgress?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "screensightPost",
          id: string,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          file?:  {
            __typename: "FilePost",
            fileName?: string | null,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            status?: VideoStatus | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            mimeType?: string | null,
            metadata?: string | null,
          } | null,
          engagement?:  {
            __typename: "PostEngagementConnection",
            items:  Array< {
              __typename: "PostEngagement",
              customerId?: string | null,
              seen?: boolean | null,
              seenAt?: string | null,
              videoProgress?: number | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "screensightPost",
            id: string,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            file?:  {
              __typename: "FilePost",
              fileName?: string | null,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              status?: VideoStatus | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              mimeType?: string | null,
              metadata?: string | null,
            } | null,
            engagement?:  {
              __typename: "PostEngagementConnection",
              items:  Array< {
                __typename: "PostEngagement",
                customerId?: string | null,
                seen?: boolean | null,
                seenAt?: string | null,
                videoProgress?: number | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "screensightPost",
              id: string,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              file?:  {
                __typename: "FilePost",
                fileName?: string | null,
                status?: VideoStatus | null,
                customerId?: string | null,
                mimeType?: string | null,
                metadata?: string | null,
              } | null,
              engagement?:  {
                __typename: "PostEngagementConnection",
                nextToken?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "screensightPost",
                id: string,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                readHorizon?: string | null,
                postTempId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              readHorizon?: string | null,
              postTempId?: string | null,
              reactions?:  {
                __typename: "ModelscreensightReactionConnection",
                nextToken?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            readHorizon?: string | null,
            postTempId?: string | null,
            reactions?:  {
              __typename: "ModelscreensightReactionConnection",
              items:  Array< {
                __typename: "screensightReaction",
                postId: string,
                customerId: string,
                unicode: string,
                videoTimestamp: number,
                channelId?: string | null,
                incrementBy?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          readHorizon?: string | null,
          postTempId?: string | null,
          reactions?:  {
            __typename: "ModelscreensightReactionConnection",
            items:  Array< {
              __typename: "screensightReaction",
              postId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              unicode: string,
              videoTimestamp: number,
              channelId?: string | null,
              incrementBy?: number | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        readHorizon?: string | null,
        postTempId?: string | null,
        reactions?:  {
          __typename: "ModelscreensightReactionConnection",
          items:  Array< {
            __typename: "screensightReaction",
            postId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            unicode: string,
            videoTimestamp: number,
            channelId?: string | null,
            incrementBy?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      readHorizon?: string | null,
      postTempId?: string | null,
      reactions?:  {
        __typename: "ModelscreensightReactionConnection",
        items:  Array< {
          __typename: "screensightReaction",
          postId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          unicode: string,
          videoTimestamp: number,
          channelId?: string | null,
          incrementBy?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    readHorizon?: string | null,
    postTempId?: string | null,
    reactions?:  {
      __typename: "ModelscreensightReactionConnection",
      items:  Array< {
        __typename: "screensightReaction",
        postId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        unicode: string,
        videoTimestamp: number,
        channelId?: string | null,
        incrementBy?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdatePostMutationVariables = {
  input: UpdateScreensightPostInput,
  condition?: ModelScreensightPostConditionInput | null,
};

export type UpdatePostMutation = {
  updatePost?:  {
    __typename: "screensightPost",
    id: string,
    channelId?: string | null,
    accessId?: string | null,
    customerId?: string | null,
    postType?: string | null,
    video?:  {
      __typename: "screensightVideo",
      id: string,
      createdAt: string,
      customerId: string,
      channelId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title?: string | null,
      searchTitle?: string | null,
      status: VideoStatus,
      fileObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      filePresignedUrl?: string | null,
      duration?: number | null,
      name?: string | null,
      thumbnailObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      accessType?: AccessType | null,
      accessId?: string | null,
      password?: string | null,
      width?: number | null,
      height?: number | null,
      viewsCountTotal?: number | null,
      viewsCountUnique?: number | null,
      viewsList?:  {
        __typename: "ModelscreensightVideoAudienceConnection",
        items:  Array< {
          __typename: "screensightVideoAudience",
          videoId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          guestName?: string | null,
          viewCount?: number | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      commentsCount?: number | null,
      uploadedVideo?:  {
        __typename: "UploadedVideo",
        fileName?: string | null,
        videoContainer?: string | null,
        videoEncoding?: string | null,
      } | null,
      transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
      transcriptionSummaryError?: string | null,
      transcriptionSummary?: string | null,
      transcriptionObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    comment?:  {
      __typename: "screensightVideoComment",
      id: string,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      videoId: string,
      channelId?: string | null,
      parentId?: string | null,
      content?: string | null,
      richContent?: string | null,
      videoTimestamp?: number | null,
      resolved?: boolean | null,
      replies?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      replyCount?: number | null,
      parentCreatedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    emailMessage?:  {
      __typename: "screensightVideoEmailMessage",
      id: string,
      videoId: string,
      customerId?: string | null,
      channelId?: string | null,
      to?: Array< string | null > | null,
      note?: string | null,
      emailStatus?:  Array< {
        __typename: "EmailStatus",
        email?: string | null,
        status?: string | null,
        reject_reason?: string | null,
        _id?: string | null,
        deliveredAt?: string | null,
        opens?: Array< string | null > | null,
      } | null > | null,
      subject?: string | null,
      contacts?:  Array< {
        __typename: "EmailContact",
        email?: string | null,
        contactId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    text?:  {
      __typename: "TextPost",
      content?: string | null,
      richContent?: string | null,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    file?:  {
      __typename: "FilePost",
      fileName?: string | null,
      fileObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      status?: VideoStatus | null,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      mimeType?: string | null,
      metadata?: string | null,
    } | null,
    engagement?:  {
      __typename: "PostEngagementConnection",
      items:  Array< {
        __typename: "PostEngagement",
        customerId?: string | null,
        seen?: boolean | null,
        seenAt?: string | null,
        videoProgress?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    referencePostId?: string | null,
    referencePost?:  {
      __typename: "screensightPost",
      id: string,
      channelId?: string | null,
      accessId?: string | null,
      customerId?: string | null,
      postType?: string | null,
      video?:  {
        __typename: "screensightVideo",
        id: string,
        createdAt: string,
        customerId: string,
        channelId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        title?: string | null,
        searchTitle?: string | null,
        status: VideoStatus,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        filePresignedUrl?: string | null,
        duration?: number | null,
        name?: string | null,
        thumbnailObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        accessType?: AccessType | null,
        accessId?: string | null,
        password?: string | null,
        width?: number | null,
        height?: number | null,
        viewsCountTotal?: number | null,
        viewsCountUnique?: number | null,
        viewsList?:  {
          __typename: "ModelscreensightVideoAudienceConnection",
          items:  Array< {
            __typename: "screensightVideoAudience",
            videoId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            guestName?: string | null,
            viewCount?: number | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        commentsCount?: number | null,
        uploadedVideo?:  {
          __typename: "UploadedVideo",
          fileName?: string | null,
          videoContainer?: string | null,
          videoEncoding?: string | null,
        } | null,
        transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
        transcriptionSummaryError?: string | null,
        transcriptionSummary?: string | null,
        transcriptionObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      comment?:  {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      emailMessage?:  {
        __typename: "screensightVideoEmailMessage",
        id: string,
        videoId: string,
        customerId?: string | null,
        channelId?: string | null,
        to?: Array< string | null > | null,
        note?: string | null,
        emailStatus?:  Array< {
          __typename: "EmailStatus",
          email?: string | null,
          status?: string | null,
          reject_reason?: string | null,
          _id?: string | null,
          deliveredAt?: string | null,
          opens?: Array< string | null > | null,
        } | null > | null,
        subject?: string | null,
        contacts?:  Array< {
          __typename: "EmailContact",
          email?: string | null,
          contactId?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      text?:  {
        __typename: "TextPost",
        content?: string | null,
        richContent?: string | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      file?:  {
        __typename: "FilePost",
        fileName?: string | null,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        status?: VideoStatus | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        mimeType?: string | null,
        metadata?: string | null,
      } | null,
      engagement?:  {
        __typename: "PostEngagementConnection",
        items:  Array< {
          __typename: "PostEngagement",
          customerId?: string | null,
          seen?: boolean | null,
          seenAt?: string | null,
          videoProgress?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      referencePostId?: string | null,
      referencePost?:  {
        __typename: "screensightPost",
        id: string,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        file?:  {
          __typename: "FilePost",
          fileName?: string | null,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          status?: VideoStatus | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          mimeType?: string | null,
          metadata?: string | null,
        } | null,
        engagement?:  {
          __typename: "PostEngagementConnection",
          items:  Array< {
            __typename: "PostEngagement",
            customerId?: string | null,
            seen?: boolean | null,
            seenAt?: string | null,
            videoProgress?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "screensightPost",
          id: string,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          file?:  {
            __typename: "FilePost",
            fileName?: string | null,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            status?: VideoStatus | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            mimeType?: string | null,
            metadata?: string | null,
          } | null,
          engagement?:  {
            __typename: "PostEngagementConnection",
            items:  Array< {
              __typename: "PostEngagement",
              customerId?: string | null,
              seen?: boolean | null,
              seenAt?: string | null,
              videoProgress?: number | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "screensightPost",
            id: string,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            file?:  {
              __typename: "FilePost",
              fileName?: string | null,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              status?: VideoStatus | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              mimeType?: string | null,
              metadata?: string | null,
            } | null,
            engagement?:  {
              __typename: "PostEngagementConnection",
              items:  Array< {
                __typename: "PostEngagement",
                customerId?: string | null,
                seen?: boolean | null,
                seenAt?: string | null,
                videoProgress?: number | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "screensightPost",
              id: string,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              file?:  {
                __typename: "FilePost",
                fileName?: string | null,
                status?: VideoStatus | null,
                customerId?: string | null,
                mimeType?: string | null,
                metadata?: string | null,
              } | null,
              engagement?:  {
                __typename: "PostEngagementConnection",
                nextToken?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "screensightPost",
                id: string,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                readHorizon?: string | null,
                postTempId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              readHorizon?: string | null,
              postTempId?: string | null,
              reactions?:  {
                __typename: "ModelscreensightReactionConnection",
                nextToken?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            readHorizon?: string | null,
            postTempId?: string | null,
            reactions?:  {
              __typename: "ModelscreensightReactionConnection",
              items:  Array< {
                __typename: "screensightReaction",
                postId: string,
                customerId: string,
                unicode: string,
                videoTimestamp: number,
                channelId?: string | null,
                incrementBy?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          readHorizon?: string | null,
          postTempId?: string | null,
          reactions?:  {
            __typename: "ModelscreensightReactionConnection",
            items:  Array< {
              __typename: "screensightReaction",
              postId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              unicode: string,
              videoTimestamp: number,
              channelId?: string | null,
              incrementBy?: number | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        readHorizon?: string | null,
        postTempId?: string | null,
        reactions?:  {
          __typename: "ModelscreensightReactionConnection",
          items:  Array< {
            __typename: "screensightReaction",
            postId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            unicode: string,
            videoTimestamp: number,
            channelId?: string | null,
            incrementBy?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      readHorizon?: string | null,
      postTempId?: string | null,
      reactions?:  {
        __typename: "ModelscreensightReactionConnection",
        items:  Array< {
          __typename: "screensightReaction",
          postId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          unicode: string,
          videoTimestamp: number,
          channelId?: string | null,
          incrementBy?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    readHorizon?: string | null,
    postTempId?: string | null,
    reactions?:  {
      __typename: "ModelscreensightReactionConnection",
      items:  Array< {
        __typename: "screensightReaction",
        postId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        unicode: string,
        videoTimestamp: number,
        channelId?: string | null,
        incrementBy?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeletePostMutationVariables = {
  input: DeleteScreensightPostInput,
  condition?: ModelScreensightPostConditionInput | null,
};

export type DeletePostMutation = {
  deletePost?:  {
    __typename: "screensightPost",
    id: string,
    channelId?: string | null,
    accessId?: string | null,
    customerId?: string | null,
    postType?: string | null,
    video?:  {
      __typename: "screensightVideo",
      id: string,
      createdAt: string,
      customerId: string,
      channelId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title?: string | null,
      searchTitle?: string | null,
      status: VideoStatus,
      fileObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      filePresignedUrl?: string | null,
      duration?: number | null,
      name?: string | null,
      thumbnailObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      accessType?: AccessType | null,
      accessId?: string | null,
      password?: string | null,
      width?: number | null,
      height?: number | null,
      viewsCountTotal?: number | null,
      viewsCountUnique?: number | null,
      viewsList?:  {
        __typename: "ModelscreensightVideoAudienceConnection",
        items:  Array< {
          __typename: "screensightVideoAudience",
          videoId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          guestName?: string | null,
          viewCount?: number | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      commentsCount?: number | null,
      uploadedVideo?:  {
        __typename: "UploadedVideo",
        fileName?: string | null,
        videoContainer?: string | null,
        videoEncoding?: string | null,
      } | null,
      transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
      transcriptionSummaryError?: string | null,
      transcriptionSummary?: string | null,
      transcriptionObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    comment?:  {
      __typename: "screensightVideoComment",
      id: string,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      videoId: string,
      channelId?: string | null,
      parentId?: string | null,
      content?: string | null,
      richContent?: string | null,
      videoTimestamp?: number | null,
      resolved?: boolean | null,
      replies?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      replyCount?: number | null,
      parentCreatedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    emailMessage?:  {
      __typename: "screensightVideoEmailMessage",
      id: string,
      videoId: string,
      customerId?: string | null,
      channelId?: string | null,
      to?: Array< string | null > | null,
      note?: string | null,
      emailStatus?:  Array< {
        __typename: "EmailStatus",
        email?: string | null,
        status?: string | null,
        reject_reason?: string | null,
        _id?: string | null,
        deliveredAt?: string | null,
        opens?: Array< string | null > | null,
      } | null > | null,
      subject?: string | null,
      contacts?:  Array< {
        __typename: "EmailContact",
        email?: string | null,
        contactId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    text?:  {
      __typename: "TextPost",
      content?: string | null,
      richContent?: string | null,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    file?:  {
      __typename: "FilePost",
      fileName?: string | null,
      fileObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      status?: VideoStatus | null,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      mimeType?: string | null,
      metadata?: string | null,
    } | null,
    engagement?:  {
      __typename: "PostEngagementConnection",
      items:  Array< {
        __typename: "PostEngagement",
        customerId?: string | null,
        seen?: boolean | null,
        seenAt?: string | null,
        videoProgress?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    referencePostId?: string | null,
    referencePost?:  {
      __typename: "screensightPost",
      id: string,
      channelId?: string | null,
      accessId?: string | null,
      customerId?: string | null,
      postType?: string | null,
      video?:  {
        __typename: "screensightVideo",
        id: string,
        createdAt: string,
        customerId: string,
        channelId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        title?: string | null,
        searchTitle?: string | null,
        status: VideoStatus,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        filePresignedUrl?: string | null,
        duration?: number | null,
        name?: string | null,
        thumbnailObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        accessType?: AccessType | null,
        accessId?: string | null,
        password?: string | null,
        width?: number | null,
        height?: number | null,
        viewsCountTotal?: number | null,
        viewsCountUnique?: number | null,
        viewsList?:  {
          __typename: "ModelscreensightVideoAudienceConnection",
          items:  Array< {
            __typename: "screensightVideoAudience",
            videoId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            guestName?: string | null,
            viewCount?: number | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        commentsCount?: number | null,
        uploadedVideo?:  {
          __typename: "UploadedVideo",
          fileName?: string | null,
          videoContainer?: string | null,
          videoEncoding?: string | null,
        } | null,
        transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
        transcriptionSummaryError?: string | null,
        transcriptionSummary?: string | null,
        transcriptionObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      comment?:  {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      emailMessage?:  {
        __typename: "screensightVideoEmailMessage",
        id: string,
        videoId: string,
        customerId?: string | null,
        channelId?: string | null,
        to?: Array< string | null > | null,
        note?: string | null,
        emailStatus?:  Array< {
          __typename: "EmailStatus",
          email?: string | null,
          status?: string | null,
          reject_reason?: string | null,
          _id?: string | null,
          deliveredAt?: string | null,
          opens?: Array< string | null > | null,
        } | null > | null,
        subject?: string | null,
        contacts?:  Array< {
          __typename: "EmailContact",
          email?: string | null,
          contactId?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      text?:  {
        __typename: "TextPost",
        content?: string | null,
        richContent?: string | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      file?:  {
        __typename: "FilePost",
        fileName?: string | null,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        status?: VideoStatus | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        mimeType?: string | null,
        metadata?: string | null,
      } | null,
      engagement?:  {
        __typename: "PostEngagementConnection",
        items:  Array< {
          __typename: "PostEngagement",
          customerId?: string | null,
          seen?: boolean | null,
          seenAt?: string | null,
          videoProgress?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      referencePostId?: string | null,
      referencePost?:  {
        __typename: "screensightPost",
        id: string,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        file?:  {
          __typename: "FilePost",
          fileName?: string | null,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          status?: VideoStatus | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          mimeType?: string | null,
          metadata?: string | null,
        } | null,
        engagement?:  {
          __typename: "PostEngagementConnection",
          items:  Array< {
            __typename: "PostEngagement",
            customerId?: string | null,
            seen?: boolean | null,
            seenAt?: string | null,
            videoProgress?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "screensightPost",
          id: string,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          file?:  {
            __typename: "FilePost",
            fileName?: string | null,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            status?: VideoStatus | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            mimeType?: string | null,
            metadata?: string | null,
          } | null,
          engagement?:  {
            __typename: "PostEngagementConnection",
            items:  Array< {
              __typename: "PostEngagement",
              customerId?: string | null,
              seen?: boolean | null,
              seenAt?: string | null,
              videoProgress?: number | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "screensightPost",
            id: string,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            file?:  {
              __typename: "FilePost",
              fileName?: string | null,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              status?: VideoStatus | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              mimeType?: string | null,
              metadata?: string | null,
            } | null,
            engagement?:  {
              __typename: "PostEngagementConnection",
              items:  Array< {
                __typename: "PostEngagement",
                customerId?: string | null,
                seen?: boolean | null,
                seenAt?: string | null,
                videoProgress?: number | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "screensightPost",
              id: string,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              file?:  {
                __typename: "FilePost",
                fileName?: string | null,
                status?: VideoStatus | null,
                customerId?: string | null,
                mimeType?: string | null,
                metadata?: string | null,
              } | null,
              engagement?:  {
                __typename: "PostEngagementConnection",
                nextToken?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "screensightPost",
                id: string,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                readHorizon?: string | null,
                postTempId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              readHorizon?: string | null,
              postTempId?: string | null,
              reactions?:  {
                __typename: "ModelscreensightReactionConnection",
                nextToken?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            readHorizon?: string | null,
            postTempId?: string | null,
            reactions?:  {
              __typename: "ModelscreensightReactionConnection",
              items:  Array< {
                __typename: "screensightReaction",
                postId: string,
                customerId: string,
                unicode: string,
                videoTimestamp: number,
                channelId?: string | null,
                incrementBy?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          readHorizon?: string | null,
          postTempId?: string | null,
          reactions?:  {
            __typename: "ModelscreensightReactionConnection",
            items:  Array< {
              __typename: "screensightReaction",
              postId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              unicode: string,
              videoTimestamp: number,
              channelId?: string | null,
              incrementBy?: number | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        readHorizon?: string | null,
        postTempId?: string | null,
        reactions?:  {
          __typename: "ModelscreensightReactionConnection",
          items:  Array< {
            __typename: "screensightReaction",
            postId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            unicode: string,
            videoTimestamp: number,
            channelId?: string | null,
            incrementBy?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      readHorizon?: string | null,
      postTempId?: string | null,
      reactions?:  {
        __typename: "ModelscreensightReactionConnection",
        items:  Array< {
          __typename: "screensightReaction",
          postId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          unicode: string,
          videoTimestamp: number,
          channelId?: string | null,
          incrementBy?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    readHorizon?: string | null,
    postTempId?: string | null,
    reactions?:  {
      __typename: "ModelscreensightReactionConnection",
      items:  Array< {
        __typename: "screensightReaction",
        postId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        unicode: string,
        videoTimestamp: number,
        channelId?: string | null,
        incrementBy?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateVideoMutationVariables = {
  input: CreateScreensightVideoInput,
  condition?: ModelScreensightVideoConditionInput | null,
};

export type CreateVideoMutation = {
  createVideo?:  {
    __typename: "screensightVideo",
    id: string,
    createdAt: string,
    customerId: string,
    channelId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title?: string | null,
    searchTitle?: string | null,
    status: VideoStatus,
    fileObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    filePresignedUrl?: string | null,
    duration?: number | null,
    name?: string | null,
    thumbnailObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    accessType?: AccessType | null,
    accessId?: string | null,
    password?: string | null,
    width?: number | null,
    height?: number | null,
    viewsCountTotal?: number | null,
    viewsCountUnique?: number | null,
    viewsList?:  {
      __typename: "ModelscreensightVideoAudienceConnection",
      items:  Array< {
        __typename: "screensightVideoAudience",
        videoId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        guestName?: string | null,
        viewCount?: number | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    commentsCount?: number | null,
    uploadedVideo?:  {
      __typename: "UploadedVideo",
      fileName?: string | null,
      videoContainer?: string | null,
      videoEncoding?: string | null,
    } | null,
    transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
    transcriptionSummaryError?: string | null,
    transcriptionSummary?: string | null,
    transcriptionObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateVideoMutationVariables = {
  input: UpdateScreensightVideoInput,
  condition?: ModelScreensightVideoConditionInput | null,
};

export type UpdateVideoMutation = {
  updateVideo?:  {
    __typename: "screensightVideo",
    id: string,
    createdAt: string,
    customerId: string,
    channelId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title?: string | null,
    searchTitle?: string | null,
    status: VideoStatus,
    fileObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    filePresignedUrl?: string | null,
    duration?: number | null,
    name?: string | null,
    thumbnailObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    accessType?: AccessType | null,
    accessId?: string | null,
    password?: string | null,
    width?: number | null,
    height?: number | null,
    viewsCountTotal?: number | null,
    viewsCountUnique?: number | null,
    viewsList?:  {
      __typename: "ModelscreensightVideoAudienceConnection",
      items:  Array< {
        __typename: "screensightVideoAudience",
        videoId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        guestName?: string | null,
        viewCount?: number | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    commentsCount?: number | null,
    uploadedVideo?:  {
      __typename: "UploadedVideo",
      fileName?: string | null,
      videoContainer?: string | null,
      videoEncoding?: string | null,
    } | null,
    transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
    transcriptionSummaryError?: string | null,
    transcriptionSummary?: string | null,
    transcriptionObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteVideoMutationVariables = {
  input: DeleteScreensightVideoInput,
  condition?: ModelScreensightVideoConditionInput | null,
};

export type DeleteVideoMutation = {
  deleteVideo?:  {
    __typename: "screensightVideo",
    id: string,
    createdAt: string,
    customerId: string,
    channelId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title?: string | null,
    searchTitle?: string | null,
    status: VideoStatus,
    fileObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    filePresignedUrl?: string | null,
    duration?: number | null,
    name?: string | null,
    thumbnailObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    accessType?: AccessType | null,
    accessId?: string | null,
    password?: string | null,
    width?: number | null,
    height?: number | null,
    viewsCountTotal?: number | null,
    viewsCountUnique?: number | null,
    viewsList?:  {
      __typename: "ModelscreensightVideoAudienceConnection",
      items:  Array< {
        __typename: "screensightVideoAudience",
        videoId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        guestName?: string | null,
        viewCount?: number | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    commentsCount?: number | null,
    uploadedVideo?:  {
      __typename: "UploadedVideo",
      fileName?: string | null,
      videoContainer?: string | null,
      videoEncoding?: string | null,
    } | null,
    transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
    transcriptionSummaryError?: string | null,
    transcriptionSummary?: string | null,
    transcriptionObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type SetVideoViewedByMutationVariables = {
  input?: SetVideoViewedInput | null,
};

export type SetVideoViewedByMutation = {
  setVideoViewedBy?:  {
    __typename: "screensightVideo",
    id: string,
    createdAt: string,
    customerId: string,
    channelId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title?: string | null,
    searchTitle?: string | null,
    status: VideoStatus,
    fileObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    filePresignedUrl?: string | null,
    duration?: number | null,
    name?: string | null,
    thumbnailObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    accessType?: AccessType | null,
    accessId?: string | null,
    password?: string | null,
    width?: number | null,
    height?: number | null,
    viewsCountTotal?: number | null,
    viewsCountUnique?: number | null,
    viewsList?:  {
      __typename: "ModelscreensightVideoAudienceConnection",
      items:  Array< {
        __typename: "screensightVideoAudience",
        videoId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        guestName?: string | null,
        viewCount?: number | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    commentsCount?: number | null,
    uploadedVideo?:  {
      __typename: "UploadedVideo",
      fileName?: string | null,
      videoContainer?: string | null,
      videoEncoding?: string | null,
    } | null,
    transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
    transcriptionSummaryError?: string | null,
    transcriptionSummary?: string | null,
    transcriptionObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateVideoCommentMutationVariables = {
  input: CreateScreensightVideoCommentInput,
  condition?: ModelScreensightVideoCommentConditionInput | null,
};

export type CreateVideoCommentMutation = {
  createVideoComment?:  {
    __typename: "screensightVideoComment",
    id: string,
    customerId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    videoId: string,
    channelId?: string | null,
    parentId?: string | null,
    content?: string | null,
    richContent?: string | null,
    videoTimestamp?: number | null,
    resolved?: boolean | null,
    replies?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    replyCount?: number | null,
    parentCreatedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateVideoCommentMutationVariables = {
  input: UpdateScreensightVideoCommentInput,
  condition?: ModelScreensightVideoCommentConditionInput | null,
};

export type UpdateVideoCommentMutation = {
  updateVideoComment?:  {
    __typename: "screensightVideoComment",
    id: string,
    customerId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    videoId: string,
    channelId?: string | null,
    parentId?: string | null,
    content?: string | null,
    richContent?: string | null,
    videoTimestamp?: number | null,
    resolved?: boolean | null,
    replies?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    replyCount?: number | null,
    parentCreatedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteVideoCommentMutationVariables = {
  input: DeleteScreensightVideoCommentInput,
  condition?: ModelScreensightVideoCommentConditionInput | null,
};

export type DeleteVideoCommentMutation = {
  deleteVideoComment?:  {
    __typename: "screensightVideoComment",
    id: string,
    customerId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    videoId: string,
    channelId?: string | null,
    parentId?: string | null,
    content?: string | null,
    richContent?: string | null,
    videoTimestamp?: number | null,
    resolved?: boolean | null,
    replies?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    replyCount?: number | null,
    parentCreatedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateEmailMessageMutationVariables = {
  input: CreateScreensightVideoEmailMessageInput,
  condition?: ModelScreensightVideoEmailMessageConditionInput | null,
};

export type CreateEmailMessageMutation = {
  createEmailMessage?:  {
    __typename: "screensightVideoEmailMessage",
    id: string,
    videoId: string,
    customerId?: string | null,
    channelId?: string | null,
    to?: Array< string | null > | null,
    note?: string | null,
    emailStatus?:  Array< {
      __typename: "EmailStatus",
      email?: string | null,
      status?: string | null,
      reject_reason?: string | null,
      _id?: string | null,
      deliveredAt?: string | null,
      opens?: Array< string | null > | null,
    } | null > | null,
    subject?: string | null,
    contacts?:  Array< {
      __typename: "EmailContact",
      email?: string | null,
      contactId?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEmailInThreadsMutationVariables = {
  input: CreateScreensightVideoEmailMessageInput,
};

export type CreateEmailInThreadsMutation = {
  createEmailInThreads?:  {
    __typename: "ExecutionStatus",
    executionArn: string,
    startDate: number,
  } | null,
};

export type CreateReactionMutationVariables = {
  input: CreateScreensightReactionInput,
  condition?: ModelScreensightReactionConditionInput | null,
};

export type CreateReactionMutation = {
  createReaction?:  {
    __typename: "screensightReaction",
    postId: string,
    customerId: string,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    unicode: string,
    videoTimestamp: number,
    channelId?: string | null,
    incrementBy?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteReactionMutationVariables = {
  input: DeleteScreensightReactionInput,
  condition?: ModelScreensightReactionConditionInput | null,
};

export type DeleteReactionMutation = {
  deleteReaction?:  {
    __typename: "screensightReaction",
    postId: string,
    customerId: string,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    unicode: string,
    videoTimestamp: number,
    channelId?: string | null,
    incrementBy?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type SendPostEventMutationVariables = {
  input?: SendPostEventInput | null,
};

export type SendPostEventMutation = {
  sendPostEvent?:  {
    __typename: "PostEvent",
    customerId: string,
    channelId?: string | null,
    action: string,
    post?:  {
      __typename: "screensightPost",
      id: string,
      channelId?: string | null,
      accessId?: string | null,
      customerId?: string | null,
      postType?: string | null,
      video?:  {
        __typename: "screensightVideo",
        id: string,
        createdAt: string,
        customerId: string,
        channelId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        title?: string | null,
        searchTitle?: string | null,
        status: VideoStatus,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        filePresignedUrl?: string | null,
        duration?: number | null,
        name?: string | null,
        thumbnailObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        accessType?: AccessType | null,
        accessId?: string | null,
        password?: string | null,
        width?: number | null,
        height?: number | null,
        viewsCountTotal?: number | null,
        viewsCountUnique?: number | null,
        viewsList?:  {
          __typename: "ModelscreensightVideoAudienceConnection",
          items:  Array< {
            __typename: "screensightVideoAudience",
            videoId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            guestName?: string | null,
            viewCount?: number | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        commentsCount?: number | null,
        uploadedVideo?:  {
          __typename: "UploadedVideo",
          fileName?: string | null,
          videoContainer?: string | null,
          videoEncoding?: string | null,
        } | null,
        transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
        transcriptionSummaryError?: string | null,
        transcriptionSummary?: string | null,
        transcriptionObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      comment?:  {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      emailMessage?:  {
        __typename: "screensightVideoEmailMessage",
        id: string,
        videoId: string,
        customerId?: string | null,
        channelId?: string | null,
        to?: Array< string | null > | null,
        note?: string | null,
        emailStatus?:  Array< {
          __typename: "EmailStatus",
          email?: string | null,
          status?: string | null,
          reject_reason?: string | null,
          _id?: string | null,
          deliveredAt?: string | null,
          opens?: Array< string | null > | null,
        } | null > | null,
        subject?: string | null,
        contacts?:  Array< {
          __typename: "EmailContact",
          email?: string | null,
          contactId?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      text?:  {
        __typename: "TextPost",
        content?: string | null,
        richContent?: string | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      file?:  {
        __typename: "FilePost",
        fileName?: string | null,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        status?: VideoStatus | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        mimeType?: string | null,
        metadata?: string | null,
      } | null,
      engagement?:  {
        __typename: "PostEngagementConnection",
        items:  Array< {
          __typename: "PostEngagement",
          customerId?: string | null,
          seen?: boolean | null,
          seenAt?: string | null,
          videoProgress?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      referencePostId?: string | null,
      referencePost?:  {
        __typename: "screensightPost",
        id: string,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        file?:  {
          __typename: "FilePost",
          fileName?: string | null,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          status?: VideoStatus | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          mimeType?: string | null,
          metadata?: string | null,
        } | null,
        engagement?:  {
          __typename: "PostEngagementConnection",
          items:  Array< {
            __typename: "PostEngagement",
            customerId?: string | null,
            seen?: boolean | null,
            seenAt?: string | null,
            videoProgress?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "screensightPost",
          id: string,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          file?:  {
            __typename: "FilePost",
            fileName?: string | null,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            status?: VideoStatus | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            mimeType?: string | null,
            metadata?: string | null,
          } | null,
          engagement?:  {
            __typename: "PostEngagementConnection",
            items:  Array< {
              __typename: "PostEngagement",
              customerId?: string | null,
              seen?: boolean | null,
              seenAt?: string | null,
              videoProgress?: number | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "screensightPost",
            id: string,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            file?:  {
              __typename: "FilePost",
              fileName?: string | null,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              status?: VideoStatus | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              mimeType?: string | null,
              metadata?: string | null,
            } | null,
            engagement?:  {
              __typename: "PostEngagementConnection",
              items:  Array< {
                __typename: "PostEngagement",
                customerId?: string | null,
                seen?: boolean | null,
                seenAt?: string | null,
                videoProgress?: number | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "screensightPost",
              id: string,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              file?:  {
                __typename: "FilePost",
                fileName?: string | null,
                status?: VideoStatus | null,
                customerId?: string | null,
                mimeType?: string | null,
                metadata?: string | null,
              } | null,
              engagement?:  {
                __typename: "PostEngagementConnection",
                nextToken?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "screensightPost",
                id: string,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                readHorizon?: string | null,
                postTempId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              readHorizon?: string | null,
              postTempId?: string | null,
              reactions?:  {
                __typename: "ModelscreensightReactionConnection",
                nextToken?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            readHorizon?: string | null,
            postTempId?: string | null,
            reactions?:  {
              __typename: "ModelscreensightReactionConnection",
              items:  Array< {
                __typename: "screensightReaction",
                postId: string,
                customerId: string,
                unicode: string,
                videoTimestamp: number,
                channelId?: string | null,
                incrementBy?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          readHorizon?: string | null,
          postTempId?: string | null,
          reactions?:  {
            __typename: "ModelscreensightReactionConnection",
            items:  Array< {
              __typename: "screensightReaction",
              postId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              unicode: string,
              videoTimestamp: number,
              channelId?: string | null,
              incrementBy?: number | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        readHorizon?: string | null,
        postTempId?: string | null,
        reactions?:  {
          __typename: "ModelscreensightReactionConnection",
          items:  Array< {
            __typename: "screensightReaction",
            postId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            unicode: string,
            videoTimestamp: number,
            channelId?: string | null,
            incrementBy?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      readHorizon?: string | null,
      postTempId?: string | null,
      reactions?:  {
        __typename: "ModelscreensightReactionConnection",
        items:  Array< {
          __typename: "screensightReaction",
          postId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          unicode: string,
          videoTimestamp: number,
          channelId?: string | null,
          incrementBy?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    videosLimitReached?: boolean | null,
  } | null,
};

export type SetPostsSeenMutationVariables = {
  input: SetPostsSeenInput,
};

export type SetPostsSeenMutation = {
  setPostsSeen?:  {
    __typename: "PostsSeen",
    customerId?: string | null,
    channelId?: string | null,
    timestamp?: string | null,
    source?: string | null,
    postIds?: Array< string | null > | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type PublishCustomerChangesMutationVariables = {
  input: PublishCustomerChangesInput,
};

export type PublishCustomerChangesMutation = {
  publishCustomerChanges?:  {
    __typename: "PostsSeen",
    customerId?: string | null,
    channelId?: string | null,
    timestamp?: string | null,
    source?: string | null,
    postIds?: Array< string | null > | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateGuestCustomerMutationVariables = {
  input: CreateScreensightCustomerInput,
  condition?: ModelScreensightCustomerConditionInput | null,
};

export type CreateGuestCustomerMutation = {
  createGuestCustomer?:  {
    __typename: "screensightCustomer",
    id: string,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    profilePhoto?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    brandLogo?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    calendlyUrl?: string | null,
    lastSeen?: string | null,
    settings?:  {
      __typename: "CustomerSettings",
      notifyEmailWatched: boolean,
      notifyEmailComment: boolean,
      notifyUnseenMessages?: boolean | null,
      timezone?: string | null,
      pushNotifications?:  {
        __typename: "PushNotificationSettings",
        video?: boolean | null,
        comment?: boolean | null,
        reply?: boolean | null,
        text?: boolean | null,
      } | null,
      lastChannel?: string | null,
      enableSplashScreen?: boolean | null,
      termsAndConditions?:  {
        __typename: "TermsAndConditions",
        version: string,
        accepted: boolean,
        acceptedAt?: string | null,
      } | null,
      channelRecentActivityDays?: number | null,
    } | null,
    stripeId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input: UpdateScreensightCustomerInput,
  condition?: ModelScreensightCustomerConditionInput | null,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "screensightCustomer",
    id: string,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    profilePhoto?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    brandLogo?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    calendlyUrl?: string | null,
    lastSeen?: string | null,
    settings?:  {
      __typename: "CustomerSettings",
      notifyEmailWatched: boolean,
      notifyEmailComment: boolean,
      notifyUnseenMessages?: boolean | null,
      timezone?: string | null,
      pushNotifications?:  {
        __typename: "PushNotificationSettings",
        video?: boolean | null,
        comment?: boolean | null,
        reply?: boolean | null,
        text?: boolean | null,
      } | null,
      lastChannel?: string | null,
      enableSplashScreen?: boolean | null,
      termsAndConditions?:  {
        __typename: "TermsAndConditions",
        version: string,
        accepted: boolean,
        acceptedAt?: string | null,
      } | null,
      channelRecentActivityDays?: number | null,
    } | null,
    stripeId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateContactMutationVariables = {
  input: CreateScreensightContactInput,
  condition?: ModelScreensightContactConditionInput | null,
};

export type CreateContactMutation = {
  createContact?:  {
    __typename: "screensightContact",
    id: string,
    ownerCustomerId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    mobilePhone?: string | null,
    contactCustomerMeta?:  {
      __typename: "ModelscreensightCustomerConnection",
      items:  Array< {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContactMutationVariables = {
  input: UpdateScreensightContactInput,
  condition?: ModelScreensightContactConditionInput | null,
};

export type UpdateContactMutation = {
  updateContact?:  {
    __typename: "screensightContact",
    id: string,
    ownerCustomerId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    mobilePhone?: string | null,
    contactCustomerMeta?:  {
      __typename: "ModelscreensightCustomerConnection",
      items:  Array< {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContactMutationVariables = {
  input: DeleteScreensightContactInput,
  condition?: ModelScreensightContactConditionInput | null,
};

export type DeleteContactMutation = {
  deleteContact?:  {
    __typename: "screensightContact",
    id: string,
    ownerCustomerId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    mobilePhone?: string | null,
    contactCustomerMeta?:  {
      __typename: "ModelscreensightCustomerConnection",
      items:  Array< {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetCustomerVideoLimitsDataQueryVariables = {
  customerId: string,
};

export type GetCustomerVideoLimitsDataQuery = {
  getCustomerVideoLimitsData?:  {
    __typename: "CustomerVideoLimitsData",
    maxVideosLimitReached?: boolean | null,
    maxVideosLimitReachedTimesFor30days?: number | null,
  } | null,
};

export type GetApplicationConfigurationQuery = {
  getApplicationConfiguration?:  {
    __typename: "ApplicationConfiguration",
    videoPlayerEventsFirehose?: string | null,
    termsAndConditionsVersion?: string | null,
    appVersionEndOfLife?: number | null,
    appVersionMinimum?: number | null,
    maintenanceMode?: boolean | null,
    featureFlags?:  {
      __typename: "FeatureFlags",
      id: string,
      maxVideosLimit?: number | null,
      maxVideoDurationLimit?: number | null,
    } | null,
  } | null,
};

export type ListPricePlansQuery = {
  listPricePlans?:  Array< {
    __typename: "BillingProduct",
    name?: string | null,
    description?: string | null,
    prices?:  Array< {
      __typename: "BillingPrice",
      id?: string | null,
      currency?: string | null,
      interval?: string | null,
      amount?: number | null,
    } | null > | null,
  } | null > | null,
};

export type GetBillingApiAccessKeyQuery = {
  getBillingApiAccessKey?:  {
    __typename: "BillingSession",
    AccessKeyId?: string | null,
    SecretAccessKey?: string | null,
    SessionToken?: string | null,
    Expiration?: string | null,
    AwsRegion?: string | null,
    ServiceName?: string | null,
  } | null,
};

export type GetBillingInformationQuery = {
  getBillingInformation?:  {
    __typename: "BillingInformation",
    paymentMethod?:  {
      __typename: "PaymentMethod",
      brand?: string | null,
      nameOnCard?: string | null,
      expirationMonth?: number | null,
      expirationYear?: number | null,
      lastFourDigits?: string | null,
    } | null,
    subscription?:  {
      __typename: "BillingSubscription",
      id?: string | null,
      price?:  {
        __typename: "BillingPrice",
        id?: string | null,
        currency?: string | null,
        interval?: string | null,
        amount?: number | null,
      } | null,
      product?:  {
        __typename: "BillingProduct",
        name?: string | null,
        description?: string | null,
        prices?:  Array< {
          __typename: "BillingPrice",
          id?: string | null,
          currency?: string | null,
          interval?: string | null,
          amount?: number | null,
        } | null > | null,
      } | null,
    } | null,
  } | null,
};

export type GetVideoQueryVariables = {
  id: string,
};

export type GetVideoQuery = {
  getVideo?:  {
    __typename: "screensightVideo",
    id: string,
    createdAt: string,
    customerId: string,
    channelId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title?: string | null,
    searchTitle?: string | null,
    status: VideoStatus,
    fileObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    filePresignedUrl?: string | null,
    duration?: number | null,
    name?: string | null,
    thumbnailObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    accessType?: AccessType | null,
    accessId?: string | null,
    password?: string | null,
    width?: number | null,
    height?: number | null,
    viewsCountTotal?: number | null,
    viewsCountUnique?: number | null,
    viewsList?:  {
      __typename: "ModelscreensightVideoAudienceConnection",
      items:  Array< {
        __typename: "screensightVideoAudience",
        videoId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        guestName?: string | null,
        viewCount?: number | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    commentsCount?: number | null,
    uploadedVideo?:  {
      __typename: "UploadedVideo",
      fileName?: string | null,
      videoContainer?: string | null,
      videoEncoding?: string | null,
    } | null,
    transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
    transcriptionSummaryError?: string | null,
    transcriptionSummary?: string | null,
    transcriptionObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListVideosByOwnerQueryVariables = {
  customerId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelscreensightVideoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVideosByOwnerQuery = {
  listVideosByOwner?:  {
    __typename: "ModelscreensightVideoConnection",
    items:  Array< {
      __typename: "screensightVideo",
      id: string,
      createdAt: string,
      customerId: string,
      channelId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title?: string | null,
      searchTitle?: string | null,
      status: VideoStatus,
      fileObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      filePresignedUrl?: string | null,
      duration?: number | null,
      name?: string | null,
      thumbnailObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      accessType?: AccessType | null,
      accessId?: string | null,
      password?: string | null,
      width?: number | null,
      height?: number | null,
      viewsCountTotal?: number | null,
      viewsCountUnique?: number | null,
      viewsList?:  {
        __typename: "ModelscreensightVideoAudienceConnection",
        items:  Array< {
          __typename: "screensightVideoAudience",
          videoId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          guestName?: string | null,
          viewCount?: number | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      commentsCount?: number | null,
      uploadedVideo?:  {
        __typename: "UploadedVideo",
        fileName?: string | null,
        videoContainer?: string | null,
        videoEncoding?: string | null,
      } | null,
      transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
      transcriptionSummaryError?: string | null,
      transcriptionSummary?: string | null,
      transcriptionObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VerifyProtectedVideoPasswordQueryVariables = {
  accessId: string,
  password: string,
};

export type VerifyProtectedVideoPasswordQuery = {
  verifyProtectedVideoPassword?:  {
    __typename: "ProtectedVideo",
    accessToken?: string | null,
    video?:  {
      __typename: "screensightVideo",
      id: string,
      createdAt: string,
      customerId: string,
      channelId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title?: string | null,
      searchTitle?: string | null,
      status: VideoStatus,
      fileObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      filePresignedUrl?: string | null,
      duration?: number | null,
      name?: string | null,
      thumbnailObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      accessType?: AccessType | null,
      accessId?: string | null,
      password?: string | null,
      width?: number | null,
      height?: number | null,
      viewsCountTotal?: number | null,
      viewsCountUnique?: number | null,
      viewsList?:  {
        __typename: "ModelscreensightVideoAudienceConnection",
        items:  Array< {
          __typename: "screensightVideoAudience",
          videoId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          guestName?: string | null,
          viewCount?: number | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      commentsCount?: number | null,
      uploadedVideo?:  {
        __typename: "UploadedVideo",
        fileName?: string | null,
        videoContainer?: string | null,
        videoEncoding?: string | null,
      } | null,
      transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
      transcriptionSummaryError?: string | null,
      transcriptionSummary?: string | null,
      transcriptionObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type AccessProtectedVideoQueryVariables = {
  accessId: string,
  accessToken: string,
};

export type AccessProtectedVideoQuery = {
  accessProtectedVideo?:  {
    __typename: "screensightVideo",
    id: string,
    createdAt: string,
    customerId: string,
    channelId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title?: string | null,
    searchTitle?: string | null,
    status: VideoStatus,
    fileObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    filePresignedUrl?: string | null,
    duration?: number | null,
    name?: string | null,
    thumbnailObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    accessType?: AccessType | null,
    accessId?: string | null,
    password?: string | null,
    width?: number | null,
    height?: number | null,
    viewsCountTotal?: number | null,
    viewsCountUnique?: number | null,
    viewsList?:  {
      __typename: "ModelscreensightVideoAudienceConnection",
      items:  Array< {
        __typename: "screensightVideoAudience",
        videoId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        guestName?: string | null,
        viewCount?: number | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    commentsCount?: number | null,
    uploadedVideo?:  {
      __typename: "UploadedVideo",
      fileName?: string | null,
      videoContainer?: string | null,
      videoEncoding?: string | null,
    } | null,
    transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
    transcriptionSummaryError?: string | null,
    transcriptionSummary?: string | null,
    transcriptionObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListVideoAudienceQueryVariables = {
  videoId?: string | null,
  customerId?: ModelIDKeyConditionInput | null,
  filter?: ModelScreensightVideoAudienceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListVideoAudienceQuery = {
  listVideoAudience?:  {
    __typename: "ModelScreensightVideoAudienceConnection",
    items:  Array< {
      __typename: "screensightVideoAudience",
      videoId: string,
      customerId: string,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      guestName?: string | null,
      viewCount?: number | null,
      video?:  {
        __typename: "screensightVideo",
        id: string,
        createdAt: string,
        customerId: string,
        channelId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        title?: string | null,
        searchTitle?: string | null,
        status: VideoStatus,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        filePresignedUrl?: string | null,
        duration?: number | null,
        name?: string | null,
        thumbnailObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        accessType?: AccessType | null,
        accessId?: string | null,
        password?: string | null,
        width?: number | null,
        height?: number | null,
        viewsCountTotal?: number | null,
        viewsCountUnique?: number | null,
        viewsList?:  {
          __typename: "ModelscreensightVideoAudienceConnection",
          items:  Array< {
            __typename: "screensightVideoAudience",
            videoId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            guestName?: string | null,
            viewCount?: number | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        commentsCount?: number | null,
        uploadedVideo?:  {
          __typename: "UploadedVideo",
          fileName?: string | null,
          videoContainer?: string | null,
          videoEncoding?: string | null,
        } | null,
        transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
        transcriptionSummaryError?: string | null,
        transcriptionSummary?: string | null,
        transcriptionObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListVideoAudienceEngagementQueryVariables = {
  videoId: string,
};

export type ListVideoAudienceEngagementQuery = {
  listVideoAudienceEngagement?:  Array< {
    __typename: "VideoAudienceEngagement",
    videoId?: string | null,
    customerId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    timestamp?: string | null,
    chartValues?: Array< number | null > | null,
    segments?:  Array< {
      __typename: "VideoAudienceEngagementSegment",
      timestamp?: string | null,
      chartValues?: Array< number | null > | null,
    } | null > | null,
  } | null > | null,
};

export type GetChannelQueryVariables = {
  id: string,
  customerId: string,
};

export type GetChannelQuery = {
  getChannel?:  {
    __typename: "screensightChannel",
    id: string,
    customerId: string,
    channelType?: string | null,
    name?: string | null,
    categoryPosition?: number | null,
    readHorizon?: string | null,
    activity?:  {
      __typename: "ChannelActivity",
      new?: boolean | null,
      latestAt?: string | null,
      latestPost?:  {
        __typename: "LatestPost",
        id?: string | null,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "LatestPost",
          id?: string | null,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "LatestPost",
            id?: string | null,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "LatestPost",
              id?: string | null,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "LatestPost",
                id?: string | null,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
    chatPartnerId?: string | null,
    chatPartnerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    latestPostAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCustomerRecentChannelsQueryVariables = {
  customerId: string,
  latestPostAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelscreensightChannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomerRecentChannelsQuery = {
  listCustomerRecentChannels?:  {
    __typename: "ModelscreensightChannelConnection",
    items:  Array< {
      __typename: "screensightChannel",
      id: string,
      customerId: string,
      channelType?: string | null,
      name?: string | null,
      categoryPosition?: number | null,
      readHorizon?: string | null,
      activity?:  {
        __typename: "ChannelActivity",
        new?: boolean | null,
        latestAt?: string | null,
        latestPost?:  {
          __typename: "LatestPost",
          id?: string | null,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "LatestPost",
            id?: string | null,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "LatestPost",
              id?: string | null,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "LatestPost",
                id?: string | null,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
      } | null,
      chatPartnerId?: string | null,
      chatPartnerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      latestPostAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListChannelParticipantsQueryVariables = {
  channelId: string,
};

export type ListChannelParticipantsQuery = {
  listChannelParticipants?:  {
    __typename: "ChannelParticipantConnection",
    items:  Array< {
      __typename: "ChannelParticipant",
      customerId: string,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPostsByChannelQueryVariables = {
  channelId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelscreensightPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostsByChannelQuery = {
  listPostsByChannel?:  {
    __typename: "ModelscreensightPostConnection",
    items:  Array< {
      __typename: "screensightPost",
      id: string,
      channelId?: string | null,
      accessId?: string | null,
      customerId?: string | null,
      postType?: string | null,
      video?:  {
        __typename: "screensightVideo",
        id: string,
        createdAt: string,
        customerId: string,
        channelId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        title?: string | null,
        searchTitle?: string | null,
        status: VideoStatus,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        filePresignedUrl?: string | null,
        duration?: number | null,
        name?: string | null,
        thumbnailObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        accessType?: AccessType | null,
        accessId?: string | null,
        password?: string | null,
        width?: number | null,
        height?: number | null,
        viewsCountTotal?: number | null,
        viewsCountUnique?: number | null,
        viewsList?:  {
          __typename: "ModelscreensightVideoAudienceConnection",
          items:  Array< {
            __typename: "screensightVideoAudience",
            videoId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            guestName?: string | null,
            viewCount?: number | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        commentsCount?: number | null,
        uploadedVideo?:  {
          __typename: "UploadedVideo",
          fileName?: string | null,
          videoContainer?: string | null,
          videoEncoding?: string | null,
        } | null,
        transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
        transcriptionSummaryError?: string | null,
        transcriptionSummary?: string | null,
        transcriptionObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      comment?:  {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      emailMessage?:  {
        __typename: "screensightVideoEmailMessage",
        id: string,
        videoId: string,
        customerId?: string | null,
        channelId?: string | null,
        to?: Array< string | null > | null,
        note?: string | null,
        emailStatus?:  Array< {
          __typename: "EmailStatus",
          email?: string | null,
          status?: string | null,
          reject_reason?: string | null,
          _id?: string | null,
          deliveredAt?: string | null,
          opens?: Array< string | null > | null,
        } | null > | null,
        subject?: string | null,
        contacts?:  Array< {
          __typename: "EmailContact",
          email?: string | null,
          contactId?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      text?:  {
        __typename: "TextPost",
        content?: string | null,
        richContent?: string | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      file?:  {
        __typename: "FilePost",
        fileName?: string | null,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        status?: VideoStatus | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        mimeType?: string | null,
        metadata?: string | null,
      } | null,
      engagement?:  {
        __typename: "PostEngagementConnection",
        items:  Array< {
          __typename: "PostEngagement",
          customerId?: string | null,
          seen?: boolean | null,
          seenAt?: string | null,
          videoProgress?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      referencePostId?: string | null,
      referencePost?:  {
        __typename: "screensightPost",
        id: string,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        file?:  {
          __typename: "FilePost",
          fileName?: string | null,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          status?: VideoStatus | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          mimeType?: string | null,
          metadata?: string | null,
        } | null,
        engagement?:  {
          __typename: "PostEngagementConnection",
          items:  Array< {
            __typename: "PostEngagement",
            customerId?: string | null,
            seen?: boolean | null,
            seenAt?: string | null,
            videoProgress?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "screensightPost",
          id: string,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          file?:  {
            __typename: "FilePost",
            fileName?: string | null,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            status?: VideoStatus | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            mimeType?: string | null,
            metadata?: string | null,
          } | null,
          engagement?:  {
            __typename: "PostEngagementConnection",
            items:  Array< {
              __typename: "PostEngagement",
              customerId?: string | null,
              seen?: boolean | null,
              seenAt?: string | null,
              videoProgress?: number | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "screensightPost",
            id: string,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            file?:  {
              __typename: "FilePost",
              fileName?: string | null,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              status?: VideoStatus | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              mimeType?: string | null,
              metadata?: string | null,
            } | null,
            engagement?:  {
              __typename: "PostEngagementConnection",
              items:  Array< {
                __typename: "PostEngagement",
                customerId?: string | null,
                seen?: boolean | null,
                seenAt?: string | null,
                videoProgress?: number | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "screensightPost",
              id: string,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              file?:  {
                __typename: "FilePost",
                fileName?: string | null,
                status?: VideoStatus | null,
                customerId?: string | null,
                mimeType?: string | null,
                metadata?: string | null,
              } | null,
              engagement?:  {
                __typename: "PostEngagementConnection",
                nextToken?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "screensightPost",
                id: string,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                readHorizon?: string | null,
                postTempId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              readHorizon?: string | null,
              postTempId?: string | null,
              reactions?:  {
                __typename: "ModelscreensightReactionConnection",
                nextToken?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            readHorizon?: string | null,
            postTempId?: string | null,
            reactions?:  {
              __typename: "ModelscreensightReactionConnection",
              items:  Array< {
                __typename: "screensightReaction",
                postId: string,
                customerId: string,
                unicode: string,
                videoTimestamp: number,
                channelId?: string | null,
                incrementBy?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          readHorizon?: string | null,
          postTempId?: string | null,
          reactions?:  {
            __typename: "ModelscreensightReactionConnection",
            items:  Array< {
              __typename: "screensightReaction",
              postId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              unicode: string,
              videoTimestamp: number,
              channelId?: string | null,
              incrementBy?: number | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        readHorizon?: string | null,
        postTempId?: string | null,
        reactions?:  {
          __typename: "ModelscreensightReactionConnection",
          items:  Array< {
            __typename: "screensightReaction",
            postId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            unicode: string,
            videoTimestamp: number,
            channelId?: string | null,
            incrementBy?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      readHorizon?: string | null,
      postTempId?: string | null,
      reactions?:  {
        __typename: "ModelscreensightReactionConnection",
        items:  Array< {
          __typename: "screensightReaction",
          postId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          unicode: string,
          videoTimestamp: number,
          channelId?: string | null,
          incrementBy?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTypeOfPostInChannelQueryVariables = {
  postType: PostTypeFilter,
  channelId: string,
  customerId?: string | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTypeOfPostInChannelQuery = {
  listTypeOfPostInChannel?:  {
    __typename: "ModelscreensightPostConnection",
    items:  Array< {
      __typename: "screensightPost",
      id: string,
      channelId?: string | null,
      accessId?: string | null,
      customerId?: string | null,
      postType?: string | null,
      video?:  {
        __typename: "screensightVideo",
        id: string,
        createdAt: string,
        customerId: string,
        channelId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        title?: string | null,
        searchTitle?: string | null,
        status: VideoStatus,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        filePresignedUrl?: string | null,
        duration?: number | null,
        name?: string | null,
        thumbnailObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        accessType?: AccessType | null,
        accessId?: string | null,
        password?: string | null,
        width?: number | null,
        height?: number | null,
        viewsCountTotal?: number | null,
        viewsCountUnique?: number | null,
        viewsList?:  {
          __typename: "ModelscreensightVideoAudienceConnection",
          items:  Array< {
            __typename: "screensightVideoAudience",
            videoId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            guestName?: string | null,
            viewCount?: number | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        commentsCount?: number | null,
        uploadedVideo?:  {
          __typename: "UploadedVideo",
          fileName?: string | null,
          videoContainer?: string | null,
          videoEncoding?: string | null,
        } | null,
        transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
        transcriptionSummaryError?: string | null,
        transcriptionSummary?: string | null,
        transcriptionObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      comment?:  {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      emailMessage?:  {
        __typename: "screensightVideoEmailMessage",
        id: string,
        videoId: string,
        customerId?: string | null,
        channelId?: string | null,
        to?: Array< string | null > | null,
        note?: string | null,
        emailStatus?:  Array< {
          __typename: "EmailStatus",
          email?: string | null,
          status?: string | null,
          reject_reason?: string | null,
          _id?: string | null,
          deliveredAt?: string | null,
          opens?: Array< string | null > | null,
        } | null > | null,
        subject?: string | null,
        contacts?:  Array< {
          __typename: "EmailContact",
          email?: string | null,
          contactId?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      text?:  {
        __typename: "TextPost",
        content?: string | null,
        richContent?: string | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      file?:  {
        __typename: "FilePost",
        fileName?: string | null,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        status?: VideoStatus | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        mimeType?: string | null,
        metadata?: string | null,
      } | null,
      engagement?:  {
        __typename: "PostEngagementConnection",
        items:  Array< {
          __typename: "PostEngagement",
          customerId?: string | null,
          seen?: boolean | null,
          seenAt?: string | null,
          videoProgress?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      referencePostId?: string | null,
      referencePost?:  {
        __typename: "screensightPost",
        id: string,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        file?:  {
          __typename: "FilePost",
          fileName?: string | null,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          status?: VideoStatus | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          mimeType?: string | null,
          metadata?: string | null,
        } | null,
        engagement?:  {
          __typename: "PostEngagementConnection",
          items:  Array< {
            __typename: "PostEngagement",
            customerId?: string | null,
            seen?: boolean | null,
            seenAt?: string | null,
            videoProgress?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "screensightPost",
          id: string,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          file?:  {
            __typename: "FilePost",
            fileName?: string | null,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            status?: VideoStatus | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            mimeType?: string | null,
            metadata?: string | null,
          } | null,
          engagement?:  {
            __typename: "PostEngagementConnection",
            items:  Array< {
              __typename: "PostEngagement",
              customerId?: string | null,
              seen?: boolean | null,
              seenAt?: string | null,
              videoProgress?: number | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "screensightPost",
            id: string,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            file?:  {
              __typename: "FilePost",
              fileName?: string | null,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              status?: VideoStatus | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              mimeType?: string | null,
              metadata?: string | null,
            } | null,
            engagement?:  {
              __typename: "PostEngagementConnection",
              items:  Array< {
                __typename: "PostEngagement",
                customerId?: string | null,
                seen?: boolean | null,
                seenAt?: string | null,
                videoProgress?: number | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "screensightPost",
              id: string,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              file?:  {
                __typename: "FilePost",
                fileName?: string | null,
                status?: VideoStatus | null,
                customerId?: string | null,
                mimeType?: string | null,
                metadata?: string | null,
              } | null,
              engagement?:  {
                __typename: "PostEngagementConnection",
                nextToken?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "screensightPost",
                id: string,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                readHorizon?: string | null,
                postTempId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              readHorizon?: string | null,
              postTempId?: string | null,
              reactions?:  {
                __typename: "ModelscreensightReactionConnection",
                nextToken?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            readHorizon?: string | null,
            postTempId?: string | null,
            reactions?:  {
              __typename: "ModelscreensightReactionConnection",
              items:  Array< {
                __typename: "screensightReaction",
                postId: string,
                customerId: string,
                unicode: string,
                videoTimestamp: number,
                channelId?: string | null,
                incrementBy?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          readHorizon?: string | null,
          postTempId?: string | null,
          reactions?:  {
            __typename: "ModelscreensightReactionConnection",
            items:  Array< {
              __typename: "screensightReaction",
              postId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              unicode: string,
              videoTimestamp: number,
              channelId?: string | null,
              incrementBy?: number | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        readHorizon?: string | null,
        postTempId?: string | null,
        reactions?:  {
          __typename: "ModelscreensightReactionConnection",
          items:  Array< {
            __typename: "screensightReaction",
            postId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            unicode: string,
            videoTimestamp: number,
            channelId?: string | null,
            incrementBy?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      readHorizon?: string | null,
      postTempId?: string | null,
      reactions?:  {
        __typename: "ModelscreensightReactionConnection",
        items:  Array< {
          __typename: "screensightReaction",
          postId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          unicode: string,
          videoTimestamp: number,
          channelId?: string | null,
          incrementBy?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerQueryVariables = {
  id: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "screensightCustomer",
    id: string,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    profilePhoto?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    brandLogo?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    calendlyUrl?: string | null,
    lastSeen?: string | null,
    settings?:  {
      __typename: "CustomerSettings",
      notifyEmailWatched: boolean,
      notifyEmailComment: boolean,
      notifyUnseenMessages?: boolean | null,
      timezone?: string | null,
      pushNotifications?:  {
        __typename: "PushNotificationSettings",
        video?: boolean | null,
        comment?: boolean | null,
        reply?: boolean | null,
        text?: boolean | null,
      } | null,
      lastChannel?: string | null,
      enableSplashScreen?: boolean | null,
      termsAndConditions?:  {
        __typename: "TermsAndConditions",
        version: string,
        accepted: boolean,
        acceptedAt?: string | null,
      } | null,
      channelRecentActivityDays?: number | null,
    } | null,
    stripeId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetContactQueryVariables = {
  id: string,
};

export type GetContactQuery = {
  getContact?:  {
    __typename: "screensightContact",
    id: string,
    ownerCustomerId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    mobilePhone?: string | null,
    contactCustomerMeta?:  {
      __typename: "ModelscreensightCustomerConnection",
      items:  Array< {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListContactsByOwnerQueryVariables = {
  ownerCustomerId: string,
  lastNameFirstName?: ModelscreensightContactByOwnerSortedByNameCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelscreensightContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactsByOwnerQuery = {
  listContactsByOwner?:  {
    __typename: "ModelscreensightContactConnection",
    items:  Array< {
      __typename: "screensightContact",
      id: string,
      ownerCustomerId?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      mobilePhone?: string | null,
      contactCustomerMeta?:  {
        __typename: "ModelscreensightCustomerConnection",
        items:  Array< {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchScreensightContactsQueryVariables = {
  filter?: SearchablescreensightContactFilterInput | null,
  sort?: Array< SearchablescreensightContactSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablescreensightContactAggregationInput | null > | null,
};

export type SearchScreensightContactsQuery = {
  searchScreensightContacts?:  {
    __typename: "SearchablescreensightContactConnection",
    items:  Array< {
      __typename: "screensightContact",
      id: string,
      ownerCustomerId?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      mobilePhone?: string | null,
      contactCustomerMeta?:  {
        __typename: "ModelscreensightCustomerConnection",
        items:  Array< {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type ListChannelsByCustomerQueryVariables = {
  customerId: string,
  categoryPositionName?: ModelscreensightChannelByCustomerCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelscreensightChannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChannelsByCustomerQuery = {
  listChannelsByCustomer?:  {
    __typename: "ModelscreensightChannelConnection",
    items:  Array< {
      __typename: "screensightChannel",
      id: string,
      customerId: string,
      channelType?: string | null,
      name?: string | null,
      categoryPosition?: number | null,
      readHorizon?: string | null,
      activity?:  {
        __typename: "ChannelActivity",
        new?: boolean | null,
        latestAt?: string | null,
        latestPost?:  {
          __typename: "LatestPost",
          id?: string | null,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "LatestPost",
            id?: string | null,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "LatestPost",
              id?: string | null,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "LatestPost",
                id?: string | null,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
      } | null,
      chatPartnerId?: string | null,
      chatPartnerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      latestPostAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchScreensightVideosQueryVariables = {
  filter?: SearchablescreensightVideoFilterInput | null,
  sort?: Array< SearchablescreensightVideoSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablescreensightVideoAggregationInput | null > | null,
};

export type SearchScreensightVideosQuery = {
  searchScreensightVideos?:  {
    __typename: "SearchablescreensightVideoConnection",
    items:  Array< {
      __typename: "screensightVideo",
      id: string,
      createdAt: string,
      customerId: string,
      channelId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title?: string | null,
      searchTitle?: string | null,
      status: VideoStatus,
      fileObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      filePresignedUrl?: string | null,
      duration?: number | null,
      name?: string | null,
      thumbnailObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      accessType?: AccessType | null,
      accessId?: string | null,
      password?: string | null,
      width?: number | null,
      height?: number | null,
      viewsCountTotal?: number | null,
      viewsCountUnique?: number | null,
      viewsList?:  {
        __typename: "ModelscreensightVideoAudienceConnection",
        items:  Array< {
          __typename: "screensightVideoAudience",
          videoId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          guestName?: string | null,
          viewCount?: number | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      commentsCount?: number | null,
      uploadedVideo?:  {
        __typename: "UploadedVideo",
        fileName?: string | null,
        videoContainer?: string | null,
        videoEncoding?: string | null,
      } | null,
      transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
      transcriptionSummaryError?: string | null,
      transcriptionSummary?: string | null,
      transcriptionObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type ListCommentsByVideoQueryVariables = {
  videoId: string,
  parentCreatedAtCreatedAt?: ModelscreensightVideoCommentByVideoCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelscreensightVideoCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsByVideoQuery = {
  listCommentsByVideo?:  {
    __typename: "ModelscreensightVideoCommentConnection",
    items:  Array< {
      __typename: "screensightVideoComment",
      id: string,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      videoId: string,
      channelId?: string | null,
      parentId?: string | null,
      content?: string | null,
      richContent?: string | null,
      videoTimestamp?: number | null,
      resolved?: boolean | null,
      replies?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      replyCount?: number | null,
      parentCreatedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
    count?: number | null,
  } | null,
};

export type ListCommentsByParentQueryVariables = {
  parentId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelscreensightVideoCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsByParentQuery = {
  listCommentsByParent?:  {
    __typename: "ModelscreensightVideoCommentConnection",
    items:  Array< {
      __typename: "screensightVideoComment",
      id: string,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      videoId: string,
      channelId?: string | null,
      parentId?: string | null,
      content?: string | null,
      richContent?: string | null,
      videoTimestamp?: number | null,
      resolved?: boolean | null,
      replies?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      replyCount?: number | null,
      parentCreatedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
    count?: number | null,
  } | null,
};

export type SearchScreensightPostsQueryVariables = {
  filter?: SearchablescreensightPostFilterInput | null,
  sort?: Array< SearchablescreensightPostSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablescreensightPostAggregationInput | null > | null,
};

export type SearchScreensightPostsQuery = {
  searchScreensightPosts?:  {
    __typename: "SearchablescreensightPostConnection",
    items:  Array< {
      __typename: "screensightPost",
      id: string,
      channelId?: string | null,
      accessId?: string | null,
      customerId?: string | null,
      postType?: string | null,
      video?:  {
        __typename: "screensightVideo",
        id: string,
        createdAt: string,
        customerId: string,
        channelId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        title?: string | null,
        searchTitle?: string | null,
        status: VideoStatus,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        filePresignedUrl?: string | null,
        duration?: number | null,
        name?: string | null,
        thumbnailObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        accessType?: AccessType | null,
        accessId?: string | null,
        password?: string | null,
        width?: number | null,
        height?: number | null,
        viewsCountTotal?: number | null,
        viewsCountUnique?: number | null,
        viewsList?:  {
          __typename: "ModelscreensightVideoAudienceConnection",
          items:  Array< {
            __typename: "screensightVideoAudience",
            videoId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            guestName?: string | null,
            viewCount?: number | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        commentsCount?: number | null,
        uploadedVideo?:  {
          __typename: "UploadedVideo",
          fileName?: string | null,
          videoContainer?: string | null,
          videoEncoding?: string | null,
        } | null,
        transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
        transcriptionSummaryError?: string | null,
        transcriptionSummary?: string | null,
        transcriptionObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      comment?:  {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      emailMessage?:  {
        __typename: "screensightVideoEmailMessage",
        id: string,
        videoId: string,
        customerId?: string | null,
        channelId?: string | null,
        to?: Array< string | null > | null,
        note?: string | null,
        emailStatus?:  Array< {
          __typename: "EmailStatus",
          email?: string | null,
          status?: string | null,
          reject_reason?: string | null,
          _id?: string | null,
          deliveredAt?: string | null,
          opens?: Array< string | null > | null,
        } | null > | null,
        subject?: string | null,
        contacts?:  Array< {
          __typename: "EmailContact",
          email?: string | null,
          contactId?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      text?:  {
        __typename: "TextPost",
        content?: string | null,
        richContent?: string | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      file?:  {
        __typename: "FilePost",
        fileName?: string | null,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        status?: VideoStatus | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        mimeType?: string | null,
        metadata?: string | null,
      } | null,
      engagement?:  {
        __typename: "PostEngagementConnection",
        items:  Array< {
          __typename: "PostEngagement",
          customerId?: string | null,
          seen?: boolean | null,
          seenAt?: string | null,
          videoProgress?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      referencePostId?: string | null,
      referencePost?:  {
        __typename: "screensightPost",
        id: string,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        file?:  {
          __typename: "FilePost",
          fileName?: string | null,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          status?: VideoStatus | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          mimeType?: string | null,
          metadata?: string | null,
        } | null,
        engagement?:  {
          __typename: "PostEngagementConnection",
          items:  Array< {
            __typename: "PostEngagement",
            customerId?: string | null,
            seen?: boolean | null,
            seenAt?: string | null,
            videoProgress?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "screensightPost",
          id: string,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          file?:  {
            __typename: "FilePost",
            fileName?: string | null,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            status?: VideoStatus | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            mimeType?: string | null,
            metadata?: string | null,
          } | null,
          engagement?:  {
            __typename: "PostEngagementConnection",
            items:  Array< {
              __typename: "PostEngagement",
              customerId?: string | null,
              seen?: boolean | null,
              seenAt?: string | null,
              videoProgress?: number | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "screensightPost",
            id: string,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            file?:  {
              __typename: "FilePost",
              fileName?: string | null,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              status?: VideoStatus | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              mimeType?: string | null,
              metadata?: string | null,
            } | null,
            engagement?:  {
              __typename: "PostEngagementConnection",
              items:  Array< {
                __typename: "PostEngagement",
                customerId?: string | null,
                seen?: boolean | null,
                seenAt?: string | null,
                videoProgress?: number | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "screensightPost",
              id: string,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              file?:  {
                __typename: "FilePost",
                fileName?: string | null,
                status?: VideoStatus | null,
                customerId?: string | null,
                mimeType?: string | null,
                metadata?: string | null,
              } | null,
              engagement?:  {
                __typename: "PostEngagementConnection",
                nextToken?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "screensightPost",
                id: string,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                readHorizon?: string | null,
                postTempId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              readHorizon?: string | null,
              postTempId?: string | null,
              reactions?:  {
                __typename: "ModelscreensightReactionConnection",
                nextToken?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            readHorizon?: string | null,
            postTempId?: string | null,
            reactions?:  {
              __typename: "ModelscreensightReactionConnection",
              items:  Array< {
                __typename: "screensightReaction",
                postId: string,
                customerId: string,
                unicode: string,
                videoTimestamp: number,
                channelId?: string | null,
                incrementBy?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          readHorizon?: string | null,
          postTempId?: string | null,
          reactions?:  {
            __typename: "ModelscreensightReactionConnection",
            items:  Array< {
              __typename: "screensightReaction",
              postId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              unicode: string,
              videoTimestamp: number,
              channelId?: string | null,
              incrementBy?: number | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        readHorizon?: string | null,
        postTempId?: string | null,
        reactions?:  {
          __typename: "ModelscreensightReactionConnection",
          items:  Array< {
            __typename: "screensightReaction",
            postId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            unicode: string,
            videoTimestamp: number,
            channelId?: string | null,
            incrementBy?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      readHorizon?: string | null,
      postTempId?: string | null,
      reactions?:  {
        __typename: "ModelscreensightReactionConnection",
        items:  Array< {
          __typename: "screensightReaction",
          postId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          unicode: string,
          videoTimestamp: number,
          channelId?: string | null,
          incrementBy?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type ListVideosWatchedQueryVariables = {
  customerId: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelscreensightVideoAudienceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVideosWatchedQuery = {
  listVideosWatched?:  {
    __typename: "ModelscreensightVideoAudienceConnection",
    items:  Array< {
      __typename: "screensightVideoAudience",
      videoId: string,
      customerId: string,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      guestName?: string | null,
      viewCount?: number | null,
      video?:  {
        __typename: "screensightVideo",
        id: string,
        createdAt: string,
        customerId: string,
        channelId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        title?: string | null,
        searchTitle?: string | null,
        status: VideoStatus,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        filePresignedUrl?: string | null,
        duration?: number | null,
        name?: string | null,
        thumbnailObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        accessType?: AccessType | null,
        accessId?: string | null,
        password?: string | null,
        width?: number | null,
        height?: number | null,
        viewsCountTotal?: number | null,
        viewsCountUnique?: number | null,
        viewsList?:  {
          __typename: "ModelscreensightVideoAudienceConnection",
          items:  Array< {
            __typename: "screensightVideoAudience",
            videoId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            guestName?: string | null,
            viewCount?: number | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        commentsCount?: number | null,
        uploadedVideo?:  {
          __typename: "UploadedVideo",
          fileName?: string | null,
          videoContainer?: string | null,
          videoEncoding?: string | null,
        } | null,
        transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
        transcriptionSummaryError?: string | null,
        transcriptionSummary?: string | null,
        transcriptionObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreatePostSubscriptionVariables = {
  channelId?: string | null,
  videoId?: string | null,
  customerId?: string | null,
};

export type OnCreatePostSubscription = {
  onCreatePost?:  {
    __typename: "screensightPost",
    id: string,
    channelId?: string | null,
    accessId?: string | null,
    customerId?: string | null,
    postType?: string | null,
    video?:  {
      __typename: "screensightVideo",
      id: string,
      createdAt: string,
      customerId: string,
      channelId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title?: string | null,
      searchTitle?: string | null,
      status: VideoStatus,
      fileObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      filePresignedUrl?: string | null,
      duration?: number | null,
      name?: string | null,
      thumbnailObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      accessType?: AccessType | null,
      accessId?: string | null,
      password?: string | null,
      width?: number | null,
      height?: number | null,
      viewsCountTotal?: number | null,
      viewsCountUnique?: number | null,
      viewsList?:  {
        __typename: "ModelscreensightVideoAudienceConnection",
        items:  Array< {
          __typename: "screensightVideoAudience",
          videoId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          guestName?: string | null,
          viewCount?: number | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      commentsCount?: number | null,
      uploadedVideo?:  {
        __typename: "UploadedVideo",
        fileName?: string | null,
        videoContainer?: string | null,
        videoEncoding?: string | null,
      } | null,
      transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
      transcriptionSummaryError?: string | null,
      transcriptionSummary?: string | null,
      transcriptionObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    comment?:  {
      __typename: "screensightVideoComment",
      id: string,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      videoId: string,
      channelId?: string | null,
      parentId?: string | null,
      content?: string | null,
      richContent?: string | null,
      videoTimestamp?: number | null,
      resolved?: boolean | null,
      replies?:  {
        __typename: "ModelscreensightVideoCommentConnection",
        items:  Array< {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
        count?: number | null,
      } | null,
      replyCount?: number | null,
      parentCreatedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    emailMessage?:  {
      __typename: "screensightVideoEmailMessage",
      id: string,
      videoId: string,
      customerId?: string | null,
      channelId?: string | null,
      to?: Array< string | null > | null,
      note?: string | null,
      emailStatus?:  Array< {
        __typename: "EmailStatus",
        email?: string | null,
        status?: string | null,
        reject_reason?: string | null,
        _id?: string | null,
        deliveredAt?: string | null,
        opens?: Array< string | null > | null,
      } | null > | null,
      subject?: string | null,
      contacts?:  Array< {
        __typename: "EmailContact",
        email?: string | null,
        contactId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    text?:  {
      __typename: "TextPost",
      content?: string | null,
      richContent?: string | null,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    file?:  {
      __typename: "FilePost",
      fileName?: string | null,
      fileObject?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      status?: VideoStatus | null,
      customerId?: string | null,
      customerMeta?:  {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      mimeType?: string | null,
      metadata?: string | null,
    } | null,
    engagement?:  {
      __typename: "PostEngagementConnection",
      items:  Array< {
        __typename: "PostEngagement",
        customerId?: string | null,
        seen?: boolean | null,
        seenAt?: string | null,
        videoProgress?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    referencePostId?: string | null,
    referencePost?:  {
      __typename: "screensightPost",
      id: string,
      channelId?: string | null,
      accessId?: string | null,
      customerId?: string | null,
      postType?: string | null,
      video?:  {
        __typename: "screensightVideo",
        id: string,
        createdAt: string,
        customerId: string,
        channelId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        title?: string | null,
        searchTitle?: string | null,
        status: VideoStatus,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        filePresignedUrl?: string | null,
        duration?: number | null,
        name?: string | null,
        thumbnailObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        accessType?: AccessType | null,
        accessId?: string | null,
        password?: string | null,
        width?: number | null,
        height?: number | null,
        viewsCountTotal?: number | null,
        viewsCountUnique?: number | null,
        viewsList?:  {
          __typename: "ModelscreensightVideoAudienceConnection",
          items:  Array< {
            __typename: "screensightVideoAudience",
            videoId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            guestName?: string | null,
            viewCount?: number | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        commentsCount?: number | null,
        uploadedVideo?:  {
          __typename: "UploadedVideo",
          fileName?: string | null,
          videoContainer?: string | null,
          videoEncoding?: string | null,
        } | null,
        transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
        transcriptionSummaryError?: string | null,
        transcriptionSummary?: string | null,
        transcriptionObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      comment?:  {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      emailMessage?:  {
        __typename: "screensightVideoEmailMessage",
        id: string,
        videoId: string,
        customerId?: string | null,
        channelId?: string | null,
        to?: Array< string | null > | null,
        note?: string | null,
        emailStatus?:  Array< {
          __typename: "EmailStatus",
          email?: string | null,
          status?: string | null,
          reject_reason?: string | null,
          _id?: string | null,
          deliveredAt?: string | null,
          opens?: Array< string | null > | null,
        } | null > | null,
        subject?: string | null,
        contacts?:  Array< {
          __typename: "EmailContact",
          email?: string | null,
          contactId?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      text?:  {
        __typename: "TextPost",
        content?: string | null,
        richContent?: string | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      file?:  {
        __typename: "FilePost",
        fileName?: string | null,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        status?: VideoStatus | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        mimeType?: string | null,
        metadata?: string | null,
      } | null,
      engagement?:  {
        __typename: "PostEngagementConnection",
        items:  Array< {
          __typename: "PostEngagement",
          customerId?: string | null,
          seen?: boolean | null,
          seenAt?: string | null,
          videoProgress?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      referencePostId?: string | null,
      referencePost?:  {
        __typename: "screensightPost",
        id: string,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        file?:  {
          __typename: "FilePost",
          fileName?: string | null,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          status?: VideoStatus | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          mimeType?: string | null,
          metadata?: string | null,
        } | null,
        engagement?:  {
          __typename: "PostEngagementConnection",
          items:  Array< {
            __typename: "PostEngagement",
            customerId?: string | null,
            seen?: boolean | null,
            seenAt?: string | null,
            videoProgress?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "screensightPost",
          id: string,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          file?:  {
            __typename: "FilePost",
            fileName?: string | null,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            status?: VideoStatus | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            mimeType?: string | null,
            metadata?: string | null,
          } | null,
          engagement?:  {
            __typename: "PostEngagementConnection",
            items:  Array< {
              __typename: "PostEngagement",
              customerId?: string | null,
              seen?: boolean | null,
              seenAt?: string | null,
              videoProgress?: number | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "screensightPost",
            id: string,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            file?:  {
              __typename: "FilePost",
              fileName?: string | null,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              status?: VideoStatus | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              mimeType?: string | null,
              metadata?: string | null,
            } | null,
            engagement?:  {
              __typename: "PostEngagementConnection",
              items:  Array< {
                __typename: "PostEngagement",
                customerId?: string | null,
                seen?: boolean | null,
                seenAt?: string | null,
                videoProgress?: number | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "screensightPost",
              id: string,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              file?:  {
                __typename: "FilePost",
                fileName?: string | null,
                status?: VideoStatus | null,
                customerId?: string | null,
                mimeType?: string | null,
                metadata?: string | null,
              } | null,
              engagement?:  {
                __typename: "PostEngagementConnection",
                nextToken?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "screensightPost",
                id: string,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                readHorizon?: string | null,
                postTempId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              readHorizon?: string | null,
              postTempId?: string | null,
              reactions?:  {
                __typename: "ModelscreensightReactionConnection",
                nextToken?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            readHorizon?: string | null,
            postTempId?: string | null,
            reactions?:  {
              __typename: "ModelscreensightReactionConnection",
              items:  Array< {
                __typename: "screensightReaction",
                postId: string,
                customerId: string,
                unicode: string,
                videoTimestamp: number,
                channelId?: string | null,
                incrementBy?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          readHorizon?: string | null,
          postTempId?: string | null,
          reactions?:  {
            __typename: "ModelscreensightReactionConnection",
            items:  Array< {
              __typename: "screensightReaction",
              postId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              unicode: string,
              videoTimestamp: number,
              channelId?: string | null,
              incrementBy?: number | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        readHorizon?: string | null,
        postTempId?: string | null,
        reactions?:  {
          __typename: "ModelscreensightReactionConnection",
          items:  Array< {
            __typename: "screensightReaction",
            postId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            unicode: string,
            videoTimestamp: number,
            channelId?: string | null,
            incrementBy?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      readHorizon?: string | null,
      postTempId?: string | null,
      reactions?:  {
        __typename: "ModelscreensightReactionConnection",
        items:  Array< {
          __typename: "screensightReaction",
          postId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          unicode: string,
          videoTimestamp: number,
          channelId?: string | null,
          incrementBy?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    readHorizon?: string | null,
    postTempId?: string | null,
    reactions?:  {
      __typename: "ModelscreensightReactionConnection",
      items:  Array< {
        __typename: "screensightReaction",
        postId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        unicode: string,
        videoTimestamp: number,
        channelId?: string | null,
        incrementBy?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnPostEventSubscriptionVariables = {
  customerId: string,
};

export type OnPostEventSubscription = {
  onPostEvent?:  {
    __typename: "PostEvent",
    customerId: string,
    channelId?: string | null,
    action: string,
    post?:  {
      __typename: "screensightPost",
      id: string,
      channelId?: string | null,
      accessId?: string | null,
      customerId?: string | null,
      postType?: string | null,
      video?:  {
        __typename: "screensightVideo",
        id: string,
        createdAt: string,
        customerId: string,
        channelId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        title?: string | null,
        searchTitle?: string | null,
        status: VideoStatus,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        filePresignedUrl?: string | null,
        duration?: number | null,
        name?: string | null,
        thumbnailObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        accessType?: AccessType | null,
        accessId?: string | null,
        password?: string | null,
        width?: number | null,
        height?: number | null,
        viewsCountTotal?: number | null,
        viewsCountUnique?: number | null,
        viewsList?:  {
          __typename: "ModelscreensightVideoAudienceConnection",
          items:  Array< {
            __typename: "screensightVideoAudience",
            videoId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            guestName?: string | null,
            viewCount?: number | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        commentsCount?: number | null,
        uploadedVideo?:  {
          __typename: "UploadedVideo",
          fileName?: string | null,
          videoContainer?: string | null,
          videoEncoding?: string | null,
        } | null,
        transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
        transcriptionSummaryError?: string | null,
        transcriptionSummary?: string | null,
        transcriptionObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      comment?:  {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      emailMessage?:  {
        __typename: "screensightVideoEmailMessage",
        id: string,
        videoId: string,
        customerId?: string | null,
        channelId?: string | null,
        to?: Array< string | null > | null,
        note?: string | null,
        emailStatus?:  Array< {
          __typename: "EmailStatus",
          email?: string | null,
          status?: string | null,
          reject_reason?: string | null,
          _id?: string | null,
          deliveredAt?: string | null,
          opens?: Array< string | null > | null,
        } | null > | null,
        subject?: string | null,
        contacts?:  Array< {
          __typename: "EmailContact",
          email?: string | null,
          contactId?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      text?:  {
        __typename: "TextPost",
        content?: string | null,
        richContent?: string | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      file?:  {
        __typename: "FilePost",
        fileName?: string | null,
        fileObject?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        status?: VideoStatus | null,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        mimeType?: string | null,
        metadata?: string | null,
      } | null,
      engagement?:  {
        __typename: "PostEngagementConnection",
        items:  Array< {
          __typename: "PostEngagement",
          customerId?: string | null,
          seen?: boolean | null,
          seenAt?: string | null,
          videoProgress?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      referencePostId?: string | null,
      referencePost?:  {
        __typename: "screensightPost",
        id: string,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        file?:  {
          __typename: "FilePost",
          fileName?: string | null,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          status?: VideoStatus | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          mimeType?: string | null,
          metadata?: string | null,
        } | null,
        engagement?:  {
          __typename: "PostEngagementConnection",
          items:  Array< {
            __typename: "PostEngagement",
            customerId?: string | null,
            seen?: boolean | null,
            seenAt?: string | null,
            videoProgress?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "screensightPost",
          id: string,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          file?:  {
            __typename: "FilePost",
            fileName?: string | null,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            status?: VideoStatus | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            mimeType?: string | null,
            metadata?: string | null,
          } | null,
          engagement?:  {
            __typename: "PostEngagementConnection",
            items:  Array< {
              __typename: "PostEngagement",
              customerId?: string | null,
              seen?: boolean | null,
              seenAt?: string | null,
              videoProgress?: number | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "screensightPost",
            id: string,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            file?:  {
              __typename: "FilePost",
              fileName?: string | null,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              status?: VideoStatus | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              mimeType?: string | null,
              metadata?: string | null,
            } | null,
            engagement?:  {
              __typename: "PostEngagementConnection",
              items:  Array< {
                __typename: "PostEngagement",
                customerId?: string | null,
                seen?: boolean | null,
                seenAt?: string | null,
                videoProgress?: number | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "screensightPost",
              id: string,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              file?:  {
                __typename: "FilePost",
                fileName?: string | null,
                status?: VideoStatus | null,
                customerId?: string | null,
                mimeType?: string | null,
                metadata?: string | null,
              } | null,
              engagement?:  {
                __typename: "PostEngagementConnection",
                nextToken?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "screensightPost",
                id: string,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                readHorizon?: string | null,
                postTempId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              readHorizon?: string | null,
              postTempId?: string | null,
              reactions?:  {
                __typename: "ModelscreensightReactionConnection",
                nextToken?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            readHorizon?: string | null,
            postTempId?: string | null,
            reactions?:  {
              __typename: "ModelscreensightReactionConnection",
              items:  Array< {
                __typename: "screensightReaction",
                postId: string,
                customerId: string,
                unicode: string,
                videoTimestamp: number,
                channelId?: string | null,
                incrementBy?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          readHorizon?: string | null,
          postTempId?: string | null,
          reactions?:  {
            __typename: "ModelscreensightReactionConnection",
            items:  Array< {
              __typename: "screensightReaction",
              postId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              unicode: string,
              videoTimestamp: number,
              channelId?: string | null,
              incrementBy?: number | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        readHorizon?: string | null,
        postTempId?: string | null,
        reactions?:  {
          __typename: "ModelscreensightReactionConnection",
          items:  Array< {
            __typename: "screensightReaction",
            postId: string,
            customerId: string,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            unicode: string,
            videoTimestamp: number,
            channelId?: string | null,
            incrementBy?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      readHorizon?: string | null,
      postTempId?: string | null,
      reactions?:  {
        __typename: "ModelscreensightReactionConnection",
        items:  Array< {
          __typename: "screensightReaction",
          postId: string,
          customerId: string,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          unicode: string,
          videoTimestamp: number,
          channelId?: string | null,
          incrementBy?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    videosLimitReached?: boolean | null,
  } | null,
};

export type OnPostsSeenSubscriptionVariables = {
  channelId: string,
};

export type OnPostsSeenSubscription = {
  onPostsSeen?:  {
    __typename: "PostsSeen",
    customerId?: string | null,
    channelId?: string | null,
    timestamp?: string | null,
    source?: string | null,
    postIds?: Array< string | null > | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnPostReactionSubscriptionVariables = {
  channelId?: string | null,
};

export type OnPostReactionSubscription = {
  onPostReaction?:  {
    __typename: "screensightReaction",
    postId: string,
    customerId: string,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    unicode: string,
    videoTimestamp: number,
    channelId?: string | null,
    incrementBy?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateVideoSubscriptionVariables = {
  customerId: string,
};

export type OnCreateVideoSubscription = {
  onCreateVideo?:  {
    __typename: "screensightVideo",
    id: string,
    createdAt: string,
    customerId: string,
    channelId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title?: string | null,
    searchTitle?: string | null,
    status: VideoStatus,
    fileObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    filePresignedUrl?: string | null,
    duration?: number | null,
    name?: string | null,
    thumbnailObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    accessType?: AccessType | null,
    accessId?: string | null,
    password?: string | null,
    width?: number | null,
    height?: number | null,
    viewsCountTotal?: number | null,
    viewsCountUnique?: number | null,
    viewsList?:  {
      __typename: "ModelscreensightVideoAudienceConnection",
      items:  Array< {
        __typename: "screensightVideoAudience",
        videoId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        guestName?: string | null,
        viewCount?: number | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    commentsCount?: number | null,
    uploadedVideo?:  {
      __typename: "UploadedVideo",
      fileName?: string | null,
      videoContainer?: string | null,
      videoEncoding?: string | null,
    } | null,
    transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
    transcriptionSummaryError?: string | null,
    transcriptionSummary?: string | null,
    transcriptionObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateVideoSubscriptionVariables = {
  channelId?: string | null,
  id?: string | null,
  customerId?: string | null,
};

export type OnUpdateVideoSubscription = {
  onUpdateVideo?:  {
    __typename: "screensightVideo",
    id: string,
    createdAt: string,
    customerId: string,
    channelId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title?: string | null,
    searchTitle?: string | null,
    status: VideoStatus,
    fileObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    filePresignedUrl?: string | null,
    duration?: number | null,
    name?: string | null,
    thumbnailObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    accessType?: AccessType | null,
    accessId?: string | null,
    password?: string | null,
    width?: number | null,
    height?: number | null,
    viewsCountTotal?: number | null,
    viewsCountUnique?: number | null,
    viewsList?:  {
      __typename: "ModelscreensightVideoAudienceConnection",
      items:  Array< {
        __typename: "screensightVideoAudience",
        videoId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        guestName?: string | null,
        viewCount?: number | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    commentsCount?: number | null,
    uploadedVideo?:  {
      __typename: "UploadedVideo",
      fileName?: string | null,
      videoContainer?: string | null,
      videoEncoding?: string | null,
    } | null,
    transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
    transcriptionSummaryError?: string | null,
    transcriptionSummary?: string | null,
    transcriptionObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteVideoSubscriptionVariables = {
  channelId?: string | null,
  id?: string | null,
  customerId?: string | null,
};

export type OnDeleteVideoSubscription = {
  onDeleteVideo?:  {
    __typename: "screensightVideo",
    id: string,
    createdAt: string,
    customerId: string,
    channelId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title?: string | null,
    searchTitle?: string | null,
    status: VideoStatus,
    fileObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    filePresignedUrl?: string | null,
    duration?: number | null,
    name?: string | null,
    thumbnailObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    accessType?: AccessType | null,
    accessId?: string | null,
    password?: string | null,
    width?: number | null,
    height?: number | null,
    viewsCountTotal?: number | null,
    viewsCountUnique?: number | null,
    viewsList?:  {
      __typename: "ModelscreensightVideoAudienceConnection",
      items:  Array< {
        __typename: "screensightVideoAudience",
        videoId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        guestName?: string | null,
        viewCount?: number | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    commentsCount?: number | null,
    uploadedVideo?:  {
      __typename: "UploadedVideo",
      fileName?: string | null,
      videoContainer?: string | null,
      videoEncoding?: string | null,
    } | null,
    transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
    transcriptionSummaryError?: string | null,
    transcriptionSummary?: string | null,
    transcriptionObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateVideoCommentSubscriptionVariables = {
  channelId?: string | null,
  videoId?: string | null,
};

export type OnUpdateVideoCommentSubscription = {
  onUpdateVideoComment?:  {
    __typename: "screensightVideoComment",
    id: string,
    customerId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    videoId: string,
    channelId?: string | null,
    parentId?: string | null,
    content?: string | null,
    richContent?: string | null,
    videoTimestamp?: number | null,
    resolved?: boolean | null,
    replies?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    replyCount?: number | null,
    parentCreatedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteVideoCommentSubscriptionVariables = {
  channelId?: string | null,
  videoId?: string | null,
};

export type OnDeleteVideoCommentSubscription = {
  onDeleteVideoComment?:  {
    __typename: "screensightVideoComment",
    id: string,
    customerId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    videoId: string,
    channelId?: string | null,
    parentId?: string | null,
    content?: string | null,
    richContent?: string | null,
    videoTimestamp?: number | null,
    resolved?: boolean | null,
    replies?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    replyCount?: number | null,
    parentCreatedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnSetVideoViewedSubscription = {
  onSetVideoViewed?:  {
    __typename: "screensightVideo",
    id: string,
    createdAt: string,
    customerId: string,
    channelId?: string | null,
    customerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title?: string | null,
    searchTitle?: string | null,
    status: VideoStatus,
    fileObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    filePresignedUrl?: string | null,
    duration?: number | null,
    name?: string | null,
    thumbnailObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    accessType?: AccessType | null,
    accessId?: string | null,
    password?: string | null,
    width?: number | null,
    height?: number | null,
    viewsCountTotal?: number | null,
    viewsCountUnique?: number | null,
    viewsList?:  {
      __typename: "ModelscreensightVideoAudienceConnection",
      items:  Array< {
        __typename: "screensightVideoAudience",
        videoId: string,
        customerId: string,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        guestName?: string | null,
        viewCount?: number | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelscreensightVideoCommentConnection",
      items:  Array< {
        __typename: "screensightVideoComment",
        id: string,
        customerId?: string | null,
        customerMeta?:  {
          __typename: "screensightCustomer",
          id: string,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          profilePhoto?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          brandLogo?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          calendlyUrl?: string | null,
          lastSeen?: string | null,
          settings?:  {
            __typename: "CustomerSettings",
            notifyEmailWatched: boolean,
            notifyEmailComment: boolean,
            notifyUnseenMessages?: boolean | null,
            timezone?: string | null,
            pushNotifications?:  {
              __typename: "PushNotificationSettings",
              video?: boolean | null,
              comment?: boolean | null,
              reply?: boolean | null,
              text?: boolean | null,
            } | null,
            lastChannel?: string | null,
            enableSplashScreen?: boolean | null,
            termsAndConditions?:  {
              __typename: "TermsAndConditions",
              version: string,
              accepted: boolean,
              acceptedAt?: string | null,
            } | null,
            channelRecentActivityDays?: number | null,
          } | null,
          stripeId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        videoId: string,
        channelId?: string | null,
        parentId?: string | null,
        content?: string | null,
        richContent?: string | null,
        videoTimestamp?: number | null,
        resolved?: boolean | null,
        replies?:  {
          __typename: "ModelscreensightVideoCommentConnection",
          items:  Array< {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null >,
          nextToken?: string | null,
          count?: number | null,
        } | null,
        replyCount?: number | null,
        parentCreatedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
      count?: number | null,
    } | null,
    commentsCount?: number | null,
    uploadedVideo?:  {
      __typename: "UploadedVideo",
      fileName?: string | null,
      videoContainer?: string | null,
      videoEncoding?: string | null,
    } | null,
    transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
    transcriptionSummaryError?: string | null,
    transcriptionSummary?: string | null,
    transcriptionObject?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateContactSubscriptionVariables = {
  ownerCustomerId?: string | null,
};

export type OnCreateContactSubscription = {
  onCreateContact?:  {
    __typename: "screensightContact",
    id: string,
    ownerCustomerId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    mobilePhone?: string | null,
    contactCustomerMeta?:  {
      __typename: "ModelscreensightCustomerConnection",
      items:  Array< {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateContactSubscriptionVariables = {
  ownerCustomerId?: string | null,
};

export type OnUpdateContactSubscription = {
  onUpdateContact?:  {
    __typename: "screensightContact",
    id: string,
    ownerCustomerId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    mobilePhone?: string | null,
    contactCustomerMeta?:  {
      __typename: "ModelscreensightCustomerConnection",
      items:  Array< {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteContactSubscriptionVariables = {
  ownerCustomerId?: string | null,
};

export type OnDeleteContactSubscription = {
  onDeleteContact?:  {
    __typename: "screensightContact",
    id: string,
    ownerCustomerId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    mobilePhone?: string | null,
    contactCustomerMeta?:  {
      __typename: "ModelscreensightCustomerConnection",
      items:  Array< {
        __typename: "screensightCustomer",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profilePhoto?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        brandLogo?:  {
          __typename: "S3Object",
          key: string,
          bucket: string,
          accessLevel?: S3FileAccessLevel | null,
          identityId?: string | null,
        } | null,
        calendlyUrl?: string | null,
        lastSeen?: string | null,
        settings?:  {
          __typename: "CustomerSettings",
          notifyEmailWatched: boolean,
          notifyEmailComment: boolean,
          notifyUnseenMessages?: boolean | null,
          timezone?: string | null,
          pushNotifications?:  {
            __typename: "PushNotificationSettings",
            video?: boolean | null,
            comment?: boolean | null,
            reply?: boolean | null,
            text?: boolean | null,
          } | null,
          lastChannel?: string | null,
          enableSplashScreen?: boolean | null,
          termsAndConditions?:  {
            __typename: "TermsAndConditions",
            version: string,
            accepted: boolean,
            acceptedAt?: string | null,
          } | null,
          channelRecentActivityDays?: number | null,
        } | null,
        stripeId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateChannelSubscriptionVariables = {
  customerId: string,
};

export type OnCreateChannelSubscription = {
  onCreateChannel?:  {
    __typename: "screensightChannel",
    id: string,
    customerId: string,
    channelType?: string | null,
    name?: string | null,
    categoryPosition?: number | null,
    readHorizon?: string | null,
    activity?:  {
      __typename: "ChannelActivity",
      new?: boolean | null,
      latestAt?: string | null,
      latestPost?:  {
        __typename: "LatestPost",
        id?: string | null,
        channelId?: string | null,
        accessId?: string | null,
        customerId?: string | null,
        postType?: string | null,
        video?:  {
          __typename: "screensightVideo",
          id: string,
          createdAt: string,
          customerId: string,
          channelId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          title?: string | null,
          searchTitle?: string | null,
          status: VideoStatus,
          fileObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          filePresignedUrl?: string | null,
          duration?: number | null,
          name?: string | null,
          thumbnailObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          accessType?: AccessType | null,
          accessId?: string | null,
          password?: string | null,
          width?: number | null,
          height?: number | null,
          viewsCountTotal?: number | null,
          viewsCountUnique?: number | null,
          viewsList?:  {
            __typename: "ModelscreensightVideoAudienceConnection",
            items:  Array< {
              __typename: "screensightVideoAudience",
              videoId: string,
              customerId: string,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              guestName?: string | null,
              viewCount?: number | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          commentsCount?: number | null,
          uploadedVideo?:  {
            __typename: "UploadedVideo",
            fileName?: string | null,
            videoContainer?: string | null,
            videoEncoding?: string | null,
          } | null,
          transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
          transcriptionSummaryError?: string | null,
          transcriptionSummary?: string | null,
          transcriptionObject?:  {
            __typename: "S3Object",
            key: string,
            bucket: string,
            accessLevel?: S3FileAccessLevel | null,
            identityId?: string | null,
          } | null,
          updatedAt: string,
        } | null,
        comment?:  {
          __typename: "screensightVideoComment",
          id: string,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          videoId: string,
          channelId?: string | null,
          parentId?: string | null,
          content?: string | null,
          richContent?: string | null,
          videoTimestamp?: number | null,
          resolved?: boolean | null,
          replies?:  {
            __typename: "ModelscreensightVideoCommentConnection",
            items:  Array< {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null >,
            nextToken?: string | null,
            count?: number | null,
          } | null,
          replyCount?: number | null,
          parentCreatedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        emailMessage?:  {
          __typename: "screensightVideoEmailMessage",
          id: string,
          videoId: string,
          customerId?: string | null,
          channelId?: string | null,
          to?: Array< string | null > | null,
          note?: string | null,
          emailStatus?:  Array< {
            __typename: "EmailStatus",
            email?: string | null,
            status?: string | null,
            reject_reason?: string | null,
            _id?: string | null,
            deliveredAt?: string | null,
            opens?: Array< string | null > | null,
          } | null > | null,
          subject?: string | null,
          contacts?:  Array< {
            __typename: "EmailContact",
            email?: string | null,
            contactId?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        text?:  {
          __typename: "TextPost",
          content?: string | null,
          richContent?: string | null,
          customerId?: string | null,
          customerMeta?:  {
            __typename: "screensightCustomer",
            id: string,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            profilePhoto?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            brandLogo?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            calendlyUrl?: string | null,
            lastSeen?: string | null,
            settings?:  {
              __typename: "CustomerSettings",
              notifyEmailWatched: boolean,
              notifyEmailComment: boolean,
              notifyUnseenMessages?: boolean | null,
              timezone?: string | null,
              pushNotifications?:  {
                __typename: "PushNotificationSettings",
                video?: boolean | null,
                comment?: boolean | null,
                reply?: boolean | null,
                text?: boolean | null,
              } | null,
              lastChannel?: string | null,
              enableSplashScreen?: boolean | null,
              termsAndConditions?:  {
                __typename: "TermsAndConditions",
                version: string,
                accepted: boolean,
                acceptedAt?: string | null,
              } | null,
              channelRecentActivityDays?: number | null,
            } | null,
            stripeId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        referencePostId?: string | null,
        referencePost?:  {
          __typename: "LatestPost",
          id?: string | null,
          channelId?: string | null,
          accessId?: string | null,
          customerId?: string | null,
          postType?: string | null,
          video?:  {
            __typename: "screensightVideo",
            id: string,
            createdAt: string,
            customerId: string,
            channelId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            title?: string | null,
            searchTitle?: string | null,
            status: VideoStatus,
            fileObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            filePresignedUrl?: string | null,
            duration?: number | null,
            name?: string | null,
            thumbnailObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            accessType?: AccessType | null,
            accessId?: string | null,
            password?: string | null,
            width?: number | null,
            height?: number | null,
            viewsCountTotal?: number | null,
            viewsCountUnique?: number | null,
            viewsList?:  {
              __typename: "ModelscreensightVideoAudienceConnection",
              items:  Array< {
                __typename: "screensightVideoAudience",
                videoId: string,
                customerId: string,
                guestName?: string | null,
                viewCount?: number | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            commentsCount?: number | null,
            uploadedVideo?:  {
              __typename: "UploadedVideo",
              fileName?: string | null,
              videoContainer?: string | null,
              videoEncoding?: string | null,
            } | null,
            transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
            transcriptionSummaryError?: string | null,
            transcriptionSummary?: string | null,
            transcriptionObject?:  {
              __typename: "S3Object",
              key: string,
              bucket: string,
              accessLevel?: S3FileAccessLevel | null,
              identityId?: string | null,
            } | null,
            updatedAt: string,
          } | null,
          comment?:  {
            __typename: "screensightVideoComment",
            id: string,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            videoId: string,
            channelId?: string | null,
            parentId?: string | null,
            content?: string | null,
            richContent?: string | null,
            videoTimestamp?: number | null,
            resolved?: boolean | null,
            replies?:  {
              __typename: "ModelscreensightVideoCommentConnection",
              items:  Array< {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null >,
              nextToken?: string | null,
              count?: number | null,
            } | null,
            replyCount?: number | null,
            parentCreatedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          emailMessage?:  {
            __typename: "screensightVideoEmailMessage",
            id: string,
            videoId: string,
            customerId?: string | null,
            channelId?: string | null,
            to?: Array< string | null > | null,
            note?: string | null,
            emailStatus?:  Array< {
              __typename: "EmailStatus",
              email?: string | null,
              status?: string | null,
              reject_reason?: string | null,
              _id?: string | null,
              deliveredAt?: string | null,
              opens?: Array< string | null > | null,
            } | null > | null,
            subject?: string | null,
            contacts?:  Array< {
              __typename: "EmailContact",
              email?: string | null,
              contactId?: string | null,
            } | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          text?:  {
            __typename: "TextPost",
            content?: string | null,
            richContent?: string | null,
            customerId?: string | null,
            customerMeta?:  {
              __typename: "screensightCustomer",
              id: string,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              profilePhoto?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              brandLogo?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              calendlyUrl?: string | null,
              lastSeen?: string | null,
              settings?:  {
                __typename: "CustomerSettings",
                notifyEmailWatched: boolean,
                notifyEmailComment: boolean,
                notifyUnseenMessages?: boolean | null,
                timezone?: string | null,
                lastChannel?: string | null,
                enableSplashScreen?: boolean | null,
                channelRecentActivityDays?: number | null,
              } | null,
              stripeId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          referencePostId?: string | null,
          referencePost?:  {
            __typename: "LatestPost",
            id?: string | null,
            channelId?: string | null,
            accessId?: string | null,
            customerId?: string | null,
            postType?: string | null,
            video?:  {
              __typename: "screensightVideo",
              id: string,
              createdAt: string,
              customerId: string,
              channelId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              title?: string | null,
              searchTitle?: string | null,
              status: VideoStatus,
              fileObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              filePresignedUrl?: string | null,
              duration?: number | null,
              name?: string | null,
              thumbnailObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              accessType?: AccessType | null,
              accessId?: string | null,
              password?: string | null,
              width?: number | null,
              height?: number | null,
              viewsCountTotal?: number | null,
              viewsCountUnique?: number | null,
              viewsList?:  {
                __typename: "ModelscreensightVideoAudienceConnection",
                nextToken?: string | null,
              } | null,
              comments?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              commentsCount?: number | null,
              uploadedVideo?:  {
                __typename: "UploadedVideo",
                fileName?: string | null,
                videoContainer?: string | null,
                videoEncoding?: string | null,
              } | null,
              transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
              transcriptionSummaryError?: string | null,
              transcriptionSummary?: string | null,
              transcriptionObject?:  {
                __typename: "S3Object",
                key: string,
                bucket: string,
                accessLevel?: S3FileAccessLevel | null,
                identityId?: string | null,
              } | null,
              updatedAt: string,
            } | null,
            comment?:  {
              __typename: "screensightVideoComment",
              id: string,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              videoId: string,
              channelId?: string | null,
              parentId?: string | null,
              content?: string | null,
              richContent?: string | null,
              videoTimestamp?: number | null,
              resolved?: boolean | null,
              replies?:  {
                __typename: "ModelscreensightVideoCommentConnection",
                nextToken?: string | null,
                count?: number | null,
              } | null,
              replyCount?: number | null,
              parentCreatedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            emailMessage?:  {
              __typename: "screensightVideoEmailMessage",
              id: string,
              videoId: string,
              customerId?: string | null,
              channelId?: string | null,
              to?: Array< string | null > | null,
              note?: string | null,
              emailStatus?:  Array< {
                __typename: "EmailStatus",
                email?: string | null,
                status?: string | null,
                reject_reason?: string | null,
                _id?: string | null,
                deliveredAt?: string | null,
                opens?: Array< string | null > | null,
              } | null > | null,
              subject?: string | null,
              contacts?:  Array< {
                __typename: "EmailContact",
                email?: string | null,
                contactId?: string | null,
              } | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            text?:  {
              __typename: "TextPost",
              content?: string | null,
              richContent?: string | null,
              customerId?: string | null,
              customerMeta?:  {
                __typename: "screensightCustomer",
                id: string,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                calendlyUrl?: string | null,
                lastSeen?: string | null,
                stripeId?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null,
            referencePostId?: string | null,
            referencePost?:  {
              __typename: "LatestPost",
              id?: string | null,
              channelId?: string | null,
              accessId?: string | null,
              customerId?: string | null,
              postType?: string | null,
              video?:  {
                __typename: "screensightVideo",
                id: string,
                createdAt: string,
                customerId: string,
                channelId?: string | null,
                title?: string | null,
                searchTitle?: string | null,
                status: VideoStatus,
                filePresignedUrl?: string | null,
                duration?: number | null,
                name?: string | null,
                accessType?: AccessType | null,
                accessId?: string | null,
                password?: string | null,
                width?: number | null,
                height?: number | null,
                viewsCountTotal?: number | null,
                viewsCountUnique?: number | null,
                commentsCount?: number | null,
                transcriptionSummaryStatus?: TranscriptionSummaryStatus | null,
                transcriptionSummaryError?: string | null,
                transcriptionSummary?: string | null,
                updatedAt: string,
              } | null,
              comment?:  {
                __typename: "screensightVideoComment",
                id: string,
                customerId?: string | null,
                videoId: string,
                channelId?: string | null,
                parentId?: string | null,
                content?: string | null,
                richContent?: string | null,
                videoTimestamp?: number | null,
                resolved?: boolean | null,
                replyCount?: number | null,
                parentCreatedAt?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              emailMessage?:  {
                __typename: "screensightVideoEmailMessage",
                id: string,
                videoId: string,
                customerId?: string | null,
                channelId?: string | null,
                to?: Array< string | null > | null,
                note?: string | null,
                subject?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              text?:  {
                __typename: "TextPost",
                content?: string | null,
                richContent?: string | null,
                customerId?: string | null,
              } | null,
              referencePostId?: string | null,
              referencePost?:  {
                __typename: "LatestPost",
                id?: string | null,
                channelId?: string | null,
                accessId?: string | null,
                customerId?: string | null,
                postType?: string | null,
                referencePostId?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
              } | null,
              createdAt?: string | null,
              updatedAt?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
    chatPartnerId?: string | null,
    chatPartnerMeta?:  {
      __typename: "screensightCustomer",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profilePhoto?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      brandLogo?:  {
        __typename: "S3Object",
        key: string,
        bucket: string,
        accessLevel?: S3FileAccessLevel | null,
        identityId?: string | null,
      } | null,
      calendlyUrl?: string | null,
      lastSeen?: string | null,
      settings?:  {
        __typename: "CustomerSettings",
        notifyEmailWatched: boolean,
        notifyEmailComment: boolean,
        notifyUnseenMessages?: boolean | null,
        timezone?: string | null,
        pushNotifications?:  {
          __typename: "PushNotificationSettings",
          video?: boolean | null,
          comment?: boolean | null,
          reply?: boolean | null,
          text?: boolean | null,
        } | null,
        lastChannel?: string | null,
        enableSplashScreen?: boolean | null,
        termsAndConditions?:  {
          __typename: "TermsAndConditions",
          version: string,
          accepted: boolean,
          acceptedAt?: string | null,
        } | null,
        channelRecentActivityDays?: number | null,
      } | null,
      stripeId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    latestPostAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionScreensightCustomerFilterInput | null,
};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?:  {
    __typename: "screensightCustomer",
    id: string,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    profilePhoto?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    brandLogo?:  {
      __typename: "S3Object",
      key: string,
      bucket: string,
      accessLevel?: S3FileAccessLevel | null,
      identityId?: string | null,
    } | null,
    calendlyUrl?: string | null,
    lastSeen?: string | null,
    settings?:  {
      __typename: "CustomerSettings",
      notifyEmailWatched: boolean,
      notifyEmailComment: boolean,
      notifyUnseenMessages?: boolean | null,
      timezone?: string | null,
      pushNotifications?:  {
        __typename: "PushNotificationSettings",
        video?: boolean | null,
        comment?: boolean | null,
        reply?: boolean | null,
        text?: boolean | null,
      } | null,
      lastChannel?: string | null,
      enableSplashScreen?: boolean | null,
      termsAndConditions?:  {
        __typename: "TermsAndConditions",
        version: string,
        accepted: boolean,
        acceptedAt?: string | null,
      } | null,
      channelRecentActivityDays?: number | null,
    } | null,
    stripeId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
