import * as React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import CloudFrontImage from '../_refactored/CloudFrontImage'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

type Props = {
  duration: number | null | undefined
  photo: string | null | undefined
  isUploading: boolean
  isConverting: boolean
  isMobile: boolean
}

const VideoItemThumbnail = (props: Props) => {
  const theme = useTheme()
  const { t: g } = useTranslation('general')
  const { t } = useTranslation('library')
  const isGif = props.photo?.includes('.gif')

  return (
    <>
      {props.photo && (
        <Box position="relative">
          <CloudFrontImage
            disableResize={isGif}
            image={props.photo}
            height={props.isMobile ? '80' : '150'}
            width={props.isMobile ? '80' : '150'}
            imgStyle={{
              width: props.isMobile ? '80px' : '150px',
              height: props.isMobile ? '80px' : '150px',
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
              borderRadius: '12px',
              objectFit: 'cover',
            }}
          />
          {!props.isMobile && (
            <Box position="absolute" bgcolor="#7A7A86" borderRadius="4px" padding="4px 7px" right="11px" bottom="20px">
              <Typography
                variant="body2"
                style={{ color: theme.palette.background.default, fontWeight: 500, fontSize: '10px' }}
              >
                {moment.utc(props.duration || 0).format(props.duration || 0 > 3600000 ? 'HH:mm:ss' : 'mm:ss')}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {(props.isUploading || props.isConverting) && (
        <Box
          display="flex"
          alignItems="center"
          height={props.isMobile ? '80' : '150px'}
          width={props.isMobile ? '80' : '150px'}
          justifyContent="center"
        >
          <Typography
            variant={props.isMobile ? 'caption' : 'h3'}
            color="secondary"
            style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
          >
            {props.isUploading ? g('common.uploading') : t('videoList.listItem.converting')}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default VideoItemThumbnail
