import * as React from 'react'
import { IconButton, Tooltip, useTheme } from '@mui/material'
import { AccessType } from '../../../API'
import AccessLockIcon from '../../../common/icons/AccessLockIcon'
import AccessOpenIcon from '../../../common/icons/AccessOpenIcon'
import { useTranslation } from 'react-i18next'

interface IPrivacyOptionsButtonProps {
  accessType: AccessType | null | undefined
  togglePrivacyModalVisibility: () => void
  isOnVideoList: boolean
}
const PrivacyOptionsButton = (props: IPrivacyOptionsButtonProps) => {
  const { t } = useTranslation('library')
  const theme = useTheme()
  return (
    <Tooltip title={t('common.privacyBtn')}>
      <IconButton
        style={{
          boxShadow: props.isOnVideoList ? 'none' : 'auto',
          backgroundColor:
            props.accessType === AccessType.protected && !props.isOnVideoList
              ? theme.palette.primary.main
              : props.accessType === AccessType.protected && props.isOnVideoList
              ? ''
              : '',
        }}
        aria-label="privacy-options"
        name="privacy-options"
        onClick={props.togglePrivacyModalVisibility}
        size="small"
      >
        {props.accessType === AccessType.protected ? (
          <AccessLockIcon
            color={
              props.accessType === AccessType.protected && props.isOnVideoList ? theme.palette.primary.main : 'white'
            }
          />
        ) : (
          <AccessOpenIcon />
        )}
      </IconButton>
    </Tooltip>
  )
}

export default PrivacyOptionsButton
