import UploadQueue from '../../../../../services/UploadQueue'
import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../../../../components/_refactored/Button'
import { Box, Typography } from '@mui/material'
import Modal from '../../../../../../components/_refactored/Modal'
import { useTranslation } from 'react-i18next'

const RecordingUploadErrorModal = () => {
  const retryTriggerInterval = useRef<null | number>(null)
  const [counter, setCounter] = useState(5)
  const { t: g } = useTranslation('general')

  useEffect(() => {
    if (retryTriggerInterval.current) clearInterval(retryTriggerInterval.current)

    retryTriggerInterval.current = window.setInterval(() => {
      setCounter((counter) => counter - 1)
    }, 1000)
  }, [setCounter])

  const retryUpload = () => {
    if (retryTriggerInterval.current) {
      clearInterval(retryTriggerInterval.current)
    }
    UploadQueue.restoreLastRecordings()
  }

  useEffect(() => {
    if (!retryTriggerInterval.current) return

    if (counter === 0) {
      retryUpload()
    }
  }, [counter, retryTriggerInterval])

  return (
    <Modal open={true}>
      <>
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          style={{ fontSize: '15px', marginBottom: '30px' }}
        >
          {g('recordControl.uploadErrorModal.title')}
        </Typography>
        <Typography variant="body1" color="textSecondary" align="center" style={{ fontSize: '15px' }}>
          {g('recordControl.uploadErrorModal.retryCounter')}
        </Typography>
        <Box width="100%" display="flex" justifyContent="center" marginTop="15px">
          <Box
            width="43px"
            borderRadius="50px"
            height="43px"
            bgcolor="primary.main"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1" style={{ color: 'white', fontWeight: 500, fontSize: '18px' }}>
              {counter}
            </Typography>
          </Box>
        </Box>
        <Button variant="contained" style={{ marginTop: '50px' }} fullWidth color="primary" onClick={retryUpload}>
          {g('recordControl.uploadErrorModal.retryBtn')}
        </Button>
      </>
    </Modal>
  )
}

export default RecordingUploadErrorModal
