import React from 'react'
import IntegrationsView from './view/Integrations.view'
import useIntegrationsHook from './hooks/useIntegrations.hook'
import PageTitle from '../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

const Integrations = () => {
  const state = useIntegrationsHook()
  const { t: g } = useTranslation('general')

  return (
    <>
      <PageTitle name={g('pageName.integrationSettings')} />
      <IntegrationsView {...state} />
    </>
  )
}

export default Integrations
