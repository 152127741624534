import React, { useContext } from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import PostsList from './ui/partials/PostsList'
import Footer from './ui/partials/Footer'
import { ChatContext } from './common/contexts/ChatContext'
import { AuthContext } from '../../../../../common/providers/AuthStatusProvider'
import FullScreenLoader from '../../../../../components/_refactored/Loaders/FullScreenLoader'
import { useLocation, useParams } from 'react-router'
import NewMessage from './ui/partials/NewMessage'
import { TUseChatParticipant } from '../../../common/hooks/useChatParticipants'
import FileDropzone from './ui/components/PostList/FileDropzone'
import useFileDropzone from './common/hooks/useFileDropzone'
import { useSelector } from 'react-redux'
import ChatPlayer from './ui/components/ChatPlayer'
import useReactionsSubscription from './common/hooks/reactions/useReactionsSubscription'
import usePostSeenStatus from './common/hooks/posts/usePostSeenStatus'
import { RootState } from '../../../common/redux/store/store'

const ChatContent = (props: TUseChatParticipant) => {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const theme = useTheme()
  const isTablet = useMediaQuery(`(max-width:1024px)`)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { id: channelId } = useParams() as { id: string }
  const { acceptedFiles, rootRef, getInputProps, isDragActive, dropzoneRootProps } = useFileDropzone()
  useReactionsSubscription()
  usePostSeenStatus()
  const isSupportChatWithUser = auth.user?.id === process.env.REACT_APP_SUPPORT_ID
  const sidebarParams = useSelector((state: RootState) =>
    isSupportChatWithUser ? state.chat.sidebarParams : state.chat.rightSidePanelParams,
  )

  if (!auth.isInitialized) {
    return <FullScreenLoader />
  }

  if (location.pathname.includes('chat') && !channelId) {
    return <FullScreenLoader withoutSidebar />
  }

  return (
    <ChatContext>
      <Box
        width={
          location.pathname.includes('support')
            ? '100%'
            : location.pathname.includes('/app/chat/new')
            ? isMobile
              ? '100%'
              : `calc(100% - 310px)`
            : auth.isAuthenticated && !isTablet
            ? `calc(100% - 310px - ${sidebarParams?.width || 344}px)`
            : !isMobile && auth.isAuthenticated && isTablet
            ? 'calc(100% - 300px)'
            : '100vw'
        }
        height="100vh"
        maxHeight="100vh"
        display="flex"
        // position="relative"
        ref={rootRef}
        marginLeft={auth.isAuthenticated ? '0' : '60px'}
        {...dropzoneRootProps}
      >
        <FileDropzone
          isDragActive={isDragActive}
          getInputProps={getInputProps}
          rootRef={rootRef}
          acceptedFiles={acceptedFiles}
        />
        {channelId === 'new' ? (
          <NewMessage />
        ) : (
          <>
            <ChatPlayer />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              width="100%"
              height="100%"
              position="relative"
            >
              <PostsList {...props} />
              <Footer />
            </Box>
          </>
        )}
      </Box>
    </ChatContext>
  )
}

export default ChatContent
