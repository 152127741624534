import { Auth } from 'aws-amplify'
import { createAuthLink, AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import aws_exports from 'aws-exports'
import createSentryErrorLink from './createSentryErrorLink'

const getAuth = async () => {
  try {
    return await Auth.currentSession()
  } catch (e: any) {
    console.error('[Cognito User Pool Apollo Client] error: ', e)
  }
}

const config = {
  url: aws_exports.aws_appsync_graphqlEndpoint,
  region: aws_exports.aws_project_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await getAuth())?.getIdToken()?.getJwtToken(),
  } as AuthOptions,
}

const mergeUnique = (items: { __ref: string }[] = []) => {
  if (!items) {
    return []
  }

  const result = []
  const map = new Map()

  for (const item of items) {
    const { __ref } = item
    if (!map.has(__ref)) {
      map.set(__ref, true)
      result.push(item)
    }
  }

  return result
}

function mergeIncomingDataForPagination() {
  return {
    merge(existing: any, incoming: any): any {
      let items = existing ? existing.items : []
      items = mergeUnique([...items, ...incoming.items])

      return {
        nextToken: incoming && incoming?.nextToken,
        items: items,
      }
    },
  }
}

function mergeIncomingDataForListPostsByChannelPagination() {
  return {
    merge(existing: any, incoming: any, { readField }: any): any {
      let items = existing ? existing.items : []
      items = mergeUnique([...items, ...incoming.items])

      const sortedItems = items?.length
        ? [...items].sort(
            // @ts-ignore
            (itemA, itemB) => new Date(readField('createdAt', itemB)) - new Date(readField('createdAt', itemA)),
          )
        : []

      const sortedExistingDates = existing?.items?.length
        ? [...existing?.items?.map((item: any) => readField('createdAt', item))].sort(
            // @ts-ignore
            (a, b) => new Date(b) - new Date(a),
          )
        : []

      const nextToken =
        existing?.items?.length &&
        ([...existing?.items]?.map((ex) => ex?.__ref)?.includes(incoming?.items?.[0]?.__ref) ||
          (incoming?.items?.length && readField('createdAt', incoming.items[0]) > sortedExistingDates?.[0]))
          ? existing?.nextToken
          : incoming && incoming?.nextToken

      return {
        nextToken: nextToken,
        items: sortedItems,
      }
    },
  }
}

function mergeIncomingDataForListCustomerRecentChannelsPagination() {
  return {
    merge(existing: any, incoming: any, { readField }: any): any {
      let items = existing ? existing.items : []
      items = mergeUnique([...items, ...incoming.items])

      const isExistingNextToken = (existing?.items || []).find(
        (item: any) => readField('id', item) === readField('id', incoming.items?.[0]),
      )

      const nextToken = isExistingNextToken ? existing?.nextToken : incoming && incoming?.nextToken

      return {
        nextToken: nextToken,
        items: items,
      }
    },
  }
}

function readDataForPagination() {
  return {
    read(existing: any): any {
      if (existing) {
        return {
          nextToken: existing.nextToken,
          items: existing.items,
        }
      }
    },
  }
}

function handleTokenPaginatedField<T>(keyArgs?: string[]) {
  if (keyArgs) {
    return {
      keyArgs,
      ...mergeIncomingDataForPagination(),
      ...readDataForPagination(),
    }
  }
  return {
    ...mergeIncomingDataForPagination(),
    ...readDataForPagination(),
  }
}

function handleTokenPaginatedListPostsByChannelField<T>(keyArgs?: string[]) {
  if (keyArgs) {
    return {
      keyArgs,
      ...mergeIncomingDataForListPostsByChannelPagination(),
      ...readDataForPagination(),
    }
  }
  return {
    ...mergeIncomingDataForListPostsByChannelPagination(),
    ...readDataForPagination(),
  }
}

function handleTokenPaginatedListCustomerRecentChannelsField<T>(keyArgs?: string[]) {
  if (keyArgs) {
    return {
      keyArgs,
      ...mergeIncomingDataForListCustomerRecentChannelsPagination(),
      ...readDataForPagination(),
    }
  }
  return {
    ...mergeIncomingDataForListCustomerRecentChannelsPagination(),
    ...readDataForPagination(),
  }
}

export default new ApolloClient({
  link: ApolloLink.from([
    createSentryErrorLink(),
    createAuthLink(config) as unknown as ApolloLink,
    createSubscriptionHandshakeLink(config) as unknown as ApolloLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          listPostsByChannel: handleTokenPaginatedListPostsByChannelField(['channelId']),
          listVideosByOwner: handleTokenPaginatedField(['customerId']),
          // listTypeOfPostInChannel: handleTokenPaginatedField(['channelId']),
          listCustomerRecentChannels: handleTokenPaginatedListCustomerRecentChannelsField(['customerId']),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'network-only',
    },
  },
})
