import React from 'react'
import useContacts from './hooks/useContacts'
import ContactsView from './view/Contacts.view'
import PageTitle from '../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

const Contacts = () => {
  const state = useContacts()
  const { t: g } = useTranslation('general')

  return (
    <>
      <PageTitle name={g('pageName.contacts')} />
      <ContactsView {...state} />
    </>
  )
}

export default Contacts
