import React from 'react'

interface IPencilIconProps {
  width?: string
  height?: string
}

const PencilIcon = (props: IPencilIconProps) => {
  return (
    <svg
      width={props.width || '18'}
      height={props.height || '18'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8712 0C14.4874 0 14.1035 0.146578 13.8107 0.439453L12.0001 2.25L15.7501 6L17.5607 4.18945C18.1464 3.6037 18.1464 2.65411 17.5607 2.06836L15.9318 0.439453C15.6389 0.146578 15.255 0 14.8712 0ZM10.5001 3.75L1.69484 12.5552C1.69484 12.5552 2.38317 12.4935 2.63967 12.75C2.89617 13.0065 2.68502 14.685 3.00002 15C3.31503 15.315 4.98296 15.0933 5.22221 15.3325C5.46146 15.5718 5.44487 16.3052 5.44487 16.3052L14.2501 7.5L10.5001 3.75ZM0.750006 15L0.0424808 17.0039C0.0146667 17.083 0.000307379 17.1662 0 17.25C0 17.4489 0.0790182 17.6397 0.219672 17.7803C0.360325 17.921 0.551092 18 0.750006 18C0.833835 17.9997 0.91702 17.9853 0.996101 17.9575C0.998548 17.9566 1.00099 17.9556 1.00343 17.9546L1.02247 17.9487C1.02394 17.9478 1.0254 17.9468 1.02686 17.9458L3.00002 17.25L1.87501 16.125L0.750006 15Z"
        fill="#7A7A86"
      />
    </svg>
  )
}

export default PencilIcon
