import { Box } from '@mui/material'
import * as React from 'react'
type TrashIconProps = {
  color?: string
}

const TrashIcon = (props: TrashIconProps) => {
  return (
    <Box display="flex">
      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.25 0L4.375 0.9H0V2.7H0.875V16.2C0.875 16.67 1.04241 17.1491 1.37231 17.4885C1.70222 17.8278 2.16806 18 2.625 18H11.375C11.8319 18 12.2978 17.8278 12.6277 17.4885C12.9576 17.1491 13.125 16.67 13.125 16.2V2.7H14V0.9H9.625L8.75 0H5.25ZM2.625 2.7H11.375V16.2H2.625V2.7ZM4.375 4.5V14.4H6.125V4.5H4.375ZM7.875 4.5V14.4H9.625V4.5H7.875Z"
          fill={props.color || '#7A7A86'}
        />
      </svg>
    </Box>
  )
}

export default TrashIcon
