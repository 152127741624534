import { S3FileAccessLevel } from '../../../../../../../../API'

export const videoPostAudience = /* GraphQL */ `
  query ListVideoAudience(
    $videoId: ID
    $customerId: ModelIDKeyConditionInput
    $filter: ModelScreensightVideoAudienceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVideoAudience(
      videoId: $videoId
      customerId: $customerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        videoId
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
        }
        guestName
        createdAt
      }
      nextToken
    }
  }
`

export type VideoPostAudienceQuery = {
  listVideoAudience?: {
    __typename: 'ModelScreensightVideoAudienceConnection'
    items: Array<{
      __typename: 'screensightVideoAudience'
      videoId: string
      customerId: string
      customerMeta?: {
        __typename: 'screensightCustomer'
        id: string
        email?: string | null
        firstName?: string | null
        lastName?: string | null
        profilePhoto?: {
          __typename: 'S3Object'
          key: string
          bucket: string
          accessLevel?: S3FileAccessLevel | null
          identityId?: string | null
        } | null
      } | null
      guestName?: string | null
      createdAt: string
    } | null>
    nextToken?: string | null
  } | null
}
