import { updateRightSidePanelParams } from 'pages/Chat/common/redux/store/chat-actions'
import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../common/redux/store/store'

const useResize = () => {
  const sidebarParams = useSelector((state: RootState) => state.chat.rightSidePanelParams)
  const dispatch = useDispatch()
  const getStoredParams = () => {
    return JSON.parse(localStorage.getItem('rightSidePanelParamsNormalUser') || '{}')
  }

  const handleWindowResizing = async () => {
    await getStoredParams()
    if (getStoredParams().width > 294) {
      dispatch(updateRightSidePanelParams({ width: 294, height: '100vh', x: window.innerWidth - 310, y: 0 }))
      return
    }
    dispatch(
      updateRightSidePanelParams({
        width: getStoredParams().width,
        height: '100%',
        x: window.innerWidth - getStoredParams().width - 60,
        y: 0,
      }),
    )
  }

  useLayoutEffect(() => {
    handleWindowResizing()
    window.addEventListener('resize', handleWindowResizing)
    return () => window.removeEventListener('resize', handleWindowResizing)
  }, [])

  useLayoutEffect(() => {
    localStorage.setItem('rightSidePanelParamsNormalUser', JSON.stringify(sidebarParams))
  }, [sidebarParams])

  return { sidebarParams }
}
export default useResize
