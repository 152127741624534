export const postFields = `
  {
  __typename
    channelId
    accessId
    customerId
    postType
    video {
    __typename
      id
      accessId
      accessType
      createdAt
      customerId
      duration
      width
      height
      fileObject {
      __typename
        key
        bucket
	  }
      customerMeta {
      __typename
        id
        email
        firstName
        lastName
      }
      channelId
      name
      title
      status
      thumbnailObject {
      __typename
        key
        bucket
      }
      updatedAt
      transcriptionSummaryStatus
      transcriptionSummaryError
      transcriptionSummary
	  transcriptionObject {
	  	__typename
	  	key
		bucket
	  }
    }
    comment {
    __typename
      id
      customerId
      videoId
      channelId
      parentId
      content
      richContent
      videoTimestamp
      createdAt
      updatedAt
    }
    emailMessage {
    __typename
      id
      videoId
      customerId
      channelId
      emailStatus {
      __typename
        email
        status
        reject_reason
        _id
        deliveredAt
        opens
      }
      createdAt
      updatedAt
    }
    text {
    __typename
      content
      richContent
      customerId
    }
    file {
    __typename
      fileName
      fileObject {
      __typename
        key
        bucket
        accessLevel
        identityId
      }
      status
      customerId
      mimeType
      metadata
    }
    engagement {
    __typename
      items {
      __typename
        customerId
        seen
        seenAt
        videoProgress
      }
      nextToken
    }
    referencePostId
    referencePost {
    __typename
      channelId
      accessId
      customerId
      postType
      reactions {
      __typename
        items {
        __typename
          postId
          customerId
          unicode
          videoTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      video {
      __typename
        id
        createdAt
        customerId
        duration
        fileObject {
		  __typename
			key
			bucket
	    }
        customerMeta {
        __typename
          id
          email
          firstName
          lastName
        }
        channelId
        title
        status
        filePresignedUrl
        thumbnailObject {
        __typename
          key
          bucket
        }
        accessType
        accessId
        updatedAt
        transcriptionSummaryStatus
      	transcriptionSummaryError
        transcriptionSummary
	    transcriptionObject {
		  __typename
		  key
		  bucket
	    }
	    width
	    height
      }
      comment {
      __typename
        id
        customerId
        videoId
        channelId
        parentId
        content
        richContent
        videoTimestamp
        createdAt
        updatedAt
      }
      emailMessage {
      __typename
        id
        videoId
        customerId
        channelId
        emailStatus {
        __typename
          email
          status
          reject_reason
          _id
          deliveredAt
          opens
        }
        createdAt
        updatedAt
      }
      text {
      __typename
        content
        richContent
        customerId
      }
      file {
      __typename
        fileName
        fileObject {
        __typename
          key
          bucket
          accessLevel
          identityId
        }
        status
        customerId
        mimeType
        metadata
      }
      readHorizon
      createdAt
      updatedAt
      id
    }
    reactions {
    __typename
      items {
      __typename
        postId
        customerId
        unicode
        videoTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
    readHorizon
    createdAt
    updatedAt
    id
  }
`
