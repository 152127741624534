import { useContext, useState } from 'react'
import { useParams } from 'react-router'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'

const useChatNewVideo = () => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const [isNewVideoModalVisible, setIsNewVideoModalVisible] = useState(false)
  const [isLibraryModalVisible, setIsLibraryModalVisible] = useState(false)
  const [defaultVideoTitle, setDefaultVideoTitle] = useState('')

  const openLibraryModal = () => setIsLibraryModalVisible(true)
  const closeLibraryModal = () => setIsLibraryModalVisible(false)

  const openNewVideoModal = () => setIsNewVideoModalVisible(true)
  const closeNewVideoModal = () => setIsNewVideoModalVisible(false)

  const handleChangeDefaultTitle = (title?: string) => setDefaultVideoTitle(title || '')

  return {
    channelId,
    isNewVideoModalVisible,
    isLibraryModalVisible,
    openNewVideoModal,
    openLibraryModal,
    closeNewVideoModal,
    closeLibraryModal,
    defaultVideoTitle,
    handleChangeDefaultTitle,
  }
}

export default useChatNewVideo
