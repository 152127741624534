import * as React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import {
  AccessType,
  PostEngagementConnection,
  screensightReaction,
  screensightVideo,
  screensightVideoEmailMessage,
  VideoStatus,
} from '../../../../../../../../API'
import useVideoPost from '../../../common/hooks/posts/useVideoPost'
import CloudFrontImage from '../../../../../../../../components/_refactored/CloudFrontImage'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import moment from 'moment/moment'
import Checkmark from '../../../../../../../../common/icons/Checkmark'
import CopyIcon from '../../../../../../../../common/icons/CopyIcon'
import CopyToClipboard from 'react-copy-to-clipboard'
import VideoPostUploading from '../VideoPostUploading'
import StatsIcon from '../../../../../../../../common/icons/StatsIcon'
import { Link } from 'react-router-dom'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import Avatar from '../../../../../../../../components/_refactored/Avatar/Avatar'
import VideoPostActivity from '../VideoPostActivity'
import { useTranslation } from 'react-i18next'
import VideoPostAISummary from '../VideoPostAISummary'
import ReactionsList from './ReactionsList'
import AccessLockIcon from '../../../../../../../../common/icons/AccessLockIcon'
import '../../../common/styles/videoPost.style.scss'
import '../../../common/styles/video.style.scss'
import VideoPostProgress from './VideoPostProgress'
import VideoPostReactions from './VideoPostReactions'

interface IVideoPost {
  owner?: boolean | undefined
  videoData: screensightVideo
  postId: string
  isEmailPost?: boolean
  partnerId?: string
  emailVideoData?: screensightVideoEmailMessage | null | undefined
  engagement?: PostEngagementConnection | null
  reactions?: Array<screensightReaction | null>
}

const VideoPost = (props: IVideoPost) => {
  const {
    auth,
    theme,
    isMobile,
    history,
    isTablet,
    handleOpenVideoModal,
    copied,
    onCopied,
    audience,
    audienceLoading,
    seenVideoPostIds,
    videoProgress,
  } = useVideoPost(props.videoData, props.postId, props.owner, props?.engagement)
  const isGif = props.videoData.thumbnailObject?.key?.includes('.gif')
  const { t } = useTranslation('chat')
  let isVideoHorizontal = true
  if (props.videoData?.width && props.videoData?.height) {
    isVideoHorizontal = props.videoData.width > props.videoData.height
  }
  const horizontalOnDesktop = isVideoHorizontal && !isTablet
  const verticalOnTablet = isTablet && !isVideoHorizontal

  if (props.videoData.status !== VideoStatus.ok) {
    return (
      <VideoPostUploading
        owner={props.owner}
        title={props.videoData.title}
        createdAt={props.videoData.createdAt}
        isMobile={isMobile}
      />
    )
  }

  return (
    <Box
      maxWidth="calc(100% - 70px)"
      width={horizontalOnDesktop ? '517px' : verticalOnTablet ? '250px' : '350px'}
      data-testid="video-post"
    >
      <Box
        display="flex"
        minWidth={isMobile ? '50%' : 'auto'}
        className="chat__desktop"
        flexDirection="column"
        alignItems={props.owner ? 'flex-end' : 'flex-start'}
        height={horizontalOnDesktop ? '180px' : isTablet ? 'auto' : '250px'}
        borderRadius="10px"
        margin="10px 0 0 0"
        bgcolor="background.paper"
        padding="30px"
        position="relative"
      >
        <Box width="100%" height="100%" display="flex" flexDirection={isTablet ? 'column' : 'row'}>
          <Box position="relative">
            {!audienceLoading && !seenVideoPostIds?.includes(props.postId) && !audience?.length && (
              <Box
                position="absolute"
                top="0"
                left="-11px"
                zIndex="1"
                bgcolor="primary.main"
                width="4px"
                borderRadius="10px"
                data-testid="video-post-blue-bar"
              />
            )}
            {props.videoData.accessType === AccessType.protected && (
              <Box
                position="absolute"
                width="100%"
                height={isTablet ? '97%' : '120px'}
                zIndex={2}
                borderRadius="12px"
                style={{
                  backgroundColor: 'rgba(23, 23, 23, 0.4)',
                  backdropFilter: 'blur(6px)',
                }}
                display="flex"
              />
            )}
            <CloudFrontImage
              image={props.videoData.thumbnailObject?.key || ''}
              width={horizontalOnDesktop ? '190' : '120'}
              disableResize={isGif}
              height={isVideoHorizontal ? '120' : '190'}
              autoScale={isTablet}
              imgStyle={{
                borderRadius: '12px',
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                width: horizontalOnDesktop ? '190px' : isTablet ? '100%' : '120px',
                height: horizontalOnDesktop ? '120px' : isTablet ? '100%' : '190px',
                objectFit: 'cover',
                maxHeight: verticalOnTablet ? '325px' : 'auto',
              }}
            />
            <IconButton
              style={{
                zIndex: 3,
                width: '50px',
                height: '50px',
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                boxShadow: 'none',
                backdropFilter: 'blur(2.61905px)',
                background: props.videoData.accessType === AccessType.protected ? 'black' : 'rgba(36, 131, 247, 0.5)',
              }}
              onClick={handleOpenVideoModal}
            >
              {props.videoData.accessType === AccessType.protected ? (
                <AccessLockIcon color={'white'} />
              ) : (
                <PlayArrowRoundedIcon style={{ color: 'white' }} />
              )}
            </IconButton>
          </Box>
          <Box
            marginLeft={isTablet ? '0' : '20px'}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width={horizontalOnDesktop ? 'calc(100% - 210px)' : isTablet ? '100%' : 'calc(100% - 100px)'}
            height={horizontalOnDesktop ? '126px' : isTablet ? 'auto' : '195px'}
          >
            <Box>
              <Typography
                variant="h6"
                className={isVideoHorizontal ? 'chat__video-horizontal-post-title' : 'chat__video-vertical-post-title'}
                sx={{
                  fontSize: '17px',
                  fontWeight: 500,
                  lineHeight: '21px',
                  marginBottom: '8px',
                  marginTop: isTablet ? '10px' : '0',
                }}
              >
                {props.owner ? (
                  <Link
                    to={`/video/${props.videoData.accessId || props.videoData.id}`}
                    style={{ textDecoration: 'none', color: theme.palette.text.primary }}
                  >
                    {props.videoData.title}
                  </Link>
                ) : (
                  props.videoData.title
                )}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ fontSize: '15px', lineHeight: '18px' }}>
                {moment(props.videoData.createdAt).format('MMM DD, YYYY')}
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent={audience?.length && props.owner ? 'space-between' : 'flex-end'}
              marginTop={isTablet || isMobile ? '12px' : '0'}
            >
              {props.owner && !!audience?.length && (
                <Box display="flex" alignItems="center" data-testid="video-post-viewers-section">
                  <VisibilityOutlinedIcon style={{ color: '#7A7A86' }} />
                  {audience.map((item, index) => (
                    <Avatar
                      key={index}
                      imgKey={item?.customerMeta?.profilePhoto?.key}
                      email={item?.customerMeta?.email || ''}
                      firstName={item?.customerMeta?.firstName || ''}
                      style={{ margin: '0 3px' }}
                      imgStyle={{ margin: '0 3px' }}
                    />
                  ))}
                </Box>
              )}
              <Box display="flex" alignItems="center">
                {auth.isAuthenticated && auth.user?.id === props.videoData.customerId && (
                  <IconButton
                    data-testid="video-post-stats-button"
                    sx={{
                      padding: '2px',
                      width: '32px',
                      height: '32px',
                      margin: '0 2px',
                      background: 'transparent',
                      boxShadow: 'none',
                    }}
                    onClick={() => history.push(`/video/${props?.videoData?.accessId || props?.videoData?.id}/stats`)}
                    color="secondary"
                  >
                    <StatsIcon />
                  </IconButton>
                )}

                <CopyToClipboard
                  onCopy={onCopied}
                  text={`${process.env.REACT_APP_SSIGHT_SHARING_URL}/${
                    props?.videoData?.accessId || props?.videoData?.id
                  }`}
                >
                  <IconButton
                    sx={{
                      padding: '2px',
                      width: '32px',
                      height: '32px',
                      margin: '0 2px',
                      background: 'transparent',
                      boxShadow: 'none',
                    }}
                    color="secondary"
                    disabled={copied}
                  >
                    {copied ? (
                      <>
                        <Checkmark />
                      </>
                    ) : (
                      <CopyIcon />
                    )}
                  </IconButton>
                </CopyToClipboard>
              </Box>
            </Box>
          </Box>
        </Box>
        <VideoPostReactions
          reactions={props?.reactions?.filter(
            (reaction) => reaction?.customerId === auth.user?.id || reaction?.customerId === props?.partnerId,
          )}
          duration={props.videoData.duration}
        />
        <VideoPostProgress duration={props.videoData.duration} progress={videoProgress} />
      </Box>
      <VideoPostAISummary
        beforeControls={<ReactionsList owner={props.owner} postId={props.postId} videoId={props.videoData.id} />}
        owner={props.owner}
        videoData={props?.videoData}
      />

      {!!audience?.length && !!audience?.[0]?.createdAt && !props.isEmailPost && props.owner && (
        <Typography
          align="right"
          variant="body1"
          data-testid="video-post-videoSeen"
          sx={{
            fontSize: '12px',
            marginTop: '4px!important',
            color: theme.palette.secondary.main,
          }}
        >
          {t('chatBody.videoPostActivity.videoSeen')} {moment(audience[0].createdAt).format('MMM DD, YYYY, h:mm A')}
        </Typography>
      )}
      {props.isEmailPost && auth.isAuthenticated && props.owner && props.emailVideoData && (
        <Box width="100%" textAlign="right">
          <VideoPostActivity
            emailVideoData={props.emailVideoData}
            partnerId={props.partnerId || ''}
            videoId={props.videoData.id}
          />
        </Box>
      )}
    </Box>
  )
}

export default VideoPost
