import {useEffect, useRef, useState} from "react";

function useInitialUILoadTime() {
	const start = useRef(performance.now());
	const [loadTime, setLoadTime] = useState(0);

	useEffect(() => {
		const end = performance.now();
		const time = end - start.current;
		const loadTime = parseFloat(time.toFixed(2))
		console.log(`UI load time: ${loadTime}ms`);
		setLoadTime(loadTime);
	}, []);
	
	return loadTime;
}

export default useInitialUILoadTime;
