import { useContext } from 'react'
import { AuthContext } from '../providers/AuthStatusProvider'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import CognitoApolloClient from './CognitoApolloClient'
import IamApolloClient from './IamApolloClient'
import { QueryHookOptions } from '@apollo/client/react/types/types'

const useApolloQuery = <T, V>(queryName: string, queryHookOptions?: QueryHookOptions<T, V>) => {
  const auth = useContext(AuthContext)
  return useQuery<T, V>(gql(queryName), {
    ...queryHookOptions,
    skip: queryHookOptions?.skip ? queryHookOptions.skip : !auth.isInitialized,
    client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
  })
}

export default useApolloQuery
