import { useLazyQuery } from '@apollo/client'
import {
  ListTypeOfPostInChannelQueryVariables,
  ModelSortDirection,
  OnPostEventSubscription,
  PostTypeFilter,
  screensightReaction,
} from '../../../../../../../API'
import gql from 'graphql-tag'
import CognitoApolloClient from '../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../common/clients/IamApolloClient'
import { listVideosInChannel, ListVideosInChannelQuery, TVideoInChannel } from '../api/listVideosInChannel'
import { useLocation, useParams } from 'react-router'
import { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'
import { useDispatch, useSelector } from 'react-redux'
import {
  addVideoPlayerReactions,
  clearVideoPlayerReactions,
  clearVideoPlaylistData,
  updateChatPlayerAutoplay,
  updateVideoPlaylistData,
} from '../../../../../common/redux/store/chat-actions'
import * as queryString from 'query-string'
import { RootState } from '../../../../../common/redux/store/store'

const useSharedVideos = ({
  partnerId,
  handleSharedVideosLoaded,
}: {
  partnerId?: string | null
  handleSharedVideosLoaded: () => void
}) => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const location = useLocation()

  const dispatch = useDispatch()

  const videoPlaylistProgress = useSelector((state: RootState) => state.chat.videoPlaylistProgress)
  const videoPlaylistData = useSelector((state: RootState) => state.chat.videoPlaylistData)

  const [nextToken, setNextToken] = useState<string | null | undefined>(null)
  const [autoPlayChecked, setAutoPlayChecked] = useState<boolean>(localStorage.getItem('chatPlayerAutoplay') === 'true')
  const [componentInitialized, setComponentInitialized] = useState<boolean>(false)
  const [videosVisible, setVideosVisible] = useState<boolean>(true)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const onPostEventSubscriptionData = useSelector((state: RootState) => state.chat.onPostEventSubscriptionData)

  const queryStringVideoId = queryString.parse(location.search)?.play
  const queryStringPostId = queryString.parse(location.search)?.postId
  const channelId = channelParamsId || auth.user?.supportChannelId

  const [getListOfVideos, { loading }] = useLazyQuery<ListVideosInChannelQuery, ListTypeOfPostInChannelQueryVariables>(
    gql(listVideosInChannel),
    {
      client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    },
  )

  const updateVideoPlaylist = (data?: OnPostEventSubscription) => {
    const subEvent = data?.onPostEvent
    if (
      (subEvent?.action !== 'create' && subEvent?.action !== 'update') ||
      subEvent?.post?.customerId === auth.user?.id ||
      subEvent.channelId !== channelId ||
      (subEvent?.post?.postType !== 'video' &&
        subEvent?.post?.postType !== 'shared' &&
        subEvent?.post?.postType !== 'email_video')
    )
      return
    const post = subEvent?.post
    dispatch(updateVideoPlaylistData([post] as TVideoInChannel[]))
    const postId = post?.postType === 'video' ? post?.id : post?.referencePost?.id
    const reactions = post?.postType === 'video' ? post?.reactions?.items : post?.referencePost?.reactions?.items
    if (!postId) return
    dispatch(addVideoPlayerReactions(postId, (reactions as screensightReaction[]) || []))
  }

  useEffect(() => {
    if (!onPostEventSubscriptionData) return
    updateVideoPlaylist(onPostEventSubscriptionData)
  }, [onPostEventSubscriptionData])

  const toggleAutoPlay = (e: any) => {
    setAutoPlayChecked((prevState) => !prevState)
    localStorage.setItem('chatPlayerAutoplay', JSON.stringify(e.target.checked))
    dispatch(updateChatPlayerAutoplay(e.target.checked))
  }

  useEffect(() => {
    const getData = async () => {
      setComponentInitialized(false)
      dispatch(clearVideoPlaylistData())
      dispatch(clearVideoPlayerReactions())
      setNextToken(null)
      if (auth.user?.pending || !channelId || !partnerId) return
      const res = await getListOfVideos({
        variables: {
          channelId: channelId || '',
          sortDirection: ModelSortDirection.DESC,
          limit: 20,
          postType: PostTypeFilter.video,
          customerId: partnerId,
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
      })
      dispatch(updateVideoPlaylistData(res.data?.listTypeOfPostInChannel?.items as TVideoInChannel[]) || [])
      res.data?.listTypeOfPostInChannel?.items?.forEach((item) => {
        const postId = item?.postType === 'video' ? item?.id : item?.referencePost?.id
        const reactions = item?.postType === 'video' ? item?.reactions?.items : item?.referencePost?.reactions?.items
        if (!postId) return
        dispatch(addVideoPlayerReactions(postId, (reactions as screensightReaction[]) || []))
      })
      setNextToken(res.data?.listTypeOfPostInChannel?.nextToken)
      setComponentInitialized(true)
    }
    getData()
  }, [auth.user?.pending, channelId, auth.isAuthenticated, partnerId])

  useEffect(() => {
    if (!componentInitialized) return
    handleSharedVideosLoaded()
  }, [componentInitialized])

  useEffect(() => {
    if (!queryStringVideoId || !queryStringPostId || !wrapperRef.current || !componentInitialized) return
    const elem = document.getElementById(`chat__playlist-video-${queryStringPostId}`)
    if (elem) {
      wrapperRef.current.scrollTo(
        0,
        (elem?.offsetTop || 0) -
          (wrapperRef.current?.offsetTop || 0) -
          (wrapperRef.current?.getBoundingClientRect()?.height || 0) / 2 +
          (elem?.getBoundingClientRect()?.height || 0) / 2,
      )
    }
  }, [queryStringVideoId, queryStringPostId, wrapperRef.current, componentInitialized])

  const loadNextVideos = async () => {
    if (!nextToken || !channelId || !partnerId) return
    const res = await getListOfVideos({
      variables: {
        limit: 10,
        channelId: channelId || '',
        nextToken: nextToken,
        postType: PostTypeFilter.video,
        customerId: partnerId,
        sortDirection: ModelSortDirection.DESC,
      },
    })
    dispatch(updateVideoPlaylistData(res.data?.listTypeOfPostInChannel?.items as TVideoInChannel[]) || [])
    res.data?.listTypeOfPostInChannel?.items?.forEach((item) => {
      const postId = item?.postType === 'video' ? item?.id : item?.referencePost?.id
      const reactions = item?.postType === 'video' ? item?.reactions?.items : item?.referencePost?.reactions?.items
      if (!postId) return
      dispatch(addVideoPlayerReactions(postId, (reactions as screensightReaction[]) || []))
    })
    setNextToken(res.data?.listTypeOfPostInChannel?.nextToken)
  }

  const toggleVideosVisible = () => {
    setVideosVisible((prevState) => !prevState)
  }

  return {
    videosListLoading: loading,
    data: Object.values(videoPlaylistData) as TVideoInChannel[],
    hasMore: !!nextToken,
    loadNextVideos,
    autoPlayChecked,
    toggleAutoPlay,
    wrapperRef,
    videoPlaylistProgress,
    videosVisible,
    toggleVideosVisible,
    auth,
  }
}

export default useSharedVideos
