import React, { createContext, useReducer } from 'react'

interface IChatContextState {
  editedPostData: {
    postId: string
    content: string
    richContent: string
  } | null
  responseBoxData:
    | {
        type: 'video' | 'comment' | 'videoReply' | 'file' | 'textReply' | 'fileReply' | 'commentReply'
        videoId?: string
        customerId?: string
        accessId?: string
        photoKey?: string
        thumbnailObject: any
        title: string
        author: string
        videoTimestamp?: string
        parentId?: string
        file?: File
        fileName?: string
        fileType?: string
        videoCommentTimestamp?: number
        postType?: any
        referencePostAuthorId: string
        engagement?: any
        postId?: string
      }
    | undefined
}

const initialState: IChatContextState = {
  responseBoxData: undefined,
  editedPostData: null,
}

export type ChatContextAction = {
  type: 'setEditedPost' | 'setResponseBoxData'
  value: any
}

const editThumbnailReducer = (state: IChatContextState, action: ChatContextAction): IChatContextState => {
  switch (action.type) {
    case 'setEditedPost':
      return {
        ...state,
        editedPostData: action.value,
      }

    case 'setResponseBoxData':
      return {
        ...state,
        responseBoxData: action.value,
      }

    default:
      throw new Error()
  }
}

export const ChatStateContext = createContext<IChatContextState>(initialState)
const ChatDispatchContext = createContext<React.Dispatch<ChatContextAction> | null>(null)

interface IChatContextProps {
  children: React.ReactNode
}

const ChatContext = ({ children }: IChatContextProps) => {
  const [state, dispatch] = useReducer(editThumbnailReducer, initialState)

  return (
    <ChatStateContext.Provider value={state}>
      <ChatDispatchContext.Provider value={dispatch}>{children}</ChatDispatchContext.Provider>
    </ChatStateContext.Provider>
  )
}

function useChatContext() {
  const context = React.useContext(ChatStateContext)
  if (!context) {
    throw new Error('useChatState must be used within the AppProvider')
  }
  return context
}

function useChatDispatch() {
  const context = React.useContext(ChatDispatchContext)
  if (!context) {
    throw new Error('useChatDispatch must be used within the AppProvider')
  }
  return context
}

export { ChatContext, useChatContext, useChatDispatch }
