import React from 'react'
import { UsePatentProvider } from './usePatentProvider'
import { PatentProviderProps } from './index'

import PatentContext from './PatentContext'

const ErrorProviderView = (props: PatentProviderViewProps) => {
  const { children, context } = props
  return <PatentContext.Provider value={context}>{children}</PatentContext.Provider>
}

export type PatentProviderViewProps = UsePatentProvider & PatentProviderProps
export default ErrorProviderView
