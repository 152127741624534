import * as React from 'react'
import { Box, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useHistory } from 'react-router-dom'
import CloudFrontImage from '../../../../../../../../components/_refactored/CloudFrontImage'
import { getUsername } from '../../../../../../../../common/utils/getUsername'
import { screensightVideo, screensightVideoComment } from '../../../../../../../../API'
import videojs from 'video.js'
import Linkify from 'react-linkify'
import { sanitazeHtml } from '../../../../../../../../common/utils/sanitazeHtml'
import gql from 'graphql-tag'
import '../../../common/styles/posts.style.scss'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'

interface ICommentPost {
  owner: boolean
  videoData: screensightVideo
  comment: screensightVideoComment
  referencePostId: string | null | undefined
}
const CommentPost = (props: ICommentPost) => {
  const auth = useContext(AuthContext)
  const theme = useTheme()
  const isTablet = useMediaQuery(`(max-width:1024px)`)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const client = auth.isAuthenticated ? CognitoApolloClient : IamApolloClient
  const history = useHistory()
  const isGif = props.videoData.thumbnailObject?.key.includes('.gif')
  const cacheReferencePostAuthor: any = client.cache.readFragment({
    id: `screensightCustomer:${props.videoData.customerId}`,
    fragment: gql`
      fragment CustomerMeta on screensightCustomer {
        id
        firstName
        lastName
        email
      }
    `,
  })
  const username = getUsername(
    cacheReferencePostAuthor?.firstName,
    cacheReferencePostAuthor?.lastName,
    cacheReferencePostAuthor?.email,
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={props.owner ? 'flex-end' : 'flex-start'}
      maxWidth={'calc(100% - 60px)'}
      minWidth={isTablet && !isMobile ? 'calc(100% - 60px)' : isMobile ? 'calc(100% - 50px)' : '250px'}
    >
      <Box
        className={props.owner ? 'chat__post-owner-text' : 'chat__post-partner-text'}
        bgcolor={props.owner ? 'primary.main' : 'rgba(188, 188, 195, 0.15)'}
        padding="15px"
        marginTop="10px"
        borderRadius="24px"
        width="100%"
        style={{ borderTopRightRadius: props.owner ? '0' : '24px', borderTopLeftRadius: props.owner ? '24px' : '0' }}
      >
        <Box display="flex" alignItems="center">
          <Box paddingLeft="10px" borderLeft={props.owner ? '2px solid white' : '2px solid #BCBCC3'}>
            <CloudFrontImage
              disableResize={isGif}
              image={props.videoData?.thumbnailObject?.key || ''}
              height={'40'}
              width={'40'}
              imgStyle={{
                width: '40px',
                height: '40px',
                borderRadius: '4px',
                objectFit: 'cover',
              }}
            />
          </Box>
          <Box paddingLeft="20px" width="calc(100% - 50px)">
            <Tooltip title={props.videoData?.title || ''}>
              <Typography
                variant="body1"
                onClick={() => {
                  history.push({
                    search: `?play=${props.videoData?.accessId || props.videoData?.id}&postId=${props.referencePostId}`,
                  })
                }}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  fontSize: '16px',
                  fontWeight: 500,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                }}
              >
                {props.videoData?.title}
              </Typography>
            </Tooltip>
            <Typography
              variant="body1"
              style={{
                fontWeight: 400,
                fontSize: '14px',
                color: props.owner ? theme.palette.background.default : theme.palette.text.secondary,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '90%',
              }}
            >
              {username}
            </Typography>
          </Box>
        </Box>
        <Box display={isMobile ? 'block' : 'flex'} alignItems="flex-start" marginTop={isMobile ? '5px' : '15px'}>
          <Typography
            variant="body1"
            style={{
              marginRight: '10px',
              marginBottom: `${isMobile ? '5px' : '0'}`,
              textDecoration: 'underline',
              fontSize: '15px',
              lineHeight: '20px',
              color: props.owner ? theme.palette.background.default : theme.palette.text.primary,
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push({
                pathname: `/app/chat/${props.comment.channelId}`,
                search: `?play=${props.videoData?.accessId || props.videoData?.id}&postId=${props.referencePostId}&t=${
                  props.comment.videoTimestamp
                }`,
              })
            }}
          >
            {videojs.formatTime(props.comment.videoTimestamp || 0, 600)}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: '15px',
              lineHeight: '20px',
              color: props.owner ? theme.palette.background.default : theme.palette.text.primary,
            }}
          >
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {sanitazeHtml(props.comment.richContent || props.comment.content || '')}
            </Linkify>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default CommentPost
