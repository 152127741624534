import React from 'react'

const VideoEmailArrowLeftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
      <path
        d="M2.67319 12C2.53693 12 2.40138 11.9456 2.29755 11.8372L0.108611 9.37501C-0.131638 9.10502 0.0529212 8.66669 0.405627 8.66669H2.04546V3.33338C2.04546 1.50038 3.48343 6.65188e-05 5.24026 6.65188e-05H10.3519C10.4366 -0.00118284 10.5207 0.0151388 10.5992 0.0480812C10.6778 0.0810235 10.7493 0.12993 10.8096 0.191959C10.8699 0.253988 10.9178 0.327903 10.9505 0.409407C10.9832 0.490912 11 0.57838 11 0.66673C11 0.755079 10.9832 0.842549 10.9505 0.924053C10.9178 1.00556 10.8699 1.07947 10.8096 1.1415C10.7493 1.20353 10.6778 1.25244 10.5992 1.28538C10.5207 1.31832 10.4366 1.33464 10.3519 1.33339H5.24026C4.17416 1.33339 3.32338 2.22106 3.32338 3.33338V8.66669H4.94075C5.29409 8.66669 5.47737 9.10502 5.23776 9.37501L3.05007 11.8372C2.94624 11.9456 2.80944 12 2.67319 12Z"
        fill="#7A7A86"
      />
    </svg>
  )
}
export default VideoEmailArrowLeftIcon
