import { useContext, useEffect, useState } from 'react'
import RecorderStateContext from '../../../../../../providers/RecorderStateProvider/RecorderStateContext'

const useInitialPermissionsModal = (onCloseModal: () => void) => {
  const { dispatch } = useContext(RecorderStateContext)
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)

  const closeModal = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop())
    }
    onCloseModal()
    dispatch({ action: 'SET_INITIAL_PERMISSIONS_VISIBLE', value: false })
    localStorage.setItem('initialPermissionsModalDisplayed', 'true')
  }

  useEffect(() => {
    const getMedia = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      setMediaStream(stream)
    }
    getMedia()

    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop())
      }
    }
  }, [])

  return {
    mediaStream,
    closeModal,
  }
}

export default useInitialPermissionsModal
