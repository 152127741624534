import * as React from 'react'
import { Box } from '@mui/material'

type Props = {
  color: string
}
const AddReactionIcon = (props: Props) => {
  return (
    <Box>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M5.41634 8.33337C5.08482 8.33337 4.76688 8.46507 4.53246 8.69949C4.29804 8.93391 4.16634 9.25185 4.16634 9.58337C4.16634 9.91489 4.29804 10.2328 4.53246 10.4673C4.76688 10.7017 5.08482 10.8334 5.41634 10.8334C5.74786 10.8334 6.0658 10.7017 6.30022 10.4673C6.53465 10.2328 6.66634 9.91489 6.66634 9.58337C6.66634 9.25185 6.53465 8.93391 6.30022 8.69949C6.0658 8.46507 5.74786 8.33337 5.41634 8.33337ZM11.2497 8.33337C10.9182 8.33337 10.6002 8.46507 10.3658 8.69949C10.1314 8.93391 9.99967 9.25185 9.99967 9.58337C9.99967 9.91489 10.1314 10.2328 10.3658 10.4673C10.6002 10.7017 10.9182 10.8334 11.2497 10.8334C11.5812 10.8334 11.8991 10.7017 12.1336 10.4673C12.368 10.2328 12.4997 9.91489 12.4997 9.58337C12.4997 9.25185 12.368 8.93391 12.1336 8.69949C11.8991 8.46507 11.5812 8.33337 11.2497 8.33337ZM4.0752 13.3334C4.74186 15.0334 6.39134 16.25 8.33301 16.25C10.2747 16.25 11.9242 15.0334 12.5908 13.3334H4.0752Z"
          fill={props.color || '#BCBCC3'}
        />
        <rect x="14.999" width="1.66667" height="8.33333" fill={props.color || '#BCBCC3'} />
        <rect
          x="11.666"
          y="5"
          width="1.66667"
          height="8.33333"
          transform="rotate(-90 11.666 5)"
          fill={props.color || '#BCBCC3'}
        />
        <path
          d="M8.33333 3.33337C6.68516 3.33337 5.07399 3.82212 3.70358 4.73779C2.33317 5.65347 1.26507 6.95496 0.634337 8.47768C0.00360697 10.0004 -0.161421 11.676 0.160123 13.2925C0.481666 14.909 1.27534 16.3938 2.44078 17.5593C3.60621 18.7247 5.09107 19.5184 6.70758 19.8399C8.32409 20.1615 9.99964 19.9964 11.5224 19.3657C13.0451 18.735 14.3466 17.6669 15.2622 16.2965C16.1779 14.926 16.6667 13.3149 16.6667 11.6667H14.9926C14.9926 12.9838 14.602 14.2713 13.8703 15.3664C13.1386 16.4615 12.0985 17.315 10.8817 17.819C9.6649 18.3231 8.32595 18.4549 7.03418 18.198C5.74241 17.9411 4.55585 17.3068 3.62453 16.3755C2.69322 15.4442 2.05899 14.2576 1.80204 12.9659C1.54509 11.6741 1.67697 10.3351 2.18099 9.11832C2.68501 7.9015 3.53855 6.86147 4.63365 6.12975C5.72876 5.39802 7.01626 5.00746 8.33333 5.00746V3.33337Z"
          fill={props.color || '#BCBCC3'}
        />
        <defs>
          <clipPath id="clip0_14617_321398">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}

export default AddReactionIcon
