import * as React from 'react'

const ArrowLeftOutline = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
      <path
        d="M1.01495 -3.05176e-05H0.698884C0.428732 -3.05176e-05 0.18314 0.129852 0.0685301 0.332651C-0.0488081 0.53773 -0.0133333 0.779267 0.15858 0.952443L6.08281 6.99997L0.15858 13.0475C-0.0133333 13.2207 -0.0488081 13.4622 0.0685301 13.6673C0.18314 13.8701 0.428732 14 0.698884 14H1.01495C1.22507 14 1.42427 13.9202 1.55525 13.7858L7.84241 7.36911C8.05253 7.15492 8.05253 6.84502 7.84241 6.63083L1.55525 0.214162C1.42427 0.0797222 1.22507 -3.05176e-05 1.01495 -3.05176e-05Z"
        fill="#7A7A86"
      />
    </svg>
  )
}

export default ArrowLeftOutline
