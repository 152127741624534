import * as React from 'react'
import VideoSummaryError from './VideoSummaryError'
import VideoBetaExplanation from './VideoBetaExplanation'
import VideoSummary from './VideoSummary'
import VideoTranscription from './VideoTranscription'
import { Box, useMediaQuery } from '@mui/material'
import { useLocation } from 'react-router'
import './styles.scss'

type Props = {
  error: any
  experimentalInfoVisible: boolean
  transcriptionSummary: any
  handleExperimentalInfoVisibility: any
  contentSelected: string
  bucket: string | undefined
  bucketKey: string | undefined
}
const AISummaryResult = (props: Props) => {
  const location = useLocation()
  const isInVideoPage = location.pathname.includes('video')
  const gridWrap = useMediaQuery(`(max-width:1279px)`)
  return (
    <Box
      border={'1px solid rgba(122, 122, 134, 0.15)'}
      borderRadius={'16px'}
      padding={'10px'}
      mt={'5px'}
      style={{ overflowY: 'auto', marginTop: gridWrap || !isInVideoPage ? '' : '60px' }}
      className={isInVideoPage ? 'videoPost-summary' : ''}
      maxHeight={isInVideoPage ? '70vh' : ''}
    >
      {props.error ? (
        <VideoSummaryError error={props.error} />
      ) : (
        <>
          {props.experimentalInfoVisible && !props?.transcriptionSummary && (
            <VideoBetaExplanation onButtonClick={() => props.handleExperimentalInfoVisibility(false)} />
          )}

          {props.contentSelected === 'SUMMARY' && props?.transcriptionSummary && (
            <VideoSummary transcriptionSummary={props?.transcriptionSummary} />
          )}

          {props.contentSelected === 'TRANSCRIPTION' && props?.bucketKey && props?.bucket && (
            <VideoTranscription bucketKey={props?.bucketKey} bucket={props?.bucket} />
          )}
        </>
      )}
    </Box>
  )
}

export default AISummaryResult
