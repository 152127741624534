import * as React from 'react'
import CommentPostTimestamp from './CommentPostTimestamp'
import { Box, Typography, useTheme } from '@mui/material'

interface IReplyParentPostDataProps {
  owner: boolean
  channelId: string | null | undefined
  videoId: string | undefined
  videoTimestamp: number | null | undefined
  username?: string
  parentPostContent: string
}
const ReplyParentPostData = (props: IReplyParentPostDataProps) => {
  const theme = useTheme()

  return (
    <>
      <Box display="inline-flex" maxWidth="100%">
        {props?.videoId ? (
          <CommentPostTimestamp
            videoId={props.videoId}
            owner={props.owner}
            videoTimestamp={props?.videoTimestamp}
            channelId={props?.channelId}
          />
        ) : null}
        <Typography
          className="reply-post-parent"
          variant="body1"
          style={{
            color: props.owner ? theme.palette.background.default : theme.palette.text.primary,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {props.parentPostContent}
        </Typography>
      </Box>
      <Typography
        width="100%"
        variant="body1"
        style={{
          paddingTop: '5px',
          fontWeight: 400,
          fontSize: '15px',
          color: props.owner ? theme.palette.background.default : theme.palette.text.secondary,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {props.username}
      </Typography>
    </>
  )
}

export default ReplyParentPostData
