import * as React from "react"
import { SVGProps } from "react"
const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={12}
		height={12}
		fill="none"
		{...props}
	>
		<path
			fill="#7A7A86"
			d="M6 .167A5.842 5.842 0 0 0 .167 6 5.842 5.842 0 0 0 6 11.833 5.842 5.842 0 0 0 11.833 6 5.842 5.842 0 0 0 6 .167Zm0 1.166A4.658 4.658 0 0 1 10.667 6 4.658 4.658 0 0 1 6 10.667 4.658 4.658 0 0 1 1.333 6 4.658 4.658 0 0 1 6 1.333Zm-.583 1.75V4.25h1.166V3.083H5.417Zm0 2.334v3.5h1.166v-3.5H5.417Z"
		/>
	</svg>
)
export default InfoIcon
