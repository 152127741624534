import { useState, useCallback, useEffect } from 'react'
// import Recorder from "containers/RecorderStateProvider/recorder";
import MediaRecorderHandler from '../RecorderStateProvider/MediaRecorderHandler'
import { useTranslation } from 'react-i18next'

export interface ErrorOpen {
  okCallback?: () => any
  abortCallback?: () => any
  title?: string
  subtitle?: string
  okText?: string
  abortText?: string
  showAbort?: boolean
}

const useErrorProvider = () => {
  const { t: g } = useTranslation('general')
  const [modalState, setModalState] = useState(false)
  const [okCallback, setOkCallback] = useState<() => void>(() => () => null)
  const [abortCallback, setAbortCallback] = useState<() => void>(() => () => null)
  const [text, setText] = useState({
    title: '',
    subtitle: '',
    okText: '',
    abortText: '',
    showAbort: false,
  })

  const deleteConfirmOpenDefault = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    okCallback: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    abortCallback: () => {},
    title: '',
    subtitle: '',
    okText: g('defaultErrorModal.okBtn'),
    abortText: g('defaultErrorModal.abortBtn'),
    showAbort: false,
  }

  const open = useCallback((props: ErrorOpen) => {
    setOkCallback((() => props.okCallback) || (() => deleteConfirmOpenDefault.okCallback))
    setAbortCallback((() => props.abortCallback) || (() => deleteConfirmOpenDefault.abortCallback))

    setText({
      title: props.title || deleteConfirmOpenDefault.title,
      subtitle: props.subtitle || deleteConfirmOpenDefault.subtitle,
      okText: props.okText || deleteConfirmOpenDefault.okText,
      abortText: props.abortText || deleteConfirmOpenDefault.abortText,
      showAbort: props.showAbort || deleteConfirmOpenDefault.showAbort,
    })

    setModalState(true)
  }, [])

  const abort = useCallback(() => {
    if (abortCallback) abortCallback()
    setModalState(false)
  }, [abortCallback])

  const ok = useCallback(() => {
    if (okCallback) okCallback()
    setModalState(false)
  }, [okCallback])

  useEffect(() => {
    MediaRecorderHandler.setErrorOpen(open)
    // Recorder.setErrorOpen(open);
  }, [open])

  return { modalState, open, abort, ok, text }
}

export type UseErrorProvider = ReturnType<typeof useErrorProvider>
export default useErrorProvider
