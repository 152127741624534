import React from 'react'
import useOnBoarding from './hooks/useOnBoarding'
import WelcomeVideoPlayer from './components/WelcomeVideoPlayer'
import OnBoardingProfileInitSteps from './OnBoardingProfileInitSteps'

const OnBoarding = () => {
  const { welcomeVideoVisible, closeWelcomeVideo, closeStepsAndOpenWelcomeVideo, onBoardingProfileInitStepsVisible } =
    useOnBoarding()

  return (
    <>
      <OnBoardingProfileInitSteps
        visible={onBoardingProfileInitStepsVisible}
        onFinish={closeStepsAndOpenWelcomeVideo}
      />
      <WelcomeVideoPlayer visible={welcomeVideoVisible} onClose={closeWelcomeVideo} />
    </>
  )
}

export default OnBoarding
