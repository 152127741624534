import React from 'react'
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material'

interface IFullScreenLoader {
  withoutSidebar?: boolean
}

const FullScreenLoader = (props: IFullScreenLoader) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  if (props.withoutSidebar)
    return (
      <Box
        bgcolor="#fff"
        width={isMobile ? '100vw' : 'calc(100vw - 60px)'}
        height={isMobile ? 'calc(100vh - 80px)' : '100vh'}
        position="fixed"
        top={isMobile ? '80px' : '0'}
        left={isMobile ? '0' : '60px'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={99}
      >
        <CircularProgress />
      </Box>
    )
  return (
    <Box
      bgcolor="#fff"
      width="100vw"
      height="100vh"
      position="fixed"
      top="0"
      left="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex={2000}
    >
      <CircularProgress />
    </Box>
  )
}

export default FullScreenLoader
