import { screensightVideo } from '../../../../../../../../../../API'
import { memo } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import AISummaryControlBtn from './styled/AISummaryControlBtn'
import { Box, Typography } from '@mui/material'
import AISummaryIcon from '../../../../../../../../../../common/icons/AISummaryIcon'
import { theme } from '../../../../../../../../../../common/providers/MuiThemeProvider'
import InfoIcon from '../../../../../../../../../../common/icons/InfoIcon'
import useAISummaryInitializer from '../../common/hooks/useAISummaryInitializer'

type AISummaryInitializerProps = {
  videoData: screensightVideo
  handleExperimentalInfoVisibility: (value: boolean) => void
  onError: (error: string) => void
  isError: boolean
}

const AISummaryInitializer = memo((props: AISummaryInitializerProps) => {
  const { t } = useTranslation('chat')
  const { onClick, loading } = useAISummaryInitializer(
    props.onError,
    props.handleExperimentalInfoVisibility,
    props.videoData.fileObject,
  )

  if (props.isError)
    return (
      <AISummaryControlBtn color="secondary" onClick={onClick}>
        <Box mr="3px" display="flex" alignItems="center">
          <AISummaryIcon color={theme.palette.secondary.main} />
        </Box>
        {t('chatBody.videoPostSummaryAI.tryAgain')}
      </AISummaryControlBtn>
    )

  if (loading)
    return (
      <Box display="flex" alignItems="center">
        <AISummaryControlBtn color="primary" disabled>
          <Box mr="3px" display="flex" alignItems="center">
            <AISummaryIcon color={theme.palette.primary.main} />
          </Box>
          <Typography
            variant="body1"
            sx={{
              fontSize: '12px',
              color: theme.palette.primary.main,
              whiteSpace: 'pre-wrap',
            }}
          >
            {t('chatBody.videoPostSummaryAI.processing')}...
          </Typography>
        </AISummaryControlBtn>

        <Box
          ml="3px"
          onClick={() => props.handleExperimentalInfoVisibility(true)}
          sx={{
            cursor: 'pointer',
          }}
        >
          <InfoIcon />
        </Box>
      </Box>
    )

  return (
    <AISummaryControlBtn color="secondary" onClick={onClick}>
      <Box mr="3px" display="flex" alignItems="center">
        <AISummaryIcon color={theme.palette.secondary.main} />
      </Box>
      {t('chatBody.videoPostSummaryAI.aiSummary')}
    </AISummaryControlBtn>
  )
})
export default AISummaryInitializer
