import * as React from 'react'
import { Box } from '@mui/material'
import Rectangle from './Rectangle'

interface IRecordFromLibrary {
  color?: string
  width?: string
  height?: string
}

const RecordFromLibrary = ({ width = '18.5', height = '36', color = '#7A7A86' }: IRecordFromLibrary) => {
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" flexDirection="column" marginTop="2px" marginRight="5px">
        <Rectangle color={color} />
        <Rectangle color={color} />
        <Rectangle color={color} />
      </Box>

      <svg width={width} height={height} viewBox="0 0 19 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.50005 0.311432C0.949128 0.311432 0.527832 0.737661 0.527832 1.29504C0.527832 1.85242 0.949128 2.27865 1.50005 2.27865H17.7038C18.2547 2.27865 18.676 1.85242 18.676 1.29504C18.676 0.737661 18.2547 0.311432 17.7038 0.311432H1.50005ZM1.50005 5.24586C0.949128 5.24586 0.527832 5.67209 0.527832 6.22947C0.527832 6.78684 0.949128 7.21307 1.50005 7.21307H7.98154C8.53246 7.21307 8.95376 6.78684 8.95376 6.22947C8.95376 5.67209 8.53246 5.24586 7.98154 5.24586H1.50005ZM1.50005 14.4098C0.949128 14.4098 0.527832 14.836 0.527832 15.3934C0.527832 15.9508 0.949128 16.377 1.50005 16.377H17.7038C18.2547 16.377 18.676 15.9508 18.676 15.3934C18.676 14.836 18.2547 14.4098 17.7038 14.4098H1.50005ZM1.50005 19.3442C0.949128 19.3442 0.527832 19.7705 0.527832 20.3278C0.527832 20.8852 0.949128 21.3114 1.50005 21.3114H7.98154C8.53246 21.3114 8.95376 20.8852 8.95376 20.3278C8.95376 19.7705 8.53246 19.3442 7.98154 19.3442H1.50005ZM1.50005 28.5082C0.949128 28.5082 0.527832 28.9344 0.527832 29.4918C0.527832 30.0491 0.949128 30.4754 1.50005 30.4754H17.7038C18.2547 30.4754 18.676 30.0491 18.676 29.4918C18.676 28.9344 18.2547 28.5082 17.7038 28.5082H1.50005ZM1.50005 33.4426C0.949128 33.4426 0.527832 33.8688 0.527832 34.4262C0.527832 34.9836 0.949128 35.4098 1.50005 35.4098H7.98154C8.53246 35.4098 8.95376 34.9836 8.95376 34.4262C8.95376 33.8688 8.53246 33.4426 7.98154 33.4426H1.50005Z"
          fill={color}
        />
      </svg>
    </Box>
  )
}

export default RecordFromLibrary
