import * as React from 'react'
import { Box } from '@mui/material'

const BackIcon = () => {
  return (
    <Box display="flex">
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.92268 0H8.30736C8.5751 0 8.81849 0.148437 8.93208 0.380208C9.04837 0.614583 9.01321 0.890625 8.84284 1.08854L2.9715 8L8.84284 14.9115C9.01321 15.1094 9.04837 15.3854 8.93208 15.6198C8.81849 15.8516 8.5751 16 8.30736 16H6.92268C6.71444 16 6.51702 15.9089 6.38721 15.7552L0.156181 8.42188C-0.0520604 8.17708 -0.0520604 7.82292 0.156181 7.57813L6.38721 0.244792C6.51702 0.091146 6.71444 0 6.92268 0Z"
          fill="#7A7A86"
        />
      </svg>
    </Box>
  )
}

export default BackIcon
