import React, { useEffect, useState } from 'react'
import CloudFrontImage from '../../../../../../../../components/_refactored/CloudFrontImage'
import { createPortal } from 'react-dom'
import ImagePostPreview from './ImagePostPreview'
import { FilePost as IFilePost } from '../../../../../../../../API'
import useImageFilePost from '../../../common/hooks/posts/useImageFilePost'

interface IImagePostProps {
  file?: IFilePost | null
  isGif?: boolean
}
const ImagePost = (props: IImagePostProps) => {
  const { handleCloseImagePreview, handleOpenImagePreview, imagePreviewKey, loading, cloudFrontDimensions } =
    useImageFilePost(props.file)

  const imgSource = `${props.file?.fileObject?.accessLevel}/${props.file?.fileObject?.identityId}/${props.file?.fileObject?.key}`
  if (loading) return <></>

  return (
    <>
      <CloudFrontImage
        image={imgSource}
        autoScale={!cloudFrontDimensions}
        width={cloudFrontDimensions?.width}
        height={cloudFrontDimensions?.height}
        disableResize={props?.isGif}
        imgStyle={{
          maxWidth: 'calc(100% - 50px)',
          maxHeight: '270px',
          width: 'auto',
          height: 'auto',
          borderRadius: '10px',
          marginTop: '6px',
          cursor: 'pointer',
        }}
        onClick={() => handleOpenImagePreview(imgSource)}
      />
      {imagePreviewKey &&
        createPortal(
          <ImagePostPreview
            handleCloseImagePreview={handleCloseImagePreview}
            imagePreviewKey={imagePreviewKey}
            isGif={props?.isGif}
          />,
          document.body,
        )}
    </>
  )
}

export default ImagePost
