import React from 'react'
import { createRoot } from 'react-dom/client'
import { Amplify, Analytics, Auth, AWSKinesisFirehoseProvider, Storage } from 'aws-amplify'

import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import build from './common/services/BuildNumber'

import './index.css'
import awsmobile from './aws-exports'
import './i18n'
import App from './app'

Amplify.configure({
  ...awsmobile,
  aws_cloud_logic_custom: [
    {
      name: 'ai_summary_api',
      endpoint: process.env.REACT_APP_AI_SUMMARY_URL || '',
      region: awsmobile.aws_project_region,
    },
  ],
  Analytics: {
    autoSessionRecord: false,
  },
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession()
      return {
        Authorization: session.getIdToken().getJwtToken(),
      }
    },
  },
})
Analytics.addPluggable(new AWSKinesisFirehoseProvider())
Analytics.configure({
  AWSKinesisFirehose: {
    // OPTIONAL -  Amazon Kinesis Firehose service region
    region: awsmobile.aws_project_region,
    // OPTIONAL - The buffer size for events in number of items.
    bufferSize: 1000,

    // OPTIONAL - The number of events to be deleted from the buffer when flushed.
    flushSize: 100,

    // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
    flushInterval: 5000, // 5s

    // OPTIONAL - The limit for failed recording retries.
    resendLimit: 5,
  },
})
Storage.configure({ level: 'public' })

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://cba79ad4605f4f9b907b2c579d33ed8a@o160332.ingest.sentry.io/5594582',
    release: build,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  })
}

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
