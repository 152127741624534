import React, { Dispatch, SetStateAction, useContext } from 'react'
import { Box, CircularProgress, Grid, Theme, Typography } from '@mui/material'

import VideoListItem from '../../../containers/VideoList/VideoListItem/VideoListItem'
import VideoNewModal from '../../../containers/VideoList/VideoNew'

import InfiniteScroll from 'react-infinite-scroll-component'
import OnBoarding from '../../../containers/VideoList/OnBoarding'
import VideoNotFound from '../../../containers/VideoList/components/VideoNotFound/VideoNotFound'
import VideoListItemMobileSettingsModal from '../../../containers/VideoList/components/VideoListItemMobileSettingsModal/VideoListItemMobileSettingsModal'
import EmptyVideoList from '../../../containers/VideoList/components/EmptyVideoList/EmptyVideoList'
import { screensightVideo } from '../../../API'
import { IndexedRecordingEntity } from '../../../common/services/IndexedDB'
import VideoListHeader from '../../../containers/VideoList/VideoListHeader/VideoListHeader'
import VideoListLoader from '../../../containers/VideoList/components/Loader/VideoListLoader'
import { useTranslation } from 'react-i18next'
import TermsAndConditionsSync from '../../../containers/VideoList/TermsAndConditionsSync'
import { AuthContext } from '../../../common/providers/AuthStatusProvider'
import InitialPermissionsModal from '../../../common/layouts/AppLayout/ui/components/RecordControl/InitialPermissionsModal'
import RecorderStateContext from '../../../common/providers/RecorderStateProvider/RecorderStateContext'

interface IVideoListView {
  iseNewVideoModalVisible: boolean
  handleNewVideoBtnClick: () => void
  openNewVideoModal: () => void
  closeNewVideoModal: () => void
  recorderActive: boolean
  deleteVideoFromList: (itemId?: string | null) => void
  searchEvent: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined
  searching: boolean
  selectedVideoItem: screensightVideo | null
  setSelectedVideoItem: Dispatch<SetStateAction<screensightVideo | null>>
  videos: Array<screensightVideo | IndexedRecordingEntity>
  getNextVideos: () => Promise<void>
  hasMore: boolean
  loading: boolean
  noVideos: boolean
  openDeleteModal: (item: screensightVideo | IndexedRecordingEntity) => void
  theme: Theme
  isMobile: boolean
  isLargeScreen: boolean
  searchPhrase: string
  searchValue: string
  clearSearchPhrase: () => void
  isInitialPermissionsModalVisible: boolean
  closeInitialPermissionsModal: () => void
}

const VideoListView = (props: IVideoListView) => {
  const { t } = useTranslation('library')
  const {
    iseNewVideoModalVisible,
    handleNewVideoBtnClick,
    closeNewVideoModal,
    openNewVideoModal,
    recorderActive,
    deleteVideoFromList,
    searchEvent,
    searching,
    selectedVideoItem,
    setSelectedVideoItem,
    videos,
    getNextVideos,
    hasMore,
    loading,
    noVideos,
    openDeleteModal,
    theme,
    isMobile,
    isLargeScreen,
    searchPhrase,
    clearSearchPhrase,
    searchValue,
  } = props

  const notFound = searching && !videos.length && !loading
  const auth = useContext(AuthContext)
  const { state: recorderState } = useContext(RecorderStateContext)

  return (
    <>
      {auth.isAuthenticated && (
        <>
          <TermsAndConditionsSync />
          <OnBoarding />
        </>
      )}

      {!isMobile && (
        <VideoListHeader
          isMobile={isMobile}
          handleNewVideoBtnClick={handleNewVideoBtnClick}
          noVideos={noVideos}
          searchEvent={searchEvent}
          searchPhrase={searchPhrase}
          clearSearchPhrase={clearSearchPhrase}
          searchValue={searchValue}
        />
      )}
      {recorderState.initialPermissionsVisible && <InitialPermissionsModal onCloseModal={openNewVideoModal} />}
      <VideoNewModal open={iseNewVideoModalVisible} onClose={closeNewVideoModal} />
      {!isMobile && (
        <Typography
          variant="h3"
          color="secondary"
          style={{
            marginTop: noVideos ? '32px' : isMobile ? ' 132px' : '80px',
            padding: 30,
            fontWeight: 500,
            visibility: noVideos ? 'hidden' : 'visible',
          }}
          data-testid="library-title"
        >
          {t('videoList.title')}
        </Typography>
      )}

      {loading && <VideoListLoader />}
      {videos.length > 0 ? (
        <Box padding="6px 16px 6px 6px" marginTop={isMobile ? '80px' : '10px'} height="100vh">
          <InfiniteScroll
            style={{ overflowX: 'hidden' }}
            dataLength={videos.length}
            next={getNextVideos}
            hasMore={hasMore}
            loader={
              <Box
                width="100px"
                margin="15px auto"
                display="flex"
                justifyContent="center"
                paddingLeft={isMobile ? '8px' : '0'}
              >
                <CircularProgress />
              </Box>
            }
          >
            <Grid container>
              {videos.map((item) => {
                return (
                  <VideoListItem
                    item={item}
                    deleteItem={deleteVideoFromList}
                    key={(item as screensightVideo).id || (item as IndexedRecordingEntity).localId}
                    setSelectedVideoItem={setSelectedVideoItem}
                    theme={theme}
                    isMobile={isMobile}
                    isLargeScreen={isLargeScreen}
                  />
                )
              })}
            </Grid>
          </InfiniteScroll>
        </Box>
      ) : null}
      {noVideos && !loading ? (
        <EmptyVideoList
          isMobile={isMobile}
          handleNewVideoBtnClick={handleNewVideoBtnClick}
          recorderActive={recorderActive}
        />
      ) : null}
      {notFound && !loading ? <VideoNotFound /> : null}
      {selectedVideoItem && isMobile ? (
        <VideoListItemMobileSettingsModal
          selectedVideoItem={selectedVideoItem}
          setSelectedVideoItem={setSelectedVideoItem}
          openDeleteModal={openDeleteModal}
        />
      ) : null}
    </>
  )
}

export default VideoListView
