import React, { ChangeEvent, useContext, useState } from 'react'
import Icon from '../../../../../icons'
import CameraModal from '../../../../../../containers/VideoRecorder/CameraModal'
import { Box, Checkbox, Typography, useTheme } from '@mui/material'
import RecorderStateContext from '../../../../../providers/RecorderStateProvider/RecorderStateContext'
import MediaRecorderHandler from '../../../../../providers/RecorderStateProvider/MediaRecorderHandler'
import Button from '../../../../../../components/_refactored/Button'
import CameraWithPrompterPreview from '../../../../../../containers/VideoRecorder/CameraWithPrompterPreview'
import KeyboardArrows from '../../../../../../containers/VideoRecorder/CameraModal/KeyboardArrows'
import { AuthContext } from '../../../../../providers/AuthStatusProvider'
import Modal from '../../../../../../components/_refactored/Modal'
import { useTranslation } from 'react-i18next'

const RecordingCameraSetupPrompterModal = () => {
  const { state: recorderState, dispatch: recorderDispatcher } = useContext(RecorderStateContext)
  const theme = useTheme()
  const { t: g } = useTranslation('general')
  const { t } = useTranslation('library')
  const auth = useContext(AuthContext)
  const [isHelperModalVisible, setHelperModalVisible] = useState(false)
  const [helpCheckboxValue, setHelpCheckboxValue] = useState(false)

  const handleHelpCheckboxChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setHelpCheckboxValue(checked)
  }

  const handleSubmit = async () => {
    localStorage.setItem('showPrompterHelp', helpCheckboxValue ? 'false' : 'true')
    setHelperModalVisible(false)
    recorderDispatcher({ action: 'UPDATE_PROMPTER_STATE', value: { currentState: 'RECORDING' } })
    await MediaRecorderHandler.initRecording({
      title: recorderState.prompter.title,
      type: 'CAM',
      enableSplashScreen: auth.user?.settings?.enableSplashScreen || false,
    })
  }

  return !isHelperModalVisible ? (
    <CameraModal open={true} isSetup>
      <>
        <CameraWithPrompterPreview cameraStream={recorderState.cameraStream} />
        <Box padding="20px 40px 50px 40px">
          <Box flexDirection="column" pt="15px">
            <Box display="flex" justifyContent="center" width="100%">
              <Button
                variant="outlined"
                color="secondary"
                style={{ marginRight: '12px', minWidth: 'fit-content' }}
                onClick={async () => {
                  recorderDispatcher({ action: 'UPDATE_PROMPTER_STATE', value: { currentState: undefined } })
                  await MediaRecorderHandler.stopMediaRecorderHandler()
                }}
              >
                {g('common.cancelBtn')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={recorderState.prompter.textEditing}
                style={{ marginLeft: '12px', minWidth: 'fit-content' }}
                onClick={async () => {
                  if (localStorage.getItem('showPrompterHelp') === 'false') {
                    recorderDispatcher({ action: 'UPDATE_PROMPTER_STATE', value: { currentState: 'RECORDING' } })
                    await MediaRecorderHandler.initRecording({
                      title: recorderState.prompter.title,
                      type: 'CAM',
                      enableSplashScreen: auth.user?.settings?.enableSplashScreen || false,
                    })
                  } else {
                    setHelperModalVisible(true)
                  }
                }}
              >
                <Icon.SmallCamera
                  color={
                    recorderState.prompter.textEditing ? theme.palette.secondary.main : theme.palette.background.default
                  }
                  style={{ marginRight: '6px' }}
                />
                {t('videoList.common.recordBtn')}
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    </CameraModal>
  ) : (
    <Modal open={true}>
      <Box padding="32px">
        <KeyboardArrows modalHelper />
        <Typography align="center" variant="body1" color="textSecondary" style={{ marginTop: '16px' }}>
          {g('recordControl.videoRecorderPreviewModal.speedControlInstruction')}
        </Typography>
        <Box width="100%" display="flex" alignItems="center" flexDirection="column" marginTop="16px">
          <Box>
            <Checkbox
              value={helpCheckboxValue}
              onChange={handleHelpCheckboxChange}
              color="primary"
              style={{ boxShadow: 'none' }}
              id="control_helper"
              name="teleprompter"
            />
            <label htmlFor="control_helper" style={{ cursor: 'pointer' }}>
              <Typography variant="caption" color="textSecondary">
                {g('recordControl.videoRecorderPreviewModal.dontShowAgainCheckbox')}
              </Typography>
            </label>
          </Box>
          <Button variant="outlined" color="secondary" onClick={handleSubmit} style={{ marginTop: '8px' }}>
            {t('videoList.common.gotItBtn')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default RecordingCameraSetupPrompterModal
