import * as React from 'react'
import { Box, ClickAwayListener, IconButton } from '@mui/material'
import { parseNativeEmoji } from './Reaction'
import Button from '../../../../../../../../components/_refactored/Button'
import AddIcon from '@mui/icons-material/Add'
import Popper from '@mui/material/Popper'

type Props = {
  closeDefaultReactions: () => void
  openAllReactions: () => void
  open: boolean
  anchorEl: any
  onEmojiClick: any
}

const REACTION_CODES = ['1f44d', '1f44e', '2764-fe0f', '1f525', '2714-fe0f', '1f44f']
const DefaultReactionsPicker = (props: Props) => {
  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      placement="bottom"
      disablePortal={false}
      style={{ zIndex: 11 }}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      <ClickAwayListener
        onClickAway={() => {
          props.closeDefaultReactions()
        }}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={99}
          bgcolor="background.default"
          padding="5px"
          height="40px"
          borderRadius="100px"
          boxShadow="0px 1px 7px rgba(0, 0, 0, 0.11)"
          width="200px"
          marginTop="6px"
        >
          {REACTION_CODES.map((code) => (
            <Button
              style={{
                minWidth: '20px',
                minHeight: '20px',
                width: 'fit-content',
                height: 'fit-content',
                cursor: 'pointer',
                padding: '0px 3px',
                borderRadius: '18px',
                fontSize: '18px',
              }}
              key={code}
              onClick={() => {
                props.onEmojiClick(code)
              }}
            >
              {parseNativeEmoji(code)}
            </Button>
          ))}
          <IconButton
            onClick={() => props.openAllReactions()}
            style={{
              position: 'relative',
              boxShadow: 'none',
              backgroundColor: 'transparent',
              width: '20px',
              height: '20px',
              marginLeft: '2px',
            }}
          >
            <AddIcon color={'secondary'} />
          </IconButton>
        </Box>
      </ClickAwayListener>
    </Popper>
  )
}

export default DefaultReactionsPicker
