import React from 'react'
import { Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { theme } from '../providers/MuiThemeProvider'

interface IChatIcon {
  active?: boolean
}

const useStyles = makeStyles({
  root: {
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
})

const ChatIcon = (props: IChatIcon) => {
  const { root } = useStyles()
  const theme = useTheme()
  return (
    <Box className={root} width="60px" display="flex" justifyContent="center" alignItems="center" height="36px">
      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.69231 0C3.72019 0 0.5 2.86528 0.5 6.4C0.5 8.21601 1.35426 9.85142 2.7195 11.0162C2.52299 11.6422 2.11433 12.2651 1.36584 12.82C1.36534 12.8204 1.36506 12.8209 1.36456 12.8212C1.29333 12.848 1.23391 12.8983 1.19658 12.9634C1.15926 13.0286 1.14637 13.1044 1.16017 13.1779C1.17396 13.2513 1.21356 13.3177 1.27211 13.3656C1.33067 13.4135 1.40448 13.4398 1.48077 13.44C1.50396 13.4399 1.52708 13.4374 1.54973 13.4325C2.81467 13.4282 3.89428 12.9 4.75383 12.2375C5.65179 12.5961 6.64435 12.8 7.69231 12.8C11.6644 12.8 14.8846 9.93472 14.8846 6.4C14.8846 2.86528 11.6644 0 7.69231 0ZM5.07692 4.48H10.3077C10.6686 4.48 10.9615 4.76672 10.9615 5.12C10.9615 5.47328 10.6686 5.76 10.3077 5.76H5.07692C4.716 5.76 4.42308 5.47328 4.42308 5.12C4.42308 4.76672 4.716 4.48 5.07692 4.48ZM5.07692 7.04H9C9.36092 7.04 9.65385 7.32672 9.65385 7.68C9.65385 8.03328 9.36092 8.32 9 8.32H5.07692C4.716 8.32 4.42308 8.03328 4.42308 7.68C4.42308 7.32672 4.716 7.04 5.07692 7.04ZM16.0467 7.78625C15.3726 11.1085 12.3307 13.6918 8.56197 14.04C9.50874 14.8547 10.8203 15.36 12.2692 15.36C12.9597 15.36 13.617 15.242 14.2206 15.0337C15.023 15.5825 15.9943 15.9893 17.1067 15.9925C17.1285 15.9972 17.1507 15.9997 17.1731 16C17.2498 16 17.324 15.9736 17.3828 15.9254C17.4416 15.8772 17.4812 15.8104 17.4948 15.7365C17.5083 15.6626 17.4949 15.5865 17.4569 15.5213C17.4188 15.4561 17.3586 15.4061 17.2867 15.38C16.6879 14.9352 16.3036 14.4461 16.0735 13.9475C16.9554 13.1456 17.5 12.0686 17.5 10.88C17.5 9.67872 16.9451 8.59073 16.0467 7.78625Z"
          fill={props?.active ? theme.palette.primary.main : '#BCBCC3'}
        />
      </svg>
    </Box>
  )
}

export default ChatIcon
