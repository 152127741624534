import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { CreateContactMutation, CreateContactMutationVariables } from '../../../../API'
import gql from 'graphql-tag'
import { createContact } from '../../../../graphql/mutations'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import { useContext, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import ToastContext from '../../../../common/providers/ToastProvider/ToastContext'
import { useTranslation } from 'react-i18next'

export interface INewContactForm {
  email: string
  firstName?: string
  lastName?: string
  numberCountry?: string
  phone?: string
}
const useNewContactModal = (hideNewContactModal: () => void) => {
  const [loading, setLoading] = useState(false)
  const toastContext = useContext(ToastContext)
  const { t } = useTranslation('contacts')
  const [image, setImage] = useState<string | ArrayBuffer | null>('')
  const cropperRef = useRef<HTMLImageElement>(null)
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
  })

  useEffect(() => {
    if (!acceptedFiles?.length) return
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(acceptedFiles[0])
  }, [acceptedFiles])

  const form = useForm<INewContactForm>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: undefined,
    },
  })

  const [createContactMutation] = useMutation<CreateContactMutation, CreateContactMutationVariables>(
    gql(createContact),
    {
      client: CognitoApolloClient,
    },
  )

  const handleCloseCropperModal = () => {
    // if (!photoLoading) {
    setImage(null)
    // }
  }

  const handleCreateContact = form.handleSubmit(async (values) => {
    try {
      setLoading(true)
      await createContactMutation({
        variables: {
          input: {
            firstName: (values?.firstName || '').trim(),
            lastName: (values?.lastName || '').trim(),
            email: values.email,
            mobilePhone: values?.phone,
          },
        },
      })
      hideNewContactModal()
      toastContext.open({ type: 'INFO', text: t('contactsList.contactCreateSuccess') })
    } catch (e: any) {
      console.log('Error during creating contact', e)
    } finally {
      setLoading(false)
    }
  })

  return {
    form,
    loading,
    handleCreateContact,
    image,
    dropzone: {
      getRootProps,
      getInputProps,
    },
    handleCloseCropperModal,
    cropperRef,
  }
}

export default useNewContactModal
