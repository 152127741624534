import { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import {
  CreateBillingPaymentMethodMutation,
  CreateBillingPaymentMethodMutationVariables,
  CreateBillingSubscriptionMutation,
  CreateBillingSubscriptionMutationVariables,
} from '../../../../../API'
import gql from 'graphql-tag'
import { createBillingPaymentMethod, createBillingSubscription } from '../../../../../graphql/mutations'
import CognitoApolloClient from '../../../../../common/clients/CognitoApolloClient'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../../../../common/providers/AuthStatusProvider'

function useCreateBillingMethodConfirmation() {
  const { isAuthenticated } = useContext(AuthContext)
  const history = useHistory()
  const url = new URL(document.location.href)
  const urlParams = new URLSearchParams(url.hash)

  const [
    createBillingPaymentMethodMutation,
    { data: billingPaymentMethodData, loading: billingPaymentMethodLoading, error: billingPaymentMethodError },
  ] = useMutation<CreateBillingPaymentMethodMutation, CreateBillingPaymentMethodMutationVariables>(
    gql(createBillingPaymentMethod),
    {
      client: CognitoApolloClient,
    },
  )

  const [
    createBillingSubscriptionMutation,
    { data: billingSubscriptionData, loading: billingSubscriptionLoading, error: billingSubscriptionError },
  ] = useMutation<CreateBillingSubscriptionMutation, CreateBillingSubscriptionMutationVariables>(
    gql(createBillingSubscription),
    {
      client: CognitoApolloClient,
    },
  )

  const redirectToBillingPage = () => history.replace('/app/settings/billing')

  useEffect(() => {
    async function confirmUserPayment() {
      if (!isAuthenticated) return

      const vaultId = urlParams.get('vaultId') ? decodeURIComponent(urlParams.get('vaultId') as string) : null
      const stripeToken = urlParams.get('stripeToken')
        ? decodeURIComponent(urlParams.get('stripeToken') as string)
        : null
      const priceId = urlParams.get('priceId') ? decodeURIComponent(urlParams.get('priceId') as string) : null

      if (!vaultId || !stripeToken || !priceId) {
        return history.replace('/app/settings/billing')
      }

      await createBillingPaymentMethodMutation({
        variables: {
          input: {
            vaultId,
            stripeToken,
          },
        },
      })

      await createBillingSubscriptionMutation({
        variables: {
          input: {
            priceId,
          },
        },
      })
    }

    confirmUserPayment()
  }, [isAuthenticated])

  return {
    billingPaymentMethodData,
    billingSubscriptionData,
    loading: billingPaymentMethodLoading || billingSubscriptionLoading,
    error: billingPaymentMethodError?.message || billingSubscriptionError?.message,
    redirectToBillingPage,
  }
}

export default useCreateBillingMethodConfirmation
