import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import Modal from '../../Modal'
import Button from '../../Button'

interface IMessageSentModalProps {
  handleClose: () => void
}
export const MessageSentModal = (props: IMessageSentModalProps) => {
  const { t } = useTranslation('library')

  return (
    <Modal open={true} onClose={props.handleClose}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h3" align="center" style={{ marginBottom: '24px' }}>
          {t('videoItem.sendViaEmailModal.messageSentSuccess')}
        </Typography>
        <Button variant="outlined" color="secondary" onClick={props.handleClose}>
          {t('videoItem.common.okBtn')}
        </Button>
      </Box>
    </Modal>
  )
}

export default MessageSentModal
