import { useParams } from 'react-router'
import { useState } from 'react'

const useGroup = () => {
  const { id: channelId } = useParams() as { id: string }
  const [expanded, setExpanded] = useState(true)
  const [customizationVisible, setCustomizationVisible] = useState(false)

  const toggleList = () => {
    setExpanded((prevState) => !prevState)
  }

  const toggleCustomizeRecent = () => {
    setCustomizationVisible(!customizationVisible)
  }

  return {
    channelId,
    expanded,
    toggleList,
    toggleCustomizeRecent,
    customizationVisible,
  }
}

export default useGroup
