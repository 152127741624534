import React, { useContext, useState } from 'react'
import Modal from '../../../components/_refactored/Modal'
import { Box, Typography, useTheme } from '@mui/material'
import Button from '../../../components/_refactored/Button'
import { useMutation } from '@apollo/client'
import { DeleteContactMutation, DeleteContactMutationVariables } from '../../../API'
import gql from 'graphql-tag'
import { deleteContact } from '../../../graphql/mutations'
import CognitoApolloClient from '../../../common/clients/CognitoApolloClient'
import ToastContext from '../../../common/providers/ToastProvider/ToastContext'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IDeleteContactModal {
  id: string
  type?: 'LIST' | 'SINGLE'
  onCancel: () => void
}

const DeleteContactModal = (props: IDeleteContactModal) => {
  const theme = useTheme()
  const { t } = useTranslation('contacts')
  const { t: g } = useTranslation('general')
  const [loading, setLoading] = useState(false)
  const toastContext = useContext(ToastContext)
  const history = useHistory()

  const [deleteContactMutation] = useMutation<DeleteContactMutation, DeleteContactMutationVariables>(
    gql(deleteContact),
    {
      client: CognitoApolloClient,
    },
  )

  const handleDeleteContact = async () => {
    try {
      setLoading(true)
      await deleteContactMutation({ variables: { input: { id: props.id } } })
      toastContext.open({ type: 'INFO', text: t('contactsList.contactDeleteSuccess') })
      if (props.type === 'SINGLE') {
        history.push('/app/contacts')
      }
      props.onCancel()
    } catch (e: any) {
      toastContext.open({ type: 'ERROR', text: t('contactsList.contactDeleteError') })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal open={true} onBackdropClick={props.onCancel}>
      <Box data-testid="delete-contact-modal">
        <Typography variant="h6" style={{ fontSize: '20px', fontWeight: 500, marginBottom: '17px' }} align="center">
          {t('contactsList.deleteContactModal.title')}
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary">
          {t('contactsList.deleteContactModal.subtitle')}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="200px" margin="50px auto 0 auto">
          <Button variant="outlined" color="secondary" onClick={props.onCancel} data-testid="delete-contact-cancel-btn">
            {g('common.cancelBtn')}
          </Button>
          <Button
            variant="outlined"
            style={{ color: theme.palette.error.main, borderColor: theme.palette.error.main }}
            onClick={handleDeleteContact}
            loading={loading}
            data-testid="delete-contact-submit-btn"
          >
            {g('common.deleteBtn')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeleteContactModal
