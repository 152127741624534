import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Typography } from '@mui/material'
import ArrowBottom from '../../../../../../../common/icons/ArrowBottom'
import ArrowRight from '../../../../../../../common/icons/ArrowRight'
import GearIcon from '../../../../../../../common/icons/GearIcon'
import RecentChannels from './RecentChannels'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import '../../common/style/GroupHeaderStyle.scss'
import AddIcon from '@mui/icons-material/Add'
import { useHistory } from 'react-router-dom'
import { createPortal } from 'react-dom'

interface IGroupHeader {
  label: string
  channelsExpanded: boolean
  toggleList: () => void
  toggleCustomizeRecent: () => void
  customizationVisible: boolean
  type?: 'recent' | null
}

const GroupHeader = (props: IGroupHeader) => {
  const { t } = useTranslation('chat')
  const history = useHistory()

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding={props.type === 'recent' ? '0 15px' : '8px 15px'}
      style={{ cursor: 'pointer' }}
      className="group-header__wrapper"
      position="relative"
    >
      <Box display="flex" alignItems="center" onClick={props.toggleList} flexGrow={2}>
        <Box width="20px" display="flex" alignItems="center" justifyContent="center">
          {props.channelsExpanded ? <ArrowBottom /> : <ArrowRight />}
        </Box>
        <Typography
          width="150px"
          variant="body1"
          color="textSecondary"
          style={{ marginLeft: '10px', fontSize: '15px', flexGrow: '1' }}
        >
          {props.label}
        </Typography>
      </Box>
      <Box>
        {props.type !== 'recent' && (
          <Box position="absolute" left="210px" top="6px">
            <IconButton
              onClick={() => {
                history.push('/app/chat/new')
              }}
              sx={{ width: '20px', height: '20px', background: '#d5e7fc', boxShadow: 'none' }}
            >
              <AddIcon style={{ color: '#2483F7' }} />
            </IconButton>
          </Box>
        )}
        <IconButton
          onClick={props.toggleCustomizeRecent}
          className={props.label === t('channelsSidebar.recentMessages') ? 'recent-messages' : 'direct-messages'}
        >
          <GearIcon color={props.customizationVisible ? theme.palette.primary.main : theme.palette.secondary.main} />
        </IconButton>
        {props.customizationVisible &&
          createPortal(
            <Box position="fixed" top="140px" left="285px">
              <RecentChannels toggleCustomizeRecent={props.toggleCustomizeRecent} />
            </Box>,
            document.body,
          )}
      </Box>
    </Box>
  )
}

export default GroupHeader
