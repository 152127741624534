import * as React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import PrivacyOptionsButton from '../../../../components/_refactored/PirvacyOptionsButton'
import VideoPrivacyModal from '../../../../containers/VideoItem/VideoPrivacyModal'
import CopyLinkIconButton from '../../../../components/_refactored/CopyLinkIconButton'
import SendByEmailTextButton from '../../../../components/_refactored/SendByEmailTextButton'
import SendByEmailModal from '../../../../components/_refactored/SendByEmailModal'
import moment from 'moment/moment'
import { AccessType, VideoStatus } from '../../../../API'
import useGetVideoFragment from '../../../../common/api/hooks/useGetVideoFragment'
import useVideoSharingBar from '../common/hooks/useVideoSharingBar'
import { theme } from '../../../../common/providers/MuiThemeProvider'
import VideoItemAiSummary from '../components/VideoItemAISummary'

interface IVideoSharingBarProps {
  videoId: string | undefined
  AISummaryState: any
}
const VideoSharingBar = (props: IVideoSharingBarProps) => {
  const cacheVideo = useGetVideoFragment(props?.videoId)
  const state = useVideoSharingBar()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      display={isMobile ? 'none' : 'flex'}
      justifyContent="flex-end"
      width="100%"
      marginBottom="30px"
      alignItems="center"
      position="absolute"
      left="0px"
      top="0px"
      padding="20px"
      zIndex={10}
    >
      <VideoItemAiSummary videoId={props.videoId} AISummaryState={props.AISummaryState} />
      <Box position="relative">
        <PrivacyOptionsButton
          togglePrivacyModalVisibility={state.togglePrivacyModalVisibility}
          accessType={cacheVideo?.accessType}
          isOnVideoList={false}
        />
        {state.privacyModalVisible && (
          <VideoPrivacyModal
            togglePrivacyModalVisibility={state.togglePrivacyModalVisibility}
            privacyType={cacheVideo?.accessType}
            videoId={cacheVideo?.id}
            videoPassword={cacheVideo?.password}
            isOnVideoList={false}
          />
        )}
      </Box>
      {cacheVideo?.status === VideoStatus.ok && (
        <CopyLinkIconButton videoId={props.videoId} accessID={cacheVideo?.accessId} isOnVideoList={false} />
      )}
      <SendByEmailTextButton showSendByEmailModal={state.showSendByEmailModal} />
      {state.sendEmailModalVisible && (
        <SendByEmailModal
          videoId={cacheVideo?.id}
          videoTitle={cacheVideo?.title}
          videoDateAdded={moment(cacheVideo?.createdAt).format('MMMM DD, YYYY ')}
          thumbnailObject={cacheVideo?.thumbnailObject}
          hideSendByEmailModal={state.hideSendByEmailModal}
          videoPassword={cacheVideo?.accessType === AccessType.protected ? cacheVideo.password : ''}
          videoStatus={cacheVideo?.status}
        />
      )}
    </Box>
  )
}
export default VideoSharingBar
