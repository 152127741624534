import { useDispatch, useSelector } from 'react-redux'
import { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../../../common/providers/AuthStatusProvider'
import { screensightReaction } from '../../../../../../../../../../API'
import { removeVideoPlayerReaction } from '../../../../../../../../common/redux/store/chat-actions'
import { keyframes } from '@mui/material'
import useCreateReaction from '../../../../../common/hooks/reactions/useCreateReaction'
import useDeleteReaction from '../../../../../common/hooks/reactions/useDeleteReaction'
import { RootState } from '../../../../../../../../common/redux/store/store'
import useGetPlayerReactions from './useGetPlayerReactions'

const ANIMATION_DURATION = 1.3 // [seconds]

const usePlayerReactins = (clientWidth: any, videoDuration: any, postId: string | undefined) => {
  const dispatch = useDispatch()
  const auth = useContext(AuthContext)
  const [animationObj, setAnimationObj] = useState<{ code: string; videoTimestamp: number; reactionX: number } | null>(
    null,
  )
  const [currentReactionHover, setCurrentReactionHover] = useState<{ code: string; videoTimestamp: number } | null>(
    null,
  )
  const [reactionsPickerVisible, setReactionsPickerVisible] = useState<boolean>(false)
  const [addReaction] = useCreateReaction()
  const [deleteReaction] = useDeleteReaction()
  const wrapperWidth = (clientWidth || 20) - 20
  const videoTimestamp = useSelector((state: RootState) => state.chat.videoPlaylistProgress)
  const { data, currentVideoPostId, channelId } = useGetPlayerReactions()
  const elem = document.querySelector('.vjs-control-bar')

  const getInitialReactionX = (className: string) => {
    return document.querySelector(`.${className}`)?.getBoundingClientRect()?.x || 0
  }
  const detectReactionHover = (code: string, videoTimestamp: number) => {
    setCurrentReactionHover({ code, videoTimestamp })
  }

  const getFinalReactionX = (reactionX: number, videoTimestamp: number) => {
    const x0 = reactionX
    const b1 =
      (((elem?.getBoundingClientRect()?.width || wrapperWidth) - 20) / (videoDuration || 1)) * 1000 * videoTimestamp
    const x = -(x0 - b1)
    return `${x}px`
  }

  const startAnimation = (code: string, videoTimestamp: number, reactionX: number) => {
    setAnimationObj({ code, videoTimestamp, reactionX })
    setTimeout(() => setAnimationObj(null), ANIMATION_DURATION * 1000)
  }
  const onEmojiClick = async (emojiData: any) => {
    const emoji = emojiData.unified || emojiData
    if (animationObj) return
    setReactionsPickerVisible(false)
    await addReaction({
      postId: currentVideoPostId || '',
      uniCode: emoji,
      channelId: channelId || '',
      videoTimestamp: videoTimestamp?.[postId || ''],
    })
    if (!emojiData.unified) {
      startAnimation(
        emoji,
        videoTimestamp?.[postId || ''],
        getInitialReactionX(`chat__player-reaction-${emoji}`) - 10 - window.innerWidth / 20,
      )
    }
  }

  const handleReactionsPickerVisibility = (isVisible: boolean) => {
    setReactionsPickerVisible(isVisible)
  }

  const handleRemoveReaction = async (reaction: screensightReaction) => {
    if (auth.user?.id !== reaction.customerId || !currentVideoPostId) return
    await deleteReaction({
      postId: currentVideoPostId || '',
      unicode: reaction.unicode,
      videoTimestamp: reaction.videoTimestamp,
    })

    dispatch(removeVideoPlayerReaction(currentVideoPostId, reaction as screensightReaction))
  }

  const moveAnimation = (left: string, bottom: number) => keyframes`
  0% {
    bottom: 0;
    left: 0;
    transform: scale(1) rotate(0);
    opacity: 1;
  }
  30% {
    transform: scale(1);
  }
  60% {
    bottom: ${bottom + 15}px;
    transform: scale(1.6) rotate(-30deg);
  }
  65% {
    transform: rotate(0) scale(1.6);
  }
  70% {
    transform: rotate(-15deg) scale(1.6);
  }
  75% {
    transform: rotate(0) scale(1.6);
  }
  80% {
    transform: rotate(15deg) scale(1.4);
  }
  85% {
    transform: rotate(0) scale(1.2);
  }
  90% {
    transform: rotate(-15deg) scale(1.1);
    opacity: 1;
  }
  95% {
    transform: rotate(0) scale(1.05);
  }
  100% {
    bottom: ${bottom}px;
    left: ${left};
    transform: scale(1);
    opacity: 0;
  }
`
  return {
    onEmojiClick,
    moveAnimation,
    animationObj,
    getFinalReactionX,
    handleReactionsPickerVisibility,
    handleRemoveReaction,
    reactionsPickerVisible,
    elem,
    wrapperWidth,
    data,
    detectReactionHover,
    currentReactionHover,
    setCurrentReactionHover,
  }
}

export default usePlayerReactins
