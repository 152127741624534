import React from 'react'
import { Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { theme } from '../providers/MuiThemeProvider'
import { Settings } from '@mui/icons-material'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
})

interface IProps {
  active?: boolean
}

const IconSettings = (props: IProps) => {
  const { root } = useStyles()
  const theme = useTheme()

  return (
    <Box className={root} width="60px" display="flex" justifyContent="center" alignItems="center" height="36px">
      <Settings style={{ color: props.active ? theme.palette.primary.main : theme.palette.secondary.light }} />
    </Box>
  )
}

export default IconSettings
