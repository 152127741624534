import React from 'react'
import { Box, BoxProps } from '@mui/material'

interface IconScreensightLogoProps extends BoxProps {
  width?: string
  height?: string
}

const IconScreensightLogo = (props: IconScreensightLogoProps) => {
  return (
    <Box {...props}>
      <svg
        width={props.width || '226'}
        height={props.height || '34'}
        viewBox="0 0 226 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMid meet"
      >
        <path
          d="M53.0335 8.08416e-07L17.6082 0.044928C16.808 0.0374739 16.0363 0.342072 15.4566 0.89419C14.8769 1.44631 14.5348 2.20257 14.5027 3.00275C14.4937 3.40173 14.5645 3.79848 14.7109 4.1697C14.8573 4.54092 15.0764 4.87912 15.3552 5.16443C15.6341 5.44974 15.9671 5.67641 16.3347 5.83114C16.7024 5.98586 17.0972 6.06552 17.496 6.06542L52.723 6.01674C53.5168 6.01674 54.2782 6.33231 54.8395 6.89403C55.4009 7.45575 55.7162 8.21761 55.7162 9.01201L55.7387 24.958C55.7387 25.7524 55.4233 26.5143 54.862 27.076C54.3006 27.6377 53.5393 27.9533 52.7454 27.9533L36.81 27.9758C36.0162 27.9758 35.2548 27.6602 34.6935 27.0985C34.1321 26.5368 33.8168 25.7749 33.8168 24.9805V14.7442C33.8252 13.9431 33.5211 13.1704 32.9691 12.5901C32.4172 12.0098 31.6609 11.6677 30.8609 11.6366C30.4622 11.6276 30.0657 11.6985 29.6947 11.845C29.3238 11.9915 28.9858 12.2107 28.7007 12.4897C28.4156 12.7687 28.189 13.102 28.0344 13.4698C27.8798 13.8377 27.8002 14.2328 27.8003 14.6319V25.2651C27.7983 26.4143 28.0234 27.5526 28.4625 28.6145C28.9017 29.6763 29.5462 30.6409 30.3592 31.4526C31.1721 32.2644 32.1374 32.9073 33.1995 33.3445C34.2617 33.7817 35.3997 34.0044 36.5481 34L53.0784 33.9775C55.3892 33.9736 57.604 33.0517 59.2359 31.4145C60.8678 29.7773 61.7833 27.5587 61.7813 25.2463L61.7589 8.70499C61.7559 6.3946 60.8364 4.17992 59.2024 2.54763C57.5684 0.915341 55.3536 -0.000993545 53.0447 8.08416e-07"
          fill="url(#paint0_linear)"
        />
        <path
          d="M40.7988 17.0093C40.8053 17.8649 41.0852 18.696 41.5975 19.3811C42.1098 20.0662 42.8277 20.5694 43.6462 20.817C44.0108 20.9244 44.3885 20.9811 44.7686 20.9855C45.8229 20.9805 46.8324 20.5585 47.5769 19.8114C48.3213 19.0644 48.7402 18.053 48.7422 16.998C48.7365 16.1417 48.457 15.3098 47.9446 14.6239C47.4323 13.9381 46.714 13.4344 45.8949 13.1866C45.5309 13.0758 45.1528 13.0191 44.7724 13.0181C43.7172 13.022 42.7065 13.4443 41.9617 14.1923C41.217 14.9404 40.7988 15.9533 40.7988 17.0093Z"
          fill="#367FF3"
        />
        <path
          d="M18.7618 24.2654H10.8521C10.0547 24.2654 9.29005 24.5823 8.72624 25.1465C8.16244 25.7107 7.8457 26.4759 7.8457 27.2737C7.8457 28.0716 8.16244 28.8368 8.72624 29.401C9.29005 29.9652 10.0547 30.2821 10.8521 30.2821H18.7655C19.5628 30.2851 20.3287 29.971 20.8946 29.4089C21.4605 28.8469 21.7801 28.0829 21.7831 27.285C21.7861 26.4871 21.4722 25.7207 20.9105 25.1545C20.3488 24.5882 19.5853 24.2684 18.788 24.2654"
          fill="url(#paint1_linear)"
        />
        <path
          d="M76.1231 14.2836C76.1231 13.5086 76.834 12.9807 77.7955 12.9807C78.8806 12.9807 79.5915 13.385 80.7888 14.1601C81.0657 14.3772 81.2827 14.4371 81.4698 14.19L82.6446 12.64C82.7314 12.5557 82.7831 12.4417 82.7893 12.3208C82.7956 12.2 82.7559 12.0813 82.6783 11.9885C82.023 11.2588 81.22 10.6771 80.3226 10.282C79.4252 9.88694 78.4541 9.68755 77.4738 9.69711C74.7462 9.69711 72.2056 11.4007 72.2056 14.3136C72.2056 19.428 79.5466 18.4994 79.5466 20.9144C79.5466 21.8766 78.5888 22.1874 77.5972 22.1874C76.3887 22.1874 75.0567 21.5995 73.8182 20.7908C73.5376 20.5737 73.3206 20.5138 73.1373 20.7609L71.9175 22.4644C71.8423 22.5504 71.8008 22.6609 71.8008 22.7752C71.8008 22.8895 71.8423 22.9999 71.9175 23.0859C72.6516 23.8914 73.5464 24.5338 74.5441 24.9717C75.5417 25.4096 76.6201 25.6333 77.7095 25.6282C80.497 25.6282 83.5015 23.9209 83.5015 20.978C83.5015 15.3993 76.1305 16.7622 76.1305 14.2836"
          fill="#14233D"
        />
        <path
          d="M97.0944 20.484C97.0534 20.4332 97.0015 20.3922 96.9426 20.364C96.8837 20.3359 96.8192 20.3212 96.7539 20.3212C96.6887 20.3212 96.6242 20.3359 96.5653 20.364C96.5064 20.3922 96.4545 20.4332 96.4135 20.484C95.5454 21.2552 94.6137 21.8468 93.1583 21.8468C90.8684 21.8468 89.2558 20.0496 89.2558 17.6609C89.2558 15.2722 90.8684 13.4788 93.1583 13.4788C94.5539 13.4788 95.4519 14.0666 96.3199 14.8416C96.5669 15.0588 96.7839 15.0887 97.0009 14.8716L98.8717 13.0445C98.9178 13.0046 98.9553 12.9557 98.982 12.9008C99.0086 12.8459 99.0238 12.7861 99.0266 12.7252C99.0294 12.6642 99.0198 12.6033 98.9983 12.5462C98.9768 12.4891 98.9439 12.437 98.9016 12.393C98.1783 11.5479 97.2807 10.8693 96.2706 10.404C95.2605 9.93869 94.1618 9.69759 93.0498 9.69727C88.6796 9.69727 85.3047 13.198 85.3047 17.6609C85.3047 22.1239 88.6721 25.6283 93.0498 25.6283C94.1763 25.6338 95.2907 25.3958 96.3168 24.9305C97.3429 24.4653 98.2565 23.7838 98.9951 22.9326C99.0374 22.8886 99.0703 22.8365 99.0918 22.7794C99.1133 22.7223 99.1229 22.6614 99.1201 22.6004C99.1173 22.5395 99.1022 22.4797 99.0755 22.4248C99.0489 22.3699 99.0114 22.321 98.9652 22.2811L97.0944 20.484Z"
          fill="#14233D"
        />
        <path
          d="M111.227 10.4121C110.648 9.94703 109.926 9.6958 109.184 9.70076C107.354 9.70076 106.116 10.7229 105.311 12.1793L105.188 10.5057C105.189 10.4443 105.178 10.3833 105.155 10.3263C105.133 10.2692 105.099 10.2174 105.055 10.174C105.012 10.1306 104.96 10.0965 104.903 10.0737C104.846 10.0509 104.785 10.0399 104.724 10.0415H101.73C101.668 10.0376 101.606 10.047 101.548 10.069C101.49 10.091 101.438 10.125 101.394 10.1689C101.35 10.2128 101.316 10.2656 101.294 10.3236C101.272 10.3817 101.263 10.4438 101.266 10.5057V24.7632C101.263 24.8252 101.272 24.8872 101.294 24.9453C101.316 25.0033 101.35 25.0561 101.394 25.1C101.438 25.1439 101.49 25.1779 101.548 25.1999C101.606 25.2219 101.668 25.2313 101.73 25.2275H104.888C104.95 25.2313 105.012 25.2219 105.07 25.1999C105.128 25.1779 105.181 25.1439 105.225 25.1C105.269 25.0561 105.303 25.0033 105.325 24.9453C105.347 24.8872 105.356 24.8252 105.352 24.7632V16.6984C105.352 14.8264 106.594 13.4149 108.514 13.4149C108.946 13.4167 109.374 13.4901 109.782 13.632C109.847 13.6638 109.917 13.6809 109.989 13.682C110.06 13.6832 110.131 13.6683 110.197 13.6386C110.262 13.6088 110.32 13.5649 110.366 13.51C110.412 13.4551 110.445 13.3907 110.463 13.3213L111.395 11.0299C111.454 10.926 111.47 10.8034 111.441 10.6876C111.412 10.5719 111.339 10.4719 111.238 10.4084"
          fill="#14233D"
        />
        <path
          d="M126.328 17.3202C126.328 13.2017 123.511 9.69727 119.111 9.69727C114.711 9.69727 111.463 13.2017 111.463 17.6609C111.463 22.1201 114.714 25.6283 119.451 25.6283C120.498 25.6333 121.535 25.4303 122.503 25.0312C123.471 24.6321 124.35 24.0447 125.09 23.3033C125.337 23.0561 125.337 22.839 125.12 22.6218L123.758 21.2702C123.541 21.0568 123.354 21.0568 123.077 21.244C122.149 21.9422 121.016 22.3123 119.855 22.2961C117.468 22.2961 115.923 21.1504 115.519 19.1361H125.247C126.175 19.1361 126.328 18.5146 126.328 17.3052V17.3202ZM115.455 16.4666C115.796 14.3324 117.285 13.1531 119.144 13.1531C121.004 13.1531 122.426 14.3324 122.703 16.4666H115.455Z"
          fill="#14233D"
        />
        <path
          d="M143.131 17.3202C143.131 13.2017 140.31 9.69727 135.913 9.69727C131.517 9.69727 128.262 13.2017 128.262 17.6609C128.262 22.1201 131.517 25.6283 136.254 25.6283C137.299 25.6325 138.335 25.4291 139.302 25.03C140.268 24.6309 141.146 24.044 141.885 23.3033C142.135 23.0561 142.135 22.839 141.918 22.6218L140.56 21.2702C140.343 21.0568 140.156 21.0568 139.876 21.244C138.948 21.9422 137.815 22.3123 136.654 22.2961C134.271 22.2961 132.722 21.1504 132.321 19.1361H142.049C142.977 19.1361 143.134 18.5146 143.134 17.3052L143.131 17.3202ZM132.261 16.4666C132.602 14.3324 134.091 13.1531 135.947 13.1531C137.803 13.1531 139.228 14.3324 139.509 16.4666H132.261Z"
          fill="#14233D"
        />
        <path
          d="M154.121 9.69718C151.921 9.69718 150.309 10.9028 149.317 12.5165V12.4865L149.194 10.5022C149.195 10.4408 149.184 10.3797 149.161 10.3227C149.139 10.2657 149.105 10.2139 149.061 10.1704C149.018 10.127 148.966 10.0929 148.909 10.0701C148.852 10.0473 148.791 10.0364 148.73 10.0379H145.695C145.633 10.0341 145.571 10.0434 145.513 10.0654C145.455 10.0874 145.403 10.1215 145.359 10.1654C145.315 10.2093 145.281 10.262 145.259 10.3201C145.237 10.3781 145.227 10.4402 145.231 10.5022V24.7596C145.227 24.8216 145.237 24.8837 145.259 24.9417C145.281 24.9998 145.315 25.0525 145.359 25.0964C145.403 25.1403 145.455 25.1744 145.513 25.1964C145.571 25.2184 145.633 25.2277 145.695 25.2239H148.853C148.915 25.2277 148.977 25.2184 149.035 25.1964C149.093 25.1744 149.146 25.1403 149.19 25.0964C149.234 25.0525 149.268 24.9998 149.29 24.9417C149.312 24.8837 149.321 24.8216 149.317 24.7596V16.4515C149.317 15.7401 150.372 13.4787 152.662 13.4787C154.159 13.4787 155.513 14.3436 155.513 16.8484V24.7521C155.51 24.814 155.52 24.8758 155.542 24.9336C155.564 24.9913 155.598 25.0438 155.642 25.0876C155.686 25.1314 155.738 25.1655 155.796 25.1876C155.854 25.2098 155.915 25.2196 155.977 25.2164H159.139C159.201 25.2196 159.262 25.2098 159.32 25.1876C159.378 25.1655 159.43 25.1314 159.474 25.0876C159.518 25.0438 159.552 24.9913 159.574 24.9336C159.596 24.8758 159.606 24.814 159.603 24.7521V16.1033C159.603 12.0447 157.216 9.6897 154.121 9.6897"
          fill="#14233D"
        />
        <path
          d="M166.109 14.2836C166.109 13.5086 166.82 12.9807 167.782 12.9807C168.863 12.9807 169.578 13.385 170.752 14.1601C171.033 14.3772 171.25 14.4371 171.433 14.19L172.612 12.64C172.699 12.5557 172.751 12.4413 172.756 12.3202C172.762 12.1991 172.721 12.0804 172.642 11.9885C171.987 11.2587 171.185 10.6769 170.288 10.2818C169.392 9.88669 168.421 9.68737 167.441 9.69712C164.713 9.69712 162.173 11.4007 162.173 14.3136C162.173 19.428 169.514 18.4995 169.514 20.9144C169.514 21.8766 168.556 22.1874 167.565 22.1874C166.356 22.1874 165.024 21.5996 163.786 20.7908C163.505 20.5737 163.288 20.5138 163.105 20.7609L161.896 22.4644C161.821 22.5505 161.779 22.6609 161.779 22.7752C161.779 22.8895 161.821 22.9999 161.896 23.086C162.631 23.894 163.528 24.5381 164.529 24.9761C165.529 25.4141 166.611 25.6363 167.703 25.6282C170.49 25.6282 173.495 23.9209 173.495 20.978C173.495 15.3994 166.124 16.7622 166.124 14.2836"
          fill="#14233D"
        />
        <path
          d="M179.331 10.038H176.173C176.111 10.0348 176.049 10.0447 175.991 10.0671C175.932 10.0894 175.88 10.1238 175.835 10.1679C175.791 10.212 175.757 10.2649 175.735 10.3232C175.712 10.3814 175.702 10.4437 175.706 10.506V24.7635C175.702 24.8257 175.712 24.8878 175.735 24.9459C175.757 25.0039 175.791 25.0566 175.836 25.1004C175.88 25.1443 175.933 25.1783 175.991 25.2002C176.049 25.2221 176.111 25.2315 176.173 25.2278H179.331C179.394 25.2322 179.456 25.2232 179.514 25.2015C179.573 25.1797 179.626 25.1457 179.67 25.1018C179.715 25.0578 179.749 25.0049 179.771 24.9465C179.793 24.8882 179.803 24.8258 179.799 24.7635V10.5023C179.803 10.4398 179.793 10.3773 179.771 10.3188C179.749 10.2602 179.715 10.2071 179.67 10.1629C179.626 10.1186 179.573 10.0843 179.515 10.0621C179.456 10.0399 179.394 10.0304 179.331 10.0343"
          fill="#14233D"
        />
        <path
          d="M197.47 10.038H194.619C194.558 10.0364 194.497 10.0474 194.44 10.0702C194.383 10.093 194.331 10.1271 194.288 10.1705C194.244 10.214 194.21 10.2657 194.188 10.3228C194.165 10.3798 194.154 10.4409 194.155 10.5022L194.062 11.8651C193.495 11.1905 192.789 10.6473 191.992 10.273C191.194 9.89866 190.325 9.70223 189.445 9.69727C185.202 9.69727 182.107 13.2279 182.107 17.5074C182.107 21.7869 185.202 25.3176 189.445 25.3176C190.348 25.3186 191.239 25.1174 192.054 24.7287C192.87 24.34 193.587 23.7736 194.155 23.0711V24.7447C194.155 27.257 191.491 27.9684 189.632 27.9684C187.998 28.0111 186.384 27.6155 184.955 26.8227C184.904 26.7865 184.846 26.7623 184.785 26.752C184.724 26.7418 184.661 26.7456 184.601 26.7633C184.542 26.781 184.487 26.8121 184.441 26.8542C184.396 26.8963 184.36 26.9482 184.337 27.0062L183.316 28.8782C183.282 28.9267 183.258 28.9815 183.246 29.0395C183.234 29.0974 183.233 29.1573 183.245 29.2154C183.256 29.2735 183.28 29.3286 183.313 29.3775C183.346 29.4264 183.389 29.4679 183.439 29.4997C185.389 30.7497 187.661 31.4005 189.976 31.3717C193.441 31.3717 197.934 29.9116 197.934 25.0779V10.5022C197.938 10.4403 197.929 10.3782 197.907 10.3201C197.885 10.2621 197.851 10.2093 197.807 10.1654C197.763 10.1215 197.71 10.0875 197.652 10.0655C197.594 10.0435 197.532 10.0341 197.47 10.038ZM189.942 21.5997C187.697 21.5997 186.04 19.7688 186.04 17.4812C186.04 15.1936 187.682 13.4077 189.942 13.4077C192.202 13.4077 193.815 15.3284 193.815 17.4662C193.815 19.6041 192.172 21.5847 189.942 21.5847"
          fill="#14233D"
        />
        <path
          d="M209.309 9.69702C207.109 9.69702 205.5 10.9026 204.508 12.5163V3.06251C204.512 3.00054 204.503 2.93848 204.481 2.88041C204.459 2.82235 204.425 2.76962 204.381 2.72572C204.337 2.68182 204.284 2.64775 204.226 2.62577C204.168 2.6038 204.106 2.59441 204.044 2.59825H200.883C200.821 2.59441 200.759 2.6038 200.701 2.62577C200.643 2.64775 200.59 2.68182 200.546 2.72572C200.502 2.76962 200.468 2.82235 200.446 2.88041C200.424 2.93848 200.415 3.00054 200.419 3.06251V24.7782C200.416 24.84 200.425 24.9018 200.448 24.9596C200.47 25.0174 200.504 25.0699 200.548 25.1137C200.591 25.1574 200.644 25.1915 200.701 25.2137C200.759 25.2359 200.821 25.2457 200.883 25.2424H204.044C204.106 25.2457 204.168 25.2359 204.226 25.2137C204.283 25.1915 204.336 25.1574 204.38 25.1137C204.423 25.0699 204.457 25.0174 204.48 24.9596C204.502 24.9018 204.512 24.84 204.508 24.7782V16.4513C204.508 15.74 205.56 13.4785 207.853 13.4785C209.35 13.4785 210.704 14.3434 210.704 16.8482V24.752C210.701 24.8139 210.71 24.876 210.732 24.9341C210.754 24.9921 210.788 25.0449 210.832 25.0888C210.876 25.1327 210.928 25.1667 210.986 25.1887C211.044 25.2107 211.106 25.2201 211.168 25.2162H214.326C214.388 25.2201 214.45 25.2107 214.508 25.1887C214.566 25.1667 214.619 25.1327 214.663 25.0888C214.707 25.0449 214.741 24.9921 214.763 24.9341C214.785 24.876 214.794 24.8139 214.79 24.752V16.1032C214.79 12.0446 212.407 9.68954 209.309 9.68954"
          fill="#14233D"
        />
        <path
          d="M225.535 10.0377H223.365V5.85183C223.369 5.78987 223.36 5.72779 223.338 5.66972C223.316 5.61166 223.282 5.55893 223.238 5.51503C223.194 5.47113 223.141 5.43706 223.083 5.41508C223.025 5.39311 222.963 5.38373 222.901 5.38756H219.743C219.681 5.38317 219.619 5.39215 219.56 5.41387C219.502 5.4356 219.449 5.46958 219.404 5.51354C219.36 5.5575 219.326 5.61043 219.303 5.66878C219.281 5.72712 219.272 5.78953 219.276 5.85183V10.0377H217.109C217.047 10.0339 216.985 10.0433 216.927 10.0652C216.869 10.0872 216.817 10.1213 216.773 10.1652C216.729 10.2091 216.695 10.2618 216.673 10.3199C216.651 10.3779 216.642 10.44 216.645 10.502V12.9207C216.642 12.9826 216.651 13.0447 216.673 13.1028C216.695 13.1608 216.729 13.2136 216.773 13.2575C216.817 13.3014 216.869 13.3354 216.927 13.3574C216.985 13.3794 217.047 13.3888 217.109 13.3849H219.276V24.7594C219.272 24.8216 219.282 24.8837 219.305 24.9418C219.327 24.9999 219.361 25.0525 219.406 25.0964C219.45 25.1402 219.503 25.1742 219.561 25.1961C219.619 25.2181 219.681 25.2275 219.743 25.2237H222.901C222.963 25.2269 223.025 25.2171 223.083 25.195C223.14 25.1728 223.193 25.1387 223.237 25.0949C223.28 25.0511 223.314 24.9987 223.337 24.9409C223.359 24.8831 223.368 24.8213 223.365 24.7594V13.3849H225.535C225.597 13.3882 225.659 13.3784 225.717 13.3562C225.774 13.334 225.827 13.2999 225.871 13.2561C225.914 13.2124 225.948 13.1599 225.971 13.1021C225.993 13.0443 226.003 12.9825 225.999 12.9207V10.502C226.003 10.44 225.994 10.3779 225.972 10.3199C225.95 10.2618 225.916 10.2091 225.872 10.1652C225.828 10.1213 225.775 10.0872 225.717 10.0652C225.659 10.0433 225.597 10.0339 225.535 10.0377Z"
          fill="#14233D"
        />
        <path
          d="M177.753 1.80054C177.222 1.80054 176.702 1.9582 176.26 2.25359C175.819 2.54898 175.474 2.96883 175.271 3.46004C175.068 3.95126 175.014 4.49177 175.118 5.01324C175.222 5.53471 175.478 6.01371 175.853 6.38967C176.229 6.76562 176.708 7.02166 177.229 7.12539C177.75 7.22911 178.29 7.17587 178.781 6.9724C179.272 6.76894 179.691 6.42439 179.987 5.98231C180.282 5.54022 180.439 5.02047 180.439 4.48879C180.439 3.77582 180.156 3.09205 179.652 2.5879C179.149 2.08376 178.465 1.80054 177.753 1.80054Z"
          fill="#187FFB"
        />
        <path
          d="M18.746 12.239L4.00042 12.2577C3.20258 12.2577 2.43743 12.5749 1.87328 13.1394C1.30912 13.7039 0.992188 14.4696 0.992188 15.268C0.992188 16.0663 1.30912 16.832 1.87328 17.3965C2.43743 17.9611 3.20258 18.2782 4.00042 18.2782L18.7423 18.2595C19.5401 18.2595 20.3052 17.9423 20.8694 17.3778C21.4335 16.8133 21.7505 16.0476 21.7505 15.2493C21.7505 14.4509 21.4335 13.6852 20.8694 13.1207C20.3052 12.5562 19.5401 12.239 18.7423 12.239"
          fill="url(#paint2_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="14.5027"
            y1="16.9981"
            x2="61.7813"
            y2="16.9981"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4AA3F5" />
            <stop offset="1" stopColor="#2C6EF2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="7.84757"
            y1="27.2943"
            x2="21.7737"
            y2="27.2943"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#53B2F6" />
            <stop offset="1" stopColor="#469BF5" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="4162.2"
            y1="2959.07"
            x2="5313.87"
            y2="2959.07"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#53B2F6" />
            <stop offset="1" stopColor="#469BF5" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  )
}

export default IconScreensightLogo
