import { useState } from 'react'
import { PartnerSidebarCurrentView } from '../types/PartnerSidebarCurrentView'

const usePartnerSidebar = () => {
  const [currentView, setCurrentView] = useState<PartnerSidebarCurrentView>(
    (localStorage.getItem('currentPartnerSidebarView') as PartnerSidebarCurrentView) || 'media',
  )

  const handleChangeCurrentView = (view: PartnerSidebarCurrentView) => {
    setCurrentView(view)
    localStorage.setItem('currentPartnerSidebarView', view)
  }

  return {
    currentView,
    handleChangeCurrentView,
  }
}

export default usePartnerSidebar
