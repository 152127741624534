import * as React from 'react'
import TemplateUserData from '../components/TemplateUserData'
import TemplateVideoData from '../components/TemplateVideoData'
import TemplateBrandLogo from '../components/TemplateBrandLogo'
import { Box } from '@mui/material'
import { VideoStatus } from '../../../../../API'

interface IEmailTemplatePreviewProps {
  isMobile: boolean
  videoTitle: string | null | undefined
  videoDateAdded: string | null | undefined
  thumbnailObject: any
  videoPassword: string | null | undefined
  videoStatus?: VideoStatus
}
const EmailTemplatePreview = (props: IEmailTemplatePreviewProps) => {
  return (
    <Box
      width={props.isMobile ? 'calc(100% + 40px)' : '100%'}
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginLeft={props.isMobile ? '-20px' : '0'}
      padding={props.isMobile ? '10px 20px 45px 20px' : '10px 0 95px 0'}
      bgcolor={props.isMobile ? 'background.default' : 'transparent'}
      style={{
        borderBottomLeftRadius: props.isMobile ? '10px' : '0',
        borderBottomRightRadius: props.isMobile ? '10px' : '0',
      }}
    >
      <TemplateUserData />
      <TemplateVideoData
        isMobile={props.isMobile}
        videoTitle={props.videoTitle}
        videoDateAdded={props.videoDateAdded}
        thumbnailObject={props.thumbnailObject}
        videoPassword={props.videoPassword}
        videoStatus={props?.videoStatus}
      />
      <TemplateBrandLogo />
    </Box>
  )
}

export default EmailTemplatePreview
