import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import { useChatDispatch } from '../../contexts/ChatContext'
import { useMutation } from '@apollo/client'
import { CreatePostMutation, CreatePostMutationVariables } from '../../../../../../../../API'
import gql from 'graphql-tag'
import { createPost } from '../../../../../../../../common/api/graphql/mutations'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import { useParams } from 'react-router'
import useUpdateChannelsCache from './useUpdateChannelsCache'

const useSendReplyPost = () => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const chatDispatcher = useChatDispatch()
  const [updateChannelLatestAt] = useUpdateChannelsCache()
  const customerIdToUse = auth.isAuthenticated
    ? auth.user?.id
    : sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId') || ''
  const [createPostMutation] = useMutation<CreatePostMutation, CreatePostMutationVariables>(gql(createPost), {
    client: !auth.user?.pending && auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
  })

  const sendReplyPost = async (content: string, richContent: string, parentId: string, videoId?: string) => {
    chatDispatcher({ type: 'setResponseBoxData', value: undefined })
    const res = await createPostMutation({
      variables: {
        input: {
          comment: {
            customerId: customerIdToUse,
            parentId: parentId,
            channelId,
            content,
            richContent,
            videoId: videoId || '',
          },
        },
      },
    })
    if (res.data?.createPost?.channelId && res.data.createPost?.createdAt) {
      updateChannelLatestAt(res.data?.createPost?.channelId, res.data.createPost?.createdAt)
    }
  }

  return [sendReplyPost]
}

export default useSendReplyPost
