import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const VideoNotFound = () => {
  const { t } = useTranslation('library')
  return (
    <Box padding="16px">
      <Typography variant="h6" align="center" style={{ fontWeight: 500 }}>
        {t('videoList.emptySearchResultMessage')}
      </Typography>
    </Box>
  )
}

export default VideoNotFound
