import React from 'react'

const StandingPersonIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1C7.84524 1 6.90476 1.8587 6.90476 2.91304C6.90476 3.96739 7.84524 4.82609 9 4.82609C10.1548 4.82609 11.0952 3.96739 11.0952 2.91304C11.0952 1.8587 10.1548 1 9 1ZM7.09524 5.17391C5.94048 5.17391 5 6.03261 5 7.08696V11.4348C5 11.8193 5.34226 12.1304 5.7619 12.1304C6.18155 12.1304 6.52381 11.8193 6.52381 11.4348V8.11957C6.52381 8.02582 6.61161 7.94565 6.71429 7.94565C6.81696 7.94565 6.90476 8.02582 6.90476 8.11957V16.0652C6.90476 16.6087 7.2247 17 7.82143 17C8.38542 17 8.80952 16.6005 8.80952 16.0652V11.5978C8.80952 11.5014 8.89435 11.4239 9 11.4239C9.10565 11.4239 9.19048 11.5014 9.19048 11.5978V16.163C9.19196 16.1658 9.20089 16.1603 9.20238 16.163C9.25446 16.644 9.65179 17 10.1786 17C10.7738 17 11.0952 16.6087 11.0952 16.0652V8.18478C11.0952 8.09103 11.183 8.01087 11.2857 8.01087C11.3884 8.01087 11.4762 8.09103 11.4762 8.18478V11.4348C11.4762 11.8193 11.8185 12.1304 12.2381 12.1304C12.6577 12.1304 13 11.8193 13 11.4348V7.08696C13 6.03261 12.0595 5.17391 10.9048 5.17391H7.09524Z"
        fill="white"
      />
    </svg>
  )
}

export default StandingPersonIcon
