import React from 'react'

const UploadVideoIcon = () => {
  return (
    <svg width="38" height="41" viewBox="0 0 38 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7317 0.60393C19.33 0.193973 18.67 0.193972 18.2683 0.603929L12.9808 6.0011C12.7119 6.27562 12.7119 6.71481 12.9808 6.98933C13.2577 7.27192 13.7127 7.27192 13.9895 6.98933L18.3 2.58947V25.9263C18.3 26.3129 18.6134 26.6263 19 26.6263C19.3866 26.6263 19.7 26.3129 19.7 25.9263V2.58947L24.0105 6.98933C24.2873 7.27192 24.7423 7.27192 25.0192 6.98933C25.2881 6.71481 25.2881 6.27562 25.0192 6.0011L19.7317 0.60393ZM4 5.60335C2.29285 5.60335 0.9 7.02806 0.9 8.76555V36.9378C0.9 38.6753 2.29285 40.1 4 40.1H34C35.7072 40.1 37.1 38.6753 37.1 36.9378V8.76555C37.1 7.02806 35.7072 5.60335 34 5.60335H29.8124C29.419 5.60335 29.1 5.92232 29.1 6.31579C29.1 6.70926 29.419 7.02823 29.8124 7.02823H34C34.9436 7.02823 35.7 7.7973 35.7 8.76555V36.9378C35.7 37.906 34.9436 38.6751 34 38.6751H4C3.05637 38.6751 2.3 37.906 2.3 36.9378V8.76555C2.3 7.7973 3.05637 7.02823 4 7.02823H8.18756C8.58103 7.02823 8.9 6.70926 8.9 6.31579C8.9 5.92232 8.58103 5.60335 8.18756 5.60335H4Z"
        fill="#7A7A86"
        stroke="#7A7A86"
        strokeWidth="0.2"
      />
      <path
        d="M23.2523 34C22.8551 34 22.5332 34.308 22.5332 34.6881C22.5332 35.0682 22.8551 35.3763 23.2523 35.3763H25.0463C25.4434 35.3763 25.7655 35.0682 25.7655 34.6881C25.7655 34.308 25.4435 34 25.0463 34H23.2523Z"
        fill="#7A7A86"
      />
      <path
        d="M18.4829 34C18.0857 34 17.7637 34.308 17.7637 34.6881C17.7637 35.0682 18.0856 35.3763 18.4829 35.3763H20.2769C20.6741 35.3763 20.9961 35.0682 20.9961 34.6881C20.9961 34.308 20.6742 34 20.2769 34H18.4829Z"
        fill="#7A7A86"
      />
      <path
        d="M13.7192 34C13.322 34 13 34.308 13 34.6881C13 35.0682 13.322 35.3763 13.7192 35.3763H15.5133C15.9104 35.3763 16.2325 35.0682 16.2325 34.6881C16.2325 34.308 15.9105 34 15.5133 34H13.7192Z"
        fill="#7A7A86"
      />
      <path
        d="M5 22.5132C5 22.9103 5.30803 23.2323 5.68813 23.2323C6.06822 23.2323 6.37625 22.9103 6.37625 22.5132V20.7192C6.37625 20.322 6.06822 20 5.68813 20C5.30803 20 5 20.3219 5 20.7192V22.5132Z"
        fill="#7A7A86"
      />
      <path
        d="M5 27.2826C5 27.6798 5.30803 28.0018 5.68813 28.0018C6.06822 28.0018 6.37625 27.6799 6.37625 27.2826V25.4885C6.37625 25.0914 6.06822 24.7693 5.68813 24.7693C5.30803 24.7693 5 25.0913 5 25.4885V27.2826Z"
        fill="#7A7A86"
      />
      <path
        d="M5 32.0463C5 32.4434 5.30803 32.7655 5.68813 32.7655C6.06822 32.7655 6.37625 32.4434 6.37625 32.0463V30.2522C6.37625 29.855 6.06822 29.533 5.68813 29.533C5.30803 29.533 5 29.855 5 30.2522V32.0463Z"
        fill="#7A7A86"
      />
      <path
        d="M31.6003 22.5132C31.6003 22.9103 31.9084 23.2323 32.2885 23.2323C32.6686 23.2323 32.9766 22.9103 32.9766 22.5132V20.7192C32.9766 20.322 32.6686 20 32.2885 20C31.9084 20 31.6003 20.3219 31.6003 20.7192V22.5132Z"
        fill="#7A7A86"
      />
      <path
        d="M31.6003 27.2826C31.6003 27.6798 31.9084 28.0018 32.2885 28.0018C32.6686 28.0018 32.9766 27.6799 32.9766 27.2826V25.4885C32.9766 25.0914 32.6686 24.7693 32.2885 24.7693C31.9084 24.7693 31.6003 25.0913 31.6003 25.4885V27.2826Z"
        fill="#7A7A86"
      />
      <path
        d="M31.6003 32.0463C31.6003 32.4434 31.9084 32.7655 32.2885 32.7655C32.6686 32.7655 32.9766 32.4434 32.9766 32.0463V30.2522C32.9766 29.855 32.6686 29.533 32.2885 29.533C31.9084 29.533 31.6003 29.855 31.6003 30.2522V32.0463Z"
        fill="#7A7A86"
      />
    </svg>
  )
}

export default UploadVideoIcon
