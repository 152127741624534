import React, { SVGProps } from 'react'
import { Box, BoxProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    marginTop: 5,
    // width: 23,
    // height: 15,
  },
})

const IconSmallCamera = (props: BoxProps & SVGProps<SVGElement>) => {
  const { color } = props
  const { root } = useStyles()

  return (
    <Box className={root} {...props}>
      <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.3 0C1.01164 0 0 1.01164 0 2.3V12.42C0 13.7084 1.01164 14.72 2.3 14.72H14.72C16.0084 14.72 17.02 13.7084 17.02 12.42V2.3C17.02 1.01164 16.0084 0 14.72 0H2.3ZM23 1.52375L17.94 4.22625V10.4937L23 13.1962V1.52375Z"
          fill={color || 'white'}
        />
      </svg>
    </Box>
  )
}

export default IconSmallCamera
