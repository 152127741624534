import React, { createContext, useReducer } from 'react'

interface IForgotPasswordContextState {
  email: string
  currentView: 'EMAIL_VIEW' | 'PASSWORD_VIEW'
}

const initialState: IForgotPasswordContextState = {
  email: '',
  currentView: 'EMAIL_VIEW',
}

export type ForgotPasswordContextAction = {
  type: 'setEmail' | 'setCurrentView'
  value?: any
}

const appointmentBookReducer = (
  state: IForgotPasswordContextState,
  action: ForgotPasswordContextAction,
): IForgotPasswordContextState => {
  switch (action.type) {
    case 'setEmail':
      return {
        ...state,
        email: action.value,
      }
    case 'setCurrentView':
      return {
        ...state,
        currentView: action.value,
      }

    default:
      throw new Error()
  }
}

const ForgotPasswordStateContext = createContext<IForgotPasswordContextState>(initialState)
const ForgotPasswordDispatchContext = createContext<React.Dispatch<ForgotPasswordContextAction> | null>(null)

interface IAppointmentBookContextProps {
  children: React.ReactNode
}

const ForgotPasswordContext = ({ children }: IAppointmentBookContextProps) => {
  const [state, dispatch] = useReducer(appointmentBookReducer, initialState)

  return (
    <ForgotPasswordStateContext.Provider value={state}>
      <ForgotPasswordDispatchContext.Provider value={dispatch}>{children}</ForgotPasswordDispatchContext.Provider>
    </ForgotPasswordStateContext.Provider>
  )
}

function useForgotPasswordContext() {
  const context = React.useContext(ForgotPasswordStateContext)
  if (!context) {
    throw new Error('useForgotPasswordState must be used within the AppProvider')
  }
  return context
}

function useForgotPasswordDispatch() {
  const context = React.useContext(ForgotPasswordDispatchContext)
  if (!context) {
    throw new Error('useForgotPasswordDispatch must be used within the AppProvider')
  }
  return context
}

export { ForgotPasswordContext, useForgotPasswordContext, useForgotPasswordDispatch }
