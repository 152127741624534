import React from 'react'
import CognitoApolloClient from 'common/clients/CognitoApolloClient'

import { ApolloProvider } from '@apollo/client'

const ClientAuthProvider = (props: any) => (
  <ApolloProvider client={CognitoApolloClient}>{props.children}</ApolloProvider>
)

export default ClientAuthProvider
