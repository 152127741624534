import * as React from 'react'
import { useEffect } from 'react'

type InitializeOptions = 'SUMMARY' | 'TRANSCRIPTION'

const useVideoPostAISummary = (transcriptionSummaryError: any) => {
  const [contentSelected, setContentSelected] = React.useState<InitializeOptions>('SUMMARY')
  const [experimentalInfoVisible, setExperimentalInfoVisible] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>(transcriptionSummaryError || '')

  useEffect(() => {
    if (transcriptionSummaryError) setError(transcriptionSummaryError)
  }, [transcriptionSummaryError])

  const handleExperimentalInfoVisibility = (isVisible: boolean) => {
    setExperimentalInfoVisible(isVisible)
  }

  const handleErrorMessage = (message: string) => {
    setError(message)
  }

  const handleContentOptionSelection = (initializerOption: InitializeOptions) => {
    setContentSelected(initializerOption)
  }

  return {
    handleExperimentalInfoVisibility,
    handleErrorMessage,
    handleContentOptionSelection,
    contentSelected,
    experimentalInfoVisible,
    error,
    setError,
    setContentSelected,
  }
}

export default useVideoPostAISummary
