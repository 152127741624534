import { useContext } from 'react'
import { AuthContext } from '../providers/AuthStatusProvider'
import CognitoApolloClient from './CognitoApolloClient'
import IamApolloClient from './IamApolloClient'

const useClient = () => {
  const auth = useContext(AuthContext)
  return auth.isAuthenticated ? CognitoApolloClient : IamApolloClient
}

export default useClient
