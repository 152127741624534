import * as React from 'react'
import { Box, useTheme } from '@mui/material'
import CloudFrontImage from '../../../../../../../../components/_refactored/CloudFrontImage'
import { FilePost as IFilePost } from '../../../../../../../../API'
import AttachmentIcon from '../../../../../../../../common/icons/AttachmentIcon'

interface IFileThumbnailProps {
  isImage?: boolean | null
  isPdf?: boolean | null
  isDoc?: boolean | null
  isGif?: boolean
  isExcel?: boolean
  imageSource: string
  width?: string
  height?: string
  isReactive?: boolean
  file?: IFilePost | null
  owner?: boolean
  isResponseBox?: boolean
}
const FileThumbnail = (props: IFileThumbnailProps) => {
  const theme = useTheme()
  return (
    <Box display={'flex'} alignItems={'center'}>
      {props.isImage && props.imageSource.includes('protected') ? (
        <CloudFrontImage
          image={props.imageSource}
          disableResize={props?.isGif}
          height={'40'}
          width={'40'}
          imgStyle={{
            width: '40px',
            height: '40px',
            borderRadius: '4px',
            objectFit: 'cover',
          }}
        />
      ) : props.isImage && props.imageSource.includes('blob') ? (
        <img
          alt={'File thumbnail'}
          src={props.imageSource}
          height={props.height || '30px'}
          width={props.width || '30px'}
          style={{ borderRadius: '6px', marginRight: '5px', objectFit: 'cover', objectPosition: 'center' }}
        />
      ) : (
        <Box
          className="file-thumbnail"
          bgcolor={props.isResponseBox ? '#F5F5F6' : 'white!important'}
          height={props.height || '30px'}
          width={props.width || '30px'}
          borderRadius="6px"
          marginRight="5px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <AttachmentIcon color={props.owner ? theme.palette.primary.main : theme.palette.secondary.main} />
        </Box>
      )}
    </Box>
  )
}

export default FileThumbnail
