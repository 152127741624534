import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import { useMutation } from '@apollo/client'
import { UpdateCustomerMutation, UpdateCustomerMutationVariables } from '../../../../API'
import gql from 'graphql-tag'
import { updateCustomer } from '../../../../graphql/mutations'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import { useForm } from 'react-hook-form'
import ToastContext from '../../../../common/providers/ToastProvider/ToastContext'
import { useTranslation } from 'react-i18next'

export interface ICalendlyForm {
  url: string
}

const useIntegrationsHook = () => {
  const auth = useContext(AuthContext)
  const [calendlyLoading, setCalendlyLoading] = useState(false)
  const { t } = useTranslation('settings')
  const { t: g } = useTranslation('general')
  const { open } = useContext(ToastContext)

  const form = useForm<ICalendlyForm>({
    mode: 'onChange',
    defaultValues: {
      url: auth.user?.calendlyUrl || '',
    },
  })

  useEffect(() => {
    form.reset({ url: auth?.user?.calendlyUrl })
  }, [auth.user?.calendlyUrl])

  const [updateUserMutation] = useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
    gql(updateCustomer),
    {
      client: CognitoApolloClient,
    },
  )

  const submit = form.handleSubmit(async (values) => {
    try {
      setCalendlyLoading(true)
      if (!auth.user?.id || !auth.user.settings) return
      await Promise.all([
        updateUserMutation({
          variables: {
            input: { id: auth.user?.id, calendlyUrl: values.url },
          },
        }),
        auth.updateUser({
          ...auth.user,
          calendlyUrl: values.url,
        }),
      ])
      form.reset(
        { url: values.url },
        {
          isDirty: false,
          touched: false,
        },
      )
      open({ type: 'INFO', text: g('common.profileUpdateSuccess') })
    } catch (e: any) {
      form.setError('url', { type: 'validate', message: t('integrations.profileUpdateFormError') })
      open({ type: 'ERROR', text: t('integrations.profileUpdateErrorMessage') })
      console.log('Error during updating calendly integration', e)
    } finally {
      setCalendlyLoading(false)
    }
  })
  return {
    form,
    submit,
    calendlyLoading,
  }
}

export default useIntegrationsHook
