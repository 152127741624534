import React from 'react'
import VideoStatisticsView from './view/VideoStatistics.view'
import useVideoStatisticsHook from './hooks/useVideoStatistics.hook'
import PageTitle from '../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

const VideoStatistics = () => {
  const state = useVideoStatisticsHook()
  const { t: g } = useTranslation('general')

  return (
    <>
      <PageTitle name={`${g('pageName.analytics')} - ${state.videoData?.getVideo?.title || ''}`} />
      <VideoStatisticsView {...state} />
    </>
  )
}

export default VideoStatistics
