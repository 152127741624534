import React from 'react'

interface IProps {
  color?: string
  style?: React.CSSProperties
}

const SendMailIcon = (props: IProps) => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={props?.style}>
      <path
        d="M1.25 0C0.918479 0 0.600537 0.122916 0.366116 0.341709C0.131696 0.560501 0 0.857247 0 1.16667V4.66667C0.000406738 4.9083 0.0811904 5.14386 0.231194 5.3408C0.381197 5.53774 0.593018 5.68636 0.837402 5.76611C0.969905 5.81002 1.1094 5.83274 1.25 5.83333C1.25448 5.83336 1.25895 5.83336 1.26343 5.83333L8.75 7L1.26343 8.16781C1.25895 8.1674 1.25448 8.16702 1.25 8.16667C1.10899 8.16713 0.969071 8.18985 0.836182 8.23389C0.592025 8.31384 0.380468 8.46253 0.230696 8.65946C0.0809234 8.85638 0.000317956 9.09184 0 9.33333V12.8333C0 13.1428 0.131696 13.4395 0.366116 13.6583C0.600537 13.8771 0.918479 14 1.25 14C1.46763 13.9996 1.68139 13.9462 1.87012 13.8451H1.87134L14.6191 7.53776L14.6204 7.53548C14.7328 7.49068 14.8287 7.41606 14.8962 7.32088C14.9637 7.2257 14.9998 7.11413 15 7C15.0002 6.88535 14.9641 6.7732 14.8964 6.67756C14.8287 6.58192 14.7322 6.50702 14.6191 6.46224L1.87134 0.154948C1.68225 0.053603 1.46805 0.000185641 1.25 0Z"
        fill={props?.color || '#7A7A86'}
      />
    </svg>
  )
}

export default SendMailIcon
