/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import { postFields } from './postFields'

export const setVideoViewedBy = /* GraphQL */ `
  mutation SetVideoViewedBy($input: SetVideoViewedInput) {
    setVideoViewedBy(input: $input) {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          timezone
        }
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
      }
      accessType
      accessId
      password
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
            }
            accessType
            accessId
            password
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            commentsCount
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      updatedAt
    }
  }
`
export const getOrCreateCustomer = /* GraphQL */ `
  mutation GetOrCreateCustomer($input: CreateScreensightCustomerInput) {
    getOrCreateCustomer(input: $input) {
      id
      email
      firstName
      lastName
      profilePhoto {
        key
        bucket
      }
      calendlyUrl
      lastSeen
      settings {
        notifyEmailWatched
        notifyEmailComment
        timezone
      }
      createdAt
      updatedAt
    }
  }
`
export const createGuestCustomer = /* GraphQL */ `
  mutation CreateGuestCustomer(
    $input: CreateScreensightCustomerInput!
    $condition: ModelScreensightCustomerConditionInput
  ) {
    createGuestCustomer(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      profilePhoto {
        key
        bucket
      }
      calendlyUrl
      lastSeen
      settings {
        notifyEmailWatched
        notifyEmailComment
        timezone
      }
      createdAt
      updatedAt
    }
  }
`
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateScreensightCustomerInput!, $condition: ModelScreensightCustomerConditionInput) {
    updateCustomer(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      profilePhoto {
        key
        bucket
      }
      calendlyUrl
      lastSeen
      settings {
        notifyEmailWatched
        notifyEmailComment
        timezone
      }
      createdAt
      updatedAt
    }
  }
`
export const createContact = /* GraphQL */ `
  mutation CreateContact($input: CreateScreensightContactInput!, $condition: ModelScreensightContactConditionInput) {
    createContact(input: $input, condition: $condition) {
      id
      ownerCustomerId
      firstName
      lastName
      email
      mobilePhone
      contactCustomerMeta {
        items {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            timezone
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const updateContact = /* GraphQL */ `
  mutation UpdateContact($input: UpdateScreensightContactInput!, $condition: ModelScreensightContactConditionInput) {
    updateContact(input: $input, condition: $condition) {
      id
      ownerCustomerId
      firstName
      lastName
      email
      mobilePhone
      contactCustomerMeta {
        items {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            timezone
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact($input: DeleteScreensightContactInput!, $condition: ModelScreensightContactConditionInput) {
    deleteContact(input: $input, condition: $condition) {
      id
      ownerCustomerId
      firstName
      lastName
      email
      mobilePhone
      contactCustomerMeta {
        items {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            timezone
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const createChannel = /* GraphQL */ `
  mutation CreateChannel($input: CreateScreensightChannelInput!, $condition: ModelScreensightChannelConditionInput) {
    createChannel(input: $input, condition: $condition) {
      id
      customerId
      channelType
      name
      categoryPosition
      readHorizon
      activity {
        new
        latestAt
      }
      createdAt
      updatedAt
    }
  }
`
export const updateChannel = /* GraphQL */ `
  mutation UpdateChannel($input: UpdateScreensightChannelInput!, $condition: ModelScreensightChannelConditionInput) {
    updateChannel(input: $input, condition: $condition) {
      id
      customerId
      channelType
      name
      categoryPosition
      readHorizon
      activity {
        new
        latestAt
      }
      createdAt
      updatedAt
    }
  }
`
export const createVideo = /* GraphQL */ `
  mutation CreateVideo($input: CreateScreensightVideoInput!, $condition: ModelScreensightVideoConditionInput) {
    createVideo(input: $input, condition: $condition) {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          timezone
        }
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
      }
      accessType
      accessId
      password
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
            }
            accessType
            accessId
            password
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            commentsCount
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      updatedAt
    }
  }
`
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo($input: UpdateScreensightVideoInput!, $condition: ModelScreensightVideoConditionInput) {
    updateVideo(input: $input, condition: $condition) {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          timezone
        }
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
      }
      accessType
      accessId
      password
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
            }
            accessType
            accessId
            password
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            commentsCount
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      updatedAt
    }
  }
`
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo($input: DeleteScreensightVideoInput!, $condition: ModelScreensightVideoConditionInput) {
    deleteVideo(input: $input, condition: $condition) {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          timezone
        }
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
      }
      accessType
      accessId
      password
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                timezone
              }
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
            }
            accessType
            accessId
            password
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                videoTimestamp
                resolved
                replies {
                  nextToken
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            commentsCount
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      updatedAt
    }
  }
`
export const createVideoComment = /* GraphQL */ `
  mutation CreateVideoComment(
    $input: CreateScreensightVideoCommentInput!
    $condition: ModelScreensightVideoCommentConditionInput
  ) {
    createVideoComment(input: $input, condition: $condition) {
      id
      customerId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          timezone
        }
        createdAt
        updatedAt
      }
      videoId
      channelId
      parentId
      content
      videoTimestamp
      resolved
      replies {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      replyCount
      parentCreatedAt
      createdAt
      updatedAt
    }
  }
`
export const updateVideoComment = /* GraphQL */ `
  mutation UpdateVideoComment(
    $input: UpdateScreensightVideoCommentInput!
    $condition: ModelScreensightVideoCommentConditionInput
  ) {
    updateVideoComment(input: $input, condition: $condition) {
      id
      customerId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          timezone
        }
        createdAt
        updatedAt
      }
      videoId
      channelId
      parentId
      content
      videoTimestamp
      resolved
      replies {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      replyCount
      parentCreatedAt
      createdAt
      updatedAt
    }
  }
`
export const deleteVideoComment = /* GraphQL */ `
  mutation DeleteVideoComment(
    $input: DeleteScreensightVideoCommentInput!
    $condition: ModelScreensightVideoCommentConditionInput
  ) {
    deleteVideoComment(input: $input, condition: $condition) {
      id
      customerId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          timezone
        }
        createdAt
        updatedAt
      }
      videoId
      channelId
      parentId
      content
      videoTimestamp
      resolved
      replies {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              timezone
            }
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  timezone
                }
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      replyCount
      parentCreatedAt
      createdAt
      updatedAt
    }
  }
`

export const createPost = /* GraphQL */ `
  mutation CreatePost($input: CreateScreensightPostInput!, $condition: ModelScreensightPostConditionInput) {
    createPost(input: $input, condition: $condition) ${postFields}
  }
`

export const createEmailMessage = /* GraphQL */ `
  mutation CreateEmailMessage(
    $input: CreateScreensightVideoEmailMessageInput!
    $condition: ModelScreensightVideoEmailMessageConditionInput
  ) {
    createEmailMessage(input: $input, condition: $condition) {
      id
      videoId
      customerId
      channelId
      to
      note
      emailStatus {
        email
        status
        reject_reason
        _id
      }
      subject
      contacts {
        email
        contactId
      }
      createdAt
      updatedAt
    }
  }
`
