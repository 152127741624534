import { Box } from '@mui/material'
import * as React from 'react'

type RectangleProps = {
  color?: string
}

const Rectangle = ({ color = '#7A7A86' }: RectangleProps) => {
  return (
    <Box style={{ fontSize: '11px' }}>
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.8" y="0.8" width="8.4" height="8.4" rx="2.47869" stroke={color} strokeWidth="1.6" />
      </svg>
    </Box>
  )
}

export default Rectangle
