import { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import { screensightReaction } from '../api/types'
import gql from 'graphql-tag'

interface CacheReactions {
  __typename: 'screensightPost'
  reactions: {
    items: screensightReaction[]
  }
}
const useReactionsList = (postId: string) => {
  const auth = useContext(AuthContext)
  const client = auth.isAuthenticated ? CognitoApolloClient : IamApolloClient
  /*
   * This state is used to disable reactions actions (add, delete) when we are waiting for response from server
   * It prevents errors in cache
   */
  const [areReactionsActionsDisabled, setReactionsActionsDisabled] = useState<boolean>(false)

  const disableReactionsActions = () => setReactionsActionsDisabled(true)
  const enableReactionsActions = () => setReactionsActionsDisabled(false)

  const cacheReactions: CacheReactions | null = client.cache.readFragment({
    id: `screensightPost:${postId}`,
    fragment: gql`
      fragment PostReactions on screensightPost {
        reactions {
          items {
            unicode
            createdAt
            customerId
            videoTimestamp
          }
        }
      }
    `,
  })

  if (!cacheReactions) return null

  // sort reactions by createdAt and then merge reactions into groups by unicode
  return {
    reactionsList: ([...cacheReactions?.reactions?.items] || [])
      .map((item) => Object.assign({}, item, { unicode: item.unicode.toLowerCase() }))
      .sort((x: any, y: any) => (x.createdAt < y.createdAt ? -1 : 1))
      .reduce((acc: any, cur: any) => {
        const index = acc.findIndex((item: any) => item.unicode === cur.unicode)
        if (index === -1) {
          acc.push({
            ...cur,
            customersId: [cur.customerId],
            reactionAmount: 1,
          })
        } else {
          acc[index].customersId.push(cur.customerId)
          acc[index].reactionAmount += 1
        }
        return acc
      }, []),
    areReactionsActionsDisabled,
    disableReactionsActions,
    enableReactionsActions,
  }
}
export default useReactionsList
