import * as React from 'react'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import '../../ui/components/styles.scss'

interface IModalLibrarySearcher {
  searchPhrase: string
  handleSearchPhraseChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  clearSearchPhrase: () => void
}

const ModalLibrarySearcher = (props: IModalLibrarySearcher) => {
  const { t: g } = useTranslation('general')

  return (
    <Box className="modal-library--searcher">
      <TextField
        placeholder={g('common.searchInputPlaceholder')}
        variant="outlined"
        value={props.searchPhrase}
        onChange={props.handleSearchPhraseChange}
        style={{ width: `100%` }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: '#7A7A86' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{ width: '16px', height: '16px', display: props.searchPhrase ? 'inline-flex' : 'none' }}
                onClick={props.clearSearchPhrase}
              >
                <CloseIcon style={{ color: '#7A7A86' }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}

export default ModalLibrarySearcher
