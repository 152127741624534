import useAmplifySubscription from '../../../../../clients/useAmplifySubscription'
import { onPostsSeen, OnPostsSeenSubscription } from './onPostSeen.subscription'
import { OnPostsSeenSubscriptionVariables } from '../../../../../../API'
import { useLocation, useParams } from 'react-router'
import { useContext } from 'react'
import { AuthContext } from '../../../../../providers/AuthStatusProvider'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'

const useOnPostSeenSubscription = () => {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = location.pathname.includes('/app/chat')
    ? channelParamsId
    : location.pathname === '/app/support'
    ? auth.user?.supportChannelId
    : null

  const { data: onPostSeenSubscriptionData } = useAmplifySubscription<
    OnPostsSeenSubscription,
    OnPostsSeenSubscriptionVariables
  >(
    onPostsSeen,
    {
      variables: {
        channelId: channelId || '',
      },
      skip: !auth.isAuthenticated || !channelId || channelId === 'new',
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    },
    'OnPostsSeenSubscription',
  )

  return { data: onPostSeenSubscriptionData }
}

export default useOnPostSeenSubscription
