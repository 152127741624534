import { useContext, useMemo, useEffect } from 'react'
import { AuthContext } from '../../../../../providers/AuthStatusProvider'
import useAmplifySubscription from '../../../../../clients/useAmplifySubscription'
import { OnPostEventSubscription, OnPostEventSubscriptionVariables } from '../../../../../../API'
import { onPostEvent } from '../../../../../api/graphql/subscriptions'
import { useDispatch } from 'react-redux'
import {
  setonPostEventSubscriptionData,
  setVideosLimitReached,
} from '../../../../../../pages/Chat/common/redux/store/chat-actions'

const useOnPostEventSubscription = () => {
  const auth = useContext(AuthContext)
  const dispatch = useDispatch()
  const customerIdToUse = useMemo(
    () => ({
      customerId: auth.user?.id
        ? auth.user?.id
        : auth.user?.id || sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId') || '',
    }),
    [auth.user?.id],
  )

  const { data } = useAmplifySubscription<OnPostEventSubscription, OnPostEventSubscriptionVariables>(
    onPostEvent,
    {
      variables: customerIdToUse,
    },
    'usePostsList',
  )

  useEffect(() => {
    dispatch(setonPostEventSubscriptionData(data?.data))
    if (typeof data?.data?.onPostEvent?.videosLimitReached === 'boolean') {
      dispatch(setVideosLimitReached(!!data?.data?.onPostEvent?.videosLimitReached))
    }
  }, [data])
}

export default useOnPostEventSubscription
