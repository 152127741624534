export function escapeBtoaString(str: string) {
  let result = ''

  str = encodeURIComponent(str)

  for (let i = 0; i < str.length; i++)
    if (str[i] === '%') {
      result += String.fromCharCode(parseInt(str.substring(i + 1, i + 3), 16))
      i += 2
    } else result += str[i]

  return result
}
