import * as React from 'react'
import { ClickAwayListener } from '@mui/base'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import Picker from 'emoji-picker-react'
import Popper from '@mui/material/Popper'
import { useTranslation } from 'react-i18next'
import useEmojiCategories from '../ChatPlayer/common/hooks/useEmojiCategories'

type Props = {
  open: boolean
  anchorEl: any
  onClose: () => void
  onEmojiClick: any
}
export const AllReactionsPicker = (props: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const categories = useEmojiCategories()
  const { t: g } = useTranslation('general')

  return (
    <Popper
      className={'kokokok'}
      open={props.open}
      anchorEl={props.anchorEl}
      placement="bottom"
      disablePortal={false}
      style={{ zIndex: 10 }}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      <ClickAwayListener
        onClickAway={() => {
          props.onClose()
        }}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <Box sx={{ zIndex: 110, position: 'relative' }}>
          <Picker
            previewConfig={{ showPreview: false }}
            searchPlaceHolder={g('common.searchInputPlaceholder')}
            onEmojiClick={props.onEmojiClick}
            autoFocusSearch={false}
            width={`${isMobile ? '300px' : '349px'}`}
            height={`${isMobile ? '300px' : '380px'}`}
            categories={categories}
          />
        </Box>
      </ClickAwayListener>
    </Popper>
  )
}

export default AllReactionsPicker
