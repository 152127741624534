import React from 'react'
import { Box, Typography } from '@mui/material'
import { SignUp } from '../../../common/config'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import { useHistory } from 'react-router-dom'

const Content = () => {
  const { t } = useTranslation('auth')
  const { t: g } = useTranslation('general')
  const history = useHistory()

  return (
    <Box display="flex" flexDirection="column" padding={'28px 40px 40px 40px'}>
      <Typography variant="h2" style={{ fontSize: '28px', marginBottom: '20px', lineHeight: '35px' }}>
        {t('signUp.userAside.heading')}
      </Typography>
      <SignUp.Shared.UserForm />
      <Box
        display="flex"
        justifyContent="center"
        paddingTop={'20px'}
        style={{ margin: '30px 0', borderTop: '1px solid rgba(22, 109, 252, 0.2)' }}
      >
        <Typography variant="body2">{t('signUp.userAside.alreadyMember')}</Typography>
        <Typography
          variant="body2"
          style={{
            fontWeight: 600,
            cursor: 'pointer',
            marginLeft: '4px',
            color: theme.palette.primary.main,
          }}
          onClick={() => history.push('/login')}
        >
          {g('common.signInBtn')}
        </Typography>
      </Box>
    </Box>
  )
}

export default Content
