import React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Verify } from '../../../common/config'
import { useTranslation } from 'react-i18next'
import IconScreensightLogo from '../../../../../../../common/icons/ScreensightLogo'

const Content = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(`(max-width:1024px)`)
  const { t } = useTranslation('auth')

  return (
    <>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
      >
        <Box paddingLeft={isMobile ? '0' : '33px'} paddingTop={isMobile ? '16px' : '33px'} display="flex">
          <IconScreensightLogo width="175px" height="25px" />
        </Box>
      </Box>
      <Box
        margin={isTablet ? '100px auto 0 auto' : '30px auto 0 auto'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="80%"
        maxWidth="328px"
      >
        <Typography variant="h2" style={{ marginBottom: '15px', fontSize: '30px', textAlign: 'center' }}>
          {t('verify.userAside.heading')}
        </Typography>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginBottom: '40px', fontSize: '16px', fontWeight: 400 }}
          align="center"
        >
          {t('verify.userAside.subheading')}
        </Typography>
        <Verify.Shared.UserForm />
      </Box>
    </>
  )
}

export default Content
