import * as React from 'react'
import { Box, IconButton, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import DownloadIcon from '../../../../../../../../common/icons/DownloadIcon'
import useDownloadAttachment from '../../../common/hooks/posts/useDownloadAttachment'
import { FilePost as IFilePost } from '../../../../../../../../API'
import AttachmentIcon from '../../../../../../../../common/icons/AttachmentIcon'

interface IAttachmentPostProps {
  file?: IFilePost | null
  owner: boolean
}
const AttachmentPost = (props: IAttachmentPostProps) => {
  const theme = useTheme()
  const { downloadFile } = useDownloadAttachment(props.file?.fileObject)
  return (
    <Box maxWidth="calc(100% - 60px)" minWidth="110px">
      <Box
        bgcolor={props.owner ? 'primary.main' : 'rgba(188, 188, 195, 0.15)'}
        padding="10px"
        height="60px"
        marginTop="6px"
        display="flex"
        borderRadius="15px"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderTopRightRadius: props.owner ? '0' : '15px', borderTopLeftRadius: props.owner ? '15px' : '0' }}
      >
        <Box
          bgcolor="white"
          height="40px"
          width="40px"
          borderRadius="6px"
          marginRight="5px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <AttachmentIcon color={props.owner ? theme.palette.primary.main : theme.palette.secondary.main} />
        </Box>
        <Box width="calc(100%  - 90px)">
          <Typography
            variant="body1"
            sx={{
              marginLeft: '10px',
              fontSize: '15px',
              color: props.owner ? theme.palette.background.default : theme.palette.text.primary,
              maxWidth: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              fontWeight: 500,
              lineHeight: '15px',
            }}
          >
            {props.file?.fileName}
          </Typography>
        </Box>
        <IconButton
          sx={{
            background: 'transparent',
            boxShadow: 'none',
            marginLeft: '5px',
          }}
          onClick={downloadFile}
        >
          <DownloadIcon color={props.owner ? theme.palette.background.default : theme.palette.secondary.main} />
        </IconButton>
      </Box>
    </Box>
  )
}

export default AttachmentPost
