import React from 'react'
import { Box, Typography } from '@mui/material'
import { SignUp } from '../../../common/config'
import { useTranslation } from 'react-i18next'
import IconScreensightLogo from '../../../../../../../common/icons/ScreensightLogo'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import { useHistory } from 'react-router-dom'
import { useAuthSignUpAnalytics } from '../../../common/providers/useAuthSignUpAnalytics'

const UserAside = () => {
  const { t } = useTranslation('auth')
  const { t: g } = useTranslation('general')
  const history = useHistory()
  const { sendAnalyticsSignupEvent } = useAuthSignUpAnalytics()

  return (
    <Box width="50%" display="flex" alignItems="center" paddingLeft="40px">
      <Box width="100%" display="flex" justifyContent="center">
        <Box maxWidth={'424px'}>
          <IconScreensightLogo width="175px" height="25px" />
          <Typography variant="h2" style={{ fontSize: '28px', margin: '28px 0 20px 0', lineHeight: '35px' }}>
            {t('signUp.userAside.heading')}
          </Typography>
          <SignUp.Shared.UserForm />
          <Box display="flex" justifyContent="center" style={{ margin: '15px 0' }}>
            <Typography variant="body2">{t('signUp.userAside.alreadyMember')}</Typography>
            <Typography
              variant="body2"
              style={{
                fontWeight: 600,
                cursor: 'pointer',
                marginLeft: '4px',
                color: theme.palette.primary.main,
              }}
              onClick={() => {
				  sendAnalyticsSignupEvent('page_signup.section_landing', 'login')
				  history.push('/login')
			  }}
            >
              {g('common.signInBtn')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default UserAside
