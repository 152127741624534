import * as React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../common/providers/MuiThemeProvider'
import MailIcon from '../../../common/icons/MailIcon'
import Button from '../Button'

interface ISendByEmailTextButtonProps {
  showSendByEmailModal: () => void
  disabled?: boolean
}

const SendByEmailTextButton = (props: ISendByEmailTextButtonProps) => {
  const { t } = useTranslation('library')

  return (
    <Button
      variant="contained"
      color="primary"
      style={{ height: '46px', marginLeft: '15px' }}
      onClick={props.showSendByEmailModal}
      disabled={props?.disabled}
    >
      <MailIcon color={props?.disabled ? 'rgba(0, 0, 0, 0.26)' : theme.palette.background.default} />
      <Typography style={{ marginLeft: '8px', fontWeight: 500 }}>{t('videoItem.buttons.email')}</Typography>
    </Button>
  )
}

export default SendByEmailTextButton
