import React from 'react'
import Card from '../../../../ui/components/Card'
import { Box, Typography, useMediaQuery } from '@mui/material'
import Button from '../../../../../../components/_refactored/Button'
import { PaymentMethod } from '../../../../../../API'
import { useHistory } from 'react-router-dom'
import useBillingRedirect from '../../../billingPlans/hooks/useBillingRedirect'
import { useTranslation } from 'react-i18next'
import KeyFeature from '../components/KeyFeature'
import { theme } from '../../../../../../common/providers/MuiThemeProvider'

type CurrentPlanProps = {
  pricePlanName: string
  currentPrice: number
  paymentMethod?: PaymentMethod | null
}

const CurrentPlan = ({ pricePlanName, currentPrice, paymentMethod }: CurrentPlanProps) => {
  const { redirectToAttachCreditCard, loading } = useBillingRedirect()
  const history = useHistory()
  const { t } = useTranslation('settings')
  const currentPlan = pricePlanName?.substr(12)?.toLowerCase()
  const isNarrowScreen = useMediaQuery(`(max-width:1169px)`)
  return (
    <Card>
      <Box display={isNarrowScreen ? 'block' : 'flex'} alignItems="center" justifyContent="space-between">
        <Box paddingBottom={isNarrowScreen ? '10px' : 0}>
          <Typography variant="h6" style={{ fontSize: '12px', marginBottom: '10px' }}>
            {t('billing.common.currentPlan')}
          </Typography>
          <Typography variant="h6" style={{ fontSize: '20px', fontWeight: 600 }}>
            ${currentPrice} {pricePlanName}
          </Typography>
        </Box>
        {paymentMethod ? (
          <Button
            loading={loading}
            disabled={loading}
            variant="contained"
            color="primary"
            style={{ padding: '25px 20px', lineHeight: 1.3 }}
            onClick={() => history.push('/app/settings/billing/plans')}
          >
            {t('billing.main.changePlanBtn')}
          </Button>
        ) : (
          <Button
            loading={loading}
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={() => redirectToAttachCreditCard()}
          >
            {t('billing.main.upgradeToProBtn')}
          </Button>
        )}
      </Box>
      <Typography
        variant="h6"
        color="textSecondary"
        style={{ fontSize: '15px', marginTop: '20px', marginBottom: '15px' }}
      >
        {/* @ts-ignore*/}
        {t(`billing.explorePlans.${currentPlan}Plan.description`)}
      </Typography>
      <Typography variant="h6" style={{ fontSize: '15px', fontWeight: 600, marginBottom: '10px' }}>
        {/* @ts-ignore*/}
        {t(`billing.explorePlans.${currentPlan}Plan.featuresTitle`)}
      </Typography>
      {/* @ts-ignore*/}
      <KeyFeature content={t(`billing.explorePlans.${currentPlan}Plan.feature1`)} />
      {/* @ts-ignore*/}
      <KeyFeature content={t(`billing.explorePlans.${currentPlan}Plan.feature2`)} />
      {/* @ts-ignore*/}
      <KeyFeature content={t(`billing.explorePlans.${currentPlan}Plan.feature3`)} />
      {/* @ts-ignore*/}
      <KeyFeature content={t(`billing.explorePlans.${currentPlan}Plan.feature4`)} />
    </Card>
  )
}

export default CurrentPlan
