import React from 'react'
import { Box } from '@mui/material'

const Card = (props: {
  children: React.ReactNode
  highlighted?: string | boolean
  fullHeight?: boolean
  className?: string
  sx?: React.CSSProperties
}) => {
  return (
    <Box
      borderRadius="10px"
      border="1px solid rgba(188, 188, 195, 0.6)"
      padding="12px"
      position="relative"
      height={props.fullHeight ? '100%' : 'auto'}
      style={{
        borderTopLeftRadius: props.highlighted ? '0' : '10px',
        borderTopRightRadius: props.highlighted ? '0' : '10px',
      }}
      className={props.className}
      sx={props?.sx}
    >
      {props.highlighted && (
        <Box
          position="absolute"
          left="0"
          top="0"
          bgcolor="primary.main"
          width="100%"
          minHeight="30px"
          color="white"
          display="flex"
          alignItems="center"
          fontWeight={500}
          justifyContent="center"
          style={{
            transform: 'translateY(-100%)',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
        >
          {typeof props.highlighted === 'string' && props.highlighted}
        </Box>
      )}
      {props.children}
    </Box>
  )
}

export default Card
