import React, { FC } from 'react'
import { ForgotPassword } from './common/config'
import PatentNote from '../../../../common/providers/ClientAuthProvider/PatentNote'
import PageTitle from '../../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

const ForgotPasswordPage: FC = () => {
  const { t: g } = useTranslation('general')

  return (
    <ForgotPassword.Layout>
      <PageTitle name={g('pageName.forgotPassword')} />
      <ForgotPassword.Header />
      <ForgotPassword.Content />
      <PatentNote styles={{ padding: '20px 12px' }} />
    </ForgotPassword.Layout>
  )
}

export default ForgotPasswordPage
