import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { IActionBlock } from '../../../common/types'

const ActionBlock = (props: IActionBlock) => {
  return (
    <Grid item>
      <Box
        borderRadius="10px"
        width="140px"
        height="90px"
        bgcolor="background.paper"
        onClick={props.onClick}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ cursor: 'pointer' }}
      >
        <>
          {props.icon}
          <Typography
            variant="body1"
            color="textSecondary"
            align={'center'}
            style={{ marginTop: '9px', fontSize: '15px' }}
          >
            {props.text}
          </Typography>
        </>
      </Box>
    </Grid>
  )
}

export default ActionBlock
