import { useState } from 'react'

export const useValidatePassword = () => {
  const [validationErrors, setValidationErrors] = useState({
    lengthError: false,
    uppercaseError: false,
    numberError: false,
  })

  const validatePassword = (value: string) => {
    const errors = { ...validationErrors }
    errors.lengthError = value.length < 8
    errors.uppercaseError = !/[A-Z]/.test(value)
    errors.numberError = !/\d/.test(value)

    setValidationErrors(errors)
    return !Object.values(errors).includes(true)
  }

  return { validationErrors, validatePassword }
}
