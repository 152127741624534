import * as React from 'react'
import { screensightVideoComment } from '../../../../../../../../API'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import videojs from 'video.js'
import Linkify from 'react-linkify'
import { sanitazeHtml } from '../../../../../../../../common/utils/sanitazeHtml'
import TrashIcon from '../../../../../../../../common/icons/TrashIcon'
import { useTranslation } from 'react-i18next'
import '../../../common/styles/posts.style.scss'

interface IRemovedVideoCommentPostProps {
  owner: boolean
  comment: screensightVideoComment
}
const RemovedVideoCommentPost = (props: IRemovedVideoCommentPostProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation('chat')

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={props.owner ? 'flex-end' : 'flex-start'}
      maxWidth="calc(100% - 60px)"
    >
      <Box
        className={props.owner ? 'chat__post-owner-text' : 'chat__post-partner-text'}
        bgcolor={props.owner ? 'primary.main' : 'rgba(188, 188, 195, 0.15)'}
        padding="15px"
        marginTop="10px"
        borderRadius="24px"
        width="100%"
        style={{ borderTopRightRadius: props.owner ? '0' : '24px', borderTopLeftRadius: props.owner ? '24px' : '0' }}
      >
        <Box display="flex" alignItems="center">
          <Box
            className="removed-video-comment-post__icon"
            width="50px"
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="3.5px"
            marginRight="10px"
          >
            <TrashIcon color={theme.palette.background.default} />
          </Box>
          <Typography variant="body2">{t('chatBody.videoDeletedCommentPost')}</Typography>
        </Box>
        <Box display={isMobile ? 'block' : 'flex'} alignItems="flex-start" marginTop={isMobile ? '5px' : '15px'}>
          <Typography
            variant="body1"
            style={{
              marginRight: '10px',
              marginBottom: `${isMobile ? '5px' : '0'}`,
              textDecoration: 'underline',
              fontSize: '15px',
              lineHeight: '20px',
              color: props.owner ? theme.palette.background.default : theme.palette.text.primary,
            }}
          >
            {videojs.formatTime(props.comment.videoTimestamp || 0, 600)}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: '15px',
              lineHeight: '20px',
              color: props.owner ? theme.palette.background.default : theme.palette.text.primary,
            }}
          >
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {sanitazeHtml(props.comment.content || '')}
            </Linkify>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default RemovedVideoCommentPost
