import React, { PropsWithChildren } from 'react'
import useSettingsLayoutHook from './hooks/useSettingsLayout.hook'

import SettingsLayoutView from './view/SettingsLayout.view'

export type LayoutProps = PropsWithChildren<any>

export default (props: LayoutProps) => {
  const state = useSettingsLayoutHook(props)
  return <SettingsLayoutView {...state} {...props} />
}
