import React from 'react'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import Button from '../../../../components/_refactored/Button'
import { useTranslation } from 'react-i18next'
import useGetScreensightCustomer from '../../../../common/api/hooks/useGetScreensightCustomer'
import { getUsername } from '../../../../common/utils/getUsername'

interface ICalendlyBox {
  customerId: string | undefined
}

const CalendlyBox = (props: ICalendlyBox) => {
  const cacheCustomer = useGetScreensightCustomer(props.customerId, '{firstName, lastName, email, calendlyUrl}')
  const { t } = useTranslation('library')
  const theme = useTheme()
  const name = getUsername(cacheCustomer?.firstName, cacheCustomer?.lastName, cacheCustomer?.email)
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  if (!cacheCustomer?.calendlyUrl) return <></>

  return (
    <Grid item xs={12} lg={2} justifyContent="center">
      <Box style={{ padding: '12px', minWidth: '300px', maxWidth: '350px', margin: '0 auto' }}>
        <Box
          bgcolor="#F8F9FB"
          borderRadius="10px"
          padding="20px"
          marginTop={isLargeScreen ? '63px' : '0px'}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            variant="body1"
            style={{ fontSize: '18px', fontWeight: 500, marginBottom: '20px', textAlign: 'center' }}
          >
            {t('videoItem.calendlyBox.bookingTitle')} {name}
          </Typography>
          <a href={cacheCustomer?.calendlyUrl || ''} target="_blank" rel="noreferrer">
            <Button color="primary" variant="contained" style={{ height: '50px', margin: '0 auto' }}>
              {t('videoItem.calendlyBox.scheduleMeetingBtn')}
            </Button>
          </a>
        </Box>
      </Box>
    </Grid>
  )
}

export default CalendlyBox
