import React from 'react'
import { screensightCustomer } from '../../../../../API'
import PartnerSidebarContent from './ui/partials/PartnerSidebarContent'

export interface IPartnerSidebar {
  partner: screensightCustomer | null | undefined
}
const PartnerSidebar = (props: IPartnerSidebar) => {
  return <PartnerSidebarContent {...props} />
}

export default PartnerSidebar
