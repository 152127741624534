import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useContext, useEffect, useState } from 'react'
import ToastContext from '../../../../../../common/providers/ToastProvider/ToastContext'
import { AuthContext } from '../../../../../../common/providers/AuthStatusProvider'
import { IAuthForm } from '../../../../common/types/Auth'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { useTranslation } from 'react-i18next'
import * as queryString from 'query-string'
import { useAuthSignUpAnalytics } from '../providers/useAuthSignUpAnalytics'

const useSignUp = () => {
  const history = useHistory()
  const toastContext = useContext(ToastContext)
  const auth = useContext(AuthContext)
  const { t } = useTranslation('auth')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const sendDataToGTM = useGTMDispatch()
  const [isPasswordHintVisible, setIsPasswordHintVisible] = useState(false)
  const [hubspotFormValues, setHubspotFormValues] = useState({
    test_param: '',
    utm_medium: '',
    utm_source: '',
    utm_term: '',
    utm_campaign: '',
    utm_content: '',
    gclid: '',
    _gl: '',
    fbclid: '',
  })

  const {
    handleInputChangeForEmailAnalytics,
    handleInputChangeForPasswordAnalytics,
    setAnalyticsEventError,
    sendAnalyticsSignupEvent,
  } = useAuthSignUpAnalytics()

  useEffect(() => {
    const params = window.location.href.split('?')?.[1]
    const queryParams = params ? queryString.parse(params) : null
    if (!queryParams) return
    setHubspotFormValues((prevState) => ({
      ...prevState,
      ...queryParams,
    }))
  }, [])

  const form = useForm<IAuthForm>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      terms: false,
    },
  })

  const handlePasswordHintsVisibility = (isVisible: boolean) => {
    setIsPasswordHintVisible(isVisible)
  }

  const translateErrorMessage = (message: any) => {
    switch (message) {
      case 'Password did not conform with policy: Password must have uppercase characters':
        return t('signUp.userAside.upperCaseError')
      case 'An account with the given email already exists.':
        return t('signUp.userAside.accountAlreadyExists')
      case 'Password did not conform with policy: Password must have numeric characters':
        return t('signUp.userAside.numberError')
      case 'Password did not conform with policy: Password must have lowercase characters':
        return t('signUp.userAside.lowerCaseError')
      default:
        return message
    }
  }

  useEffect(() => {
    if (!errorMessage) return
    toastContext.open({ text: translateErrorMessage(errorMessage), time: 5000 })
    setErrorMessage(undefined)
  }, [errorMessage])

  const onSubmit = form.handleSubmit(async (values: IAuthForm) => {
    try {
      setLoading(true)
      await auth.register(values.email, values.password)
      sendDataToGTM({ event: 'SceensightSignup' })
      await sendAnalyticsSignupEvent('page_signup.section_landing', 'next')
      history.push({ pathname: '/verify', state: { email: values.email, password: values.password } })
    } catch (err: any) {
      console.error('err', err.message)
      if (err.message.includes('Member must satisfy regular expression pattern')) {
        setErrorMessage(t('signUp.userAside.spaceInPassword'))
      } else {
        setErrorMessage(err.message)
      }
      setAnalyticsEventError(err.message)
    } finally {
      setLoading(false)
    }
  })

  const switchPasswordVisible = () => {
    setPasswordVisible((prevState) => !prevState)
  }

  return {
    loading,
    errorMessage,
    form,
    onSubmit,
    switchPasswordVisible,
    isPasswordVisible,
    hubspotFormValues,
    handlePasswordHintsVisibility,
    isPasswordHintVisible,
    handleInputChangeForEmailAnalytics,
    handleInputChangeForPasswordAnalytics,
  }
}

export default useSignUp
