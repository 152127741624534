import * as React from 'react'

const ExpandQuillIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5026 3.55623L12.0588 9L11 7.94119L16.4438 2.4974H11.7643V1H19V8.23573H17.5026V3.55623Z"
        fill="#7A7A86"
      />
      <path
        d="M2.49741 16.4438L7.94118 11L9 12.0588L3.55622 17.5026H8.23571V19H1V11.7643H2.49741V16.4438Z"
        fill="#7A7A86"
      />
    </svg>
  )
}

export default ExpandQuillIcon
