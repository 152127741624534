import React, { useState } from 'react'

export interface ToastProvider {
  text: string
  time?: number
  type?: 'ERROR' | 'INFO'
  styles?: React.CSSProperties
}

const toastDefault: ToastProvider = {
  text: 'Error!',
  time: 3000,
  type: 'ERROR',
}

const useToastProvider = () => {
  const [toastState, setToastState] = useState(false)
  const [text, setText] = useState<ToastProvider['text']>('')
  const [type, setType] = useState<ToastProvider['type']>('ERROR')
  const [styles, setStyles] = useState<ToastProvider['styles']>({})

  const open = (props: ToastProvider) => {
    const { text, time = toastDefault.time, type = toastDefault.type, styles } = props
    setText(text)
    setType(type)
    setStyles(styles)
    setToastState(true)
    setTimeout(() => setToastState(false), time)
  }

  const close = () => {
    setToastState(false)
  }

  return { toastState, open, close, text, type, styles }
}

export type UseToastProvider = ReturnType<typeof useToastProvider>
export default useToastProvider
