import type { FC, ReactNode } from 'react'
import React, { createContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Analytics, API, Auth } from 'aws-amplify'
import { CognitoUser as AwsCognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import { getCustomer } from '../../../graphql/queries'
import * as Sentry from '@sentry/react'
import { CreateChannelMutation, GetApplicationConfigurationQuery } from '../../../API'
import { createChannel } from '../../../graphql/mutations'
import { getUsername } from '../../utils/getUsername'
import i18next from 'i18next'
import moment from 'moment'

export interface ICognitoUser extends AwsCognitoUser {
  [key: string]: any
}

export interface AuthStatusContext {
  id?: string
  pending?: boolean
  email?: string | null
  firstName?: string | null
  lastName?: string | null
  updateAuthFields?: any
  brandLogo?: {
    bucket?: string
    key?: string
  }
  profilePhoto?: {
    bucket?: string
    key?: string
  }
  calendlyUrl?: string
  settings?: {
    notifyEmailComment: boolean
    notifyEmailWatched: boolean
    notifyUnseenMessages: boolean
    enableSplashScreen: boolean
    timezone: string
    channelRecentActivityDays?: number | null
    lastChannel?: string | null
    pushNotifications: {
      comment: boolean
      reply: boolean
      text: boolean
      video: boolean
    }
    termsAndConditions?: {
      version: string
      accepted: boolean
      acceptedAt?: string | null
    }
  }
  profilePhotoUrl?: string
  supportChannelId?: string
}

interface State {
  isInitialized: boolean
  isAuthenticated: boolean
  applicationConfiguration?: GetApplicationConfigurationQuery
  user: AuthStatusContext | null
}

export interface AuthContextValue extends State {
  method: 'Amplify'
  login: (email: string, password: string) => Promise<void | ICognitoUser>
  logout: () => Promise<void>
  register: (email: string, password: string) => Promise<void>
  verifyCode: (username: string, code: string) => Promise<void>
  resendCode: (username: string) => Promise<void>
  passwordRecovery: (username: string) => Promise<void>
  passwordReset: (username: string, code: string, newPassword: string) => Promise<void>
  completeNewPassword: (cognitoUser: ICognitoUser, newPassword: string) => Promise<void>
  updateUser: (user: AuthStatusContext) => Promise<void>
  setApplicationConfiguration: (applicationConfiguration: GetApplicationConfigurationQuery) => void
}

interface AuthProviderProps {
  children: ReactNode
}

type InitializeAction = {
  type: 'INITIALIZE'
  payload: {
    isAuthenticated: boolean
    user: Partial<AuthStatusContext> | null
  }
}

type LoginAction = {
  type: 'LOGIN'
  payload: {
    user: AuthStatusContext
  }
}

type PendingAction = {
  type: 'PENDING'
}

type LogoutAction = {
  type: 'LOGOUT'
}

type RegisterAction = {
  type: 'REGISTER'
}

type VerifyCodeAction = {
  type: 'VERIFY_CODE'
}

type ResendCodeAction = {
  type: 'RESEND_CODE'
}
type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY'
}

type PasswordResetAction = {
  type: 'PASSWORD_RESET'
}
type CompleteNewPassword = {
  type: 'COMPLETE_NEW_PASSWORD'
}
type UpdateUserAction = {
  type: 'UPDATE_USER'
  payload: {
    user: AuthStatusContext | null
  }
}

type SetApplicationConfiguration = {
  type: 'SET_APPLICATION_CONFIGURATION'
  payload: {
    applicationConfiguration: GetApplicationConfigurationQuery
  }
}

type Action =
  | PendingAction
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction
  | CompleteNewPassword
  | UpdateUserAction
  | SetApplicationConfiguration

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  applicationConfiguration: undefined,
  user: { pending: true },
}

const handlers: Record<string, (state: State, action: Action) => State> = {
  // @ts-ignore
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    }
  },
  // @ts-ignore
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user,
    }
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  PENDING: (state: State): State => ({
    ...state,
    user: {
      ...state.user,
      pending: true,
    },
  }),
  REGISTER: (state: State): State => ({ ...state }),
  VERIFY_CODE: (state: State): State => ({ ...state }),
  RESEND_CODE: (state: State): State => ({ ...state }),
  PASSWORD_RECOVERY: (state: State): State => ({ ...state }),
  PASSWORD_RESET: (state: State): State => ({ ...state }),
  COMPLETE_NEW_PASSWORD: (state: State): State => ({ ...state }),
  // @ts-ignore
  UPDATE_USER: (state: State, action: UpdateUserAction): State => ({
    ...state,
    user: { ...state.user, ...action.payload.user },
  }),
  // @ts-ignore
  SET_APPLICATION_CONFIGURATION: (state: State, action: SetApplicationConfiguration): State => ({
    ...state,
    applicationConfiguration: action.payload.applicationConfiguration,
  }),
}

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  method: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  completeNewPassword: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  setApplicationConfiguration: () => Promise.resolve(),
})

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        dispatch({
          type: 'PENDING',
        })

        const getLanguage = () => localStorage.getItem('i18nextLng') || i18next.language
        localStorage.setItem('i18nextLng', getLanguage())
        moment.locale(getLanguage())

        const user = await Auth.currentAuthenticatedUser({ bypassCache: true })

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.attributes.sub,
              email: user.attributes.email,
              firstName: user.attributes.given_name,
              lastName: user.attributes.family_name,
              pending: true,
            },
          },
        })
      } catch (error: any) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: {
              pending: false,
            },
          },
        })
      }
    }

    initialize()
  }, [])

  useEffect(() => {
    const updatePinPointAnalytics = async () => {
      if (!state.user?.id) return

      const {
        // @ts-ignore
        data,
      } = await API.graphql({ query: getCustomer, variables: { id: state.user?.id } })

      try {
        await Analytics.updateEndpoint({ userId: state.user.id })
        Analytics.autoTrack('session', { enable: true })
      } catch (error) {
        console.warn('PinPoint update endpoint failed: ', error)
      }

      if (state.user?.id !== process.env.REACT_APP_SUPPORT_ID) {
        const session = await Auth.currentSession()
        const newChannelData = (await API.graphql({
          authToken: session.getIdToken().getJwtToken(),
          query: createChannel,
          variables: {
            input: {
              participants: [
                {
                  customerId: state.user?.id,
                  name: getUsername('', '', state.user?.email),
                },
                {
                  customerId: process.env.REACT_APP_SUPPORT_ID,
                  name: 'Support',
                },
              ],
            },
          },
        })) as { data: CreateChannelMutation }

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              ...state.user,
              ...data.getCustomer,
              supportChannelId: newChannelData?.data?.createChannel?.id,
              pending: false,
            },
          },
        })
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              ...state.user,
              ...data.getCustomer,
              pending: false,
            },
          },
        })
      }

      Sentry.setUser({ username: data.getCustomer.email, customerId: data.getCustomer.id })
    }
    updatePinPointAnalytics()
  }, [state?.user?.id])

  const login = async (email: string, password: string): Promise<void | ICognitoUser> => {
    const user = (await Auth.signIn(email, password)) as ICognitoUser

    const {
      // @ts-ignore
      data,
    } = await API.graphql({ query: getCustomer, variables: { id: user.attributes.sub } })

    if (user.attributes.sub !== process.env.REACT_APP_SUPPORT_ID) {
      const session = await Auth.currentSession()
      // @ts-ignore
      const newChannelData = await API.graphql({
        query: createChannel,
        authToken: session.getIdToken().getJwtToken(),
        variables: {
          input: {
            participants: [
              {
                customerId: user.attributes.sub,
                name: getUsername('', '', data.getCustomer.email),
              },
              {
                customerId: process.env.REACT_APP_SUPPORT_ID,
                name: 'Support',
              },
            ],
          },
        },
      })

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            ...state.user,
            ...data.getCustomer,
            // @ts-ignore
            supportChannelId: newChannelData?.data?.createChannel?.id,
            pending: false,
          },
        },
      })

      Sentry.setUser({ username: data.getCustomer.email, customerId: data.getCustomer.id })
    } else {
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            ...state.user,
            ...data.getCustomer,
            pending: false,
          },
        },
      })

      Sentry.setUser({ username: data.getCustomer.email, customerId: data.getCustomer.id })
    }
  }

  const completeNewPassword = async (cognitoObject: ICognitoUser, newPassword: string): Promise<void> => {
    const pool = new CognitoUserPool({
      UserPoolId: cognitoObject.pool.userPoolId,
      ClientId: cognitoObject.pool.clientId,
      endpoint: cognitoObject.client.endpoint,
      Storage: window.localStorage,
      AdvancedSecurityDataCollectionFlag: cognitoObject.advancedSecurityDataCollectionFlag,
    })
    const cognitoUser = new AwsCognitoUser({
      Username: cognitoObject.username,
      Pool: pool,
      Storage: window.localStorage,
    })

    // @ts-ignore
    cognitoUser.Session = cognitoObject.Session

    await Auth.completeNewPassword(cognitoUser, newPassword, cognitoObject.challengeParams)
  }

  const logout = async (): Promise<void> => {
    await Auth.signOut()
    dispatch({
      type: 'LOGOUT',
    })
  }

  const register = async (email: string, password: string): Promise<void> => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email },
    })
    dispatch({
      type: 'REGISTER',
    })
  }

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code)
    dispatch({
      type: 'VERIFY_CODE',
    })
  }

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username)
    dispatch({
      type: 'RESEND_CODE',
    })
  }

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username)
    dispatch({
      type: 'PASSWORD_RECOVERY',
    })
  }

  const passwordReset = async (username: string, code: string, newPassword: string): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword)
    dispatch({
      type: 'PASSWORD_RESET',
    })
  }

  const updateUser = async (user: AuthStatusContext) => {
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user: user,
      },
    })
  }

  const setApplicationConfiguration = async (applicationConfiguration: GetApplicationConfigurationQuery) => {
    dispatch({
      type: 'SET_APPLICATION_CONFIGURATION',
      payload: {
        applicationConfiguration: applicationConfiguration,
      },
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        completeNewPassword,
        updateUser,
        setApplicationConfiguration,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthProvider
