import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import GlobeLang from '../../../../common/icons/GlobeLang'
import LanguageItem from './LanguageItem'
import useLanguage from '../../hooks/useLanguage'
import { theme } from '../../../../common/providers/MuiThemeProvider'
import { Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
const LanguageMenu = () => {
  const state = useLanguage()
  const { t: g } = useTranslation('general')

  return (
    <div>
      <Tooltip title={g('menu.language')} placement="right">
        <Button
          id="demo-positioned-button"
          aria-controls={state.open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={state.open ? 'true' : undefined}
          onClick={state.handleClick}
          sx={{
            borderRadius: 0,
            ':hover': { bgcolor: 'background.paper' },
            color: theme.palette.text.secondary,
            fontWeight: 400,
            padding: 0,
          }}
        >
          <GlobeLang />
          <Typography component="span" sx={{ paddingLeft: '4.5px', fontSize: '13px' }}>
            {state.getLanguage()?.toUpperCase().slice(0, 2)}
          </Typography>
        </Button>
      </Tooltip>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={state.anchorEl}
        open={state.open}
        onClose={state.handleClose}
        componentsProps={{ backdrop: { style: { background: 'transparent' } } }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            transform: 'translateX(25%) translateY(15%)',
          },
        }}
      >
        {state.languages.map((language) => (
          <LanguageItem
            handleChange={state.handleChange}
            key={language.key}
            short={language.key}
            flag={language.flag}
          />
        ))}
      </Menu>
    </div>
  )
}
export default LanguageMenu
