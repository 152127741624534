import React from 'react'

const NewContactIcon = () => {
  return (
    <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="27.5" cy="27.5" r="27.5" fill="#F9FAFC" />
      <path
        d="M25 44.9998C25 44.9998 6.5 44.9998 4 44.9998C4 36.9998 18 35.9998 19 32.9998C20 29.9998 19.5 27.4998 19.5 27.4998C19.5 27.4998 18.7248 25.6744 18.5 25C18.3361 24.5083 17.8797 23.6704 17.6444 23.2525C17.5591 23.1011 17.382 23.0238 17.2102 22.9976C16.7971 22.9348 16.0304 22.591 15.5 20.9998C15.0096 19.5285 15.3626 18.7056 15.6732 18.3078C15.821 18.1186 15.8786 17.8284 15.7603 17.6196C15.1251 16.4984 13.6353 13.0015 16.5 8.49982C19.5153 3.76153 25.1282 3.84751 26.6251 3.9622C26.8653 3.98061 27.072 4.11976 27.1959 4.32634L28.3912 6.31855C28.4565 6.42726 28.5818 6.48393 28.7075 6.46773C29.3375 6.38658 31.2603 6.26028 32.5 7.5C34 9 35 11.9998 35 11.9998C35 11.9998 35.5 12.5002 35.5 14.5C35.5 15.9673 34.6925 17.1655 34.2625 17.6996C34.1239 17.8716 34.122 18.1319 34.2342 18.3222C34.4944 18.7629 34.8361 19.6556 34.5 21C34 22.9998 32.5 23.4998 32.5 23.4998L30.5 27.5V32"
        stroke="#2483F7"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M45.6667 54C45.6667 54 30.9841 54 29 54C29 47.9617 40.1111 47.2069 40.9048 44.9425C41.6984 42.6782 41.3016 40.7912 41.3016 40.7912C41.3016 40.7912 40.6863 39.4134 40.5079 38.9044C40.3845 38.5523 40.0521 37.9649 39.8589 37.6362C39.7732 37.4905 39.6003 37.4172 39.4345 37.3845C39.0977 37.3181 38.5275 37.0277 38.127 35.8851C37.7761 34.8841 37.9692 34.2807 38.1912 33.9515C38.3296 33.7464 38.3906 33.4394 38.27 33.2234C37.7402 32.2747 36.7382 29.7119 38.9206 26.4502C41.2667 22.9441 45.5942 22.9376 46.8827 23.0202C47.1187 23.0354 47.3269 23.1692 47.4531 23.3693L48.3354 24.7678C48.4012 24.8721 48.5236 24.926 48.6461 24.9121C49.1806 24.8513 50.6583 24.7819 51.619 25.6956C52.8095 26.8278 53.6032 29.092 53.6032 29.092C53.6032 29.092 54 29.4696 54 30.9791C54 32.0132 53.4413 32.8702 53.0894 33.3074C52.9459 33.4856 52.9433 33.7608 53.0522 33.962C53.2463 34.3205 53.4491 34.9617 53.2063 35.8852C52.8095 37.3947 51.619 37.772 51.619 37.772L50.0317 40.7913V44.1879"
        stroke="#2483F7"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default NewContactIcon
