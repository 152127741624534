import * as React from 'react'
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useTranslation } from 'react-i18next'
import { UseFormMethods } from 'react-hook-form/dist/types/form'
import { IVideoAccessModalHookForm } from '../../common/hooks/useVideoAccessModal.hook'

interface IPasswordInputProps {
  form: UseFormMethods<IVideoAccessModalHookForm>
  loading: boolean
  isPasswordShowed: boolean
  togglePasswordVisible: () => void
}

const PasswordInput = (props: IPasswordInputProps) => {
  const { t } = useTranslation('library')
  const { t: g } = useTranslation('general')

  return (
    <Box width="90%" marginTop="15px">
      <Controller
        name={'password'}
        control={props.form.control}
        rules={{
          required: g('common.requiredField'),
          validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
        }}
        render={({ value, onChange, onBlur }) => (
          <TextField
            id="password"
            type={'text'}
            value={value}
            onChange={onChange}
            disabled={props.loading}
            onBlur={onBlur}
            error={!!props.form.errors['password']}
            helperText={props.form.errors['password']?.message}
            variant="outlined"
            fullWidth
            className="test"
            InputProps={{
              style: {
                fontSize: '16px',
                fontFamily: props.isPasswordShowed ? '' : 'text-security-disc',
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="visibility"
                    style={{ boxShadow: 'none' }}
                    onClick={props.togglePasswordVisible}
                  >
                    {!props.isPasswordShowed ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  )
}

export default PasswordInput
