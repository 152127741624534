import * as React from 'react'

const CameraDisabled = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <path
        d="M10.6 20.9062C10.6 19.7091 11.6198 18.6 13.2 18.6H35.88C37.4602 18.6 38.48 19.7091 38.48 20.9062V38.0938C38.48 39.2909 37.4602 40.4 35.88 40.4H13.2C11.6198 40.4 10.6 39.2909 10.6 38.0938V20.9062ZM43.36 33.8305V25.1695L49.4 22.1692V36.8308L43.36 33.8305Z"
        stroke="#F73737"
        strokeWidth="3.2"
      />
      <rect x="3" y="13.4075" width="5.78341" height="60" transform="rotate(-49.6491 3 13.4075)" fill="white" />
      <rect x="4.78979" y="11.3007" width="3" height="60" transform="rotate(-49.6491 4.78979 11.3007)" fill="#F73737" />
    </svg>
  )
}
export default CameraDisabled
