import React from 'react'
import Modal from '../../../../components/_refactored/Modal'
import { Box, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material'
import Button from '../../../../components/_refactored/Button'
import styled from 'styled-components'
import OnBoardingName from '../components/OnBoardingName'
import OnBoardingPhoto from '../components/OnBoardingPhoto'
import OnBoardingCalendly from '../components/OnBoardingCalendly'
import OnBoardingExtension from '../components/OnBoardingExtension'
import { IOnBoardingView } from '../interfaces'
import { useTranslation } from 'react-i18next'

interface IStyledDot {
  active: boolean
  disabled: boolean
}

const StyledDot = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 50px;
  cursor: ${(props: IStyledDot) => (props.disabled || props.active ? 'default' : 'pointer')};
  background: ${(props: IStyledDot) => (props.active ? '#2483F7' : '#BCBCC3')};
`

const OnBoardingProfileInitStepsView = (props: IOnBoardingView) => {
  const theme = useTheme()
  const { t } = useTranslation('library')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Modal open={true} boxStyle={{ width: '840px' }}>
      <>
        <Box bgcolor="background.default">
          <Box height="300px">
            <Box display="flex" alignItems="center" width="100%" height="calc(100% - 40px)">
              {props.step === 0 && (
                <OnBoardingName
                  firstName={props.firstName}
                  lastName={props.lastName}
                  handleChangeFirstName={props.handleChangeFirstName}
                  handleChangeLastName={props.handleChangeLastName}
                  isMobile={isMobile}
                />
              )}
              {props.step === 1 && (
                <OnBoardingPhoto
                  dropzone={props.dropzone}
                  image={props.image}
                  cropperRef={props.cropperRef}
                  handleCloseCropperModal={props.handleCloseCropperModal}
                  handleImageCroppingSubmit={props.handleImageCroppingSubmit}
                  showRemoveIcon={props.showRemoveIcon}
                  hideRemoveIcon={props.hideRemoveIcon}
                  removePhotoIconVisible={props.removePhotoIconVisible}
                  clearUserPhoto={props.clearUserPhoto}
                  isMobile={isMobile}
                />
              )}
              {props.step === 2 && (
                <OnBoardingCalendly
                  calendlyUrl={props.calendlyUrl}
                  calendlyUrlError={props.calendlyUrlError}
                  handleChangeCalendlyUrl={props.handleChangeCalendlyUrl}
                  isMobile={isMobile}
                />
              )}
              {props.step === 3 && (
                <OnBoardingExtension
                  isMobile={isMobile}
                  submitUserDataAndRedirectToStore={props.submitUserDataAndRedirectToStore}
                />
              )}
            </Box>
          </Box>
          {!isMobile ? null : (
            <Box display="flex" justifyContent="space-between" marginBottom="16px">
              <Button
                variant="contained"
                color="primary"
                disabled={props.isNextButtonDisabled}
                onClick={() => {
                  if (props.step === 3) {
                    props.submitUserDataAndDisplayVideo()
                    return
                  } else {
                    props.setStep((prevState) => prevState + 1)
                  }
                }}
                fullWidth={props.step === 3}
              >
                {props.step === 3 ? t('videoList.onBoardingModal.doneBtn') : t('videoList.onBoardingModal.nextBtn')}
              </Button>
              <Button
                variant="text"
                color="secondary"
                onClick={props.handleSkip}
                style={{ display: props.step === 3 ? 'none' : 'block' }}
              >
                {t('common.skipBtn')}
              </Button>
            </Box>
          )}
          <Box display="flex" justifyContent={isMobile ? 'center' : 'space-between'} alignItems="center">
            <Box width="56px" display="flex" justifyContent="space-between">
              <StyledDot
                active={props.step === 0}
                disabled={props.step === 0}
                onClick={() => {
                  if (props.step > 0) {
                    props.setStep(0)
                  }
                }}
              />
              <StyledDot
                active={props.step === 1}
                disabled={props.step < 1}
                onClick={() => {
                  if (props.step > 1) {
                    props.setStep(1)
                  }
                }}
              />
              <StyledDot
                active={props.step === 2}
                disabled={props.step < 2}
                onClick={() => {
                  if (props.step > 2) {
                    props.setStep(2)
                  }
                }}
              />
              <StyledDot active={props.step === 3} disabled={props.step < 3} />
            </Box>
            {isMobile ? null : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={props.isNextButtonDisabled}
                  onClick={() => {
                    if (props.step === 3) {
                      props.submitUserDataAndDisplayVideo()
                      return
                    } else {
                      props.setStep((prevState) => prevState + 1)
                    }
                  }}
                >
                  {props.step === 3 ? t('videoList.onBoardingModal.doneBtn') : t('videoList.onBoardingModal.nextBtn')}
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={props.handleSkip}
                  style={{ visibility: props.step === 3 ? 'hidden' : 'visible' }}
                >
                  {t('common.skipBtn')}
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Modal open={props.saving}>
          <Box bgcolor="background.default">
            <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: '16px' }}>
              {t('videoList.onBoardingModal.savingDataLoader')}
            </Typography>
            <LinearProgress />
          </Box>
        </Modal>
      </>
    </Modal>
  )
}

export default OnBoardingProfileInitStepsView
