import React from 'react'

const EnvelopeIcon = () => {
  return (
    <svg width="46" height="38" viewBox="0 0 46 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.8333 35.6812C45.8295 36.2284 45.6106 36.752 45.2239 37.1391C44.8372 37.5262 44.3138 37.7456 43.7667 37.75H6.23333C5.68503 37.7494 5.15937 37.5312 4.77185 37.1433C4.38434 36.7554 4.16667 36.2296 4.16667 35.6812V33.5833H41.6667V9.20833L25 24.2083L4.16667 5.45833V2.33333C4.16667 1.7808 4.38616 1.25089 4.77686 0.860194C5.16756 0.469493 5.69747 0.25 6.25 0.25H43.75C44.3025 0.25 44.8324 0.469493 45.2231 0.860194C45.6138 1.25089 45.8333 1.7808 45.8333 2.33333V35.6812ZM9.2375 4.41667L25 18.6042L40.7625 4.41667H9.2375ZM0 25.25H16.6667V29.4167H0V25.25ZM0 14.8333H10.4167V19H0V14.8333Z"
        fill="white"
      />
    </svg>
  )
}
export default EnvelopeIcon
