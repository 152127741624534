import * as React from 'react'
import { Box } from '@mui/material'

const StripeIdIcon = () => {
  return (
    <Box display="flex">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.125 3.75C2.09717 3.75 1.25 4.59717 1.25 5.625V14.375C1.25 15.4028 2.09717 16.25 3.125 16.25H16.875C17.9028 16.25 18.75 15.4028 18.75 14.375V5.625C18.75 4.59717 17.9028 3.75 16.875 3.75H3.125ZM3.125 5H16.875C17.229 5 17.5 5.271 17.5 5.625V14.375C17.5 14.729 17.229 15 16.875 15H3.125C2.771 15 2.5 14.729 2.5 14.375V5.625C2.5 5.271 2.771 5 3.125 5ZM6.875 6.25C5.50049 6.25 4.375 7.37549 4.375 8.75C4.375 9.4458 4.67285 10.0732 5.13672 10.5273C4.46861 10.9799 3.97975 11.6872 3.81272 12.5042C3.74357 12.8423 4.02982 13.125 4.375 13.125C4.72018 13.125 4.98957 12.8376 5.1012 12.511C5.35312 11.7739 6.04799 11.25 6.875 11.25C7.70201 11.25 8.39688 11.7739 8.6488 12.511C8.76043 12.8376 9.02982 13.125 9.375 13.125C9.72018 13.125 10.0064 12.8423 9.93728 12.5042C9.77025 11.6872 9.28139 10.9799 8.61328 10.5273C9.07715 10.0732 9.375 9.4458 9.375 8.75C9.375 7.37549 8.24951 6.25 6.875 6.25ZM11.875 6.875C11.5298 6.875 11.25 7.15482 11.25 7.5C11.25 7.84518 11.5298 8.125 11.875 8.125H14.7917C15.1368 8.125 15.4167 7.84518 15.4167 7.5C15.4167 7.15482 15.1368 6.875 14.7917 6.875H11.875ZM6.875 7.5C7.57324 7.5 8.125 8.05176 8.125 8.75C8.125 9.44824 7.57324 10 6.875 10C6.17676 10 5.625 9.44824 5.625 8.75C5.625 8.05176 6.17676 7.5 6.875 7.5ZM11.875 9.375C11.5298 9.375 11.25 9.65482 11.25 10C11.25 10.3452 11.5298 10.625 11.875 10.625H13.9583C14.3035 10.625 14.5833 10.3452 14.5833 10C14.5833 9.65482 14.3035 9.375 13.9583 9.375H11.875ZM11.875 11.875C11.5298 11.875 11.25 12.1548 11.25 12.5C11.25 12.8452 11.5298 13.125 11.875 13.125H12.7083C13.0535 13.125 13.3333 12.8452 13.3333 12.5C13.3333 12.1548 13.0535 11.875 12.7083 11.875H11.875Z"
          fill="#171717"
        />
      </svg>
    </Box>
  )
}

export default StripeIdIcon
