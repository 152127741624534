import { Box, Modal } from '@mui/material'
import Button from 'components/_refactored/Button'
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import videojs, { VideoJsPlayer } from 'video.js'
import DeleteCrossIcon from '../../../../../common/icons/DeleteCrossIcon'
import 'video.js/dist/video-js.css'
import './styles.scss'

type PlayerProps = { playerRef: MutableRefObject<VideoJsPlayer | null>; onClose: () => void }
const Player = (props: PlayerProps) => {
  const [playerReady, setPlayerReady] = useState(false)
  const videoNode = useRef(null)

  useEffect(() => {
    if (!videoNode.current) return

    props.playerRef.current = videojs(videoNode.current, {
      sources: [{ src: `${process.env.PUBLIC_URL}/ScreenSight_Welcome_Video.mp4`, type: 'video/mp4' }],
      autoplay: true,
      controls: true,
      bigPlayButton: true,
      muted: false,
      fluid: true,
      playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 3, 4],
    })
    setPlayerReady(true)
  }, [videoNode])

  useEffect(() => {
    if (props?.playerRef.current) {
      props?.playerRef.current?.play()
      return
    }
  }, [playerReady, props.playerRef])

  // clear player on unmount
  useEffect(() => {
    if (!props?.playerRef?.current) return
    const player = props?.playerRef.current
    return () => {
      if (player) {
        player.dispose()
        props.playerRef.current = null
      }
    }
  }, [props?.playerRef])

  useEffect(() => {
    const player = props.playerRef.current
    if (!player) return
    player.on('ended', () => {
      props.onClose()
    })

    return () => {
      player.dispose()
    }
  }, [props.playerRef])

  return (
    <Box className="c-player__screen" data-vjs-player="true">
      <video
        ref={videoNode}
        className="video-js"
        style={{ margin: 0, borderRadius: '10px', display: 'flex', maxWidth: '100%!important', alignItems: 'center' }}
      ></video>
    </Box>
  )
}

type WelcomeVideoPlayerProps = {
  onClose: () => void
  visible: boolean
}

const WelcomeVideoPlayer: FC<WelcomeVideoPlayerProps> = ({ onClose, visible }) => {
  const playerRef = useRef<VideoJsPlayer | null>(null)

  return (
    <Modal open={visible}>
      <Box
        className={'welcome__video__player'}
        position="fixed"
        left="50%"
        top="50%"
        width="100%"
        padding={'0 30px'}
        maxWidth="1100px"
        borderRadius="10px"
        style={{ transform: 'translate(-50%, -50%)', outline: 'none' }}
      >
        <Box position={'relative'} overflow={'hidden'} borderRadius={'5px'}>
          <Box
            display={'flex'}
            position={'absolute'}
            top={0}
            left={0}
            right={0}
            height={'100px'}
            zIndex={1}
            justifyContent={'flex-end'}
            style={{
              background: 'linear-gradient(180deg, #171717 -55.17%, rgba(23, 23, 23, 0) 81.03%)',
              borderTopRightRadius: '10px',
              borderTopLeftRadius: '10px',
            }}
          >
            <Button onClick={onClose}>
              <DeleteCrossIcon fill={'#fff'} />
            </Button>
          </Box>
          <Player playerRef={playerRef} onClose={onClose} />
        </Box>
      </Box>
    </Modal>
  )
}

export default WelcomeVideoPlayer
