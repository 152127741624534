import React, { PropsWithChildren } from 'react'
import { createTheme, ThemeProvider } from '@mui/material'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2483F7',
    },
    secondary: {
      main: '#7A7A86',
      light: '#BCBCC3',
    },
    error: {
      main: '#F73737',
      light: '#FEF7F9',
    },
    background: {
      default: '#FFF',
      paper: '#F8F9FB',
    },
    text: {
      primary: '#171717',
      secondary: '#7A7A86',
    },
    success: {
      main: 'rgb(67, 202, 129)',
    },
  },
  typography: {
    fontFamily: [
      'Heebo',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 85,
      fontWeight: 500,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
    },
    h4: {
      fontSize: 20,
      fontWeight: 400,
    },
    h5: {
      fontSize: 20,
      fontWeight: 400,
    },
    h6: {
      fontSize: 18,
      fontWeight: 400,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 15,
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementLeft: {
          fontSize: '11px',
        },
        tooltipPlacementRight: {
          fontSize: '11px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: 16,
          borderRadius: 100,
          padding: '10px 20px',
          boxShadow: 'none',
          textTransform: 'initial',
          height: '40px',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedSizeLarge: {
          fontSize: 20,
          padding: '13px 30px',
          height: '46px',
        },
        outlined: {
          borderWidth: '2px!important',
        },
        outlinedSizeLarge: {
          fontSize: 20,
          padding: '15px 30px',
        },
        text: {
          padding: '10px 20px',
        },
        textSizeLarge: {
          fontSize: 20,
          padding: '15px 30px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: '40px',
          height: '40px',
          boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.11)',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          boxShadow: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '40px',
        },
        // input: {
        //   padding: '10.5px 14px',
        // },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          height: '46px',
          padding: '10px',
          borderRadius: '100px!important',
          margin: '0!important',
          borderColor: 'transparent',
          backgroundColor: '#F8F9FB!important',
        },
        input: {
          padding: '0!important',
          paddingLeft: '10px!important',
          borderColor: 'transparent',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingTop: '2px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '15px',
          lineHeight: '14px',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(20, 35, 61, 0.7)',
        },
      },
    },
  },
})

const MuiThemeProvider = (props: PropsWithChildren) => <ThemeProvider theme={theme}>{props.children}</ThemeProvider>

export default MuiThemeProvider
