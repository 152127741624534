import React, { PropsWithChildren } from 'react'

import DeleteConfirmProviderView from './ToastProviderView'
import useToastProvider from './useToastProvider'

export type ToastProviderProps = PropsWithChildren<any>

export default (props: ToastProviderProps) => {
  const state = useToastProvider()
  return <DeleteConfirmProviderView {...state} {...props} />
}
