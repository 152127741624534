import {
  FilePost,
  ListPostsByChannelQuery,
  ListPostsByChannelQueryVariables,
  ModelSortDirection,
  OnPostEventSubscription,
  screensightPost,
} from '../../../../../../../../API'
import { listPostsByChannel } from '../../../../../../../../common/api/graphql/queries'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import { useLocation, useParams } from 'react-router'
import useApolloQuery from '../../../../../../../../common/clients/useApolloQuery'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../common/redux/store/store'
import { useNetworkState } from 'react-use'

/*
 * This hook is used to fetch posts list and subscribe to new posts
 * It also provides some useful data for the chat
 */
const usePostsList = () => {
  const auth = useContext(AuthContext)
  const [lastSeen, setLastSeen] = useState('')
  const [lastUnseen, setLastUnseen] = useState('')
  const [newMessagesInfo, setNewMessagesInfo] = useState(false)
  const { id: channelParamsId } = useParams() as { id: string }
  const location = useLocation()
  const elem = document.getElementById('chat__scrollable-box')
  const [visualEffectsVisible, setVisualEffectsVisible] = useState(false)
  const [deletedPostsIds, setDeletedPostsIds] = useState<string[]>([])
  const [channelId, setChannelId] = useState('')
  const onPostEventSubscriptionData = useSelector((state: RootState) => state.chat.onPostEventSubscriptionData)
  const networkState = useNetworkState()
  const el = document.getElementById('chat__scrollable-box')

  /*
    Scroll to bottom when changing channel
   */
  useEffect(() => {
    if (!channelId || !el) return
    el.scrollTo(0, 0)
  }, [channelId, el])

  useEffect(() => {
    if (auth.user?.pending) return
    if (location.pathname === '/app/support') {
      setChannelId(auth.user?.supportChannelId || '')
    } else {
      setChannelId(channelParamsId)
    }
  }, [channelParamsId, location.pathname, auth.user])

  const {
    data: listPostData,
    loading: listPostDataLoading,
    fetchMore: fetchMorePostsData,
    updateQuery: updateListPostDataQuery,
    startPolling,
    stopPolling,
  } = useApolloQuery<ListPostsByChannelQuery, ListPostsByChannelQueryVariables>(listPostsByChannel, {
    variables: {
      channelId: channelId,
      sortDirection: ModelSortDirection.DESC,
      limit: 20,
    },
    skip: !auth.isInitialized || !channelId || channelId === 'new' || location.pathname === '/app/chat',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
  })

  useEffect(() => {
    const refetch = async () => {
      if (auth.user?.pending || !channelId || !networkState?.online) return
      if (document.visibilityState === 'visible') {
        startPolling(1000)
        setTimeout(() => stopPolling(), 1500)
        setTimeout(() => startPolling(15000), 2000)
      } else {
        stopPolling()
      }
    }
    document.addEventListener('visibilitychange', refetch)
    return () => document.removeEventListener('visibilitychange', refetch)
  }, [channelId, auth.user?.pending, networkState?.online])

  useEffect(() => {
    if (!networkState?.online) {
      stopPolling()
      return
    }
    startPolling(1000)
    setTimeout(() => stopPolling(), 1500)
    setTimeout(() => startPolling(15000), 2000)
  }, [networkState?.online])

  /*
   * This function is used to handle deleted post ids. It is used to hide deleted posts from the chat
   * We need to use it, because cache update is triggered by subscription even if we remove element from the cache
   */
  const handleDeletedPosts = (data?: OnPostEventSubscription) => {
    if (data?.onPostEvent?.action !== 'delete' || channelId !== data?.onPostEvent?.channelId) return
    setDeletedPostsIds((prev) => [...prev, data?.onPostEvent?.post?.id || ''])
  }

  /*
   * function that inform user about new messages
   * click on button causes scroll to bottom of chat
   */
  const handleDisplayNewMessageInfo = (data?: OnPostEventSubscription) => {
    if (
      !data?.onPostEvent?.post ||
      data?.onPostEvent?.action === 'update' ||
      data?.onPostEvent?.action === 'delete' ||
      !channelId ||
      channelId !== data?.onPostEvent?.channelId
    )
      return
    if (data?.onPostEvent?.post.customerId !== auth.user?.id) {
      if ((elem?.scrollTop as number) >= 0) return
      setNewMessagesInfo(true)
      setTimeout(() => setNewMessagesInfo(false), 3000)
    }
  }

  const handleAddNewPost = (data?: OnPostEventSubscription) => {
    if (
      !data?.onPostEvent?.post ||
      data?.onPostEvent.action === 'delete' ||
      !channelId ||
      channelId !== data?.onPostEvent?.channelId
    )
      return
    updateListPostDataQuery((previousQueryResult) => {
      if (!previousQueryResult?.listPostsByChannel?.items) return previousQueryResult
      let obj: ListPostsByChannelQuery
      /*
       update file object. Solution below if condition does not work with "update" action type
       */
      if (data?.onPostEvent?.action === 'update' && data?.onPostEvent?.post?.postType === 'file') {
        const filePostToUpdateIndex = previousQueryResult?.listPostsByChannel?.items.findIndex(
          (item) => item?.id === data?.onPostEvent?.post?.id,
        )
        const newResults = [...(previousQueryResult?.listPostsByChannel?.items as screensightPost[])]
        let newPost = { ...newResults[filePostToUpdateIndex] }
        newPost = {
          ...newPost,
          file: {
            ...newPost.file,
            ...(data?.onPostEvent?.post?.file as FilePost),
          },
        }
        if (filePostToUpdateIndex >= 0 && newPost) {
          newResults[filePostToUpdateIndex] = newPost
        }
        obj = Object.assign({}, previousQueryResult, {
          listPostsByChannel: {
            items: newResults,
            nextToken: previousQueryResult?.listPostsByChannel?.nextToken,
          },
        })
        return obj
      }
      obj = Object.assign({}, previousQueryResult, {
        listPostsByChannel: {
          items: [...(previousQueryResult?.listPostsByChannel?.items as screensightPost[]), data?.onPostEvent?.post],
          nextToken: previousQueryResult?.listPostsByChannel?.nextToken,
        },
      })
      return obj
    })
  }

  useEffect(() => {
    if (!onPostEventSubscriptionData) return
    handleDeletedPosts(onPostEventSubscriptionData)
    handleDisplayNewMessageInfo(onPostEventSubscriptionData)
    handleAddNewPost(onPostEventSubscriptionData)
  }, [onPostEventSubscriptionData])

  useEffect(() => {
    if (!listPostData?.listPostsByChannel?.items) return
    const sortedPosts = [...listPostData?.listPostsByChannel?.items]
      ?.sort(function (a, b) {
        if (!a?.createdAt || !b?.createdAt) return 0
        return b?.createdAt < a?.createdAt ? -1 : b?.createdAt > a?.createdAt ? 1 : 0
      })
      ?.filter((item) => !(item?.postType === 'video' && !item?.video?.name?.includes('.mp4')))

    const lastPost = sortedPosts?.find(
      (l) => l?.engagement?.items?.length && l?.customerId === auth.user?.id && l.engagement.items[0]?.seen,
    )

    const lastUnseenPost = sortedPosts?.find((l) => l?.customerId === auth.user?.id && !l?.engagement?.items?.[0]?.seen)

    const lastPostIndex = sortedPosts?.findIndex((l) => l?.id === lastPost?.id)
    const lastUnseenPostIndex = sortedPosts?.findIndex((l) => l?.id === lastUnseenPost?.id)

    setLastSeen(lastPost?.id || '')
    if (lastUnseenPost?.id === 'temp-id' || lastUnseenPost?.id === 'temp-id-id') return
    setLastUnseen(lastUnseenPost?.id || '')
    if (lastPostIndex < lastUnseenPostIndex) {
      setLastUnseen('')
    }
  }, [listPostData?.listPostsByChannel?.items])

  //
  // /*
  //   * This effect is used to show visual effects when a new post is received
  //  */
  // useEffect(() => {
  //   if (!listPostData?.listPostsByChannel?.items?.length || !channelId) return
  //   const visualEffectsItem = [...listPostData?.listPostsByChannel?.items]
  //     ?.sort(function (a, b) {
  //       if (!a?.createdAt || !b?.createdAt) return 0
  //       return b?.createdAt < a?.createdAt ? -1 : b?.createdAt > a?.createdAt ? 1 : 0
  //     })
  //     .find(
  //       (item) =>
  //         item?.customerId !== auth.user?.id &&
  //         item?.postType === 'text' &&
  //         (item?.text?.content?.toLowerCase() === 'balloons...' ||
  //           item?.text?.content?.toLowerCase() === 'balloons...\n'),
  //     )
  //   if (visualEffectsItem?.createdAt) {
  //     const lastTimestamp = localStorage.getItem(`lastVisualEffect:${channelId}`)
  //     if (!lastTimestamp) {
  //       localStorage.setItem(`lastVisualEffect:${channelId}`, visualEffectsItem?.createdAt || '')
  //       setVisualEffectsVisible(true)
  //       const timeout = setTimeout(() => setVisualEffectsVisible(false), 5000)
  //       return () => clearTimeout(timeout)
  //     } else {
  //       const oldDate = new Date(lastTimestamp)
  //       const newDate = new Date(visualEffectsItem.createdAt)
  //       // @ts-ignore
  //       if (oldDate - newDate < 0) {
  //         localStorage.setItem(`lastVisualEffect:${channelId}`, visualEffectsItem?.createdAt || '')
  //         setVisualEffectsVisible(true)
  //         const timeout = setTimeout(() => setVisualEffectsVisible(false), 5000)
  //         return () => clearTimeout(timeout)
  //       }
  //     }
  //   }
  // }, [listPostData?.listPostsByChannel?.items?.length, auth.user?.id, channelId])

  /*
   * function to scroll to bottom of chat
   */
  const handleScrollToBottom = () => {
    const elem = document.getElementById('chat__scrollable-box')
    elem?.scrollTo(0, 0)
    setNewMessagesInfo(false)
  }

  /*
   * Load next posts from DB
   */
  const loadNextPosts = async () => {
    if (!listPostData?.listPostsByChannel?.nextToken) return
    await fetchMorePostsData({
      variables: {
        limit: 15,
        nextToken: listPostData?.listPostsByChannel?.nextToken,
        channelId: channelId,
      },
    })
  }

  return {
    items: listPostData?.listPostsByChannel?.items,
    userId: auth.user?.id || sessionStorage.getItem('guestCustomerId') || '',
    loadNextPosts,
    postsCount: listPostData?.listPostsByChannel?.items?.length || 0,
    hasMore: !!listPostData?.listPostsByChannel?.nextToken,
    listPostDataLoading: listPostData?.listPostsByChannel?.items?.length ? false : listPostDataLoading,
    lastSeen,
    lastUnseen,
    newMessagesInfo,
    handleScrollToBottom,
    channelId,
    visualEffectsVisible,
    deletedPostsIds,
  }
}

export default usePostsList
