import React, { useContext, useEffect } from 'react'
import RecorderStateContext from 'common/providers/RecorderStateProvider/RecorderStateContext'
import RecordingStartOverModal from './RecordingStartOverModal'
import RecordingUploadErrorModal from './RecordingUploadErrorModal'
import RecordingUploadModal from './RecordingUploadModal'
import RecordingInProgressModal from './RecordingInProgressModal'
import RecordingCameraInProgressModal from './RecordingCameraInProgressModal'
import RecordingCameraSetupPrompterModal from './RecordingCameraSetupPrompterModal'
import RecordingCountDown from './RecordingCountDown'
import { useTranslation } from 'react-i18next'
import ScreenRecordingPermissionsErrorModal from './ScreenRecordingPermissionsErrorModal'
import CameraPermissionErrorModal from './CamerPermissionErrorModal'
import MicrophonePermissionErrorModal from './MicrophonePermissionErrorModal'

const RecordControl = () => {
  const { state: recorderState } = useContext(RecorderStateContext)
  const { t: g } = useTranslation('general')
  const handleBlockReloadPage = (ev: BeforeUnloadEvent) => {
    ev.preventDefault()
    return (ev.returnValue = g('recordControl.reloadPage.message'))
  }
  useEffect(() => {
    if (!recorderState.active) return
    window.addEventListener('beforeunload', handleBlockReloadPage)
    return () => window.removeEventListener('beforeunload', handleBlockReloadPage)
  }, [recorderState.active])

  if (recorderState.prompter.currentState === 'SETUP') return <RecordingCameraSetupPrompterModal />
  if (recorderState.screenPermissionDeniedBySystem) return <ScreenRecordingPermissionsErrorModal />
  if (recorderState.cameraPermissionDeniedBySystem) return <CameraPermissionErrorModal />
  if (recorderState.microphonePermissionDeniedBySystem) return <MicrophonePermissionErrorModal />
  if (!recorderState.active) return null
  if (recorderState.countdown && !recorderState.cameraStream?.active) return <RecordingCountDown />
  else if (recorderState.startover) return <RecordingStartOverModal />
  else if (recorderState.upload && recorderState.uploadError) return <RecordingUploadErrorModal />
  else if (recorderState.upload && !recorderState.uploadError) return <RecordingUploadModal />
  else if (recorderState.cameraStream?.active)
    return (
      <RecordingCameraInProgressModal
        hasPrompter={recorderState.prompter.currentState === 'RECORDING'}
        countDown={recorderState.countdown}
      />
    )
  else return <RecordingInProgressModal />
}

export default RecordControl
