import React from 'react'
import { Box, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'
import CloudFrontImage from '../../../../../../../components/_refactored/CloudFrontImage'
import ScreenSightCustomerIcon from '../../../../../../../common/icons/ScreenSightCustomerIcon'

interface IUserAvatar {
  label?: string
  active?: boolean
  width?: string
  height?: string
  fontSize?: string
  imgKey?: string
  style?: React.CSSProperties
  isScreenSightUser?: boolean
}

const UserAvatar = (props: IUserAvatar) => {
  return (
    <Box
      width={props.width || '20px'}
      height={props.height || '20px'}
      borderRadius="50px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={props.active ? '#2196ff' : '#d5e7fc'}
      style={props.style}
      position="relative"
    >
      {props.imgKey ? (
        <CloudFrontImage
          image={`public/${props.imgKey}`}
          width="40"
          height="40"
          imgStyle={{
            width: '100%',
            height: '100%',
            borderRadius: '100px',
            objectFit: 'cover',
          }}
        />
      ) : (
        <Typography
          variant="body1"
          style={{ color: props.active ? 'white' : theme.palette.primary.main, fontSize: props.fontSize || '16px' }}
        >
          {props.label?.charAt(0)?.toUpperCase()}
        </Typography>
      )}
      {/*{props.isScreenSightUser && <ScreenSightCustomerIcon />}*/}
    </Box>
  )
}

export default UserAvatar
