import React, { useContext } from 'react'

import { ProfileProps } from '../index'
import { UseProfile } from '../hooks/useProfile.hook'
import { Box, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Controller } from 'react-hook-form'
import Button from '../../../../components/_refactored/Button'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import styled from 'styled-components'
import AddIcon from '../../../../common/assets/Icon-Add-Photo.png'
import TrashIcon from '../../../../common/icons/TrashIcon'
import Modal from '../../../../components/_refactored/Modal'
import { Cropper } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import UploadModal from '../../../../components/Settings/Profile/UploadModal'
import CloudFrontImage from '../../../../components/_refactored/CloudFrontImage'
import { useTranslation } from 'react-i18next'
import LanguageSelect from '../../../../pages/Settings/ui/components/LanguageSelect'

const StyledForm = styled.form`
  input[type='file'] {
    display: none;
  }
`

const ProfileView = (props: ProfileViewProps) => {
  const { form } = props
  const theme = useTheme()
  const { t } = useTranslation('settings')
  const { t: g } = useTranslation('general')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const auth = useContext(AuthContext)

  return (
    <StyledForm onSubmit={form.submit}>
      <Box position="relative">
        {props.photoLoading && <UploadModal />}
        {auth?.user?.profilePhoto?.key ? (
          <Box
            position="relative"
            onMouseOver={props.showRemoveIcon}
            onMouseLeave={props.hideRemoveIcon}
            width={isMobile ? '80px' : '150px'}
            height={isMobile ? '80px' : '150px'}
          >
            {props.removePhotoIconVisible && (
              <Box
                width={isMobile ? '80px' : '150px'}
                height={isMobile ? '80px' : '150px'}
                position="absolute"
                top="0"
                left="0"
                style={{ background: 'rgba(20, 35, 61, 0.7)', cursor: 'pointer' }}
                borderRadius="100px"
                zIndex={100}
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={props.showDeletePhotoModal}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="body2" style={{ color: 'white' }}>
                    {g('common.deleteBtn')}
                  </Typography>
                  <TrashIcon color="white" />
                </Box>
              </Box>
            )}
            {auth?.user?.profilePhoto?.key && (
              <CloudFrontImage
                image={`public/${auth.user.profilePhoto.key}`}
                height={isMobile ? '80' : '150'}
                width={isMobile ? '80' : '150'}
                imgStyle={{
                  height: isMobile ? '80px' : '150px',
                  width: isMobile ? '80px' : '150px',
                  marginRight: '6px',
                  objectFit: 'cover',
                  borderRadius: '100px',
                }}
              />
            )}
          </Box>
        ) : (
          <Box mb={3}>
            <Box
              borderRadius="100px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor="#EEF0F6"
              width={isMobile ? '80px' : '150px'}
              height={isMobile ? '80px' : '150px'}
              style={{ cursor: 'pointer' }}
              {...props.dropzone.getRootProps()}
            >
              <input {...props.dropzone.getInputProps()} />
              <img alt="Add icon" src={AddIcon} style={{ width: '40px', height: '40px', objectFit: 'cover' }} />
            </Box>
            {props.image && (
              <Modal open={true} onClose={props.handleCloseCropperModal} style={{ position: 'relative' }}>
                <>
                  <Box
                    width="100%"
                    height="400px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    overflow="hidden"
                    border="1px solid gray"
                  >
                    <Cropper
                      style={{ height: 400, width: '100%' }}
                      zoomTo={0}
                      initialAspectRatio={1}
                      aspectRatio={1}
                      preview=".img-preview"
                      src={props.image as string}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                      guides={true}
                      draggable
                      ref={props.cropperRef}
                    />
                  </Box>
                  <Box display="flex" justifyContent="flex-end" paddingTop="16px">
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginRight: '8px' }}
                      onClick={props.handleCloseCropperModal}
                    >
                      {g('common.cancelBtn')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={props.handleImageCroppingSubmit}>
                      {g('common.submitBtn')}
                    </Button>
                  </Box>
                </>
              </Modal>
            )}
          </Box>
        )}

        <Controller
          name={'firstName'}
          control={form.control}
          rules={{
            required: g('common.requiredField'),
            validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
          }}
          render={({ value, onChange, onBlur }) => (
            <TextField
              // InputLabelProps={{ shrink: true }}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              label={g('common.firstName')}
              error={!!form.errors['firstName']}
              helperText={form.errors['firstName']?.message}
              variant="outlined"
              style={{ width: '100%', marginTop: '32px' }}
              InputProps={{
                style: {
                  fontSize: '16px',
                },
              }}
            />
          )}
        />
        <Box height="24px" />
        <Controller
          name={'lastName'}
          control={form.control}
          rules={{
            required: g('common.requiredField'),
            validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
          }}
          render={({ value, onChange, onBlur }) => (
            <TextField
              // InputLabelProps={{ shrink: true }}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              label={g('common.lastName')}
              error={!!form.errors['lastName']}
              helperText={form.errors['lastName']?.message}
              variant="outlined"
              style={{ width: '100%' }}
              InputProps={{
                style: {
                  fontSize: '16px',
                },
              }}
            />
          )}
        />
        <Box height="24px" />
        <TextField
          key={auth.user?.email}
          disabled
          id="outlined-disabled"
          label={g('common.email')}
          defaultValue={auth.user?.email}
          fullWidth
        />
        <LanguageSelect />
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} marginTop="24px">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!form.formState.isDirty}
            style={{ marginRight: !isMobile ? '8px' : '', width: isMobile ? '100%' : '' }}
            loading={props.loading}
          >
            {g('common.saveChangesBtn')}
          </Button>
          {form.formState.isDirty && (
            <Button
              variant="text"
              color="secondary"
              onClick={() => form.reset()}
              style={{ width: isMobile ? '100%' : '', marginTop: isMobile ? '12px' : '' }}
            >
              {g('common.cancelBtn')}
            </Button>
          )}
        </Box>
      </Box>
      <Modal open={props.removeModalVisible} onBackdropClick={props.hideDeletePhotoModal}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" style={{ fontWeight: 500, fontSize: '18px', marginBottom: '17px' }}>
            {t('common.deletePhotoModalTitle')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t('common.deletePhotoModalSubtitle')}
          </Typography>
          <Box display="flex" justifyContent="space-between" marginTop="30px" width="200px">
            <Button variant="outlined" onClick={props.hideDeletePhotoModal} disabled={props.photoDeleting}>
              {g('common.cancelBtn')}
            </Button>
            <Button
              variant="outlined"
              style={{ color: 'red', borderColor: 'red' }}
              loading={props.photoDeleting}
              onClick={props.removeImage}
            >
              {g('common.deleteBtn')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </StyledForm>
  )
}

export type ProfileViewProps = ProfileProps & UseProfile

export default ProfileView
