import React from 'react'
import NotificationRow from '../../../../components/Settings/EmailNotifications/NotificationRow'
import { AuthContextValue } from '../../../../common/providers/AuthStatusProvider'
import { Box, Typography } from '@mui/material'
import Landscape from '../../../../common/icons/Landscape'
import Button from '../../../../components/_refactored/Button'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import TrashIcon from '../../../../common/icons/TrashIcon'
import LandscapeAdd from '../../../../common/icons/LandscapeAdd'
import Modal from '../../../../components/_refactored/Modal'
import { Cropper } from 'react-cropper'
import UploadModal from '../../../../components/Settings/Profile/UploadModal'
import { useTranslation } from 'react-i18next'

const MonitorIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.54175 3.5C2.51362 3.5 1.66675 4.34687 1.66675 5.375V13.7083C1.66675 14.7365 2.51362 15.5833 3.54175 15.5833H7.08341V17.6667H5.62508C5.54226 17.6655 5.46003 17.6808 5.38318 17.7117C5.30632 17.7426 5.23637 17.7884 5.17739 17.8466C5.11841 17.9047 5.07158 17.974 5.03961 18.0504C5.00764 18.1268 4.99118 18.2088 4.99118 18.2917C4.99118 18.3745 5.00764 18.4565 5.03961 18.5329C5.07158 18.6093 5.11841 18.6786 5.17739 18.7368C5.23637 18.7949 5.30632 18.8408 5.38318 18.8717C5.46003 18.9025 5.54226 18.9178 5.62508 18.9167H14.3751C14.4579 18.9178 14.5401 18.9025 14.617 18.8717C14.6938 18.8408 14.7638 18.7949 14.8228 18.7368C14.8818 18.6786 14.9286 18.6093 14.9606 18.5329C14.9925 18.4565 15.009 18.3745 15.009 18.2917C15.009 18.2088 14.9925 18.1268 14.9606 18.0504C14.9286 17.974 14.8818 17.9047 14.8228 17.8466C14.7638 17.7884 14.6938 17.7426 14.617 17.7117C14.5401 17.6808 14.4579 17.6655 14.3751 17.6667H12.9167V15.5833H16.4584C17.4865 15.5833 18.3334 14.7365 18.3334 13.7083V5.375C18.3334 4.34687 17.4865 3.5 16.4584 3.5H3.54175ZM3.54175 4.75H16.4584C16.8111 4.75 17.0834 5.02229 17.0834 5.375V13.7083C17.0834 14.061 16.8111 14.3333 16.4584 14.3333C12.1529 14.3333 7.8473 14.3333 3.54175 14.3333C3.18904 14.3333 2.91675 14.061 2.91675 13.7083V5.375C2.91675 5.02229 3.18904 4.75 3.54175 4.75ZM8.33341 15.5833H9.8422C9.95009 15.6128 10.0639 15.6128 10.1718 15.5833H11.6667V17.6667H8.33341V15.5833Z"
      fill="#171717"
      stroke="#171717"
      strokeWidth="0.8"
    />
    <path
      d="M11.9638 7.45459L7.76806 7.46C7.67327 7.4591 7.58187 7.49575 7.51321 7.56218C7.44455 7.62861 7.40403 7.7196 7.40024 7.81587C7.39917 7.86388 7.40756 7.91161 7.4249 7.95628C7.44224 8.00094 7.46818 8.04163 7.50121 8.07596C7.53424 8.11029 7.57368 8.13756 7.61722 8.15618C7.66076 8.17479 7.70752 8.18438 7.75476 8.18437L11.9271 8.17851C12.0211 8.17851 12.1113 8.21648 12.1778 8.28406C12.2442 8.35165 12.2816 8.44331 12.2816 8.53889L12.2843 10.4575C12.2843 10.5531 12.2469 10.6447 12.1804 10.7123C12.1139 10.7799 12.0238 10.8179 11.9297 10.8179L10.0423 10.8206C9.9483 10.8206 9.85813 10.7826 9.79164 10.715C9.72516 10.6474 9.6878 10.5558 9.6878 10.4602V9.22858C9.6888 9.13219 9.65278 9.03922 9.58741 8.9694C9.52204 8.89958 9.43246 8.85842 9.33771 8.85468C9.29049 8.8536 9.24353 8.86212 9.19959 8.87975C9.15565 8.89737 9.11562 8.92375 9.08185 8.95732C9.04808 8.99089 9.02125 9.03099 9.00294 9.07525C8.98463 9.11951 8.9752 9.16704 8.97521 9.21506V10.4944C8.97498 10.6327 9.00163 10.7696 9.05364 10.8974C9.10566 11.0252 9.182 11.1412 9.27829 11.2389C9.37457 11.3366 9.4889 11.4139 9.6147 11.4665C9.7405 11.5191 9.87528 11.5459 10.0113 11.5454L11.9692 11.5427C12.2429 11.5422 12.5052 11.4313 12.6985 11.2343C12.8917 11.0373 13.0002 10.7704 12.9999 10.4922L12.9973 8.50195C12.9969 8.22397 12.888 7.95751 12.6945 7.76111C12.501 7.56472 12.2386 7.45447 11.9652 7.45459"
      fill="#171717"
    />
    <path
      d="M10.5146 9.50108C10.5154 9.60403 10.5486 9.70403 10.6092 9.78646C10.6699 9.86889 10.7549 9.92943 10.8519 9.95922C10.8951 9.97215 10.9398 9.97897 10.9848 9.97949C11.1097 9.9789 11.2293 9.92812 11.3174 9.83824C11.4056 9.74835 11.4552 9.62667 11.4555 9.49973C11.4548 9.39671 11.4217 9.29661 11.361 9.21409C11.3003 9.13157 11.2152 9.07097 11.1182 9.04115C11.0751 9.02783 11.0303 9.021 10.9853 9.02087C10.8603 9.02135 10.7406 9.07215 10.6524 9.16216C10.5642 9.25217 10.5146 9.37404 10.5146 9.50108Z"
      fill="#171717"
    />
    <path
      d="M7.90497 10.3741H6.96814C6.8737 10.3741 6.78313 10.4123 6.71635 10.4802C6.64958 10.548 6.61206 10.6401 6.61206 10.7361C6.61206 10.8321 6.64958 10.9242 6.71635 10.9921C6.78313 11.0599 6.8737 11.0981 6.96814 11.0981H7.90541C7.99985 11.0984 8.09056 11.0606 8.15758 10.993C8.22461 10.9254 8.26246 10.8335 8.26281 10.7375C8.26317 10.6415 8.22599 10.5493 8.15946 10.4811C8.09293 10.413 8.00251 10.3745 7.90807 10.3741"
      fill="#171717"
    />
    <path
      d="M7.90282 8.92719L6.15635 8.92944C6.06185 8.92944 5.97122 8.9676 5.90441 9.03552C5.83759 9.10344 5.80005 9.19556 5.80005 9.29162C5.80005 9.38768 5.83759 9.4798 5.90441 9.54773C5.97122 9.61565 6.06185 9.65381 6.15635 9.65381L7.90238 9.65155C7.99687 9.65155 8.0875 9.6134 8.15432 9.54547C8.22114 9.47755 8.25867 9.38543 8.25867 9.28937C8.25867 9.19331 8.22114 9.10119 8.15432 9.03327C8.0875 8.96534 7.99687 8.92719 7.90238 8.92719"
      fill="#171717"
    />
  </svg>
)

const WhiteCircle = () => (
  <Box
    bgcolor="white"
    borderRadius="100px"
    width="20px"
    height="20px"
    boxShadow="0px 1px 2px rgba(23, 23, 23, 0.1)"
    marginRight="12px"
  />
)

interface IBrandingSettingsProps {
  handleSplashScreenVisibility: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  auth: AuthContextValue
  splashScreenMutationLoading: boolean
  dropzone: any
  image: string | ArrayBuffer | null
  cropperRef: any
  handleCloseCropperModal: () => void
  handleImageCroppingSubmit: () => void
  showRemoveIcon: () => void
  hideRemoveIcon: () => void
  showDeletePhotoModal: () => void
  hideDeletePhotoModal: () => void
  removeImage: () => void
  isCropperVisible: boolean
  removePhotoIconVisible: boolean
  removeModalVisible: boolean
  photoDeleting: boolean
  photoUpdating: boolean
}

const BrandingView = (props: IBrandingSettingsProps) => {
  const { t } = useTranslation('settings')
  const { t: g } = useTranslation('general')
  if (!props.auth.user?.settings) return <></>
  return (
    <>
      {props.photoUpdating && <UploadModal />}
      <NotificationRow
        icon={MonitorIcon}
        title={t('branding.splashScreenTitle')}
        description={t('branding.splashScreenDescription')}
        submitAction={props.handleSplashScreenVisibility}
        checked={props.auth.user?.settings?.enableSplashScreen}
        loading={props.splashScreenMutationLoading}
      />
      <Box marginBottom="20px">
        <Box display="flex" alignItems="center" marginBottom="8px">
          <Landscape color="black" />
          <Typography variant="body1" style={{ marginLeft: '8px', fontWeight: 500 }}>
            {t('branding.addLogoTitle')}
          </Typography>
        </Box>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" color="textSecondary">
            {t('branding.addLogoDescription')}
          </Typography>
          <Box
            width="60px"
            height="60px"
            borderRadius="6px"
            bgcolor="#F9FAFC"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ marginRight: '16px', cursor: 'pointer' }}
            padding="2px"
            onMouseOver={props.showRemoveIcon}
            onMouseLeave={props.hideRemoveIcon}
            position="relative"
            {...props.dropzone.getRootProps()}
          >
            {props.image && props.removePhotoIconVisible && (
              <Box
                width="100%"
                height="100%"
                position="absolute"
                top="0"
                left="0"
                borderRadius="6px"
                style={{ background: 'rgba(20, 35, 61, 0.7)', cursor: 'pointer' }}
                zIndex={100}
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={props.showDeletePhotoModal}
              >
                <TrashIcon color="white" />
              </Box>
            )}
            {props.image ? (
              <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                <img alt="Brand logo" src={props.image as string} style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </Box>
            ) : (
              <LandscapeAdd />
            )}
            <input {...props.dropzone.getInputProps()} />
          </Box>
        </Box>
      </Box>
      <Typography variant="h6" color="textPrimary" style={{ fontSize: '12px', fontWeight: 400, marginTop: '0' }}>
        {t('branding.videoPreview')}
      </Typography>
      <Box
        width="100%"
        maxWidth="90vw"
        height="340px"
        bgcolor="#F9FAFC"
        marginTop="15px"
        borderRadius="10px"
        padding="20px"
      >
        <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
          <Box
            width="45px"
            height="45px"
            border={props.image ? 'none' : '2px dotted #7A7A86'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={props.image ? '0' : '6px'}
          >
            {props?.image ? (
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                boxShadow="0px 0px 3px rgba(0, 0, 0, 0.1)"
                borderRadius="6px"
                padding="2px"
              >
                <img
                  alt="Brand logo"
                  src={props.image as string}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </Box>
            ) : (
              <Box padding="5px 5px 1px 5px">
                <Landscape />
              </Box>
            )}
          </Box>
          <Box display="flex">
            <WhiteCircle />
            <WhiteCircle />
            <WhiteCircle />
            <Box width="52px" height="20px" borderRadius="10px" bgcolor="#BCBCC3" />
          </Box>
        </Box>
        <Box width="350px" maxWidth="90%" margin="20px auto 0 auto" display="flex" alignItems="center">
          <Box width="20px" height="20px" borderRadius="100px" bgcolor="white" marginRight="12px" />
          <Typography variant="h6" style={{ fontSize: '12px' }} color="textSecondary">
            {t('branding.videoTitle')}
          </Typography>
        </Box>
        <Box
          width="350px"
          height="160px"
          maxWidth="100%"
          margin="10px auto 20px auto"
          bgcolor="white"
          borderRadius="14px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            width="40px"
            height="40px"
            bgcolor="#BCBCC3"
            borderRadius="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <PlayArrowRoundedIcon style={{ color: 'white' }} />
          </Box>
        </Box>
      </Box>
      {props.isCropperVisible && (
        <Modal open={true} onClose={props.handleCloseCropperModal} style={{ position: 'relative' }}>
          <>
            <Box
              width="100%"
              height="400px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              border="1px solid gray"
            >
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={0}
                initialAspectRatio={1}
                // aspectRatio={16 / 9}
                preview=".img-preview"
                src={props.image as string}
                viewMode={1}
                minCropBoxHeight={40}
                minCropBoxWidth={40}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                guides={true}
                draggable
                ref={props.cropperRef}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" paddingTop="16px">
              <Button
                variant="contained"
                color="secondary"
                style={{ marginRight: '8px' }}
                onClick={props.handleCloseCropperModal}
              >
                {g('common.cancelBtn')}
              </Button>
              <Button variant="contained" color="primary" onClick={props.handleImageCroppingSubmit}>
                {g('common.submitBtn')}
              </Button>
            </Box>
          </>
        </Modal>
      )}
      <Modal open={props.removeModalVisible} onBackdropClick={props.hideDeletePhotoModal}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" style={{ fontWeight: 500, fontSize: '18px', marginBottom: '17px' }}>
            {t('common.deletePhotoModalTitle')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t('common.deletePhotoModalSubtitle')}
          </Typography>
          <Box display="flex" justifyContent="space-between" marginTop="30px" width="200px">
            <Button variant="outlined" onClick={props.hideDeletePhotoModal}>
              {g('common.cancelBtn')}
            </Button>
            <Button
              variant="outlined"
              style={{ color: 'red', borderColor: 'red' }}
              loading={props.photoDeleting}
              onClick={props.removeImage}
            >
              {g('common.deleteBtn')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default BrandingView
