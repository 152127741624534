import * as React from 'react'
import Linkify from 'react-linkify'
import { Box } from '@mui/material'
import { sanitazeHtml } from '../../../../../../../../common/utils/sanitazeHtml'
import '../../../common/styles/posts.style.scss'

interface ITextPostProps {
  postId: string
  owner: boolean
  richContent?: string
  content?: string
  mergedInside?: boolean
  firstMerged?: boolean
  lastMerged?: boolean
}

const TextPost = (props: ITextPostProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      className="text__post"
      maxWidth="calc(100% - 60px)"
      justifyContent="flex-end"
    >
      <Box
        display="flex"
        flexDirection={props.owner ? 'row' : 'row-reverse'}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Box
          className={props.owner ? 'chat__post-owner-text' : 'chat__post-partner-text'}
          color={props.owner ? '#FFF' : '#171717'}
          bgcolor={props.owner ? 'primary.main' : 'rgba(188, 188, 195, 0.15)'}
          padding="15px"
          borderRadius="24px"
          marginTop="6px"
          style={{
            fontSize: '15px',
            borderBottomRightRadius:
              props.owner && (props.mergedInside || props.lastMerged) ? '4px' : props.firstMerged ? '24px' : '24px',
            borderTopRightRadius: props.owner
              ? props.mergedInside || props.firstMerged
                ? '4px'
                : props.lastMerged
                ? '0'
                : '0'
              : '24px',
            borderTopLeftRadius: !props.owner
              ? props.mergedInside || props.firstMerged
                ? '4px'
                : props.lastMerged
                ? '0'
                : '0'
              : '24px',
            borderBottomLeftRadius:
              !props.owner && (props.mergedInside || props.lastMerged) ? '4px' : props.firstMerged ? '24px' : '24px',
          }}
        >
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {sanitazeHtml(props?.richContent || props?.content || '')}
          </Linkify>
        </Box>
      </Box>
    </Box>
  )
}

export default TextPost
