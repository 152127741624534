import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { useQuery } from '@apollo/client'
import {
  ListContactsByOwnerQuery,
  ListContactsByOwnerQueryVariables,
  ModelSortDirection,
  screensightContact,
  SearchablescreensightContactSortableFields,
  SearchableSortDirection,
  SearchScreensightContactsQuery,
  SearchScreensightContactsQueryVariables,
} from '../../../../API'
import gql from 'graphql-tag'
import { listContactsByOwner, searchScreensightContacts } from '../../../../graphql/queries'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import { API } from 'aws-amplify'
import { onCreateContact, onDeleteContact, onUpdateContact } from '../../../../graphql/subscriptions'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import { GraphQLErrors } from '@apollo/client/errors'

const useContacts = () => {
  const auth = useContext(AuthContext)
  const [contacts, setContacts] = useState<screensightContact[]>([])
  const [newContactModalVisible, setNewContactModalVisible] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchPhrase, setSearchPhrase] = useState('')

  const {
    loading: contactsListQueryLoading,
    data: contactsListQueryData,
    refetch: refetchContactsListQueryData,
  } = useQuery<ListContactsByOwnerQuery, ListContactsByOwnerQueryVariables>(gql(listContactsByOwner), {
    variables: {
      sortDirection: ModelSortDirection.ASC,
      ownerCustomerId: auth.user?.id || '',
    },
    skip: !auth.isInitialized || !auth.isAuthenticated || !!searchPhrase.length,
    client: CognitoApolloClient,
  })

  const {
    loading: contactsSearchQueryLoading,
    data: contactsSearchQueryData,
    refetch: refetchContactsSearchQueryData,
  } = useQuery<SearchScreensightContactsQuery, SearchScreensightContactsQueryVariables>(
    gql(searchScreensightContacts),
    {
      variables: {
        sort: [
          {
            field: SearchablescreensightContactSortableFields.lastName,
            direction: SearchableSortDirection.asc,
          },
          {
            field: SearchablescreensightContactSortableFields.firstName,
            direction: SearchableSortDirection.asc,
          },
          {
            field: SearchablescreensightContactSortableFields.email,
            direction: SearchableSortDirection.asc,
          },
        ],
        filter: searchPhrase
          ? {
              or: [
                {
                  email: { matchPhrasePrefix: searchPhrase },
                },
                {
                  firstName: { matchPhrasePrefix: searchPhrase },
                },
                {
                  lastName: { matchPhrasePrefix: searchPhrase },
                },
              ],
            }
          : undefined,
      },
      skip: !auth.isInitialized || !auth.isAuthenticated || !searchPhrase.length,
      client: CognitoApolloClient,
    },
  )

  const getNextContacts = async () => {
    if (searchPhrase) {
      if (!contactsSearchQueryData?.searchScreensightContacts?.nextToken) return
      await refetchContactsSearchQueryData({
        nextToken: contactsSearchQueryData?.searchScreensightContacts?.nextToken,
        limit: 10,
      })
    } else {
      if (!contactsListQueryData?.listContactsByOwner?.nextToken) return
      await refetchContactsListQueryData({
        nextToken: contactsListQueryData?.listContactsByOwner?.nextToken,
        limit: 10,
      })
    }
  }

  const updateContactsByListQuery = () => {
    const oldContacts = [...contacts]
    const newContacts = contactsListQueryData?.listContactsByOwner?.items
      ? contactsListQueryData?.listContactsByOwner?.items
      : []
    // @ts-ignore
    const updatedContacts = [...new Map([...oldContacts, ...newContacts].map((item) => [item['id'], item])).values()]
    setContacts(updatedContacts)
  }

  const updateContactsBySearchQuery = () => {
    const oldContacts = [...contacts]
    const newContacts = contactsSearchQueryData?.searchScreensightContacts?.items
      ? contactsSearchQueryData?.searchScreensightContacts?.items
      : []
    // @ts-ignore
    const updatedContacts = [...new Map([...oldContacts, ...newContacts].map((item) => [item['id'], item])).values()]
    setContacts(updatedContacts)
  }

  useEffect(() => {
    if (contactsListQueryData?.listContactsByOwner?.items) {
      updateContactsByListQuery()
    } else {
      updateContactsBySearchQuery()
    }
  }, [contactsSearchQueryData?.searchScreensightContacts?.items, contactsListQueryData?.listContactsByOwner?.items])

  useEffect(() => {
    if (!auth.user?.id) return
    const subOnUpdateContact = API.graphql({
      query: gql(onCreateContact),
      variables: {
        ownerCustomerId: auth.user.id,
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      // @ts-ignore
    }).subscribe({
      next: ({ value }: any) => {
        const oldContacts = [...contacts]
        oldContacts.push(value?.data?.onCreateContact)
        setContacts(oldContacts)
      },
      error: (error: GraphQLErrors) => {
        if (error) {
          console.error('Error on create contact subscription', error)
        }
      },
    })
    return () => {
      subOnUpdateContact.unsubscribe()
    }
  }, [auth.user?.id, contacts])

  useEffect(() => {
    if (!auth.user?.id) return
    const subOnUpdateContact = API.graphql({
      query: gql(onUpdateContact),
      variables: {
        ownerCustomerId: auth.user.id,
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      // @ts-ignore
    }).subscribe({
      next: ({ value }: any) => {
        const oldContacts = [...contacts]
        const index = oldContacts.findIndex((contact) => contact.id === value?.data?.onUpdateContact.id)
        oldContacts[index] = { ...value?.data?.onUpdateContact }
        setContacts(oldContacts)
      },
      error: (error: GraphQLErrors) => {
        if (error) {
          console.error('Error on create contact subscription', error)
        }
      },
    })
    return () => {
      subOnUpdateContact.unsubscribe()
    }
  }, [auth.user?.id, contacts])

  useEffect(() => {
    if (!auth.user?.id) return
    const subOnDeleteContact = API.graphql({
      query: gql(onDeleteContact),
      variables: {
        ownerCustomerId: auth.user.id,
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      // @ts-ignore
    }).subscribe({
      next: ({ value }: any) => {
        const oldContacts = [...contacts]
        const index = oldContacts.findIndex((contact) => contact.id === value?.data?.onDeleteContact.id)
        if (index !== -1) {
          oldContacts.splice(index, 1)
        }
        setContacts(oldContacts)
      },
      error: (error: GraphQLErrors) => {
        if (error) {
          console.error('Error on create contact subscription', error)
        }
      },
    })
    return () => {
      subOnDeleteContact.unsubscribe()
    }
  }, [auth.user?.id, contacts])

  const debounceCallback = useCallback(
    debounce((text: string) => {
      setContacts([])
      text !== '' ? setSearchPhrase(text) : setSearchPhrase('')
    }, 300),
    [],
  )

  const handleSearchPhraseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
      debounceCallback(event.target.value)
    },
    [debounceCallback],
  )

  const showNewContactModal = () => {
    setNewContactModalVisible(true)
  }

  const hideNewContactModal = () => {
    setNewContactModalVisible(false)
  }

  const hasMore = useMemo(() => {
    if (searchPhrase) {
      return (contactsSearchQueryData?.searchScreensightContacts?.items?.length || 0) === 10
    } else {
      return (contactsListQueryData?.listContactsByOwner?.items?.length || 0) === 10
    }
  }, [
    searchPhrase,
    contactsListQueryData?.listContactsByOwner?.items,
    contactsSearchQueryData?.searchScreensightContacts?.items,
  ])

  return {
    newContactModalVisible,
    showNewContactModal,
    hideNewContactModal,
    handleSearchPhraseChange,
    searchValue,
    searchPhrase,
    getNextContacts,
    loading: contactsSearchQueryLoading || contactsListQueryLoading,
    hasMore,
    contacts,
  }
}

export default useContacts
