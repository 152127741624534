import * as React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface IVideoPostUploading {
  owner?: boolean
  title: string | null | undefined
  createdAt: string
  isMobile: boolean
}

export const VideoPostUploading = (props: IVideoPostUploading) => {
  const { t: g } = useTranslation('general')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      bgcolor="background.paper"
      padding="25px 30px"
      borderRadius="10px"
      width="400px"
      maxWidth="100%"
      display="flex"
      style={{
        borderTopRightRadius: props.owner ? '0' : '10px',
        borderTopLeftRadius: props.owner ? '10px' : '0',
      }}
      position="relative"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={props.isMobile ? '80px' : '120px'}
        width={props.isMobile ? '80px' : '120px'}
      >
        <Typography
          variant={isMobile ? 'body1' : 'h3'}
          color="secondary"
          style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
        >
          {g('common.uploading')}
        </Typography>
      </Box>
      <Box paddingLeft="20px" width="calc(100% - 150px)">
        <Typography
          variant="body1"
          style={{
            fontSize: '20px',
            fontWeight: 500,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          {props.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: 400, fontSize: '15px' }}>
          {moment(props.createdAt).format('MMMM DD, YYYY ')}
        </Typography>
      </Box>
    </Box>
  )
}

export default VideoPostUploading
