import * as React from 'react'
import { IconButton } from '@mui/material'
import StarPro from '../../../../common/icons/StarPro'

type Props = {
  onClick: (value: boolean) => void
}
const StarIconButton = (props: Props) => {
  return (
    <IconButton
      onClick={(event) => {
        event.preventDefault()
        props.onClick(true)
      }}
      style={{
        padding: 0,
        width: '24px',
        height: '24px',
        position: 'absolute',
        left: '-8px',
        top: '-8px',
        border: '2px solid #FFF',
        boxShadow: 'none',
        zIndex: 9,
      }}
    >
      <StarPro />
    </IconButton>
  )
}
export default StarIconButton
