import { useMutation } from '@apollo/client'
import { CreateReactionMutation } from '../api/types'
import { CreateReactionMutationVariables } from '../../../../../../../../API'
import gql from 'graphql-tag'
import { createReaction } from '../api/createReaction.mutation'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import { useDispatch } from 'react-redux'
import { addVideoPlayerReaction } from '../../../../../../common/redux/store/chat-actions'
import useWriteReactionFragment from './useWriteReactionFragment'

/*
 * This hook is used to create reaction
 * It will update cache fragment
 */
const useCreateReaction = () => {
  const auth = useContext(AuthContext)
  const client = auth.isAuthenticated ? CognitoApolloClient : IamApolloClient
  const dispatch = useDispatch()
  const [updateReactionCacheFragment] = useWriteReactionFragment()
  const [createReactionMutation] = useMutation<CreateReactionMutation, CreateReactionMutationVariables>(
    gql(createReaction),
    {
      client: client,
    },
  )

  /*
   * This function is used to create reaction
   * It will update cache fragment and call mutation
   * @param postId - id of the post
   * @param videoTimestamp - timestamp of the video. If not provided, should be set to 0 (optional)
   * @param unicode - unicode of the reaction
   * @param channelId - id of the channel
   * @param onlyCache - if true, it will only update cache (optional)
   * @param customerId - id of the customer. If not provided, default customerId is equal to currentUserId (optional)
   * @param createdAt - createdAt of the reaction. If onlyCache is set to false, it will be passed from response.
   *  If onlyCache is set to true provide this field to prevent cache errors, default createdAt is equal to current date (optional)
   * @param updatedAt - updatedAt of the reaction. If onlyCache is set to false, it will be passed from response.
   *  If onlyCache is set to true provide this field to prevent cache errors, default updated is equal to current date (optional)
   */
  const addReaction = async ({
    postId,
    videoTimestamp = 0,
    uniCode,
    channelId,
    onlyCache,
    customerId,
    createdAt,
    updatedAt,
  }: {
    postId: string
    videoTimestamp?: number
    uniCode: string
    channelId: string
    onlyCache?: boolean
    customerId?: string
    createdAt?: string
    updatedAt?: string
  }) => {
    if (!uniCode || !channelId) return
    /*
     * variable to use for customerId. If user is not authenticated, we will use guestCustomerId
     */
    const customerIdToUse = customerId
      ? customerId
      : auth.user?.id || sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId') || ''

    /*
     * read cache fragment for reactions
     */
    const cachedReactions: any = client.cache.readFragment({
      id: `screensightPost:${postId}`,
      fragment: gql`
        fragment PostReaction2 on screensightPost {
          reactions {
            items {
              postId
              customerId
              unicode
              videoTimestamp
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      `,
    })
    /*
     * if onlyCache is set to true, we will only update cache fragment
     */
    if (!onlyCache) {
      const res = await createReactionMutation({
        variables: {
          input: {
            postId: postId,
            customerId: customerIdToUse,
            videoTimestamp: videoTimestamp || 0,
            unicode: uniCode,
            incrementBy: 1,
            channelId,
          },
        },
      })
      /*
       * if videoTimestamp is provided, we will add reaction to video player and store in redux
       */
      if (videoTimestamp && res?.data?.createReaction) {
        dispatch(addVideoPlayerReaction(postId, res?.data?.createReaction || ([] as any)))
      }
      if (!cachedReactions) return
      /*
       * update cache fragment with new reaction
       */
      updateReactionCacheFragment(postId, [
        ...cachedReactions?.reactions?.items,
        {
          __typename: 'screensightReaction',
          ...res.data?.createReaction,
        },
      ])
      return
    } else {
      /*
       * if onlyCache is set to true, we will only update cache fragment
       */
      updateReactionCacheFragment(postId, [
        ...cachedReactions?.reactions?.items,
        {
          __typename: 'screensightReaction',
          postId,
          customerId: customerIdToUse,
          unicode: uniCode,
          videoTimestamp: videoTimestamp || 0,
          createdAt,
          updatedAt,
        },
      ])
      return
    }
  }

  return [addReaction]
}

export default useCreateReaction
