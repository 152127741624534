import React, { useContext } from 'react'
import Logo from '../../../../common/icons/Logo'
import { Box, Typography } from '@mui/material'
import Button from '../../../../components/_refactored/Button'
import Icon from '../../../../common/icons'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'

interface IEmptyVideoList {
  isMobile: boolean
  handleNewVideoBtnClick: () => void
  recorderActive: boolean
}

const EmptyVideoList = (props: IEmptyVideoList) => {
  const auth = useContext(AuthContext)
  const isFreeTier =
    auth.applicationConfiguration?.getApplicationConfiguration?.featureFlags?.id?.toLowerCase() === 'free'

  const { isMobile, recorderActive, handleNewVideoBtnClick } = props
  const { t } = useTranslation('library')
  return (
    <Box
      padding="16px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      height={isMobile ? 'calc(100% - 80px)' : 'calc(100% - 113px)'}
      justifyContent="center"
      marginTop={isMobile ? '0' : '-56px'}
    >
      <Logo color="#D7D7DB" />
      <Typography variant="h6" style={{ fontWeight: 700, margin: '20px 0 10px 0' }}>
        {t('videoList.emptyList.title')}
      </Typography>
      <Typography variant="body1" color="secondary" style={{ fontWeight: 500 }}>
        {t('videoList.emptyList.subtitle')}
      </Typography>
      {isFreeTier && (
        <Typography variant="body1" color="secondary" style={{ fontWeight: 500, marginTop: '10px' }}>
          {t('videoList.emptyList.freeAccountsInfo')}
        </Typography>
      )}
      <Box display="flex" width="100%" justifyContent="center" marginTop="30px">
        <Button
          variant="contained"
          color="primary"
          onClick={handleNewVideoBtnClick}
          disabled={recorderActive}
          data-testid="empty-list-record-new-video"
        >
          <Box marginRight="10px">
            <Icon.SmallCamera />
          </Box>
          {t('videoList.emptyList.recordVideoBtn')}
        </Button>
      </Box>
    </Box>
  )
}

export default EmptyVideoList
