import {useState} from "react";
import {Analytics} from "aws-amplify";
import useInitialUILoadTime from "../../../../../../common/utils/useInitialUILoadTime";
import useTimeToFirstInputAnalytics from "../../../../../../common/utils/useTimeToFirstInputAnalytics";

const useAuthVerifyAnalytics = () => {
	const loadTime = useInitialUILoadTime()
	const [analyticsEventError, setAnalyticsEventError] = useState('')
	const pinCodeAnalytics = useTimeToFirstInputAnalytics()

	const sendAnalyticsSignUpVerifyEvent = async (pageExitPoint: string, email: string) => {
		const timeToPageExit = parseFloat((performance.now() - loadTime).toFixed(2))
		const event = {
			name: 'page_signup.section_email_verify',
			metrics: {
				ui_load_time: loadTime,
				time_to_first_input_pin: pinCodeAnalytics.timeToFirstInput,
				time_to_page_exit: timeToPageExit
			},
			attributes: {
				email,
				error: analyticsEventError,
				exit_point: pageExitPoint
			}
		}

		console.log('Sending analytics event: ', event)

		await Analytics.record({
			...event,
			immediate: true
		})
	}

	return {
		handleInputChangeForPinAnalytics: pinCodeAnalytics.handleInputChange,
		setAnalyticsEventError,
		sendAnalyticsSignUpVerifyEvent
	}
}

export default useAuthVerifyAnalytics
