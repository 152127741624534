import React from 'react'
import { UseToastProvider } from './useToastProvider'
import { ToastProviderProps } from './index'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ToastContext from './ToastContext'
import { Box, IconButton, Typography, useTheme } from '@mui/material'

const ToastProviderView = (props: ToastProviderViewProps) => {
  const { children, open, close, text, type, toastState, styles } = props
  const theme = useTheme()

  return (
    <ToastContext.Provider value={{ open }}>
      <Box
        display={toastState ? 'flex' : 'none'}
        zIndex={99999999999}
        width="100%"
        position="fixed"
        top={0}
        padding={'4px 15px'}
        justifyContent="space-between"
        alignItems="center"
        data-testid="error"
        style={{
          boxShadow: '0 0 6px #4f4f4f',
          backgroundColor:
            type === 'ERROR'
              ? theme.palette.error.main
              : type === 'INFO'
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
          ...styles,
        }}
      >
        {type === 'ERROR' ? (
          <ErrorIcon style={{ fill: theme.palette.background.default, width: '15px', height: '15px' }} />
        ) : (
          <CheckCircleRoundedIcon style={{ fill: theme.palette.background.default, width: '15px', height: '15px' }} />
        )}
        <Typography style={{ fontSize: '13px', color: theme.palette.background.default }}>{text}</Typography>
        <IconButton style={{ width: '15px', height: '15px' }} onClick={close}>
          <CloseIcon style={{ fill: theme.palette.background.default, width: '15px', height: '15px' }} />
        </IconButton>
      </Box>
      {children}
    </ToastContext.Provider>
  )
}

export type ToastProviderViewProps = UseToastProvider & ToastProviderProps
export default ToastProviderView
