import React from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { IAuthLayout } from '../../../../common/types/Auth'

const Layout = (props: IAuthLayout) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      width="100vw"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      padding={isMobile ? '0' : '60px'}
    >
      {props.children}
    </Box>
  )
}
export default Layout
