import React from 'react'

interface IProps {
  expanded?: boolean
}

const ArrowRight = (props: IProps) => {
  return (
    <svg
      width="7"
      height="14"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: props?.expanded ? 'rotate(90deg)' : '' }}
    >
      <path
        d="M5.04815e-05 8.94235C5.48822e-05 9.85266 1.11741 10.2895 1.73486 9.62062L5.3739 5.67828C5.72749 5.29522 5.72749 4.70478 5.3739 4.32172L1.73482 0.379383C1.11737 -0.289518 7.96382e-06 0.147355 1.23645e-05 1.05767L5.04815e-05 8.94235Z"
        fill="#BCBCC3"
      />
    </svg>
  )
}

export default ArrowRight
