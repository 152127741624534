import * as React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TrackInThreadsBtn from './TrackInThreadsBtn'
import SendEmailBtn from './SendEmailBtn'
import CloseBtn from './CloseBtn'
import { screensightVideo, VideoStatus } from '../../../../../API'

interface IFormControlProps {
  isMobile: boolean
  sending: boolean
  trackInThreads: boolean
  toggleTrackInThreads: () => void
  emails: string[]
  subject: string
  currentContactToAdd: string | undefined
  hideSendByEmailModal: () => void
  sendEmail: () => void
  isError: boolean
  videoStatus?: VideoStatus
}
const FormControl = (props: IFormControlProps) => {
  const theme = useTheme()
  const { t } = useTranslation('library')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      width="100%"
      height={isMobile ? '160px' : '60px'}
      bgcolor="background.paper"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="10px 20px"
      flexWrap="wrap"
      style={{ borderTopLeftRadius: '9px', borderTopRightRadius: '9px' }}
    >
      <Typography variant="body1" style={{ fontWeight: 500, fontSize: '18px' }}>
        {t('videoItem.sendViaEmailModal.header')}
      </Typography>
      <Box display="flex" alignItems="center">
        <TrackInThreadsBtn
          isMobile={isMobile}
          sending={props.sending}
          trackInThreads={props.trackInThreads}
          toggleTrackInThreads={props.toggleTrackInThreads}
        />
        <SendEmailBtn
          isError={props.isError}
          sending={props.sending}
          emails={props.emails}
          sendEmail={props.sendEmail}
          subject={props.subject}
          currentContactToAdd={props.currentContactToAdd}
          disabled={props?.videoStatus !== VideoStatus.ok}
        />
        <CloseBtn sending={props.sending} hideSendByEmailModal={props.hideSendByEmailModal} />
      </Box>
    </Box>
  )
}

export default FormControl
