import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { AuthContext } from '../../../../../../common/providers/AuthStatusProvider'
import ToastContext from '../../../../../../common/providers/ToastProvider/ToastContext'
import useAuthVerifyAnalytics from './useAuthVerifyAnalytics'

const useVerify = () => {
  const auth = useContext(AuthContext)
  const [value, setValue] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [resending, setResending] = useState(false)
  const history = useHistory()
  const location = useLocation<{ email: string; password?: string }>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const toastContext = useContext(ToastContext)
  const { sendAnalyticsSignUpVerifyEvent, setAnalyticsEventError, handleInputChangeForPinAnalytics } =
    useAuthVerifyAnalytics()

  useEffect(() => {
    if (!errorMessage) return
    toastContext.open({ text: errorMessage, time: 5000 })
    setErrorMessage(undefined)
  }, [errorMessage])

  useEffect(() => {
    if (!location.state) history.push('/sign-up')
  }, [location.state])

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setLoading(true)
      await auth.verifyCode(location.state.email, value)
      localStorage.removeItem('onBoarded')

      if (location.state.password) {
        await auth.login(location.state.email, location.state.password)
        await sendAnalyticsSignUpVerifyEvent('next', location.state.email)
      } else {
        history.push('/login')
      }
    } catch (err: any) {
      setErrorMessage(err.message)
      setAnalyticsEventError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const resendCode = async () => {
    try {
      setResending(true)
      await auth.resendCode(location.state?.email)
      toastContext.open({ text: 'Code has been sent', type: 'INFO' })
    } catch (e: any) {
      console.error(e)
      setErrorMessage(e.message)
    } finally {
      setResending(false)
    }
  }

  const handleUpdateValue = (value: string) => {
    setValue(value)
  }

  return {
    onSubmit,
    loading,
    errorMessage,
    resendCode,
    handleInputChangeForPinAnalytics,
    history,
    resending,
    handleUpdateValue,
    value,
  }
}

export default useVerify
