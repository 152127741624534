import * as React from 'react'
import { useTheme } from '@mui/material'
import { Rnd } from 'react-rnd'
import { useDispatch } from 'react-redux'
import { updateSidebarWidth } from '../../../common/redux/store/chat-actions'
import useResize from './common/hooks/useResize'
import './styles.scss'

type ResizableContainerProps = {
  children: React.ReactNode
  maxWidth?: string
}
const ResizableContainer = (props: ResizableContainerProps) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { sidebarParams } = useResize()

  return (
    <Rnd
      className="resizable__container"
      size={{ width: sidebarParams.width, height: sidebarParams.height }}
      enableResizing={{ left: true, right: false }}
      onResize={(e, direction, ref, delta, position) => {
        dispatch(
          updateSidebarWidth({
            width: Number(ref.style.width.replace('px', '')),
            height: ref.style.height,
            x: position.x,
            y: position.y,
          }),
        )
      }}
      position={{ x: sidebarParams.x, y: sidebarParams.y }}
      maxWidth={props?.maxWidth || '300px'}
      minWidth={44}
      dragAxis="x"
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
      }}
      disableDragging
    >
      {props.children}
    </Rnd>
  )
}
export default ResizableContainer
