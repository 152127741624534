import React from 'react'
import { BoxProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'

const useStyles = makeStyles({
  root: {},
})

const Footer = ({ children, ...props }: BoxProps) => {
  const { root } = useStyles()
  return (
    <Box className={root} display="flex" alignItems={'center'} justifyContent={'center'} flexDirection="row" {...props}>
      {children}
    </Box>
  )
}

export default Footer
