import { useEditThumbnailContext, useEditThumbnailDispatch } from '../context/EditThumbnailContext'
import { useEffect, useRef, useState } from 'react'
import { dataURItoBlob } from '../../../../../../common/utils/convertBase64ToBlob'
import * as d3 from 'd3'
import moment from 'moment'
import { IThumbnailState } from '../types'
import { calculateTicks } from '../../../../../../common/utils/calculateTicks'
import useUploadThumbnail from './useUploadThumbnail'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

const useVideoFrameThumbnailModal = () => {
  const { id: videoAccessId } = useParams() as { id: string }
  const editThumbnailContext = useEditThumbnailContext()
  const editThumbnailDispatcher = useEditThumbnailDispatch()
  const history = useHistory()
  const [arrayOfImages, setArrayOfImages] = useState<IThumbnailState[]>([])
  const videoRef = useRef<HTMLVideoElement>(null)
  const [selectedImageObject, setSelectedImageObject] = useState<IThumbnailState | null>(null)
  const d3Chart = useRef(null)
  const update = useRef(false)
  const parentDiv = document.getElementById('video-frame-thumbnail__axis-wrapper')
  const [duration, setDuration] = useState(0)
  const [currentGeneratedFrame, setCurrentGeneratedFrame] = useState(0)
  const [uploadThumbnail] = useUploadThumbnail(editThumbnailContext.videoId, editThumbnailContext.thumbnailKey)
  const [uploading, setUploading] = useState(false)
  const handleCanPlay = () => {
    const video = videoRef.current
    if (!video || video.currentTime >= video.duration || arrayOfImages.length >= 10) return
    setCurrentGeneratedFrame((prevState) => prevState + 1)
    video.currentTime = Number(
      (video.duration * (currentGeneratedFrame / 10) + Math.random() * (video.duration / 10)).toFixed(3),
    )
  }

  useEffect(() => {
    if (arrayOfImages?.length === 10) {
      setSelectedImageObject(arrayOfImages[0])
    }
  }, [arrayOfImages])

  const handleLoadedMetadata = () => {
    const video = videoRef.current
    if (!video) return
    setDuration(video.duration)
    video.currentTime = 0

    video.addEventListener(
      'seeked',
      () => {
        const canvas = document.querySelector('canvas')
        if (!canvas) return
        canvas.width = video.getBoundingClientRect().width
        canvas.height = video.getBoundingClientRect().height
        const context = canvas?.getContext('2d')
        context?.drawImage(video, 0, 0, canvas.width, canvas.height)
        setArrayOfImages((prevState) => [
          ...prevState,
          { currentTime: video.currentTime, image: canvas?.toDataURL('image/jpeg', 0.8) || '' },
        ])
      },
      false,
    )
  }

  const handleUpload = async () => {
    if (!selectedImageObject?.image) return
    const file = dataURItoBlob(selectedImageObject.image)
    if (!file) return
    try {
      setUploading(true)
      await uploadThumbnail(file)
      history.push(`/video/${videoAccessId}`)
    } catch (e: any) {
      console.error('Error during uploading video thumbnail')
    } finally {
      setUploading(false)
    }
  }

  useEffect(() => {
    if (!parentDiv?.offsetWidth || !duration) return
    if (update.current) {
      d3.selectAll('g').remove()
    } else {
      update.current = true
    }
    drawChart(parentDiv?.offsetWidth, duration)
  }, [parentDiv?.offsetWidth, duration])

  const drawChart = (parentWidth: number, duration: number) => {
    const svg = d3.select(d3Chart.current)
    const width = parentWidth

    const videoLength = duration
    const uiTicks = calculateTicks(0, videoLength, 5)
    const detailedTicks = calculateTicks(0, videoLength, 15)

    svg
      .append('g')
      .attr('transform', `translate(0 10)`)
      .append('rect')
      .attr('width', width)
      .attr('height', '1px')
      .attr('fill', '#7A7A86')
      .attr('x', () => `${0}px`)
    // ticks
    svg
      .append('g')
      .selectAll('.background-legend-bar')
      .data([...detailedTicks, videoLength])
      .join('rect')
      .attr('width', '1px')
      .attr('height', (d: number, i: number) =>
        uiTicks.includes(d) || i === 0 || i === detailedTicks.length ? '7px' : '3px',
      )
      .attr('fill', '#7A7A86')
      .attr('y', (d: number, i: number) =>
        uiTicks.includes(d) || i === 0 || i === detailedTicks.length ? '3px' : '7px',
      )
      .attr('x', (d: number, i: number) =>
        i === detailedTicks.length ? `${(d / videoLength) * width - 1}px` : `${(d / videoLength) * width}px`,
      )

    svg
      .append('g')
      .selectAll('.background-legend-bar')
      .data([...detailedTicks, videoLength])
      .join('text')
      .attr('fill', '#7A7A86')
      .text((d: number) => moment.utc(d * 1000).format('mm:ss'))
      .attr('y', '0px')
      .attr('visibility', (d: number, i: number) =>
        uiTicks.includes(d) || i === 0 || i === detailedTicks.length ? 'visible' : 'hidden',
      )
      .attr('x', (d: any, i: number) => `${(d / videoLength) * width - 17}px`)
  }

  const refreshThumbnails = async () => {
    await editThumbnailDispatcher({ type: 'setCurrentModalView', value: '' })
    editThumbnailDispatcher({ type: 'setCurrentModalView', value: 'FRAME' })
  }

  return {
    arrayOfImages,
    handleUpload,
    refreshThumbnails,
    videoRef,
    handleLoadedMetadata,
    handleCanPlay,
    selectedImageObject,
    setSelectedImageObject,
    d3Chart,
    uploading,
  }
}

export default useVideoFrameThumbnailModal
