import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ClockIcon from '../../../../common/icons/ClockIcon'

const VideoConverting = () => {
  const { t } = useTranslation('library')
  return (
    <Box
      width="100%"
      height="500px"
      bgcolor="transparent"
      borderRadius="6px"
      border="1px solid rgba(188,188,195,0.6)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginBottom="15px"
      flexDirection="column"
    >
      <ClockIcon />
      <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 500, marginTop: '20px' }}>
        {t('videoItem.videoConverting.title')}
      </Typography>
      <Typography variant="body1" color="textSecondary" style={{ fontSize: '15px' }}>
        {t('videoItem.videoConverting.subtitle')}
      </Typography>
    </Box>
  )
}

export default VideoConverting
