import React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import Icon from '../../../../../../common/icons'
import { Login } from '../../common/config'
import { useTranslation } from 'react-i18next'
// import LanguageSelect from '../../../../../Settings/ui/components/LanguageSelect'

const Content = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation('auth')
  return (
    <Box
      maxWidth={isMobile ? '100%' : '328px'}
      width="90%"
      margin="50px 0"
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      alignSelf="center"
      data-testid="login-form"
    >
      {/*<LanguageSelect />*/}

      <Box display="flex" flexDirection="column" alignItems="center">
        <Icon.ScreensightLogo style={{ marginBottom: 80 }} />
        <Typography variant="body1" style={{ marginBottom: 50, fontWeight: 500, fontSize: '20px' }}>
          {t('login.heading')}
        </Typography>
        <Login.UserForm />
      </Box>
    </Box>
  )
}

export default Content
