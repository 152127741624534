import { Box } from '@mui/material'
import * as React from 'react'

const RefreshIcon = () => {
  return (
    <Box display="flex">
      <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 1C6.17791 1 2.93856 3.02342 1.35632 5.97256C1.14279 6.37056 1.35131 6.85123 1.77393 7.01061C2.17458 7.16171 2.61923 6.96634 2.82502 6.59084C4.15701 4.16051 6.82282 2.5 10 2.5C12.6229 2.5 14.9614 3.69238 16.4213 5.5H13.986C13.5717 5.5 13.236 5.83579 13.236 6.25C13.236 6.66421 13.5717 7 13.986 7H17.8989C18.4512 7 18.8989 6.55228 18.8989 6V2.55899C18.8989 2.1122 18.5367 1.75 18.0899 1.75C17.6431 1.75 17.2809 2.1122 17.2809 2.55899V4.07031C15.5081 2.18652 12.8883 1 10 1ZM18.2261 12.9894C17.8254 12.8383 17.3808 13.0337 17.175 13.4092C15.843 15.8395 13.1772 17.5 10 17.5C7.34867 17.5 5.01966 16.29 3.55337 14.5H6.01404C6.42826 14.5 6.76404 14.1642 6.76404 13.75C6.76404 13.3358 6.42826 13 6.01404 13H2.10112C1.54884 13 1.10112 13.4477 1.10112 14V17.441C1.10112 17.8878 1.46332 18.25 1.91011 18.25C2.3569 18.25 2.7191 17.8878 2.7191 17.441V15.9297C4.48876 17.79 7.08322 19 10 19C13.8221 19 17.0614 16.9766 18.6437 14.0274C18.8572 13.6294 18.6487 13.1488 18.2261 12.9894Z"
          fill="#7A7A86"
          stroke="#7A7A86"
          strokeWidth="0.6"
        />
      </svg>
    </Box>
  )
}

export default RefreshIcon
