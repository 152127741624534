import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/material'
import React from 'react'
import Modal from '../../../../components/_refactored/Modal'
import Button from '../../../../components/_refactored/Button'
import FormTextField from '../../FormTextField'
import { UseFormMethods } from 'react-hook-form/dist/types/form'
import { INewContactForm } from '../hooks/useNewContactModal'
import { isEmail } from 'react-multi-email'
import { useTranslation } from 'react-i18next'

interface INewContactModalView {
  form: UseFormMethods<INewContactForm>
  handleCreateContact: () => void
  hideNewContactModal: () => void
  handleCloseCropperModal: () => void
  loading: boolean
  image: string | ArrayBuffer | null
  dropzone: any
  cropperRef: any
}

const NewContactModalView = (props: INewContactModalView) => {
  const { t } = useTranslation('contacts')
  const { t: g } = useTranslation('general')

  return (
    <Modal
      open={true}
      boxStyle={{ width: '640px', maxWidth: '90vw' }}
      onBackdropClick={props.hideNewContactModal}
      data-testid="add-contact-modal"
    >
      <>
        <Box display="flex" width="100%" justifyContent="center" marginBottom="40px">
          <Typography variant="body1" style={{ fontWeight: 500, fontSize: '18px' }}>
            {t('contactsList.addContactModal.title')}
          </Typography>
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <form>
              <FormTextField
                fieldName="firstName"
                form={props.form}
                topLabel={g('common.firstName')}
                rules={{
                  validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
                }}
              />
              <FormTextField
                fieldName="lastName"
                form={props.form}
                topLabel={g('common.lastName')}
                rules={{
                  validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
                }}
              />
              <FormTextField
                data-testid="contact-page-email-input"
                fieldName="email"
                form={props.form}
                topLabel={g('common.email')}
                rules={{
                  required: g('common.requiredField'),
                  validate: (value: string) => (isEmail(value) ? true : g('common.incorrectEmail')),
                }}
              />
              <FormTextField
                type="tel"
                topLabel={g('common.phone')}
                fieldName="phone"
                form={props.form}
                rules={{
                  validate: (value: string) => (value?.length > 30 ? g('common.maxTextInputLength') : true),
                }}
              />
            </form>
          </Grid>
        </Grid>
        <Box display="flex" width="100%" justifyContent="center" marginBottom="40px">
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '50px' }}
            onClick={props.handleCreateContact}
            loading={props.loading}
            data-testid="submit-new-contact-btn"
          >
            {g('common.addContactBtn')}
          </Button>
        </Box>
      </>
    </Modal>
  )
}

export default NewContactModalView
