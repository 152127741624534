import React from 'react'
import useTermsAndConditions from './useTermsAndConditions'
import TermsAndConditions from './TermsAndConditions'
import TermsAndConditionsUpdate from './TermsAndConditionsUpdate'

const TermsAndConditionsSync = () => {
  const {
    currentTermsAndConditionsVersionForScreenSight,
    termsAndConditionsStep,
    setAcceptedValueForCurrentTermsAndConditions,
    goToActualTermsAndConditionsToAccept,
    updatingTermsAndConditions,
  } = useTermsAndConditions()

  if (termsAndConditionsStep === 'UPDATED_CONDITIONS_MENTION') {
    return <TermsAndConditionsUpdate onNext={goToActualTermsAndConditionsToAccept} />
  }

  if (termsAndConditionsStep === 'TO_ACCEPT' && currentTermsAndConditionsVersionForScreenSight)
    return (
      <TermsAndConditions
        loading={updatingTermsAndConditions}
        termsAndConditionsVersion={currentTermsAndConditionsVersionForScreenSight}
        onAccept={setAcceptedValueForCurrentTermsAndConditions}
      />
    )

  return null
}

export default TermsAndConditionsSync
