import React from 'react'

const ReplyIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.52348 0.916782C6.36424 0.921658 6.21287 0.987154 6.1003 1.09989L0.26697 6.93322C0.149804 7.05044 0.0839844 7.20938 0.0839844 7.37512C0.0839844 7.54085 0.149804 7.6998 0.26697 7.81701L6.1003 13.6503C6.18772 13.7377 6.29908 13.7972 6.42029 13.8213C6.54151 13.8454 6.66714 13.833 6.78132 13.7857C6.8955 13.7384 6.9931 13.6583 7.06178 13.5556C7.13047 13.4528 7.16715 13.332 7.1672 13.2084V10.0232C8.07869 9.97506 9.49099 9.97522 10.9335 10.4497C12.6425 11.0118 14.1847 12.1079 14.6794 14.5813C14.71 14.7332 14.796 14.8684 14.9206 14.9605C15.0453 15.0526 15.1997 15.0951 15.3539 15.0798C15.5081 15.0645 15.6511 14.9924 15.7552 14.8776C15.8593 14.7628 15.917 14.6134 15.9172 14.4584C15.9172 14.4131 15.9038 14.3805 15.9034 14.3356H15.905C15.9044 14.3324 15.9024 14.3305 15.9017 14.3274C15.8588 10.2379 14.0089 7.76139 11.9279 6.41402C10.0949 5.22712 8.27437 4.90921 7.1672 4.7856V1.54178C7.16723 1.45811 7.15046 1.37528 7.11788 1.29821C7.0853 1.22114 7.03758 1.15139 6.97755 1.09311C6.91752 1.03482 6.8464 0.989182 6.7684 0.958894C6.6904 0.928605 6.60712 0.914285 6.52348 0.916782Z"
        fill="#2483F7"
      />
    </svg>
  )
}

export default ReplyIcon
