import { memo } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { theme } from '../../../../../../../../../../common/providers/MuiThemeProvider'
import useVideoTranscription from '../../common/hooks/useVideoTranscription'

type VideoTranscriptionProps = {
  bucketKey: string
  bucket: string
}

const VideoTranscription = memo((props: VideoTranscriptionProps) => {
  const { transcription, error } = useVideoTranscription(props.bucket, props.bucketKey)

  const { t } = useTranslation('chat')

  if (error)
    return (
      <Typography
        variant={'body1'}
        sx={{
          fontSize: '12px',
          color: theme.palette.secondary.main,
          whiteSpace: 'pre-wrap',
        }}
      >
        {error}
      </Typography>
    )

  return (
    <Typography
      variant={'body1'}
      sx={{
        fontSize: '12px',
        color: theme.palette.secondary.main,
        whiteSpace: 'pre-wrap',
      }}
    >
      {transcription === ''
        ? t('chatBody.videoPostSummaryAI.noTranscriptionAvailableForThisVideo')
        : !transcription
        ? `${t('chatBody.videoPostSummaryAI.loadingTheEntireTranscription')}...`
        : transcription}
    </Typography>
  )
})

export default VideoTranscription
