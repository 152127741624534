import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { UpdateVideoMutation, UpdateVideoMutationVariables } from '../../../../../API'
import gql from 'graphql-tag'
import { updateVideo } from '../../../../../graphql/mutations'
import CognitoApolloClient from '../../../../../common/clients/CognitoApolloClient'
import { useForm } from 'react-hook-form'

interface INewVideoForm {
  title: string
}

const useVideoDescription = (title: string | null | undefined, videoId: string | undefined) => {
  const [editState, setEditState] = useState({ edit: false, update: false })

  const form = useForm<INewVideoForm>({
    mode: 'onChange',
    defaultValues: {
      title: '',
    },
  })

  useEffect(() => {
    if (!videoId) return
    form.reset({ title: title || '' })
  }, [videoId, title])

  const [updateAction] = useMutation<UpdateVideoMutation, UpdateVideoMutationVariables>(gql(updateVideo), {
    client: CognitoApolloClient,
  })

  const disableEditing = () => {
    form.reset({
      title: title || '',
    })
    setEditState({ edit: false, update: false })
  }

  const enableEditing = () => {
    setEditState({ edit: true, update: false })
  }

  const submit = form.handleSubmit(async (values) => {
    if (!videoId) return
    const { title = '' } = values
    setEditState({ edit: false, update: true })

    try {
      await updateAction({
        variables: { input: { id: videoId, title, searchTitle: title.toLowerCase() } },
      })
    } catch (err: any) {
      console.error(err)
    }

    setEditState({ edit: false, update: false })
  })

  return {
    form: {
      ...form,
      submit,
      editState,
      enableEditing,
      disableEditing,
    },
  }
}

export default useVideoDescription
