import * as React from 'react'
import { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import FromLibraryIcon from '../../../../../../../../common/icons/FromLibraryIcon'
import { theme } from '../../../../../../../../common/providers/MuiThemeProvider'
import Button from '../../../../../../../../components/_refactored/Button'
import Icon from '../../../../../../../../common/icons'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useChatDispatch } from '../../../common/contexts/ChatContext'
import ToastContext from '../../../../../../../../common/providers/ToastProvider/ToastContext'

interface ISubmitPostActionsProps {
  handleSubmit: () => void
  openNewVideoModal?: () => void
  openLibraryModal?: () => void
  isVideoFromLinkLoading: boolean
  videoPlayerEditor?: boolean | null
}
export const SubmitPostActions = (props: ISubmitPostActionsProps) => {
  const auth = useContext(AuthContext)
  const toastContext = useContext(ToastContext)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation('chat')
  const chatContextDispatcher = useChatDispatch()

  const inputRef = useRef<HTMLInputElement>(null)

  const handleAttachFileButtonClick = () => {
    inputRef.current?.click()
    chatContextDispatcher({
      type: 'setResponseBoxData',
      value: null,
    })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    if (file.size > 157286400) {
      toastContext.open({ type: 'ERROR', text: t('chatBody.fileUpload.sizeError') })
      return
    }
    chatContextDispatcher({
      type: 'setResponseBoxData',
      value: {
        type: 'file',
        file: file,
        fileName: e.target.files?.[0].name,
        fileType: e.target.files?.[0].type,
      },
    })
  }
  if (props.videoPlayerEditor) {
    return <></>
  }

  return (
    <Box
      style={{
        opacity: props.isVideoFromLinkLoading ? 0.3 : 1,
        pointerEvents: props.isVideoFromLinkLoading ? 'none' : 'all',
      }}
      display="flex"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
      padding="10px"
      className="editor--action-bar"
    >
      {isMobile && auth.isAuthenticated ? (
        <IconButton
          onClick={props.openLibraryModal}
          style={{
            boxShadow: 'none',
            padding: 0,
            width: '160px',
          }}
        >
          <Box display="flex" width="160px" alignItems="center">
            <FromLibraryIcon />
            <Typography style={{ paddingLeft: '12px', color: theme.palette.primary.main }}>
              {t('chatBody.addFromLibraryBtn')}
            </Typography>
          </Box>
        </IconButton>
      ) : !isMobile && auth.isAuthenticated ? (
        <Box height="40px">
          <Button
            variant="contained"
            color="primary"
            onClick={props.openNewVideoModal}
            style={{ width: '40px', minWidth: '40px' }}
          >
            <Icon.SmallCamera />
          </Button>
          <IconButton
            onClick={handleAttachFileButtonClick}
            sx={{ boxShadow: 'none', marginLeft: '16px', transform: 'rotate(45deg)' }}
          >
            <AttachFileIcon />
          </IconButton>
          <input
            ref={inputRef}
            onChange={handleFileChange}
            accept=".doc, .docx, .xlsx, .xls, application/pdf, image/jpeg, image/png, .gif"
            type="file"
            // @ts-ignore
            onClick={(e) => (e.target.value = null)}
            style={{ display: 'none' }}
          />
        </Box>
      ) : (
        <Box height="32px">
          <IconButton
            onClick={handleAttachFileButtonClick}
            sx={{ boxShadow: 'none', marginLeft: '0', transform: 'rotate(45deg)' }}
          >
            <AttachFileIcon />
          </IconButton>
          <input
            ref={inputRef}
            onChange={handleFileChange}
            accept=".doc, .docx, .xlsx, .xls, application/pdf, image/jpeg, image/png, .gif"
            type="file"
            // @ts-ignore
            onClick={(e) => (e.target.value = null)}
            style={{ display: 'none' }}
          />
        </Box>
      )}
      <Box position="relative">
        <IconButton className="chat__send-btn" onClick={props.handleSubmit}>
          <SendRoundedIcon style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Box>
    </Box>
  )
}
export default SubmitPostActions
