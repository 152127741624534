import * as React from 'react'
import { Box } from '@mui/material'

interface IDownloadIconProps {
  color?: string
}

const DownloadIcon = (props: IDownloadIconProps) => {
  return (
    <Box display="flex">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 0C8.568 0 8.21739 0.350609 8.21739 0.782609V10.2503L6.81649 8.8395C6.51245 8.53311 6.01622 8.53125 5.70983 8.83568C5.40305 9.14051 5.40081 9.63556 5.70525 9.94234L8.44438 12.7013C8.59112 12.8493 8.79144 12.9329 9 12.9329C9.20856 12.9329 9.40888 12.8493 9.55562 12.7013L12.2948 9.94234C12.5992 9.63556 12.5969 9.14012 12.2902 8.83568C11.9834 8.53086 11.4876 8.53272 11.1835 8.8395L9.78261 10.2503V0.782609C9.78261 0.350609 9.432 0 9 0ZM2.34783 4.69565C1.053 4.69565 0 5.74865 0 7.04348V15.6522C0 16.947 1.053 18 2.34783 18H15.6522C16.947 18 18 16.947 18 15.6522V7.04348C18 5.74865 16.947 4.69565 15.6522 4.69565H10.9565V6.26087H15.6522C16.0838 6.26087 16.4348 6.61187 16.4348 7.04348V15.6522C16.4348 16.0838 16.0838 16.4348 15.6522 16.4348H2.34783C1.91622 16.4348 1.56522 16.0838 1.56522 15.6522V7.04348C1.56522 6.61187 1.91622 6.26087 2.34783 6.26087H7.04348V4.69565H2.34783Z"
          fill={props?.color || '#7A7A86'}
        />
      </svg>
    </Box>
  )
}

export default DownloadIcon
