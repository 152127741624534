import React from 'react'

const AccessOpenIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.125 8.85646C6.23689 8.85646 5.5 9.63383 5.5 10.5707V19.1422C5.5 20.0791 6.23689 20.8565 7.125 20.8565H16.875C17.7631 20.8565 18.5 20.0791 18.5 19.1422V10.5707C18.5 9.63383 17.7631 8.85646 16.875 8.85646C13 8.85658 12 8.85632 7.125 8.85646ZM7.125 10.5707H16.875V19.1422H7.125V10.5707Z"
        fill="#7A7A86"
      />
      <path
        d="M8.17125 3.84035C7.72255 4.2377 7.80284 4.93578 8.30246 5.26684C9.86352 6.30124 12.6501 8.10044 14.453 9.31895C14.9506 9.65525 15.6411 9.48971 15.8597 8.93037C18.0408 3.35132 12.7739 -0.235507 8.17125 3.84035ZM14.3647 8.33597C14.2091 8.73694 14.3164 9.20646 14.6679 9.45429L8.0297 5.10229C8.40735 5.36848 8.92281 5.2924 9.25714 4.97349C12.3224 2.04959 15.9091 4.35375 14.3647 8.33597Z"
        fill="#7A7A86"
      />
    </svg>
  )
}

export default AccessOpenIcon
