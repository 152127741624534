import React, { useContext } from 'react'
import RecorderStateContext from '../../../../../providers/RecorderStateProvider/RecorderStateContext'
import MediaRecorderHandler from 'common/providers/RecorderStateProvider/MediaRecorderHandler'
import { Box, Modal, Typography, useTheme } from '@mui/material'
import Button from '../../../../../../components/_refactored/Button'
import { useTranslation } from 'react-i18next'

const RecordingCountDown = () => {
  const { state: recorderState } = useContext(RecorderStateContext)
  const { t: g } = useTranslation('general')
  const theme = useTheme()

  return (
    <Modal open={true}>
      <Box
        bgcolor="rgba(20, 35, 61, 0.7)"
        height="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding="10px"
      >
        <Box
          bgcolor="background.default"
          justifyContent="center"
          width="77px"
          height="77px"
          borderRadius="173px"
          display="flex"
          alignItems="center"
          marginBottom="30px"
          marginTop="-68px"
        >
          <Typography variant="h1" style={{ fontSize: '38px' }} color="secondary">
            {recorderState.countdownProgress}
          </Typography>
        </Box>
        <Typography variant="h2" style={{ color: theme.palette.background.default, marginTop: '30px' }} align="center">
          {g('recordControl.countDownModal.title')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: '80px' }}
          onClick={() => MediaRecorderHandler.cancelRecording()}
        >
          {g('common.cancelBtn')}
        </Button>
      </Box>
    </Modal>
  )
}

export default RecordingCountDown
