import { Box, CircularProgress } from '@mui/material'
import * as React from 'react'
import { useContext } from 'react'
import SinglePost from '../blocks/SinglePost'
import usePostsList from '../../common/hooks/posts/usePostsList'
import InfiniteScroll from 'react-infinite-scroll-component'
import TimeDivider from '../components/PostList/TimeDivider'
import EmptySupportChat from '../components/EmptySupportChat'
import ListLoader from '../components/ListLoader'
import { screensightPost, VideoStatus } from '../../../../../../../API'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'
import NewMessagesBox from '../components/NewMessagesBox'
import moment from 'moment'
import { useLocation } from 'react-router'
import EmptyChatPostList from '../components/EmptyChatPostList'
import sv from '../../../../../bal.png'
import { createPortal } from 'react-dom'
import { TUseChatParticipant } from '../../../../../common/hooks/useChatParticipants'
import '../../common/styles/postList.scss'

const PostsList = (props: TUseChatParticipant) => {
  const state = usePostsList()
  const auth = useContext(AuthContext)
  const location = useLocation()
  const list = [...(state.items || [])]
    ?.sort(function (a, b) {
      if (!a?.createdAt || !b?.createdAt) return 0
      return b?.createdAt < a?.createdAt ? -1 : b?.createdAt > a?.createdAt ? 1 : 0
    })
    ?.filter((post) => post?.channelId === state.channelId)

  if (state.listPostDataLoading || state.items === undefined) {
    return <ListLoader />
  }

  if (!state.items || state.items?.length === 0)
    return (
      <Box margin="auto">
        {location.pathname.startsWith('/app/chat') ? <EmptyChatPostList /> : <EmptySupportChat />}
      </Box>
    )
  const lastReceivedPostId = list?.find((post) => post?.customerId !== state.userId)?.id
  return (
    <Box
      className={'scrollable__postList'}
      width="100%"
      height="100%"
      padding="0 20px 20px 20px"
      display="flex"
      flexDirection="column-reverse"
      style={{ overflowY: 'auto' }}
      id="chat__scrollable-box"
      position="relative"
    >
      {state.newMessagesInfo && (
        <Box width="100%" height="50px" display="flex" justifyContent="center" bgcolor="primary.main">
          <NewMessagesBox onClick={state.handleScrollToBottom} />
        </Box>
      )}
      <InfiniteScroll
        style={{
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column-reverse',
          position: 'relative',
        }}
        dataLength={state.postsCount}
        inverse
        next={state.loadNextPosts}
        hasMore={state.hasMore}
        scrollableTarget="chat__scrollable-box"
        loader={
          <Box width="100px" margin="15px auto" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        }
      >
        {list?.map((post, key) => {
          const mergedInside =
            key < ((list?.length || 0) - 1 || 0) && key > 0 && post?.postType === 'text'
              ? list?.[key + 1]?.customerId === post?.customerId &&
                list?.[key + 1]?.postType === 'text' &&
                list?.[key - 1]?.customerId === post?.customerId &&
                list?.[key - 1]?.postType === 'text' &&
                Math.abs(
                  moment.duration(moment(post?.createdAt).diff(moment(list?.[key + 1]?.createdAt))).as('minutes'),
                ) < 15 &&
                Math.abs(
                  moment.duration(moment(post?.createdAt).diff(moment(list?.[key - 1]?.createdAt))).as('minutes'),
                ) < 15
              : false

          const lastMerged =
            !mergedInside && key > 0 && post?.postType === 'text'
              ? list?.[key - 1]?.customerId === post?.customerId &&
                list?.[key - 1]?.postType === 'text' &&
                Math.abs(
                  moment.duration(moment(post?.createdAt).diff(moment(list?.[key - 1]?.createdAt))).as('minutes'),
                ) < 15
              : false

          const firstMerged =
            !mergedInside && key < ((list?.length || 0) - 1 || 0) && post?.postType === 'text'
              ? list?.[key + 1]?.customerId === post?.customerId &&
                list?.[key + 1]?.postType === 'text' &&
                Math.abs(
                  moment.duration(moment(post?.createdAt).diff(moment(list?.[key + 1]?.createdAt))).as('minutes'),
                ) < 15
              : false
          const isLastPost = post?.id === lastReceivedPostId
          return state.deletedPostsIds?.includes(post?.id || '') ? (
            <></>
          ) : (
            <React.Fragment key={post?.id || key}>
              <SinglePost
                key={post?.id || key}
                owner={post?.customerId === state.userId}
                participantData={props.participantData}
                post={post as screensightPost}
                lastSeen={auth.isAuthenticated && state.lastSeen === post?.id}
                lastUnseen={auth.isAuthenticated && state.lastUnseen === post?.id}
                mergedInside={mergedInside}
                firstMerged={firstMerged}
                lastMerged={lastMerged}
                isLastPost={isLastPost}
              />
              <TimeDivider
                hidden={post?.postType === 'video' && post?.video?.status !== VideoStatus.ok}
                currentDate={post?.createdAt || ''}
                prevDate={list?.[key + 1]?.createdAt || ''}
                isLastDate={key === (list?.length || 0) - 1}
              />
            </React.Fragment>
          )
        })}
      </InfiniteScroll>
      {state.visualEffectsVisible &&
        createPortal(<img src={sv} className="balloons-anim" alt="balloons" />, document.body)}
    </Box>
  )
}

export default PostsList
