import awsmobile from '../../aws-exports'
import { Storage } from 'aws-amplify'
import { escapeBtoaString } from './escapeBtoaString'

export const getCloudFrontUrl = async (key: string, avoidCloudFront?: boolean) => {
  if (avoidCloudFront) {
    return await Storage.get(key, {
      customPrefix: {
        public: '',
      },
    })
  }
  const imageRequest = JSON.stringify({
    bucket: awsmobile.aws_user_files_s3_bucket,
    key: key,
  })

  return ` https://${process.env.REACT_APP_CLOUDFRONT_DOMAIN_NAME}/${btoa(escapeBtoaString(imageRequest))}`
}
