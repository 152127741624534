import * as React from 'react'

type Props = {}
const FlagES = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_16376_376400)">
        <path
          d="M18.6509 5H1.34839C3.07839 2.014 6.30089 0 9.99989 0C13.6989 0 16.9214 2.014 18.6509 5ZM9.99989 20C13.6989 20 16.9214 17.986 18.6509 15H1.34889C3.07889 17.986 6.30089 20 9.99989 20Z"
          fill="url(#paint0_linear_16376_376400)"
        />
        <path
          d="M20 10C20 8.1765 19.504 6.472 18.6515 5H1.3485C0.496 6.472 0 8.1765 0 10C0 11.8235 0.496 13.528 1.349 15H18.6515C19.504 13.528 20 11.8235 20 10Z"
          fill="url(#paint1_linear_16376_376400)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_16376_376400"
          x1="2.87689"
          y1="2.877"
          x2="16.9709"
          y2="16.971"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F44F5A" />
          <stop offset="0.443" stop-color="#EE3D4A" />
          <stop offset="1" stop-color="#E52030" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16376_376400"
          x1="3.0335"
          y1="3.0335"
          x2="16.8775"
          y2="16.877"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FEDE00" />
          <stop offset="1" stop-color="#FFD000" />
        </linearGradient>
        <clipPath id="clip0_16376_376400">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FlagES
