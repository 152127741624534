import { useForm } from 'react-hook-form'
import { useContext, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  CreateGuestCustomerMutation,
  CreateGuestCustomerMutationVariables,
  screensightVideo,
  VerifyProtectedVideoPasswordQuery,
  VerifyProtectedVideoPasswordQueryVariables,
} from '../../../../../API'
import gql from 'graphql-tag'
import CognitoApolloClient from '../../../../../common/clients/CognitoApolloClient'
import { verifyProtectedVideoPassword } from '../../../../../graphql/queries'
import IamApolloClient from '../../../../../common/clients/IamApolloClient'
import { AuthContext } from '../../../../../common/providers/AuthStatusProvider'
import { useHistory } from 'react-router-dom'
import ToastContext from '../../../../../common/providers/ToastProvider/ToastContext'
import { createGuestCustomer } from '../../../../../graphql/mutations'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Analytics } from 'aws-amplify'
import useGetVideoFragment from '../../../../../common/api/hooks/useGetVideoFragment'

export interface IVideoAccessModalHookForm {
  password: string
  name?: string
}

const useVideoAccessModalHook = (videoId: string | undefined, onSuccess?: (data: screensightVideo) => any) => {
  const cacheVideo = useGetVideoFragment(videoId, '{accessId}')
  const auth = useContext(AuthContext)
  const { t } = useTranslation('library')
  const history = useHistory()
  const { id: videoParamId } = useParams() as { id: string }
  const [isPasswordShowed, setIsPasswordShowed] = useState(false)
  const toastContext = useContext(ToastContext)
  const [loading, setLoading] = useState(false)

  const form = useForm<IVideoAccessModalHookForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      name: '',
    },
  })

  const { refetch: checkVerifyAccess } = useQuery<
    VerifyProtectedVideoPasswordQuery,
    VerifyProtectedVideoPasswordQueryVariables
  >(gql(verifyProtectedVideoPassword), {
    skip: true,
    client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
  })

  const [createGuestAccount] = useMutation<CreateGuestCustomerMutation, CreateGuestCustomerMutationVariables>(
    gql(createGuestCustomer),
    {
      client: IamApolloClient,
    },
  )

  const togglePasswordVisible = () => {
    setIsPasswordShowed((prevState) => !prevState)
  }

  const handleVerifyAccess = form.handleSubmit(async (values: IVideoAccessModalHookForm) => {
    if (!cacheVideo?.accessId) return
    try {
      setLoading(true)
      const verifyRes = await checkVerifyAccess({
        accessId: cacheVideo?.accessId,
        password: values.password,
      })
      if (!verifyRes.data.verifyProtectedVideoPassword) {
        toastContext.open({ type: 'ERROR', text: t('videoItem.videoAccessModal.accessError') })
        history.replace('/')
        return
      }
      if (onSuccess) onSuccess(verifyRes?.data.verifyProtectedVideoPassword.video as screensightVideo)
      if (!localStorage.getItem('guestCustomerId') && values?.name) {
        const guestCustomerRes = await createGuestAccount({ variables: { input: { firstName: values.name } } })
        if (guestCustomerRes.data?.createGuestCustomer?.id) {
          localStorage.setItem('guestCustomerId', guestCustomerRes.data?.createGuestCustomer?.id)
          await Analytics.updateEndpoint({ userId: guestCustomerRes.data?.createGuestCustomer?.id })
          Analytics.autoTrack('session', { enable: true })
        }
      }
    } catch (e: any) {
      console.log('Error during verifying video protection', e)
      toastContext.open({ type: 'ERROR', text: t('videoItem.videoAccessModal.passwordError') })
    } finally {
      setLoading(false)
    }
  })

  const handleSignInPush = () => {
    history.push({ pathname: '/login', state: { videoParamId } })
  }

  return {
    form,
    isPasswordShowed,
    togglePasswordVisible,
    loading,
    handleVerifyAccess,
    authorized: !auth.user?.pending && auth.isAuthenticated,
    handleSignInPush,
  }
}

export default useVideoAccessModalHook
