import React from 'react'

interface IFileIconProps {
  color?: string
}
const FileIcon = (props: IFileIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      data-testid="file-icon"
    >
      <path
        d="M6 2.25C5.17157 2.25 4.5 2.92157 4.5 3.75V20.25C4.5 21.0784 5.17157 21.75 6 21.75H18C18.8284 21.75 19.5 21.0784 19.5 20.25V8.13169C19.5 7.53495 19.2629 6.96265 18.841 6.5407L15.2093 2.90901C14.7873 2.48705 14.215 2.25 13.6183 2.25H6ZM6 3.75H13.5V6.75C13.5 7.57843 14.1716 8.25 15 8.25H18V20.25H6V3.75ZM15 4.80029L16.9497 6.75H15V4.80029ZM8.25 9.75C7.83579 9.75 7.5 10.0858 7.5 10.5V10.5C7.5 10.9142 7.83579 11.25 8.25 11.25H15.75C16.1642 11.25 16.5 10.9142 16.5 10.5V10.5C16.5 10.0858 16.1642 9.75 15.75 9.75H8.25Z"
        fill={props.color || '#2483F7'}
      />
      <path
        d="M13.75 14.625H8.25C7.83579 14.625 7.5 14.2892 7.5 13.875C7.5 13.4608 7.83579 13.125 8.25 13.125H13.75C14.1642 13.125 14.5 13.4608 14.5 13.875C14.5 14.2892 14.1642 14.625 13.75 14.625Z"
        fill={props.color || '#2483F7'}
      />
      <path
        d="M10.75 18H8.25C7.83579 18 7.5 17.6642 7.5 17.25C7.5 16.8358 7.83579 16.5 8.25 16.5H10.75C11.1642 16.5 11.5 16.8358 11.5 17.25C11.5 17.6642 11.1642 18 10.75 18Z"
        fill={props.color || '#2483F7'}
      />
    </svg>
  )
}

export default FileIcon
