import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { useNetworkState } from 'react-use'
import ErrorIcon from '@mui/icons-material/Error'
import { theme } from '../../../../../providers/MuiThemeProvider'
import { useTranslation } from 'react-i18next'

const NetworkStatusInfo = () => {
  const networkState = useNetworkState()
  const { t: g } = useTranslation('general')

  if (networkState.online) {
    return <></>
  }

  return (
    <Box
      display="flex"
      zIndex={9000}
      width="100%"
      position="fixed"
      top={0}
      padding={'4px 15px'}
      justifyContent="space-between"
      alignItems="center"
      style={{
        boxShadow: '0 0 6px #4f4f4f',
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <ErrorIcon style={{ fill: theme.palette.background.default, width: '15px', height: '15px' }} />
      <Typography style={{ fontSize: '13px', color: theme.palette.background.default }}>
        {g('common.internetConnectionLost')}
      </Typography>
      <IconButton style={{ width: '15px', height: '15px', visibility: 'hidden' }} />
    </Box>
  )
}

export default NetworkStatusInfo
