import React from 'react'
import useBranding from './hooks/useBranding'
import BrandingView from './view/Branding.view'
import PageTitle from '../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

const Branding = () => {
  const state = useBranding()
  const { t: g } = useTranslation('general')

  return (
    <>
      <PageTitle name={g('pageName.brandingSettings')} />
      <BrandingView {...state} />
    </>
  )
}

export default Branding
