import * as React from 'react'
import { Box, Typography } from '@mui/material'
import CloudFrontImage from '../../../../../../../components/_refactored/CloudFrontImage'
import { getUsername } from '../../../../../../../common/utils/getUsername'
import { screensightCustomer } from '../../../../../../../API'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import ScheduleMeetingBtn from '../components/ScheduleMeetingBtn'

type Props = {
  partner: screensightCustomer | null | undefined
}
const PartnerInfo = (props: Props) => {
  const { t } = useTranslation('chat')

  const userName = getUsername(props.partner?.firstName, props.partner?.lastName, props.partner?.email)

  const lastSeen =
    new Date(props.partner?.lastSeen as string).getDay() === new Date().getDay()
      ? moment(props.partner?.lastSeen).calendar()
      : moment(props.partner?.lastSeen).format('LLL')

  return (
    <Box
      position="relative"
      alignItems="center"
      borderBottom={'1px solid rgba(122, 122, 134, 0.3)'}
      paddingBottom="20px"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="10px">
        {props.partner?.profilePhoto?.key ? (
          <CloudFrontImage
            image={`public/${props.partner?.profilePhoto?.key}`}
            height="40"
            width="40"
            imgStyle={{ borderRadius: '100px' }}
          />
        ) : (
          <Box
            bgcolor="primary.main"
            width="40px"
            height="40px"
            borderRadius="100px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body2" style={{ color: 'white' }}>
              {userName?.toUpperCase()?.charAt(0)}
            </Typography>
          </Box>
        )}
        {props.partner?.calendlyUrl && <ScheduleMeetingBtn calendlyUrl={props.partner.calendlyUrl} />}
      </Box>
      <Typography
        style={{
          fontWeight: 500,
          width: '99%',
          fontSize: '16px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {userName}
      </Typography>
      {props?.partner?.lastSeen && (
        <Typography
          style={{
            fontSize: '12px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          color="textSecondary"
        >
          {t('partnerSidebar.lastSeen')} {lastSeen}
        </Typography>
      )}
    </Box>
  )
}

export default PartnerInfo
