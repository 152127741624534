import gql from 'graphql-tag'

import CognitoApolloClient from 'common/clients/CognitoApolloClient'
import { DeleteVideoMutation, DeleteVideoMutationVariables } from 'API'
import { deleteVideo } from 'graphql/mutations'

import { SchedulerItem } from './SchedulerItem'
import { UploadScheduler } from './UploadScheduler'
import { RecordingIds } from './index'
import { captureException } from '@sentry/react'

export class RecordingDeleteEntity extends SchedulerItem {
  ids: RecordingIds

  constructor(ids: RecordingIds, scheduler: UploadScheduler) {
    super(scheduler)
    this.ids = { ...ids }
  }

  async singleUpload() {
    await CognitoApolloClient.mutate<DeleteVideoMutation, DeleteVideoMutationVariables>({
      mutation: gql(deleteVideo),
      variables: {
        input: {
          id: this.ids.entityId,
        },
      },
    })
  }

  setError() {
    captureException(new Error('Delete entity request error'), {
      tags: {
        entityId: this.ids.entityId,
        localId: this.ids.localId,
      },
    })
    super.setError()
  }

  log(...args: any[]) {
    console.log(new Date().toISOString(), `[RecordingDeleteEntity]:`, ...args)
  }
}
