import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import { useParams } from 'react-router'
import { useChatDispatch } from '../../contexts/ChatContext'
import { useMutation } from '@apollo/client'
import { CreatePostMutation, CreatePostMutationVariables, VideoStatus } from '../../../../../../../../API'
import gql from 'graphql-tag'
import { createPost } from '../../../../../../../../common/api/graphql/mutations'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import { Storage } from '@aws-amplify/storage'
import moment from 'moment/moment'
import { updateFileAttachmentProgress } from '../../../../../../common/redux/store/chat-actions'
import { useDispatch } from 'react-redux'

const useSendFilePost = () => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const chatDispatcher = useChatDispatch()
  const dispatch = useDispatch()

  const progressCallback = (progress: any, postId?: string) => {
    if (!postId) return
    dispatch(updateFileAttachmentProgress(progress.loaded / progress.total, postId))
  }

  const [createPostMutation] = useMutation<CreatePostMutation, CreatePostMutationVariables>(gql(createPost), {
    client: !auth.user?.pending && auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
    update(cache, { data }) {
      cache.modify({
        fields: {
          listPostsByChannel(existingItems = {}) {
            const newTodoRef = cache.writeFragment({
              data: data?.createPost,
              fragment: gql`
                fragment newPost on ScreensightPost {
                  id
                  postType
                  channelId
                  customerId
                  comment
                  accessId
                  readHorizon
                  referencePost
                  referencePostId
                  createdAt
                  updatedAt
                  text
                  file {
                    fileName
                    fileObject {
                      key
                      bucket
                      accessLevel
                      identityId
                    }
                    status
                    customerId
                    mimeType
                    metadata
                  }
                }
              `,
            })

            return {
              ...existingItems,
              items: [newTodoRef, ...existingItems.items],
            }
          },
        },
      })
    },
  })

  const sendFilePost = async (file: File, filename: string, customerId: string, metadata?: any) => {
    const tempDate = moment(new Date()).add(1, 's').toISOString()
    chatDispatcher({ type: 'setResponseBoxData', value: undefined })
    const res = await createPostMutation({
      variables: {
        input: {
          file: {
            channelId: channelId || '',
            customerId,
            metadata,
          },
        },
      },
      optimisticResponse: {
        createPost: {
          id: 'temp-id',
          __typename: 'screensightPost',
          postType: 'file',
          channelId,
          customerId:
            auth.user?.id || sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId'),
          comment: null,
          accessId: null,
          video: null,
          emailMessage: null,
          engagement: null,
          postTempId: null,
          readHorizon: null,
          referencePost: null,
          referencePostId: null,
          createdAt: tempDate,
          updatedAt: tempDate,
          reactions: null,
          file: {
            __typename: 'FilePost',
            customerId:
              auth.user?.id || sessionStorage.getItem('guestCustomerId') || localStorage.getItem('guestCustomerId'),
            fileName: null,
            fileObject: null,
            metadata: null,
            mimeType: null,
            status: VideoStatus.init,
          },
          text: null,
        },
      },
    })
    await Storage.put(`files/${res.data?.createPost?.id}/${filename}`, file, {
      customPrefix: {
        public: 'uploads/',
      },
      useAccelerateEndpoint: true,
      progressCallback: (progress: any) => progressCallback(progress, res.data?.createPost?.id),
    })
  }

  return [sendFilePost]
}

export default useSendFilePost
