import React from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import PlanCard from '../components/PlanCard'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import useGetUserPayment from '../../../billing/common/useGetUserPayment'
import { useHistory } from 'react-router-dom'
import FullScreenLoader from '../../../../../../components/_refactored/Loaders/FullScreenLoader'
import { useTranslation } from 'react-i18next'

const Content = () => {
  const state = useGetUserPayment()
  const history = useHistory()
  const { t } = useTranslation('settings')

  if (state.loading) return <FullScreenLoader withoutSidebar />

  return (
    <Box position="relative">
      <IconButton
        style={{
          position: 'absolute',
          left: '0',
          top: '0',
          boxShadow: 'none',
        }}
        onClick={() => history.push('/app/settings/billing')}
      >
        <ArrowBackIosIcon style={{ marginLeft: '6px' }} />
      </IconButton>
      <Typography
        variant="h2"
        align="center"
        style={{ marginTop: '100px', fontSize: '20px', fontWeight: 500, marginBottom: '60px' }}
      >
        {t('billing.explorePlans.title')}
      </Typography>
      <Grid container spacing={2} style={{ padding: '12px' }}>
        <Grid item xs={12} md={6} lg={4} style={{ marginBottom: '32px' }}>
          <PlanCard
            planName={t('billing.explorePlans.enterprisePlan.name')}
            price={t('billing.explorePlans.enterprisePlan.price')}
            priceInfo={t('billing.explorePlans.enterprisePlan.priceInfo')}
            description={t('billing.explorePlans.enterprisePlan.description')}
            featuresTitle={t('billing.explorePlans.enterprisePlan.featuresTitle')}
            features={[
              t('billing.explorePlans.enterprisePlan.feature1'),
              t('billing.explorePlans.enterprisePlan.feature2'),
              t('billing.explorePlans.enterprisePlan.feature3'),
              t('billing.explorePlans.enterprisePlan.feature4'),
            ]}
            ctaName={t('billing.explorePlans.enterprisePlan.ctaNameBtn')}
            isCurrentPlan={state.currentPlan === 'ENTERPRISE'}
            ctaLink="mailto:sales@screensight.com"
            // ctaLink="/app/settings/billing/contact-sales"
            hideMonthSuffix
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4} style={{ marginBottom: '32px' }}>
          <PlanCard
            pricePlan={state.proPlan}
            planName={t('billing.explorePlans.professionalPlan.name')}
            price={`$${state.prices.professional}`}
            priceInfo={t('billing.explorePlans.professionalPlan.priceInfo')}
            description={t('billing.explorePlans.professionalPlan.description')}
            featuresTitle={t('billing.explorePlans.professionalPlan.featuresTitle')}
            features={[
              t('billing.explorePlans.professionalPlan.feature1'),
              t('billing.explorePlans.professionalPlan.feature2'),
              t('billing.explorePlans.professionalPlan.feature3'),
              t('billing.explorePlans.professionalPlan.feature4'),
            ]}
            ctaName={t('billing.explorePlans.professionalPlan.ctaNameBtn')}
            ctaLink="/app/settings/billing/plans/professional"
            isRecommended={t('billing.explorePlans.professionalPlan.mostPopular')}
            isCurrentPlan={state.currentPlan?.toLowerCase().includes('professional')}
            hasPaymentAttached={state.hasPaymentAttached}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4} style={{ marginBottom: '32px' }}>
          <PlanCard
            planName={t('billing.explorePlans.freePlan.name')}
            price={t('billing.explorePlans.freePlan.price')}
            priceInfo={t('billing.explorePlans.freePlan.priceInfo')}
            description={t('billing.explorePlans.freePlan.description')}
            featuresTitle={t('billing.explorePlans.freePlan.featuresTitle')}
            features={[
              t('billing.explorePlans.freePlan.feature1'),
              t('billing.explorePlans.freePlan.feature2'),
              t('billing.explorePlans.freePlan.feature3'),
              t('billing.explorePlans.freePlan.feature4'),
            ]}
            ctaName={t('billing.explorePlans.freePlan.ctaNameBtn')}
            isCurrentPlan={state.currentPlan?.toLowerCase().includes('free')}
            ctaLink="/app/settings/billing/plans/free"
            loading={state.loadingSubscriptionCancel}
            onSubmit={state.cancelPricePlanToFree}
            hasPaymentAttached={state.hasPaymentAttached}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Content
