import React from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import AllItemsIcon from '../components/AllItemsIcon'
import VideosIcon from '../components/VideosIcon'
import FilesWidgetIcon from '../components/FilesWidgetIcon'
import { PartnerSidebarCurrentView } from '../../common/types/PartnerSidebarCurrentView'
import { useTranslation } from 'react-i18next'
import { updateRightSidePanelParams } from '../../../../../common/redux/store/chat-actions'
import ArrowLeftOutline from '../../../../../../../common/icons/ArrowLeftOutline'
import ArrowRightOutline from '../../../../../../../common/icons/ArrowRightOutline'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../common/redux/store/store'

interface IWidgetsList {
  currentView: PartnerSidebarCurrentView
  handleChangeCurrentView: (view: PartnerSidebarCurrentView) => void
}
const WidgetsList = (props: IWidgetsList) => {
  const dispatch = useDispatch()
  const sidebarParams = useSelector((state: RootState) => state.chat.rightSidePanelParams)
  const { t } = useTranslation('chat')

  return (
    <Box width="44px" height="100%" padding="20px 5px 0 5px" boxShadow="0px 0px 3px rgba(0, 0, 0, 0.1)">
      <IconButton
        sx={{
          width: '34px',
          height: '34px',
          borderRadius: '6px',
          boxShadow: 'none',
          padding: '0',
          marginBottom: '14px',
          '&:hover': { background: '#2483F726' },
        }}
        onClick={() =>
          sidebarParams.width < 250
            ? dispatch(updateRightSidePanelParams({ width: 294, height: '100vh', x: window.innerWidth - 310, y: 0 }))
            : dispatch(updateRightSidePanelParams({ width: 44, height: '100vh', x: window.innerWidth - 44, y: 0 }))
        }
      >
        {sidebarParams.width < 250 ? <ArrowLeftOutline /> : <ArrowRightOutline />}
      </IconButton>
      <Tooltip title={t('partnerSidebar.all')} placement="left">
        <IconButton
          sx={{
            width: '34px',
            height: '34px',
            borderRadius: '6px',
            boxShadow: 'none',
            padding: '0',
            background: props.currentView === 'media' ? '#2483F726' : 'transparent',
            marginBottom: '14px',
            '&:hover': { background: '#2483F726' },
          }}
          onClick={() => {
            props.handleChangeCurrentView('media')
            if (sidebarParams.width === 44) {
              dispatch(updateRightSidePanelParams({ width: 294, height: '100vh', x: window.innerWidth - 310, y: 0 }))
            }
          }}
        >
          <AllItemsIcon color={props.currentView === 'media' ? '#2483F7' : undefined} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('partnerSidebar.videos')} placement="left">
        <IconButton
          sx={{
            width: '34px',
            height: '34px',
            borderRadius: '6px',
            boxShadow: 'none',
            padding: '0',
            background: props.currentView === 'videos' ? '#2483F726' : 'transparent',
            marginBottom: '14px',
            '&:hover': { background: '#2483F726' },
          }}
          onClick={() => {
            props.handleChangeCurrentView('videos')
            if (sidebarParams.width === 44) {
              dispatch(updateRightSidePanelParams({ width: 294, height: '100vh', x: window.innerWidth - 310, y: 0 }))
            }
          }}
        >
          <VideosIcon color={props.currentView === 'videos' ? '#2483F7' : undefined} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('partnerSidebar.files')} placement="left">
        <IconButton
          sx={{
            width: '34px',
            height: '34px',
            borderRadius: '6px',
            boxShadow: 'none',
            padding: '0',
            background: props.currentView === 'files' ? '#2483F726' : 'transparent',
            marginBottom: '14px',
            '&:hover': { background: '#2483F726' },
          }}
          onClick={() => {
            props.handleChangeCurrentView('files')
            if (sidebarParams.width === 44) {
              dispatch(updateRightSidePanelParams({ width: 294, height: '100vh', x: window.innerWidth - 310, y: 0 }))
            }
          }}
        >
          <FilesWidgetIcon color={props.currentView === 'files' ? '#2483F7' : undefined} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default WidgetsList
