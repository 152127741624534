import React from 'react'
import ReactCodeInput from 'react-code-input'
import '../../../common/style/CodeInput.scss'

interface IProps {
  handleUpdateValue: (e: string) => void
  value: string
  handleInputChangeForPinAnalytics: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

const CodeInput = ({ value, handleUpdateValue, handleInputChangeForPinAnalytics }: IProps) => {
  return (
    <div className="code-input__wrapper">
      <ReactCodeInput
        value={value}
        onChange={(e) => {
          handleUpdateValue(e)
          handleInputChangeForPinAnalytics({ target: { value: e } } as any)
        }}
        inputMode="numeric"
        name="code"
        fields={6}
      />
    </div>
  )
}

export default CodeInput
