import * as React from 'react'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SendMailIcon from '../../../../../common/icons/SendMailIcon'
import Button from '../../../Button'

interface ISendEmailBtnProps {
  sending: boolean
  emails: string[]
  sendEmail: () => void
  subject: string
  currentContactToAdd: string | undefined
  isError: boolean
  disabled?: boolean
}
export const SendEmailBtn = (props: ISendEmailBtnProps) => {
  const theme = useTheme()
  const { t } = useTranslation('library')

  return (
    <Button
      variant="contained"
      color="primary"
      loading={props.sending}
      disabled={
        !props.emails.length || !props.subject.length || !!props.currentContactToAdd || props.isError || props.disabled
      }
      onClick={props.sendEmail}
    >
      <SendMailIcon
        style={{ marginRight: '10px' }}
        color={
          !props.emails.length || !props.subject.length || props.currentContactToAdd
            ? theme.palette.secondary.main
            : theme.palette.background.default
        }
      />
      {t('videoItem.common.sendBtn')}
    </Button>
  )
}

export default SendEmailBtn
