import React from 'react'

const UploadIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_11123_270134)">
        <path
          d="M13.0009 12.586L17.2439 16.828L15.8289 18.243L14.0009 16.415V22H12.0009V16.413L10.1729 18.243L8.75787 16.828L13.0009 12.586ZM13.0009 2C14.7179 2.00008 16.3749 2.63111 17.6571 3.77312C18.9392 4.91512 19.757 6.48846 19.9549 8.194C21.1991 8.53332 22.2846 9.2991 23.0215 10.3575C23.7585 11.416 24.1 12.6997 23.9865 13.9844C23.873 15.2691 23.3116 16.473 22.4004 17.3858C21.4893 18.2986 20.2864 18.8622 19.0019 18.978V16.964C19.462 16.8983 19.9045 16.7416 20.3035 16.503C20.7024 16.2644 21.0498 15.9487 21.3254 15.5744C21.6011 15.2001 21.7993 14.7746 21.9087 14.3228C22.0181 13.8711 22.0364 13.402 21.9626 12.9431C21.8887 12.4841 21.7242 12.0445 21.4786 11.6498C21.233 11.2552 20.9112 10.9134 20.5321 10.6445C20.1529 10.3755 19.724 10.1848 19.2704 10.0834C18.8167 9.98203 18.3474 9.97203 17.8899 10.054C18.0465 9.32489 18.038 8.56997 17.8651 7.84455C17.6922 7.11913 17.3592 6.44158 16.8905 5.86153C16.4218 5.28147 15.8293 4.81361 15.1563 4.49219C14.4834 4.17078 13.7471 4.00397 13.0014 4.00397C12.2556 4.00397 11.5193 4.17078 10.8464 4.49219C10.1735 4.81361 9.58097 5.28147 9.11227 5.86153C8.64358 6.44158 8.31058 7.11913 8.13765 7.84455C7.96473 8.56997 7.95626 9.32489 8.11287 10.054C7.20053 9.88267 6.25749 10.0808 5.49121 10.6048C4.72494 11.1287 4.1982 11.9357 4.02687 12.848C3.85554 13.7603 4.05366 14.7034 4.57763 15.4697C5.10161 16.2359 5.90853 16.7627 6.82087 16.934L7.00087 16.964V18.978C5.71632 18.8623 4.51328 18.2989 3.602 17.3862C2.69073 16.4735 2.1292 15.2696 2.01554 13.9848C1.90189 12.7001 2.24335 11.4163 2.98024 10.3578C3.71713 9.29926 4.80258 8.53339 6.04687 8.194C6.24458 6.48838 7.06228 4.91491 8.34445 3.77287C9.62662 2.63082 11.2838 1.99986 13.0009 2Z"
          fill="#7A7A86"
        />
      </g>
      <defs>
        <clipPath id="clip0_11123_270134">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UploadIcon
