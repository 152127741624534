import React from 'react'
import { Box, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { VideoListInputWrapper } from '../components/VideoListInputWrapper/VideoListInputWrapper'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import ScreensightLogo from '../../../common/icons/ScreensightLogo'
import NewVideoButton from '../../../pages/VideoList/ui/components/NewVideoButton'

interface IVideoListHeader {
  isMobile: boolean
  noVideos: boolean
  searchEvent: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined
  handleNewVideoBtnClick: () => void
  clearSearchPhrase: () => void
  searchPhrase: string
  searchValue: string
}

const VideoListHeader = (props: IVideoListHeader) => {
  const { t: g } = useTranslation('general')

  return (
    <Box
      position="fixed"
      left={'0'}
      top={'0px'}
      width={'100vw'}
      height={props.isMobile ? '132px' : '80px'}
      padding="20px"
      bgcolor={'background.default'}
      boxShadow="0px 0px 3px rgba(0, 0, 0, 0.1)"
      zIndex={1000}
      style={{ transition: 'top 0.6s' }}
      display={!props.noVideos ? '' : 'none'}
    >
      <Grid container direction={props.isMobile ? 'column-reverse' : 'row'} justifyContent="space-between">
        <Grid
          item
          style={{
            marginTop: props.isMobile ? '16px' : '',
            display: 'flex',
            width: '65%',
          }}
        >
          <ScreensightLogo width={'165px'} height={'37px'} />
          <VideoListInputWrapper>
            <TextField
              placeholder={g('common.searchInputPlaceholder')}
              variant="outlined"
              value={props.searchValue}
              onChange={props.searchEvent}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: '#7A7A86' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ width: '16px', height: '16px', display: props.searchPhrase ? 'inline-flex' : 'none' }}
                      onClick={props.clearSearchPhrase}
                    >
                      <CloseIcon style={{ color: '#7A7A86' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </VideoListInputWrapper>
        </Grid>
        <Grid justifyContent="flex-end">
          <NewVideoButton handleNewVideoBtnClick={props.handleNewVideoBtnClick} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default VideoListHeader
