import React from 'react'
import { Box, IconButton, InputAdornment, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material'
import { Controller } from 'react-hook-form'
import { VideoPrivacyForm } from '../hooks/useVideoPrivacyModal.hook'
import { UseFormMethods } from 'react-hook-form/dist/types/form'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Button from '../../../../components/_refactored/Button'
import { AccessType } from '../../../../API'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../../common/providers/MuiThemeProvider'

interface IProps {
  form: UseFormMethods<VideoPrivacyForm>
  togglePasswordVisible: () => void
  handleUpdatePrivacy: () => void
  isPasswordShowed: boolean
  loading: boolean
  togglePrivacyModalVisibility: () => void
  isOnVideoList: boolean
}

const VideoPrivacyModalView = (props: IProps) => {
  const { t } = useTranslation('library')
  const { t: g } = useTranslation('general')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      position="absolute"
      sx={{
        transform:
          props.isOnVideoList && !isMobile
            ? 'translate(-70px, -200px)'
            : props.isOnVideoList && isMobile
            ? 'translate(-80px, -150px)'
            : 'translate(0, 0)',
        padding: '12px',
        width: '250px',
        boxShadow: '0px 1px 7px rgb(0 0 0 / 11%)',
        backgroundColor: 'background.default',
        zIndex: 9,
        borderRadius: '10px',
        ...(!props.isOnVideoList && { top: '48px', left: '-140px' }),
      }}
    >
      <form>
        <Typography variant="body1" style={{ fontWeight: 500, fontSize: '14px', marginBottom: '6px' }}>
          {t('videoItem.videoPrivacyModal.videoTypeInputTitle')}
        </Typography>
        <Controller
          as={
            <Select fullWidth variant="outlined" disabled={props.loading}>
              {[
                { value: AccessType.public, label: t('videoItem.videoPrivacyModal.publicOption') },
                { value: AccessType.protected, label: t('videoItem.videoPrivacyModal.privateOption') },
              ].map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )
              })}
            </Select>
          }
          name={'type'}
          control={props.form.control}
          defaultValue={'public'}
        />
        {props.form.watch('type') !== 'public' && (
          <>
            <Typography variant="body1" style={{ fontWeight: 500, fontSize: '14px', marginTop: '20px' }}>
              {t('videoItem.videoPrivacyModal.providePasswordInputTitle')}
            </Typography>
            <Controller
              name={'videoPassword'}
              control={props.form.control}
              rules={{
                required: g('common.requiredField'),
                validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
              }}
              render={({ value, onChange, onBlur }) => (
                <TextField
                  id="videoPassword"
                  type={props.isPasswordShowed ? 'text' : 'password'}
                  value={value}
                  onChange={onChange}
                  disabled={props.loading}
                  onBlur={onBlur}
                  error={!!props.form.errors['videoPassword']}
                  helperText={props.form.errors['videoPassword']?.message}
                  variant="outlined"
                  fullWidth
                  label=""
                  className="test"
                  InputProps={{
                    style: {
                      fontSize: '16px',
                      marginTop: '8px',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="visibility"
                          style={{ boxShadow: 'none' }}
                          onClick={props.togglePasswordVisible}
                        >
                          {!props.isPasswordShowed ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </>
        )}

        <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="20px">
          <Button
            variant="text"
            color="secondary"
            disabled={props.loading}
            onClick={props.togglePrivacyModalVisibility}
          >
            {g('common.cancelBtn')}
          </Button>
          <Button variant="contained" color="primary" onClick={props.handleUpdatePrivacy} disabled={props.loading}>
            {g('common.saveBtn')}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default VideoPrivacyModalView
