import * as React from 'react'
import { Box, Skeleton } from '@mui/material'

export const PartnerInfoSkeleton = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom="10px">
        <Skeleton variant="circular" width="40px" height="40px" />
        <Skeleton variant="rectangular" width="133px" height="40px" sx={{ borderRadius: '100px' }} />
      </Box>
      <Skeleton variant="rectangular" width="100%" height="42px" sx={{ borderRadius: '5px', marginBottom: '20px' }} />
      <Skeleton variant="rectangular" width="100%" height="1px" />
    </>
  )
}

export default PartnerInfoSkeleton
