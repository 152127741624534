import { useLocation } from 'react-router'
import * as queryString from 'query-string'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addSeenVideoPostId } from '../../../../../../common/redux/store/chat-actions'

/*
 * This hook is workaround for the hiding blue bar for videos that we received from chat partners
 * It set redux state for the video post id that we received from chat partners
 */
const useVideoSeenStatus = (owner?: boolean) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const postIdQueryParam = queryString.parse(location.search)?.postId

  useEffect(() => {
    if (!postIdQueryParam || owner) return
    dispatch(addSeenVideoPostId(postIdQueryParam as string))
  }, [postIdQueryParam, owner])
}

export default useVideoSeenStatus
