import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CreateChannelMutationVariables,
  GetOrCreateCustomerMutation,
  GetOrCreateCustomerMutationVariables,
  screensightContact,
  SearchablescreensightContactSortableFields,
  SearchableSortDirection,
  SearchScreensightContactsQuery,
  SearchScreensightContactsQueryVariables,
} from '../../../../../../../API'
import gql from 'graphql-tag'
import { getOrCreateCustomer } from '../../../../../../../graphql/mutations'
import CognitoApolloClient from '../../../../../../../common/clients/CognitoApolloClient'
import ToastContext from '../../../../../../../common/providers/ToastProvider/ToastContext'
import { searchScreensightContacts } from '../../../../../../../graphql/queries'
import { getUsername } from '../../../../../../../common/utils/getUsername'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'
import { useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { refetchListChannels } from '../../../../../common/redux/store/chat-actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ReactMultiEmail } from 'react-multi-email'
import useAvailableEmails from '../../../../../../../components/_refactored/SendByEmailModal/AvailableEmailsModal/hooks/useAvailableEmails'
import { createChannel, CreateChannelMutation } from '../../../../../../../common/api/graphql/createChannel'

const useNewMessage = () => {
  const auth = useContext(AuthContext)
  const { t } = useTranslation('chat')
  const toastContext = useContext(ToastContext)
  const [emails, setEmails] = useState<string[]>()
  const history = useHistory()
  const elem: HTMLInputElement | null = document.querySelector('.react-multi-email input')
  const [createContactModalVisible, setCreateContactModalVisible] = useState(false)

  const [partnerId, setPartnerId] = useState('')
  const [loading, setLoading] = useState(false)
  const [availableContacts, setAvailableContacts] = useState<screensightContact[]>([])
  const [refetching, setRefetching] = useState(false)
  const dispatch = useDispatch()
  const inputRef = useRef<ReactMultiEmail | null>(null)
  const { handleSelectContact, handleNavigationInsideTheList } = useAvailableEmails(
    setEmails,
    // @ts-ignore
    setAvailableContacts,
    availableContacts,
    inputRef,
  )

  useEffect(() => {
    if (!inputRef.current?.emailInputRef.current) return
    inputRef.current?.emailInputRef.current.focus()
    inputRef.current?.emailInputRef.current.addEventListener('keyup', (event: any) => {
      handleNavigationToTheList(event, availableContacts[0])
    })
  }, [inputRef.current, availableContacts])

  useEffect(() => {
    if (!elem) return
    elem.addEventListener('keydown', debounce(handleInputChange, 300))
    return () => elem.removeEventListener('keydown', handleInputChange)
  }, [elem])

  useEffect(() => {
    if (emails?.length === 1) {
      elem?.setAttribute('disabled', 'true')
      // @ts-ignore
      elem.style.background = 'transparent'
    } else {
      elem?.removeAttribute('disabled')
    }
  }, [emails, elem])

  const [getOrCreateCustomerMutation] = useMutation<GetOrCreateCustomerMutation, GetOrCreateCustomerMutationVariables>(
    gql(getOrCreateCustomer),
    {
      client: CognitoApolloClient,
    },
  )

  const [createChannelMutation] = useMutation<CreateChannelMutation, CreateChannelMutationVariables>(
    gql(createChannel),
    {
      client: CognitoApolloClient,
    },
  )

  const [fetchContactsQuery, { data: contactsQueryData }] = useLazyQuery<
    SearchScreensightContactsQuery,
    SearchScreensightContactsQueryVariables
  >(gql(searchScreensightContacts), {
    variables: {
      sort: [
        {
          direction: SearchableSortDirection.desc,
          field: SearchablescreensightContactSortableFields.lastName,
        },
        {
          direction: SearchableSortDirection.desc,
          field: SearchablescreensightContactSortableFields.firstName,
        },
        {
          direction: SearchableSortDirection.desc,
          field: SearchablescreensightContactSortableFields.email,
        },
      ],
    },
  })

  useEffect(() => {
    if (!contactsQueryData?.searchScreensightContacts?.items?.length) {
      setAvailableContacts([])
    } else {
      setAvailableContacts(contactsQueryData?.searchScreensightContacts?.items as screensightContact[])
    }
  }, [contactsQueryData, refetching])

  const checkCustomer = async (email: string) => {
    if (!auth?.user?.id || !auth.isAuthenticated) return
    if (email === auth.user.email) {
      toastContext.open({ type: 'ERROR', text: t('chatBody.createChannelToYourselfError') })
      return
    }
    try {
      setLoading(true)
      const checkCustomerRes = await getOrCreateCustomerMutation({
        variables: { input: { email: email, firstName: '' } },
      })

      if (!checkCustomerRes.data?.getOrCreateCustomer?.id) return
      if (checkCustomerRes.data?.getOrCreateCustomer?.id.startsWith('anon#')) {
        toastContext.open({
          type: 'ERROR',
          text: t('chatBody.addresseeError'),
        })
      } else {
        const listContactRes = await fetchContactsQuery({
          variables: {
            limit: 1,
            filter: { email: { eq: email } },
          },
        })
        const isEmailContact = !!listContactRes?.data?.searchScreensightContacts?.items?.length
        const contact = listContactRes?.data?.searchScreensightContacts?.items[0]
        if (isEmailContact) {
          const addChannelRes = await addChannel({
            customerId: checkCustomerRes.data?.getOrCreateCustomer?.id || '',
            name: getUsername(contact?.firstName, contact?.lastName, contact?.email),
          })
          history.push(`/app/chat/${addChannelRes?.data?.createChannel?.id || ''}`)
          dispatch(refetchListChannels(auth.user.id))
        } else {
          setPartnerId(checkCustomerRes.data?.getOrCreateCustomer?.id || '')
          setCreateContactModalVisible(true)
        }
      }
    } catch (e: any) {
      toastContext.open({ type: 'ERROR', text: t('chatBody.creatingConversationError') })
      console.error('Error during creating conversation', e.message)
    } finally {
      setLoading(false)
    }
  }

  const addChannel = async (participant: { customerId: string; name: string }) => {
    try {
      return await createChannelMutation({
        variables: {
          input: {
            participants: [
              {
                customerId: auth.user?.id || '',
                name: getUsername(auth.user?.firstName, auth.user?.lastName, auth.user?.email),
              },
              { customerId: participant.customerId, name: participant.name },
            ],
          },
        },
      })
    } catch (e: any) {
      toastContext.open({ type: 'ERROR', text: t('chatBody.addChannelError') })
    }
  }

  const handleInputChange = async (e: any) => {
    if (['ArrowDown', 'ArrowUp', 'Enter', 'Tab'].includes(e.key)) return
    const val = elem?.value
    setRefetching(true)
    await fetchContactsQuery({
      variables: {
        filter: val
          ? {
              or: [
                {
                  email: { matchPhrasePrefix: val },
                },
                {
                  firstName: { matchPhrasePrefix: val },
                },
                {
                  lastName: { matchPhrasePrefix: val },
                },
              ],
            }
          : undefined,
      },
    })
    setRefetching(false)
  }

  const handleNavigationToTheList = (
    event: React.KeyboardEvent<HTMLInputElement>,
    firstContactFromList?: screensightContact,
  ) => {
    event.preventDefault()
    if (event.code === 'ArrowDown') {
      const firstContact = document.getElementById('available-emails-list-0')
      firstContact?.focus()
    } else if (event.code === 'Tab' && firstContactFromList) {
      setEmails([firstContactFromList.email])
      setAvailableContacts([])
    }
  }

  return {
    emails,
    setEmails,
    checkCustomer,
    inputRef,
    createContactModalVisible,
    setCreateContactModalVisible,
    handleNavigationInsideTheList,
    handleNavigationToTheList,
    handleSelectContact,
    partnerId,
    loading,
    searchPhrase: elem?.value || '',
    availableContacts,
    setAvailableContacts,
    handleInputChange,
    refetching,
  }
}

export type TUseCreateMessageTopBar = ReturnType<typeof useNewMessage>
export default useNewMessage
