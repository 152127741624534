import { useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import { useChatDispatch } from '../../contexts/ChatContext'

const usePostEditionDispatch = () => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = useMemo(
    () => channelParamsId || auth.user?.supportChannelId,
    [channelParamsId, auth.user?.supportChannelId],
  )
  const chatDispatcher = useChatDispatch()

  useEffect(() => {
    if (!channelId) return

    chatDispatcher({
      type: 'setEditedPost',
      value: null,
    })
  }, [channelId])
}

export default usePostEditionDispatch
