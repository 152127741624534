import * as React from 'react'
import { Box } from '@mui/material'
import RecipientsInput from '../components/RecipientsInput'
import SubjectInput from '../components/SubjectInput'
import QuillEmailNote from '../components/QuillEmailNote'
import FormControl from '../components/FormControl'
import AvailableEmailsModal from '../../AvailableEmailsModal'
import AddNewContactModal from '../../AddNewContactModal'
import useEmailForm from '../../common/hooks/useEmailForm'
import { VideoStatus } from '../../../../../API'

interface IEmailFormProps {
  videoId: string | undefined
  hideSendByEmailModal: () => void
  videoTitle?: string | null
  showMessageSentModal: () => void
  videoStatus?: VideoStatus
}

const EmailForm = (props: IEmailFormProps) => {
  const state = useEmailForm(props.videoId, props.showMessageSentModal, props.hideSendByEmailModal, props.videoTitle)
  return (
    <>
      <FormControl
        isError={state.isError}
        subject={state.subject}
        isMobile={state.isMobile}
        emails={state.emails}
        sending={state.sending}
        trackInThreads={state.trackInThreads}
        toggleTrackInThreads={state.toggleTrackInThreads}
        currentContactToAdd={state.currentContactToAdd}
        hideSendByEmailModal={props.hideSendByEmailModal}
        sendEmail={state.sendEmail}
        videoStatus={props?.videoStatus}
      />
      <Box padding="0 20px" marginBottom="40px">
        <RecipientsInput
          emails={state.emails}
          inputRef={state.inputRef}
          verifyEmail={state.verifyEmail}
          isVerifying={state.isVerifying}
        />
        {state.searchPhrase?.length > 1 ? (
          <AvailableEmailsModal
            inputRef={state.inputRef}
            availableContacts={state.availableContacts}
            setAvailableContacts={state.setAvailableContacts}
            setEmails={state.setEmails}
            searchPhrase={state.searchPhrase || ''}
            loading={state.loading}
          />
        ) : null}
        {state.currentContactToAdd && (
          <AddNewContactModal
            email={state.currentContactToAdd}
            handleSetVerifiedEmails={state.handleSetVerifiedEmails}
          />
        )}
        <SubjectInput subject={state.subject} handleSubject={state.handleSubject} />
        <QuillEmailNote
          isError={state.isError}
          quillModules={state.quillModules}
          videoId={props.videoId}
          quillRef={state.quillRef}
          note={state.note}
          handleNote={state.handleNote}
        />
      </Box>
    </>
  )
}

export default EmailForm
