import { useForm } from 'react-hook-form'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from 'common/providers/AuthStatusProvider'
import ToastContext from '../../../../../../common/providers/ToastProvider/ToastContext'
import { IAuthForm } from '../../../../common/types/Auth'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { useTranslation } from 'react-i18next'

const useLogin = () => {
  const auth = useContext(AuthContext)
  const toastContext = useContext(ToastContext)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const sendDataToGTM = useGTMDispatch()
  const { t } = useTranslation('auth')
  const form = useForm<IAuthForm>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const translateErrorMessage = (errorMessage: string) => {
    switch (errorMessage) {
      case 'Password attempts exceeded':
        return t('login.attemptsExceeded')
      case 'Incorrect username or password.':
        return t('login.incorrectCredentials')
      case 'User does not exist.':
        return t('login.userDoesNotExist')
      default:
        return errorMessage
    }
  }

  useEffect(() => {
    if (!errorMessage) return
    toastContext.open({
      text: translateErrorMessage(errorMessage),
    })
    setErrorMessage(undefined)
  }, [errorMessage])

  const onSubmit = form.handleSubmit(async (values: IAuthForm) => {
    try {
      setLoading(true)
      await auth.login(values.email, values.password)
      sendDataToGTM({ event: 'SceensightLogin' })
    } catch (err: any) {
      console.error('err', err)
      if (err.code === 'UserNotConfirmedException') {
        history.push({ pathname: '/verify', state: { email: values.email } })
      }
      setErrorMessage(err.message)
    } finally {
      setLoading(false)
    }
  })

  const switchPasswordVisible = () => {
    setPasswordVisible((prevState) => !prevState)
  }

  return {
    form,
    onSubmit,
    loading,
    errorMessage,
    switchPasswordVisible,
    isPasswordVisible,
    history,
  }
}

export default useLogin
