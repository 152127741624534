import React, { createContext, useReducer } from 'react'
import { TEditThumbnailModalView } from '../types'

interface IEditThumbnailContextState {
  currentModalView: TEditThumbnailModalView
  imageFile: Blob | File | null
  thumbnailKey: string
  videoId: string
}

const initialState: IEditThumbnailContextState = {
  currentModalView: '',
  imageFile: null,
  thumbnailKey: '',
  videoId: '',
}

export type EditThumbnailContextAction = {
  type: 'setThumbnailState' | 'setCurrentModalView' | 'setThumbnailImageFile' | 'setThumbnailKey' | 'setVideoId'
  value: any
}

const editThumbnailReducer = (
  state: IEditThumbnailContextState,
  action: EditThumbnailContextAction,
): IEditThumbnailContextState => {
  switch (action.type) {
    case 'setThumbnailState':
      return {
        ...state,
        ...action.value,
      }

    case 'setCurrentModalView':
      return {
        ...state,
        currentModalView: action.value,
      }

    case 'setThumbnailImageFile':
      return {
        ...state,
        imageFile: action.value,
      }

    case 'setThumbnailKey':
      return {
        ...state,
        thumbnailKey: action.value,
      }

    case 'setVideoId':
      return {
        ...state,
        videoId: action.value,
      }

    default:
      throw new Error()
  }
}

const EditThumbnailStateContext = createContext<IEditThumbnailContextState>(initialState)
const EditThumbnailDispatchContext = createContext<React.Dispatch<EditThumbnailContextAction> | null>(null)

interface IAppointmentBookContextProps {
  children: React.ReactNode
}

const EditThumbnailContext = ({ children }: IAppointmentBookContextProps) => {
  const [state, dispatch] = useReducer(editThumbnailReducer, initialState)

  return (
    <EditThumbnailStateContext.Provider value={state}>
      <EditThumbnailDispatchContext.Provider value={dispatch}>{children}</EditThumbnailDispatchContext.Provider>
    </EditThumbnailStateContext.Provider>
  )
}

function useEditThumbnailContext() {
  const context = React.useContext(EditThumbnailStateContext)
  if (!context) {
    throw new Error('useEditThumbnailState must be used within the AppProvider')
  }
  return context
}

function useEditThumbnailDispatch() {
  const context = React.useContext(EditThumbnailDispatchContext)
  if (!context) {
    throw new Error('useEditThumbnailDispatch must be used within the AppProvider')
  }
  return context
}

export { EditThumbnailContext, useEditThumbnailContext, useEditThumbnailDispatch }
