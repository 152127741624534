import React from 'react'
import useCameraWithPrompterPreview from './hooks/useCameraWithPrompterPreview'
import CameraWithPrompterPreviewView from './view/CameraWithPrompterPreviewView'

interface IProps {
  cameraStream: MediaStream | undefined
  paused?: boolean
}

const CameraWithPrompterPreview = (props: IProps) => {
  const state = useCameraWithPrompterPreview(props.cameraStream, props.paused)
  return <CameraWithPrompterPreviewView {...state} />
}

export default CameraWithPrompterPreview
