import enGeneral from './en/general.json'
import enAuth from '../translations/en/auth.json'
import enSettings from '../translations/en/settings.json'
import enContacts from '../translations/en/contacts.json'
import enChat from '../translations/en/chat.json'
import enLibrary from '../translations/en/library.json'
import enSupport from '../translations/en/support.json'
import enTermsAndConditions from '../translations/en/termsAndConditions.json'
import plGeneral from './pl/general.json'
import plAuth from '../translations/pl/auth.json'
import plSettings from '../translations/pl/settings.json'
import plContacts from '../translations/pl/contacts.json'
import plChat from '../translations/pl/chat.json'
import plLibrary from '../translations/pl/library.json'
import plSupport from '../translations/pl/support.json'
import plTermsAndConditions from '../translations/pl/termsAndConditions.json'

type Languages = string[]
type Namespaces = string[]
interface IResources {
  [key: string]: {
    [key: string]: string
  }
}
const languages: Languages = ['en', 'pl', 'fr', 'de', 'it', 'es']

export const namespaces: Namespaces = [
  'general',
  'auth',
  'settings',
  'contacts',
  'chat',
  'library',
  'support',
  'termsAndConditions',
]

// is used in i18n to import languages and namespaces
export const resources: IResources = languages.reduce(
  (languageAccumulator, language) => ({
    ...languageAccumulator,
    [language]: namespaces.reduce(
      (namespaceAccumulator, namespace) => ({
        ...namespaceAccumulator,
        [namespace]: require(`./${language}/${namespace}.json`),
      }),
      {},
    ),
  }),
  {},
)

// required to be exported as const for the Typescript purpose
export const defaultResources = {
  en: {
    general: enGeneral,
    auth: enAuth,
    settings: enSettings,
    contacts: enContacts,
    chat: enChat,
    library: enLibrary,
    support: enSupport,
    termsAndConditions: enTermsAndConditions,
  },
  pl: {
    general: plGeneral,
    auth: plAuth,
    settings: plSettings,
    contacts: plContacts,
    chat: plChat,
    library: plLibrary,
    support: plSupport,
    termsAndConditions: plTermsAndConditions,
  },
} as const

export default {}
