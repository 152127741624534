import Layout from '../ui/desktop/Layout'
import TabletLayout from '../ui/tablet/Layout'
import UserForm from '../ui/shared/blocks/UserForm'
import CompanyAside from '../ui/desktop/partials/CompanyAside'
import UserAside from '../ui/desktop/partials/UserAside'
import Content from '../ui/tablet/partials/Content'

export const Verify = {
  Desktop: {
    Layout,
    CompanyAside,
    UserAside,
  },
  Tablet: {
    Layout: TabletLayout,
    Content,
  },
  Shared: {
    UserForm,
  },
}
