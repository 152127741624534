import React, { memo } from 'react'
import { Box, Tooltip, Typography, useTheme } from '@mui/material'
import CloudFrontImage from '../../../../components/_refactored/CloudFrontImage'

interface IProfileRoundedImage {
  name: string
  width?: string
  height?: string
  photoUrl?: string
  hideTooltip?: boolean
  wrapperStyle?: React.CSSProperties
  S3ImageStyle?: React.CSSProperties
  disabledLoading?: boolean
  handleLoadImage?: () => void
  type?: 'S3' | 'IMG'
  isPrimaryColor?: boolean
}

const ProfileRoundedImage = (props: IProfileRoundedImage) => {
  const { name, width, height, photoUrl, S3ImageStyle, hideTooltip, wrapperStyle, isPrimaryColor } = props
  const theme = useTheme()

  return (
    <Tooltip title={hideTooltip ? '' : name}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={photoUrl ? 'transparent' : isPrimaryColor ? 'primary.main' : 'background.default'}
        border={isPrimaryColor || photoUrl ? '' : `2px solid ${theme.palette.secondary.main}`}
        borderRadius="100px"
        height={height || '100%'}
        width={width || '100%'}
        marginRight="10px"
        style={{ ...wrapperStyle }}
      >
        {photoUrl ? (
          <Box position="relative" display="flex">
            <CloudFrontImage
              image={`public/${photoUrl}`}
              width="43"
              height="43"
              imgStyle={{
                width: '43px',
                height: '43px',
                borderRadius: '100px',
                objectFit: 'cover',
                ...S3ImageStyle,
              }}
            />
          </Box>
        ) : (
          <Typography variant="body1" style={{ color: isPrimaryColor ? 'white' : theme.palette.text.secondary }}>
            {(name || '').charAt(0).toUpperCase()}
          </Typography>
        )}
      </Box>
    </Tooltip>
  )
}

export default memo(ProfileRoundedImage)
