import { AccessType, S3FileAccessLevel, VideoStatus } from '../../../../../../../../API'

export const listVideosInChannel = /* GraphQL */ `
  query ListTypeOfPostInChannel(
    $postType: PostTypeFilter!
    $channelId: ID!
    $customerId: ID
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listTypeOfPostInChannel(
      postType: $postType
      channelId: $channelId
      customerId: $customerId
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelId
        accessId
        customerId
        postType
        video {
          id
          createdAt
          customerId
          title
          status
          filePresignedUrl
          duration
          name
          thumbnailObject {
            key
            bucket
            accessLevel
            identityId
          }
          accessType
          accessId
          password
          width
          height
          viewsList {
            items {
              customerId
            }
          }
        }
        engagement {
          items {
            customerId
            seen
            seenAt
            videoProgress
          }
          nextToken
        }
        reactions {
          items {
            postId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
            }
            unicode
            videoTimestamp
            createdAt
            updatedAt
          }
          nextToken
        }
        referencePost {
          id
          reactions {
            items {
              postId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
              }
              unicode
              videoTimestamp
              createdAt
              updatedAt
            }
            nextToken
          }
          video {
            id
            createdAt
            customerId
            title
            status
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsList {
              items {
                customerId
              }
            }
          }
        }
        createdAt
      }
      nextToken
    }
  }
`

export type TVideoInChannel = {
  __typename: 'screensightPost'
  id: string
  channelId?: string | null
  accessId?: string | null
  customerId?: string | null
  postType?: string | null
  video?: {
    __typename: 'screensightVideo'
    id: string
    createdAt: string
    customerId: string
    channelId?: string | null
    title?: string | null
    status: VideoStatus
    filePresignedUrl?: string | null
    duration?: number | null
    name?: string | null
    thumbnailObject?: {
      __typename: 'S3Object'
      key: string
      bucket: string
      accessLevel?: S3FileAccessLevel | null
      identityId?: string | null
    } | null
    accessType?: AccessType | null
    accessId?: string | null
    password?: string | null
    width?: number | null
    height?: number | null
    viewsList?: {
      __typename: 'ModelscreensightVideoAudienceConnection'
      items: Array<{
        __typename: 'screensightVideoAudience'
        customerId: string
      }>
    } | null
  } | null
  engagement?: {
    __typename: 'PostEngagementConnection'
    items: Array<{
      __typename: 'PostEngagement'
      customerId?: string | null
      seen?: boolean | null
      seenAt?: string | null
      videoProgress?: number | null
    } | null>
    nextToken?: string | null
  } | null
  reactions?: {
    __typename: 'ModelscreensightReactionConnection'
    items: Array<{
      __typename: 'screensightReaction'
      postId: string
      customerId: string
      customerMeta?: {
        __typename: 'screensightCustomer'
        id: string
        email?: string | null
        firstName?: string | null
        lastName?: string | null
      } | null
      unicode: string
      videoTimestamp: number
      createdAt?: string | null
      updatedAt?: string | null
    } | null>
    nextToken?: string | null
  } | null
  referencePost?: {
    __typename: 'screensightPost'
    id: string
    reactions?: {
      __typename: 'ModelscreensightReactionConnection'
      items: Array<{
        __typename: 'screensightReaction'
        postId: string
        customerId: string
        customerMeta?: {
          __typename: 'screensightCustomer'
          id: string
          email?: string | null
          firstName?: string | null
          lastName?: string | null
        } | null
        unicode: string
        videoTimestamp: number
        createdAt?: string | null
        updatedAt?: string | null
      } | null>
      nextToken?: string | null
    } | null
    video?: {
      __typename: 'screensightVideo'
      id: string
      createdAt: string
      customerId: string
      channelId?: string | null
      title?: string | null
      status: VideoStatus
      filePresignedUrl?: string | null
      duration?: number | null
      name?: string | null
      thumbnailObject?: {
        __typename: 'S3Object'
        key: string
        bucket: string
        accessLevel?: S3FileAccessLevel | null
        identityId?: string | null
      } | null
      accessType?: AccessType | null
      accessId?: string | null
      password?: string | null
      width?: number | null
      height?: number | null
      viewsList?: {
        __typename: 'ModelscreensightVideoAudienceConnection'
        items: Array<{
          __typename: 'screensightVideoAudience'
          customerId: string
        }>
      } | null
    } | null
  } | null
  createdAt?: string | null
}

export type ListVideosInChannelQuery = {
  listTypeOfPostInChannel?: {
    __typename: 'ModelscreensightPostConnection'
    items: Array<TVideoInChannel | null>
    nextToken?: string | null
  } | null
}
