import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Tooltip, useTheme } from '@mui/material'
import AddReactionIcon from '../../../../../../../../common/icons/AddReactionIcon'
import DefaultReactionsPicker from './DefaultReactionsPicker'
import useReactionBtn from '../../../common/hooks/reactions/useReactionBtn'
import AllReactionsPicker from './AllReactionsPicker'
import './styles.scss'

interface IReactionBtnProps {
  postId: string
  videoId: string | undefined
  location: 'postActions' | 'reactionsList'
}

const ReactionBtn = (props: IReactionBtnProps) => {
  const theme = useTheme()
  const {
    onEmojiClick,
    openAllReactions,
    closeAllReactions,
    openDefaultReactions,
    closeDefaultReactions,
    allEmojisAnchorEl,
    defaultEmojisAnchorEl,
    openAllEmojis,
    openDefaultEmojis,
  } = useReactionBtn(props.postId, props.location)
  const { t } = useTranslation('chat')

  return (
    <Box className={`post--reactions-${props.postId}-${props.location}`}>
      <Tooltip title={t('chatBody.reactionTooltip.addReaction')} placement="top">
        <IconButton onClick={openDefaultReactions} className="reaction-btn" style={{ zIndex: 0 }}>
          <AddReactionIcon
            color={
              allEmojisAnchorEl || defaultEmojisAnchorEl ? theme.palette.primary.main : theme.palette.secondary.light
            }
          />
        </IconButton>
      </Tooltip>
      <DefaultReactionsPicker
        open={openDefaultEmojis}
        anchorEl={defaultEmojisAnchorEl}
        closeDefaultReactions={closeDefaultReactions}
        openAllReactions={openAllReactions}
        onEmojiClick={onEmojiClick}
      />
      <AllReactionsPicker
        open={openAllEmojis}
        anchorEl={allEmojisAnchorEl}
        onClose={closeAllReactions}
        onEmojiClick={onEmojiClick}
      />
    </Box>
  )
}

export default ReactionBtn
