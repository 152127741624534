import React from 'react'
import { useSelector } from 'react-redux'
import videojs from 'video.js'
import Typography from '@mui/material/Typography'
import { useChatContext } from '../../../common/contexts/ChatContext'
import { RootState } from '../../../../../../common/redux/store/store'

const ResponseBoxTimestamp = () => {
  const videoPlaylistProgress = useSelector((state: RootState) => state.chat.videoPlaylistProgress)
  const chatContext = useChatContext()

  return (
    <Typography color="textSecondary" style={{ fontSize: '15px', width: '50px' }}>
      {videojs.formatTime(
        parseInt(
          String(videoPlaylistProgress?.[chatContext?.responseBoxData?.postId || '']) ||
            chatContext.responseBoxData?.videoTimestamp ||
            '0',
        ) || 0,
        600,
      )}
    </Typography>
  )
}

export default ResponseBoxTimestamp
