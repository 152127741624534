import React from 'react'
import { Box, Typography } from '@mui/material'
import UserAvatar from '../../../ChannelsSidebar/ui/components/UserAvatar'
import useRow from '../../../ChannelsSidebar/common/hooks/useRow'
import { RowWrapper } from '../../../ChannelsSidebar/ui/components/styled/RowWrapper'
import { theme } from '../../../../../../../common/providers/MuiThemeProvider'

interface IRow {
  active?: boolean
  shouldNotify?: boolean | null
  label: string
  imgKey?: string
  channelId: string
  createNewMessageRow?: boolean
  closeChannels: () => void
  isScreenSightUser?: boolean
}

const Row = (props: IRow) => {
  const state = useRow(props.channelId, props.closeChannels)

  return (
    <RowWrapper
      width="100%"
      display="flex"
      alignItems="center"
      padding="8px 15px"
      style={{ cursor: 'pointer' }}
      active={props.active}
      onClick={state.handleRedirect}
      hasNewMessages={props.shouldNotify}
    >
      <UserAvatar
        label={props.label}
        active={props.active}
        imgKey={props?.imgKey}
        isScreenSightUser={props?.isScreenSightUser}
      />
      <Typography
        variant="body1"
        style={{
          fontWeight: props.shouldNotify && state.channelParamsId !== props.channelId ? 500 : 400,
          marginLeft: '10px',
          fontSize: '15px',
          color: props?.createNewMessageRow
            ? theme.palette.primary.main
            : !!props.channelId && props.channelId === state.channelParamsId
            ? 'white'
            : props.shouldNotify
            ? 'black'
            : theme.palette.text.secondary,
        }}
      >
        {props.label}
      </Typography>
      {props.shouldNotify && state.channelParamsId !== props.channelId && (
        <Box position="absolute" right="10px" top="0" height="100%" width="10px" display="flex" alignItems="center">
          <Box width="10px" height="10px" border="40px" bgcolor="#FF1B69" borderRadius="40px" />
        </Box>
      )}
    </RowWrapper>
  )
}

export default Row
