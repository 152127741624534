import { useContext, useEffect } from 'react'
import { AuthContext } from '../../../../providers/AuthStatusProvider'
import { useMutation, useQuery } from '@apollo/client'
import {
  GetApplicationConfigurationQuery,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
} from '../../../../../API'
import gql from 'graphql-tag'
import { updateCustomer } from '../../../../../graphql/mutations'
import CognitoApolloClient from '../../../../clients/CognitoApolloClient'
import { getApplicationConfiguration } from '../../../../../graphql/queries'
import IamApolloClient from '../../../../clients/IamApolloClient'
import { Storage } from 'aws-amplify'

/*
 * This hook is used to update the user's profile photo and timezone
 * It also updates the application configuration to the user's settings
 */
const useUpdateUserConfig = () => {
  const auth = useContext(AuthContext)

  const [updateUserMutation] = useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
    gql(updateCustomer),
    {
      client: CognitoApolloClient,
    },
  )

  const { data: appConfigData } = useQuery<GetApplicationConfigurationQuery, never>(gql(getApplicationConfiguration), {
    skip: auth.user?.pending,
    client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
  })

  /*
    This useEffect updates the application configuration to the user's settings
   */
  useEffect(() => {
    if (!appConfigData) return
    auth.setApplicationConfiguration(appConfigData)
  }, [appConfigData])

  /*
    This useEffect updates the user's profile photo
   */
  useEffect(() => {
    const updateUserProfile = async () => {
      if (auth.user?.pending) return
      if (!auth.user?.profilePhoto?.key) {
        await auth.updateUser({ ...auth.user, profilePhotoUrl: undefined })
        return
      }
      const photoUrl = (await Storage.get(auth.user.profilePhoto.key)) as string
      await auth.updateUser({ ...auth.user, profilePhotoUrl: photoUrl })
    }
    updateUserProfile()
  }, [auth.user?.profilePhoto?.key, auth.user?.pending])

  /*
    This useEffect updates the user's timezone
   */
  useEffect(() => {
    const updateTimezone = async () => {
      if (!auth.user?.id || !auth.user?.settings) return
      const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

      if (currentTimezone === auth.user.settings.timezone) return
      const settings = { ...auth.user.settings }
      delete settings.termsAndConditions
      await updateUserMutation({
        variables: {
          input: {
            id: auth.user.id,
            settings: {
              ...settings,
              timezone: currentTimezone,
            },
          },
        },
      })
    }
    updateTimezone()
  }, [auth.user?.id])
}

export default useUpdateUserConfig
