import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import AISummaryIcon from '../../../../../../../../../../common/icons/AISummaryIcon'
import { theme } from '../../../../../../../../../../common/providers/MuiThemeProvider'
import * as React from 'react'
import AISummaryControlBtn from './styled/AISummaryControlBtn'

type VideoPostExistingSummaryControlsProps = {
  onSummaryClick: () => void
  onTranscriptionClick: () => void
  contentSelected: string
}
const VideoPostExistingSummaryControls = (props: VideoPostExistingSummaryControlsProps) => {
  const { t } = useTranslation('chat')

  return (
    <Box display={'flex'} alignItems={'center'}>
      <AISummaryControlBtn
        onClick={props.onSummaryClick}
        color={props.contentSelected === 'SUMMARY' ? 'primary' : 'secondary'}
      >
        <Box mr={'3px'} display={'flex'} alignItems={'center'}>
          <AISummaryIcon
            color={props.contentSelected === 'SUMMARY' ? theme.palette.primary.main : theme.palette.secondary.main}
          />
        </Box>
        {t('chatBody.videoPostSummaryAI.aiSummary')}
      </AISummaryControlBtn>
      <Box
        marginX={'8px'}
        sx={{
          fontSize: '12px',
          color: theme.palette.secondary.main,
        }}
      >
        |
      </Box>
      <AISummaryControlBtn
        variant={'text'}
        color={props.contentSelected === 'TRANSCRIPTION' ? 'primary' : 'secondary'}
        onClick={props.onTranscriptionClick}
      >
        {t('chatBody.videoPostSummaryAI.transcription')}
      </AISummaryControlBtn>
    </Box>
  )
}

export default VideoPostExistingSummaryControls
