import React from 'react'
import { Box } from '@mui/material'
import { IPartnerSidebar } from '../../index'
import WidgetsList from '../blocks/WidgetsList'
import SharedMedia from '../blocks/SharedMedia'
import usePartnerSidebar from '../../common/hooks/usePartnerSidebar'
import '../../common/styles/PartnerSidebarContent.scss'
import ResizableRightSidePanelContainer from '../components/ResizableRightSidePanelContainer'

const PartnerSidebarContent = (props: IPartnerSidebar) => {
  const state = usePartnerSidebar()

  return (
    <ResizableRightSidePanelContainer>
      <Box
        width="100%"
        height="100vh"
        maxHeight="100vh"
        bgcolor="background.paper"
        display="flex"
        sx={{ overflowX: 'hidden', overflowY: 'auto' }}
        className="partner-sidebar__wrapper"
      >
        <Box width="250px" padding="20px 10px 20px 20px">
          <SharedMedia currentView={state.currentView} partner={props.partner} />
        </Box>
        <Box width="44px" />
        <Box position="fixed" right="0" top="0" width="44px" height="100%" zIndex={10} bgcolor="#F8F9FB">
          <WidgetsList currentView={state.currentView} handleChangeCurrentView={state.handleChangeCurrentView} />
        </Box>
      </Box>
    </ResizableRightSidePanelContainer>
  )
}

export default PartnerSidebarContent
