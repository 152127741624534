import React from 'react'
import { Box } from '@mui/material'
import ChatContent from '../Chat/ui/components/ChatContent'
import useChatParticipants from '../Chat/common/hooks/useChatParticipants'
import PageTitle from '../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

const Support = () => {
  const state = useChatParticipants()
  const { t: g } = useTranslation('general')

  return (
    <Box display="flex" justifyContent="space-between" width="100%" height="100%">
      <PageTitle name={g('pageName.support')} />
      <ChatContent {...state} />
    </Box>
  )
}

export default Support
