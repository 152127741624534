import React from 'react'
import { Box, Typography } from '@mui/material'
import FirstMessageImg from '../../../../../../../common/assets/ChatFirstMessage.png'
import { useTranslation } from 'react-i18next'

const EmptyChatList = () => {
  const { t } = useTranslation('chat')
  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
      <img src={FirstMessageImg} width="75px" height="67px" alt="Record new video" />
      <Typography variant="body1" color="textSecondary" style={{ marginTop: '20px' }}>
        {t('chatBody.emptyChatBackgroundMessage')}
      </Typography>
    </Box>
  )
}

export default EmptyChatList
