import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import { useMutation } from '@apollo/client'
import { UpdateCustomerMutation, UpdateCustomerMutationVariables } from '../../../../API'
import gql from 'graphql-tag'
import { updateCustomer } from '../../../../graphql/mutations'
import CognitoApolloClient from '../../../../common/clients/CognitoApolloClient'
import { useLocation, useHistory } from 'react-router'
import * as queryString from 'query-string'
import ToastContext from '../../../../common/providers/ToastProvider/ToastContext'
import { useTranslation } from 'react-i18next'

const useEmailNotifications = () => {
  const auth = useContext(AuthContext)
  const toastContext = useContext(ToastContext)
  const [viewsNotificationsLoading, setViewsNotificationsLoading] = useState(false)
  const [commentsNotificationsLoading, setCommentsNotificationsLoading] = useState(false)
  const [unseenNotificationLoading, setUnseenNotificationLoading] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const queryParams = queryString.parse(location.search)
  const { t } = useTranslation('settings')

  const [updateUserMutation] = useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
    gql(updateCustomer),
    {
      client: CognitoApolloClient,
    },
  )

  useEffect(() => {
    if (!queryParams?.unsubscribe || auth.user?.pending) return

    const unsubscribe = async () => {
      if (queryParams.unsubscribe === 'notifyEmailComment') {
        await handleCommentsNotification(false)
        toastContext.open({ type: 'INFO', text: t('emailNotifications.unsubscribe.notifyEmailComment') })
        history.replace({ search: '' })
      }

      if (queryParams.unsubscribe === 'notifyEmailWatched') {
        await handleViewsNotification(false)
        toastContext.open({ type: 'INFO', text: t('emailNotifications.unsubscribe.notifyEmailWatched') })
        history.replace({ search: '' })
      }
      if (queryParams.unsubscribe === 'notifyUnseenMessage') {
        await handleUnseenMessagesNotification(false)
        toastContext.open({ type: 'INFO', text: t('emailNotifications.unsubscribe.notifyUnseenMessage') })
        history.replace({ search: '' })
      }
    }
    unsubscribe()
  }, [auth.user?.pending])

  const handleViewsNotification = async (event: React.ChangeEvent<HTMLInputElement> | boolean) => {
    try {
      setViewsNotificationsLoading(true)
      if (!auth.user?.id || !auth.user.settings) return
      const updatedUserRes = await updateUserMutation({
        variables: {
          input: {
            id: auth.user?.id,
            settings: {
              notifyEmailComment: auth.user.settings.notifyEmailComment,
              notifyEmailWatched:
                typeof event === 'boolean' ? event : (event as React.ChangeEvent<HTMLInputElement>).target.checked,
              notifyUnseenMessages: auth.user.settings.notifyUnseenMessages,
            },
          },
        },
      })
      await auth.updateUser({
        ...auth.user,
        settings: {
          ...auth.user.settings,
          notifyEmailWatched: updatedUserRes.data?.updateCustomer?.settings?.notifyEmailWatched as boolean,
        },
      })
    } catch (e: any) {
      console.log('Error during updating watch notification', e)
    } finally {
      setViewsNotificationsLoading(false)
    }
  }

  const handleCommentsNotification = async (event: React.ChangeEvent<HTMLInputElement> | boolean) => {
    try {
      setCommentsNotificationsLoading(true)
      if (!auth.user?.id || !auth.user.settings) return
      const updatedUserRes = await updateUserMutation({
        variables: {
          input: {
            id: auth.user?.id,
            settings: {
              notifyEmailWatched: auth.user.settings.notifyEmailWatched,
              notifyEmailComment:
                typeof event === 'boolean' ? event : (event as React.ChangeEvent<HTMLInputElement>).target.checked,
              notifyUnseenMessages: auth.user.settings.notifyUnseenMessages,
            },
          },
        },
      })
      await auth.updateUser({
        ...auth.user,
        settings: {
          ...auth.user.settings,
          notifyEmailComment: updatedUserRes.data?.updateCustomer?.settings?.notifyEmailComment as boolean,
        },
      })
    } catch (e: any) {
      console.log('Error during updating comment notification', e)
    } finally {
      setCommentsNotificationsLoading(false)
    }
  }
  const handleUnseenMessagesNotification = async (event: React.ChangeEvent<HTMLInputElement> | boolean) => {
    try {
      setUnseenNotificationLoading(true)
      if (!auth.user?.id || !auth.user.settings) return
      const updatedUserRes = await updateUserMutation({
        variables: {
          input: {
            id: auth.user?.id,
            settings: {
              notifyUnseenMessages:
                typeof event === 'boolean' ? event : (event as React.ChangeEvent<HTMLInputElement>).target.checked,
              notifyEmailWatched: auth.user.settings.notifyEmailWatched,
              notifyEmailComment: auth.user.settings.notifyEmailComment,
            },
          },
        },
      })
      await auth.updateUser({
        ...auth.user,
        settings: {
          ...auth.user.settings,
          notifyUnseenMessages: updatedUserRes.data?.updateCustomer?.settings?.notifyUnseenMessages as boolean,
        },
      })
    } catch (e: any) {
      console.log('Error during updating unseen message notification', e)
    } finally {
      setUnseenNotificationLoading(false)
    }
  }

  return {
    handleViewsNotification,
    handleCommentsNotification,
    handleUnseenMessagesNotification,
    auth,
    viewsNotificationsLoading,
    commentsNotificationsLoading,
    unseenNotificationLoading,
  }
}

export default useEmailNotifications
