import React, { FC, useEffect, useState } from 'react'
import { IconSelectButton, Label } from './styledComponents'
import IconScreenRecording from '../../common/icons/ScreenRecording'
import IconCameraRecording from '../../common/icons/CameraRecording'
import useTheme from '@mui/material/styles/useTheme'
import { RecordTypeAction } from '../../common/providers/RecorderStateProvider/MediaRecorderHandler/MediaRecorderHandler.types'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import BothRecording from '../../common/icons/BothRecording'
import { useTranslation } from 'react-i18next'
import RecordFromLibrary from '../../common/icons/RecordFromLibrary'
import { useLocation } from 'react-router'
import UploadVideoIcon from '../../common/icons/UploadVideoIcon'
import { useSelector } from 'react-redux'
import { RootState } from '../../pages/Chat/common/redux/store/store'
import VideoLimitModal from '../../pages/VideoList/ui/components/VideoLimitModal'
import StarIconButton from '../../containers/VideoList/VideoNew/view/StarIconButton'

interface IProps {
  setScreenValue: () => void
  setCameraValue: () => void
  setCameraAndScreenValue: () => void
  openLibraryModal?: () => void
  selected: RecordTypeAction
  form: any
  fileInputRef?: any
  handleUploadVideoInputClick?: any
  handleUploadingProcess?: any
}

const RecordingTypeRadioButtons: FC<IProps> = ({
  setScreenValue,
  setCameraValue,
  setCameraAndScreenValue,
  selected,
  form,
  openLibraryModal,
  fileInputRef,
  handleUploadVideoInputClick,
  handleUploadingProcess,
}) => {
  const theme = useTheme()
  const { t: g } = useTranslation('general')
  const location = useLocation()
  const titleInput = document.getElementById('video__title__input')

  const videoLimitReached = useSelector((state: RootState) => state.chat.videosLimitReached)
  const [isVideoLimitModalOpen, setIsVideoLimitModalOpen] = useState(false)

  const hideVideoLimitModal = () => {
    setIsVideoLimitModalOpen(false)
  }

  useEffect(() => {
    if (!titleInput) return
    titleInput.focus()
  }, [selected])

  return (
    <Box width="100%" position="relative">
      {isVideoLimitModalOpen && (
        <VideoLimitModal
          hideModal={hideVideoLimitModal}
          style={{ left: '0px', top: '-62px', zIndex: 101, transform: 'none', width: '100%' }}
        />
      )}
      <Box display="flex" width="100%">
        <Box
          display="flex"
          alignItems="center"
          padding="30px 0 0 0"
          flexWrap="wrap"
          width="100%"
          justifyContent="center"
        >
          <Box
            bgcolor={
              selected === 'SCREEN' && !videoLimitReached ? theme.palette.primary.main : theme.palette.background.paper
            }
            width="93px"
            height="96px"
            borderRadius="6px"
            display="flex"
            alignItems="center"
            margin="0px 7px 10px"
            justifyContent="center"
            style={{ cursor: 'pointer' }}
            position="relative"
            onClick={() => {
              if (videoLimitReached) {
                setIsVideoLimitModalOpen(true)
              } else {
                setScreenValue()
              }
            }}
          >
            {videoLimitReached && <StarIconButton onClick={setIsVideoLimitModalOpen} />}
            <IconSelectButton type="button">
              <IconScreenRecording color={selected === 'SCREEN' && !videoLimitReached ? 'white' : undefined} />
              <Label color={selected === 'SCREEN' && !videoLimitReached ? 'white' : undefined}>
                {g('recordControl.newVideoModal.recordScreenOption')}
              </Label>
            </IconSelectButton>
          </Box>
          <Box
            bgcolor={
              selected === 'CAM' && !videoLimitReached ? theme.palette.primary.main : theme.palette.background.paper
            }
            width="93px"
            height="96px"
            borderRadius="6px"
            display="flex"
            alignItems="center"
            margin="0px 7px 10px"
            justifyContent="center"
            style={{ cursor: 'pointer' }}
            position="relative"
            onClick={() => {
              if (videoLimitReached) {
                setIsVideoLimitModalOpen(true)
              } else {
                setCameraValue()
              }
            }}
          >
            {videoLimitReached && <StarIconButton onClick={setIsVideoLimitModalOpen} />}
            <IconSelectButton type="button">
              <IconCameraRecording color={selected === 'CAM' && !videoLimitReached ? 'white' : undefined} />
              <Label color={selected === 'CAM' && !videoLimitReached ? 'white' : undefined}>
                {g('recordControl.newVideoModal.recordCameraOption')}
              </Label>
            </IconSelectButton>
          </Box>
          <Box
            bgcolor={
              selected === 'SCREENANDCAM' && !videoLimitReached
                ? theme.palette.primary.main
                : theme.palette.background.paper
            }
            width="93px"
            height="96px"
            borderRadius="6px"
            display="flex"
            alignItems="center"
            margin="0px 7px 10px"
            justifyContent="center"
            style={{ cursor: 'pointer' }}
            position="relative"
            onClick={() => {
              if (videoLimitReached) {
                setIsVideoLimitModalOpen(true)
              } else {
                setCameraAndScreenValue()
              }
            }}
          >
            {videoLimitReached && <StarIconButton onClick={setIsVideoLimitModalOpen} />}
            <IconSelectButton type="button">
              <BothRecording color={selected === 'SCREENANDCAM' && !videoLimitReached ? 'white' : undefined} />
              <Label color={selected === 'SCREENANDCAM' && !videoLimitReached ? 'white' : undefined}>
                {g('recordControl.newVideoModal.recordBothOption')}
              </Label>
            </IconSelectButton>
          </Box>
          {(location.pathname?.includes('chat') || location.pathname?.includes('support')) && (
            <Box
              bgcolor={theme.palette.background.paper}
              width="93px"
              height="96px"
              borderRadius="6px"
              display="flex"
              alignItems="center"
              margin="0px 7px 10px"
              justifyContent="center"
              style={{ cursor: 'pointer' }}
              onClick={openLibraryModal}
            >
              <Box>
                <IconSelectButton type="button">
                  <RecordFromLibrary />
                  <Box>
                    <Typography style={{ fontSize: '14px', paddingTop: '8px', width: '93px' }} color="textSecondary">
                      {g('recordControl.newVideoModal.recordFromLibraryOption')}
                    </Typography>
                  </Box>
                </IconSelectButton>
              </Box>
            </Box>
          )}
          {location.pathname === '/app' && (
            <Box
              bgcolor={theme.palette.background.paper}
              width="93px"
              height="96px"
              borderRadius="6px"
              display="flex"
              alignItems="center"
              margin="0px 7px 10px"
              justifyContent="center"
              style={{ cursor: 'pointer' }}
              onClick={openLibraryModal}
            >
              <Box>
                <IconSelectButton type="button" onClick={handleUploadVideoInputClick}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept="video/mp4"
                    onChange={handleUploadingProcess}
                  />
                  <UploadVideoIcon />
                  <Box>
                    <Typography style={{ fontSize: '14px', paddingTop: '8px', width: '93px' }} color="textSecondary">
                      {g('recordControl.newVideoModal.uploadVideoOption')}
                    </Typography>
                  </Box>
                </IconSelectButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {selected === 'CAM' && (
        <Box display="flex" width="50%" marginLeft="45%" alignItems="center" justifyContent="center" marginTop="40px">
          <FormControlLabel
            style={{ color: theme.palette.secondary.main }}
            control={
              <Checkbox
                inputRef={form.register}
                color="primary"
                style={{ boxShadow: 'none' }}
                id="check__login--remainSignedIn"
                name="teleprompter"
              />
            }
            label={g('recordControl.newVideoModal.teleprompterCheckbox')}
          />
        </Box>
      )}
    </Box>
  )
}

export default RecordingTypeRadioButtons
