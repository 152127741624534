import React from 'react'
import { Box } from '@mui/material'
import { ForgotPassword } from '../../common/config'
import { useForgotPasswordContext } from '../../common/context/ForgotPasswordContext'

const Content = () => {
  const forgotPasswordContext = useForgotPasswordContext()

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      alignSelf="center"
      maxWidth="400px"
      width="100%"
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        {forgotPasswordContext.currentView === 'EMAIL_VIEW' ? (
          <ForgotPassword.VerifyEmail />
        ) : (
          <ForgotPassword.NewPassword />
        )}
      </Box>
    </Box>
  )
}

export default Content
