import React from 'react'
import AppLayout from 'common/layouts/AppLayout'

import VideoList from 'pages/VideoList'
import VideoItem from 'pages/VideoItem'
import ClientAuthProvider from 'common/providers/ClientAuthProvider'
import ClientApiKeyProvider from 'common/providers/ClientIamProvider'
import { Redirect } from 'react-router'
import VideoNotFoundPage from '../../pages/VideoNotFound'

import SettingsLayout from 'pages/Settings'
import ProfileSettings from 'containers/Settings/Profile'
import PasswordSettings from 'containers/Settings/Password'
import Advanced from 'containers/Settings/Advanced'
import EmailNotifications from '../../containers/Settings/EmailNotifications'
import Integrations from '../../containers/Settings/Integrations'
import VideoStatistics from '../../pages/VideoStatistics'
import Contacts from '../../pages/Contacts/List'
import SingleContact from 'pages/Contacts/SingleContact'
import SingleContactEdit from 'pages/Contacts/SingleContactEdit'
import Chat from '../../pages/Chat'
import Support from '../../pages/Support'
import Branding from '../../containers/Settings/Branding'
import SignUpPage from '../../pages/Auth/pages/SignUp'
import LoginPage from '../../pages/Auth/pages/Login'
import ForgotPasswordPage from '../../pages/Auth/pages/ForgotPassword'
import VerifyPage from '../../pages/Auth/pages/Verify'
import EditThumbnail from '../../pages/VideoItem/pages/EditThumbnail'
import Billing from '../../pages/Settings/pages/billing'
import BillingPlans from '../../pages/Settings/pages/billingPlans'
import BillingCheckout from '../../pages/Settings/pages/billingCheckout'
import BillingConfirmation from '../../pages/Settings/pages/billingConfirmation'
// import ContactSales from '../../pages/Settings/pages/contactSales'

export interface IRoute<T = React.FunctionComponent> {
  path: string
  order: number
  component: T // could not work out a good way of managing this
}

export interface INestedRoute<T = { [key: string]: any }> {
  path: string
  order: number
  routes: T
  layout: any
}

interface IAuthRoutes extends Record<string, IRoute> {
  videoList: IRoute
  videoItem: IRoute
}

const routes: Record<string, IRoute | INestedRoute> = {
  notFound: {
    path: '/page-not-found',
    order: 2,
    component: VideoNotFoundPage,
  },
  auth: {
    path: '/app',
    order: 1,
    layout: ClientAuthProvider,
    routes: {
      provider: {
        path: '/app',
        order: 1,
        layout: AppLayout,
        routes: {
          videoList: {
            path: '/app',
            order: 1,
            component: VideoList,
          },
          chat: {
            path: '/app/chat',
            order: 1,
            component: Chat,
          },
          newChat: {
            path: '/app/chat/:id',
            order: 1,
            component: Chat,
          },
          contacts: {
            path: '/app/contacts',
            order: 1,
            component: Contacts,
          },
          singleContact: {
            path: '/app/contacts/:id',
            order: 1,
            component: SingleContact,
          },
          singleContactEdit: {
            path: '/app/contacts/:id/edit',
            order: 1,
            component: SingleContactEdit,
          },
          support: {
            path: '/app/support',
            order: 1,
            component: Support,
          },
          billingConfirmation: {
            path: '/app/settings/billing/confirmation',
            order: 1,
            component: BillingConfirmation,
          },
          billingPlans: {
            path: '/app/settings/billing/plans',
            order: 1,
            component: BillingPlans,
          },
          // contactSales: {
          //   path: '/app/settings/billing/contact-sales',
          //   order: 1,
          //   component: ContactSales,
          // },
          billingCheckout: {
            path: '/app/settings/billing/plans/:id',
            order: 1,
            component: BillingCheckout,
          },
          settings: {
            path: '/app/settings',
            order: 1,
            layout: SettingsLayout,
            routes: {
              profileSettings: {
                path: '/app/settings/profile',
                order: 1,
                component: ProfileSettings,
              },
              billingSettings: {
                path: '/app/settings/billing',
                order: 1,
                component: Billing,
              },
              passwordSettings: {
                path: '/app/settings/password',
                order: 1,
                component: PasswordSettings,
              },
              emailNotifications: {
                path: '/app/settings/email-notifications',
                order: 1,
                component: EmailNotifications,
              },
              integrations: {
                path: '/app/settings/integrations',
                order: 1,
                component: Integrations,
              },
              branding: {
                path: '/app/settings/branding',
                order: 1,
                component: Branding,
              },
              advancedSettings: {
                path: '/app/settings/advanced',
                order: 1,
                component: Advanced,
              },
              redir: {
                path: '*',
                order: 2,
                component: () => <Redirect to="/app/settings/profile" />,
              },
            },
          },
        },
      },
    },
  },
  unauth: {
    path: '/',
    order: 2,
    layout: ClientApiKeyProvider,
    routes: {
      provider: {
        path: '/',
        order: 1,
        layout: AppLayout,
        routes: {
          login: {
            path: '/login',
            order: 1,
            component: LoginPage,
          },
          signUp: {
            path: '/sign-up',
            order: 1,
            component: SignUpPage,
          },
          verify: {
            path: '/verify',
            order: 1,
            component: VerifyPage,
          },
          forgotPassword: {
            path: '/forgot-password',
            order: 1,
            component: ForgotPasswordPage,
          },
          videoItem: {
            path: '/video/:id',
            order: 1,
            component: VideoItem,
          },
          videoStatistics: {
            path: '/video/:id/stats',
            order: 1,
            component: VideoStatistics,
          },
          editThumbnail: {
            path: '/video/:id/edit-thumbnail',
            order: 1,
            component: EditThumbnail,
          },
          notFound: {
            path: '*',
            order: 3,
            component: () => <Redirect to="/app" />,
          },
          toMain: {
            path: '/',
            order: 4,
            component: () => <Redirect to="/app" />,
          },
        },
      },
    },
  },
}
export default routes
