import { useContext, useEffect } from 'react'
import RecorderStateContext from '../../../../common/providers/RecorderStateProvider/RecorderStateContext'

const usePrompterText = (
  increaseTextSpeed: () => void,
  decreaseTextSpeed: () => void,
  editorState: 'DEFAULT' | 'FONT' | 'SPEED' | 'TEXT',
  prompterRef: any,
  isPrompterPlaying: boolean,
) => {
  const { state: recorderState, dispatch: recorderDispatcher } = useContext(RecorderStateContext)

  useEffect(() => {
    if (!prompterRef?.current || editorState === 'TEXT' || !isPrompterPlaying) return
    const textInterval = setInterval(() => {
      prompterRef.current.scrollTo(0, prompterRef.current?.scrollTop + 1)
    }, 120 - recorderState.prompter.textSpeed)

    return () => clearInterval(textInterval)
  }, [recorderState.prompter.textSpeed, editorState, prompterRef?.current, isPrompterPlaying])

  const handleKeyEvent = (e: KeyboardEvent) => {
    switch (e.code) {
      case 'ArrowUp': {
        prompterRef.current.scrollTo(0, prompterRef.current?.scrollTop - 40)
        break
      }
      case 'ArrowRight': {
        const newVal = recorderState.prompter.textSpeed + 10
        if (recorderState.prompter.textSpeed <= 80) {
          recorderDispatcher({
            action: 'UPDATE_PROMPTER_STATE',
            value: { textSpeed: newVal },
          })
        }
        break
      }
      case 'ArrowDown': {
        prompterRef.current.scrollTo(0, prompterRef.current?.scrollTop + 40)
        break
      }
      case 'ArrowLeft': {
        const newVal = recorderState.prompter.textSpeed - 10
        if (recorderState.prompter.textSpeed >= 10 && recorderState.prompter.textSpeed < 20) {
          recorderDispatcher({
            action: 'UPDATE_PROMPTER_STATE',
            value: { textSpeed: 10 },
          })
        }
        if (recorderState.prompter.textSpeed >= 20) {
          recorderDispatcher({
            action: 'UPDATE_PROMPTER_STATE',
            value: { textSpeed: newVal },
          })
        }
        break
      }
      default:
        break
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyEvent)
    return () => window.removeEventListener('keydown', handleKeyEvent)
  }, [recorderState.prompter, prompterRef.current])

  return {
    prompterRef,
  }
}

export default usePrompterText
