import { styled } from '@mui/material'
import Button from '../../../../../../../../../../../components/_refactored/Button'
import { theme } from '../../../../../../../../../../../common/providers/MuiThemeProvider'

const AISummaryControlBtn = styled(Button)`
  box-shadow: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  &:hover {
    background-color: transparent;
    color: ${theme.palette.primary.main};
  }
  &:hover svg,
  &:hover svg path {
    fill: ${theme.palette.primary.main};
  }
`
export default AISummaryControlBtn
