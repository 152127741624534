import * as React from 'react'
import { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import CloudFrontImage from '../../../CloudFrontImage'
import PersonIcon from '../../../../../common/icons/PersonIcon'
import { AuthContext } from '../../../../../common/providers/AuthStatusProvider'

export const TemplateUserData = () => {
  const auth = useContext(AuthContext)

  return (
    <Box marginTop="16px" display="flex" flexDirection="column" alignItems="center">
      {auth.user?.profilePhoto?.key ? (
        <CloudFrontImage
          image={`public/${auth.user?.profilePhoto?.key}`}
          height="60"
          width="60"
          imgStyle={{ borderRadius: '100px', marginBottom: '10px' }}
        />
      ) : (
        <Box
          width="60px"
          height="60px"
          borderRadius="100px"
          bgcolor="background.paper"
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom="10px"
        >
          <PersonIcon />
        </Box>
      )}
      <Typography variant="body1" style={{ fontSize: '15px', marginBottom: '10px' }}>
        {auth.user?.firstName && auth.user.lastName
          ? `${auth.user.firstName} ${auth.user.lastName}`
          : `${auth.user?.email}`}
      </Typography>
    </Box>
  )
}

export default TemplateUserData
