import React from 'react'
import Content from './ui/partials/Content'
import PageTitle from '../../../../components/_refactored/PageTitle/PageTitle'
import { useTranslation } from 'react-i18next'

const Billing = () => {
  const { t: g } = useTranslation('general')

  return (
    <>
      <PageTitle name={g('pageName.billingSettings')} />
      <Content />
    </>
  )
}

export default Billing
