import React from 'react'
import { Typography } from '@mui/material'
import ActionSection from '../../blocks/ActionSection'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation('library')

  return (
    <>
      <Typography variant="body1" style={{ fontSize: '15px', marginBottom: '15px' }} color="textSecondary">
        {t('videoItem.editThumbnail.thumbnailDescriptionText')}
      </Typography>
      <ActionSection />
    </>
  )
}

export default Footer
