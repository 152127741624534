import React from 'react'
import { Box } from '@mui/material'

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      width="300px"
      marginTop="30px"
      height="100vh"
      padding="15px"
      id="chat__channels-drawer-scrollable-box"
      sx={{ overflowY: 'auto' }}
    >
      {children}
    </Box>
  )
}

export default Layout
