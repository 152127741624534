import { ChangeEventHandler, useEffect, useState } from 'react'

const useTimeToFirstInputAnalytics = () => {
  const [inputStartTime, setInputStartTime] = useState<number | null>(null)
  const [timeToFirstInput, setTimeToFirstInput] = useState(0)
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setInputStartTime(performance.now())
  }, [])

  const handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setInputValue(event.target.value)
    if (inputStartTime && event.target.value) {
      const time = performance.now() - inputStartTime
      const fixedTime = parseFloat(time.toFixed(2))
      console.log(`Time to first input: ${fixedTime}ms`)

      setTimeToFirstInput(fixedTime)
      setInputStartTime(null)
    }
  }

  return { handleInputChange, timeToFirstInput, inputValue }
}

export default useTimeToFirstInputAnalytics
