import React from 'react'
import { Box, Typography } from '@mui/material'
import Modal from '../Modal'
import useVideoAccessModalHook from './common/hooks/useVideoAccessModal.hook'
import { useTranslation } from 'react-i18next'
import { screensightVideo } from '../../../API'
import AccessForm from './ui/components/AccessForm'
import UnauthorizedModalHeader from '../UnauthorizedModalHeader/UnauthorizedModalHeader'
import AccessLockIcon from '../../../common/icons/AccessLockIcon'

interface IVideoAccessModalView {
  onCancel?: any
  videoId?: string
  onSuccess?: (data: screensightVideo) => any
}

const VideoAccessModal = (props: IVideoAccessModalView) => {
  const state = useVideoAccessModalHook(props.videoId, props?.onSuccess)
  const { t } = useTranslation('library')

  return (
    <Box
      position="fixed"
      left="0"
      top="0"
      width="100vw"
      height="100vh"
      overflow="hidden"
      bgcolor="background.default"
      zIndex={9999999}
    >
      <Modal open={true} style={{ zIndex: 100000000 }} boxStyle={{ padding: '0' }}>
        <>
          {!state.authorized && <UnauthorizedModalHeader handleSignInPush={state.handleSignInPush} />}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            bgcolor="background.default"
            borderRadius="10px"
            padding="40px 60px"
          >
            <Box
              borderRadius="50%"
              width="40px"
              height="40px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ backgroundColor: 'black', marginBottom: '20px' }}
            >
              <AccessLockIcon color={'white'} />
            </Box>
            <Typography variant="h6" align={'center'} style={{ fontWeight: 500, fontSize: '18px', color: 'black' }}>
              {t('videoItem.videoAccessModal.title')}
            </Typography>
            <Typography variant="h6" align={'center'} style={{ fontWeight: 500, fontSize: '18px', color: 'black' }}>
              {t('videoItem.videoAccessModal.subtitle')}
            </Typography>
            <AccessForm
              onCancel={props.onCancel}
              handleVerifyAccess={state.handleVerifyAccess}
              form={state.form}
              loading={state.loading}
              isPasswordShowed={state.isPasswordShowed}
              togglePasswordVisible={state.togglePasswordVisible}
              authorized={state.authorized}
            />
          </Box>
        </>
      </Modal>
    </Box>
  )
}

export default VideoAccessModal
