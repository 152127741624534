/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost($channelId: ID, $videoId: ID, $customerId: ID) {
    onCreatePost(
      channelId: $channelId
      videoId: $videoId
      customerId: $customerId
    ) {
      id
      channelId
      accessId
      customerId
      postType
      video {
        id
        createdAt
        customerId
        channelId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        title
        searchTitle
        status
        fileObject {
          key
          bucket
          accessLevel
          identityId
        }
        filePresignedUrl
        duration
        name
        thumbnailObject {
          key
          bucket
          accessLevel
          identityId
        }
        accessType
        accessId
        password
        width
        height
        viewsCountTotal
        viewsCountUnique
        viewsList {
          items {
            videoId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            guestName
            viewCount
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          nextToken
          count
        }
        commentsCount
        uploadedVideo {
          fileName
          videoContainer
          videoEncoding
        }
        transcriptionSummaryStatus
        transcriptionSummaryError
        transcriptionSummary
        transcriptionObject {
          key
          bucket
          accessLevel
          identityId
        }
        updatedAt
      }
      comment {
        id
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        videoId
        channelId
        parentId
        content
        richContent
        videoTimestamp
        resolved
        replies {
          items {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          nextToken
          count
        }
        replyCount
        parentCreatedAt
        createdAt
        updatedAt
      }
      emailMessage {
        id
        videoId
        customerId
        channelId
        to
        note
        emailStatus {
          email
          status
          reject_reason
          _id
          deliveredAt
          opens
        }
        subject
        contacts {
          email
          contactId
        }
        createdAt
        updatedAt
      }
      text {
        content
        richContent
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
      }
      file {
        fileName
        fileObject {
          key
          bucket
          accessLevel
          identityId
        }
        status
        customerId
        customerMeta {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        mimeType
        metadata
      }
      engagement {
        items {
          customerId
          seen
          seenAt
          videoProgress
        }
        nextToken
      }
      referencePostId
      referencePost {
        id
        channelId
        accessId
        customerId
        postType
        video {
          id
          createdAt
          customerId
          channelId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          title
          searchTitle
          status
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          filePresignedUrl
          duration
          name
          thumbnailObject {
            key
            bucket
            accessLevel
            identityId
          }
          accessType
          accessId
          password
          width
          height
          viewsCountTotal
          viewsCountUnique
          viewsList {
            items {
              videoId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              guestName
              viewCount
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          comments {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          commentsCount
          uploadedVideo {
            fileName
            videoContainer
            videoEncoding
          }
          transcriptionSummaryStatus
          transcriptionSummaryError
          transcriptionSummary
          transcriptionObject {
            key
            bucket
            accessLevel
            identityId
          }
          updatedAt
        }
        comment {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        emailMessage {
          id
          videoId
          customerId
          channelId
          to
          note
          emailStatus {
            email
            status
            reject_reason
            _id
            deliveredAt
            opens
          }
          subject
          contacts {
            email
            contactId
          }
          createdAt
          updatedAt
        }
        text {
          content
          richContent
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
        }
        file {
          fileName
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          status
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          mimeType
          metadata
        }
        engagement {
          items {
            customerId
            seen
            seenAt
            videoProgress
          }
          nextToken
        }
        referencePostId
        referencePost {
          id
          channelId
          accessId
          customerId
          postType
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          comment {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          emailMessage {
            id
            videoId
            customerId
            channelId
            to
            note
            emailStatus {
              email
              status
              reject_reason
              _id
              deliveredAt
              opens
            }
            subject
            contacts {
              email
              contactId
            }
            createdAt
            updatedAt
          }
          text {
            content
            richContent
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
          }
          file {
            fileName
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            status
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            mimeType
            metadata
          }
          engagement {
            items {
              customerId
              seen
              seenAt
              videoProgress
            }
            nextToken
          }
          referencePostId
          referencePost {
            id
            channelId
            accessId
            customerId
            postType
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            comment {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            emailMessage {
              id
              videoId
              customerId
              channelId
              to
              note
              emailStatus {
                email
                status
                reject_reason
                _id
                deliveredAt
                opens
              }
              subject
              contacts {
                email
                contactId
              }
              createdAt
              updatedAt
            }
            text {
              content
              richContent
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
            }
            file {
              fileName
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              status
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              mimeType
              metadata
            }
            engagement {
              items {
                customerId
                seen
                seenAt
                videoProgress
              }
              nextToken
            }
            referencePostId
            referencePost {
              id
              channelId
              accessId
              customerId
              postType
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              comment {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              emailMessage {
                id
                videoId
                customerId
                channelId
                to
                note
                emailStatus {
                  email
                  status
                  reject_reason
                  _id
                  deliveredAt
                  opens
                }
                subject
                contacts {
                  email
                  contactId
                }
                createdAt
                updatedAt
              }
              text {
                content
                richContent
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
              }
              file {
                fileName
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                status
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                mimeType
                metadata
              }
              engagement {
                items {
                  customerId
                  seen
                  seenAt
                  videoProgress
                }
                nextToken
              }
              referencePostId
              referencePost {
                id
                channelId
                accessId
                customerId
                postType
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                comment {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                emailMessage {
                  id
                  videoId
                  customerId
                  channelId
                  to
                  note
                  subject
                  createdAt
                  updatedAt
                }
                text {
                  content
                  richContent
                  customerId
                }
                file {
                  fileName
                  status
                  customerId
                  mimeType
                  metadata
                }
                engagement {
                  nextToken
                }
                referencePostId
                referencePost {
                  id
                  channelId
                  accessId
                  customerId
                  postType
                  referencePostId
                  readHorizon
                  postTempId
                  createdAt
                  updatedAt
                }
                readHorizon
                postTempId
                reactions {
                  nextToken
                }
                createdAt
                updatedAt
              }
              readHorizon
              postTempId
              reactions {
                items {
                  postId
                  customerId
                  unicode
                  videoTimestamp
                  channelId
                  incrementBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            readHorizon
            postTempId
            reactions {
              items {
                postId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                unicode
                videoTimestamp
                channelId
                incrementBy
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          readHorizon
          postTempId
          reactions {
            items {
              postId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              unicode
              videoTimestamp
              channelId
              incrementBy
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        readHorizon
        postTempId
        reactions {
          items {
            postId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            unicode
            videoTimestamp
            channelId
            incrementBy
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      readHorizon
      postTempId
      reactions {
        items {
          postId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          unicode
          videoTimestamp
          channelId
          incrementBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onPostEvent = /* GraphQL */ `
  subscription OnPostEvent($customerId: ID!) {
    onPostEvent(customerId: $customerId) {
      customerId
      channelId
      action
      post {
        id
        channelId
        accessId
        customerId
        postType
        video {
          id
          createdAt
          customerId
          channelId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          title
          searchTitle
          status
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          filePresignedUrl
          duration
          name
          thumbnailObject {
            key
            bucket
            accessLevel
            identityId
          }
          accessType
          accessId
          password
          width
          height
          viewsCountTotal
          viewsCountUnique
          viewsList {
            items {
              videoId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              guestName
              viewCount
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          comments {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          commentsCount
          uploadedVideo {
            fileName
            videoContainer
            videoEncoding
          }
          transcriptionSummaryStatus
          transcriptionSummaryError
          transcriptionSummary
          transcriptionObject {
            key
            bucket
            accessLevel
            identityId
          }
          updatedAt
        }
        comment {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        emailMessage {
          id
          videoId
          customerId
          channelId
          to
          note
          emailStatus {
            email
            status
            reject_reason
            _id
            deliveredAt
            opens
          }
          subject
          contacts {
            email
            contactId
          }
          createdAt
          updatedAt
        }
        text {
          content
          richContent
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
        }
        file {
          fileName
          fileObject {
            key
            bucket
            accessLevel
            identityId
          }
          status
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          mimeType
          metadata
        }
        engagement {
          items {
            customerId
            seen
            seenAt
            videoProgress
          }
          nextToken
        }
        referencePostId
        referencePost {
          id
          channelId
          accessId
          customerId
          postType
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          comment {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          emailMessage {
            id
            videoId
            customerId
            channelId
            to
            note
            emailStatus {
              email
              status
              reject_reason
              _id
              deliveredAt
              opens
            }
            subject
            contacts {
              email
              contactId
            }
            createdAt
            updatedAt
          }
          text {
            content
            richContent
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
          }
          file {
            fileName
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            status
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            mimeType
            metadata
          }
          engagement {
            items {
              customerId
              seen
              seenAt
              videoProgress
            }
            nextToken
          }
          referencePostId
          referencePost {
            id
            channelId
            accessId
            customerId
            postType
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            comment {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            emailMessage {
              id
              videoId
              customerId
              channelId
              to
              note
              emailStatus {
                email
                status
                reject_reason
                _id
                deliveredAt
                opens
              }
              subject
              contacts {
                email
                contactId
              }
              createdAt
              updatedAt
            }
            text {
              content
              richContent
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
            }
            file {
              fileName
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              status
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              mimeType
              metadata
            }
            engagement {
              items {
                customerId
                seen
                seenAt
                videoProgress
              }
              nextToken
            }
            referencePostId
            referencePost {
              id
              channelId
              accessId
              customerId
              postType
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              comment {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              emailMessage {
                id
                videoId
                customerId
                channelId
                to
                note
                emailStatus {
                  email
                  status
                  reject_reason
                  _id
                  deliveredAt
                  opens
                }
                subject
                contacts {
                  email
                  contactId
                }
                createdAt
                updatedAt
              }
              text {
                content
                richContent
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
              }
              file {
                fileName
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                status
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                mimeType
                metadata
              }
              engagement {
                items {
                  customerId
                  seen
                  seenAt
                  videoProgress
                }
                nextToken
              }
              referencePostId
              referencePost {
                id
                channelId
                accessId
                customerId
                postType
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                comment {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                emailMessage {
                  id
                  videoId
                  customerId
                  channelId
                  to
                  note
                  subject
                  createdAt
                  updatedAt
                }
                text {
                  content
                  richContent
                  customerId
                }
                file {
                  fileName
                  status
                  customerId
                  mimeType
                  metadata
                }
                engagement {
                  nextToken
                }
                referencePostId
                referencePost {
                  id
                  channelId
                  accessId
                  customerId
                  postType
                  referencePostId
                  readHorizon
                  postTempId
                  createdAt
                  updatedAt
                }
                readHorizon
                postTempId
                reactions {
                  nextToken
                }
                createdAt
                updatedAt
              }
              readHorizon
              postTempId
              reactions {
                items {
                  postId
                  customerId
                  unicode
                  videoTimestamp
                  channelId
                  incrementBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            readHorizon
            postTempId
            reactions {
              items {
                postId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                unicode
                videoTimestamp
                channelId
                incrementBy
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          readHorizon
          postTempId
          reactions {
            items {
              postId
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              unicode
              videoTimestamp
              channelId
              incrementBy
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        readHorizon
        postTempId
        reactions {
          items {
            postId
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            unicode
            videoTimestamp
            channelId
            incrementBy
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      videosLimitReached
    }
  }
`;
export const onPostsSeen = /* GraphQL */ `
  subscription OnPostsSeen($channelId: ID!) {
    onPostsSeen(channelId: $channelId) {
      customerId
      channelId
      timestamp
      source
      postIds
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
    }
  }
`;
export const onPostReaction = /* GraphQL */ `
  subscription OnPostReaction($channelId: ID) {
    onPostReaction(channelId: $channelId) {
      postId
      customerId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      unicode
      videoTimestamp
      channelId
      incrementBy
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVideo = /* GraphQL */ `
  subscription OnCreateVideo($customerId: ID!) {
    onCreateVideo(customerId: $customerId) {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
        accessLevel
        identityId
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
        accessLevel
        identityId
      }
      accessType
      accessId
      password
      width
      height
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
        count
      }
      commentsCount
      uploadedVideo {
        fileName
        videoContainer
        videoEncoding
      }
      transcriptionSummaryStatus
      transcriptionSummaryError
      transcriptionSummary
      transcriptionObject {
        key
        bucket
        accessLevel
        identityId
      }
      updatedAt
    }
  }
`;
export const onUpdateVideo = /* GraphQL */ `
  subscription OnUpdateVideo($channelId: ID, $id: ID, $customerId: ID) {
    onUpdateVideo(channelId: $channelId, id: $id, customerId: $customerId) {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
        accessLevel
        identityId
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
        accessLevel
        identityId
      }
      accessType
      accessId
      password
      width
      height
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
        count
      }
      commentsCount
      uploadedVideo {
        fileName
        videoContainer
        videoEncoding
      }
      transcriptionSummaryStatus
      transcriptionSummaryError
      transcriptionSummary
      transcriptionObject {
        key
        bucket
        accessLevel
        identityId
      }
      updatedAt
    }
  }
`;
export const onDeleteVideo = /* GraphQL */ `
  subscription OnDeleteVideo($channelId: ID, $id: ID, $customerId: ID) {
    onDeleteVideo(channelId: $channelId, id: $id, customerId: $customerId) {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
        accessLevel
        identityId
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
        accessLevel
        identityId
      }
      accessType
      accessId
      password
      width
      height
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
        count
      }
      commentsCount
      uploadedVideo {
        fileName
        videoContainer
        videoEncoding
      }
      transcriptionSummaryStatus
      transcriptionSummaryError
      transcriptionSummary
      transcriptionObject {
        key
        bucket
        accessLevel
        identityId
      }
      updatedAt
    }
  }
`;
export const onUpdateVideoComment = /* GraphQL */ `
  subscription OnUpdateVideoComment($channelId: ID, $videoId: ID) {
    onUpdateVideoComment(channelId: $channelId, videoId: $videoId) {
      id
      customerId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      videoId
      channelId
      parentId
      content
      richContent
      videoTimestamp
      resolved
      replies {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
        count
      }
      replyCount
      parentCreatedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVideoComment = /* GraphQL */ `
  subscription OnDeleteVideoComment($channelId: ID, $videoId: ID) {
    onDeleteVideoComment(channelId: $channelId, videoId: $videoId) {
      id
      customerId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      videoId
      channelId
      parentId
      content
      richContent
      videoTimestamp
      resolved
      replies {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
        count
      }
      replyCount
      parentCreatedAt
      createdAt
      updatedAt
    }
  }
`;
export const onSetVideoViewed = /* GraphQL */ `
  subscription OnSetVideoViewed {
    onSetVideoViewed {
      id
      createdAt
      customerId
      channelId
      customerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      title
      searchTitle
      status
      fileObject {
        key
        bucket
        accessLevel
        identityId
      }
      filePresignedUrl
      duration
      name
      thumbnailObject {
        key
        bucket
        accessLevel
        identityId
      }
      accessType
      accessId
      password
      width
      height
      viewsCountTotal
      viewsCountUnique
      viewsList {
        items {
          videoId
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          guestName
          viewCount
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          customerId
          customerMeta {
            id
            email
            firstName
            lastName
            profilePhoto {
              key
              bucket
              accessLevel
              identityId
            }
            brandLogo {
              key
              bucket
              accessLevel
              identityId
            }
            calendlyUrl
            lastSeen
            settings {
              notifyEmailWatched
              notifyEmailComment
              notifyUnseenMessages
              timezone
              pushNotifications {
                video
                comment
                reply
                text
              }
              lastChannel
              enableSplashScreen
              termsAndConditions {
                version
                accepted
                acceptedAt
              }
              channelRecentActivityDays
            }
            stripeId
            createdAt
            updatedAt
          }
          videoId
          channelId
          parentId
          content
          richContent
          videoTimestamp
          resolved
          replies {
            items {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            nextToken
            count
          }
          replyCount
          parentCreatedAt
          createdAt
          updatedAt
        }
        nextToken
        count
      }
      commentsCount
      uploadedVideo {
        fileName
        videoContainer
        videoEncoding
      }
      transcriptionSummaryStatus
      transcriptionSummaryError
      transcriptionSummary
      transcriptionObject {
        key
        bucket
        accessLevel
        identityId
      }
      updatedAt
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact($ownerCustomerId: ID) {
    onCreateContact(ownerCustomerId: $ownerCustomerId) {
      id
      ownerCustomerId
      firstName
      lastName
      email
      mobilePhone
      contactCustomerMeta {
        items {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact($ownerCustomerId: ID) {
    onUpdateContact(ownerCustomerId: $ownerCustomerId) {
      id
      ownerCustomerId
      firstName
      lastName
      email
      mobilePhone
      contactCustomerMeta {
        items {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact($ownerCustomerId: ID) {
    onDeleteContact(ownerCustomerId: $ownerCustomerId) {
      id
      ownerCustomerId
      firstName
      lastName
      email
      mobilePhone
      contactCustomerMeta {
        items {
          id
          email
          firstName
          lastName
          profilePhoto {
            key
            bucket
            accessLevel
            identityId
          }
          brandLogo {
            key
            bucket
            accessLevel
            identityId
          }
          calendlyUrl
          lastSeen
          settings {
            notifyEmailWatched
            notifyEmailComment
            notifyUnseenMessages
            timezone
            pushNotifications {
              video
              comment
              reply
              text
            }
            lastChannel
            enableSplashScreen
            termsAndConditions {
              version
              accepted
              acceptedAt
            }
            channelRecentActivityDays
          }
          stripeId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChannel = /* GraphQL */ `
  subscription OnCreateChannel($customerId: ID!) {
    onCreateChannel(customerId: $customerId) {
      id
      customerId
      channelType
      name
      categoryPosition
      readHorizon
      activity {
        new
        latestAt
        latestPost {
          id
          channelId
          accessId
          customerId
          postType
          video {
            id
            createdAt
            customerId
            channelId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            title
            searchTitle
            status
            fileObject {
              key
              bucket
              accessLevel
              identityId
            }
            filePresignedUrl
            duration
            name
            thumbnailObject {
              key
              bucket
              accessLevel
              identityId
            }
            accessType
            accessId
            password
            width
            height
            viewsCountTotal
            viewsCountUnique
            viewsList {
              items {
                videoId
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                guestName
                viewCount
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            comments {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            commentsCount
            uploadedVideo {
              fileName
              videoContainer
              videoEncoding
            }
            transcriptionSummaryStatus
            transcriptionSummaryError
            transcriptionSummary
            transcriptionObject {
              key
              bucket
              accessLevel
              identityId
            }
            updatedAt
          }
          comment {
            id
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
            videoId
            channelId
            parentId
            content
            richContent
            videoTimestamp
            resolved
            replies {
              items {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              nextToken
              count
            }
            replyCount
            parentCreatedAt
            createdAt
            updatedAt
          }
          emailMessage {
            id
            videoId
            customerId
            channelId
            to
            note
            emailStatus {
              email
              status
              reject_reason
              _id
              deliveredAt
              opens
            }
            subject
            contacts {
              email
              contactId
            }
            createdAt
            updatedAt
          }
          text {
            content
            richContent
            customerId
            customerMeta {
              id
              email
              firstName
              lastName
              profilePhoto {
                key
                bucket
                accessLevel
                identityId
              }
              brandLogo {
                key
                bucket
                accessLevel
                identityId
              }
              calendlyUrl
              lastSeen
              settings {
                notifyEmailWatched
                notifyEmailComment
                notifyUnseenMessages
                timezone
                pushNotifications {
                  video
                  comment
                  reply
                  text
                }
                lastChannel
                enableSplashScreen
                termsAndConditions {
                  version
                  accepted
                  acceptedAt
                }
                channelRecentActivityDays
              }
              stripeId
              createdAt
              updatedAt
            }
          }
          referencePostId
          referencePost {
            id
            channelId
            accessId
            customerId
            postType
            video {
              id
              createdAt
              customerId
              channelId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              title
              searchTitle
              status
              fileObject {
                key
                bucket
                accessLevel
                identityId
              }
              filePresignedUrl
              duration
              name
              thumbnailObject {
                key
                bucket
                accessLevel
                identityId
              }
              accessType
              accessId
              password
              width
              height
              viewsCountTotal
              viewsCountUnique
              viewsList {
                items {
                  videoId
                  customerId
                  guestName
                  viewCount
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              commentsCount
              uploadedVideo {
                fileName
                videoContainer
                videoEncoding
              }
              transcriptionSummaryStatus
              transcriptionSummaryError
              transcriptionSummary
              transcriptionObject {
                key
                bucket
                accessLevel
                identityId
              }
              updatedAt
            }
            comment {
              id
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
              videoId
              channelId
              parentId
              content
              richContent
              videoTimestamp
              resolved
              replies {
                items {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                nextToken
                count
              }
              replyCount
              parentCreatedAt
              createdAt
              updatedAt
            }
            emailMessage {
              id
              videoId
              customerId
              channelId
              to
              note
              emailStatus {
                email
                status
                reject_reason
                _id
                deliveredAt
                opens
              }
              subject
              contacts {
                email
                contactId
              }
              createdAt
              updatedAt
            }
            text {
              content
              richContent
              customerId
              customerMeta {
                id
                email
                firstName
                lastName
                profilePhoto {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                brandLogo {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                calendlyUrl
                lastSeen
                settings {
                  notifyEmailWatched
                  notifyEmailComment
                  notifyUnseenMessages
                  timezone
                  lastChannel
                  enableSplashScreen
                  channelRecentActivityDays
                }
                stripeId
                createdAt
                updatedAt
              }
            }
            referencePostId
            referencePost {
              id
              channelId
              accessId
              customerId
              postType
              video {
                id
                createdAt
                customerId
                channelId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                title
                searchTitle
                status
                fileObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                filePresignedUrl
                duration
                name
                thumbnailObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                accessType
                accessId
                password
                width
                height
                viewsCountTotal
                viewsCountUnique
                viewsList {
                  nextToken
                }
                comments {
                  nextToken
                  count
                }
                commentsCount
                uploadedVideo {
                  fileName
                  videoContainer
                  videoEncoding
                }
                transcriptionSummaryStatus
                transcriptionSummaryError
                transcriptionSummary
                transcriptionObject {
                  key
                  bucket
                  accessLevel
                  identityId
                }
                updatedAt
              }
              comment {
                id
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
                videoId
                channelId
                parentId
                content
                richContent
                videoTimestamp
                resolved
                replies {
                  nextToken
                  count
                }
                replyCount
                parentCreatedAt
                createdAt
                updatedAt
              }
              emailMessage {
                id
                videoId
                customerId
                channelId
                to
                note
                emailStatus {
                  email
                  status
                  reject_reason
                  _id
                  deliveredAt
                  opens
                }
                subject
                contacts {
                  email
                  contactId
                }
                createdAt
                updatedAt
              }
              text {
                content
                richContent
                customerId
                customerMeta {
                  id
                  email
                  firstName
                  lastName
                  calendlyUrl
                  lastSeen
                  stripeId
                  createdAt
                  updatedAt
                }
              }
              referencePostId
              referencePost {
                id
                channelId
                accessId
                customerId
                postType
                video {
                  id
                  createdAt
                  customerId
                  channelId
                  title
                  searchTitle
                  status
                  filePresignedUrl
                  duration
                  name
                  accessType
                  accessId
                  password
                  width
                  height
                  viewsCountTotal
                  viewsCountUnique
                  commentsCount
                  transcriptionSummaryStatus
                  transcriptionSummaryError
                  transcriptionSummary
                  updatedAt
                }
                comment {
                  id
                  customerId
                  videoId
                  channelId
                  parentId
                  content
                  richContent
                  videoTimestamp
                  resolved
                  replyCount
                  parentCreatedAt
                  createdAt
                  updatedAt
                }
                emailMessage {
                  id
                  videoId
                  customerId
                  channelId
                  to
                  note
                  subject
                  createdAt
                  updatedAt
                }
                text {
                  content
                  richContent
                  customerId
                }
                referencePostId
                referencePost {
                  id
                  channelId
                  accessId
                  customerId
                  postType
                  referencePostId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      chatPartnerId
      chatPartnerMeta {
        id
        email
        firstName
        lastName
        profilePhoto {
          key
          bucket
          accessLevel
          identityId
        }
        brandLogo {
          key
          bucket
          accessLevel
          identityId
        }
        calendlyUrl
        lastSeen
        settings {
          notifyEmailWatched
          notifyEmailComment
          notifyUnseenMessages
          timezone
          pushNotifications {
            video
            comment
            reply
            text
          }
          lastChannel
          enableSplashScreen
          termsAndConditions {
            version
            accepted
            acceptedAt
          }
          channelRecentActivityDays
        }
        stripeId
        createdAt
        updatedAt
      }
      latestPostAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer(
    $filter: ModelSubscriptionScreensightCustomerFilterInput
  ) {
    onUpdateCustomer(filter: $filter) {
      id
      email
      firstName
      lastName
      profilePhoto {
        key
        bucket
        accessLevel
        identityId
      }
      brandLogo {
        key
        bucket
        accessLevel
        identityId
      }
      calendlyUrl
      lastSeen
      settings {
        notifyEmailWatched
        notifyEmailComment
        notifyUnseenMessages
        timezone
        pushNotifications {
          video
          comment
          reply
          text
        }
        lastChannel
        enableSplashScreen
        termsAndConditions {
          version
          accepted
          acceptedAt
        }
        channelRecentActivityDays
      }
      stripeId
      createdAt
      updatedAt
    }
  }
`;
