import React from 'react'
import { Box, FormControlLabel, Switch, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface INotificationRow {
  title: string
  description: string
  submitAction?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  icon?: SVGAElement | React.ReactNode | any
  checked?: boolean
  loading: boolean
}

const NotificationRow = (props: INotificationRow) => {
  const { t } = useTranslation('settings')
  return (
    <Box marginBottom="29px">
      <Box display="flex" alignItems="center" marginBottom="8px">
        <>
          {props.icon}
          <Typography variant="body1" style={{ marginLeft: props.icon ? '8px' : '0', fontWeight: 500 }}>
            {props.title}
          </Typography>
        </>
      </Box>
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" color="textSecondary">
          {props.description}
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={props.checked}
              disabled={props.loading}
              defaultChecked={props?.checked}
              color="primary"
              onChange={props.submitAction}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              {props.checked ? t('emailNotifications.switchOn') : t('emailNotifications.switchOff')}
            </Typography>
          }
          labelPlacement="start"
        />
      </Box>
    </Box>
  )
}

export default NotificationRow
