import React from 'react'

import { PasswordProps } from '../index'
import { UsePassword } from '../hooks/usePassword.hook'
import { Controller } from 'react-hook-form'
import { Box, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material'
import Button from '../../../../components/_refactored/Button'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useTranslation } from 'react-i18next'

const PasswordView = (props: PasswordViewProps) => {
  const { form, switchOldPassword, switchNewPassword, oldPasswordShowed, newPasswordShowed } = props
  const theme = useTheme()
  const { t } = useTranslation('settings')
  const { t: g } = useTranslation('general')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <form onSubmit={form.submit}>
      <Box>
        <Controller
          name={'oldPassword'}
          control={form.control}
          rules={{
            required: g('common.requiredField'),
            validate: (value: string) =>
              value?.length > 100
                ? g('common.maxTextInputLength')
                : value?.length < 6
                ? t('password.minPasswordLength')
                : true,
          }}
          render={({ value, onChange, onBlur }) => (
            // TODO: Create common comonent
            <TextField
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              label={t('password.oldPasswordPlaceholder')}
              error={!!form.errors['oldPassword']}
              helperText={form.errors['oldPassword']?.message}
              variant="outlined"
              style={{ width: '100%' }}
              type={oldPasswordShowed ? 'text' : 'password'}
              InputProps={{
                style: {
                  fontSize: '16px',
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" style={{ boxShadow: 'none' }} onClick={switchOldPassword}>
                      {!oldPasswordShowed ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Box height="24px" />
        <Controller
          name={'newPassword'}
          control={form.control}
          rules={{
            required: g('common.requiredField'),
            validate: (value: string) =>
              value?.length > 100
                ? g('common.maxTextInputLength')
                : value?.length < 9
                ? t('password.minPasswordLength')
                : true,
          }}
          render={({ value, onChange, onBlur }) => (
            <TextField
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              label={t('password.newPasswordPlaceholder')}
              error={!!form.errors['newPassword']}
              helperText={form.errors['newPassword']?.message}
              variant="outlined"
              style={{ width: '100%' }}
              type={newPasswordShowed ? 'text' : 'password'}
              InputProps={{
                style: {
                  fontSize: '16px',
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" style={{ boxShadow: 'none' }} onClick={switchNewPassword}>
                      {!newPasswordShowed ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} marginTop="24px">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!form.formState.isDirty || !form.formState.isValid}
            style={{ marginRight: !isMobile ? '8px' : '', width: isMobile ? '100%' : '' }}
          >
            {g('common.saveChangesBtn')}
          </Button>
          {form.formState.isDirty && (
            <Button
              variant="text"
              color="secondary"
              onClick={() => form.reset()}
              style={{ width: isMobile ? '100%' : '', marginTop: isMobile ? '12px' : '' }}
            >
              {g('common.cancelBtn')}
            </Button>
          )}
        </Box>
      </Box>
    </form>
  )
}

export type PasswordViewProps = PasswordProps & UsePassword

export default PasswordView
