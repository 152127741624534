import { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../../../../../../../../../common/providers/AuthStatusProvider'
import { useLocation } from 'react-router'
import * as queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { useChatContext, useChatDispatch } from '../../../../../common/contexts/ChatContext'
import { GetVideoQuery, GetVideoQueryVariables, screensightVideo } from '../../../../../../../../../../API'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { getVideoPresignedUrl } from '../../../../../../../../../../common/api/graphql/queries'
import CognitoApolloClient from '../../../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../../../common/clients/IamApolloClient'
import { getUsername } from '../../../../../../../../../../common/utils/getUsername'
import useClient from '../../../../../../../../../../common/clients/useClient'
import { VideoJsPlayer } from 'video.js'

const useChatPlayer = () => {
  const playerRef = useRef<VideoJsPlayer | null>(null)
  const auth = useContext(AuthContext)
  const location = useLocation()
  const client = useClient()
  const videoIdQueryParam = queryString.parse(location.search)?.play
  const postIdQueryParam = queryString.parse(location.search)?.postId
  const history = useHistory()
  const wrapperRef = useRef<HTMLElement>(null)
  const playerWrapperRef = useRef<HTMLElement>(null)
  const chatDispatcher = useChatDispatch()
  const chatContext = useChatContext()
  const [privateVideoData, setPrivateVideoData] = useState<screensightVideo | null | undefined>(null)
  const [chatPlayerLoader, setChatPlayerLoader] = useState(false)
  const [quillExpanded, setQuillExpanded] = useState(true)

  useEffect(() => {
    setChatPlayerLoader(true)
    setTimeout(() => setChatPlayerLoader(false), 20)
  }, [postIdQueryParam])

  const { data: videoData } = useQuery<GetVideoQuery, GetVideoQueryVariables>(gql(getVideoPresignedUrl), {
    variables: { id: videoIdQueryParam as string },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    skip: !videoIdQueryParam || auth.user?.pending,
    client: auth.isAuthenticated ? CognitoApolloClient : IamApolloClient,
  })

  useEffect(() => {
    if (!videoData?.getVideo?.id) return
    if (privateVideoData) {
      chatDispatcher({
        type: 'setResponseBoxData',
        value: {
          type: 'comment',
          videoId: privateVideoData?.id,
          title: privateVideoData?.title,
          author: getUsername(
            privateVideoData?.customerMeta?.firstName,
            privateVideoData?.customerMeta?.lastName,
            privateVideoData?.customerMeta?.email,
          ),
          photoKey: privateVideoData?.thumbnailObject?.key,
          postId: postIdQueryParam as string,
        },
      })
    } else {
      chatDispatcher({
        type: 'setResponseBoxData',
        value: {
          type: 'comment',
          customerId: videoData?.getVideo?.customerId,
          videoId: videoData?.getVideo?.id,
          title: videoData?.getVideo?.title,
          author: getUsername(
            videoData?.getVideo?.customerMeta?.firstName,
            videoData?.getVideo?.customerMeta?.lastName,
            videoData?.getVideo?.customerMeta?.email,
          ),
          photoKey: videoData?.getVideo?.thumbnailObject?.key,
          postId: postIdQueryParam as string,
        },
      })
    }

    return () => {
      if (postIdQueryParam !== chatContext.responseBoxData?.postId) return
      chatDispatcher({
        type: 'setResponseBoxData',
        value: null,
      })
    }
  }, [videoData?.getVideo, postIdQueryParam, privateVideoData])

  const handleVerifyAccess = (data: screensightVideo) => {
    setPrivateVideoData(data)
  }

  const toggleHeight = () => {
    setQuillExpanded(!quillExpanded)
  }

  useEffect(() => {
    setPrivateVideoData(null)
  }, [postIdQueryParam])

  return {
    auth,
    history,
    wrapperRef,
    playerWrapperRef,
    videoIdQueryParam,
    postIdQueryParam,
    handleVerifyAccess,
    videoData,
    privateVideoData,
    chatPlayerLoader,
    playerRef,
    quillExpanded,
    toggleHeight,
  }
}

export default useChatPlayer
