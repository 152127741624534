import React from 'react'
import useSingleContact from './hooks/useSingleContact'
import SingleContactView from './view/SingleContact.view'

const SingleContact = () => {
  const state = useSingleContact()
  return <SingleContactView {...state} />
}

export default SingleContact
