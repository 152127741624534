import React, { useEffect } from 'react'
import BillingType from '../../blocks/BillingType'
import { Box } from '@mui/material'
import FormTextField from '../../../../../../../containers/Contacts/FormTextField'
import { useForm } from 'react-hook-form'
import Button from '../../../../../../../components/_refactored/Button'
import './style.scss'
import { PaymentMethod } from '../../../../../../../API'
import { useTranslation } from 'react-i18next'

export interface IBillingForm {
  cardNumber: string
  name: string
  billingType: 'year' | 'month'
  expiration: string
}

interface IProps {
  paymentMethod: PaymentMethod | undefined
  prices: { year: string; month: string }
  handleChangeBillingType: (type: 'year' | 'month') => void
  updatePlan: any
  updating: boolean
}

const Form = (props: IProps) => {
  const { t } = useTranslation('settings')
  const { t: g } = useTranslation('general')

  const form = useForm<IBillingForm>({
    mode: 'onChange',
    defaultValues: {
      cardNumber: `**** **** **** ${props.paymentMethod?.lastFourDigits}`,
      billingType: 'year',
      name: props.paymentMethod?.nameOnCard || '',
      expiration: `${props.paymentMethod?.expirationMonth}/${props.paymentMethod?.expirationYear}`,
    },
  })

  useEffect(() => {
    form.register('billingType')
  }, [form.register])

  const handleSetBillingType = (type: 'month' | 'year') => {
    form.setValue('billingType', type)
    props.handleChangeBillingType(type)
  }

  return (
    <form className="billing-form">
      <BillingType control={form.control} handleSetBillingType={handleSetBillingType} prices={props.prices} />
      <Box marginTop="16px">
        <FormTextField
          disabled
          type="text"
          topLabel={t('billing.purchase.cardNumber')}
          fieldName="cardNumber"
          form={form}
          marginBottom="0"
          rules={{
            validate: (value: string) => (value?.length > 30 ? t('billing.purchase.maxLengthError') : true),
          }}
        />
      </Box>
      <Box marginTop="16px">
        <FormTextField
          disabled
          type="text"
          topLabel={t('billing.purchase.cardExpirationDate')}
          fieldName="expiration"
          form={form}
          marginBottom="0"
          rules={{
            validate: (value: string) => (value?.length > 30 ? t('billing.purchase.maxLengthError') : true),
          }}
        />
      </Box>
      <Box marginTop="16px">
        <FormTextField
          type="text"
          topLabel={t('billing.purchase.nameOnCard')}
          fieldName="name"
          form={form}
          disabled
          marginBottom="0"
          rules={{
            validate: (value: string) => (value?.length > 30 ? t('billing.purchase.maxLengthError') : true),
          }}
        />
      </Box>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        style={{ marginTop: '32px' }}
        onClick={props.updatePlan}
        loading={props.updating}
      >
        {g('common.submitBtn')}
      </Button>
    </form>
  )
}

export default Form
