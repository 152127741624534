import { useCallback, useContext, useEffect, useState } from 'react'
import { IndexedRecordingEntity } from '../../../../common/services/IndexedDB'
import UploadQueue from '../../../../common/services/UploadQueue'
import DeleteConfirmContext from '../../../../common/providers/DeleteConfirmProvider/DeleteConfirmContext'
import build from '../../../../common/services/BuildNumber'
import { useTranslation } from 'react-i18next'

const useAdvancedHook = () => {
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState<IndexedRecordingEntity[]>([])
  const { t } = useTranslation('settings')

  const load = useCallback(async () => {
    const list = await UploadQueue.getRecordingsToRestore()
    setList(list)
    setLoading(false)
  }, [])

  const entityUpload = useCallback(
    async (uuid: string) => {
      await UploadQueue.restoreRecording(uuid)
      load()
    },
    [load],
  )

  const entityDelete = useCallback(
    async (uuid: string) => {
      await UploadQueue.deleteStoredRecording(uuid)
      load()
    },
    [load],
  )

  useEffect(() => {
    load()
  }, [load])

  const deleteModal = useContext(DeleteConfirmContext)

  const onClearLocalStorage = useCallback(() => {
    deleteModal.open({
      title: t('advanced.modalClearStorageTitle'),
      subtitle: t('advanced.modalClearStorageSubtitle'),
      callback: async () => {
        await UploadQueue.clearAllStoredRecordings()
        load()
      },
    })
  }, [deleteModal, load])

  return {
    entities: {
      loading,
      list,
      upload: entityUpload,
      delete: entityDelete,
    },
    build,
    onClearLocalStorage,
  }
}

export type UseAdvanced = ReturnType<typeof useAdvancedHook>
export default useAdvancedHook
