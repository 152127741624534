import useClient from '../../clients/useClient'
import gql from 'graphql-tag'
import { screensightVideo } from '../../../API'
import { disableFragmentWarnings } from 'graphql-tag'

const useGetVideoFragment = (id?: string | undefined | null, customGql?: string): screensightVideo | null => {
  const client = useClient()
  disableFragmentWarnings()

  if (!id) return null

  if (!customGql) {
    return client.cache.readFragment({
      id: `screensightVideo:${id}`,
      fragment: gql`
        fragment video${String(new Date().getTime())} on screensightVideo {
          id
          customerId
          password
          createdAt
          thumbnailObject {
            key
            bucket
          }
          title
          status
          accessId
          accessType
          filePresignedUrl
          fileObject 
          viewsCountUnique
          transcriptionSummaryStatus
          transcriptionSummaryError
          transcriptionSummary
          transcriptionObject {
            key
           bucket
          }
        }
      `,
    })
  } else {
    return client.cache.readFragment({
      id: `screensightVideo:${id}`,
      fragment: gql`
        fragment video${String(new Date().getTime())} on screensightVideo ${customGql}
      `,
    })
  }
}
export default useGetVideoFragment
