import { Typography } from '@mui/material'
import { theme } from '../../../../../../../../../../common/providers/MuiThemeProvider'
import * as React from 'react'

const VideoSummary = (props: { transcriptionSummary: string }) => {
  return (
    <Typography
      variant={'body1'}
      sx={{
        fontSize: '12px',
        color: theme.palette.secondary.main,
        whiteSpace: 'pre-wrap',
      }}
    >
      {props.transcriptionSummary}
    </Typography>
  )
}

export default VideoSummary
