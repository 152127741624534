import React from 'react'
import ContactsListView from './view/ContactsList.view'
import useContactsList from './hooks/useContactsList'
import { screensightContact } from '../../../API'

interface IContactsList {
  searchPhrase: string
  searchValue: string
  hasMore: boolean
  getNextContacts: () => void
  loading: boolean
  contacts: screensightContact[]
  showNewContactModal: () => void
}

const ContactsList = (props: IContactsList) => {
  const state = useContactsList()
  return <ContactsListView {...props} {...state} />
}

export default ContactsList
