import { ErrorCode, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useMemo } from 'react'
import ToastContext from '../../../../../../../common/providers/ToastProvider/ToastContext'

const useFileDropzone = () => {
  const { t } = useTranslation('chat')
  const { open } = useContext(ToastContext)

  const acceptedFormats = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ]

  const validateFile = (file: File) => {
    if (file.size > 157286400) {
      open({ type: 'ERROR', text: t('chatBody.fileUpload.sizeError') })
      return {
        message: t('chatBody.fileUpload.sizeError'),
        code: ErrorCode.FileTooLarge,
      }
    } else if (!acceptedFormats.includes(file.type)) {
      open({ type: 'ERROR', text: t('chatBody.fileUpload.typeError') })
      return {
        message: t('chatBody.fileUpload.typeError'),
        code: ErrorCode.FileInvalidType,
      }
    }
    return null
  }

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, rootRef, fileRejections } = useDropzone({
    accept: acceptedFormats.join(', '),
    maxFiles: 1,
    maxSize: 157286400,
    validator: (file) => {
      return validateFile(file)
    },
  })

  useEffect(() => {
    if (fileRejections.length > 1) {
      open({ type: 'ERROR', text: t('chatBody.fileUpload.amountError') })
    }
  }, [fileRejections])

  const dropzoneRootProps = useMemo(() => {
    const obj: React.HTMLAttributes<HTMLElement> = getRootProps({ onClick: (e) => e.stopPropagation() })
    delete obj.onClick
    delete obj.onFocus
    delete obj.onBlur
    return obj
  }, [])

  return { acceptedFiles, acceptedFormats, dropzoneRootProps, getInputProps, isDragActive, rootRef, validateFile }
}
export default useFileDropzone
