import React from 'react'

const DeleteCrossIcon = ({ fill = '#7A7A86' }) => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5.96912" y="7.47534" width="2.5" height="19" transform="rotate(-45.3921 5.96912 7.47534)" fill={fill} />
      <rect x="7.47528" y="20.9004" width="2.5" height="19" transform="rotate(-135.392 7.47528 20.9004)" fill={fill} />
    </svg>
  )
}

export default DeleteCrossIcon
