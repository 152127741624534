import * as React from 'react'

const StarPro = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_17489_406720)">
        <path
          d="M12 0.5C5.65849 0.5 0.5 5.6585 0.5 12C0.5 18.3415 5.65849 23.5 12 23.5C18.3415 23.5 23.5 18.3415 23.5 12C23.5 5.6585 18.3415 0.5 12 0.5Z"
          fill="#2483F7"
          stroke="white"
        />
        <path
          d="M12 16.2719L7.05574 19L8.16001 13.5217L4 9.72949L9.62676 9.07186L12 4L14.3733 9.07186L20 9.72949L15.8401 13.5217L16.9443 19L12 16.2719Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_17489_406720">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default StarPro
