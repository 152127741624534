import gql from 'graphql-tag'
import { screensightCustomer } from '../../../API'
import useClient from '../../clients/useClient'

const useGetScreensightCustomer = (id?: string | null, customGql?: string): screensightCustomer | null => {
  const client = useClient()
  const randomString = String(Math.floor(Math.random() * 1000))
  if (!id) return null

  if (!customGql) {
    return client.readFragment({
      id: `screensightCustomer:${id}`,
      fragment: gql`
        fragment user${randomString} on screensightCustomer {
          id
          email
          firstName
          lastName
          profilePhoto
          brandLogo
          calendlyUrl
          lastSeen
          settings
          createdAt
          updatedAt
        }
      `,
    })
  } else {
    return client.readFragment({
      id: `screensightCustomer:${id}`,
      fragment: gql`
        fragment user${randomString} on screensightCustomer ${customGql}
      `,
    })
  }
}

export default useGetScreensightCustomer
