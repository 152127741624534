import React from 'react'
import build from 'common/services/BuildNumber'
import { UseLayout } from '../hooks/useSettingsLayout.hook'
import { Box, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { LayoutProps } from '../index'
import { useTranslation } from 'react-i18next'

const SettingsLayoutView = (props: LayoutViewProps) => {
  const {
    children,
    fullName,
    pageName,
    active: { isProfile, isBilling, isPassword, isEmailNotifications, isIntegrations, isBranding, isAdvanced },
  } = props
  const { t } = useTranslation('settings')
  return (
    <Box padding={'80px 20px 20px 20px'} position="relative" minHeight="100vh">
      <Box display="flex" alignItems="center" marginBottom="32px">
        <Typography variant="h6" color="textSecondary">
          {fullName}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          &nbsp;/&nbsp;
        </Typography>
        <Typography variant="h6" color="textPrimary">
          {pageName}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Link to="/app/settings/profile">
            <Typography
              variant="h6"
              color={isProfile ? 'textPrimary' : 'textSecondary'}
              style={{ marginBottom: '6px' }}
            >
              {t('menu.profile')}
            </Typography>
          </Link>
          <Link to="/app/settings/billing">
            <Typography
              variant="h6"
              color={isBilling ? 'textPrimary' : 'textSecondary'}
              style={{ marginBottom: '6px' }}
            >
              {t('menu.billing')}
            </Typography>
          </Link>
          <Link to="/app/settings/password">
            <Typography
              variant="h6"
              color={isPassword ? 'textPrimary' : 'textSecondary'}
              style={{ marginBottom: '6px' }}
            >
              {t('menu.password')}
            </Typography>
          </Link>
          <Link to="/app/settings/email-notifications">
            <Typography
              variant="h6"
              color={isEmailNotifications ? 'textPrimary' : 'textSecondary'}
              style={{ marginBottom: '6px' }}
            >
              {t('menu.emailNotifications')}
            </Typography>
          </Link>
          <Link to="/app/settings/integrations">
            <Typography
              variant="h6"
              color={isIntegrations ? 'textPrimary' : 'textSecondary'}
              style={{ marginBottom: '6px' }}
            >
              {t('menu.integrations')}
            </Typography>
          </Link>
          <Link to="/app/settings/branding">
            <Typography
              variant="h6"
              color={isBranding ? 'textPrimary' : 'textSecondary'}
              style={{ marginBottom: '6px' }}
            >
              {t('menu.branding')}
            </Typography>
          </Link>
          <Link to="/app/settings/advanced">
            <Typography
              variant="h6"
              color={isAdvanced ? 'textPrimary' : 'textSecondary'}
              style={{ marginBottom: '6px' }}
            >
              {t('menu.advanced')}
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
      <Box width="100%" textAlign="center" marginTop="20px">
        <Typography variant="body2" color="secondary">
          {t('layout.buildNumber')}: {build}
        </Typography>
      </Box>
    </Box>
  )
}

export type LayoutViewProps = LayoutProps & UseLayout

export default SettingsLayoutView
