import React from 'react'
import { Box, CircularProgress } from '@mui/material'

const ListLoader = () => {
  return (
    <Box
      width="calc(100% - 1px)"
      height="100%"
      top="80px"
      left="1px"
      bgcolor="#fff"
      zIndex={11}
      overflow="hidden"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  )
}

export default ListLoader
