import * as React from 'react'
import { Box } from '@mui/material'

type Props = { color?: string }

const GearIcon = (props: Props) => {
  return (
    <Box display="flex">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.05493 0.666656L6.6464 2.76952C5.9596 3.02853 5.32891 3.3916 4.77629 3.84537L2.75643 3.14875L0.809814 6.51789L2.42765 7.92414C2.36546 8.306 2.33325 8.65986 2.33325 8.99999C2.33325 9.34063 2.36642 9.69384 2.42765 10.0758V10.0775L0.809814 11.4837L2.75643 14.8512L4.77466 14.1562C5.32733 14.6103 5.95945 14.9713 6.6464 15.2305L7.05493 17.3333H10.9449L11.3534 15.2305C12.0407 14.9713 12.6707 14.6088 13.2236 14.1546L15.2434 14.8512L17.1884 11.4837L15.5722 10.0758C15.6344 9.69398 15.6666 9.34012 15.6666 8.99999C15.6666 8.66037 15.6342 8.30697 15.5722 7.92577V7.92414L17.19 6.51627L15.2434 3.14875L13.2252 3.84374C12.6725 3.38972 12.0404 3.02868 11.3534 2.76952L10.9449 0.666656H7.05493ZM8.42863 2.33332H9.57121L9.8951 3.99999L10.7642 4.32877C11.2881 4.52621 11.7588 4.79729 12.1672 5.1328L12.8866 5.72199L14.4882 5.17186L15.0595 6.15982L13.7802 7.2731L13.9267 8.18944V8.19107C13.9776 8.50352 13.9999 8.76563 13.9999 8.99999C13.9999 9.23435 13.9776 9.49642 13.9267 9.80891L13.7786 10.7252L15.0579 11.8385L14.4866 12.8281L12.8866 12.2764L12.1656 12.8672C11.7572 13.2027 11.2881 13.4738 10.7642 13.6712H10.7626L9.89347 14L9.56958 15.6667H8.42863L8.10474 14L7.2356 13.6712C6.71171 13.4738 6.24102 13.2027 5.8326 12.8672L5.1132 12.278L3.51164 12.8281L2.94035 11.8402L4.22127 10.7252L4.07316 9.81216V9.81054C4.02293 9.49673 3.99992 9.23388 3.99992 8.99999C3.99992 8.76563 4.0222 8.50356 4.07316 8.19107L4.22127 7.27473L2.94035 6.16145L3.51164 5.17186L5.1132 5.72362L5.8326 5.1328C6.24102 4.79729 6.71171 4.52621 7.2356 4.32877L8.10474 3.99999L8.42863 2.33332ZM8.99992 5.66666C7.16948 5.66666 5.66658 7.16955 5.66658 8.99999C5.66658 10.8304 7.16948 12.3333 8.99992 12.3333C10.8304 12.3333 12.3333 10.8304 12.3333 8.99999C12.3333 7.16955 10.8304 5.66666 8.99992 5.66666ZM8.99992 7.33332C9.92615 7.33332 10.6666 8.07376 10.6666 8.99999C10.6666 9.92622 9.92615 10.6667 8.99992 10.6667C8.07369 10.6667 7.33325 9.92622 7.33325 8.99999C7.33325 8.07376 8.07369 7.33332 8.99992 7.33332Z"
          fill={props.color || '#000000'}
        />
      </svg>
    </Box>
  )
}

export default GearIcon
