import React, { Dispatch, SetStateAction } from 'react'
import { Box, Table, TableBody, Typography } from '@mui/material'
import { TableContainer } from '@mui/material'
import ContactRow from '../components/ContactRow'
import FullScreenLoader from '../../../../components/_refactored/Loaders/FullScreenLoader'
import DeleteContactModal from '../../DeleteContactModal'
import { screensightContact } from '../../../../API'
import AddIcon from '@mui/icons-material/Add'
import Button from '../../../../components/_refactored/Button'
import NewContactIcon from '../../../../common/icons/NewContactIcon'
import { useTranslation } from 'react-i18next'

interface IContactsListView {
  contacts: screensightContact[]
  loading: boolean
  setContactToDelete: Dispatch<SetStateAction<string | null>>
  contactToDelete: string | null
  hasMore: boolean
  getNextContacts: () => void
  searchPhrase: string
  searchValue: string
  showNewContactModal: () => void
}

const ContactsListView = (props: IContactsListView) => {
  const { t } = useTranslation('contacts')
  const { t: g } = useTranslation('general')
  return (
    <Box marginTop="80px" maxWidth="100vw">
      {props.contactToDelete ? (
        <DeleteContactModal id={props.contactToDelete} onCancel={() => props.setContactToDelete(null)} />
      ) : null}
      <Typography
        variant="h3"
        color="secondary"
        style={{
          padding: 30,
          fontWeight: 500,
        }}
      >
        {t('contactsList.title')}
      </Typography>
      {props.loading && <FullScreenLoader withoutSidebar />}
      {props?.contacts?.length > 0 ? (
        <Box marginTop="50px" padding="30px">
          <TableContainer style={{ overflowX: 'auto', borderTop: '1px solid #BCBCC3' }}>
            <Table aria-label="contacts table" style={{ minWidth: '700px' }}>
              <TableBody data-testid="contact-list-table">
                {props.contacts?.length &&
                  props.contacts?.map(
                    (row) =>
                      row && (
                        <ContactRow
                          data-testid={`contact-list-row-${row.email}`}
                          name={
                            row?.firstName && row?.lastName
                              ? row?.firstName + ' ' + row?.lastName
                              : row?.firstName
                              ? row?.firstName
                              : row?.lastName
                              ? row.lastName
                              : ''
                          }
                          email={row.email}
                          image={row.contactCustomerMeta?.items[0]?.profilePhoto?.key}
                          phone={row.mobilePhone}
                          id={row.id}
                          key={row.id}
                          setContactToDelete={props.setContactToDelete}
                        />
                      ),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : props.searchPhrase ? (
        <Typography variant="h6" style={{ marginTop: '16px' }} align="center" color="textPrimary">
          {t('contactsList.emptySearchResult')}
        </Typography>
      ) : (
        <Box
          width="100vw"
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          position="fixed"
          top="0"
          left="0"
          bgcolor="transparent"
        >
          <Box
            width="75px"
            height="75px"
            bgcolor="#F9FAFC"
            padding="5px"
            borderRadius="100px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <NewContactIcon />
          </Box>
          <Typography variant="h6" style={{ marginTop: '16px', fontWeight: 500 }} align="center" color="textPrimary">
            {t('contactsList.emptyList.title')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={props.showNewContactModal}
            style={{ fontSize: '16px', marginTop: '20px', paddingLeft: '20px', paddingRight: '20px' }}
            data-testid="add-contact-when-list-is-empty-btn"
          >
            <Box marginRight="10px" display="flex" alignItems="center">
              <AddIcon />
            </Box>
            {g('common.addContactBtn')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default ContactsListView
