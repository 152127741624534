import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSidebarWidth } from '../../../../../common/redux/store/chat-actions'

const useResize = () => {
  const sidebarParams = useSelector((state: any) => state.chat.sidebarParams)
  const dispatch = useDispatch()
  const getStoredParams = () => {
    return JSON.parse(localStorage.getItem('rightSidePanelParams') || '{}')
  }
  const handleWindowResizing = async () => {
    await getStoredParams()

    dispatch(
      updateSidebarWidth({
        width: getStoredParams().width,
        height: '100%',
        x: window.innerWidth - getStoredParams().width - 60,
        y: 0,
      }),
    )
  }

  useEffect(() => {
    handleWindowResizing()
    window.addEventListener('resize', () => handleWindowResizing())
    return () => window.removeEventListener('resize', () => handleWindowResizing())
  }, [])

  useEffect(() => {
    localStorage.setItem('rightSidePanelParams', JSON.stringify(sidebarParams))
  }, [sidebarParams])

  return { sidebarParams }
}
export default useResize
