import React from 'react'
import { Box, Typography } from '@mui/material'
import Card from '../../../../ui/components/Card'
import { Link } from 'react-router-dom'
import { theme } from '../../../../../../common/providers/MuiThemeProvider'
import { useTranslation } from 'react-i18next'

const ExplorePlans = () => {
  const { t } = useTranslation('settings')
  return (
    <Card>
      <Typography variant="h6" style={{ fontSize: '12px', marginBottom: '10px' }}>
        {t('billing.main.exploreAndUpgrade')}
      </Typography>
      <Typography variant="h6" style={{ fontSize: '20px', fontWeight: 600 }}>
        {t('billing.main.explorePlansTitle')}
      </Typography>
      <Typography
        variant="h6"
        color="textSecondary"
        style={{ fontSize: '15px', marginTop: '20px', marginBottom: '35px' }}
      >
        {t('billing.main.explorePlansDescription')}
      </Typography>
      <Link
        to="/app/settings/billing/plans"
        style={{ fontWeight: 500, color: theme.palette.primary.main, fontSize: '16px' }}
      >
        {t('billing.main.explorePlansBtn')}
      </Link>
    </Card>
  )
}

export default ExplorePlans
