import { Box } from '@mui/material'
import * as React from 'react'

const CopyIcon = () => {
  return (
    <Box display="flex">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.1438 -0.000915522C13.1558 -0.000969108 12.1674 0.375611 11.4151 1.12775L9.2716 3.27122C8.2033 4.33952 7.89796 5.88221 8.34723 7.22659L9.88701 5.68681C9.95132 5.24654 10.1458 4.82186 10.484 4.48362L12.6275 2.34015C13.0454 1.9226 13.5951 1.71302 14.1438 1.71302C14.6925 1.71302 15.2418 1.92218 15.6593 2.34015C16.4948 3.1761 16.4948 4.53562 15.6593 5.37115L13.5158 7.51462C13.1776 7.85285 12.7533 8.04687 12.3126 8.1116L10.772 9.65222C11.1699 9.78512 11.5841 9.85568 11.9995 9.85568C12.9876 9.85568 13.9759 9.47937 14.7282 8.72701L16.8717 6.58355C18.3764 5.07926 18.376 2.63204 16.8717 1.12775C16.1193 0.375825 15.1318 -0.000861892 14.1438 -0.000915522ZM11.5549 5.56205C11.3321 5.56869 11.1207 5.66178 10.9654 5.82161L5.82112 10.9659C5.73884 11.0449 5.67314 11.1396 5.62788 11.2443C5.58263 11.349 5.55872 11.4617 5.55755 11.5757C5.55639 11.6898 5.578 11.803 5.62112 11.9086C5.66424 12.0142 5.728 12.1101 5.80866 12.1908C5.88932 12.2715 5.98527 12.3352 6.09088 12.3783C6.19649 12.4214 6.30964 12.4431 6.42371 12.4419C6.53778 12.4407 6.65047 12.4168 6.75518 12.3716C6.85989 12.3263 6.95452 12.2606 7.03352 12.1783L12.1778 7.03401C12.3017 6.91358 12.3864 6.75853 12.4206 6.58918C12.4549 6.41983 12.4372 6.24408 12.3698 6.08496C12.3025 5.92584 12.1887 5.79078 12.0432 5.69747C11.8978 5.60416 11.7276 5.55696 11.5549 5.56205ZM6.19121 8.15263C5.14002 8.09914 4.07228 8.47086 3.27073 9.27209L1.12727 11.4156C-0.377017 12.9198 -0.377017 15.3679 1.12727 16.8722C1.87962 17.6241 2.86745 18 3.85516 18C4.84287 18 5.8307 17.6237 6.58306 16.8714L8.72653 14.7279C9.79483 13.6596 10.101 12.1169 9.65173 10.7725L8.11112 12.3131C8.04681 12.7534 7.85237 13.1781 7.51413 13.5163L5.37066 15.6598C4.53514 16.4957 3.17519 16.4957 2.33967 15.6598C1.50414 14.8238 1.50414 13.4643 2.33967 12.6288L4.48313 10.4853C4.82137 10.1471 5.24605 9.95223 5.68632 9.8875L7.2261 8.34772C6.89 8.2354 6.5416 8.17046 6.19121 8.15263Z"
          fill="#7A7A86"
        />
      </svg>
    </Box>
  )
}

export default CopyIcon
