import React from 'react'
import { Box, Typography } from '@mui/material'
import Button from '../../../../../../components/_refactored/Button'
import Modal from '../../../../../../components/_refactored/Modal'
import { theme } from '../../../../../../common/providers/MuiThemeProvider'
import { useTranslation } from 'react-i18next'

interface IProps {
  onCancel: () => void
  deletePaymentMethod: () => void
  loading: boolean
}

const DeletePaymentInfoModal = (props: IProps) => {
  const { t } = useTranslation('settings')
  const { t: g } = useTranslation('general')

  return (
    <Modal open={true} onBackdropClick={props.onCancel}>
      <Box>
        <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 500, marginBottom: '17px' }} align="center">
          {t('billing.main.deletePaymentMethodModal.title')}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="200px" margin="50px auto 0 auto">
          <Button variant="outlined" color="secondary" onClick={props.onCancel}>
            {g('common.cancelBtn')}
          </Button>
          <Button
            variant="outlined"
            style={{ color: theme.palette.error.main, borderColor: theme.palette.error.main }}
            onClick={() => {
              props.deletePaymentMethod()
              props.onCancel()
            }}
            loading={props.loading}
          >
            {g('common.removeBtn')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeletePaymentInfoModal
