import * as React from 'react'
import { Box, Skeleton } from '@mui/material'

type Props = { items: number }
const SharedFilesSkeleton = (props: Props) => {
  return (
    <Box marginTop={props.items > 5 ? '5px' : '0'}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height="20px"
        sx={{ borderRadius: '5px', marginBottom: '18px', display: props.items > 5 ? 'none' : 'block' }}
      />
      {Array.from(Array(props.items).keys()).map(() => (
        <Box display="flex" marginBottom="8px">
          <Skeleton
            variant="rectangular"
            width="50px"
            height="50px"
            sx={{ borderRadius: '8px', marginRight: '10px' }}
          />
          <Skeleton variant="rectangular" width="160px" height="50px" sx={{ borderRadius: '8px' }} />
        </Box>
      ))}
      <Skeleton variant="rectangular" width="100%" height="1px" sx={{ display: props.items > 5 ? 'none' : 'block' }} />
    </Box>
  )
}

export default SharedFilesSkeleton
