import * as React from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ISubjectInputProps {
  subject: string
  handleSubject: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const SubjectInput = (props: ISubjectInputProps) => {
  const { t } = useTranslation('library')

  return (
    <Box
      width="100%"
      padding="15px 15px 15px 0"
      borderBottom="1px solid rgba(122,122,134, .3)"
      display="flex"
      alignItems="center"
    >
      <Typography variant="body1" color="textSecondary" style={{ fontSize: '15px', minWidth: 'fit-content' }}>
        {t('videoItem.sendViaEmailModal.messageSubjectInputPrefix')}
      </Typography>
      <Box className="email-subject-wrapper">
        <TextField
          value={props.subject}
          onChange={props.handleSubject}
          variant="outlined"
          inputProps={{ maxLength: 100 }}
          style={{ width: '100%' }}
        />
      </Box>
    </Box>
  )
}

export default SubjectInput
