import IamApolloClient from 'common/clients/IamApolloClient'
import gql from 'graphql-tag'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import { useContext } from 'react'

/*
 * This hook is used to get small part of reaction objects from cache fragment
 * It is used to determine if we have to add or remove reaction
 */
const useGetCachedReactions = (postId: string) => {
  const auth = useContext(AuthContext)
  const client = auth.isAuthenticated ? CognitoApolloClient : IamApolloClient

  return client.cache.readFragment({
    id: `screensightPost:${postId}`,
    fragment: gql`
      fragment PostReaction4 on screensightPost {
        reactions {
          items {
            unicode
            customerId
            createdAt
          }
        }
      }
    `,
  }) as { reactions: { items: { unicode: string; customerId: string; createdAt: string }[] } } | null
}

export default useGetCachedReactions
