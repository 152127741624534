import React, { Dispatch, SetStateAction } from 'react'
import Modal from '../Modal'
import { Box, Typography } from '@mui/material'
import useViewerInfoModalHook from './common/hooks/useViewerInfoModal.hook'
import { useTranslation } from 'react-i18next'
import UnauthorizedModalHeader from '../UnauthorizedModalHeader/UnauthorizedModalHeader'
import ViewerInfoForm from './ui/components/ViewerInfoForm'

interface IViewerInfoModalView {
  setIsNameAdded: Dispatch<SetStateAction<boolean>>
}

const ViewerInfoModalView = (props: IViewerInfoModalView) => {
  const { t } = useTranslation('library')
  const state = useViewerInfoModalHook(props?.setIsNameAdded)

  return (
    <Modal open={true} boxStyle={{ padding: '0' }}>
      <>
        <UnauthorizedModalHeader handleSignInPush={state.handleSignInPush} />
        <Box bgcolor="background.default" borderRadius="10px" padding="40px 60px">
          <Typography variant="h6" align="center" style={{ marginBottom: '24px' }}>
            {t('videoItem.viewerInfoModal.title')}
          </Typography>
          <Typography variant="h6" align="center" paddingBottom={'24px'}>
            {t('videoItem.viewerInfoModal.subtitle')}
          </Typography>
          <ViewerInfoForm form={state.form} onSubmit={state.onSubmit} loading={state.viewedByLoading} />
        </Box>
      </>
    </Modal>
  )
}

export default ViewerInfoModalView
