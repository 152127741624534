import * as React from 'react'

type Props = {
  color?: string
  width?: string
  height?: string
}
const ReplyIconOutlined = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '20'}
      height={props.height || '17'}
      viewBox="0 0 20 17"
      fill="none"
    >
      <path
        d="M8.84939 0.752247C8.84939 0.453633 8.50104 0.290575 8.27173 0.481853L1.38511 6.16525C1.18462 6.33249 1.18462 6.64049 1.38511 6.80772L8.27173 12.4911C8.50104 12.6824 8.84939 12.5193 8.84939 12.2207V9.91845C8.84939 9.35738 9.31285 8.9024 9.87194 8.94952C13.6856 9.27095 15.7597 10.8693 16.8913 12.4664C17.8132 13.7677 18.1252 15.0478 18.2267 15.6473C18.2601 15.8443 18.4301 15.9973 18.6298 15.9939L18.961 15.9883C18.9816 15.9879 18.9981 15.9712 18.9981 15.9506V15.9382C19.0009 15.861 19.0802 12.9946 17.8314 10.0695C16.6774 7.36418 14.3098 4.65617 9.821 4.20069C9.28205 4.14601 8.84939 3.70748 8.84939 3.16576V0.752247ZM7.30608 2.26976C7.63214 2.00046 8.12448 2.23238 8.12448 2.65527V4.54257C8.12448 4.74274 8.28676 4.90502 8.48694 4.90502C13.6222 4.90502 15.9937 7.64788 17.1745 10.4154C17.3887 10.9175 17.5548 11.4069 17.689 11.8833C17.7287 12.0242 17.5539 12.0915 17.469 11.9722C16.073 10.0031 13.3985 8.06795 8.48694 8.06795C8.28676 8.06795 8.12448 8.23023 8.12448 8.43041V10.3177C8.12448 10.7406 7.63214 10.9725 7.30608 10.7032L3.13415 7.25751C2.6499 6.85755 2.6499 6.11542 3.13415 5.71546L7.30608 2.26976Z"
        fill={props.color || '#BCBCC3'}
        stroke={props.color || '#BCBCC3'}
        strokeWidth="0.6"
      />
    </svg>
  )
}
export default ReplyIconOutlined
