import React from 'react'
import ActionBlock from '../../components/ActionBlock'
import { Grid } from '@mui/material'
import FrameIcon from '../../../common/icons/FrameIcon'
import UploadIcon from '../../../common/icons/UploadIcon'
import { useEditThumbnailDispatch } from '../../../common/context/EditThumbnailContext'
import { useTranslation } from 'react-i18next'

const ActionSection = () => {
  const editThumbnailDispatcher = useEditThumbnailDispatch()
  const { t } = useTranslation('library')

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        <ActionBlock
          icon={<FrameIcon />}
          text={t('videoItem.editThumbnailFramePhotoModal.selectFrame')}
          onClick={() => editThumbnailDispatcher({ type: 'setCurrentModalView', value: 'FRAME' })}
        />
        <ActionBlock
          icon={<UploadIcon />}
          text={t('videoItem.editThumbnail.uploadPhotoText')}
          onClick={() => editThumbnailDispatcher({ type: 'setCurrentModalView', value: 'CUSTOM' })}
        />
      </Grid>
    </>
  )
}

export default ActionSection
