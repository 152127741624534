import * as React from 'react'
import { Box, Skeleton } from '@mui/material'

type Props = { items: number }
const SharedVideosSkeleton = (props: Props) => {
  return (
    <Box marginTop={props.items > 5 ? '8px' : '30px'} marginBottom="13px">
      <Skeleton
        variant="rectangular"
        width="100%"
        height="20px"
        sx={{ borderRadius: '5px', marginBottom: '18px', display: props.items > 5 ? 'none' : 'block' }}
      />
      <Skeleton variant="rectangular" width="100%" height="22px" sx={{ borderRadius: '5px', marginBottom: '16px' }} />

      {Array.from(Array(props.items).keys()).map(() => (
        <Box display="flex" marginBottom="8px">
          <Skeleton
            variant="rectangular"
            width="90px"
            height="57px"
            sx={{ borderRadius: '8px', marginRight: '10px' }}
          />
          <Skeleton variant="rectangular" width="120px" height="57px" sx={{ borderRadius: '8px' }} />
        </Box>
      ))}

      <Skeleton
        variant="rectangular"
        width="100%"
        height="1px"
        sx={{ marginBottom: '30px', display: props.items > 5 ? 'none' : 'block' }}
      />
    </Box>
  )
}

export default SharedVideosSkeleton
