import React from 'react'

import usePatentProvider from './usePatentProvider'
import PatentProviderView from './PatentProviderView'

export type PatentProviderProps = any

export default (props: any) => {
  const state = usePatentProvider()
  return <PatentProviderView {...state} {...props} />
}
