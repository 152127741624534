import * as React from 'react'
import { Check } from '@mui/icons-material'
import { theme } from '../../../../../../common/providers/MuiThemeProvider'
import { Box, Typography } from '@mui/material'

type Props = {
  content: any
}
export const KeyFeature = (props: Props) => {
  return (
    <Box display="flex" alignItems="center" marginBottom="2px">
      <Check style={{ color: theme.palette.primary.main }} />
      <Typography variant="h6" color="textSecondary" style={{ fontSize: '15px', marginLeft: '6px' }}>
        {props.content}
      </Typography>
    </Box>
  )
}

export default KeyFeature
