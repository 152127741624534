import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { theme } from '../../../../../../../../../../common/providers/MuiThemeProvider'
import Button from '../../../../../../../../../../components/_refactored/Button'
import * as React from 'react'

const VideoBetaExplanation = (props: { onButtonClick: () => void }) => {
  const { t } = useTranslation('chat')

  return (
    <>
      <Typography
        variant={'body1'}
        sx={{
          fontSize: '12px',
          color: theme.palette.secondary.main,
          whiteSpace: 'pre-wrap',
          marginBottom: '10px',
        }}
      >
        ({t('chatBody.videoPostSummaryAI.experimental')})
      </Typography>
      <Typography
        variant={'body1'}
        sx={{
          fontSize: '12px',
          color: theme.palette.secondary.main,
          whiteSpace: 'pre-wrap',
          marginBottom: '10px',
        }}
      >
        {t('chatBody.videoPostSummaryAI.experimentalContent')}
      </Typography>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button variant={'contained'} color={'primary'} onClick={props.onButtonClick}>
          {t('chatBody.videoPostSummaryAI.gotIt')}
        </Button>
      </Box>
    </>
  )
}
export default VideoBetaExplanation
