import React, { useState } from 'react'
import Card from '../../../../ui/components/Card'
import { Box, Grid, Typography } from '@mui/material'
import Button from '../../../../../../components/_refactored/Button'
import DeletePaymentInfoModal from './DeletePaymentInfoModal'
import { useTranslation } from 'react-i18next'

type PaymentInfoProps = {
  name: string
  card4digits: string
  cardBrand: string
  deletePaymentMethod: () => void
  deletePaymentLoading: boolean
}

const PaymentInfo = ({ name, card4digits, cardBrand, deletePaymentMethod, deletePaymentLoading }: PaymentInfoProps) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const { t } = useTranslation('settings')
  const closeDeleteModal = () => setDeleteModalVisible(false)

  return (
    <Card>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: 600, marginBottom: '10px' }}>
            {t('billing.main.paymentName')}
          </Typography>
          <Typography variant="h6" color="textSecondary" style={{ fontSize: '15px' }}>
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: 600, marginBottom: '10px' }}>
            {t('billing.main.paymentCreditCard')}
          </Typography>
          <Typography variant="h6" color="textSecondary" style={{ fontSize: '15px' }}>
            **** **** **** {card4digits}
          </Typography>
        </Grid>
      </Grid>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between" marginTop="20px">
        <Box>
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: 600, marginBottom: '10px' }}>
            {t('billing.main.paymentCardBrand')}
          </Typography>
          <Typography variant="h6" color="textSecondary" style={{ fontSize: '15px' }}>
            {cardBrand}
          </Typography>
        </Box>
        <Button
          variant="text"
          color="secondary"
          onClick={() => setDeleteModalVisible(true)}
          loading={deletePaymentLoading}
          style={{ color: '#F73737' }}
        >
          {t('billing.main.removeCardBtn')}
        </Button>
        {deleteModalVisible && (
          <DeletePaymentInfoModal
            onCancel={closeDeleteModal}
            deletePaymentMethod={deletePaymentMethod}
            loading={deletePaymentLoading}
          />
        )}
        {/*<Link to="/app" style={{ fontWeight: 500, color: theme.palette.primary.main, fontSize: '16px' }}>*/}
        {/*  Edit Payment Info*/}
        {/*</Link>*/}
      </Box>
    </Card>
  )
}

export default PaymentInfo
