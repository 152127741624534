import * as React from 'react'
import { Box, useTheme } from '@mui/material'
import VideoErrorItem from '../../../../../containers/VideoList/components/VideoErrorItem/VideoErrorItem'
import { VideoStatus } from '../../../../../API'
import ModalLibraryItemContent from './ModalLibraryItemContent'
import VideoItemThumbnail from '../../../../../components/VideoItem/VideoItemThumbnail'

interface IMadalLibraryItem {
  videoItem: any
  isMobile: boolean
  isLargeScreen: boolean
  register: any
  handleSelectVideo: (videoId: string) => void
  selectedVideoId?: string
}

const ModalLibraryItem = (props: IMadalLibraryItem) => {
  const { videoItem, isMobile, isLargeScreen } = props
  const theme = useTheme()
  if (videoItem?.error) {
    return <VideoErrorItem isMobile={isMobile} isLargeScreen={isLargeScreen} item={videoItem} theme={theme} />
  }

  return (
    <Box className="form-check" width="100%">
      <label htmlFor={videoItem.id} style={{ display: 'flex' }}>
        <input
          onChange={() => props.handleSelectVideo(videoItem.id)}
          checked={props.selectedVideoId === videoItem.id}
          ref={props.register}
          type="radio"
          value={videoItem.id}
          name={'selectedVideo'}
          className="form-check-input"
          id={videoItem.id}
          style={{ marginLeft: '20px' }}
        />
        <Box
          display="flex"
          bgcolor="background.paper"
          width="100%"
          padding={isMobile ? '14px' : '25px'}
          borderRadius="10px"
          margin={isMobile ? '0px 20px 20px 10px' : '0px 20px 20px 25px'}
          style={{ overflowX: 'hidden' }}
        >
          <VideoItemThumbnail
            isMobile={isMobile}
            duration={videoItem.duration}
            photo={videoItem.thumbnailObject?.key}
            isUploading={videoItem.status === VideoStatus.uploading}
            isConverting={videoItem.status === VideoStatus.converting}
          />
          <ModalLibraryItemContent videoItem={videoItem} isMobile={isMobile} />
        </Box>
      </label>
    </Box>
  )
}

export default ModalLibraryItem
