import * as React from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { getUsername } from '../../../../../../../../common/utils/getUsername'
import gql from 'graphql-tag'
import { screensightPost, screensightVideoComment } from '../../../../../../../../API'
import FileData from './FileData'
import '../../../common/styles/posts.style.scss'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import SanitazedPostContent from './SanitazedPostContent'
import ReplyParentPostData from './ReplyParentPostData'

interface IReplyPost {
  owner: boolean
  referencePost: screensightPost
  reply: screensightVideoComment
  postId: string
}

const ReplyPost = (props: IReplyPost) => {
  const auth = useContext(AuthContext)
  const theme = useTheme()
  const client = auth.isAuthenticated ? CognitoApolloClient : IamApolloClient
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(`(max-width:1024px)`)

  const cacheReferencePostAuthor: any = client.cache.readFragment({
    id: `screensightCustomer:${props.referencePost.customerId}`,
    fragment: gql`
      fragment CustomerMeta on screensightCustomer {
        id
        firstName
        lastName
        email
      }
    `,
  })
  const username = getUsername(
    cacheReferencePostAuthor?.firstName,
    cacheReferencePostAuthor?.lastName,
    cacheReferencePostAuthor?.email,
  )
  const parentPostContent =
    props.referencePost?.text?.content ||
    props.referencePost?.comment?.content ||
    props.referencePost?.file?.fileName ||
    ''

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={props.owner ? 'flex-end' : 'flex-start'}
      maxWidth={'calc(100% - 60px)'}
      minWidth={isTablet && !isMobile ? 'calc(100% - 50px)' : isMobile ? 'calc(100% - 60px)' : '250px'}
    >
      <Box
        display="flex"
        className={props.owner ? 'chat__post-owner-text' : 'chat__post-partner-text'}
        flexDirection="column"
        width="100%"
        bgcolor={props.owner ? 'primary.main' : 'rgba(188, 188, 195, 0.15)'}
        margin={'10px 0px 0px 0px'}
        padding="15px"
        borderRadius="24px"
        style={{ borderTopRightRadius: props.owner ? '0' : '24px', borderTopLeftRadius: props.owner ? '24px' : '0' }}
      >
        <Box width="100%" position="relative">
          <Box
            width="100%"
            borderLeft={`2px solid ${props.owner ? 'white' : theme.palette.secondary.light} `}
            sx={{ paddingLeft: '10px' }}
          >
            {props.referencePost?.file ? (
              <FileData
                file={props.referencePost.file}
                owner={props.owner}
                username={username}
                parentPostContent={parentPostContent}
              />
            ) : (
              <ReplyParentPostData
                username={username}
                owner={props.owner}
                channelId={props?.referencePost?.comment?.channelId}
                videoId={props.referencePost.comment?.videoId}
                videoTimestamp={props?.referencePost?.comment?.videoTimestamp}
                parentPostContent={parentPostContent}
              />
            )}
          </Box>
        </Box>
        <SanitazedPostContent owner={props.owner} content={props.reply.richContent || props.reply.content || ''} />
      </Box>
    </Box>
  )
}

export default ReplyPost
