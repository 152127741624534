import React from 'react'
import { Box, LinearProgress, Typography } from '@mui/material'
import Modal from '../../_refactored/Modal'
import { useTranslation } from 'react-i18next'

const UploadModal = () => {
  const { t: g } = useTranslation('general')
  return (
    <Modal open={true}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="body1" color="textSecondary">
          {g('common.uploading')}
        </Typography>
        <LinearProgress style={{ width: '100%', height: '3px', marginTop: '24px' }} color="primary" />
      </Box>
    </Modal>
  )
}

export default UploadModal
