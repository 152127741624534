import * as React from 'react'

type Props = {}
const FlagDe = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_16376_376406)">
        <path
          d="M10.0001 3.05176e-05C5.71059 3.05176e-05 2.06259 2.70603 0.64209 6.50003H19.3576C17.9376 2.70603 14.2896 3.05176e-05 10.0001 3.05176e-05Z"
          fill="#455A64"
        />
        <path
          d="M20 9.99997C20 8.76647 19.766 7.59097 19.358 6.49997H0.642C0.234 7.59097 0 8.76647 0 9.99997C0 11.2335 0.234 12.409 0.642 13.5H19.3575C19.766 12.409 20 11.2335 20 9.99997Z"
          fill="#FF3D00"
        />
        <path
          d="M19.3581 13.5H0.64209C2.06259 17.294 5.71059 20 10.0001 20C14.2896 20 17.9376 17.294 19.3581 13.5Z"
          fill="#FFC107"
        />
      </g>
      <defs>
        <clipPath id="clip0_16376_376406">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default FlagDe
