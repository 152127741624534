import { MutableRefObject, useEffect, useState } from 'react'
import { VideoJsPlayer } from 'video.js'
import { useDispatch, useSelector } from 'react-redux'
import * as queryString from 'query-string'
import { useHistory, useLocation } from 'react-router'
import { TVideoInChannel } from '../../../../../../PartnerSidebar/common/api/listVideosInChannel'
import { useChatDispatch } from '../../../../../common/contexts/ChatContext'
import { RootState } from '../../../../../../../../common/redux/store/store'
import { setChatPlayerPaused } from '../../../../../../../../common/redux/store/chat-actions'

const useVideoPlayerProgress = (
  player: MutableRefObject<VideoJsPlayer | null>,
  videoId: string,
  playerReady: boolean,
) => {
  const history = useHistory()
  const location = useLocation()
  const chatDispatcher = useChatDispatch()
  const videoPostsPlaylist = useSelector((state: RootState) => state.chat.videoPlaylistData)
  const chatPlayerAutoplay = useSelector((state: RootState) => state.chat.chatPlayerAutoplay)
  const chatPlayerPaused = useSelector((state: RootState) => state.chat.chatPlayerPaused)
  const dispatch = useDispatch()
  const [nextVideo, setNextVideo] = useState<TVideoInChannel | null>(null)
  const queryStringPostId = queryString.parse(location.search)?.postId
  const timestampQueryParam = queryString.parse(location.search)?.t
  const localStorageKey = `player:${videoId}`

  useEffect(() => {
    return () =>
      chatDispatcher({
        type: 'setResponseBoxData',
        value: undefined,
      })
  }, [])

  useEffect(() => {
    setNextVideo(() => {
      if (Object.values(videoPostsPlaylist)?.length) {
        const currentVideoIndex = (Object.values(videoPostsPlaylist) || [])?.findIndex(
          (post) => post.id === queryStringPostId,
        )
        if (currentVideoIndex !== -1) {
          const nextVideo = (Object.values(videoPostsPlaylist) || [])?.[currentVideoIndex + 1]
          if (nextVideo) {
            return nextVideo
          }
        }
      }
      return null
    })
  }, [videoPostsPlaylist, queryStringPostId])

  const handleVideoEnded = () => {
    if (!chatPlayerAutoplay || localStorage.getItem('chatPlayerAutoplay') === 'false') return
    const nextVideoId =
      nextVideo?.video?.accessId ||
      nextVideo?.referencePost?.video?.accessId ||
      nextVideo?.video?.id ||
      nextVideo?.referencePost?.video?.id
    const nextPostId = nextVideo?.id || nextVideo?.referencePost?.id
    if (!nextVideoId || !nextPostId) return
    if (nextVideo) {
      history.push({
        search: `?play=${nextVideoId}&postId=${nextPostId}`,
      })
    }
  }

  useEffect(() => {
    if (!playerReady || !player.current) return

    let interval: ReturnType<typeof setInterval>
    const userPlaybackRate = localStorage.getItem('playbackRate')
    if (userPlaybackRate) {
      player?.current?.playbackRate(Number(userPlaybackRate))
    }

    player?.current?.on('ratechange', (e, a) => {
      localStorage.setItem('playbackRate', e?.target?.player?.cache_?.lastPlaybackRate)
    })

    player?.current?.on('pause', () => {
      clearInterval(interval)
    })

    player.current?.on('play', () => {
      dispatch(setChatPlayerPaused(false))
      interval = setInterval(() => {
        if (player?.current?.currentTime()) {
          localStorage.setItem(localStorageKey, player?.current?.currentTime().toString())
        }
      }, 1000)
    })

    player?.current?.on('ended', () => {
      clearInterval(interval)
      localStorage.removeItem(localStorageKey)
      handleVideoEnded()
      localStorage.setItem(`chatPlayerWatched:${videoId}`, 'true')
    })

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [player, playerReady, nextVideo, chatPlayerAutoplay])

  useEffect(() => {
    if (!playerReady || !player.current) return
    if (chatPlayerPaused) {
      player.current?.pause()
    } else {
      if (player && timestampQueryParam) {
        player?.current?.currentTime(Number(timestampQueryParam))
        player.current?.play()
        return
      }
      if (player && localStorage.getItem(localStorageKey)) {
        player?.current?.currentTime(Number(localStorage.getItem(localStorageKey) || ''))
        player.current?.play()
        return
      }
    }
  }, [player, playerReady, chatPlayerPaused, timestampQueryParam])
}

export default useVideoPlayerProgress
