import * as React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CameraPostIcon from '../../../../../../../../common/icons/CameraPostIcon'
import DeleteCrossIcon from '../../../../../../../../common/icons/DeleteCrossIcon'
import '../../../common/styles/video.style.scss'

type IVideoItemRemovedProps = {
  owner: boolean
}
const VideoItemRemoved = (props: IVideoItemRemovedProps) => {
  const { t } = useTranslation('chat')

  return (
    <Box justifyContent={props.owner ? 'flex-end' : 'flex-start'} className={'removed__video__container'}>
      <Box className={'removed__video__content'}>
        <Box className={'removed__video__icons'}>
          <CameraPostIcon />
          <Box className={'removed__video__icon-cross'}>
            <DeleteCrossIcon />
          </Box>
        </Box>
        <Typography variant="body2">{t('chatBody.removedVideoPost')}</Typography>
      </Box>
    </Box>
  )
}

export default VideoItemRemoved
