import React from 'react'

import { UseVideoNewModal } from '../hooks/useVideoNewModal.hook'
import { VideoNewModalProps } from '../index'
import RecordingTypeRadioButtons from '../../../../components/RecordingTypeRadioButtons'
import Modal from '../../../../components/_refactored/Modal'
import { Box, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Controller } from 'react-hook-form'
import Icon from '../../../../common/icons'
import Button from '../../../../components/_refactored/Button'
import ScriptIcon from '../../../../common/icons/ScriptIcon'
import ExtensionModal from '../components/ExtensionModal'
import BrowserSupportModalContentView from './BrowserSupportModalContent.view'
import ExtensionInstalledAnimationTutorialView from './ExtensionInstalledAnimationTutorial.view'
import { useTranslation } from 'react-i18next'
import ProgressBar from '../../../../components/_refactored/ProgressBar'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../pages/Chat/common/redux/store/store'

const VideoNewModalView = (props: VideoNewModalViewProps) => {
  const { t } = useTranslation('library')
  const { t: g } = useTranslation('general')
  const {
    open,
    onClose,
    form,
    len,
    setScreenValue,
    setCameraValue,
    setCameraAndScreenValue,
    selectedRecordingType,
    extensionModalVisible,
    setExtensionModalVisible,
    openLibraryModal,
    fileInputRef,
    handleUploadVideoInputClick,
    handleUploadingProcess,
    isVideoUploading,
    titleInputRef,
  } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const videoLimitReached = useSelector((state: RootState) => state.chat.videosLimitReached)

  return (
    <Modal
      open={open}
      boxStyle={{
        width: extensionModalVisible
          ? '870px'
          : extensionModalVisible && props.extensionInstalled && selectedRecordingType === 'SCREENANDCAM'
          ? '870px'
          : '550px',
      }}
      onClose={() => {
        onClose()
        setExtensionModalVisible(false)
      }}
    >
      <>
        {extensionModalVisible && !props.extensionInstalled ? (
          <ExtensionModal isMobile={isMobile} />
        ) : // @ts-ignore
        !!window.chrome ? (
          extensionModalVisible && props.extensionInstalled ? (
            <ExtensionInstalledAnimationTutorialView
              form={form}
              isMobile={isMobile}
              onClose={onClose}
              selectedRecordingType={selectedRecordingType}
              setCameraAndScreenValue={setCameraAndScreenValue}
              setCameraValue={setCameraValue}
              setScreenValue={setScreenValue}
              setExtensionModalVisible={setExtensionModalVisible}
              handleUploadVideoInputClick={handleUploadVideoInputClick}
              handleUploadingProcess={handleUploadingProcess}
              fileInputRef={fileInputRef}
            />
          ) : (
            <form onSubmit={form.submit}>
              <Typography variant="h3" align="center">
                {g('recordControl.newVideoModal.title')}
              </Typography>
              <Controller
                name={'title'}
                control={form.control}
                defaultValue=""
                rules={{
                  validate: (value: string) => (value?.length > 100 ? g('common.maxTextInputLength') : true),
                }}
                render={({ value, onChange, onBlur }) => (
                  <TextField
                    id="video__title__input"
                    autoFocus
                    inputRef={titleInputRef}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!form.errors['title']}
                    helperText={form.errors['title']?.message}
                    variant="outlined"
                    style={{ width: '100%' }}
                    InputProps={{
                      style: {
                        fontSize: '15px',
                        marginTop: '16px',
                        fontWeight: 500,
                        color: theme.palette.text.secondary,
                      },
                    }}
                  />
                )}
              />
              <Typography variant="body2" color="secondary">
                {len}/100
              </Typography>
              <Box display="flex" justifyContent="center">
                <RecordingTypeRadioButtons
                  openLibraryModal={openLibraryModal}
                  setScreenValue={setScreenValue}
                  setCameraValue={setCameraValue}
                  setCameraAndScreenValue={setCameraAndScreenValue}
                  selected={selectedRecordingType}
                  form={form}
                  fileInputRef={fileInputRef}
                  handleUploadVideoInputClick={handleUploadVideoInputClick}
                  handleUploadingProcess={handleUploadingProcess}
                />
              </Box>
              <Box marginTop="62px" display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={videoLimitReached || !form.formState.isValid}
                  style={{ width: 'fit-content' }}
                >
                  {!form.watch('teleprompter') ? (
                    <>
                      <Box marginRight="10px">
                        <Icon.SmallCamera color={!form.formState.isValid ? theme.palette.secondary.main : ''} />
                      </Box>
                      {t('videoList.common.recordBtn')}
                    </>
                  ) : (
                    <>
                      <Box marginRight="10px">
                        <ScriptIcon color={!form.formState.isValid ? theme.palette.secondary.main : ''} />
                      </Box>
                      {g('recordControl.newVideoModal.setupScriptBtn')}
                    </>
                  )}
                </Button>
              </Box>
            </form>
          )
        ) : (
          <BrowserSupportModalContentView isMobile={isMobile} onClose={onClose} />
        )}
        {isVideoUploading && (
          <Modal sx={{ width: '100%', height: '100%' }} open={true} style={{ zIndex: 1310 }}>
            <Box width="100%" display="flex" flexDirection="column" alignItems="center">
              <Typography sx={{ marginBottom: '16px', fontWeight: 500, fontSize: '18px' }}>
                {t('videoList.uploadingVideoModal.title')}{' '}
                {props.isUploadPaused ? t('videoList.uploadingVideoModal.uploadingPaused') : ''}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                align="center"
                sx={{ marginBottom: '16px', fontSize: '15px' }}
              >
                {t('videoList.uploadingVideoModal.subtitle')}
              </Typography>
              <Box display="flex" width="100%" height="40px" alignItems="center">
                <ProgressBar progress={props.uploadProgress / 100 || 0} />
              </Box>
              <Box display="flex" marginTop="30px">
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: theme.palette.error.main,
                    marginRight: '12px',
                    color: theme.palette.error.main,
                    '&:hover': { borderColor: theme.palette.error.main, color: theme.palette.error.main },
                  }}
                  onClick={props.handleCancelS3Upload}
                >
                  {g('common.cancelBtn')}
                </Button>
                <Button variant="outlined" color="secondary" onClick={props.toggleUploadStatus} sx={{ width: '105px' }}>
                  {props.isUploadPaused
                    ? t('videoList.uploadingVideoModal.resumeUploadBtn')
                    : t('videoList.uploadingVideoModal.pauseUploadBtn')}
                </Button>
              </Box>
            </Box>
          </Modal>
        )}
      </>
    </Modal>
  )
}

export type VideoNewModalViewProps = UseVideoNewModal & VideoNewModalProps
export default VideoNewModalView
