import React from 'react'
import { Box, Typography } from '@mui/material'
import VisaPng from '../../../../../../../common/assets/Visa.png'
import MasterCardPng from '../../../../../../../common/assets/MasterCard.png'
import AmexPng from '../../../../../../../common/assets/AMEX.png'
import { useTranslation } from 'react-i18next'

interface IAside {
  planName: string
  totalPrice: number
}

const Aside = (props: IAside) => {
  const { t } = useTranslation('settings')

  return (
    <Box>
      <Box borderBottom="1px solid rgba(188, 188, 195, 0.6)">
        <Typography variant="body1" style={{ fontSize: '18px', marginBottom: '20px' }} color="textSecondary">
          {t('billing.purchase.planName')}
        </Typography>
        <Typography variant="body1" style={{ fontSize: '20px', fontWeight: 500, marginBottom: '24px' }}>
          {props.planName?.toUpperCase()}
        </Typography>
      </Box>
      <Box borderBottom="1px solid rgba(188, 188, 195, 0.6)" marginTop="24px">
        <Typography variant="body1" style={{ fontSize: '18px', marginBottom: '20px' }} color="textSecondary">
          {t('billing.purchase.totalBilling')}
        </Typography>
        <Typography variant="body1" style={{ fontSize: '32px', fontWeight: 600, marginBottom: '24px' }}>
          ${props.totalPrice.toFixed(2)}
        </Typography>
      </Box>
      <Box marginTop="24px">
        <Typography variant="body1" style={{ fontSize: '18px', marginBottom: '20px' }} color="textSecondary">
          {t('billing.purchase.acceptedPaymentTypes')}
        </Typography>
        <Box display="flex">
          <Box
            padding="10px"
            border="1px solid rgba(188, 188, 195, 0.6)"
            borderRadius="6px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img alt="Visa logo" src={VisaPng} width="40px" />
          </Box>
          <Box borderRadius="6px" display="flex" justifyContent="center" alignItems="center" marginLeft="10px">
            <img alt="MasterCard logo" src={MasterCardPng} width="50px" />
          </Box>
          <Box borderRadius="6px" display="flex" justifyContent="center" alignItems="center" marginLeft="10px">
            <img alt="AmericanExpress logo" src={AmexPng} width="50px" />
          </Box>
        </Box>
        <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px', marginTop: '15px' }}>
          {t('billing.purchase.additionalInfo')}
        </Typography>
      </Box>
    </Box>
  )
}

export default Aside
