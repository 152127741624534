import * as React from 'react'
import { Components, Virtuoso } from 'react-virtuoso'
import ModalLibraryItem from '../components/ModalLibraryItem'
import { screensightVideo } from '../../../../../API'
import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material'
import './styles.scss'

interface IModalLibraryList {
  register: any
  videoList: any
  getNextVideos: any
  hasMore: boolean
  loading: boolean
  handleSelectVideo: (videoId: string) => void
  searchPhrase?: string
  selectedVideoId?: string
}

const ModalLibraryList = (props: IModalLibraryList) => {
  const { videoList, register, hasMore, handleSelectVideo } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      marginTop="10px"
      alignItems="center"
      height="548px"
      position="relative"
      id="scrollableDiv"
      style={{ overflowY: 'auto', overflowX: 'hidden' }}
    >
      {props.loading && (
        <Box
          position="absolute"
          left={0}
          top={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="background.default"
          zIndex={10}
        >
          <CircularProgress />
        </Box>
      )}
      {props.searchPhrase && !videoList?.length && <Typography>No results</Typography>}
      {videoList ? (
        <form style={{ width: '100%', height: '100%' }}>
          <Virtuoso
            style={{ height: '95%' }}
            data={videoList}
            overscan={1000}
            endReached={props.getNextVideos}
            components={(hasMore ? { Footer } : { Loaded }) as Components<any> | undefined}
            itemContent={(index, videoItem) => {
              return (
                <ModalLibraryItem
                  key={(videoItem as screensightVideo).id}
                  videoItem={videoItem}
                  isMobile={isMobile}
                  isLargeScreen={isLargeScreen}
                  register={register}
                  handleSelectVideo={handleSelectVideo}
                  selectedVideoId={props.selectedVideoId}
                />
              )
            }}
          />
        </form>
      ) : (
        <Box display="flex" marginTop="35%">
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

const Footer = () => {
  return (
    <Box width="100%" display="flex" justifyContent="center" padding="10px 0">
      <CircularProgress />
    </Box>
  )
}

const Loaded = () => {
  return <Box></Box>
}

export default ModalLibraryList
