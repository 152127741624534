import gql from 'graphql-tag'
import { getUsername } from '../../../../../../../../common/utils/getUsername'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import IamApolloClient from '../../../../../../../../common/clients/IamApolloClient'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'

const useReaction = (authors: string[], reactionAmount: number) => {
  const auth = useContext(AuthContext)
  const client = auth.isAuthenticated ? CognitoApolloClient : IamApolloClient
  const { t } = useTranslation('chat')

  const cacheCustomer: any = client.cache.readFragment({
    id: `screensightCustomer:${authors.find((author) => author !== auth.user?.id)}`,
    fragment: gql`
      fragment CustomerMeta on screensightCustomer {
        id
        firstName
        lastName
        email
      }
    `,
  })

  const username = getUsername(cacheCustomer?.firstName, cacheCustomer?.lastName, cacheCustomer?.email)

  return () => {
    if (reactionAmount === 1 && !cacheCustomer) {
      return t('chatBody.reactionTooltip.youReacted')
    } else if (reactionAmount === 1 && cacheCustomer) {
      return `${username} ${t('chatBody.reactionTooltip.someoneReacted')}`
    } else {
      return `${username} ${t('chatBody.reactionTooltip.youAndSomeoneReacted')}`
    }
  }
}

export default useReaction
