import { FormControl, InputLabel, Select, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import moment from 'moment/moment'
import useLanguage from '../../hooks/useLanguage'
import { theme } from '../../../../common/providers/MuiThemeProvider'
import './styles.scss'
const LanguageSelect = () => {
  const { i18n } = useTranslation()
  const [value, setValue] = useState(i18n.language)
  const { t: g } = useTranslation('general')
  const state = useLanguage()
  const handleChange = (event: any) => {
    setValue(event.target.value)
    i18n.changeLanguage(event.target.value)
    moment.locale(event.target.value)
    localStorage.setItem('i18nextLng', event.target.value)
  }

  return (
    <FormControl style={{ margin: '24px 0' }} fullWidth>
      <InputLabel sx={{ paddingBottom: '2px' }} id="demo-simple-select-label">
        {g('menu.language')}
      </InputLabel>
      <Select
        className={'select-language'}
        fullWidth
        id="simple-select"
        value={value}
        label={g('menu.language')}
        defaultValue={localStorage.getItem('i18nextLng')}
        placeholder={'Choose your language'}
        onChange={handleChange}
      >
        {state.languages.map((language) => {
          return (
            <MenuItem value={language.key} className={'koko'}>
              <Typography component={'span'} style={{ lineHeight: 0.5, display: 'flex', alignItems: 'center' }}>
                {<language.flag />}
              </Typography>
              <Typography component={'span'} sx={{ color: theme.palette.text.secondary, padding: '0 6px 0 6px' }}>
                {language.key.toUpperCase()} -
              </Typography>
              <Typography component={'span'}>
                {/*//@ts-ignore*/}
                {g(`menu.languages.${language.key}`)}
              </Typography>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
export default LanguageSelect
