import * as React from 'react'
import PasswordInput from './PasswordInput'
import NameInput from '../../../NameInput/NameInput'
import { UseFormMethods } from 'react-hook-form/dist/types/form'
import { IVideoAccessModalHookForm } from '../../common/hooks/useVideoAccessModal.hook'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Button from '../../../Button'

interface IAccessFormProps {
  form: UseFormMethods<IVideoAccessModalHookForm>
  loading: boolean
  isPasswordShowed: boolean
  togglePasswordVisible: () => void
  authorized: boolean
  onCancel?: () => void
  handleVerifyAccess: () => void
}
const AccessForm = (props: IAccessFormProps) => {
  const { t } = useTranslation('library')
  const { t: g } = useTranslation('general')
  return (
    <>
      <PasswordInput
        form={props.form}
        loading={props.loading}
        isPasswordShowed={props.isPasswordShowed}
        togglePasswordVisible={props.togglePasswordVisible}
      />

      {!localStorage.getItem('guestCustomerId') && !props.authorized && (
        <Box width="90%" marginTop="30px">
          <NameInput topLabel={t('videoItem.videoAccessModal.nameInput')} form={props.form} loading={props.loading} />
        </Box>
      )}
      <Box display="flex" alignItems="center" marginTop="30px">
        {props.onCancel && (
          <Button sx={{ marginRight: '10px' }} onClick={props.onCancel}>
            {g('common.cancelBtn')}
          </Button>
        )}
        <Button variant="contained" color="primary" loading={props.loading} onClick={props.handleVerifyAccess}>
          {g('common.watchVideoBtn')}
        </Button>
      </Box>
    </>
  )
}

export default AccessForm
