import React from 'react'
import { Box } from '@mui/material'

const MicrophoneDisabled = () => {
  return (
    <Box>
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M30 4.99997C25.8575 4.99997 22.5 8.35747 22.5 12.5V27.5C22.5 31.6425 25.8575 35 30 35C34.1425 35 37.5 31.6425 37.5 27.5V12.5C37.5 8.35747 34.1425 4.99997 30 4.99997ZM15.2197 27.5C13.7047 27.5 12.4845 28.8443 12.7295 30.3418C13.9542 37.8411 19.9528 43.7225 27.5 44.8047V50C27.5 51.38 28.62 52.5 30 52.5C31.38 52.5 32.5 51.38 32.5 50V44.8047C40.0472 43.7225 46.0458 37.8411 47.2705 30.3418C47.5155 28.8443 46.2953 27.5 44.7803 27.5C43.5453 27.5 42.5268 28.4089 42.3193 29.6289C41.3068 35.5164 36.1775 40 30 40C23.8225 40 18.6932 35.5164 17.6807 29.6289C17.4732 28.4089 16.4572 27.5 15.2197 27.5Z"
          fill="#7A7A86"
        />
        <rect x="36.5" y="30.5" width="16" height="16" stroke="#F73737" strokeWidth="9" />
        <rect x="31.5" y="25.5" width="26" height="26" stroke="white" strokeWidth="3" />
      </svg>
    </Box>
  )
}

export default MicrophoneDisabled
