import { createSlice, current } from '@reduxjs/toolkit'
import { TVideoInChannel } from '../../../ui/components/PartnerSidebar/common/api/listVideosInChannel'
import { OnPostEventSubscription, screensightCustomer, screensightReaction } from '../../../../../API'

const sortVideoPlaylistData = (videoPlaylistData: any) => {
  return Object.assign(
    {},
    ...Object.values(videoPlaylistData)
      .sort(function (a: any, b: any) {
        if (!a?.createdAt || !b?.createdAt) return 0
        return b?.createdAt < a?.createdAt ? -1 : b?.createdAt > a?.createdAt ? 1 : 0
      })
      .map((item: any) => ({ [item.id]: item })),
  )
}

export type SidebarParams = {
  width: number
  height: string
  x: number
  y: number
}

export type IChatCustomers = {
  author?: screensightCustomer | null
  partner?: screensightCustomer | null
}

export interface ChatSliceState {
  listChannels: any[]
  uploadFileAttachmentProgress?: { [key: string]: string }
  videoPlaylistData: { [key: string]: TVideoInChannel }
  chatPlayerAutoplay: boolean
  videoPlaylistProgress: { [key: string]: number }
  videoPlayerReactions: { [key: string]: screensightReaction[] }
  chatCustomers: IChatCustomers | null | undefined
  chatPlayerPaused: boolean
  unreadChannelsCount: number
  sidebarParams: SidebarParams
  seenVideoPostIds: string[]
  onPostEventSubscriptionData?: OnPostEventSubscription | undefined | null
  videosLimitReached: boolean
  rightSidePanelParams: SidebarParams
}

const storedPanelParams = JSON.parse(localStorage.getItem('rightSidePanelParams') || '{}')
const storedPanelParamsNormalUser = JSON.parse(localStorage.getItem('rightSidePanelParamsNormalUser') || '{}')

const initialState: ChatSliceState = {
  listChannels: [],
  uploadFileAttachmentProgress: {},
  videoPlaylistData: {},
  sidebarParams: {
    width: storedPanelParams.width || 294,
    height: '100vh',
    x: window.innerWidth - 310,
    y: 0,
  },
  chatPlayerAutoplay: localStorage.getItem('chatPlayerAutoplay') === 'true',
  videoPlaylistProgress: {},
  videoPlayerReactions: {},
  chatCustomers: null,
  chatPlayerPaused: false,
  unreadChannelsCount: 0,
  seenVideoPostIds: [],
  onPostEventSubscriptionData: null,
  videosLimitReached: false,
  rightSidePanelParams: {
    width: storedPanelParamsNormalUser.width || 294,
    height: '100vh',
    x: window.innerWidth - (storedPanelParamsNormalUser.width || 294) + 60,
    y: 0,
  },
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    replaceChannels(state, action) {
      return {
        ...state,
        listChannels: action.payload.listChannels,
      }
    },
    updateProgress(state, action) {
      return {
        ...state,
        uploadFileAttachmentProgress: {
          ...state.uploadFileAttachmentProgress,
          [action.payload.postId]: action.payload.progress,
        },
      }
    },
    updateWidth(state, action) {
      return {
        ...state,
        sidebarParams: {
          width: action.payload.width,
          height: action.payload.height,
          x: action.payload.x,
          y: action.payload.y,
        },
      }
    },
    updateRightSidePanelParams(state, action) {
      return {
        ...state,
        rightSidePanelParams: {
          width: action.payload.width,
          height: action.payload.height,
          x: action.payload.x,
          y: action.payload.y,
        },
      }
    },
    updateVideoPlaylistData(state, action) {
      let subItem = [...action.payload.videoPlaylistData?.map((item: any) => ({ [item.id]: item }))]
      const _state = current(state.videoPlaylistData)
      if (subItem?.length === 1) {
        if (
          _state?.[Object.keys(subItem?.[0])?.[0]] &&
          _state?.[Object.keys(subItem?.[0])?.[0]]?.engagement?.items?.length
        ) {
          subItem = [
            ...action.payload.videoPlaylistData?.map((item: any) => ({
              [item.id]: {
                ...item,
                engagement: _state?.[Object.keys(subItem[0])[0]].engagement,
              },
            })),
          ]
        }
      }
      const oldData = {
        ...state.videoPlaylistData,
        ...Object.assign({}, ...subItem),
      }
      return {
        ...state,
        videoPlaylistData: sortVideoPlaylistData(oldData),
      }
    },
    clearVideoPlaylistData(state, action) {
      return {
        ...state,
        videoPlaylistData: {},
      }
    },
    addPostToVideoPlaylistData(state, action) {
      return {
        ...state,
        videoPlaylistData: {
          ...state.videoPlaylistData,
          [action.payload.post.id]: action.payload.post,
        },
      }
    },
    updateChatPlayerAutoplay(state, action) {
      return {
        ...state,
        chatPlayerAutoplay: action.payload.chatPlayerAutoplay,
      }
    },
    addVideoPlaylistProgress(state, action) {
      return {
        ...state,
        videoPlaylistProgress: {
          ...state.videoPlaylistProgress,
          [action.payload.postId]: action.payload.progress,
        },
      }
    },
    removeVideoPlaylistProgress(state, action) {
      const newProgress = { ...state.videoPlaylistProgress }
      delete newProgress[action.payload.postId]
      return {
        ...state,
        videoPlaylistProgress: newProgress,
      }
    },
    setVideoPlayerReactions(state, action) {
      return {
        ...state,
        videoPlayerReactions: {
          ...state.videoPlayerReactions,
          ...Object.assign(
            {},
            ...action.payload.reactions?.map((item: any) => {
              return { [item.id]: item }
            }),
          ),
        },
      }
    },
    addVideoPlayerReactions(state, action) {
      return {
        ...state,
        videoPlayerReactions: {
          ...state.videoPlayerReactions,
          [action.payload.postId]: action.payload.reactions,
        },
      }
    },
    addVideoPlayerReaction(state, action) {
      return {
        ...state,
        videoPlayerReactions: {
          ...state.videoPlayerReactions,
          [action.payload.postId]: [
            ...(state.videoPlayerReactions[action.payload.postId] || []),
            action.payload.reaction,
          ],
        },
      }
    },
    removeVideoPlayerReaction(state, action) {
      const newReaction = state.videoPlayerReactions[action.payload.postId]?.filter(
        (reaction: screensightReaction) => reaction?.createdAt !== action.payload.reaction.createdAt,
      )
      return {
        ...state,
        videoPlayerReactions: {
          ...state.videoPlayerReactions,
          [action.payload.postId]: newReaction,
        },
      }
    },
    clearVideoPlayerReactions(state, action) {
      return {
        ...state,
        videoPlayerReactions: {},
      }
    },
    setChatCustomers(state, action) {
      return {
        ...state,
        chatCustomers: action.payload.chatCustomers,
      }
    },
    setChatPlayerPaused(state, action) {
      return {
        ...state,
        chatPlayerPaused: action.payload.chatPlayerPaused,
      }
    },
    setUnreadChannelsCount(state, action) {
      return {
        ...state,
        unreadChannelsCount: action.payload.unreadChannelsCount,
      }
    },
    addSeenVideoPostId(state, action) {
      return {
        ...state,
        seenVideoPostIds: [...state.seenVideoPostIds, action.payload.postId],
      }
    },
    setonPostEventSubscriptionData(state, action) {
      return {
        ...state,
        onPostEventSubscriptionData: action.payload.onPostEventSubscriptionData,
      }
    },
    setVideosLimitReached(state, action) {
      return {
        ...state,
        videosLimitReached: action.payload.videosLimitReached,
      }
    },
  },
})

export const chatActions = chatSlice.actions

export default chatSlice
