import { useContext, useEffect } from 'react'
import { AuthContext } from '../../../../providers/AuthStatusProvider'
import { useHistory, useLocation } from 'react-router-dom'

export const PROTECTED_PATHS = ['/app/support', '/app/contacts', '/app/settings']
export const AUTH_PATHS = ['/login', '/sign-up', '/sign-up/', '/verify', '/forgot-password']

/*
 * This hook is used to redirect the user to the correct page based on their authentication status
 */
const useAppRedirects = () => {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const history = useHistory()
  const { state } = useLocation<{ videoId?: string; message?: string }>()

  const isPathProtected = PROTECTED_PATHS.find((path) => {
    if (location.pathname === '/app') return true
    return location.pathname.includes(path)
  })

  useEffect(() => {
    if (!auth.user?.pending && !auth.isAuthenticated && isPathProtected) history.replace('/sign-up')
    else if (auth.isAuthenticated && state?.videoId) {
      history.replace({ pathname: `/video/${state.videoId}`, state: { message: state.message } })
    } else if (auth.isAuthenticated && AUTH_PATHS.includes(location.pathname)) {
      history.replace('/app')
    } else return
  }, [auth, location.pathname, state, isPathProtected])
}

export default useAppRedirects
