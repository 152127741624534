import * as React from 'react'
import { Box } from '@mui/material'
import useGetVideoFragment from '../../../../common/api/hooks/useGetVideoFragment'
import { useContext } from 'react'
import { AuthContext } from '../../../../common/providers/AuthStatusProvider'
import AISummaryInitializer from '../../../Chat/ui/components/ChatContent/ui/components/VideoPostAISummary/ui/components/AISummaryInitializer'
import VideoPostExistingSummaryControls from '../../../Chat/ui/components/ChatContent/ui/components/VideoPostAISummary/ui/components/VideoPostExistingSummaryControls'

type Props = {
  videoId: string | undefined
  AISummaryState: any
}
const VideoItemAiSummary = (props: Props) => {
  const cacheVideo = useGetVideoFragment(props?.videoId)
  const auth = useContext(AuthContext)

  if (!auth.isAuthenticated) return <></>

  return (
    <Box marginRight="15px">
      {!cacheVideo?.transcriptionSummary && cacheVideo?.fileObject?.bucket && cacheVideo?.fileObject?.key && (
        <AISummaryInitializer
          videoData={cacheVideo}
          handleExperimentalInfoVisibility={props.AISummaryState.handleExperimentalInfoVisibility}
          onError={props.AISummaryState.handleErrorMessage}
          isError={!!props.AISummaryState.error}
        />
      )}

      {cacheVideo?.transcriptionSummary && cacheVideo?.fileObject?.bucket && cacheVideo?.fileObject?.key && (
        <VideoPostExistingSummaryControls
          onSummaryClick={() => props.AISummaryState.handleContentOptionSelection('SUMMARY')}
          onTranscriptionClick={() => props.AISummaryState.handleContentOptionSelection('TRANSCRIPTION')}
          contentSelected={props.AISummaryState.contentSelected}
        />
      )}
    </Box>
  )
}

export default VideoItemAiSummary
