import React from 'react'
import { Box } from '@mui/material'

interface IVideoProgress {
  duration?: number | null
  progress?: number | null
}

const VideoPostProgress = (props: IVideoProgress) => {
  return (
    <>
      <Box
        height="3px"
        bgcolor="#2483F7"
        width="calc(100% - 6px)"
        position="absolute"
        left="3px"
        bottom="1px"
        zIndex={2}
        sx={{ borderBottomLeftRadius: '3px', borderBottomRightRadius: '3px', opacity: '15%' }}
      />
      <Box
        data-testid="videoPostProgress-progress-bar"
        height="3px"
        bgcolor="#2483F7"
        width={props?.progress && props?.duration ? `calc(${(props.progress / props.duration) * 100}% - 8px)` : '0'}
        position="absolute"
        left="3px"
        bottom="1px"
        zIndex={3}
        sx={{ borderBottomLeftRadius: '3px', borderBottomRightRadius: '3px' }}
      />
    </>
  )
}

export default VideoPostProgress
