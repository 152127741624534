import React from 'react'
import { Box } from '@mui/material'
import { parseNativeEmoji } from './Reaction'
import { screensightReaction } from '../../../../../../../../API'

interface IVideoPostReactions {
  reactions?: Array<screensightReaction | null>
  duration?: number | null
}

const VideoPostReactions = (props: IVideoPostReactions) => {
  return (
    <>
      {props.reactions?.map((reaction, key) => {
        const position = ((reaction?.videoTimestamp || 0) * 100000) / Number(props?.duration)
        return reaction?.unicode ? (
          <Box
            data-testid={`reaction-emoji-${key}`}
            key={key}
            position="absolute"
            bottom="5px"
            left={`calc(9px + ${position > 94 ? 95 : position}%)`}
            sx={{ transform: 'translateX(-50%)', cursor: 'default' }}
          >
            {parseNativeEmoji(reaction?.unicode)}
          </Box>
        ) : (
          <></>
        )
      })}
    </>
  )
}

export default VideoPostReactions
