import * as React from 'react'

type Props = {}
const FlagUs = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_16376_376323)">
        <path
          d="M13 0.5V0.459C12.053 0.1615 11.0455 0 10 0C4.477 0 0 4.477 0 10C0 10.3375 0.0179999 10.671 0.0504999 11H13V0.5Z"
          fill="#3F51B5"
        />
        <path d="M13 3.5H17.59C17.1115 2.942 16.571 2.4415 15.9815 2H13V3.5Z" fill="#ECEFF1" />
        <path
          d="M19.9495 11H13H0.0505371C0.101537 11.514 0.202037 12.012 0.328037 12.5H19.6725C19.798 12.012 19.8985 11.514 19.9495 11Z"
          fill="#ECEFF1"
        />
        <path
          d="M17.1355 17H2.8645C3.428 17.5745 4.0635 18.0745 4.751 18.5H15.249C15.9365 18.0745 16.572 17.5745 17.1355 17Z"
          fill="#ECEFF1"
        />
        <path
          d="M19.1624 14H0.837402C1.0669 14.525 1.3399 15.0265 1.6524 15.5H18.3474C18.6599 15.0265 18.9324 14.525 19.1624 14Z"
          fill="#ECEFF1"
        />
        <path d="M13 6.5H19.358C19.1625 5.978 18.9275 5.4765 18.6515 5H13V6.5Z" fill="#ECEFF1" />
        <path d="M13 9.5H19.975C19.9495 8.99 19.8985 8.487 19.7995 8H13V9.5Z" fill="#ECEFF1" />
        <path d="M13 2H15.982C15.1205 1.355 14.1565 0.842 13.1115 0.5H13V2Z" fill="#F44336" />
        <path d="M13 5H18.6515C18.343 4.467 17.9885 3.965 17.5905 3.5H13V5Z" fill="#F44336" />
        <path d="M13 8H19.799C19.694 7.483 19.539 6.985 19.3575 6.5H13V8Z" fill="#F44336" />
        <path
          d="M13 11H19.9495C19.982 10.671 20 10.3375 20 10C20 9.831 19.983 9.6665 19.975 9.5H13V11Z"
          fill="#F44336"
        />
        <path
          d="M19.6719 12.5H0.327881C0.460881 13.0165 0.627381 13.5195 0.837881 14H19.1624C19.3724 13.5195 19.5389 13.0165 19.6719 12.5Z"
          fill="#F44336"
        />
        <path
          d="M18.3476 15.5H1.65259C2.00809 16.039 2.41409 16.541 2.86459 17H17.1361C17.5861 16.541 17.9921 16.039 18.3476 15.5Z"
          fill="#F44336"
        />
        <path
          d="M4.75098 18.5C6.27798 19.445 8.07198 20 9.99998 20C11.928 20 13.722 19.445 15.249 18.5H4.75098Z"
          fill="#F44336"
        />
        <path
          d="M3.125 4.5L3.3575 4.994L3.875 5.072L3.5 5.457L3.59 6L3.125 5.744L2.66 6L2.75 5.457L2.375 5.072L2.8925 4.994L3.125 4.5Z"
          fill="white"
        />
        <path
          d="M5.125 4.5L5.3575 4.994L5.875 5.072L5.5 5.457L5.59 6L5.125 5.744L4.66 6L4.75 5.457L4.375 5.072L4.8925 4.994L5.125 4.5Z"
          fill="white"
        />
        <path
          d="M1.1251 5.744L1.5901 6L1.5001 5.457L1.8751 5.072L1.3576 4.994L1.3556 4.989C1.1906 5.273 1.0391 5.5655 0.9021 5.8665L1.1251 5.744Z"
          fill="white"
        />
        <path
          d="M7.125 4.5L7.3575 4.994L7.875 5.072L7.5 5.457L7.59 6L7.125 5.744L6.66 6L6.75 5.457L6.375 5.072L6.8925 4.994L7.125 4.5Z"
          fill="white"
        />
        <path
          d="M9.125 4.5L9.3575 4.994L9.875 5.072L9.5 5.457L9.59 6L9.125 5.744L8.66 6L8.75 5.457L8.375 5.072L8.8925 4.994L9.125 4.5Z"
          fill="white"
        />
        <path
          d="M11 4.5L11.2325 4.994L11.75 5.072L11.375 5.457L11.465 6L11 5.744L10.535 6L10.625 5.457L10.25 5.072L10.7675 4.994L11 4.5Z"
          fill="white"
        />
        <path
          d="M3.125 8.5L3.3575 8.994L3.875 9.072L3.5 9.457L3.59 10L3.125 9.744L2.66 10L2.75 9.457L2.375 9.072L2.8925 8.994L3.125 8.5Z"
          fill="white"
        />
        <path
          d="M5.125 8.5L5.3575 8.994L5.875 9.072L5.5 9.457L5.59 10L5.125 9.744L4.66 10L4.75 9.457L4.375 9.072L4.8925 8.994L5.125 8.5Z"
          fill="white"
        />
        <path
          d="M7.125 8.5L7.3575 8.994L7.875 9.072L7.5 9.457L7.59 10L7.125 9.744L6.66 10L6.75 9.457L6.375 9.072L6.8925 8.994L7.125 8.5Z"
          fill="white"
        />
        <path
          d="M1.125 8.5L1.3575 8.994L1.875 9.072L1.5 9.457L1.59 10L1.125 9.744L0.66 10L0.75 9.457L0.375 9.072L0.8925 8.994L1.125 8.5Z"
          fill="white"
        />
        <path
          d="M9.125 8.5L9.3575 8.994L9.875 9.072L9.5 9.457L9.59 10L9.125 9.744L8.66 10L8.75 9.457L8.375 9.072L8.8925 8.994L9.125 8.5Z"
          fill="white"
        />
        <path
          d="M11 8.5L11.2325 8.994L11.75 9.072L11.375 9.457L11.465 10L11 9.744L10.535 10L10.625 9.457L10.25 9.072L10.7675 8.994L11 8.5Z"
          fill="white"
        />
        <path
          d="M3.625 6.5L3.8575 6.994L4.375 7.072L4 7.457L4.09 8L3.625 7.744L3.16 8L3.25 7.457L2.875 7.072L3.3925 6.994L3.625 6.5Z"
          fill="white"
        />
        <path
          d="M5.625 6.5L5.8575 6.994L6.375 7.072L6 7.457L6.09 8L5.625 7.744L5.16 8L5.25 7.457L4.875 7.072L5.3925 6.994L5.625 6.5Z"
          fill="white"
        />
        <path
          d="M1.625 6.5L1.8575 6.994L2.375 7.072L2 7.457L2.09 8L1.625 7.744L1.16 8L1.25 7.457L0.875 7.072L1.3925 6.994L1.625 6.5Z"
          fill="white"
        />
        <path
          d="M7.625 6.5L7.8575 6.994L8.375 7.072L8 7.457L8.09 8L7.625 7.744L7.16 8L7.25 7.457L6.875 7.072L7.3925 6.994L7.625 6.5Z"
          fill="white"
        />
        <path
          d="M9.625 6.5L9.8575 6.994L10.375 7.072L10 7.457L10.09 8L9.625 7.744L9.16 8L9.25 7.457L8.875 7.072L9.3925 6.994L9.625 6.5Z"
          fill="white"
        />
        <path
          d="M11.5 6.5L11.7325 6.994L12.25 7.072L11.875 7.457L11.965 8L11.5 7.744L11.035 8L11.125 7.457L10.75 7.072L11.2675 6.994L11.5 6.5Z"
          fill="white"
        />
        <path
          d="M3.625 2.5L3.8575 2.994L4.375 3.072L4 3.457L4.09 4L3.625 3.744L3.16 4L3.25 3.457L2.875 3.072L3.3925 2.994L3.625 2.5Z"
          fill="white"
        />
        <path
          d="M5.625 2.5L5.8575 2.994L6.375 3.072L6 3.457L6.09 4L5.625 3.744L5.16 4L5.25 3.457L4.875 3.072L5.3925 2.994L5.625 2.5Z"
          fill="white"
        />
        <path
          d="M7.625 2.5L7.8575 2.994L8.375 3.072L8 3.457L8.09 4L7.625 3.744L7.16 4L7.25 3.457L6.875 3.072L7.3925 2.994L7.625 2.5Z"
          fill="white"
        />
        <path
          d="M9.625 2.5L9.8575 2.994L10.375 3.072L10 3.457L10.09 4L9.625 3.744L9.16 4L9.25 3.457L8.875 3.072L9.3925 2.994L9.625 2.5Z"
          fill="white"
        />
        <path
          d="M11.5 2.5L11.7325 2.994L12.25 3.072L11.875 3.457L11.965 4L11.5 3.744L11.035 4L11.125 3.457L10.75 3.072L11.2675 2.994L11.5 2.5Z"
          fill="white"
        />
        <path
          d="M5.58991 2L5.49991 1.457L5.87491 1.072L5.59941 1.0305C5.30491 1.175 5.01941 1.3335 4.74191 1.5055L4.65991 2L5.12491 1.744L5.58991 2Z"
          fill="white"
        />
        <path
          d="M7.125 0.5L7.3575 0.994L7.875 1.072L7.5 1.457L7.59 2L7.125 1.744L6.66 2L6.75 1.457L6.375 1.072L6.8925 0.994L7.125 0.5Z"
          fill="white"
        />
        <path
          d="M9.125 0.5L9.3575 0.994L9.875 1.072L9.5 1.457L9.59 2L9.125 1.744L8.66 2L8.75 1.457L8.375 1.072L8.8925 0.994L9.125 0.5Z"
          fill="white"
        />
        <path
          d="M11 0.5L11.2325 0.994L11.75 1.0725L11.375 1.4575L11.465 2L11 1.744L10.535 2L10.625 1.457L10.25 1.072L10.7675 0.994L11 0.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_16376_376323">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FlagUs
