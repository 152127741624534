import React from 'react'
import { Box, Typography } from '@mui/material'

interface IAvailableContactRow {
  text: string
  searchPhrase: string
}
function getHighlightedText(text: string, highlight: string) {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  return (
    <span className="contacts__highlight-none">
      {parts.map((part) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={part + new Date()} className="contacts__highlight">
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </span>
  )
}

const AvailableContactRow = (props: IAvailableContactRow) => {
  return (
    <Box className="contact-parts">
      <Typography variant="body1" className="available-modal__contact">
        {getHighlightedText(props.text, props.searchPhrase)}
      </Typography>
    </Box>
  )
}
export default AvailableContactRow
