import { useEffect, useState } from 'react'

const useSendByEmailModal = (hideSendByEmailModal: () => void) => {
  const [isSentModalVisible, setIsSentModalVisible] = useState(false)

  useEffect(() => {
    if (!isSentModalVisible) return
    const emailSentTimeout = setTimeout(() => {
      handleClose()
    }, 2000)
    return () => clearTimeout(emailSentTimeout)
  }, [isSentModalVisible])
  const handleClose = () => {
    hideSendByEmailModal()
    setIsSentModalVisible(false)
  }
  const showMessageSentModal = () => {
    setIsSentModalVisible(true)
  }

  return {
    showMessageSentModal,
    isSentModalVisible,
    handleClose,
  }
}

export type UseSendByEmailModalType = ReturnType<typeof useSendByEmailModal>
export default useSendByEmailModal
