import * as React from 'react'

const FlagPl = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
      <mask id="a">
        <circle cx="256" cy="256" r="256" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path fill="#D82A05" d="m0 256 249.6-41.3L512 256v256H0z" />
        <path fill="#eee" d="M0 0h512v256H0z" />
      </g>
    </svg>
  )
}

export default FlagPl
