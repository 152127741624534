import React from 'react'
import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material'
import UploadThumbnailModal from '../../components/UploadThumbnailModal'
import { useEditThumbnailContext } from '../../../common/context/EditThumbnailContext'
import useEditThumbnail from '../../../common/hooks/useEditThumbnail'
import CloudFrontImage from '../../../../../../../components/_refactored/CloudFrontImage'
import Button from '../../../../../../../components/_refactored/Button'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useTranslation } from 'react-i18next'
import VideoFrameThumbnailModal from '../../components/VideoFrameThumbnailModal'

const Content = () => {
  const { t } = useTranslation('library')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const editThumbnailContext = useEditThumbnailContext()
  const state = useEditThumbnail()
  const isGif = state.photoUrl?.includes('.gif')
  const storedVideoParams = JSON.parse(localStorage.getItem('videoParams') || '{}')

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="100px" width="100%">
        <Button style={{ maxWidth: '250px' }} startIcon={<ArrowBackIosIcon />} onClick={state.handleExit}>
          <Typography variant="body1" color="textPrimary" style={{ fontWeight: 500, fontSize: '20px' }} noWrap>
            {t('videoItem.editThumbnail.editThumbnail')}
          </Typography>
        </Button>
      </Box>
      {editThumbnailContext.currentModalView === 'CUSTOM' && (
        <UploadThumbnailModal videoParams={storedVideoParams.params} />
      )}
      {editThumbnailContext.currentModalView === 'FRAME' && (
        <VideoFrameThumbnailModal filePresignedUrl={state.filePresignedUrl} />
      )}
      <Box
        width="100%"
        borderRadius="10px"
        height="auto"
        margin={isMobile ? '16px auto' : '24px auto'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-end"
        position="relative"
      >
        {editThumbnailContext.imageFile ? (
          <img
            alt="Video Thumbnail"
            src={
              editThumbnailContext.imageFile
                ? URL.createObjectURL(editThumbnailContext.imageFile)
                : state.photoUrl
                ? state.photoUrl
                : ''
            }
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              borderRadius: '10px',
            }}
          />
        ) : state.loading ? (
          <CircularProgress style={{ margin: 'auto' }} />
        ) : (
          <CloudFrontImage
            image={state.photoUrl as string}
            autoScale
            disableResize={isGif}
            imgStyle={{
              aspectRatio: `${storedVideoParams.params?.width} / ${storedVideoParams.params?.height}` || 'auto',
              height: '100%',
              maxWidth: '100%',
              borderRadius: '10px',
              objectFit: 'cover',
            }}
          />
        )}
      </Box>
    </>
  )
}

export default Content
