import React, { Suspense } from 'react'

import Router from 'common/router'
import RecorderProvider from 'common/providers/RecorderStateProvider'
import DeleteConfirmProvider from 'common/providers/DeleteConfirmProvider'
import { AuthProvider } from 'common/providers/AuthStatusProvider'
import MuiThemeProvider, { theme } from 'common/providers/MuiThemeProvider'
import ErrorProvider from 'common/providers/ErrorProvider'
import PatentProvider from 'common/providers/PatentProvider'
import ToastProvider from 'common/providers/ToastProvider'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { Provider as ReduxProvider } from 'react-redux'
import store from '../pages/Chat/common/redux/store/store'
import FullScreenLoader from '../components/_refactored/Loaders/FullScreenLoader'
import DraftContextProvider from 'common/providers/DraftProvider/DraftContextProviderView'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

const App = () => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <GTMProvider state={{ id: process.env.REACT_APP_GTM_ID || '' }}>
        <MuiThemeProvider>
          <StyledThemeProvider theme={theme}>
            <PatentProvider>
              <DeleteConfirmProvider>
                <ErrorProvider>
                  <RecorderProvider>
                    <AuthProvider>
                      <ReduxProvider store={store}>
                        <ToastProvider>
                          <DraftContextProvider>
                            <Router></Router>
                          </DraftContextProvider>
                        </ToastProvider>
                      </ReduxProvider>
                    </AuthProvider>
                  </RecorderProvider>
                </ErrorProvider>
              </DeleteConfirmProvider>
            </PatentProvider>
          </StyledThemeProvider>
        </MuiThemeProvider>
      </GTMProvider>
    </Suspense>
  )
}

export default App
